import React, { useEffect } from "react";
import { useState } from "react";
import { editLedgerAPI } from "../adminApi/Ledger";
import LoaderComponent from "../components/LoaderComponent";
import { toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "../assets/images/icons/close-icon.svg";
import { balancePrecission } from "../adminUtility";

const EditLedgerModal = ({
  showLedgerModal,
  setShowLedgerModal,
  ledgerId,
  selectedLedgerDetails,
  fetchData,
}) => {
  const [paymentNoteValue, setPaymentNoteValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePaymentNoteChange = (event) => {
    const { value } = event.target;
    setPaymentNoteValue(value);
  };

  useEffect(() => {
    setPaymentNoteValue(selectedLedgerDetails?.paymentnote);
  }, [selectedLedgerDetails]);

  const handleSubmit = async () => {
    setLoading(true);
    const token = localStorage.getItem("access_token");
    try {
      const response = await editLedgerAPI(token, ledgerId, paymentNoteValue);
      setTimeout(() => {
        setLoading(false);
      }, 1000);

      setShowLedgerModal(false);
      toast.success("Ledger Updated Succesully.");
      fetchData();
    } catch (error) {
      toast.error(error?.response?.data);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoaderComponent />}
      {showLedgerModal ? (
        <>
          <div
            className="Admin-modal Admin-modal--open"
            id="modalCategory"
            role="dialog"
          >
            <div className="Admin-modal__dialog Admin-modal__dialog--md">
              <div className="Admin-modal__content">
                <div className="Admin-modal__head">
                  <h2 className="Admin-modal__head--title">Edit Ledger</h2>
                  <button
                    className="Admin-modal__close"
                    onClick={() => {
                      setShowLedgerModal(false);
                    }}
                  >
                    <CloseIcon />
                  </button>
                </div>

                <div className="Admin-modal__body">
                  <div className="flexbox">
                    <div className="column">
                      <label
                        htmlFor="clientId "
                        className="title--regular label"
                      >
                        {" "}
                        Client ID
                      </label>
                      <input
                        type="text"
                        className="adminform__input"
                        placeholder="Client ID"
                        disabled
                        value={selectedLedgerDetails?.clientId}
                      />
                    </div>
                    <div className="column">
                      <label
                        htmlFor="clientId"
                        className="title--regular label"
                      >
                        {" "}
                        Student ID
                      </label>
                      <input
                        type="text"
                        className="adminform__input"
                        placeholder="Student ID"
                        disabled
                        value={selectedLedgerDetails?.studentId}
                      />
                    </div>
                  </div>

                  <div className="flexbox">
                    <div className="column">
                      <label
                        htmlFor="clientId"
                        className="title--regular label"
                      >
                        {" "}
                        Student Name
                      </label>

                      <input
                        type="text"
                        className="adminform__input"
                        placeholder="Student Name"
                        value={
                          selectedLedgerDetails?.student_first_name +
                          " " +
                          selectedLedgerDetails?.student_last_name
                        }
                        disabled
                      />
                    </div>

                    {/* <div className="column">
                      <label
                        htmlFor="clientId"
                        className="title--regular label"
                      >
                        {" "}
                        Class Enrolled
                      </label>

                      <input
                        type="text"
                        className="adminform__input"
                        placeholder="Class Enrolled"
                        value={
                          selectedLedgerDetails?.classes
                            ? selectedLedgerDetails.classes
                                .map((val) => val.name)
                                .join(", ")
                            : ""
                        }
                        disabled
                      />
                    </div> */}
                  </div>

                  {/* ENROLLMENT INFO TABLE  */}

                  {selectedLedgerDetails?.classes?.length  > 0 && (
                    <div className="Table">
                      <div className="Heading">
                        <div
                          className="Cell"
                          style={{ color: "white", minWidth: "200px" }}
                        >
                          <p>Class Name</p>
                        </div>
                        <div
                          className="Cell"
                          style={{ color: "white", maxWidth: "100px" }}
                        >
                          <p>Fee/Month</p>
                        </div>
                      </div>
                      {selectedLedgerDetails?.classes?.map((val) => {
                        return (
                          <div className="Row">
                            <div
                              className="Cell Cell_input"
                              style={{ width: "60px" }}
                            >
                              <p>{val?.name}</p>
                            </div>
                            <div
                              className="Cell Cell_input"
                              style={{ width: "60px" }}
                            >
                              <p>${val?.tuition}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  <div className="flexbox">
                    <div className="column">
                      <label
                        htmlFor="clientId"
                        className="title--regular label"
                      >
                        {" "}
                        Parent 1 Name
                      </label>

                      <input
                        type="text"
                        className="adminform__input"
                        placeholder="Paren 1 Name"
                        disabled
                        value={
                          selectedLedgerDetails?.parent1_first_name +
                          " " +
                          selectedLedgerDetails?.parent1_last_name
                        }
                      />
                    </div>

                    <div className="column">
                      <label
                        htmlFor="clientId"
                        className="title--regular label"
                      >
                        {" "}
                        Parent 2 Name
                      </label>

                      <input
                        type="text"
                        className="adminform__input"
                        placeholder="Paren 2 Name"
                        disabled
                        value={
                          selectedLedgerDetails?.parent2_first_name &&
                          selectedLedgerDetails?.parent2_last_name
                            ? `${selectedLedgerDetails.parent2_first_name} ${selectedLedgerDetails.parent2_last_name}`
                            : "Empty or Not Available"
                        }
                      />
                    </div>
                  </div>

                  <div className="flexbox">
                    {/* <div className="column">
                      <label
                        htmlFor="clientId"
                        className="title--regular label"
                      >
                        {" "}
                        Tuition Fee ($)
                      </label>

                      <input
                        type="text"
                        className="adminform__input"
                        placeholder="Tuition Fee"
                        disabled
                        value={
                          selectedLedgerDetails?.classes
                            ? selectedLedgerDetails.classes
                                .map((val) => val.tuition)
                                .join(", ")
                            : ""
                        }
                      />
                    </div> */}
                    <div className="column">
                      <label
                        htmlFor="clientId"
                        className="title--regular label"
                      >
                        {" "}
                        Amount To Pay($)
                      </label>

                      <input
                        type="text"
                        className="adminform__input"
                        placeholder="Pay"
                        disabled
                        value={balancePrecission(
                          selectedLedgerDetails?.amount_to_pay
                        )}
                      />
                    </div>
                  </div>

                  <div className="flexbox">
                    {/* <div className="column">
                      <label
                        htmlFor="clientId"
                        className="title--regular label"
                      >
                        {" "}
                        Paid
                      </label>

                      <input
                        type="text"
                        className="adminform__input"
                        placeholder="Paid"
                        disabled
                        value={
                          selectedLedgerDetails?.paid === true ? "Yes" : "No"
                        }
                      />
                    </div> */}
                  </div>
                  <div className="flexbox">
                    <div className="column">
                      <div className="adminform__group">
                        <label
                          htmlFor="clientId"
                          className="title--regular label"
                        >
                          {" "}
                          Payment Notes
                        </label>

                        <textarea
                          type="text"
                          className="adminform__textarea"
                          placeholder="Payment Notes"
                          name="paymentNote"
                          value={paymentNoteValue}
                          onChange={handlePaymentNoteChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Admin-modal__footer">
                  <div
                    className="admin-button__group"
                    style={{ display: "flex", gap: "30px" }}
                  >
                    <button
                      className="adminbtn adminbtn-blueOutline"
                      onClick={() => {
                        setShowLedgerModal(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="adminbtn adminbtn-primary"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default EditLedgerModal;
