import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/images/icons/close-icon.svg";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../reducers/loadingSlice";
import LoaderComponent from "../../../components/LoaderComponent";

const EditCalenderHoliday = ({
  setHolidayEditModal,
  holiday,
  getCalenderData,
  type,
}) => {
  const token = localStorage.getItem("access_token");
  const [holidayName, setHolidayName] = useState();
  const [holidayDate, setHolidayDate] = useState();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    setHolidaysFields();
  }, [holiday]);

  const setHolidaysFields = () => {
    setHolidayName(holiday?.text);
    setHolidayDate(holiday?.date);
  };

  const editCalenderHandler = async () => {
    dispatch(setLoading(true));
    try {
      const postData = {
        calendarId: 60,
        text: holidayName,
        sessionId: holiday?.session_id,
        dateId: holiday?.id,
        date: [holidayDate],
        season: holiday?.season,
      };

      const response = await axios.put(
        process.env.REACT_APP_BASE_URL + "/admin/updatecalendar",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 201 || response?.status === 200) {
        dispatch(setLoading(false));
        toast.success("Calender updated successfully.");
        setHolidayEditModal(false);
        getCalenderData();
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.error(error);
      toast.error(error?.response?.data?.error);
      // setHolidayEditModal(false);
    }
  };

  const deleteMonthHolidayHandler = async () => {
    dispatch(setLoading(true));
    try {
      const postData = {
        dateId: holiday?.id,
        sessionId: 123,
      };

      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/admin/deletecalendar",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.status === 200 || response?.status === 201) {
        dispatch(setLoading(false));

        toast.success("Holiday deleted successfully");
        setHolidayEditModal(false);
        getCalenderData();
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error while deleting holiday", error);
      toast.error(error);
      setHolidayEditModal(false);
    }
  };
  return (
    <>
      {state?.loading?.loading && <LoaderComponent />}
      <div
        className="Admin-modal Admin-modal--open"
        id="modalDelete"
        role="dialog"
      >
        <div className="Admin-modal__dialog Admin-modal__dialog--md">
          <div className="Admin-modal__content">
            <div className="Admin-modal__head">
              <h2 className="Admin-modal__head--title">{type} Calender</h2>
              <button
                className="Admin-modal__close"
                onClick={() => setHolidayEditModal(false)}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="Admin-modal__body">
              <div
                className=""
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  gap: "10px",
                  fontSize: "0.875rem",
                }}
              >
                <span style={{ color: "red", fontWeight:"600" }}> Note:</span>
                <p style={{ fontSize: "0.875rem" }}>
                  To Edit the Holiday For Date Range, Please Delete the Range &
                  Add New One
                </p>
              </div>
              <div className="holiday_container_edit">
                <input
                  type="text"
                  value={holidayDate}
                  className="adminform__input"
                  onChange={(e) => setHolidayDate(e.target.value)}
                />

                <input
                  type="text"
                  placeholder="Enter holiday"
                  className="adminform__input"
                  value={holidayName}
                  onChange={(e) => setHolidayName(e.target.value)}
                />
              </div>
              <div className="btn_conatiner" style={{marginTop:"40px", marginBottom:"20px"}}>
                {type === "Edit" ? (
                  <button
                    className="btn adminbtn-primary"
                    onClick={() => editCalenderHandler()}
                  >
                    Edit
                  </button>
                ) : (
                  <button
                    className="btn adminbtn-primary"
                    onClick={() => deleteMonthHolidayHandler()}
                  >
                    Delete
                  </button>
                )}
                <button
                  className="btn adminbtn-primary"
                  onClick={() => setHolidayEditModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCalenderHoliday;
