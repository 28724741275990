import axios from "axios";
import { GET_TEACHERS } from "../adminQueries/Teacher";

export async function getTeachers(token, formData, page, sortKey) {
  const data = formData?.map((item) => {
    return `${item.selectUser}:${item.name}`;
  });

  var variables = {};
  data.forEach(function (item) {
    var pair = item.split(":");

    variables[pair[0]] = pair[1];
  });

  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_TEACHERS,
        variables: {
          pageInfo: {
            limit: 10,
            page: page,
            isAsc: sortKey?.isAsc,
            sort: sortKey?.sortId?.toString(),
          },
          ...variables,
        },
      },
      {
        headers: {
          Authorization: `Bearer skdfgskjdfhbkjhb`,
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error;
  }
}

export async function updateTeacher(editData, token, id) {
  if (editData?.altphone === null) {
    editData.altphone = "12345";
  }
  if (editData?.cell === null) {
    editData.cell = "123456789";
  }
  if (editData?.home_phone === null) {
    editData.home_phone = "123456789";
  }
  try {
    const response = await axios.put(
      process.env.REACT_APP_BASE_URL + `/teacher/update/${id}`,
      editData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data;
  } catch (error) {
    return error;
    throw new Error(error);
  }
}

export const deleteTeacher = async (data, token) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_BASE_URL + `/teacher/delete/${data}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    console.error("Error deleting teacher", error);
    throw error;
  }
};

// List of teachers For Dropdowns
export async function getTeachersList(token, formData, page, sortKey) {
  const data = formData?.map((item) => {
    return `${item.selectUser}:${item.name}`;
  });

  var variables = {};
  data.forEach(function (item) {
    var pair = item.split(":");

    variables[pair[0]] = pair[1];
  });

  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_TEACHERS,
        variables: {
          pageInfo: {
            isAsc: true,
            limit: 3000000,
            page: 0,
            sort: "1",
          },
          ...variables,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error;
  }
}
