const GET_CAMP_CLASS_DETAIL_QUERY = `
query GetClass($programId: Int, $isHalf: Boolean) 
{  getCampProgramClass(programId: $programId, isHalf: $isHalf) 
    {    id    end_date    name    max_strength    start_date    end_date    class_schedules 
        {        
            classScheduleId       
            id     
            createdat      
            end_time      
            weekday      
            start_time    
        }        classes_enrolled 
        {        
            id    
        }  
    }
}
`;

export const GET_CLASS_CAMP_QUERY=`
query getClassesQuery($classId: Int) {
    getWeeklyClass(classId: $classId) {
        name
        id
        name
        start_time
        end_time
        category_name
        start_date
        end_date
        max_strength
        tuition
        halfDay {
            id
            name
            week
            start_date
            end_date
            max_strength
            tuition
            start_day
            end_day
            enrolled
            start_time
            end_time
            class_type
        }
        fullDay {
            id
            name
            week
            start_date
            end_date
            max_strength
            tuition
            start_day
            end_day
            enrolled
            start_time
            end_time
            class_type
        }
      }
    
  }
`
export {GET_CAMP_CLASS_DETAIL_QUERY};