import React from "react";
import cakeIcon from "../../parent-assets/images/birthday-icons/cake-icon.png";
import musicIcon from "../../parent-assets/images/birthday-icons/music-icon.png";
import yogaIcon from "../../parent-assets/images/birthday-icons/yoga-icon.png";
import martialArtsIcon from "../../parent-assets/images/birthday-icons/martial-arts.png";
import danceIcon from "../../parent-assets/images/birthday-icons/dance-icon.png";
import artIcon from "../../parent-assets/images/art-icon.png";
import cookingIcon from "../../parent-assets/images/cooking-icon.png";

function ActivityComponent() {
  return (
    <div
      className="birthdayActivity__list"
      style={{
        display: "flex",
        alignContent: "center",
        flexWrap: "wrap",
      }}
    >
      {/* ART  */}
      <div className="birthdayActivity__list__col">
        <div className="birthdayActivity__card">
          <div className="tittleBox titleBox-Cake">
            <div className="icon icon--cake">
              <img src={artIcon} alt="Art" />
            </div>
            <h3
              className="title titleBox--cake iconText"
              style={{ color: "#00BBE3" }}
            >
              Art
            </h3>
          </div>
          <div className="shortText">
            <p className="para">
              Grab your paint brushes or markers and join us for a colorful
              extravaganza in the art room! Let the children’s creativity soar
              as they dive into a world of imagination and fun.
            </p>
          </div>
          <div className="form__group">
            <p className="para">
              <span className="para__askmask">*</span> Subject to size limit
            </p>
            <div className="form__checkbox">
              <div className="form__checkbox__option">
                <div className="form__checkbox__labelText">$400</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* COOKING  */}
      <div className="birthdayActivity__list__col">
        <div className="birthdayActivity__card">
          <div className="tittleBox titleBox-Cake">
            <div className="icon icon--cake">
              <img src={cookingIcon} alt="Cooking" />
            </div>
            <h3 className="title titleBox--cake" style={{ color: "#EA519C" }}>
              Cooking
            </h3>
          </div>
          <div className="shortText">
            <p className="para">
              Treat your little chef’s, ages 3 and older, to a unique party
              where the children will cook their own food with the help of our
              professional chefs! Mix, shake, decorate, and bake with some
              kitchen fun!
            </p>
          </div>
          <div className="form__group">
            <p className="para">
              <span className="para__askmask">*</span> Subject to size limit
            </p>
            <div className="form__checkbox">
              <div className="form__checkbox__option">
                <div className="form__checkbox__labelText">$400</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* CUPCAKE  */}
      <div className="birthdayActivity__list__col">
        <div className="birthdayActivity__card">
          <div
            className="tittleBox titleBox-Cake"
            style={{ display: "flex", gap: "20px" }}
          >
            <div className="icon icon--cake">
              <img src={cakeIcon} alt="cakeIcon" />
            </div>
            <h3
              className="title titleBox--cake titleBox--cake--responsive"
              style={{ color: "#593F98", fontSize: "1.275rem", width: "215px" }}
            >
              Cupcake <br /> Decorating
            </h3>
          </div>
          <div className="shortText">
            <p className="para">
              Get ready to sprinkle, swirl, and frost your way to cupcake
              perfection at the Cupcake Decorating Party! With an array of
              colorful frostings and sprinkles, every cupcake becomes a canvas
              and a delicious treat to bring home!
            </p>
          </div>
          <div className="form__group">
            <p className="para">
              <span className="para__askmask">*</span> Subject to size limit
            </p>
            <div className="form__checkbox">
              <div className="form__checkbox__option">
                <div className="form__checkbox__labelText">$400</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* MARTIAL_ARTS  */}
      <div className="birthdayActivity__list__col">
        <div className="birthdayActivity__card">
          <div
            className="tittleBox titleBox-Cake"
            style={{ display: "flex", gap: "5px" }}
          >
            <div
              className="icon icon--cake"
              style={{ transform: "rotate(346deg" }}
            >
              <img src={martialArtsIcon} alt="Martial Arts" />
            </div>
            <h3 className="title titleBox--cake" style={{ color: "#F79727" }}>
              Martial <br /> Arts
            </h3>
          </div>
          <div className="shortText">
            <p className="para">
              Get ready to kick, punch, and karate chop your way through an epic
              adventure at our Martial Arts Party! Young warriors will unleash
              their inner strength and show off their awesome moves.
            </p>
          </div>
          <div className="form__group">
            <p className="para">
              <span className="para__askmask">*</span> Subject to size limit
            </p>
            <div className="form__checkbox">
              <div className="form__checkbox__option">
                <div className="form__checkbox__labelText">$400</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* DANCE  */}
      <div className="birthdayActivity__list__col">
        <div className="birthdayActivity__card">
          <div className="tittleBox titleBox-Cake">
            <div
              className="icon icon--cake dance_icon"
              style={{
                transform: "rotate(354deg)",
                width: "83px",
                height: "100%",
                marginBottom: "1.2rem",
              }}
            >
              <img src={danceIcon} alt="Martial Arts" className="dance_icon" />
            </div>
            <h3
              className="title titleBox--cake"
              style={{ color: "rgb(234, 81, 156)" }}
            >
              Dance
            </h3>
          </div>
          <div className="shortText">
            <p className="para">
              Step into the spotlight and bust a move at this Dance Party! It’s
              a dazzling celebration where young dancers can shine and show off
              their best moves. With exciting dance games and nonstop fun, get
              ready to groove the day away. It’s a party where every twirl is a
              thrill and every leap is a leap of joy!
            </p>
          </div>
          <div className="form__group">
            <p className="para">
              <span className="para__askmask">*</span> Subject to size limit
            </p>
            <div className="form__checkbox">
              <div className="form__checkbox__option">
                <div className="form__checkbox__labelText">$400</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* MUSIC  */}

      <div className="birthdayActivity__list__col">
        <div className="birthdayActivity__card">
          <div
            className="tittleBox titleBox-Cake"
            style={{ display: "flex", gap: "5px" }}
          >
            <div
              className="icon icon--cake"
              style={{ transform: "rotate(346deg)", width: "105px" }}
            >
              <img
                src={musicIcon}
                alt="Martial Arts"
                style={{ height: "105%", gap: "0px" }}
              />
            </div>
            <h3 className="title titleBox--cake" style={{ color: "#593F98" }}>
              Music
            </h3>
          </div>
          <div className="shortText">
            <p className="para">
              Calling all music lovers and rhythm enthusiasts! Get ready to
              rock, dance, and sing at our Music Party! It’s time to make some
              noise and create unforgettable melodies at your child’s birthday
              party!
            </p>
          </div>
          <div className="form__group">
            <p className="para">
              <span className="para__askmask">*</span> Subject to size limit
            </p>
            <div className="form__checkbox">
              <div className="form__checkbox__option">
                <div className="form__checkbox__labelText">$400</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* YOGA  */}
      <div className="birthdayActivity__list__col">
        <div className="birthdayActivity__card">
          <div
            className="tittleBox titleBox-Cake"
            style={{ display: "flex", gap: "10px" }}
          >
            <div
              className="icon icon--cake dance_icon"
              style={{ width: "138px" }}
            >
              <img src={yogaIcon} alt="Martial Arts" />
            </div>
            <h3 className="title yoga_title" style={{ color: "#C3D940" }}>
              Yoga
            </h3>
          </div>
          <div className="shortText">
            <p className="para">
              Prepare to stretch, breathe, and find your inner peace at our Yoga
              Party! Young minds will explore the magic of yoga in a fun and
              playful environment. We’ll journey through a series of poses and
              mindful activities designed just for kids.
            </p>
          </div>
          <div className="form__group">
            <p className="para">
              <span className="para__askmask">*</span> Subject to size limit
            </p>
            <div className="form__checkbox">
              <div className="form__checkbox__option">
                {/* <input
                  type="checkbox"
                  className="form__checkbox__input form__checkbox__input--withoutlabel"
                  id="7"
                  name="Yoga"
                  value="400"
                  onChange={handleActivityChange}
                  checked={selectedActivities.some(
                    (act) => act.activity === "Yoga"
                  )}
                /> */}
                {/* <label
                  htmlFor="activityPayment"
                  className="form__checkbox__label"
                ></label> */}
                <div className="form__checkbox__labelText">$400</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivityComponent;
