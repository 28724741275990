import axios from "axios";
import { GET_BLOGS, GET_COMMENTS, GET_LIKES } from "../pages/adminQueries/Blog";

export async function getBlogs(token,currPage,limit) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_BLOGS,
        variables: {
          page: currPage,
          limit: limit,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data?.getblogsCMS;
  } catch (error) {
    console.error(error);
  }
}

export async function getLikes(token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_LIKES,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data?.getTotalLikes;
  } catch (error) {
    console.error(error);
  }
}

export async function getComments(token, blogId) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_COMMENTS,
        variables: { blogId: Number(blogId) },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data?.getCommentsBlog;
  } catch (error) {
    console.error(error);
  }
}
