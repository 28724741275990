import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet, useLocation } from "react-router-dom";
import Overlay from "../parent-pages/Overlay";
import { useSelector } from "react-redux";

const Layout = () => {
  const location = useLocation();
  const showHeaderAndFooter = !location.pathname.startsWith("/admin");
  // Use Redux and add loading state in it
  const loading = useSelector((state) => state.loading.loading);

  return (
    <div>
      <div>
        {showHeaderAndFooter && <Header />}
        <Outlet />
        {showHeaderAndFooter && <Footer />}
      </div>
      {/* {loading && <Overlay />} */}
    </div>
  );
};

export default Layout;
