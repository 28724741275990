import axios from "axios";

const postRequest = async (url, data) => {
  try {
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const formatDate = (date) => {
  if (date) {
    const parts = date.split("-");
    if (parts.length === 3) {
      const year = parts[0].slice(2);
      const month = parts[1];
      const day = parts[2];
      return `${month}/${day}/${year}`;
    }
  }
  return date;
};

// Fri Jan 05 2024 00:00:00 GMT+0530 (India Standard Time)  Conevrtsion

export async function FormatDateGMT(dateString) {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

export function AgeCalculationFunction(dob) {
  const dobParts = dob.split("-");
  if (dobParts.length !== 3) {
    return "Age";
  }
  const birthYear = parseInt(dobParts[0]);
  const birthMonth = parseInt(dobParts[1]);
  const birthDay = parseInt(dobParts[2]);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();

  let age = currentYear - birthYear;
  const monthDifference = currentMonth - birthMonth;

  if (monthDifference < 0 || (monthDifference === 0 && currentDay < birthDay)) {
    age--;
  }

  if (age < 2) {
    let months = (currentYear - birthYear) * 12 + (currentMonth - birthMonth);
    if (currentDay < birthDay) {
      months--;
    }
    if (months < 0) {
      months += 12;
    }
    return months + " months";
  } else {
    return age + " years";
  }
}

export default postRequest;
