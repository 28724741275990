import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as AccordionIcon } from "../../../Parent/parent-assets/images/arrow-down-black.svg";

import {
  enableRecurringOrders,
  getAllCardForOrder,
  recurringStatusAPI_ORDERS,
} from "../../adminApi/Ledger";
import { toast } from "react-toastify";
import masterCard from "../../assets/images/icons/mastercard.svg";
import discoverCard from "../../assets/images/icons/discover.svg";
import americanExpress from "../../assets/images/icons/americanExpress.svg";
import jcbCard from "../../assets/images/icons/jcbCard.svg";
import visaCard from "../../assets/images/icons/visa-card.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/deleteRowIcon.svg";

import {
  isValidEmail,
  isValidExpiryDate,
  isValidName,
  isValidState,
  isValidUSZipCode,
} from "../../../Parent/utility/validation";

import TrashIcon from "../../../Parent/parent-assets/images/trash-icon-red.svg";

import { useEffect } from "react";
import { getStates } from "../../adminApi/users";
import creditCardType from "credit-card-type";
import { orderRecurringAPI, orderRepay } from "../../adminApi/OrdersAPI";
import { getCurrentDateYYMMDD, validateScheduleRows } from "../../adminUtility";

function CreditCardPayment({
  recurringDuration,
  ledgerDetails,
  paymentFormData,
  amountTendered,
  LedgerApiData,
  buttonType,
  setLoading,
  paymentMethodStatus,
  orderApiData,
  setEnablePaymentModal,
  orderID,
  toggleState,
  setOpenDeleteCardFile,
  setDeleteCardID,
  PrefilledDetails,
  fetchData,
  orderDetails,
  getModalDatafunction,
  deleteCardOnFile,
}) {
  const [isChecked, setIsChecked] = useState(false);
  const [cardType, setCardType] = useState(null);
  const [selectedCardId, setSelectedCardId] = useState("");

  const getCardIcon = () => {
    if (cardType === "american-express") {
      return <img height={"34px"} src={americanExpress} alt="discover" />;
    } else if (cardType === "discover") {
      return <img height={"50px"} src={discoverCard} alt="discover" />;
    } else if (cardType === "mastercard") {
      return <img src={masterCard} alt="masterCard" height={"34px"} />;
    } else if (cardType === "jcb") {
      return <img src={jcbCard} alt="jcb" height={"34px"} />;
    } else if (cardType === "maestro") {
      return <img src={jcbCard} alt="jcb" height={"34px"} />;
    } else if (cardType === "visa") {
      return <img src={visaCard} alt="jcb" height={"34px"} />;
    } else {
      return <></>;
    }
    return <span></span>;
  };
  const token = localStorage.getItem("access_token");

  const dropdownRef = useRef();
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [updatedRecurringDates, setUpdatedRecurringDates] = useState([]);
  const [recurringApiData, setRecurringApiData] = useState();
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + 1);
  const [expiryDate, setExpiryDate] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [getAllStateApi, setGetAllStateApi] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [cardLists, setCardLists] = useState();
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [formData, setFormData] = useState({
    creditCard: "",
    amount: "",
    cvv: "",
    expirationDate: "",
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
    recurringInput: recurringDuration || "",
    isRecurring: buttonType === "Enable" ? true : false,
  });
  const [editedFormData, setEditedFormData] = useState({
    creditCard: "",
    amount: "",
    cvv: "",
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    recurringInput: recurringDuration || "",
    cardType: "",
    isRecurring: false,
    updateRecurringDetails: PrefilledDetails?.selectedCheckboxes,
  });
  const [scheduleRows, setScheduleRows] = useState([]);

  const handleAddSchedules = (e, recDate, recIndex) => {
    e.preventDefault();
    const lastObject = recDate?.dateArray[recDate?.dateArray.length - 1];
    const currentRows = scheduleRows[recIndex] || [];
    const updatedRows = [
      ...currentRows,

      {
        className: lastObject?.className ? lastObject?.className : "",
        classScheduleId: lastObject?.classScheduleId
          ? lastObject?.classScheduleId
          : recDate?.classScheduleId,
      },
    ];

    setScheduleRows({
      ...scheduleRows,
      [recIndex]: updatedRows,
    });
  };

  const handleDeleteRows = (recIndex, rowIndex) => {
    const updatedRows = scheduleRows[recIndex].filter((_, i) => i !== rowIndex);

    setScheduleRows({
      ...scheduleRows,
      [recIndex]: updatedRows,
    });
  };

  const handleScheduleInputChange = (recIndex, rowIndex, fieldName, value) => {
    const updatedRows = [...scheduleRows[recIndex]];

    if (fieldName == "fee") {
      updatedRows[rowIndex][fieldName] = parseInt(value);
    } else {
      updatedRows[rowIndex][fieldName] = value;
    }

    setScheduleRows({
      ...scheduleRows,
      [recIndex]: updatedRows,
    });
  };

  useEffect(() => {
    if (PrefilledDetails?.id) {
      const dateString = PrefilledDetails?.card_expiry_date;

      if (dateString && typeof dateString === "string") {
        const [month, year] = dateString.split("/");
        const convertedDate = new Date(`20${year}`, month - 1);

        setFormData({
          creditCard: "",
          // cardType: PrefilledDetails?.card_type,
          amount: amountTendered
            ? amountTendered
            : PrefilledDetails?.amount_tendered,
          cvv: "",
          expirationDate: convertedDate,
          firstName: PrefilledDetails?.first_name,
          lastName: PrefilledDetails?.last_name,
          email: PrefilledDetails?.email,
          address: PrefilledDetails?.address1,
          city: PrefilledDetails?.city,
          state: PrefilledDetails?.state,
          zip: PrefilledDetails?.zip,
          country: "US",
          // recurringInput: recurringDuration || "",
          recurringInput: PrefilledDetails?.chargeDay,
          isRecurring: buttonType === "Enable",
          recurringAmount: PrefilledDetails?.isRecurringAmount,
          updateRecurringDetails: PrefilledDetails?.selectedCheckboxes,
        });

        setExpiryDate(PrefilledDetails?.card_expiry_date);
      }
    }
  }, [PrefilledDetails, amountTendered]);

  useEffect(() => {
    setSelectedCheckboxes(orderApiData?.data?.recurringDates);
  }, [orderApiData]);

  const [formErrors, setFormErrors] = useState({
    creditCard: "",
    adhocCharges: "",
    cvv: "",
    expirationDate: "",
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    zip: "",
    country: "",
    // cardType: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === "checkbox" ? checked : value;

    if (editedFormData.hasOwnProperty(name)) {
      setEditedFormData({
        ...editedFormData,
        [name]: newValue,
      });
    } else {
      if (name === "expirationDate") {
        setFormData({
          ...formData,
          [name]: newValue,
        });
      } else if (name === "amount" || name === "cvv" || name === "zip") {
        newValue = newValue.replace(/\D/g, "");
      }
    }
    if (name === "cvv") {
      const isValidCVV = validateCVV(newValue, cardType);

      if (!isValidCVV) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid CVV for this card type.",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }

    if (name === "expirationDate") {
      setFormData({
        ...formData,
        [name]: newValue,
      });
    } else if (name === "amount" || name === "cvv" || name === "zip") {
      newValue = newValue.replace(/\D/g, "");
    }

    if (name === "creditCard") {
      newValue = newValue.replace(/\D/g, "");
      const detectedCardType = creditCardType(newValue);
      setCardType(
        detectedCardType.length > 0 ? detectedCardType[0].type : null
      );
      if ((!newValue || newValue.length < 16) && newValue === "") {
        setCardType(null);
      }
      getCardIcon();
    }

    if (name === "recurringInput") {
      const newValue = type === "checkbox" ? checked : value;
      setFormData((prevData) => ({
        ...prevData,
        recurringInput: newValue,
        isRecurring: newValue !== "" && newValue !== 0,
      }));
    } else {
      setFormData({
        ...formData,
        [name]: newValue,
      });
    }

    // if (name === "cardType") {
    //   setFormData({
    //     ...formData,
    //     [name]: newValue,
    //   });
    // } else
    if (name === "recurringInput") {
      setFormData((prevData) => ({
        ...prevData,
        recurringInput: newValue,
        isRecurring: isChecked ? newValue : false,
      }));
    } else {
      setFormData({
        ...formData,
        [name]: newValue,
      });
    }

    let errorMessage = "";

    switch (name) {
      case "firstName":
        errorMessage = isValidName(newValue)
          ? ""
          : "First name must not contain numbers.";
        break;
      case "lastName":
        errorMessage = isValidName(newValue)
          ? ""
          : "Last name must not contain numbers.";
        break;
      case "email":
        errorMessage = isValidEmail(newValue) ? "" : "Invalid email address.";
        break;
      case "expirationDate":
        errorMessage = isValidUSZipCode(newValue) ? "" : "Enter Valid Date.";
        break;
      case "zip":
        errorMessage = isValidUSZipCode(newValue)
          ? ""
          : "Zip Code Must be 5-digit .";
        break;

      default:
        errorMessage = "";
        break;
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  const validateForm = () => {
    let isValid = true;

    const errors = {
      creditCard: "",
      cvv: "",
      expirationDate: "",
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      city: "",
      zip: "",
      country: "",
      recurringInput: "",
      // cardType: "",
    };

    if (!isValidName(formData.firstName)) {
      errors.firstName = "First name must not contain numbers.";
      isValid = false;
    }

    if (!isValidName(formData.lastName)) {
      errors.lastName = "Last name must not contain numbers.";
      isValid = false;
    }

    if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email address.";
      isValid = false;
    }

    if (!isValidExpiryDate(formData.expirationDate)) {
      errors.expirationDate = "Invalid Expiry Date.";
      return false;
    }

    if (!isValidUSZipCode(formData.zip)) {
      errors.zip = "Zip Code Must be .";
      isValid = true;
    }

    if (formData.state && !isValidState(formData.state)) {
      errors.email = "Invalid State.";
      return false;
    }

    if (validateCVV(formData.cvv)) {
      errors.zip = "Zip Code Must be .";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const validateCVV = (cvv, cardType) => {
    let isValid = false;
    // Remove non-digit characters from CVV
    const cleanedCVV = cvv.replace(/\D/g, "");
    // Define CVV length based on card types
    const cvvLengths = {
      amex: 4,
      visa: 3,
      mastercard: 3,
      discover: 3,
    };
    // Check if the provided CVV matches the expected length for the card type
    if (cvvLengths.hasOwnProperty(cardType)) {
      const expectedLength = cvvLengths[cardType];
      isValid = cleanedCVV.length === expectedLength;
    }
    return isValid;
  };

  // Submit hanlder
  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = String(currentDate.getFullYear()).slice(-2);
    const formattedDate = `${month}/${day}/${year}`;
    const classPaymentDetailsArray = ledgerDetails?.map((val, index) => ({
      classSchedule: parseInt(val?.classSchedule, 10),
      amountOwed: parseFloat(val?.proRatedFee),
      ledgerDetailId: val?.ledgerDetailId,
      amountToPay: parseFloat(val?.proRatedFee),
      enrollmentStartDate: val?.enrollmentStartDate,
      enrollmentEndDate: val?.enrollmentEndDate,
    }));

    const emptyFields = Object.keys(formData).filter(
      (key) =>
        !formData[key] &&
        key !== "state" &&
        key !== "isRecurring" &&
        key !== "recurringInput" &&
        key !== "amount" &&
        key !== "recurringAmount" &&
        key !== "updateRecurringDetails"
    );

    if (emptyFields.length > 0) {
      const emptyFieldNames = emptyFields.map((field) => field);
      toast.error(
        `Please fill in the following fields: ${emptyFieldNames.join(", ")}`
      );
      return;
    }
    if (validateForm()) {
      return;
    }

    const APIDATA = {
      payment_method: paymentFormData?.paymentMethod,
      payment_preprocessor: paymentFormData?.paymentProcessor,
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      address1: formData.address,
      address2: formData.address,
      city: formData?.city,
      state: formData?.state,
      zip: formData.zip,
      country: "USA",
      card_number: formData.creditCard,
      expiry: expiryDate,
      cvv: formData.cvv,
      memo: paymentFormData?.memo,
      // paymentDate: paymentFormData?.date,
      paymentDate: formattedDate,
      checkNumber: paymentFormData?.checkNumber,
      amountTendered: amountTendered,
      // RECURING DATA
      isRecurringEnabled: true,
      recurringDuration: formData?.isRecurring ? formData?.recurringInput : "",
      recurringAmount: parseInt(formData?.recurringAmount),
      classPaymentDetails: classPaymentDetailsArray,
      recurringDates: selectedCheckboxes,
      cardOnFile: paymentMethodStatus === "cardOnFile",
      cardOnFileId: selectedCardId,
    };

    const token = localStorage.getItem("access_token");
    setLoading(true);
    try {
      const apiResponse = await enableRecurringOrders(token, orderID, APIDATA)
        .then((res) => {
          if (res.status == 200) {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            setEnablePaymentModal(false);
            toast.success(res?.data?.data);
            fetchData();
          } else {
            toast.error(res?.data?.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.error, {
            toastId: "error",
          });
        });
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data);
    }
  };

  // STATES
  useEffect(() => {
    async function getAllStates() {
      const response = await getStates();
      setGetAllStateApi(response?.data?.getStates);
    }

    getAllStates();
  }, []);

  const toggleAccordion = (accordionId) => {
    setActiveAccordion((prevActiveAccordion) =>
      prevActiveAccordion === accordionId ? null : accordionId
    );
  };
  // CREDIT CARD PAYMENT
  const options = cardLists?.map((val) => ({
    id: val?.id,
    description: val?.description,
  }));

  const handleDeleteCard = (selectedId) => {
    // setCofID((prevCofID) => [...prevCofID, selectedId]);

    if (cardLists.length > 1) {
      setOpenDeleteCardFile(true);
      setDeleteCardID(selectedId);
      setSelectedOption(null);
      fetchData();
    } else {
      toast.error("You need to add new Credit Card to Delete!");
    }
  };
  // CARD ON FILE PAYMENT HANLDER
  const handleCardOnChange = async (e, classScheduleId, recIndex, recDate) => {
    e.preventDefault();
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = String(currentDate.getFullYear()).slice(-2);
    const formattedDate = `${month}/${day}/${year}`;
    const classPaymentDetailsArray = orderDetails?.map((val, index) => ({
      classSchedule: parseInt(val?.classSchedule, 10),
      amountOwed: parseFloat(val?.proRatedFee),
      ledgerDetailId: val?.ledgerDetailId,
      amountToPay: val?.amountToPay || 0,
      enrollmentStartDate: val?.enrollmentStartDate,
      enrollmentEndDate: val?.enrollmentEndDate,
    }));

    const APIDATA = {
      payment_method: 0,
      payment_preprocessor: paymentFormData?.paymentProcessor,
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      address1: formData.address,
      address2: formData.address,
      city: formData?.city,
      state: formData?.state,
      zip: formData.zip,
      country: "USA",
      card_number: formData.creditCard,
      expiry: expiryDate,
      cvv: formData.cvv,
      memo: paymentFormData?.memo,
      paymentDate: formattedDate,
      checkNumber: paymentFormData?.checkNumber,
      amountTendered: amountTendered,
      isRecurringEnabled: formData.isRecurring,
      recurringDuration: formData?.isRecurring ? formData?.recurringInput : "",
      recurringAmount: parseInt(formData?.recurringAmount),
      classPaymentDetails: classPaymentDetailsArray,

      // recurringDates: updatedRecurringDates[recIndex].dateArray,
      recurringDates:
        Object.keys(scheduleRows).length > 0
          ? [
              ...scheduleRows[recIndex],
              ...updatedRecurringDates[recIndex]?.dateArray,
            ]
          : updatedRecurringDates[recIndex].dateArray,
      cardOnFile: paymentMethodStatus === "cardOnFile",
      cardOnFileId: selectedCardId,
      cardDeleted: deleteCardOnFile?.deleteArray,
    };

    const isEmptyAmount = classPaymentDetailsArray.some(
      (detail) =>
        detail.amountToPay === undefined ||
        detail.amountToPay === null ||
        detail.amountToPay === ""
    );
    // Check if card on file is not selected
    const isCardOnFileNotSelected = !APIDATA.cardOnFileId && APIDATA.cardOnFile;
    if (isEmptyAmount || isCardOnFileNotSelected) {
      if (isEmptyAmount) {
        toast.error("Please enter an amount for all selected classes.", {
          toastId: "error",
        });
      }
      if (isCardOnFileNotSelected) {
        toast.error("Please select a card from the dropdown.", {
          toastId: "error",
        });
      }
      return;
    }

    const token = localStorage.getItem("access_token");
    if (!validateScheduleRows(scheduleRows)) {
      validateScheduleRows(scheduleRows);
    } else {
      setLoading(true);
      try {
        const apiResponse = await orderRecurringAPI(
          token,
          orderID,
          classScheduleId,
          APIDATA
        );
        if (apiResponse?.response?.data?.data !== null) {
          toast.success(apiResponse?.data?.data);
          setEnablePaymentModal(false);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setEnablePaymentModal(false);
          fetchData();
        } else {
          setLoading(false);
          toast.error(apiResponse?.response?.data?.error, {
            toastId: "error",
          });
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (PrefilledDetails && PrefilledDetails.cardOnFileId && !selectedCardId) {
      const preselectedOption = options?.find(
        (option) => option.id === PrefilledDetails.cardOnFileId
      );

      if (preselectedOption) {
        setSelectedCardId(preselectedOption.id);
        setSelectedOption(preselectedOption.description);
      }
    }
  }, [PrefilledDetails, options, selectedCardId]);

  // DATE INPUT FORMATTER

  const handleInputChange = (e, recIndex, dateIndex) => {
    if (LedgerApiData?.data?.recurringDates?.length > 0) {
      const updated = [...updatedRecurringDates];
      updated[recIndex].dateArray[dateIndex].currentlyPaying = e.target.checked;
      setUpdatedRecurringDates(updated);
    } else {
      const updated = [...updatedRecurringDates];
      updated[recIndex].dateArray[dateIndex].currentlyPaying = e.target.checked;
      setUpdatedRecurringDates(updated);
    }
  };

  useEffect(() => {
    if (
      orderApiData &&
      orderApiData.data &&
      orderApiData.data.recurringDates?.length
    ) {
      setUpdatedRecurringDates(orderApiData?.data?.recurringDates);
    }

    // Mapping of Data
    if (LedgerApiData?.data?.recurringDates?.length) {
      setRecurringApiData(orderApiData?.data?.recurringDates);
    } else {
      setRecurringApiData(orderApiData?.data?.recurringWeeklyDates);
    }
  }, [orderApiData]);

  // CARD ON FILE STATE MANAGEMENT
  const fetchCards = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await getAllCardForOrder(token, orderID);
      if (deleteCardOnFile.deleteArray.length > 0) {
        const filteredCards = response.data.filter(
          (card) => !deleteCardOnFile.deleteArray.includes(card.id)
        );
        setCardLists(filteredCards);
      } else {
        setCardLists(response?.data);
      }
      // setCardLists(response?.data);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  // COF & New Card Otions Handlers
  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (e, option) => {
    setSelectedCardId(option?.id);
    setSelectedOption(option?.description);
    setIsOpen(false);
  };

  useEffect(() => {
    fetchCards();
  }, [toggleState]);

  // REPAY HANDLER
  const repayHandler = async (e, val, cardType) => {
    e.preventDefault();

    let payload;
    const token = localStorage.getItem("access_token");
    if (paymentMethodStatus == "cardOnFile") {
      if (cardLists?.length == 0) {
        toast.error("Please Add Card Details!");
        return;
      } else {
        if (!selectedCardId) {
          toast.error(" Select the Cards to Proceed!");
          return;
        }
      }
    }

    if (paymentMethodStatus === "cardOnFile") {
      payload = {
        date: getCurrentDateYYMMDD(val?.date),
        paymentDate: getCurrentDateYYMMDD(val?.date),
        cardOnFile: true,
        cardOnFileId: selectedCardId,
        amount: val?.fee,
        id: val?.id,
      };
    } else {
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = String(currentDate.getFullYear()).slice(-2);

      const formattedDate = `${month}/${day}/${year}`;

      payload = {
        id: val?.id,
        date: val?.date,
        payment_method: paymentFormData?.paymentMethod,
        payment_preprocessor: paymentFormData?.paymentProcessor,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        address1: formData.address,
        address2: formData.address,
        city: formData?.city,
        state: formData?.state,
        zip: formData.zip,
        country: "USA",
        card_number: formData.creditCard,
        expiry: expiryDate,
        cvv: formData.cvv,
        memo: paymentFormData?.memo,
        // paymentDate: paymentFormData?.date,
        paymentDate: formattedDate,
        checkNumber: paymentFormData?.checkNumber,
        // RECURING DATA
        isRecurringEnabled: true,
        recurringDuration: formData?.isRecurring
          ? formData?.recurringInput
          : "",
        recurringAmount: parseInt(formData?.recurringAmount),
        amount: val?.fee,
      };
    }

    setLoading(true);
    try {
      const response = await orderRepay(token, payload);
      if (response.status === 200) {
        setLoading(false);
        toast.success(response?.data?.data);
        getModalDatafunction();
        fetchData();
      } else {
        setLoading(false);
        toast.error(response.response?.data?.error);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    }
  };

  // Recurring Dates
  const CustomDatePickerInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      className="adminform__input"
      placeholder="Select Expiry Date(MM/YY)"
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
    />
  ));

  const handleDateChanges = (date, recIndex, dateIndex) => {
    const updated = [...updatedRecurringDates];
    updated[recIndex].dateArray[dateIndex].date = date
      .toISOString()
      .split("T")[0];
    setUpdatedRecurringDates(updated);
  };

  const handleEnrollmentStartDateChange = (date, recIndex, dateIndex) => {
    const updated = [...updatedRecurringDates];
    updated[recIndex].dateArray[dateIndex].enrollmentStartDate = date
      .toISOString()
      .split("T")[0];
    setUpdatedRecurringDates(updated);
  };

  const handleEnrollmentEndDateChange = (date, recIndex, dateIndex) => {
    const updated = [...updatedRecurringDates];
    updated[recIndex].dateArray[dateIndex].enrollmentEndDate = date
      .toISOString()
      .split("T")[0];
    setUpdatedRecurringDates(updated);
  };
  const handleAmountChanges = (e, recIndex, dateIndex) => {
    const updated = [...updatedRecurringDates];
    updated[recIndex].dateArray[dateIndex].fee = e.target.value;
    setUpdatedRecurringDates(updated);
  };
  // MEMO
  const handleMemoChanges = (e, recIndex, dateIndex) => {
    const updated = [...updatedRecurringDates];
    updated[recIndex].dateArray[dateIndex].memo = e.target.value;
    setUpdatedRecurringDates(updated);
  };

  const handleStatusChange = async (
    event,
    index,
    classScheduleId,
    CLASS_TYPE
  ) => {
    const { value } = event.target;
    // Update the status for the specific index
    const updatedStatuses = [...statuses];
    updatedStatuses[index] = { classScheduleId, status: value };
    setStatuses(updatedStatuses);

    try {
      setLoading(true);
      const response = await recurringStatusAPI_ORDERS(
        updatedStatuses[index],
        token,
        orderID
      );
      if (response?.status === 200) {
        setLoading(false);
        toast.success(response?.data?.data);
        setEnablePaymentModal(false);
        fetchData();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response.data?.error);
    }
  };

  return (
    <>
      {paymentMethodStatus === "cardOnFile" ? (
        <>
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignContent: "center",
              alignItems: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <div className="flexbox">
              <div className="column">
                <div className="adminform__group">
                  <label htmlFor="" className="label">
                    Card on File <span className="important_field">*</span>
                  </label>
                  <div class="adminform__group adminform__graybox">
                    <div
                      className="custom-dropdown title--h6 "
                      ref={dropdownRef}
                    >
                      <div
                        className="selected-option adminform__input"
                        onClick={handleToggleDropdown}
                      >
                        {selectedOption ? (
                          <>
                            <span className="title--h6">{selectedOption}</span>
                            <span
                              className="delete-icon"
                              onClick={() => setSelectedOption(null)}
                            >
                              &#x2715;
                            </span>
                          </>
                        ) : (
                          <>
                            <span>Select Card</span>
                            <span className="dropdown-icon">
                              <AccordionIcon />
                            </span>
                          </>
                        )}
                      </div>
                      {isOpen && (
                        <div className="options">
                          {options?.map((option) => (
                            <div
                              key={option.id}
                              className="option adminform__group"
                              onClick={(e) => handleOptionClick(e, option)}
                            >
                              <span>{option?.description}</span>
                              <span
                                className="delete-icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteCard(option?.id);
                                }}
                              >
                                <img src={TrashIcon} alt="Delete" />
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Day Of Month &  Payment Checkbox  */}
          <div className="flexbox">
            <div className="column" style={{ maxWidth: "200px" }}>
              <label htmlFor="" className="label">
                Payment
              </label>
              <div className="adminform__group__grayBox">
                <label className="adminform-label " htmlFor="flexCheckChecked">
                  Recurring Payment
                </label>

                <input
                  className="aminform__input"
                  type="checkbox"
                  value=""
                  name="isChecked"
                  checked={formData?.isRecurring || true}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      isRecurring: !formData?.isRecurring,
                    });
                  }}
                />
              </div>
            </div>
            <div className="column">
              <label htmlFor="" className="label">
                Day of Month to Charge
              </label>

              <input
                style={{ maxWidth: "150px" }}
                type="number"
                className="adminform__input"
                name="recurringInput"
                placeholder="Recurring duration in months."
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    recurringInput: e.target.value,
                  });
                }}
                // value={"13"}
                value={formData.recurringInput}
              />
            </div>
          </div>

          <form onSubmit={handleCardOnChange}>
            <div className="">
              <div className="flexbox">
                {formData?.isRecurring || true ? (
                  <div className="">
                    <div
                        style={{ maxWidth: "750px !important" }}
                      className="flexbox"
                    >
                      <div className="column" style={{maxWidth:"400px", minWidth:"400px"}}>
                          <div className="adminform__group">
                            <label
                              htmlFor=""
                              className="label"
                              style={{
                                fontSize: "1rem",
                              }}
                            >
                                <p>Class name </p>
                            
                            </label>
                          </div>
                        </div>
                      <div
                        className="column"
                        style={{
                          minWidth: "170px ",
                           width:"250px"
                        }}
                      >
                        <div className="adminform__group">
                          <label
                            htmlFor=""
                            className="label"
                            style={{ fontSize: "1rem" }}
                          >
                            <p>Card Used</p>
                          </label>
                        </div>
                      </div>

                      <div
                        className="column"
                   
                      >
                        <div className="adminform__group">
                          <label
                            htmlFor=""
                            className="label"
                            style={{ fontSize: "1rem", padding: "10px" }}
                          >
                            <p>Recurring </p>
                          </label>
                        </div>
                      </div>
                      <div
                        className="column"
                       
                      >
                        <div className="adminform__group">
                          <label
                            htmlFor=""
                            className="label"
                            style={{ fontSize: "1rem", padding: "10px" }}
                          >
                            <p>Status</p>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flexbox"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      {orderApiData?.data?.recurringDates?.length > 0 ? (
                        orderApiData?.data?.recurringDates?.map(
                          (recDate, recIndex) => {
                            const accordionId = `accordion-${recIndex}`;
                            return (
                              <div
                                className=""
                                style={{ marginLeft: "5px" }}
                                key={recIndex}
                              >
                                <div className="flexbox">
                                  <div className="column"       style={{ minWidth: "200px", maxWidth:"200px" ,}}>
                                    <div className="adminform__group">
                                      <input
                                        type="text"
                                        className="adminform__input"
                                        value={recDate.className}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="column"
                                    style={{
                                      cursor: "pointer",
                                      maxWidth: "40px",
                                    }}
                                    onClick={() => toggleAccordion(accordionId)}
                                  >
                                    <AccordionIcon
                                      className={
                                        expandedAccordion === recIndex
                                          ? "rotateIcon"
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div className="column" style={{minWidth:"120px", maxWidth:"120px"}}>
                                    <button
                                      className="adminbtn adminbtn-blueOutline"
                                      style={{ minWidth: "120px" }}
                                      onClick={(e) => {
                                        handleCardOnChange(
                                          e,
                                          recDate.classScheduleId == undefined
                                            ? recDate?.campId
                                            : recDate?.classScheduleId,
                                          recIndex,
                                          recDate
                                        );
                                      }}
                                    >
                                      Save Recurring
                                    </button>
                                  </div>

                                  {recDate?.cardOnFileDescription ? (
                                    <div
                                      className="column"
                                    style={{width:"170px", minWidth:"200px"}}
                                    >
                                      <p className="label">
                                          {
                                            recDate?.cardOnFileDescription?.split(
                                              " "
                                            )[0]
                                          }
                                          <br />
                                          <span>
                                            {
                                              recDate?.cardOnFileDescription?.split(
                                                " "
                                              )[1]
                                            }
                                          </span>
                                          <br />
                                          <span>
                                            {
                                              recDate?.cardOnFileDescription?.split(
                                                " "
                                              )[2]
                                            }
                                          </span>
                                        </p>
                                    </div>
                                  ) : (
                                    <div
                                      className="column"
                                      style={{ minWidth: "170px !important" }}
                                    >
                                      <p
                                        className="label"
                                        style={{
                                          paddingTop: "15px",
                                          paddingLeft: "5%",
                                        }}
                                      >
                                        N/A
                                      </p>
                                    </div>
                                  )}

                                  {recDate?.currentRecurring !== undefined &&
                                  recDate?.totalRecurring !== undefined ? (
                                    <div className="column" style={{maxWidth:"150px", minWidth:"150px"}}>
                                      <p className="label">
                                        {recDate?.currentRecurring} of{" "}
                                        {recDate?.totalRecurring}
                                      </p>
                                    </div>
                                  ) : (
                                    <div
                                      className="column"
                                      style={{
                                        position: "absolute",
                                        left: "550px",
                                        top: "10px",
                                      }}
                                    >
                                      <p className="label">0 of 0</p>
                                    </div>
                                  )}
                                <div
                                  className="column"  
                                >
                                  <select
                                    name="status"
                                    id={`status-${recIndex}`}
                                    className="status_input adminform__input"
                                    style={{ width: "120px" }}
                                    value={
                                      (statuses[recIndex] &&
                                        statuses[recIndex].status) ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      handleStatusChange(
                                        e,
                                        recIndex,
                                        recDate.classScheduleId,
                                        "MONTHLY"
                                      )
                                    }
                                  >
                                    {recDate?.status ? (
                                      <option selected value={recDate?.status}>
                                        {recDate?.status}
                                      </option>
                                    ) : (
                                      <option value="" selected disabled>
                                        {" "}
                                        Select Status
                                      </option>
                                    )}
                                    {recDate?.status !== "Active" && (
                                      <option value="Active">Active</option>
                                    )}
                                    {recDate?.status !== "Pause" && (
                                      <option value="Pause">Pause</option>
                                    )}
                                    {recDate?.status !== "Complete" && (
                                      <option value="Complete">Complete</option>
                                    )}
                                  </select>
                                </div>
                                </div>

                                {/* Recurring Dates */}
                                {activeAccordion === accordionId && (
                                  <>
                                    {recDate?.dateArray.map((dates, index) => {
                                      let isDisabled = false;
                                      if (dates?.showRepay) {
                                        isDisabled = false;
                                      } else {
                                        if (dates?.prefilled == "1") {
                                          isDisabled = true;
                                        }
                                      }
                                      return (
                                        <div
                                          className="flexbox"
                                          key={index}
                                          style={{ marginLeft: "4%" }}
                                        >
                                          {/* CHEKCBOX  */}
                                          <div
                                            className="column"
                                            style={{ maxWidth: "30px", paddingTop:"30px" }}
                                          >
                                            <div className="adminform__group">
                                              <input
                                                type="checkbox"
                                                checked={dates?.currentlyPaying}
                                                disabled={
                                                  dates?.showRepay
                                                    ? true
                                                    : dates?.prefilled
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    e,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {/* MONTHLY COF - MEMO  */}
                                          <div className="column">
                                            <div className="adminform__group fee_input">
                                              <label
                                                htmlFor=""
                                                className="label"
                                              >
                                                Memo
                                              </label>
                                              <input
                                                className="adminform__input"
                                                name="memo"
                                                type="text"
                                                placeholder="MEMO"
                                                value={dates?.memo}
                                                disabled={isDisabled}
                                                onChange={(e) =>
                                                  handleMemoChanges(
                                                    e,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {/* ENROLLMMNET START DATE  */}
                                          <div className="column">
                                            <div className="adminform__group">
                                              <label
                                                htmlFor=""
                                                className="label"
                                              >
                                                Enrollment Start Date
                                              </label>
                                              <input
                                                type="date"
                                                disabled={isDisabled}
                                                value={
                                                  dates.enrollmentStartDate
                                                }
                                                name="enrollmentStartDate"
                                                className="adminform__input"
                                                onChange={(e) =>
                                                  handleEnrollmentStartDateChange(
                                                    e.target.valueAsDate,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {/* ENROLLMMNET END DATE  */}

                                          <div className="column">
                                            <div className="adminform__group">
                                              <label
                                                htmlFor=""
                                                className="label"
                                              >
                                                Enrollment End Date
                                              </label>
                                              <input
                                                name="enrollmentEndDate"
                                                type="date"
                                                disabled={isDisabled}
                                                value={dates.enrollmentEndDate}
                                                className="adminform__input"
                                                onChange={(e) =>
                                                  handleEnrollmentEndDateChange(
                                                    e.target.valueAsDate,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {/* RECURRING DATE  */}
                                          <div className="column">
                                            <div className="adminform__group">
                                              <label
                                                htmlFor=""
                                                className="label"
                                              >
                                                Recurring Date
                                              </label>
                                              <input
                                                name="date"
                                                type="date"
                                                disabled={isDisabled}
                                                value={dates?.date}
                                                className="adminform__input"
                                                onChange={(e) =>
                                                  handleDateChanges(
                                                    e.target.valueAsDate,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {dates?.campType && (
                                            <div className="column">
                                              <div className="adminform__group">
                                                <input
                                                  type="text"
                                                  value={dates?.campType}
                                                  disabled
                                                  className="adminform__input"
                                                />
                                              </div>
                                            </div>
                                          )}

                                          <div className="column">
                                            <div className="adminform__group fee_input">
                                              <span
                                                className="dollar"
                                                style={{ top: "32px" }}
                                              >
                                                $
                                              </span>
                                              <label
                                                htmlFor=""
                                                className="label"
                                              >
                                                Fee($)
                                              </label>
                                              <input
                                                className="adminform__input "
                                                type="number"
                                                value={dates?.fee}
                                                disabled={isDisabled}
                                                onChange={(e) =>
                                                  handleAmountChanges(
                                                    e,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>

                                          <div className="column">
                                            <p className="label">
                                              {dates?.status}
                                            </p>
                                          </div>

                                          {/* Show Repay Button   */}

                                          {dates?.showRepay && (
                                            <div className="column">
                                              <button
                                                className="adminbtn adminbtn-primary"
                                                onClick={(e) => {
                                                  repayHandler(e, dates, "cof");
                                                }}
                                              >
                                                Repay
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })}

                                    {scheduleRows?.[recIndex]?.map(
                                      (val, ind) => {
                                        return (
                                          <div
                                            className="flexbox"
                                            key={recIndex}
                                            style={{
                                              marginLeft: "2%",
                                              gap: "15px",
                                            }}
                                          >
                                            <div
                                              className="column"
                                              style={{ maxWidth: "50px" }}
                                            >
                                              <label
                                                htmlFor=""
                                                className="label"
                                              ></label>
                                              <input
                                                type="checkbox"
                                                placeholder="Rec"
                                                value={val.prefilled}
                                                name="currentlyPaying"
                                                onChange={(e) =>
                                                  handleScheduleInputChange(
                                                    recIndex,
                                                    ind,
                                                    "currentlyPaying",
                                                    e.target.checked
                                                  )
                                                }
                                              />
                                            </div>
                                            {/* DATE  */}
                                            <div className="column">
                                              <div className="adminform__group fee_input">
                                                <label
                                                  htmlFor=""
                                                  className="label"
                                                >
                                                  Memo
                                                </label>
                                                <input
                                                  className="adminform__input"
                                                  name="memo"
                                                  placeholder="Memo"
                                                  type="text"
                                                  value={val?.memo}
                                                  // disabled={isDisabled}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "memo",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            {/* STRT DATE  */}
                                            <div className="column">
                                              <div className="adminform__group fee_input">
                                                <label
                                                  htmlFor=""
                                                  className="label"
                                                >
                                                  Enrollment Start Date
                                                </label>

                                                <input
                                                  style={{ minWidth: "80px" }}
                                                  className="adminform__input"
                                                  name="enrollmentStartDate"
                                                  placeholder="Enrollment start Date"
                                                  type="date"
                                                  value={
                                                    val?.enrollmentStartDate
                                                  }
                                                  // disabled={isDisabled}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "enrollmentStartDate",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            {/* END DATE  */}
                                            <div className="column">
                                              <div className="adminform__group fee_input">
                                                <label
                                                  htmlFor=""
                                                  className="label"
                                                >
                                                  Enrollment End Date
                                                </label>

                                                <input
                                                  style={{ minWidth: "80px" }}
                                                  className="adminform__input"
                                                  name="enrollmentEndDate"
                                                  placeholder="Enrollment End Date"
                                                  type="date"
                                                  value={val?.enrollmentEndDate}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "enrollmentEndDate",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            {/* DATE  */}
                                            <div className="column">
                                              <div className="adminform__group">
                                                <label
                                                  htmlFor=""
                                                  className="label"
                                                >
                                                  Recurring Date
                                                </label>{" "}
                                                <br />
                                                <input
                                                  type="date"
                                                  className="adminform__input"
                                                  placeholder="date"
                                                  value={val.date}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "date",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>

                                            {/* FEE  */}

                                            <div className="column">
                                              <div className="adminform__group">
                                                <span
                                                  className="dollar"
                                                  style={{
                                                    left: "1px",
                                                    top: "30px",
                                                  }}
                                                >
                                                  $
                                                </span>
                                                <label
                                                  htmlFor=""
                                                  className="label"
                                                >
                                                  Fee($)
                                                </label>

                                                <input
                                                  type="number"
                                                  placeholder="Amount"
                                                  className="adminform__input"
                                                  value={val.fee}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "fee",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>

                                            {/* MEMO FOR COF  */}

                                            <div
                                              className="column"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                handleDeleteRows(recIndex, ind);
                                              }}
                                            >
                                              <DeleteIcon />
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                    <div className="">
                                      <button
                                        className="adminbtn adminbtn-primary"
                                        style={{ marginLeft: "10%" }}
                                        onClick={(e) => {
                                          handleAddSchedules(
                                            e,
                                            recDate,
                                            recIndex
                                          );
                                        }}
                                      >
                                        + Add Recurrings
                                      </button>
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          }
                        )
                      ) : (
                        <label className=" important_field">
                          No Monthly Recurrings Found !
                        </label>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* FOOTER  */}
            <div
              className="Admin-modal__footer"
              style={{ display: "flex", justifyContent: "right" }}
            >
              <div
                className="flexbox"
                style={{ display: "flex", alignItems: "center" }}
              >
                <button
                  className="adminbtn   adminbtn-blueOutline"
                  onClick={() => setEnablePaymentModal(false)}
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </>
      ) : (
        // NEW CARD
        <div className="column">
          <form onSubmit={handleSubmit}>
            <div className="recurring">
              <div className="flexbox">
                <div className="column">
                  <label className="label">
                    First Name <span className="important_field">*</span>
                  </label>
                  <div className="adminform__group">
                    <input
                      type="text"
                      class="adminform__input"
                      name="firstName"
                      value={formData?.firstName}
                      onChange={handleChange}
                      placeholder="First Name"
                    />
                    {formErrors.firstName && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors.firstName}
                      </div>
                    )}
                  </div>
                </div>

                <div className="column">
                  <div className="adminform__group">
                    <label className="label">
                      Last Name <span className="important_field">*</span>
                    </label>
                    <input
                      type="text"
                      class="adminform__input"
                      id="lastName"
                      name="lastName"
                      value={formData?.lastName}
                      onChange={handleChange}
                      placeholder="Last Name"
                    />
                    {formErrors.lastName && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors.lastName}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flexbox">
                <div className="column">
                  <label className="label">
                    Email <span className="important_field">*</span>{" "}
                  </label>
                  <div className="adminform__group">
                    <input
                      type="text"
                      class="adminform__input"
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={formData?.email}
                      onChange={handleChange}
                    />
                    {formErrors.email && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors.email}
                      </div>
                    )}
                  </div>
                </div>
                <div className="column">
                  <label className="label">
                    Address <span className="important_field">*</span>
                  </label>
                  <div className="adminform__group">
                    <input
                      type="text"
                      className="adminform__input"
                      name="address"
                      value={formData?.address}
                      onChange={handleChange}
                      placeholder="Address"
                    />
                    {formErrors.address && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors.address}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flexbox">
                <div className="column">
                  <label className="label">
                    City<span className="important_field">*</span>{" "}
                  </label>
                  <div className="adminform__group">
                    <input
                      type="text"
                      className="adminform__input"
                      name="city"
                      value={formData?.city}
                      onChange={handleChange}
                      placeholder="City"
                    />
                    {formErrors.city && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors.city}
                      </div>
                    )}
                  </div>
                </div>

                <div className="column">
                  <label className="label">
                    Zip <span className="important_field">*</span>
                  </label>
                  <div className="adminform__group">
                    <input
                      type="text"
                      className="adminform__input"
                      id="zip"
                      name="zip"
                      placeholder="Zip"
                      value={formData?.zip}
                      onChange={handleChange}
                      pattern="[0-9]*"
                    />
                    {formErrors.zip && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors.zip}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flexbox">
                <div className="column">
                  <label className="label">
                    Select States<span className="important_field">*</span>{" "}
                  </label>
                  <div className="adminform__group">
                    <select
                      name="state"
                      className="adminform__select"
                      value={formData?.state}
                      onChange={handleChange}
                    >
                      <option value="">Select State</option>
                      {getAllStateApi &&
                        getAllStateApi.map((state, index) => (
                          <option key={index} value={state.name}>
                            {state.name}
                          </option>
                        ))}
                    </select>

                    {formErrors.state && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors.state}
                      </div>
                    )}
                  </div>
                </div>
                <div className="column">
                  <label className="label">
                    Country <span className="important_field">*</span>
                  </label>
                  <div className="adminform__group">
                    <input
                      type="text"
                      className="adminform__input"
                      id="country"
                      disabled
                      name="country"
                      placeholder="Country"
                      value={"USA"}
                      onChange={handleChange}
                    />
                    {formErrors.country && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors.country}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <h1 className="title--h5" style={{ marginTop: "20px" }}>
                Credit Card & Billing Info :
              </h1>
              <hr />

              <div className="flexbox">
                <div className="column">
                  <label className="label">
                    Card Number <span className="important_field">*</span>{" "}
                  </label>
                  <div
                    className="adminform__group"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <br />
                    <input
                      type="text"
                      name="creditCard"
                      className="adminform__input"
                      placeholder="Enter card number"
                      onChange={handleChange}
                      value={formData.creditCard}
                    />

                    {getCardIcon()}

                    {formErrors.creditCard && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors?.creditCard}
                      </div>
                    )}
                  </div>
                </div>

                <div className="column">
                  <label className="label">
                    Expiry Date(MM/YY)<span className="important_field">*</span>{" "}
                  </label>

                  <DatePicker
                    style={{ width: "100%" }}
                    name="expirationDate"
                    selected={formData?.expirationDate}
                    onChange={(datee) => {
                      if (datee) {
                        const date = new Date(datee);

                        const month = date.getMonth() + 1;
                        const year = date.getFullYear().toString().slice(-2);

                        const mmYyFormat = `${
                          (month < 10 ? "0" : "") + month
                        }/${year}`;
                        setExpiryDate(mmYyFormat);

                        setFormData({ ...formData, expirationDate: datee });
                      }
                    }}
                    dateFormat="MM/yy"
                    showMonthYearPicker
                    placeholderText="Expiry Date(MM/YY)"
                    className="adminform__input"
                    minDate={new Date()}
                    customInput={<CustomDatePickerInput />}
                  />

                  {showAlert && (
                    <div className="alert alert-danger mt-2">
                      Expiration date should be after September 2023.
                    </div>
                  )}
                </div>
              </div>
              <div className="column">
                <label className="label">
                  CVV <span className="important_field">*</span>
                </label>
                <div className="adminform__group" style={{ maxWidth: "220px" }}>
                  <input
                    type="text"
                    className="adminform__input"
                    maxLength={"4"}
                    id="cvv"
                    name="cvv"
                    placeholder="CVV"
                    value={formData.cvv}
                    onChange={handleChange}
                    pattern="[0-9]*"
                  />
                  {formErrors.cvv && (
                    <div className="error-message" style={{ color: "red" }}>
                      {formErrors?.cvv}
                    </div>
                  )}
                </div>
              </div>
              {/* Recurring Dates */}
              <div className="flexbox">
                {formData?.isRecurring || true ? (
                  <div className=""style={{ minWidth: "680px" }}>
                       <div
                        style={{ maxWidth: "750px !important" }}
                      className="flexbox"
                    >
                      <div className="column" style={{maxWidth:"400px", minWidth:"400px"}}>
                          <div className="adminform__group">
                            <label
                              htmlFor=""
                              className="label"
                              style={{
                                fontSize: "1rem",
                              }}
                            >
                                <p>Class name </p>
                            
                            </label>
                          </div>
                        </div>
                      <div
                        className="column"
                        style={{
                          minWidth: "170px ",
                           width:"250px"
                        }}
                      >
                        <div className="adminform__group">
                          <label
                            htmlFor=""
                            className="label"
                            style={{ fontSize: "1rem" }}
                          >
                            <p>Card Used</p>
                          </label>
                        </div>
                      </div>

                      <div
                        className="column"
                   
                      >
                        <div className="adminform__group">
                          <label
                            htmlFor=""
                            className="label"
                            style={{ fontSize: "1rem", padding: "10px" }}
                          >
                            <p>Recurring </p>
                          </label>
                        </div>
                      </div>
                      <div
                        className="column"
                       
                      >
                        <div className="adminform__group">
                          <label
                            htmlFor=""
                            className="label"
                            style={{ fontSize: "1rem", padding: "10px" }}
                          >
                            <p>Status</p>
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* REURRING DATAS  */}

                    <div
                      className="flexbox"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      {orderApiData?.data?.recurringDates?.length > 0 ? (
                        orderApiData?.data?.recurringDates?.map(
                          (recDate, recIndex) => {
                            const accordionId = `accordion-${recIndex}`;
                            return (
                              <div
                                className=""
                                style={{ marginLeft: "5px" }}
                                key={recIndex}
                              >
                                <div className="flexbox">
                                  <div className="column"       style={{ minWidth: "200px", maxWidth:"200px" ,}}>
                                    <div className="adminform__group">
                                      <input
                                        type="text"
                                        className="adminform__input"
                                        value={recDate.className}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="column"
                                    style={{
                                      cursor: "pointer",
                                      maxWidth: "40px",
                                    }}
                                    onClick={() => toggleAccordion(accordionId)}
                                  >
                                    <AccordionIcon
                                      className={
                                        expandedAccordion === recIndex
                                          ? "rotateIcon"
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div className="column" style={{minWidth:"120px", maxWidth:"120px"}}>
                                    <button
                                      className="adminbtn adminbtn-blueOutline"
                                      style={{ minWidth: "120px" }}
                                      onClick={(e) => {
                                        handleCardOnChange(
                                          e,
                                          recDate.classScheduleId == undefined
                                            ? recDate?.campId
                                            : recDate?.classScheduleId,
                                          recIndex,
                                          recDate
                                        );
                                      }}
                                    >
                                      Save Recurring
                                    </button>
                                  </div>

                                  {recDate?.cardOnFileDescription ? (
                                    <div
                                      className="column"
                                    style={{width:"170px", minWidth:"200px"}}
                                    >
                                      <p className="label">
                                          {
                                            recDate?.cardOnFileDescription?.split(
                                              " "
                                            )[0]
                                          }
                                          <br />
                                          <span>
                                            {
                                              recDate?.cardOnFileDescription?.split(
                                                " "
                                              )[1]
                                            }
                                          </span>
                                          <br />
                                          <span>
                                            {
                                              recDate?.cardOnFileDescription?.split(
                                                " "
                                              )[2]
                                            }
                                          </span>
                                        </p>
                                    </div>
                                  ) : (
                                    <div
                                      className="column"
                                      style={{ minWidth: "170px !important" }}
                                    >
                                      <p
                                        className="label"
                                        style={{
                                          paddingTop: "15px",
                                          paddingLeft: "5%",
                                        }}
                                      >
                                        N/A
                                      </p>
                                    </div>
                                  )}

                                  {recDate?.currentRecurring !== undefined &&
                                  recDate?.totalRecurring !== undefined ? (
                                    <div className="column" style={{maxWidth:"150px", minWidth:"150px"}}>
                                      <p className="label">
                                        {recDate?.currentRecurring} of{" "}
                                        {recDate?.totalRecurring}
                                      </p>
                                    </div>
                                  ) : (
                                    <div
                                      className="column"
                                      style={{
                                        position: "absolute",
                                        left: "550px",
                                        top: "10px",
                                      }}
                                    >
                                      <p className="label">0 of 0</p>
                                    </div>
                                  )}
                                <div
                                  className="column"  
                                >
                                  <select
                                    name="status"
                                    id={`status-${recIndex}`}
                                    className="status_input adminform__input"
                                    style={{ width: "120px" }}
                                    value={
                                      (statuses[recIndex] &&
                                        statuses[recIndex].status) ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      handleStatusChange(
                                        e,
                                        recIndex,
                                        recDate.classScheduleId,
                                        "MONTHLY"
                                      )
                                    }
                                  >
                                    {recDate?.status ? (
                                      <option selected value={recDate?.status}>
                                        {recDate?.status}
                                      </option>
                                    ) : (
                                      <option value="" selected disabled>
                                        {" "}
                                        Select Status
                                      </option>
                                    )}
                                    {recDate?.status !== "Active" && (
                                      <option value="Active">Active</option>
                                    )}
                                    {recDate?.status !== "Pause" && (
                                      <option value="Pause">Pause</option>
                                    )}
                                    {recDate?.status !== "Complete" && (
                                      <option value="Complete">Complete</option>
                                    )}
                                  </select>
                                </div>
                                </div>

                                {/* Recurring Dates */}
                                {activeAccordion === accordionId && (
                                  <>
                                    {recDate?.dateArray.map((dates, index) => {
                                      let isDisabled = false;
                                      if (dates?.showRepay) {
                                        isDisabled = false;
                                      } else {
                                        if (dates?.prefilled == "1") {
                                          isDisabled = true;
                                        }
                                      }
                                      return (
                                        <div
                                          className="flexbox"
                                          key={index}
                                          style={{ marginLeft: "4%" }}
                                        >
                                          {/* CHEKCBOX  */}
                                          <div
                                            className="column"
                                            style={{ maxWidth: "30px", paddingTop:"30px" }}
                                          >
                                            <div className="adminform__group">
                                              <input
                                                type="checkbox"
                                                checked={dates?.currentlyPaying}
                                                disabled={
                                                  dates?.showRepay
                                                    ? true
                                                    : dates?.prefilled
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    e,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {/* MONTHLY COF - MEMO  */}
                                          <div className="column">
                                            <div className="adminform__group fee_input">
                                              <label
                                                htmlFor=""
                                                className="label"
                                              >
                                                Memo
                                              </label>
                                              <input
                                                className="adminform__input"
                                                name="memo"
                                                type="text"
                                                placeholder="MEMO"
                                                value={dates?.memo}
                                                disabled={isDisabled}
                                                onChange={(e) =>
                                                  handleMemoChanges(
                                                    e,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {/* ENROLLMMNET START DATE  */}
                                          <div className="column">
                                            <div className="adminform__group">
                                              <label
                                                htmlFor=""
                                                className="label"
                                              >
                                                Enrollment Start Date
                                              </label>
                                              <input
                                                type="date"
                                                disabled={isDisabled}
                                                value={
                                                  dates.enrollmentStartDate
                                                }
                                                name="enrollmentStartDate"
                                                className="adminform__input"
                                                onChange={(e) =>
                                                  handleEnrollmentStartDateChange(
                                                    e.target.valueAsDate,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {/* ENROLLMMNET END DATE  */}

                                          <div className="column">
                                            <div className="adminform__group">
                                              <label
                                                htmlFor=""
                                                className="label"
                                              >
                                                Enrollment End Date
                                              </label>
                                              <input
                                                name="enrollmentEndDate"
                                                type="date"
                                                disabled={isDisabled}
                                                value={dates.enrollmentEndDate}
                                                className="adminform__input"
                                                onChange={(e) =>
                                                  handleEnrollmentEndDateChange(
                                                    e.target.valueAsDate,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {/* RECURRING DATE  */}
                                          <div className="column">
                                            <div className="adminform__group">
                                              <label
                                                htmlFor=""
                                                className="label"
                                              >
                                                Recurring Date
                                              </label>
                                              <input
                                                name="date"
                                                type="date"
                                                disabled={isDisabled}
                                                value={dates?.date}
                                                className="adminform__input"
                                                onChange={(e) =>
                                                  handleDateChanges(
                                                    e.target.valueAsDate,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {dates?.campType && (
                                            <div className="column">
                                              <div className="adminform__group">
                                                <input
                                                  type="text"
                                                  value={dates?.campType}
                                                  disabled
                                                  className="adminform__input"
                                                />
                                              </div>
                                            </div>
                                          )}

                                          <div className="column">
                                            <div className="adminform__group fee_input">
                                              <span
                                                className="dollar"
                                                style={{ top: "32px" }}
                                              >
                                                $
                                              </span>
                                              <label
                                                htmlFor=""
                                                className="label"
                                              >
                                                Fee($)
                                              </label>
                                              <input
                                                className="adminform__input "
                                                type="number"
                                                value={dates?.fee}
                                                disabled={isDisabled}
                                                onChange={(e) =>
                                                  handleAmountChanges(
                                                    e,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>

                                          <div className="column">
                                            <p className="label">
                                              {dates?.status}
                                            </p>
                                          </div>

                                          {/* Show Repay Button   */}

                                          {dates?.showRepay && (
                                            <div className="column">
                                              <button
                                                className="adminbtn adminbtn-primary"
                                                onClick={(e) => {
                                                  repayHandler(e, dates, "cof");
                                                }}
                                              >
                                                Repay
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })}

                                    {scheduleRows?.[recIndex]?.map(
                                      (val, ind) => {
                                        return (
                                          <div
                                            className="flexbox"
                                            key={recIndex}
                                            style={{
                                              marginLeft: "2%",
                                              gap: "15px",
                                            }}
                                          >
                                            <div
                                              className="column"
                                              style={{ maxWidth: "50px" }}
                                            >
                                              <label
                                                htmlFor=""
                                                className="label"
                                              ></label>
                                              <input
                                                type="checkbox"
                                                placeholder="Rec"
                                                value={val.prefilled}
                                                name="currentlyPaying"
                                                onChange={(e) =>
                                                  handleScheduleInputChange(
                                                    recIndex,
                                                    ind,
                                                    "currentlyPaying",
                                                    e.target.checked
                                                  )
                                                }
                                              />
                                            </div>
                                            {/* DATE  */}
                                            <div className="column">
                                              <div className="adminform__group fee_input">
                                                <label
                                                  htmlFor=""
                                                  className="label"
                                                >
                                                  Memo
                                                </label>
                                                <input
                                                  className="adminform__input"
                                                  name="memo"
                                                  placeholder="Memo"
                                                  type="text"
                                                  value={val?.memo}
                                                  // disabled={isDisabled}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "memo",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            {/* STRT DATE  */}
                                            <div className="column">
                                              <div className="adminform__group fee_input">
                                                <label
                                                  htmlFor=""
                                                  className="label"
                                                >
                                                  Enrollment Start Date
                                                </label>

                                                <input
                                                  style={{ minWidth: "80px" }}
                                                  className="adminform__input"
                                                  name="enrollmentStartDate"
                                                  placeholder="Enrollment start Date"
                                                  type="date"
                                                  value={
                                                    val?.enrollmentStartDate
                                                  }
                                                  // disabled={isDisabled}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "enrollmentStartDate",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            {/* END DATE  */}
                                            <div className="column">
                                              <div className="adminform__group fee_input">
                                                <label
                                                  htmlFor=""
                                                  className="label"
                                                >
                                                  Enrollment End Date
                                                </label>

                                                <input
                                                  style={{ minWidth: "80px" }}
                                                  className="adminform__input"
                                                  name="enrollmentEndDate"
                                                  placeholder="Enrollment End Date"
                                                  type="date"
                                                  value={val?.enrollmentEndDate}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "enrollmentEndDate",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            {/* DATE  */}
                                            <div className="column">
                                              <div className="adminform__group">
                                                <label
                                                  htmlFor=""
                                                  className="label"
                                                >
                                                  Recurring Date
                                                </label>{" "}
                                                <br />
                                                <input
                                                  type="date"
                                                  className="adminform__input"
                                                  placeholder="date"
                                                  value={val.date}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "date",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>

                                            {/* FEE  */}

                                            <div className="column">
                                              <div className="adminform__group">
                                                <span
                                                  className="dollar"
                                                  style={{
                                                    left: "1px",
                                                    top: "30px",
                                                  }}
                                                >
                                                  $
                                                </span>
                                                <label
                                                  htmlFor=""
                                                  className="label"
                                                >
                                                  Fee($)
                                                </label>

                                                <input
                                                  type="number"
                                                  placeholder="Amount"
                                                  className="adminform__input"
                                                  value={val.fee}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "fee",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>

                                            {/* MEMO FOR COF  */}

                                            <div
                                              className="column"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                handleDeleteRows(recIndex, ind);
                                              }}
                                            >
                                              <DeleteIcon />
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                    <div className="">
                                      <button
                                        className="adminbtn adminbtn-primary"
                                        style={{ marginLeft: "10%" }}
                                        onClick={(e) => {
                                          handleAddSchedules(
                                            e,
                                            recDate,
                                            recIndex
                                          );
                                        }}
                                      >
                                        + Add Recurrings
                                      </button>
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          }
                        )
                      ) : (
                        <label className=" important_field">
                          No Monthly Recurrings Found !
                        </label>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* Footer  */}
            <div
              className="Admin-modal__footer"
              style={{ display: "flex", justifyContent: "right" }}
            >
              <div
                className="flexbox"
                style={{ display: "flex", alignItems: "center" }}
              >
                <button
                  className="adminbtn adminbtn  adminbtn-blueOutline"
                  onClick={() => setEnablePaymentModal(false)}
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default CreditCardPayment;
