import React from "react";

function PackageComponent({ sectionData }) {
  return (
    <div className="birthdayPackages">
      <h3 className="title title--extrabold">Packages</h3>
      <div className="birthdayPackages__list">
        <div className="birthdayPackages__list__col">
          <div className="packageCard">
            <h3 className="packageCard__title" style={{ marginRight: "50px" }}>
              Basic
            </h3>
            {sectionData?.map(
              (item) =>
                item?.section_name === "Package 1" && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item?.descriptionSection?.description,
                    }}
                  ></p>
                )
            )}

            <div className="packageCard__price">
              <div className="form__group">
                <div className="form__checkbox">
                  <div className="form__checkbox__option">
                    
                    <div className="form__checkbox__labelText">
                      {sectionData?.map(
                        (item) =>
                          item?.section_name === "Package 1" && (
                            <h1
                              style={{ textAlign: "center" }}
                              className="form__checkbox__labelText"
                            >
                              ${item?.price_package}
                            </h1>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="birthdayPackages__list__col">
          <div className="packageCard">
            <h3 className="packageCard__title">All-Inclusive</h3>
            {sectionData?.map(
              (item) =>
                item?.section_name === "Package 2" && (
                  <p
                    style={{ marginBottom: "30px" }}
                    dangerouslySetInnerHTML={{
                      __html: item?.descriptionSection?.description,
                    }}
                  ></p>
                )
            )}

            <div className="packageCard__price">
              <div className="form__group">
                <div className="form__checkbox">
                  <div className="form__checkbox__option">
                    {/* {sectionData?.map(
                      (item) =>
                        item?.section_name === "Package 2" && (
                          <div>
                            <input
                              type="radio"
                              name="package"
                              value={item?.id}
                              id="basicPackage"
                              className="form__checkbox__input form__checkbox__input--withoutlabel"
                              onChange={handlePackageChange}
                              // checked={selectedPackage === "1275"}
                            />
                            <label
                              htmlFor="RecurringPayment"
                              className="form__checkbox__label"
                            ></label>
                          </div>
                        )
                    )} */}
                    <div className="form__checkbox__labelText">
                      {sectionData?.map(
                        (item) =>
                          item?.section_name === "Package 2" && (
                            <h1 className="form__checkbox__labelText">
                              ${item?.price_package}
                            </h1>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="birthdayPackages__list__col">
          <div className="packageCard">
            <h3 className="packageCard__title">Ultimate</h3>

            {sectionData?.map(
              (item) =>
                item?.section_name === "Package 3" && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item?.descriptionSection?.description,
                    }}
                  ></p>
                )
            )}

            <div className="packageCard__price">
              <div className="form__group">
                <div className="form__checkbox">
                  <div className="form__checkbox__option">
                    {/* {sectionData?.map(
                      (item) =>
                        item?.section_name === "Package 3" && (
                          <div>
                            <input
                              type="radio"
                              name="package"
                              value={item?.id}
                              id="basicPackage"
                              className="form__checkbox__input form__checkbox__input--withoutlabel"
                              onChange={handlePackageChange}
                              // checked={selectedPackage === "1875"}
                            />
                            <label
                              htmlFor="RecurringPayment"
                              className="form__checkbox__label"
                            ></label>
                          </div>
                        )
                    )} */}

                    <div className="form__checkbox__labelText">
                      {sectionData?.map(
                        (item) =>
                          item?.section_name === "Package 3" && (
                            <h1 className="form__checkbox__labelText">
                              ${item?.price_package}
                            </h1>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackageComponent;
