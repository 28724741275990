import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close-icon.svg";
import Select from "react-select";
import {
  customStyles,
  dateFormatterWithoutNew,
  formatDateNewMMDDYYY,
  formatDateWithoutNewPrefilled,
  timeFormatter,
} from "../../adminUtility";
import getPrograms from "../../adminApi/categories";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { format, isValid } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as DeleteIcon } from "../../assets/images/icons/deleteRowIcon.svg";
import {
  addStudent,
  getClassScheduleDetails,
  getMontlyClassForSwitch,
  getStudentDetailsAPI,
  getWeeklyClassForSwitch,
  getclass,
} from "../../adminApi/clients";
import LoaderComponent from "../LoaderComponent.jsx";
import ScheduleTable from "./ScheduleTable";
import { AgeCalculationFunction } from "../../../Parent/utility/postRequests.js";
import { getWeeklyClassByID } from "../../adminApi/addClassList.js";
import CampTable from "./CampTable.jsx";
import { updateStudent } from "../../adminApi/users.js";
import { deleteClassFromStudent } from "../../adminApi/classApi.js";
import ShowTeachers from "../Classess/ShowTeachers.jsx";
import SwtichClassModal from "./SwtichClassModal.jsx";
import { isAfter } from "date-fns"; // Import necessary functions from date-fns

const determineFileType = (base64) => {
  const mime = base64.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  return mime ? mime[1] : "";
};
const FileUpload = ({ uploadedFile, type }) => {
  const [fileType, setFileType] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");

  useEffect(() => {
    if (uploadedFile) {
      const fileType = determineFileType(uploadedFile);
      setFileType(fileType);

      if (fileType.includes("pdf")) {
        const byteString = atob(uploadedFile.split(",")[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      }
    }
  }, [uploadedFile]);

  return (
    <div>
      {uploadedFile && (
        <div className="preview">
          {fileType.includes("image") ? (
            <img
              style={{ width: "250px", height: "100%" }}
              src={uploadedFile}
              alt="Uploaded File"
            />
          ) : fileType.includes("pdf") ? (
            <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
              Click to View the Pdf
            </a>
          ) : (
            <p></p>
          )}
        </div>
      )}
    </div>
  );
};

function AddStudentModal({
  openAddNewUser,
  setAddNewUser,
  type,
  data,
  fetchData,
}) {
  // STATES
  const [startDate, setStartDate] = useState(new Date());
  const token = localStorage.getItem("access_token");
  const [categoryLists, setCategorylist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [classId, setClassId] = useState();
  const [classList, setClassList] = useState([]);
  const [isCampClass, setIsCampClass] = useState(false);
  const [classScheduleData, setClassScheduleData] = useState();
  const [campScheduleData, setCampScheduleData] = useState();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    allergies: "",
    class: "",
    category: null,
    dob: "",
    gender: "",
    parent_signature: "",
    doctor_phone: "",
    doctor_name: "",
    emergency_name: "",
    emergency_phone: "",
    currently_attending: "",
    vaccination: false,
    comments: "",
  });
  const [rows, setRows] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedSchedules, setSelectedSchedules] = useState([]);
  const [selectedCampSchedules, setSelectedCampSchedules] = useState([]);
  const [campId, setCampId] = useState();
  const [selectedCampId, setSelectedCampId] = useState([]);
  const [uploadedFile, setUploadedFile] = useState();
  const [showSchedules, setShowSchedules] = useState();
  const [selectedStudentSchedules, setSelectedStudentSchedules] = useState();
  const [tempSelectedDate, setTempSelectedDate] = useState();
  const [OpenSwitchModal, setOpenSwitchModal] = useState({
    isOpen: false,
    data: "",
    val: "",
    studentId: data?.id,
  });
  const [prefilledData, setPrefilledData] = useState();

  // FUNCTION
  const validateForm = () => {
    const newErrors = {};
    const emptyFields = [];

    const nameRegex = /^[a-zA-Z\s]*$/;

    // Validate first_name
    if (!formData.first_name.trim()) {
      newErrors.first_name = "First name is required";
      emptyFields.push("First name");
    } else if (!nameRegex.test(formData.first_name.trim())) {
      newErrors.first_name = "First name must contain only letters and spaces";
    }

    // Validate last_name
    if (!formData.last_name.trim()) {
      newErrors.last_name = "Last name is required";
      emptyFields.push("Last name");
    } else if (!nameRegex.test(formData.last_name.trim())) {
      newErrors.last_name = "Last name must contain only letters and spaces";
    }

    // Validate allergies
    if (!formData.allergies.trim()) {
      newErrors.allergies = "Allergies is required";
      emptyFields.push("Allergies");
    }

    // Validate dob
    if (!formData?.dob.trim()) {
      newErrors.dob = "DOB is required";
      emptyFields.push("DOB");
    } else {
      const today = new Date();
      const selectedDate = new Date(formData.dob);
      if (selectedDate > today) {
        newErrors.dob = "DOB cannot be a future date";
      }
    }

    // Validate gender
    if (!formData.gender) {
      newErrors.gender = "Gender is required";
      emptyFields.push("Gender");
    }

    // If there are empty fields, show toast message
    if (emptyFields.length > 0) {
      if (emptyFields.length === 1) {
        toast.error(`Enter the required field: ${emptyFields[0]}`);
      } else {
        toast.error(
          `Enter the following required fields: ${emptyFields.join(", ")}`
        );
      }
      return false;
    }

    setErrors(newErrors);
    return true;
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type, files } = e.target;

    if (files && files.length > 0 && name === "parent_signature") {
      const file = files[0];
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "application/pdf",
      ];

      if (!allowedTypes.includes(file.type)) {
        toast.error("Please upload a valid image file (JPEG, PNG, GIF, PDF)", {
          toastId: "error",
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setUploadedFile(reader?.result);

        setFormData({
          ...formData,
          [name]: reader?.result,
        });
      };
      reader.readAsDataURL(file);
    } else if (name === "dob") {
      // Prevent setting future dates for dob
      const selectedDate = new Date(value);
      const currentDate = new Date();
      if (selectedDate > currentDate) {
        toast.error("DOB cannot be a future dates");
        return;
      }
    } else if (name === "vaccination") {
      const inputValue = type === "checkbox" ? checked : value;
      setFormData({ ...formData, [name]: inputValue });
    }

    setFormData({ ...formData, [name]: value });
    if (name === "dob" || name === "gender") {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleCategoryChange = (selectedOption) => {
    setCategoryId(selectedOption?.value);
    setFormData({ ...formData, category: selectedOption });
  };

  const handleClassChange = (selectedOption) => {
    if (selectedOption?.period == "Weekly") {
      setIsCampClass(true);
      setCampId(selectedOption?.value);
    } else {
      // setIsCampClass(false);
      setClassId(selectedOption?.value);
      setFormData({ ...formData, class: selectedOption?.value });
    }
  };

  // SUBMIT HANLDER
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "application/pdf",
      ];
      const file = document.getElementById("parent_signature").files[0];

      if (file && !allowedTypes.includes(file.type)) {
        toast.error("Please upload a valid image file (JPEG, PNG, GIF, PDF)", {
          toastId: "error",
        });
        return;
      }
      // UNIQUE IDS FOR THAT
      const uniqueClassScheduleIds = [...new Set(classScheduleIds)];

      const payload = {
        studentDetails: {
          first_name: formData.first_name,
          last_name: formData.last_name,
          age: AgeCalculationFunction(formData?.dob),
          dob: formData.dob,
          gender: formData.gender,
          allergies: formData.allergies,
          doctor_name: formData.doctor_name,
          doctor_phone: formData.doctor_phone,
          emergency_name: formData.emergency_name,
          emergency_phone: formData.emergency_phone,
          vaccination: formData.vaccination ? "1" : "0",
          parent_signature: formData.parent_signature,
          comments: formData.comments,
        },
        schoolAttendingWithDetails: rows?.map((val) => ({
          name: val?.name,
          attending_with: val?.attended_with,
        })),
        // classSchedules: classScheduleIds,
        classSchedules: uniqueClassScheduleIds,
        weeklyClassSchedules: selectedCampId || [],
      };

      setLoading(true);
      if (type == "Add") {
        try {
          const response = await addStudent(data, payload, token);
          if (response) {
            setLoading(false);
            if (response?.data) {
              toast.success(response?.data?.data);
              setLoading(false);
              setAddNewUser(false);
              const jsonString = JSON.stringify(response?.data?.ids);
              localStorage.setItem("Ledger_Client", jsonString);
              fetchData();
            } else {
              setLoading(false);
              toast.error(response?.data?.error);
              const jsonString = JSON.stringify(response?.data?.ids);
              localStorage.setItem("Ledger_Client", jsonString);
              fetchData();
            }
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          toast.error(error?.response?.data?.error, {
            toastId: "error",
          });
        }
      } else {
        try {
          const response = await updateStudent(payload, token, data?.id);
          if (response == undefined) {
            setLoading(false);
            return;
          }
          if (response?.response?.data?.data !== null) {
            setLoading(false);
            toast.success(response?.data);
            setLoading(false);
            setAddNewUser(false);
            const jsonString = JSON.stringify(response?.ids);
            localStorage.setItem("Ledger_Client", jsonString);
            fetchData();
          } else {
            toast.error(response?.response?.data?.error);
            return;
          }
        } catch (error) {
          setLoading(false);
          toast.error(error?.response?.data?.error, {
            toastId: "error",
          });
          return;
        }
      }
    }
  };

  // GET ALL CATEGORIES
  useEffect(() => {
    const getAllCategoryData = async () => {
      try {
        const sortKey = {
          isAsc: true,
          sortId: "1",
        };
        const formData = {
          name: "",
          categoryName: "",
        };
        const token = localStorage.getItem("access_item");
        const response = await getPrograms(
          token,
          formData,
          0,
          sortKey,
          "300000"
        );
        setCategorylist(response?.data || []);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    getAllCategoryData();
  }, []);

  // GET CLASSES FOR CATEGORY
  useEffect(() => {
    const classesByCategory = async () => {
      try {
        const response = await getclass(categoryId, token);
        setClassList(response);
      } catch (error) { }
    };
    classesByCategory();
  }, [categoryId]);

  useEffect(() => {
    const getClassScheduleData = async () => {
      try {
        const response = await getClassScheduleDetails(
          parseInt(classId),
          token
        );
        setClassScheduleData(response);
      } catch (error) { }
    };

    if (classId !== undefined) {
      getClassScheduleData();
    }
  }, [classId]);

  // In the useEffect hook fetching camp schedule data
  useEffect(() => {
    const getCampScheduleData = async () => {
      try {
        const response = await getWeeklyClassByID(token, campId);
        setCampScheduleData(response?.data?.getWeeklyClass);
      } catch (error) { }
    };

    if (isCampClass && campId !== undefined) {
      getCampScheduleData();
    }
  }, [campId, isCampClass]);

  // SCHEDULE ID Into Array
  const getClassScheduleIds = () => {
    let classScheduleIds = [];

    selectedSchedules.forEach((schedule) => {
      if (schedule.class_schedules && Array.isArray(schedule.class_schedules)) {
        schedule.class_schedules.forEach((classSchedule) => {
          classScheduleIds.push(classSchedule.classScheduleId);
        });
      }
    });

    // Return the array of class schedule ids
    return classScheduleIds;
  };

  const classScheduleIds = getClassScheduleIds();

  // Prefilled Data
  const getStudentINFO = async () => {
    try {
      const response = await getStudentDetailsAPI(token, data?.id);
      setPrefilledData(response?.getStudentDetailsById);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (type == "Edit") {
      getStudentINFO();
    }
  }, [type]);

  useEffect(() => {
    if (prefilledData?.id) {
      setFormData({
        first_name: prefilledData?.first_name,
        last_name: prefilledData?.last_name,
        allergies: prefilledData?.allergies,
        class: "",
        category: null,
        dob: prefilledData?.dob,
        gender: prefilledData?.gender,
        parent_signature: prefilledData?.parent_signature,
        doctor_phone: prefilledData?.doctor_phone,
        doctor_name: prefilledData?.doctor_name,
        emergency_name: prefilledData?.emergency_name,
        emergency_phone: prefilledData?.emergency_phone,
        vaccination: prefilledData?.vaccination == 1 ? true : false,
        comments: prefilledData?.comments,
      });

      if (prefilledData?.school_attending_withs) {
        const preFilledRows = prefilledData?.school_attending_withs?.map(
          (attendingWith) => ({
            id: attendingWith?.id,
            name: attendingWith?.name,
            attended_with: attendingWith?.attending_with,
          })
        );
        setRows(preFilledRows);
      }
    }
  }, [prefilledData?.id]);

  // Currently Attending with
  const addRow = () => {
    setRows([...rows, { id: new Date().getTime() }]);
  };

  const deleteRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleRowChange = (e, id) => {
    const { name, value } = e.target;
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const deleteClassHandler = async (studentID, classScheduledId, period) => {
    setLoading(true);
    try {
      const response = await deleteClassFromStudent(
        studentID,
        classScheduledId,
        period,
        token
      );
      toast.success(response?.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      // setAddNewUser(false);
      getStudentINFO();
    } catch (error) {
      setLoading(false);
    }
  };

  // CLASS FOR MONTHLY (SWITCHING OF CLASS)
  const classSwitchingAPIFunction = async (val) => {
    try {
      if (val?.period === "Monthly") {
        const response = await getMontlyClassForSwitch(token);
        setOpenSwitchModal({
          isOpen: true,
          data: response,
          val: val,
          studentId: prefilledData?.id,
        });
      } else {
        const response = await getWeeklyClassForSwitch(token);

        setOpenSwitchModal({
          isOpen: true,
          data: response,
          val: val,
          studentId: prefilledData?.id,
        });
      }
    } catch (error) {
      return error;
    }
  };

  const handleDateChange = (name, date) => {
   
    try {
      const formattedDate = dateFormatterWithoutNew(date);
      setFormData({ ...formData, [name]: formattedDate });
      setErrors({ ...errors, [name]: "" });
    } catch (error) {
      console.error("Error formatting date:", error);
      // Handle error (e.g., set error state)
    }
  };
  function convertToDate(dateStr) {
    const parts = dateStr.split('-');
    if (parts.length !== 3) return null;
    const [year, month, day] = parts.map(Number);
    if (isNaN(year) || isNaN(month) || isNaN(day)) return null;

    // Months are 0-based in JavaScript Date, so subtract 1 from the month
    return new Date(year, month - 1, day);
  }

  return (
    <>
      {loading && <LoaderComponent />}

      {openAddNewUser && (
        <div
          class="Admin-modal Admin-modal--open "
          id="modalAddNewStudent"
          role="dialog"
        >
          <div
            class="Admin-modal__dialog modal__dialog-md"
            style={{ width: "90vw" }}
          >
            <div class="Admin-modal__content">
              <div class="Admin-modal__head">
                <h2 class="Admin-modal__head--title">
                  {type === "Edit" ? "+ Edit Student" : "+ Add New Student"}
                </h2>
                <button
                  class="Admin-modal__close"
                  onClick={() => {
                    setAddNewUser(false);
                  }}
                >
                  <CloseIcon />
                </button>
              </div>

              <div className="Admin-modal__body">
                <div className="flexbox">
                  <div className="column">
                    <div className="adminform__group">
                      <label htmlFor="first_name" className="label">
                        First Name<span className="important_field">*</span>
                      </label>
                      <input
                        id="first_name"
                        type="text"
                        className="adminform__input"
                        placeholder="Enter First Name"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleInputChange}
                      />
                      {errors.first_name && (
                        <span className="error">{errors.first_name}</span>
                      )}
                    </div>
                  </div>
                  <div className="column">
                    <div className="adminform__group">
                      <label htmlFor="last_name" className="label">
                        Last Name <span className="important_field">*</span>
                      </label>
                      <input
                        id="last_name"
                        type="text"
                        className="adminform__input"
                        placeholder="Enter Last Name"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleInputChange}
                      />
                      {errors.last_name && (
                        <span className="error">{errors.last_name}</span>
                      )}
                    </div>
                  </div>
                </div>
                {/* DOB & Gender  */}
                <div className="flexbox">
                  <div className="column">
                    <div className="adminform__group">
                      <label htmlFor="dob" className="label">
                        DOB (MM/DD/YYYY)
                        <span className="important_field">*</span>
                      </label>
                      
                      <DatePicker
                        className="adminform__input"
                        showMonthDropdown
                        showYearDropdown
                          dropdownMode="select"
                        selected={tempSelectedDate ? tempSelectedDate: convertToDate(formData?.dob)}
                        onChange={(date) => {
                          setTempSelectedDate(date)
                          handleDateChange("dob", date)
                        }}

                      />
                      {errors.dob && (
                        <span className="error">{errors.dob}</span>
                      )}
                    </div>
                  </div>

                  <div className="column">
                    <div className="adminform__group">
                      <label htmlFor="gender" className="label">
                        Gender <span className="important_field">*</span>
                      </label>
                      <select
                        name="gender"
                        id="gender"
                        className="adminform__select"
                        onChange={handleInputChange}
                        value={formData.gender}
                      >
                        <option value="" disabled>
                          Gender
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>

                      {errors.gender && (
                        <span className="error">{errors.gender}</span>
                      )}
                    </div>
                  </div>
                </div>
                {/* Category & Class  */}
                <div className="flexbox">
                  <div className="column">
                    <div className="adminform__group">
                      <label htmlFor="category" className="label">
                        Category
                      </label>
                      <Select
                        className="multiSelectInput"
                        options={[
                          { label: "Select Category", value: null },
                          ...(Array.isArray(categoryLists) &&
                            categoryLists.length > 0
                            ? categoryLists
                              .filter((val) => val?.name.trim() !== "")
                              .map((category) => ({
                                label: category.name,
                                value: category.id,
                                period: category.period,
                              }))
                              .filter((option) => option.value !== "")
                            : []),
                        ]}
                        placeholder="Select category"
                        isSearchable={true}
                        styles={customStyles}
                        value={formData.category}
                        onChange={handleCategoryChange}
                      />
                    </div>
                  </div>
                  <div className="column">
                    <div className="adminform__group">
                      <label htmlFor="class" className="label">
                        Class
                      </label>

                      <Select
                        className="multiSelectInput"
                        options={[
                          { label: "Select Class", value: null },
                          ...(Array.isArray(classList) && classList.length > 0
                            ? classList
                              .filter((val) => val?.name.trim() !== "")
                              .map((category) => {
                                let labelString = category?.name;
                                if (category?.class_schedules?.length > 0) {
                                  labelString += " (";
                                  labelString += category.class_schedules
                                    .map((el) => {
                                      if (
                                        el?.weekday &&
                                        el?.start_time &&
                                        el?.end_time
                                      ) {
                                        return `${el.weekday.substring(
                                          0,
                                          3
                                        )} ${timeFormatter(
                                          el.start_time
                                        )} - ${timeFormatter(el.end_time)}`;
                                      }
                                      return null;
                                    })
                                    .filter(Boolean)
                                    .join(", ");
                                  labelString += ")";
                                }
                                if (category?.schedules?.length > 0) {
                                  labelString += ")";
                                }
                                return {
                                  label: labelString,
                                  value: category.id,
                                  period: category.period,
                                };
                              })
                            : []),
                        ]}
                        onChange={handleClassChange}
                        placeholder="Select Class"
                        isSearchable={true}
                        styles={customStyles}
                        isDisabled={!formData.category?.value}
                      />
                    </div>
                  </div>
                </div>

                {/* EDIT TABLE CLASS  */}
                {type === "Edit" && prefilledData?.classDetails.length > 0 && (
                  <div className="Table">
                    <div className="Heading">
                      <div className="Cell">
                        <p>Category</p>
                      </div>
                      <div className="Cell">
                        <p>Class Name</p>
                      </div>
                      <div className="Cell">
                        <p>Schedules</p>
                      </div>
                      <div className="Cell">
                        <p>Actions</p>
                      </div>
                    </div>
                    {prefilledData?.classDetails.length ? (
                      prefilledData?.classDetails.map((val) => (
                        <div className="Row">
                          <div className="Cell">
                            <p>{val?.categoryName ? val?.categoryName : "-"}</p>
                          </div>
                          <div className="Cell" style={{ maxWidth: "120px" }}>
                            <p>{val?.className ? val?.className : "-"}</p>
                          </div>
                          <div
                            className="Cell"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <button
                              className="adminbtn adminbtn-blueOutline"
                              style={{ maxWidth: "100px" }}
                              onClick={() => {
                                setShowSchedules(!showSchedules);
                                setSelectedStudentSchedules(
                                  val?.classScheduleDetails
                                );
                              }}
                            >
                              View Scheudles
                            </button>
                          </div>
                          <div className="Cell">
                            <div className="flexbox">
                              <DeleteIcon
                                style={{
                                  cursor: "pointer",
                                  maxWidth: "40px",
                                }}
                                onClick={() =>
                                  deleteClassHandler(
                                    prefilledData?.id,
                                    val?.classScheduleId,
                                    val?.period
                                  )
                                }
                              />
                              <button
                                style={{ minWidth: "20px" }}
                                className="btn adminbtn-blueOutline"
                                onClick={() => {
                                  if (val?.period === "Monthly") {
                                    classSwitchingAPIFunction(val);
                                  } else {
                                    classSwitchingAPIFunction(val);
                                  }
                                }}
                              >
                                Switch{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p style={{ fontWeight: "400", fontSize: "1rem" }}>
                        No enrollments found
                      </p>
                    )}
                  </div>
                )}

                {/* SCHEDULE TABLE  */}
                {classId !== undefined && classId !== null && (
                  <div className="flexbox">
                    <ScheduleTable
                      selectedSchedules={selectedSchedules}
                      scheduleData={classScheduleData}
                      setClassScheduleData={setClassScheduleData}
                      setSelectedSchedules={setSelectedSchedules}
                    />
                  </div>
                )}

                {isCampClass && (
                  <div className="flexbox">
                    <CampTable
                      selectedCampSchedules={selectedCampSchedules}
                      campScheduleData={campScheduleData}
                      setCampScheduleData={setCampScheduleData}
                      setSelectedCampSchedules={setSelectedCampSchedules}
                      setSelectedCampId={setSelectedCampId}
                      selectedCampId={selectedCampId}
                    />
                  </div>
                )}

                {/* ALLERGIES & VACCINATION  */}
                <div className="flexbox">
                  <div className="column">
                    <div className="adminform__group">
                      <label htmlFor="allergies" className="label">
                        Allergies <span className="important_field">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Allergies"
                        className="adminform__input"
                        id="allergies"
                        name="allergies"
                        value={formData?.allergies}
                        onChange={handleInputChange}
                      />
                      {errors.allergies && (
                        <span className="error">{errors.allergies}</span>
                      )}
                    </div>
                  </div>
                  <div className="column">
                    <label htmlFor="" className="label">
                      Vaccination Card
                    </label>
                    <div className="adminform__group__grayBox">
                      <label className="label">Has vaccination card</label>
                      <input
                        id="hasVaccinationCard"
                        type="checkbox"
                        className="adminform__checkbox"
                        name="vaccination"
                        value={formData?.vaccination}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                {/* DOCTOR NAME & DOCTOR PHONE  */}
                <div className="flexbox">
                  <div className="column">
                    <div className="adminform__group">
                      <label htmlFor="doctor_name" className="label">
                        Doctor Name
                      </label>
                      <input
                        id="doctor_name"
                        className="adminform__input"
                        type="text"
                        placeholder="Doctor Name"
                        name="doctor_name"
                        onChange={handleInputChange}
                        value={formData?.doctor_name}
                      />
                    </div>
                  </div>
                  <div className="column">
                    <div className="adminform__group">
                      <label htmlFor="doctor_phone" className="label">
                        Doctor Phone
                      </label>
                      <input
                        id="doctor_phone"
                        className="adminform__input"
                        type="text"
                        placeholder="Doctor Phone"
                        name="doctor_phone"
                        onChange={handleInputChange}
                        value={formData?.doctor_phone}
                        maxLength="10"
                        pattern="[0-9]{10}"
                      />
                    </div>
                  </div>
                </div>
                {/* EMERGENCY NAME & CONTACT  */}
                <div className="flexbox">
                  <div className="column">
                    <div className="adminform__group">
                      <label htmlFor="emergency_name" className="label">
                        Emergency Name
                      </label>
                      <input
                        id="emergency_name"
                        className="adminform__input"
                        type="text"
                        placeholder="Emergency Name"
                        name="emergency_name"
                        onChange={handleInputChange}
                        value={formData?.emergency_name}
                      />
                    </div>
                  </div>
                  <div className="column">
                    <div className="adminform__group">
                      <label htmlFor="emergency_phone" className="label">
                        Emergency Phone
                      </label>
                      <input
                        id="emergency_phone"
                        name="emergency_phone"
                        className="adminform__input"
                        type="text"
                        placeholder="Emergency Phone"
                        onChange={handleInputChange}
                        value={formData?.emergency_phone}
                        maxLength="10"
                        pattern="[0-9]{10}"
                      />
                    </div>
                  </div>
                </div>

                {/*COMMENTS  */}
                <div className="flexbox">
                  <div className="column">
                    <div className="adminform__group">
                      <label htmlFor="comments" className="label">
                        Comments
                      </label>
                      <input
                        type="text"
                        id="comments"
                        name="comments"
                        className="adminform__input"
                        placeholder="Comments"
                        value={formData.comments}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="flexbox">
                  <div className="column">
                    <label htmlFor="currently_attending_with" className="label">
                      Currently Attending
                    </label>
                    <div className="adminbutton__group">
                      <button
                        className="adminbtn  adminbtn-blueOutline"
                        onClick={() => {
                          addRow();
                        }}
                      >
                        Add +
                      </button>
                    </div>

                    {rows.map((row) => {
                      return (
                        <div
                          className="flexbox"
                          key={row?.id}
                          style={{ marginTop: "10px", alignItems: "center" }}
                        >
                          <div className="column">
                            <label htmlFor="attended_with" className="label">
                              Attended with
                            </label>
                            <div className="adminform__group">
                              <select
                                name="attended_with"
                                id={`attended_with_${row.id}`}
                                className="adminform__select"
                                value={row.attended_with}
                                onChange={(e) => handleRowChange(e, row.id)}
                              >
                                <option selected value="" disabled>
                                  Select Relationship
                                </option>
                                <option value="caretaker">Care taker</option>
                                <option value="Parent">Parent</option>
                              </select>
                            </div>
                          </div>

                          <div className="column">
                            <label htmlFor="" className="label">
                              Attended name
                            </label>
                            <div className="adminform__group">
                              <input
                                type="text"
                                id={`currently_attending_with_${row?.id}`}
                                name="name"
                                placeholder="Currently attending name"
                                className="adminform__input"
                                value={row.name}
                                onChange={(e) => handleRowChange(e, row?.id)}
                              />
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={() => deleteRow(row.id)}
                              style={{ marginTop: "10px" }}
                            >
                              <DeleteIcon />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* PARENT SUIGNARE  */}
                <div className="flexbox">
                  <div className="adminform__group">
                    <label htmlFor="parent_signature" className="label">
                      Parent Signature
                    </label>
                    <input
                      id="parent_signature"
                      type="file"
                      className="adminform__input"
                      placeholder="Parent Signature"
                      name="parent_signature"
                      onChange={handleInputChange}
                    />

                    <span
                      className="important_field label"
                      style={{ color: "red" }}
                    >
                      Supported file type: JPG,PNG,PDF
                    </span>
                    {errors.parent_signature && (
                      <span className="error">{errors.parent_signature}</span>
                    )}
                  </div>
                </div>
                {/* {ADD } */}

                {type == "Add" && (
                  <FileUpload type={type} uploadedFile={uploadedFile} />
                )}

                {/* EDIT CASE  */}
                {type === "Edit" &&
                  uploadedFile == undefined &&
                  data?.parent_signature !== "" && (
                    <FileUpload
                      type={type}
                      uploadedFile={data?.parent_signature}
                    />
                  )}

                {/* EDIT  , When Prefilled Data is Coming */}

                {type == "Edit" &&
                  uploadedFile !== undefined &&
                  data?.parent_signature !== "" && (
                    <FileUpload type={type} uploadedFile={uploadedFile} />
                  )}

                {/* TYPE EDIT AND NO UPLOADED FILE AND  */}
                {type === "Edit" &&
                  uploadedFile === undefined &&
                  data?.parent_signature !== "" &&
                  (data?.parent_signature?.includes(".pdf") ? (
                    <div className="preview">
                      <a
                        href={data?.parent_signature}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click to View the Pdf
                      </a>
                    </div>
                  ) : (
                    <div className="preview">
                      <img
                        style={{ width: "250px", height: "100%" }}
                        src={data?.parent_signature}
                        alt="Uploaded File"
                      />
                    </div>
                  ))}
              </div>

              {/* FOOTER  */}
              <div
                class="Admin-modal__footer"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <div
                  className="admin-button__group"
                  style={{ display: "flex", width: "350px", gap: "30px" }}
                >
                  <button
                    className="adminbtn adminbtn-blueOutline"
                    onClick={() => {
                      setAddNewUser(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="adminbtn adminbtn-primary"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {OpenSwitchModal?.isOpen && (
        <SwtichClassModal
          setOpenSwitchModal={setOpenSwitchModal}
          OpenSwitchModal={OpenSwitchModal}
          fetchStudentDetails={getStudentINFO}
        />
      )}

      {showSchedules && (
        <ShowTeachers
          showTeacherModal={showSchedules}
          setShowTeacherModal={setShowSchedules}
          type={"Student"}
          studentData={selectedStudentSchedules}
        />
      )}
    </>
  );
}

export default AddStudentModal;
