// rollSheetSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const rollSheetSlice = createSlice({
  name: 'rollSheet',
  initialState: {
    rollSheetCompleteData: [],
    dateCopy: '',
  },
  reducers: {
    setRollSheetData: (state, action) => {
      state.rollSheetCompleteData = action.payload.rollSheetCompleteData;
      state.dateCopy = action.payload.dateCopy;
    },
  },
});

export const { setRollSheetData } = rollSheetSlice.actions;

export default rollSheetSlice.reducer;

