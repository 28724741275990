import classDataReducer from "../reducers/classDetailsSlice";
import studentDataReducer from "../reducers/studentDataSlice";
import successModalReducer from "../reducers/userSlice";
import cartReducer from "../reducers/cartSlice";
import loadingReducer from "../reducers/loadingSlice";
import { configureStore } from "@reduxjs/toolkit";
import adminModalsSlice from "../reducers/admin-slice/adminModalsSlice";
import toRender from "../reducers/admin-slice/toRenderSlice";
import studentDataSlice from "../reducers/studentDataSlice";
import sessionReducer from "../reducers/sessionHandler";
import rollSheetSlice from "../reducers/rollSheetSlice";
import categorySumSlice from "../reducers/categorySumSlice";
import attendanceSummary from "../reducers/attendanceSummary";
const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("reduxState", serializedState);
  } catch (error) {
    console.error("Error saving to local storage:", error);
  }
};

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("reduxState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.error("Error loading from local storage:", error);
    return undefined;
  }
};

const store = configureStore({
  reducer: {
    classData: classDataReducer,
    studentData: studentDataReducer,
    successModalContent: successModalReducer,
    loading: loadingReducer,
    userRole: successModalReducer,
    cart: cartReducer,
    adminSlice: adminModalsSlice,
    torender: toRender,
    studentSlice: studentDataSlice,
    sessionSlice: sessionReducer,
    rollSheet: rollSheetSlice,
    categorySum: categorySumSlice,
    attendanceSum: attendanceSummary,
  },
  preloadedState: loadFromLocalStorage(),
});

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});

export default store;
