const GET_CLASSES = `
query GetClassesForAdmin($pageInfo: PaginationInfo, $season: String, $className: String, $classId : Int ,$category: String, $tuition: String, $maxAge: String, $minAge: String, $website: String, $startDate: String) {
  getClassesForAdmin(pageInfo: $pageInfo, season: $season, className: $className, category: $category, classId : $classId, tuition: $tuition, maxAge: $maxAge, minAge: $minAge, website: $website,start_date: $startDate) {
    data {
      id
      fk_season_classes
     
      name
      fk_category_classes
      categoryId
      tuition
      max_age
      min_age
      website
      hidden
      max_strength
      gender
      period
      fk_teachers_classes
      start_date
      end_date
      weekday
      category_name
      season_name
      is_combo
      halfDay {
        id
        name
        week
        start_date
        end_date
        max_strength
        tuition
        start_day
        end_day
        enrolled
        start_time
        end_time
        class_type
        fk_teacher_id
    }
    fullDay {
        id
        name
        week
        start_date
        end_date
        max_strength
        tuition
        start_day
        end_day
        enrolled
        start_time
        end_time
        class_type
        fk_teacher_id
    }
      schedules {
        id
        teacherId
        start_time
        end_time
        weekday
        name  
        max_strength
        subject
      }
      makeUpClasses {
        id
        fk_class_makeup_classes
        fk_teacher_makeup_classes
        date
        start_time
        end_time
        slots
      }
    }
    totalCount
    totalPages
    limit
    currPage
  }
}
`;

 const GET_CLASS_MAKEUP = `
query GetClassesForAdmin($pageInfo: PaginationInfo, $season: String, $className: String, $category: String, $tuition: String, $maxAge: String, $minAge: String, $website: String, $startDate: String) {
  getClassesForAdmin(pageInfo: $pageInfo, season: $season, className: $className, category: $category, tuition: $tuition, maxAge: $maxAge, minAge: $minAge, website: $website,start_date: $startDate) {
    data {
      id
      fk_season_classes
     
      name
      fk_category_classes
      categoryId
      tuition
      max_age
      min_age
      website
      hidden
      max_strength
      gender
      period
      fk_teachers_classes
      start_date
      end_date
      weekday
      category_name
      season_name
      is_combo
      halfDay {
        id
        name
        week
        start_date
        end_date
        max_strength
        tuition
        start_day
        end_day
        enrolled
        start_time
        end_time
        class_type
        fk_teacher_id
    }
    fullDay {
        id
        name
        week
        start_date
        end_date
        max_strength
        tuition
        start_day
        end_day
        enrolled
        start_time
        end_time
        class_type
        fk_teacher_id
    }
      schedules {
        id
        teacherId
        start_time
        end_time
        weekday
        name  
        max_strength
        subject
      }
      makeUpClasses {
        id
        fk_class_makeup_classes
        fk_teacher_makeup_classes
        date
        start_time
        end_time
        slots
      }
    }
    totalCount
    totalPages
    limit
    currPage
  }
}
`;

const GET_STUDENT_BY_CLASS_ID = `
query GetEnrolledStudentsFromClassId($pageInfo: PaginationInfo, $classId: Int!, $isCamp : Boolean ) {
  getEnrolledStudentsFromClassId(pageInfo: $pageInfo, classId: $classId , isCamp : $isCamp) {
    data {
      id
      first_name
      last_name
      age
      proRatedFee
      dob
    }
    totalCount
    totalPages
    limit
    currPage
  }
}

`;

const GET_WEEKLY_CLASS = `
query getClassesQuery($classId: Int) {
  getWeeklyClass(classId: $classId) {
    name
    id
    name
    start_time
    end_time
    category_name
    start_date
    end_date
    max_strength
    tuition
    halfDay {
        id
        name
        week
        start_date
        end_date
        max_strength
        tuition
        start_day
        end_day
        enrolled
        start_time
        end_time
        class_type
    }
    fullDay {
        id
        name
        week
        start_date
        end_date
        max_strength
        tuition
        start_day
        end_day
        enrolled
        start_time
        end_time
        class_type
    }
  }
}`;

export { GET_CLASSES, GET_STUDENT_BY_CLASS_ID, GET_WEEKLY_CLASS , GET_CLASS_MAKEUP};
