import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./mailingList.css"
const MailingListPdf = () => {
  const { state } = useLocation();
  const dateFormatHandler = (value) => {
    const date = value?.split("-");
    return ` ${date[1]}-${date[2]}-${date[0]}`;
  };
  useEffect(() => {
    const handleBeforePrint = () => {
      window.scrollTo(0, 0);
    }

    window.addEventListener('beforeprint', handleBeforePrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
    }
  }, []);
  return (
    <>
      <div className="page_mailing_list">
        <h1
          style={{
            fontSize: "40px",
            fontWeight: "600",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          {state?.selectedReport}
        </h1>
        <h1
          className="rollsheet_date_heading"
          style={{
            fontWeight: "400",
            fontSize: "1.5rem",
            marginTop: "10px",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Summary of all categories charged on
          <span style={{ fontWeight: "600" }}>
            {dateFormatHandler(state?.date?.copyStartDate)}{" "}
          </span>
          to
          <span style={{ fontWeight: "600" }}>
            {dateFormatHandler(state?.date?.copyEndDate)}
          </span>
        </h1>
        <table className="rollsheet_pdf_container">
          <thead>
            <tr>
              <th 
              className="mailing_list_pdf_heading"
              style={{ color: "white" }}>Name</th>
              <th 
              className="mailing_list_pdf_heading"
              style={{ color: "white" }}>Address</th>
              <th 
              className="mailing_list_pdf_heading"
              style={{ color: "white" }}>Home Phone</th>
              <th 
              className="mailing_list_pdf_heading"
              style={{ color: "white" }}>Email</th>
              <th 
              className="mailing_list_pdf_heading"
              style={{ color: "white" }}>Email 2</th>
              <th 
              className="mailing_list_pdf_heading"
              style={{ color: "white" }}>Enrollment Type</th>
              <th 
              className="mailing_list_pdf_heading"
              style={{ color: "white" }}>Class Name</th>
              <th 
              className="mailing_list_pdf_heading"
              style={{ color: "white" }}>Category Name</th>
            </tr>
          </thead>
          <tbody>
            {state?.mailingListData?.map((item) => (
              <tr>
                <td style={{ fontSize: "22px" }}>
                  {`${
                    item?.parent1_first_name ? item?.parent1_first_name : "-"
                  } ${item?.parent1_last_name ? item?.parent1_last_name : "-"}`}
                </td>
                <td style={{ fontSize: "22px" }}>
                  {`${item?.parent1_address ? item?.parent1_address : "-"} ${
                    item?.parent1_city ? item?.parent1_city : "-"
                  } ${item?.parent1_state ? item?.parent1_state : ""}`}
                </td>
                <td style={{ fontSize: "22px" }}>
                  {item?.parent1_cell ? item?.parent1_cell : "-"}
                </td>
                <td style={{ fontSize: "22px" }}>
                  {item?.parent1_email ? item?.parent1_email : "-"}
                </td>
                <td style={{ fontSize: "22px" }}>
                  {item?.parent2_email ? item?.parent2_email : "-"}
                </td>
                <td style={{ fontSize: "22px" }}>
                  {item?.enrollment_status ? item?.enrollment_status : "-"}
                </td>
                <td style={{ fontSize: "22px" }}>
                  {item?.classes_name ? item?.classes_name : "-"}
                </td>
                <td style={{ fontSize: "22px" }}>
                  {item?.category_name ? item?.category_name : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default MailingListPdf;
