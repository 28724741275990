import React, { useState, useEffect, useRef } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import FadeLoader from "react-spinners/FadeLoader";
import Header from "../components/Header";
import NavigationComponent from "../components/NavigationComponent";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import scrollTop from "../../Parent/parent-assets/images/scrollTop.svg";
import {
  getAttendenceReport,
  getReport,
  getdropoffSummary,
  getmailingList,
  getphotoRelease,
} from "../adminApi/reports";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import getPrograms from "../adminApi/categories";
import "./report.css";
import ToRender from "../components/TableComponent/ToRender";
import {
  generateReportData,
  generatetableheadersData,
  getReportSelected,
} from "../../reducers/admin-slice/toRenderSlice";
import {
  getClass,
  getClassesByWeekdays,
  getClassesNew,
} from "../adminApi/classApi";
import { getRollSheetReport } from "../adminApi/rollsheet";
import { useNavigate } from "react-router";
import { dateFormatter, timeFormatter } from "../adminUtility";
import { setRollSheetData } from "../../reducers/rollSheetSlice";
import { setAttendanceSummaryData } from "../../reducers/attendanceSummary";
import { setCategorySumCompleteData } from "../../reducers/categorySumSlice";

const Reports = () => {
  const { attendanceSumaryData } = useSelector((state) => state?.attendanceSum);
  const selectRef = useRef(null);
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const initialDate = `${year}-${month}-01`; // Set day part to 01 for the 1st day of the month

  const currentDate1 = new Date();
  const year1 = currentDate1.getFullYear();
  const month1 = String(currentDate1.getMonth() + 1).padStart(2, "0"); // Month1 is zero-based
  const lastDayOfCurrentMonth = new Date(year1, month1, 0).getDate();
  const lastDate = `${year1}-${month1}-${lastDayOfCurrentMonth}`;

  const token = localStorage.getItem("access_token");
  const navigate = useNavigate("");
  const [rollSheetSearchState, setRollSheetSearchState] = useState();
  const [filterText, setFilterText] = useState("");
  const [showDropDown, setShowDropdown] = useState(false);
  const [showSpinnerLoader, setShowSpinnerLoader] = useState(false);
  const [pageLimit, setPageLimit] = useState("1000000");
  const [gender, setGender] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [selectedRange, setSelectedRange] = useState(null);
  const [typeDetails, setTypeDetails] = useState("");
  const [_tabelHeadersData, setTableHeader] = useState([]);
  const [selectedReport, setSelectedReport] = useState("");
  const [startDate, setStartDate] = useState(initialDate);
  const [isEnded, setIsEnded] = useState(true);
  const [endDate, setEndDate] = useState(lastDate);
  const [rollSheetStartDate, setRollSheetStartDate] = useState(
    formatDate(new Date())
  );
  const [rollSheetEndDate, setRollSheetEndDate] = useState(null);
  const [showNestedBoxForMailingList, setShowNestedBoxforMailingList] =
    useState(false);
  const [showPdfGenerateBtn, setShowGeneratePDFBtn] = useState(false);
  const [showAttendenceSearchParams, setShowAttendenceSearchParams] =
    useState(false);
  const [showCategorySummarySearchParams, setShowCategorySummaryParams] =
    useState(false);
  const [showDropOffSearchParams, setShowDropOffSearchParams] = useState(false);
  const [isAllCategorySelected, setIsAllCategorySelected] = useState(false);
  const [mailingListSearchParams, setMailingListSearchParams] = useState(false);
  const [photoRealeaseSearchParams, setPhotoRealeaseSearchParams] =
    useState(false);
  const [rollSheetSearchParams, setRollSheetSearchParams] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState([]);
  const [showAttendanceStudentStatus, setShowAttendenceStudentStatus] =
    useState(false);
  const [showCategoryList, setShowCategoryList] = useState(false);
  const [showDropOffClassList, setShowDropOffClassList] = useState(false);
  const [showGenderMailingList, setShowGenderMailingList] = useState(false);
  const [activeInActiveContent, setActiveInActiveContent] = useState(false);
  const [showWeekdays, setShowWeekdays] = useState(false);
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const [category, setCategory] = useState([]);
  const [classes, setClasses] = useState([]);
  const [tempClasses, setTempClasses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectAllChecked, setSelectedAllChecked] = useState(false);
  const [rollSheetCompleteData, setRollSheetCompleteData] = useState([]);
  const [showClassLoader, setShowClassLoader] = useState(false);
  const [showCategoryLoader, setShowCategoryLoader] = useState(false);
  const [showDeselectAll, setShowDeselectAllClass] = useState(false);
  const [showNoDataFound, setShowNoDataFound] = useState(false);
  const [selectRow, setSelectRow] = useState("5");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [isSelectAllClass, setIsSelectAllClass] = useState(false);
  const [dateCopy, setDateCopy] = useState({
    copyStartDate: "",
    copyEndDate: "",
  });
  const [copyCategory, setCopyCategory] = useState([]);
  const [copyClasses, setCopyClasses] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    categoryName: "",
  });
  const [selectedClassDummy, setSelectedClassDummy] = useState([]);
  const [showDropOffPdf, setShowDropOffPdf] = useState(false);
  const [sortKey, setSortKey] = useState({
    sortId: "1",
    isAsc: true,
  });
  const [typeStatus, setTypeStatus] = useState([]);
  const [minDisabledDate, setMinDisabledDate] = useState(new Date(2024, 5, 1));
  const [mailingListData, setMailingListData] = useState();
  const [scrollId, setScrollId] = useState();
  const dateRangePickerRef = useRef(null);
  const page = useSelector((state) => state?.adminSlice?.adminModalContent);
  // const role = useSelector((state) => state?.userRole?.RoleId);
  const dispatch = useDispatch();
  const componentRef = useRef();

  const convertMonth = (value) => {
    switch (value) {
      case 1:
        return "January";
      case 2:
        return "February";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";
      default:
        return "Invalid month";
    }
  };

  function formatAge(months) {
    if (months < 24) {
      return `${months} months`;
    }
    if (months === undefined || months === null) {
      return "-";
    }

    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;

    let result = "";
    if (years > 0) {
      result += years + " year" + (years > 1 ? "s" : "") + " ";
    }
    if (remainingMonths > 0) {
      result += remainingMonths + " month" + (remainingMonths > 1 ? "s" : "");
    }

    return result.trim();
  }

  // const report = { age: 26 }; // Example age in months
  // const ageDescription = report?.age ? formatAge(report.age) : "-";

  const fetchData = async () => {
    try {
      if (selectedCategory?.length > 0) {
        setCopyCategory(selectedCategory);
      } else {
        setCopyCategory([]);
      }
      if (selectedClasses?.length > 0) {
        setCopyClasses(selectedClasses);
      } else {
        setCopyClasses([]);
      }
      setDateCopy({
        copyStartDate: startDate,
        copyEndDate: endDate,
      });
      let response;
      let dataItem;
      const token = localStorage.getItem("access_token");
      if (selectedReport === "Category Summary") {
        setShowSpinnerLoader(true);
        response = await getReport(
          token,
          selectedCategoryId,
          startDate,
          endDate
        );
        dataItem = [...response?.data?.data?.getcategorySummary];
        setShowSpinnerLoader(false);
      } else if (selectedReport === "Attendance Summary") {
        setShowSpinnerLoader(true);
        response = await getAttendenceReport(
          token,
          typeDetails,
          selectedCategory,
          endDate,
          startDate,
          typeStatus
        );
        dispatch(
          setAttendanceSummaryData({
            attendanceSumaryData: response?.data?.data?.getAttendanceSummary,
            dateCopy: { copyStartDate: startDate, copyEndDate: endDate },
          })
        );
        dataItem = [...response?.data?.data?.getAttendanceSummary];
        setShowSpinnerLoader(false);
      } else if (selectedReport === "Photo Release Report") {
        setShowSpinnerLoader(true);
        response = await getphotoRelease(
          token,
          isActive,
          gender,
          selectedRange?.endAge,
          selectedRange?.startAge
        );
        dataItem = [...response?.data?.data?.getphotoRelease];
        setShowSpinnerLoader(false);
      } else if (selectedReport === "Mailing List") {
        setShowSpinnerLoader(true);
        response = await getmailingList(
          token,
          selectedRange?.startAge,
          selectedRange?.endAge,
          startDate,
          endDate,
          gender,
          selectedCategoryId,
          tempClasses
        );
        setMailingListData(response?.data?.data?.getmailingList);
        dataItem = [...response?.data?.data?.getmailingList];
        setShowSpinnerLoader(false);
      } else if (selectedReport === "Drop Off Report") {
        if (startDate && endDate) {
          setShowSpinnerLoader(true);
        }
        response = await getdropoffSummary(token, startDate, endDate);
        dataItem = [...response?.data?.data?.getdropoffSummary];
        setShowSpinnerLoader(false);
      } else if (selectedReport === "Class Rollsheet") {
        // let convertedClassId = selectedClasses.map(Number);

        if (startDate === "" || endDate === "") {
          toast.info("Provide date range with classes to get the roll sheet.");
          return;
        }
        if (selectedClasses?.length == 0) {
          toast.info("Provide class name.");
          return;
        }
        setShowSpinnerLoader(true);
        response = await getRollSheetReport(
          token,
          selectedClasses,
          startDate,
          endDate
        );

        dispatch(
          setRollSheetData({
            rollSheetCompleteData: response?.data?.data?.classRollSheet,
            dateCopy: { copyStartDate: startDate, copyEndDate: endDate },
          })
        );
        setRollSheetCompleteData(response?.data?.data?.classRollSheet);
        if (response?.status === 200 || response?.status === 201) {
          setShowGeneratePDFBtn(true);
        }
        setShowNoDataFound(true);
        setShowSpinnerLoader(false);
        dataItem = [
          ...response?.data?.data?.classRollSheet?.map(
            (items) => items?.classSchedules?.[0]?.students
          ),
        ];
      }
      const modifiedData = dataItem?.map((report, index) => {
        if (selectedReport === "Category Summary") {
          return {
            data: [
              report?.clientId ? report?.clientId : "-",
              report?.client_first_name + " " + report?.client_last_name,
              report?.charge_date,
              report?.category_name ? report?.category_name : "-",
              report?.class_name ? report?.class_name : "-",
              report?.amount ? report?.amount : "-",
              report?.paid ? report?.paid : "-",
              report?.payment_date ? report?.payment_date : "-",
              // report?.amount - report?.paid,
            ],
          };
        } else if (selectedReport === "Attendance Summary") {
          return {
            data: [
              report?.class_date !== null && dateFormatter(report?.class_date),
              // report?.type === "1" ? "Present" : "Absent",
              <>
                {report?.className}

                {report?.classSchedules?.map((item, index) => (
                  <div key={index}>
                    <span>{item.weekday} </span>
                    <span>{item?.start_time}</span>-
                    <span>{item?.end_time}</span>
                  </div>
                ))}
              </>,
              <p>
                {report?.class_start_time
                  ? timeFormatter(report?.class_start_time)
                  : "-"}{" "}
                :
                {report?.class_end_time
                  ? timeFormatter(report?.class_end_time)
                  : "-"}
              </p>,
              report?.studentName,
              report?.typeStatus ? report?.typeStatus : "-",
              report?.memo ? report?.memo : "-",
            ],
            item: report,
          };
        } else if (selectedReport === "Photo Release Report") {
          return {
            data: [
              report?.parent1_first_name ? report?.parent1_first_name : "-",
              report?.parent1_last_name ? report?.parent1_last_name : "-",
              report?.parent1_address ? report?.parent1_address : "-",
              report?.parent1_city ? report?.parent1_city : "-",
              report?.parent1_state ? report?.parent1_state : "-",
              report?.parent1_zip ? report?.parent1_zip : "-",
              report?.parent1_cell ? report?.parent1_cell : "-",
              report?.parent1_email ? report?.parent1_email : "-",
              report?.parent2_email ? report?.parent2_email : "-",
              report?.gender ? report?.gender : "-",
              report?.age ? formatAge(parseInt(report?.age)) : "-",
              report?.active
                ? report?.active == "1"
                  ? "Active"
                  : "In-active"
                : "-",
            ],
          };
        } else if (selectedReport === "Mailing List") {
          return {
            data: [
              report?.parent1_first_name ? report?.parent1_first_name : "-",
              report?.parent1_last_name ? report?.parent1_last_name : "-",
              report?.parent1_address ? report?.parent1_address : "-",
              report?.parent1_city ? report?.parent1_city : "-",
              report?.parent1_state ? report?.parent1_state : "-",
              report?.parent1_zip ? report?.parent1_zip : "-",
              report?.parent1_cell ? report?.parent1_cell : "-",
              report?.parent1_email ? report?.parent1_email : "-",
              report?.parent2_email ? report?.parent2_email : "-",
              report?.enrollment_status ? report?.enrollment_status : "-",
              report?.age ? formatAge(parseInt(report.age)) : "-",
              report?.gender ? report?.gender : "-",
              report?.classes_name ? report?.classes_name : "-",
              report?.category_name ? report?.category_name : "-",
            ],
          };
        } else if (selectedReport === "Drop Off Report") {
          return {
            data: [
              report?.parent_name ? report?.parent_name : "-",
              report?.student_name ? report?.student_name : "-",
              report?.parent1_email ? report?.parent1_email : "-",
              report?.start_month ? convertMonth(report?.start_month) : "-",
              report?.class_name ? report?.class_name : "-",
            ],
          };
        } else if (selectedReport === "Class Rollsheet") {
          return {
            data: [
              report?.first_name ? report?.first_name : "-",
              report?.dob ? report?.dob : "-",
              report?.age ? report?.age : "-",
              report?.gender ? report?.gender : "-",
              report?.school_attending_with
                ? report?.school_attending_with
                : "-",
              report?.clients[0]?.parent1_email
                ? report?.clients[0]?.parent1_email
                : "-",
              report?.photo_release ? report?.photo_release : "-",
            ],
          };
        }
      });

      dispatch(generateReportData(modifiedData));

      if (
        selectedReport === "Category Summary" &&
        selectedCategoryId?.length > 0
      ) {
        dispatch(
          setCategorySumCompleteData({
            categorySumCompleteData: response?.data?.data?.getcategorySummary,
            dateCopy: { copyStartDate: startDate, copyEndDate: endDate },
            tabelHeadersData: _tabelHeadersData,
          })
        );

        window.open(`/admin/categorySumPDF`, "_blank") ||
          window.location.replace(`/admin/categorySumPDF`);
      }

      if (selectedReport === "Drop Off Report" && startDate && endDate) {
        navigate(`/admin/PDF`, {
          state: {
            modifiedData: modifiedData,
            selectedReport: selectedReport,
            tabelHeadersData: _tabelHeadersData,
            date: {
              copyStartDate: startDate,
              copyEndDate: endDate,
            },
          },
        });
      }
      resetHandler();
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchCategoryItems = async () => {
    if (
      selectedReport === "Category Summary" ||
      selectedReport === "Mailing List" ||
      selectedReport === "Drop Off Report" ||
      selectedReport === "Class Rollsheet"
    ) {
      setShowCategoryLoader(true);
      const response = await getPrograms(
        token,
        formData,
        page,
        sortKey,
        pageLimit
      );
      setCategory(response?.data);
      setShowCategoryLoader(false);
      setShowClassLoader(true);
      // const getData = await getClass(
      //   token,
      //   { sortId: "2", isAsc: true },
      //   0,
      //   classFormData,
      //   "10000"
      // );
      if (selectedReport === "Class Rollsheet") {
        const getData = await getClassesNew(
          token,
          isEnded,
          currentPage,
          selectRow,
          filterText,
          rollSheetSearchState
        );

        setPageCount(getData?.totalPages);
        setClasses(getData?.data);
      } else {
        const getData = await getClassesNew(
          token,
          false,
          currentPage,
          selectRow,
          filterText,
          rollSheetSearchState
        );

        setPageCount(getData?.totalPages);
        setClasses(getData?.data);
      }
      // const getData = await getClassesNew(token);

      setShowClassLoader(false);
    }
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${month}-${day}-${year}`;
  }
  const handleSearchBox = () => {
    if (selectedReport === "Drop Off Report") {
      setShowDropOffPdf(true);
    }
    if (selectedReport !== "Attendance Summary") {
      resetHandler();
    } else {
      if (startDate === "" || endDate === "") {
        toast.error("select the date first");
      }
    }
    fetchData();
    setSelectedClasses([]);
    resetOnChangeReport();
    setDateCopy({
      copyStartDate: startDate,
      copyEndDate: endDate,
    });
    if (selectRef.current) {
      selectRef.current.selectedIndex = 0;
    }
  };

  const resetHandler = () => {
    setRollSheetStartDate(formatDate(new Date()));
    setShowDeselectAllClass(false);
    setIsAllCategorySelected(false);
    setFilterText("");
    setSelectedClasses([]);
    setShowNestedBoxforMailingList(false);
    setTypeDetails("");
    // setStartDate(initialDate);
    // setEndDate(lastDate);
    setSelectedRange(null);
    setIsActive(true);
    setGender("");
    // setSelectedCategory([]);
    // setSelectedCategoryId([])
    setSelectedWeekdays([]);
    setSelectedClassDummy([]);
    setTempClasses([]);
    setTypeStatus([]);
    setShowDropdown(false);
    setFormData({
      name: "",
      categoryName: "",
    });
  };

  const handleCheckboxChange = (e, id) => {
    const isChecked = e.target.checked;

    if (id === "p1") {
      // Handle "Select All" checkbox
      if (isChecked) {
        const allCategoryNames = category.map(
          (categoryItem) => categoryItem.name
        );
        setSelectedCategory(allCategoryNames);
      } else {
        setSelectedCategory([]);
      }
    } else {
      // Handle individual category checkboxes
      const value = e.target.getAttribute("data-value");
      if (isChecked) {
        setSelectedCategory([...selectedCategory, value?.split("|")?.[1]]);
        setSelectedCategoryId([
          ...selectedCategoryId,
          parseInt(value?.split("|")?.[0]),
        ]);
      } else {
        setSelectedCategory(
          selectedCategory?.filter(
            (categoryName) => categoryName !== value?.split("|")?.[1]
          )
        );
        setSelectedCategoryId(
          selectedCategoryId?.filter(
            (categoryId) => categoryId !== parseInt(value?.split("|")?.[0])
          )
        );
      }

      // Update "Select All" checkbox state based on individual checkboxes
      const allChecked = category.every((categoryItem) =>
        selectedCategory?.includes(categoryItem?.name)
      );
    }
  };

  const handleCheckboxChangeForClasses = (e, id, classnames, camp) => {
    const isChecked = e.target.checked;
    // Handle individual category checkboxes
    const value = parseInt(e.target.getAttribute("data-value"));
    if (isChecked) {
      setTempClasses((prevState) => [...prevState, value]);
      setSelectedClasses((prevSelectedClasses) => [
        ...prevSelectedClasses,
        {
          isCamp: camp,
          id: value,
        },
      ]);
      setSelectedClassDummy((prevSelectedClassDummy) => [
        ...prevSelectedClassDummy,
        classnames,
      ]);
    } else {
      setSelectedAllChecked(!selectAllChecked);
      setSelectedClasses(
        selectedClasses?.filter((className) => className?.id != value)
      );
      setSelectedClassDummy(
        selectedClassDummy?.filter((className) => className !== classnames)
      );
      setTempClasses(tempClasses?.filter((item) => item != value));
    }

    // Update "Select All" checkbox state based on individual checkboxes
    // const allChecked = classes.every((classItem) =>
    //   selectedClasses.includes(classItem.name)
    // );
  };
  const statusTypeHandler = (e, value) => {
    const checked = e.target.checked;
    setTypeStatus((prevState) => {
      if (checked) {
        return [...prevState, value];
      } else {
        return prevState?.filter((status) => status !== value);
      }
    });
  };
  const selectReportHandler = (e) => {
    setSelectedCategory([]);
    setStartDate(initialDate);
    setEndDate(lastDate);
    if (
      e.target.value === "Drop Off Report" ||
      e.target.value === "Attendance Summary"
    ) {
      setStartDate();
      setEndDate();
    }
    setSelectedReport(e.target.value);
    setCopyClasses([]);
    if (e.target.value === "Attendance Summary") {
      setShowAttendenceSearchParams(true);
      setShowCategorySummaryParams(false);
      setShowDropOffSearchParams(false);
      setMailingListSearchParams(false);
      setPhotoRealeaseSearchParams(false);
      setRollSheetSearchParams(false);
      toast.info("select the date range first to get the data in Attendance.");
      const tabelHeadersData = [
        {
          name: "Date",
        },
        // {
        //   name: "Status",
        // },
        {
          name: "Class Name",
        },
        {
          name: "Time",
        },
        {
          name: "Student",
        },
        {
          name: "Type",
        },
        {
          name: "Memo",
        },
      ];
      setTableHeader(tabelHeadersData);
      dispatch(generatetableheadersData(tabelHeadersData));
      dispatch(getReportSelected(e.target.value));
    } else if (e.target.value === "Category Summary") {
      setShowAttendenceSearchParams(false);
      setShowCategorySummaryParams(true);
      setShowDropOffSearchParams(false);
      setMailingListSearchParams(false);
      setPhotoRealeaseSearchParams(false);
      setRollSheetSearchParams(false);
      const tabelHeadersData = [
        {
          name: "Client ID",
        },
        // {
        //   name: "Start Date",
        // },
        // {
        //   name: "End Date",
        // },
        {
          name: "Name",
        },
        {
          name: "Start Date",
        },
        {
          name: "Payment Date",
        },
        {
          name: "Category",
        },
        {
          name: "Memo",
        },
        {
          name: "Amount($)",
        },
        {
          name: "Paid($)",
        },
        {
          name: "Due($)",
        },
      ];

      setTableHeader(tabelHeadersData);
      dispatch(generatetableheadersData(tabelHeadersData));
      dispatch(getReportSelected(e.target.value));
    } else if (e.target.value === "Drop Off Report") {
      setShowAttendenceSearchParams(false);
      setShowCategorySummaryParams(false);
      setShowDropOffSearchParams(true);
      setMailingListSearchParams(false);
      setPhotoRealeaseSearchParams(false);
      setRollSheetSearchParams(false);
      const tabelHeadersData = [
        {
          name: "Parent Name",
        },
        {
          name: "Student Name",
        },
        {
          name: "Email",
        },
        {
          name: "Start Month",
        },
        {
          name: "Class",
        },
      ];
      setTableHeader(tabelHeadersData);
      dispatch(generatetableheadersData(tabelHeadersData));
      dispatch(getReportSelected(e.target.value));
    } else if (e.target.value === "Mailing List") {
      setShowAttendenceSearchParams(false);
      setShowCategorySummaryParams(false);
      setShowDropOffSearchParams(false);
      setMailingListSearchParams(true);
      setPhotoRealeaseSearchParams(false);
      setRollSheetSearchParams(false);
      const tabelHeadersData = [
        {
          name: "First Name",
        },
        {
          name: "Last Name",
        },
        {
          name: "Address",
        },
        {
          name: "City",
        },
        {
          name: "State",
        },
        {
          name: "Zip",
        },
        {
          name: "Home Phone",
        },
        {
          name: "Email",
        },
        {
          name: "Email 2",
        },
        {
          name: "Enrollment Type",
        },
        {
          name: "Age",
        },
        {
          name: "Gender",
        },
        {
          name: "Class Name",
        },
        {
          name: "Category Name",
        },
      ];
      setTableHeader(tabelHeadersData);
      dispatch(generatetableheadersData(tabelHeadersData));
      dispatch(getReportSelected(e.target.value));
    } else if (e.target.value === "Photo Release Report") {
      setShowAttendenceSearchParams(false);
      setShowCategorySummaryParams(false);
      setShowDropOffSearchParams(false);
      setMailingListSearchParams(false);
      setPhotoRealeaseSearchParams(true);
      setRollSheetSearchParams(false);
      const tabelHeadersData = [
        {
          name: "Parent First Name",
        },
        {
          name: "Parent Last Name",
        },
        {
          name: "Address",
        },
        {
          name: "City",
        },
        {
          name: "State",
        },
        {
          name: "Zip",
        },
        {
          name: "Home Phone",
        },
        {
          name: "Email ",
        },
        {
          name: "Email 2",
        },
        {
          name: "Gender",
        },
        {
          name: "Student Age",
        },
        {
          name: "Active",
        },
      ];
      setTableHeader(tabelHeadersData);
      dispatch(generatetableheadersData(tabelHeadersData));
      dispatch(getReportSelected(e.target.value));
    } else if (e.target.value === "Class Rollsheet") {
      setShowAttendenceSearchParams(false);
      setShowCategorySummaryParams(false);
      setShowDropOffSearchParams(false);
      setMailingListSearchParams(false);
      setPhotoRealeaseSearchParams(false);
      setRollSheetSearchParams(true);
      const tabelHeadersData = [
        {
          name: "Name",
        },
        {
          name: "Birthdate",
        },
        {
          name: "Age",
        },
        {
          name: "Gender",
        },
        {
          name: "Attending/Pick-Up with",
        },
        {
          name: "Email",
        },
        {
          name: "Auth. Photos",
        },
        // {
        //   name: "Type"
        // },
        {
          name: "Attendence",
        },
      ];
      setTableHeader(tabelHeadersData);
      dispatch(generatetableheadersData(tabelHeadersData));
      dispatch(getReportSelected(e.target.value));
    }
    setShowGeneratePDFBtn(false);
    resetHandler();
    resetOnChangeReport();
  };

  const handleBtnExport = () => {
    const tableContent = document.getElementById("tablePdfContent");
    if (!tableContent) {
      return;
    }
    const csvData = convertToCSV(tableContent);
    downloadCSV(csvData, "mydata.csv");
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const isActiveHandler = (e) => {
    e.target.value === "active" ? setIsActive(true) : setIsActive(false);
  };
  const typeDetailsHandler = (e) => {
    setTypeDetails(e.target.value);
  };
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setSelectedRange((prevState) => ({
      ...prevState,
      [name]: parseInt(value),
    }));
  };

  useEffect(() => {
    fetchData();
    fetchCategoryItems();
  }, [selectedReport]);

  const debouncedFetchData = debounce(fetchData, 2000);
  useEffect(() => {
    const pattern = /[^ ]/;
    if (pattern.test(formData.name) || formData.name?.length == 0) {
      if (formData.name !== undefined) {
        const timeoutId = setTimeout(() => {
          debouncedFetchData(fetchData);
        }, 900);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [formData.name]);

  function convertToCSV(table) {
    const rows = table.querySelectorAll("tr");
    const csv = [];
    for (const row of rows) {
      const rowData = [];
      const cells = row.querySelectorAll("td, th");
      for (const cell of cells) {
        rowData.push(cell.textContent.trim());
      }
      csv.push(rowData.join(","));
    }
    return csv.join("\n");
  }

  function downloadCSV(content, filename) {
    const csvContent =
      "data:text/csv;charset=utf-8," + encodeURIComponent(content);
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const classFilterHandler = (e) => {
    setFilterText(e.target.value);
  };

  let filterClass;
  filterClass = classes?.filter(
    (item) =>
      item?.name?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
      item?.id?.toString()?.includes(filterText?.toLowerCase())
  );

  const filterCategory = category?.filter((item) =>
    item?.name?.toLowerCase()?.includes(filterText?.toLowerCase())
  );

  const resetOnChangeReport = () => {
    setShowAttendenceStudentStatus(false);
    setShowCategoryList(false);
    setShowDropOffClassList(false);
    setShowGenderMailingList(false);
    setActiveInActiveContent(false);
    setShowWeekdays(false);
  };

  const handleDateChange = (value) => {
    const formattedDate = value?.toLocaleDateString("en-CA");
    setStartDate(formattedDate);

    if (selectedReport === "Class Rollsheet") {
      if (value instanceof Date) {
        const formattedDate = `${(value.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${value
          .getDate()
          .toString()
          .padStart(2, "0")}-${value.getFullYear()}`;
        setRollSheetStartDate(formattedDate);
      } else {
        setRollSheetStartDate(null); // Handle null or invalid dates
      }
    }
  };

  const handleEndDateChange = (value) => {
    const formattedDate = value?.toLocaleDateString("en-CA");
    setEndDate(formattedDate);

    if (selectedReport === "Class Rollsheet") {
      if (value instanceof Date) {
        const formattedDate = `${(value.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${value
          .getDate()
          .toString()
          .padStart(2, "0")}-${value.getFullYear()}`;
        setRollSheetEndDate(formattedDate);
      } else {
        setRollSheetEndDate(null); // Handle null or invalid dates
      }
    }
  };

  const generateClassRollsheetPdf = () => {
    if (selectedReport === "Mailing List") {
      navigate("/admin/mailingListPdf", {
        state: {
          selectedReport,
          mailingListData: mailingListData,
          date: {
            copyStartDate: startDate,
            copyEndDate: endDate,
          },
        },
      });
      return;
    }
    window.open(`/admin/rollSheetPDF`, "_blank");
    setShowGeneratePDFBtn(false);
    return;
    // navigate("/admin/rollSheetPDF",  {
    //   state:
    //   {
    //     rollSheetCompleteData: rollSheetCompleteData,
    //     date: dateCopy
    //   },
    // });
  };

  const addWeekDayHandler = async (weekday, e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedWeekdays([...selectedWeekdays, weekday]);
    } else {
      setSelectedWeekdays(selectedWeekdays.filter((day) => day !== weekday));
    }
  };

  useEffect(() => {
    if (selectedWeekdays?.length > 0) {
      getClassesByWeekdayName();
    } else {
      getClassesWithoutWeekdays();
    }
  }, [selectedWeekdays, isEnded, selectRow, currentPage, filterText]);

  const getClassesByWeekdayName = async () => {
    setShowClassLoader(true);
    const classByWeekdays = await getClassesByWeekdays(
      selectedWeekdays,
      token,
      isEnded,
      currentPage,
      selectRow,
      filterText,
      rollSheetSearchState
    );
    setClasses(classByWeekdays?.data);
    setPageCount(classByWeekdays?.totalPages);
    setShowClassLoader(false);
  };

  const getClassesWithoutWeekdays = async () => {
    setShowClassLoader(true);

    const getData = await getClassesNew(
      token,
      isEnded,
      currentPage,
      selectRow,
      filterText,
      rollSheetSearchState
    );
    setClasses(getData?.data);
    setPageCount(getData?.totalPages);
    setShowClassLoader(false);
  };

  const selectAllClassRollSheetHandler = () => {
    setShowDeselectAllClass(true);
    setSelectedClasses(
      classes?.map((item) => ({
        ...selectedClasses,
        isCamp: item?.isCamp,
        id: item?.id,
      }))
    );
    setSelectedClassDummy(
      classes?.map(
        (val) =>
          val?.name +
          val?.class_schedules?.map(
            (item) =>
              item?.class_schedules_details?.map((item) => item?.weekday) +
              item?.id
          )
      )
    );
  };
  const deSelectAllClassRollSheetHandler = () => {
    setShowDeselectAllClass(false);
    setSelectedClasses([]);
    setSelectedClassDummy([]);
  };

  const toglleClassListHandler = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setIsEnded(true);
    } else {
      setIsEnded(false);
    }
  };

  const selectAllCategoryHandler = () => {
    setSelectedCategory(filterCategory?.map((item) => item?.name));
    setSelectedCategoryId(filterCategory?.map((item) => parseInt(item?.id)));
    setIsAllCategorySelected(true);
  };

  const deSelectAllCategoryHandler = () => {
    setIsAllCategorySelected(false);
    setSelectedCategory([]);
    setSelectedCategoryId([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dateRangePickerRef.current &&
        !dateRangePickerRef.current.contains(event.target)
      ) {
        setShowDropOffClassList(false);
        setShowCategoryList(false);
        setShowWeekdays(false);
        setShowAttendenceStudentStatus(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dateRangePickerRef]);

  const schedulesHandler = (value) => {
    let element = [];
    element.push(
      <span>
        {value?.class_schedules?.map((item) => (
          <span>
            {item?.class_schedules_details?.map((item) => (
              <span>{item?.weekday + ` ,`}</span>
            ))}{" "}
            (ScheduleId:{item?.id})
          </span>
        ))}
      </span>
    );
    return element;
  };
  const attendanceSummaryPdfHandler = () => {
    window.open("/admin/attendance-summary");
  };

  const selectAllClassesHandler = () => {
    setSelectedClassDummy(
      filterClass?.map((items) => items?.name + `(ID${items?.id})`)
    );
    setTempClasses(filterClass?.map((item) => item?.id));
  };

  return (
    <>
      <div className="container--component">
        <div className="container--grid">
          <NavigationComponent />
          <div className="Adminpage">
            <Header title="Reports" subTitle="(Manage Available Reports)" />
            <section className="Adminpage__section">
              <div>
                <div className="generate_report_select_report_dropdown">
                  <div className="reports_upper_content_header">
                    <h2 className="Adminpage__title">Select Report:</h2>
                  </div>
                  <div>
                    <button
                      disabled={selectedReport ? false : true}
                      className="adminbtn adminbtn-light export_btn_attendence"
                      id="btnCategory"
                      onClick={handleBtnExport}
                    >
                      Export Report
                    </button>
                  </div>
                </div>
                <div
                  className="adminForm__group"
                  style={{
                    marginBottom: "1rem",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <select
                    ref={dateRangePickerRef}
                    name=""
                    id=""
                    className="adminform__select"
                    style={{ width: "5rem" }}
                    onChange={selectReportHandler}
                  >
                    <option value="" selected disabled>
                      Select report
                    </option>
                    <option value="Attendance Summary">
                      Attendance Summary
                    </option>
                    <option value="Category Summary">Category Summary</option>
                    <option value="Drop Off Report">Drop Off Report</option>
                    <option value="Mailing List">Mailing List</option>
                    <option value="Photo Release Report">
                      Photo Release Report
                    </option>
                    <option value="Class Rollsheet">Class Roll sheet</option>
                  </select>
                  {selectedReport === "Category Summary" && (
                    <div className="category_sum_disclaimer">
                      <p
                        className="disclaimer_category_sum_title"
                        style={{ color: "red", fontSize: "1rem" }}
                      >
                        (*Select date after 15th May 2024 for the Category
                        Summary using this system.)
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <span className="Adminpage__title">Search by:</span>
              <span className="rollsheet_desclaimer">
                {selectedReport === "Class Rollsheet"
                  ? ""
                  : selectedReport === "Attendance Summary"
                  ? ""
                  : ""}
              </span>
              <div className="Adminpage__head" style={{ float: "right" }}>
                <div className="generate_report_btn_container">
                  <button
                    className="adminbtn adminbtn-primary"
                    disabled={selectedReport ? false : true}
                    onClick={() => {
                      handleSearchBox();
                    }}
                    style={{
                      display:
                        selectedReport === ""
                          ? "none"
                          : selectedReport === "Class Rollsheet"
                          ? (startDate !== "" || rollSheetStartDate) &&
                            endDate !== "" &&
                            selectedClasses?.length > 0
                            ? "inline-flex"
                            : "none"
                          : "inline-flex",
                    }}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.96326 9.16769L12.3473 11.569M11.2455 6.05994C11.2455 8.49399 9.27231 10.4672 6.83826 10.4672C4.40421 10.4672 2.43103 8.49399 2.43103 6.05994C2.43103 3.62589 4.40421 1.65271 6.83826 1.65271C9.27231 1.65271 11.2455 3.62589 11.2455 6.05994Z"
                        stroke="white"
                        strokeWidth="1.10181"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Generate Report
                  </button>
                </div>
              </div>

              {showNestedBoxForMailingList &&
                selectedReport === "Mailing List" && (
                  <div className="categoryItemContainer">
                    <div className="closeItemBtn">
                      <button
                        onClick={() => {
                          setShowNestedBoxforMailingList(false);
                          resetHandler();
                        }}
                      >
                        <span className="reportSubTitlecloseIcon">X</span>
                      </button>
                    </div>
                    <div>
                      <ul className="nestedSelectBox">
                        <input
                          type="text"
                          className="classNameSearchBox"
                          placeholder="Search class..."
                          onChange={classFilterHandler}
                        />
                        {filterClass?.map((items) => (
                          <li className="childCheckBoxContainer">
                            <input
                              type="checkbox"
                              className="childCheckbox"
                              // checked={selectedClasses.includes(items?.name)}
                              data-value={items?.name}
                              onChange={(e) => {
                                handleCheckboxChangeForClasses(e, items?.id);
                                // handleReportSelectInput("name", categoryItem?.name,"inputBox")
                              }}
                            />
                            {items?.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}

              <div className="calender_radio_preabs_container">
                {showAttendenceSearchParams && (
                  <div className="attendance_summary_search_param">
                    <div className="calenderContainer">
                      <div>
                        {
                          <DatePicker
                            dateFormat="yyyy-MM-dd"
                            placeholderText={`${
                              startDate
                                ? dateFormatter(startDate)
                                : "Select Start Date"
                            }`}
                            className="adminform__input _attendance_startDate"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={(date) => handleDateChange(date)}
                          />
                        }
                      </div>
                    </div>

                    <div>
                      {
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          placeholderText={`${
                            endDate ? dateFormatter(endDate) : "Select End Date"
                          }`}
                          showMonthDropdown
                          showYearDropdown
                          className="adminform__input"
                          dropdownMode="select"
                          minDate={new Date(startDate)}
                          //  selected={endDate}
                          onChange={(date) => handleEndDateChange(date)}
                        />
                      }
                    </div>

                    <div className="makeup_trail_dropins_radio">
                      <div className="radio_make">
                        <div
                          className="adminform__input select_type_attendance_container"
                          onClick={() => setShowDropdown(!showDropDown)}
                        >
                          <label htmlFor="select-type-attendence">
                            Select type
                          </label>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.70615 8.71067C5.31565 9.10117 5.31565 9.73437 5.70615 10.1249L10.5984 15.0123C11.3796 15.7927 
12.6454 15.7924 13.4262 15.0116L18.3165 10.1213C18.707 9.73077 18.707 9.09757 18.3165 8.70707C17.926 8.31647 17.2928 8.31647 16.9023 8.70707L12.7167 
12.8927C12.3261 13.2832 11.693 13.2832 11.3025 12.8927L7.12035 8.71067C6.72985 8.32017 6.09665 8.32017 5.70615 8.71067Z"
                              fill="gray"
                            ></path>
                          </svg>
                        </div>
                        {showDropDown && (
                          <ul className="attendance_option_dropdown_list">
                            <li>
                              <input
                                type="checkbox"
                                id="m"
                                onChange={(e) => statusTypeHandler(e, "M")}
                              />
                              <label htmlFor="m">Makeups</label>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                id="d"
                                onChange={(e) => statusTypeHandler(e, "D")}
                              />
                              <label htmlFor="d">Dropins</label>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                id="t"
                                onChange={(e) => statusTypeHandler(e, "T")}
                              />
                              <label htmlFor="t">Trials</label>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {showCategorySummarySearchParams && (
                <div className="category_search_params_container">
                  <div className="calenderContainer">
                    <div>
                      {
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          placeholderText={`${
                            startDate
                              ? dateFormatter(startDate)
                              : "Select Start Date"
                          }`}
                          className="adminform__input"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          onChange={(date) => handleDateChange(date)}
                        />
                      }
                    </div>
                  </div>
                  <div>
                    {
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        placeholderText={`${
                          endDate ? dateFormatter(endDate) : "Select End Date"
                        }`}
                        showMonthDropdown
                        showYearDropdown
                        className="adminform__input"
                        dropdownMode="select"
                        minDate={new Date(startDate)}
                        onChange={(date) => handleEndDateChange(date)}
                      />
                    }
                  </div>
                  <div>
                    <h3
                      className="category_list_title"
                      ref={dateRangePickerRef}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowCategoryList(!showCategoryList);
                      }}
                    >
                      {selectedCategory?.length > 0
                        ? selectedCategory?.map((category) => (
                            <span>{category?.substring(0, 3) + "..."},</span>
                          ))
                        : "Choose Category"}
                    </h3>
                    {showCategoryList && (
                      <ul className="nestedSelectBox" ref={dateRangePickerRef}>
                        <li>
                          <input
                            type="text"
                            placeholder="Search category..."
                            className="category_search_bar"
                            onChange={(e) => {
                              setFilterText(e.target.value);
                            }}
                          />
                        </li>
                        {
                          <li className="childCheckBoxContainer">
                            {!isAllCategorySelected ? (
                              <button
                                className="select_all_class_name_btn"
                                onClick={() => selectAllCategoryHandler()}
                              >
                                Select all
                              </button>
                            ) : (
                              <button
                                className="select_all_class_name_btn"
                                onClick={() => deSelectAllCategoryHandler()}
                              >
                                Deselect all
                              </button>
                            )}
                          </li>
                        }
                        {showCategoryLoader ? (
                          <div className="fade_loader_container">
                            <FadeLoader color="#5497C7" height={25} width={9} />
                          </div>
                        ) : (
                          filterCategory?.map((categoryItem) => (
                            <li className="childCheckBoxContainer">
                              <input
                                type="checkbox"
                                className="childCheckbox"
                                checked={selectedCategory.includes(
                                  categoryItem?.name
                                )}
                                data-value={`${categoryItem?.id}|${categoryItem?.name}`}
                                onChange={(e) => {
                                  handleCheckboxChange(e, categoryItem?.id);
                                }}
                              />
                              {categoryItem?.name}
                            </li>
                          ))
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              )}
              {showDropOffSearchParams && (
                <div className="dropOffSearchParams_container">
                  <div
                    className="calenderContainer"
                    style={{ display: "flex", gap: "20px" }}
                  >
                    <div style={{ marginTop: "15px" }}>
                      <p style={{ fontSize: "15px", fontWeight: "600" }}>
                        Select Start Date
                      </p>
                      {
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          placeholderText={`${
                            startDate
                              ? dateFormatter(startDate)
                              : "Select Start Date"
                          }`}
                          className="adminform__input"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          onChange={(date) => handleDateChange(date)}
                        />
                      }
                    </div>

                    <div style={{ marginTop: "15px" }}>
                      <p style={{ fontSize: "15px", fontWeight: "600" }}>
                        Select End Date
                      </p>
                      {
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          placeholderText={`${
                            endDate ? dateFormatter(endDate) : "Select End Date"
                          }`}
                          showMonthDropdown
                          showYearDropdown
                          className="adminform__input"
                          dropdownMode="select"
                          minDate={new Date(startDate)}
                          onChange={(date) => handleEndDateChange(date)}
                        />
                      }
                    </div>
                  </div>

                  <div className="classList_container"></div>
                </div>
              )}

              {mailingListSearchParams && (
                <div className="mailing_list_search_param_container">
                  <div className="mailing_list_upper_container">
                    <input
                      type="number"
                      placeholder="Enter the min age (years)"
                      name="startAge"
                      className="adminform__input min-max-age-input"
                      onChange={(e) => handleSelectChange(e)}
                    />
                    <input
                      type="number"
                      placeholder="Enter the max age (years)"
                      name="endAge"
                      className="adminform__input min-max-age-input"
                      onChange={(e) => handleSelectChange(e)}
                    />

                    <div className="calenderContainer">
                      <div>
                        {
                          <DatePicker
                            dateFormat="yyyy-MM-dd"
                            placeholderText={`${
                              startDate
                                ? dateFormatter(startDate)
                                : "Select Start Date"
                            }`}
                            className="adminform__input"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={(date) => handleDateChange(date)}
                          />
                        }
                      </div>
                    </div>

                    <div>
                      {
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          placeholderText={`${
                            endDate ? dateFormatter(endDate) : "Select End Date"
                          }`}
                          showMonthDropdown
                          showYearDropdown
                          className="adminform__input"
                          dropdownMode="select"
                          minDate={new Date(startDate)}
                          onChange={(date) => handleEndDateChange(date)}
                        />
                      }
                    </div>
                  </div>
                  <div className="mailing_list_lower_section_container">
                    <div className="classList_container">
                      <h3
                        className="dropOff_classlist_title"
                        ref={dateRangePickerRef}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowCategoryList(false);
                          setShowDropOffClassList(!showDropOffClassList);
                        }}
                      >
                        {selectedClassDummy?.length > 0
                          ? selectedClassDummy?.map((classItem, index) => (
                              <span
                                className="selected_class_name_rollsheet"
                                key={classItem + index}
                              >
                                {classItem + "...,"}
                              </span>
                            ))
                          : "Select Classes"}
                      </h3>

                      {showDropOffClassList && (
                        <ul
                          className="nestedSelectBox"
                          ref={dateRangePickerRef}
                        >
                          <input
                            type="text"
                            className="classNameSearchBox"
                            placeholder="Search class..."
                            onChange={classFilterHandler}
                          />
                          <li>
                            {!isSelectAllClass && (
                              <button
                                style={{
                                  backgroundColor: "#5497c7",
                                  padding: "5px 8px",
                                  color: "white",
                                  borderRadius: "3px",
                                }}
                                onClick={() => selectAllClassesHandler()}
                              >
                                Select all
                              </button>
                            )}
                          </li>
                          {showClassLoader ? (
                            <div className="fade_loader_container">
                              <FadeLoader
                                color="#5497C7"
                                height={25}
                                width={9}
                              />
                            </div>
                          ) : (
                            filterClass?.map((items) => (
                              <li className="childCheckBoxContainer">
                                <input
                                  type="checkbox"
                                  className="childCheckbox"
                                  checked={selectedClassDummy.includes(
                                    items?.name + `(ID${items?.id})`
                                  )}
                                  data-value={items?.id}
                                  onChange={(e) => {
                                    handleCheckboxChangeForClasses(
                                      e,
                                      items?.id,
                                      items?.name + `(ID${items?.id})`,
                                      items?.isCamp
                                    );
                                  }}
                                />
                                {items?.name + " " + `(ID ${items?.id})`}
                              </li>
                            ))
                          )}
                        </ul>
                      )}
                    </div>
                    <div>
                      <h3
                        className="dropOff_classlist_title"
                        onClick={() => {
                          setShowGenderMailingList(!showGenderMailingList);
                        }}
                      >
                        Select Gender
                      </h3>
                      {showGenderMailingList && (
                        <div className="gender_container">
                          <div className="male_mailing_list">
                            <input
                              type="radio"
                              name="gender"
                              id="Male"
                              value="Male"
                              onChange={handleGenderChange}
                            />
                            <label for="Male" className="radioLabel">
                              Male
                            </label>
                          </div>
                          <div className="female_mailing_list">
                            <input
                              type="radio"
                              name="gender"
                              id="Female"
                              value="Female"
                              onChange={handleGenderChange}
                            />
                            <label for="Female" className="radioLabel">
                              Female
                            </label>
                          </div>
                          <div className="coed_mailing_list">
                            <input
                              type="radio"
                              name="gender"
                              id="Both"
                              value="Both"
                              onChange={handleGenderChange}
                            />
                            <label for="Both" className="radioLabel">
                              Coed
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {photoRealeaseSearchParams && (
                <div className="photo_release_searchParam_container">
                  <div className="">
                    <h3
                      className="dropOff_classlist_title"
                      onClick={() => {
                        setActiveInActiveContent(!activeInActiveContent);
                      }}
                    >
                      Active/In-active
                    </h3>
                    {activeInActiveContent && (
                      <div className="active_inactive_container">
                        <div className="radioContainer">
                          <input
                            type="radio"
                            className="radioInput_pres_abs"
                            name="isActive"
                            id="active"
                            value="active"
                            onChange={isActiveHandler}
                          />
                          <label for="active" className="radioLabel">
                            Active
                          </label>
                        </div>
                        <div className="radioContainer">
                          <input
                            type="radio"
                            className="radioInput_pres_abs"
                            name="isActive"
                            id="in-active"
                            value="in-active"
                            onChange={isActiveHandler}
                          />
                          <label for="in-active" className="radioLabel">
                            In Active
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <h3
                      className="dropOff_classlist_title"
                      onClick={() => {
                        setShowGenderMailingList(!showGenderMailingList);
                      }}
                    >
                      Select Gender
                    </h3>

                    {showGenderMailingList && (
                      <div className="gender_container">
                        <div className="male_mailing_list">
                          <input
                            type="radio"
                            name="gender"
                            id="Male"
                            value="Male"
                            onChange={handleGenderChange}
                          />
                          <label for="Male" className="radioLabel">
                            Male
                          </label>
                        </div>
                        <div className="female_mailing_list">
                          <input
                            type="radio"
                            name="gender"
                            id="Female"
                            value="Female"
                            onChange={handleGenderChange}
                          />
                          <label for="Female" className="radioLabel">
                            Female
                          </label>
                        </div>
                        <div className="coed_mailing_list">
                          <input
                            type="radio"
                            name="gender"
                            id="Both"
                            value="Both"
                            onChange={handleGenderChange}
                          />
                          <label for="Both" className="radioLabel">
                            Coed
                          </label>
                        </div>
                      </div>
                    )}
                  </div>

                  <input
                    type="number"
                    placeholder="Enter the min age (Years)"
                    name="startAge"
                    className="adminform__input min-max-age-input"
                    onChange={(e) => handleSelectChange(e)}
                  />
                  <input
                    type="number"
                    placeholder="Enter the max age (Years)"
                    name="endAge"
                    className="adminform__input min-max-age-input"
                    onChange={(e) => handleSelectChange(e)}
                  />
                </div>
              )}

              {rollSheetSearchParams && (
                <div>
                  <div className="particular_class_container">
                    <input
                      type="checkbox"
                      id="show_active_class"
                      checked={isEnded}
                      onChange={(e) => toglleClassListHandler(e)}
                    />
                    <label
                      htmlFor="show_active_class"
                      className="active_class_label"
                    >
                      {" "}
                      Don't show classes that have ended
                    </label>
                  </div>
                  <div className="class_rollsheet_search_params_container calenderContainer">
                    <div className="calenderContainer_start_end">
                      <div>
                        {
                          <div>
                            <span
                              style={{
                                color: "red",
                                fontSize: "1rem",
                                float: "right",
                              }}
                            >
                              *
                            </span>

                            <DatePicker
                              dateFormat="MM-dd-yyyy"
                              placeholderText={
                                startDate
                                  ? dateFormatter(startDate)
                                  : "Select Start Date"
                              }
                              className="adminform__input"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              onChange={(date) => handleDateChange(date)}
                            />
                          </div>
                        }
                      </div>
                    </div>

                    <div>
                      {
                        <div>
                          <span
                            style={{
                              color: "red",
                              fontSize: "1rem",
                              float: "right",
                            }}
                          >
                            *
                          </span>
                          <DatePicker
                            dateFormat="yyyy-MM-dd"
                            placeholderText={`${
                              endDate
                                ? dateFormatter(endDate)
                                : "Select End Date"
                            }`}
                            showMonthDropdown
                            showYearDropdown
                            className="adminform__input"
                            dropdownMode="select"
                            minDate={new Date(rollSheetStartDate)}
                            onChange={(date) => handleEndDateChange(date)}
                          />
                        </div>
                      }
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn adminbtn-light"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowWeekdays(false);
                        setShowDropOffClassList(!showDropOffClassList);
                      }}
                    >
                      Choose Classes
                    </button>

                    {showDropOffClassList && (
                      <ul
                        className="nestedSelectBox"
                        ref={dateRangePickerRef}
                        style={{
                          width: "100%",
                          height: "30rem",
                          width: "50rem",
                          overflow: "auto",
                        }}
                      >
                        <div style={{ display: "flex", gap: "20px" }}>
                          <select
                            className="adminform__select"
                            style={{ width: "50%" }}
                            onChange={(e) => {
                              setFilterText("");
                              setRollSheetSearchState(e.target.value);
                            }}
                          >
                            <option value="" selected disabled>
                              Search
                            </option>
                            <option value="className">Class Name</option>
                            <option value="id">Class Id</option>
                          </select>
                          {rollSheetSearchState && (
                            <input
                              style={{ width: "100%" }}
                              type="text"
                              className="classNameSearchBox"
                              placeholder={`Search ${rollSheetSearchState}...`}
                              onChange={classFilterHandler}
                              value={filterText}
                            />
                          )}
                        </div>
                        {showClassLoader ? (
                          <div className="fade_loader_container">
                            <FadeLoader color="#5497C7" height={25} width={9} />
                          </div>
                        ) : (
                          <div>
                            <div className="weekdays_containers_fluid">
                              <ul className="select_weekdays_container">
                                <li className="roll_sheet_weekday">
                                  <input
                                    type="checkbox"
                                    className="childCheckbox"
                                    data-value="Monday"
                                    checked={selectedWeekdays?.includes(
                                      "Monday"
                                    )}
                                    onChange={(e) =>
                                      addWeekDayHandler("Monday", e)
                                    }
                                  />
                                  <span>Monday</span>
                                </li>
                                <li className="roll_sheet_weekday">
                                  <input
                                    type="checkbox"
                                    className="childCheckbox"
                                    data-value="Tuesday"
                                    checked={selectedWeekdays?.includes(
                                      "Tuesday"
                                    )}
                                    onChange={(e) =>
                                      addWeekDayHandler("Tuesday", e)
                                    }
                                  />
                                  <span>Tuesday</span>
                                </li>
                                <li className="roll_sheet_weekday">
                                  <input
                                    type="checkbox"
                                    className="childCheckbox"
                                    data-value="Wednesday"
                                    checked={selectedWeekdays?.includes(
                                      "Wednesday"
                                    )}
                                    onChange={(e) =>
                                      addWeekDayHandler("Wednesday", e)
                                    }
                                  />
                                  <span>Wednesday</span>
                                </li>
                                <li className="roll_sheet_weekday">
                                  <input
                                    type="checkbox"
                                    className="childCheckbox"
                                    data-value="Thursday"
                                    checked={selectedWeekdays?.includes(
                                      "Thursday"
                                    )}
                                    onChange={(e) =>
                                      addWeekDayHandler("Thursday", e)
                                    }
                                  />
                                  <span>Thursday</span>
                                </li>
                                <li className="roll_sheet_weekday">
                                  <input
                                    type="checkbox"
                                    className="childCheckbox"
                                    data-value="Friday"
                                    checked={selectedWeekdays?.includes(
                                      "Friday"
                                    )}
                                    onChange={(e) =>
                                      addWeekDayHandler("Friday", e)
                                    }
                                  />
                                  <span>Friday</span>
                                </li>
                                <li className="roll_sheet_weekday">
                                  <input
                                    type="checkbox"
                                    className="childCheckbox"
                                    data-value="Saturday"
                                    checked={selectedWeekdays?.includes(
                                      "Saturday"
                                    )}
                                    onChange={(e) =>
                                      addWeekDayHandler("Saturday", e)
                                    }
                                  />
                                  <span>Saturday</span>
                                </li>
                                <li className="roll_sheet_weekday">
                                  <input
                                    type="checkbox"
                                    className="childCheckbox"
                                    data-value="Sunday"
                                    checked={selectedWeekdays?.includes(
                                      "Sunday"
                                    )}
                                    onChange={(e) =>
                                      addWeekDayHandler("Sunday", e)
                                    }
                                  />
                                  <span>Sunday</span>
                                </li>
                              </ul>
                            </div>
                            <div className="selectAllClassContainer">
                              {showDeselectAll ? (
                                <button
                                  className="select_all_class_name_btn"
                                  onClick={deSelectAllClassRollSheetHandler}
                                >
                                  Deselect All
                                </button>
                              ) : (
                                <button
                                  className="select_all_class_name_btn"
                                  onClick={selectAllClassRollSheetHandler}
                                >
                                  Select All
                                </button>
                              )}
                            </div>
                            <div className="Table">
                              <div className="Heading">
                                <div
                                  className="Cell  Heading_style"
                                  style={{ width: "65px" }}
                                >
                                  <p>Id</p>
                                </div>
                                <div
                                  className="Cell  Heading_style"
                                  style={{ width: "65px" }}
                                >
                                  <p>Class name</p>
                                </div>

                                <div
                                  className="Cell  Heading_style"
                                  style={{ width: "65px" }}
                                >
                                  <p>Season</p>
                                </div>

                                <div
                                  className="Cell  Heading_style"
                                  style={{ width: "65px" }}
                                >
                                  <p>Age</p>
                                </div>
                                <div
                                  className="Cell  Heading_style"
                                  style={{ width: "65px" }}
                                >
                                  <p>Tuition</p>
                                </div>
                              </div>

                              {classes?.map((val, index) => {
                                return (
                                  <label
                                    htmlFor={`rollsheet_class_select_id-${index}`}
                                    key={val.id + index}
                                    className="Row"
                                    style={{
                                      backgroundColor:
                                        selectedClassDummy.includes(
                                          val?.name +
                                            val?.class_schedules?.map(
                                              (item) =>
                                                item?.class_schedules_details?.map(
                                                  (item) => item?.weekday
                                                ) + item?.id
                                            )
                                        )
                                          ? "#5497C7"
                                          : "",
                                      color: selectedClassDummy.includes(
                                        val?.name +
                                          val?.class_schedules?.map(
                                            (item) =>
                                              item?.class_schedules_details?.map(
                                                (item) => item?.weekday
                                              ) + item?.id
                                          )
                                      )
                                        ? "white"
                                        : "",
                                      fontWeight: "500",
                                    }}
                                  >
                                    <div className="Cell">
                                      <input
                                        type="checkbox"
                                        className="childCheckbox"
                                        id={`rollsheet_class_select_id-${index}`}
                                        checked={selectedClassDummy.includes(
                                          val?.name +
                                            val?.class_schedules?.map(
                                              (item) =>
                                                item?.class_schedules_details?.map(
                                                  (item) => item?.weekday
                                                ) + item?.id
                                            )
                                        )}
                                        data-value={val?.id}
                                        style={{ display: "none" }}
                                        onChange={(e) => {
                                          handleCheckboxChangeForClasses(
                                            e,
                                            val?.id,
                                            val?.name +
                                              val?.class_schedules?.map(
                                                (item) =>
                                                  item?.class_schedules_details?.map(
                                                    (item) => item?.weekday
                                                  ) + item?.id
                                              ),
                                            val?.isCamp
                                          );
                                        }}
                                      />{" "}
                                      &nbsp;
                                      {val?.id}
                                    </div>
                                    <div className="Cell">
                                      <p>
                                        {val?.name}
                                        {schedulesHandler(val)}
                                      </p>
                                    </div>
                                    <div className="Cell">
                                      <p>{val?.fk_season_classes}</p>
                                    </div>
                                    <div className="Cell">
                                      <p>
                                        {val?.min_age}-{val?.max_age} months
                                      </p>
                                    </div>
                                    <div className="Cell">
                                      <p>{val?.tuition}</p>
                                    </div>
                                  </label>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              )}

              {((selectedReport === "Class Rollsheet" && showPdfGenerateBtn) ||
                selectedReport === "Mailing List") && (
                <div style={{ float: "right", marginTop: "-55px" }}>
                  <button
                    className="adminbtn adminbtn-light"
                    onClick={() => {
                      generateClassRollsheetPdf();
                    }}
                  >
                    Generate PDF
                  </button>
                </div>
              )}
              {selectedReport === "Attendance Summary" &&
                attendanceSumaryData?.length > 0 && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      className=" adminbtn adminbtn-light"
                      onClick={() => attendanceSummaryPdfHandler()}
                    >
                      Generate PDF
                    </button>
                  </div>
                )}
              <div
                className="Adminpage__body"
                id="tablePdfContent"
                style={{
                  pageBreakInside: "avoid",
                  marginTop:
                    selectedReport === "Attendance Summary" &&
                    attendanceSumaryData?.length > 0
                      ? "60px"
                      : "0px",
                }}
              >
                {selectedReport !== "Class Rollsheet" && (
                  <h1 className="reportHeading">{selectedReport}</h1>
                )}

                {selectedReport ? (
                  <div>
                    <div ref={componentRef} style={{ width: "100%" }}>
                      {showSpinnerLoader ? (
                        <div className="spinner_loader_section">
                          <ScaleLoader
                            color="#5497C7"
                            height={63}
                            margin={3}
                            radius={5}
                            width={8}
                          />
                        </div>
                      ) : (
                        <>
                          <div className="table_main_container_with_scroll_up">
                            {selectedReport !== "Category Summary" && (
                              <ToRender
                                selectedReport={selectedReport}
                                startDate={startDate}
                                endDate={endDate}
                                rollSheetCompleteData={rollSheetCompleteData}
                                copyStartDate={dateCopy?.copyStartDate}
                                copyEndDate={dateCopy.copyEndDate}
                                showNoDataFound={showNoDataFound}
                                setScrollId={setScrollId}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="select_report_title">
                    <h1 className="NonSelectReportHeading">
                      Select the Report First
                    </h1>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
