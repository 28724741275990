import { Link, useNavigate, useParams } from "react-router-dom";
import { aboutPageContent } from "../../../api/contentApi";
import enrichmentLogo from "../../../parent-assets/images/enrichment-images/enrichment-logo.png";
import pinkImage from "../../../parent-assets/images/AboutUs.jpg";
import { useEffect, useState } from "react";
import { getSubPrograms } from "../../../../Admin/adminApi/subProgram";
import { getEnrichmentManageProgramData } from "../../../../Admin/adminApi/manageProgramData";
import axios from "axios";
import AboutUsShimmer from "../../../../Admin/components/ShimmerUi/AboutUsShimmer";

function EnrichmentComboMain() {
  const { id } = useParams();
  const [programId, setProgramId] = useState(id);
  const [subProgramData, setSubProgramData] = useState([]);
  const [enrichmentCategory, setEnrichmentCategory] = useState([]);
  const [showShimmer, setShowShimmer] = useState(false);
  const [showMore, setShowMore] = useState([]);
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();

  useEffect(() => {
    getEnrichementCombosData();
  }, []);

  const getEnrichementCombosData = async () => {
    setShowShimmer(true);
    const response = await getSubPrograms(token);
    setSubProgramData(response);
    setShowShimmer(false);
    const getEnrichmentManageData = await getEnrichmentManageProgramData(
      token,
      id
    );
    setEnrichmentCategory(getEnrichmentManageData?.data?.data);
    setShowMore(
      new Array(getEnrichmentManageData?.data?.data?.length)?.fill(false)
    );
  };

  const filterData = subProgramData?.filter((item) => item?.id == id);

  const enrichmentCategoryHandler = async (id, name) => {
    const postData = {
      categoryId: `${id}`,
    };

    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/getClassesCMSAdminForCombo`,
      postData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    navigate(`/enrichment-combo/${id}`, {
      state: {
        enrichmentClasses: response?.data?.data,
        programId: programId,
        programName: name,
      },
    });
  };
  const expandDescriptionHandler = (index) => {
    setShowMore((prevState) => {
      let temp = [...prevState];
      temp[index] = !temp[index];
      return temp;
    });
  };
  return (
    <>
      {showShimmer ? (
        <AboutUsShimmer />
      ) : (
        <div className="contentwraper">
          <section style={{ position: "relative" }}>
            <section className="siteBanner" style={{ background: "#ea519c" }}>
              <div className="siteBanner__overlay">
                <img
                  className="siteBanner__img"
                  src={
                    filterData[0]?.image
                      ? filterData[0]?.image
                      : aboutPageContent.aboutBannerImage.url
                  }
                  alt="banner"
                />
              </div>
            </section>
          </section>

          {/* BODY  */}
          <section className="breadcrumbSec">
            <div className="container">
              <div className="breadcrumb">
                <div className="breadcrumb__item">
                  <Link className="breadcrumb__link" to="/">
                    Back to results
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="facilitiesSec  enrichment_combo">
            <div className="container">
              <div
                className="picture "
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                <img
                  src={enrichmentLogo}
                  alt="gymtimeGuy"
                  className="picture__img enr_icon"
                  style={{
                    height: "100px",
                    position: "relative",
                    bottom: "9px",
                  }}
                />
                <h1 className="title title--withicon title--extrabold girls_gymnastics_title_new">
                  Enrichment Combo{" "}
                </h1>
              </div>

                <section
                  className="aboutusSec enr_abou"
                  style={{ padding: "0px 0 10px 0px" }}
                >
                  <div className="container">
                    {/* LISTS */}

                    {/* UPPER CARD  */}
                    <div className="aboutData">
                      <div className="aboutData__box">
                        <div className="enrichment_combo__row cheerleading_upper_content_row">
                          <div className="aboutData__col ">
                            <div className="content">
                              <div className="shortText">
                                <p className="enr_para">
                                  { filterData[0]?.description}
                                </p>
                              </div>
                            </div>
                      
                        </div>
                        <div className="aboutData__col">
                          <div className="picture picture--enrichment picture--enrichment--afterNone cheerleading_img">
                            <img
                              className="picture__img picture__img_enrichment_new"
                              src={pinkImage}
                              alt="About Us"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="programList">
                    {enrichmentCategory?.map((item, index) => (
                      <div className="programList__item" key={item?.id}>
                        <div className="programList__card">
                          <div
                            className="picture"
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              className="picture__img"
                              src={item?.image}
                              alt=""
                            />
                          </div>
                          <div className="detail">
                            <div className="content">
                              <h1 className="title title--extrabold">
                                {item?.name}{" "}
                              </h1>
                              <p className="enrichment_para_content">
                                {item?.description ? (
                                  <>
                                    {showMore?.[index]
                                      ? item?.description
                                      : item?.description?.slice(0, 200)}

                                    <button
                                      style={{ color: "red" }}
                                      onClick={() =>
                                        expandDescriptionHandler(index)
                                      }
                                    >
                                      {showMore?.[index]
                                        ? "...show less"
                                        : "...show more"}
                                    </button>
                                  </>
                                ) : (
                                  `With over 30 years of experience, we've established ourselves as the leader in child development.`
                                )}
                              </p>
                            </div>
                            <div className="buttons">
                              <button
                                className="btn btn--primary Enrichment_combo_btn"
                                onClick={() => {
                                  enrichmentCategoryHandler(
                                    item?.id,
                                    item?.name
                                  );
                                }}
                              >
                                More Info
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default EnrichmentComboMain;
