import axios from "axios";
import {
  ADD_CLASS_AGE_LIST,
  ADD_CLASS_LIST,
} from "../pages/adminQueries/AddClassList";
import { GET_CLASSES } from "../adminQueries/Classes";
import store from "../../store/store";
import { openSessionModal } from "../../reducers/sessionHandler";

export async function getAddClassList(token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: ADD_CLASS_LIST,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data?.getClassesCMSWithNoFkProgram;
  } catch (error) {
    console.error(error);
  }
}

export async function geAddClassListForAge(token) {
  const response = await axios.post(
    process.env.REACT_APP_BASE_URL + "/graphql",
    {
      query: ADD_CLASS_AGE_LIST,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response?.data?.data?.getClassesCMSWithNoFkAge;
}

// Student Delete Warning For Enrolled
export async function getWarningForStudent(token, studentID) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/students/student-delete-warning/${studentID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching student delete warning:", error);
    throw error;
  }
}

export async function getWarningForClass(token, classId, classItem) {
  let isCamp = classItem?.period == "Monthly" ? false : true;
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/admin/class/warn-delete/${classId}/${isCamp}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching student delete warning:", error);
    throw error;
  }
}

// Class List for Dropdown
export async function getClassForDropdown(token, page, sortID) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_CLASSES,
        variables: {
          pageInfo: {
            // isAsc: sortKey?.isAsc,
            limit: 300000,
            page: 0,
            sort: sortID,
            isAsc: true,
            // sort: sortKey?.sortId === null ? null: sortKey?.sortId?.toString() ,
          },
          season: "",
          className: "",
          category: "",
          tuition: null,
          maxAge: null,
          minAge: null,
          website: "",
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data?.getClassesForAdmin;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}

// Weekly Class List For Camp (in AddNew Class)
export async function getWeeklyClassByID(token, classID) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: `query getClassesQuery($classId: Int) {
          getWeeklyClass(classId: $classId) {
            name
            id
            name
            start_time
            end_time
            category_name
            start_date
            end_date
            max_strength
            tuition
            halfDay {
                id
                name
                week
                start_date
                end_date
                max_strength
                tuition
                start_day
                end_day
                enrolled
                start_time
                end_time
                class_type
            }
            fullDay {
                id
                name
                week
                start_date
                end_date
                max_strength
                tuition
                start_day
                end_day
                enrolled
                start_time
                end_time
                class_type
            }
        }        }`,
        variables: {
          classId: parseInt(classID),
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.error(error);
  }
}


