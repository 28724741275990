import React from "react";
import { Link } from "react-router-dom";
import bannerImage from "../../parent-assets/images/home-banner.jpg";
import { useEffect } from "react";
import { useState } from "react";
import { getSectionCMS } from "../../../Admin/adminApi/mastermenu";
import HomeShimmer from "../../../Admin/components/ShimmerUi/HomeShimmer";
import PolicyContent from "./PolicyContent";

const Policies = () => {
  const token = localStorage.getItem("access_token");
  const [sectionData, setSectionData] = useState([]);
  const [showHomeImage, setShowHomeImage] = useState(false);
  const [showShimmer, setShowShimmer] = useState(false);
  useEffect(() => {
    getPoliciesImages();
  }, []);

  const getPoliciesImages = async () => {
    setShowShimmer(true);
    setShowHomeImage(false);
    const section = await getSectionCMS(token, 173);
    setSectionData(section);
    setShowShimmer(false);
    setShowHomeImage(true);
  };
  return (
    <>
      {showShimmer ? (
        <HomeShimmer />
      ) : (
        <div className="contentwraper">
          <section style={{ position: "relative" }}>
            <section className="siteBanner" style={{ background: "#C3D940" }}>
              <div className="siteBanner__overlay">
                <img
                  className="siteBanner__img"
                  // src={bannerImage}
                  src={
                    sectionData?.length > 0
                      ? sectionData[0]?.imageSection?.image
                      : bannerImage
                  }
                  onError={(e) => {
                    e.target.onerror = null || "";
                    e.target.src = bannerImage;
                  }}
                  alt="banner img"
                />
              </div>
            </section>
          </section>
          <section className="breadcrumbSec">
            <div className="container">
              <div className="breadcrumb">
                <div className="breadcrumb__item">
                  <Link className="breadcrumb__link" to="/">
                    Back to results
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className="commonSec">
            <div className="container">
              <PolicyContent sectionData={sectionData} />
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default Policies;
