import React from "react";
import { Link, useNavigate } from "react-router-dom";
import aboutImage from "../parent-assets/images/About.jpg";
import { aboutPageContent } from "../api/contentApi";
import { useEffect } from "react";
import { useState } from "react";
import { getSectionCMS } from "../../Admin/adminApi/mastermenu";
import AboutUsShimmer from "../../Admin/components/ShimmerUi/AboutUsShimmer";
function About() {
  const [showShimmer, setShowShimmer] = useState(false);
  const token = localStorage.getItem("access_token");
  const [sectionData, setSectionData] = useState([]);
  const [showHomeImage, setShowHomeImage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      const sessionTimeOut = setTimeout(() => {
        localStorage.removeItem("access_token");
        navigate("/");
      }, 86400000);
      return () => clearTimeout(sessionTimeOut);
    }
  }, [navigate, token]);

  useEffect(() => {
    getHomeImages();
  }, []);

  const getHomeImages = async () => {
    setShowHomeImage(false);
    setShowShimmer(true);
    const section = await getSectionCMS(token, 168);
    setSectionData(section);
    setShowShimmer(false);
    setShowHomeImage(true);
  };

  return (
    <>
      {showShimmer ? (
        <AboutUsShimmer />
      ) : (
        <div className="contentwraper" style={{ paddingBottom: "0px" }}>
          <section style={{ position: "relative" }}>
            <section className="siteBanner" style={{ background: "#ea519c" }}>
              <div className="siteBanner__overlay">
                <img
                  className="siteBanner__img"
                  src={
                    sectionData?.length > 0 &&
                    sectionData[0]?.imageSection?.image
                  }
                  onError={(e) => {
                    e.target.onerror = null || "";
                    e.target.src = aboutPageContent.aboutBannerImage.url;
                  }}
                  alt="banner"
                />
              </div>
            </section>
          </section>

          <section className="breadcrumbSec">
            <div className="container">
              <div className="breadcrumb">
                <div className="breadcrumb__item">
                  <Link
                    className="breadcrumb__link"
                    onClick={() => navigate(-1)}
                  >
                    Back to results
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="aboutusSec camp_sec">
            <div className="container">
              <div className="aboutData">
                <div className="aboutData__box">
                  <div className="aboutData__row about_firt_col">
                    <div className="aboutData__col">
                      <div className="content">
                        <h1
                          className="title title--h1 title--extrabold about-text"
                          style={{ marginBottom: "1rem" }}
                        >
                          {aboutPageContent?.aboutUsTop?.topContent?.title}
                        </h1>
                        <div className="shortText">
                          <p>
                            {sectionData?.length > 0
                              ? sectionData[1]?.descriptionSection?.description
                              : aboutPageContent.aboutUsTop.topContent.content}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="aboutData__col">
                      <div className="picture picture--cliplayerBlue">
                        <img
                          className="picture__img picture_imag_new_abt"
                          src={
                            sectionData?.length > 0
                              ? sectionData[1]?.imageSection?.image
                              : aboutPageContent.middleContent
                                  .middleContentImage.url
                          }
                          onError={(e) => {
                            e.target.onerror = null || "";
                            e.target.src =
                              aboutPageContent.middleContent.middleContentImage.url;
                          }}
                          alt="About Us"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="aboutData__box">
                  <h2
                    className="title title--h1 title--extrabold text-center about-text"
                    style={{ marginBottom: "40px !important" }}
                  >
                    {aboutPageContent.middleContent.middleItems.title}
                  </h2>
                  <div className="aboutData__row">
                    <div className="aboutData__col">
                      <div className="content">
                        <div className="shortText">
                          <p>
                            {sectionData?.[3]?.descriptionSection?.description
                              ? sectionData?.[3]?.descriptionSection
                                  ?.description
                              : aboutPageContent.middleContent.middleItems
                                  .content}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="aboutData__col">
                      <div className="picture picture--cliplayerPurple">
                        <img
                          className="picture__img_boy"
                          src={
                            sectionData?.[3]?.imageSection?.image
                              ? sectionData?.[3]?.imageSection?.image
                              : aboutImage
                          }
                          alt="About Us"
                          onError={(e) => {
                            e.target.onerror = null || "";
                            e.target.src = aboutImage;
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="aboutData__box">
                  <h3
                    className="title title--h1 title--extrabold text-center title--fixWidth"
                    style={{ marginBottom: "40px" }}
                  >
                    “Both My Kids Love Gymtime!”
                    <span
                      className="textGray"
                      style={{
                        color: "rgba(37, 43, 66, 0.69)",
                        fontWeight: "500",
                      }}
                    >
                      {" "}
                      -Beth Smith
                    </span>
                  </h3>
                  <div className="aboutData__row">
                    <div className="aboutData__col">
                      <div className="content">
                        <div className="shortText">
                          <p>
                            {sectionData?.[4]?.descriptionSection?.description
                              ? sectionData?.[4]?.descriptionSection
                                  ?.description
                              : aboutPageContent.bottomContent.bottomText
                                  .content}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="aboutData__col">
                      <div className="picture picture--cliplayerPink">
                        <img
                          className="picture__img picture__img_new"
                          src={
                            sectionData?.[4]?.imageSection?.image
                              ? sectionData?.[4]?.imageSection?.image
                              : aboutPageContent.bottomContent.bottomImage.url
                          }
                          alt="About Us"
                          onError={(e) => {
                            e.target.onerror = null || "";
                            e.target.src =
                              aboutPageContent.bottomContent.bottomImage.url;
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="aboutvideoSec">
            <div className="container">
              <div className="flexBox">
                <div className="flexBox__col">
                  <h3 className="title">
                    {aboutPageContent.videoContent.videoData.title}
                  </h3>
                  <div className="shortText">
                    <p>
                      {sectionData?.[5]?.descriptionSection?.description
                        ? sectionData?.[5]?.descriptionSection?.description
                        : aboutPageContent.videoContent.videoData.content}
                    </p>
                  </div>
                </div>
                <div className="flexBox__col">
                  <div className="videothumnialbox">
                    <div className="videothumnialbox__pic">
                      <img
                        src={sectionData?.[5]?.imageSection?.image}
                        className="picture__img_boy dummy_picture__img_boy"
                        alt="dummy img"
                      />
                      <p className="videoLink"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default About;
