const GET_ATTENDENCE=`
query GetAttendance($date: String, $inputName: String, $pageInfo: PaginationInfo, $classEnrolled: [ClassTypeInput!], $studentName: String) {
  getAttendance(date: $date, inputName: $inputName, pageInfo: $pageInfo, class_enrolled: $classEnrolled, studentName: $studentName) {
    currPage
    data {
      class_code
      class_id
      date
      time
      student_full_name
      student_id
      class_enrolled
      attendanceId
      enrollment_type
      is_present
      parent1_last_name
      parent1_first_name
      parent2_first_name
      parent2_last_name
    }
    limit
    totalCount
    totalPages
  }
}
`;


export {
  GET_ATTENDENCE
}