import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import { setLoading } from "../../../reducers/loadingSlice";
import LoaderComponent from "../../components/LoaderComponent";
import {
  orderVoidPayment,
  voidOrderTransaction,
} from "../../adminApi/OrdersAPI";

const VoidPaymenModal = ({
  openVoidOrderModal,
  setOpenVoidOrderModal,
  orderID,
  fetchData,
}) => {
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tableApiData, setTableApiData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedRows(tableApiData.map((item) => item.id));
    } else {
      setSelectedRows([]);
    }
  };

  const toggleRowSelection = (id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, id];
    } else {
      newSelectedRows = selectedRows.filter((rowId) => rowId !== id);
    }

    setSelectedRows(newSelectedRows);
    setSelectAll(newSelectedRows.length === tableApiData?.length);
  };

  const getModalApiData = async () => {
    const token = localStorage.getItem("access_token");
    const response = await orderVoidPayment(token, orderID);
    setTableApiData(response?.data?.data);
  };

  useEffect(() => {
    getModalApiData();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const token = localStorage.getItem("access_token");
    try {
      const response = await voidOrderTransaction(token, selectedRows);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      if (response?.status == 200) {
        toast.success(response?.data?.data);
        setOpenVoidOrderModal(false);
        fetchData();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
       
    }
  };

  return (
    <>
      {loading && <LoaderComponent />}
      {openVoidOrderModal ? (
        <>
          <div
            className="Admin-modal Admin-modal--open"
            id="modalCategory"
            role="dialog"
          >
            <div className="Admin-modal__dialog Admin-modal__dialog--md">
              <div className="Admin-modal__content">
                <div className="Admin-modal__head">
                  <h2 className="Admin-modal__head--title">
                    Void Transaction Status
                  </h2>
                  <button
                    className="Admin-modal__close"
                    onClick={() => {
                      setOpenVoidOrderModal(false);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_1_9631)">
                        <path
                          d="M14.0728 6.92854L14.0728 6.92856C14.4017 7.26332 14.4017 7.79324 14.0728 8.128L14.0713 8.12951L14.0713 8.1295L11.6968 10.5057L14.0354 12.8405C14.3655 13.1697 14.3655 13.7084 14.0354 14.0376C13.706 14.3667 13.1717 14.3663 12.8421 14.0376L12.8418 14.0373L10.5035 11.6969L8.14748 14.0551L8.14649 14.0561C7.81513 14.3841 7.27944 14.3841 6.94808 14.0561L6.94561 14.0536L6.94562 14.0536C6.61805 13.7202 6.61475 13.1828 6.94753 12.8523C6.94764 12.8521 6.94775 12.852 6.94785 12.8519L9.30329 10.4943L6.9652 8.15951C6.63514 7.8303 6.63511 7.2917 6.9651 6.96245C6.96513 6.96242 6.96516 6.96238 6.9652 6.96235L7.14175 7.13935C7.37272 6.90779 7.74915 6.90779 7.98131 7.13935L14.0728 6.92854ZM14.0728 6.92854L14.0703 6.92609C13.739 6.5981 13.2039 6.5981 12.8725 6.92609L12.8716 6.92703L10.4965 9.30317L14.0728 6.92854ZM10.5 18.5625C6.04722 18.5625 2.4375 14.9516 2.4375 10.5C2.4375 6.04836 6.04722 2.4375 10.5 2.4375C14.9528 2.4375 18.5625 6.04836 18.5625 10.5C18.5625 14.9516 14.9528 18.5625 10.5 18.5625ZM10.5 0.75C5.11499 0.75 0.75 5.11315 0.75 10.5C0.75 15.8869 5.11499 20.25 10.5 20.25C15.885 20.25 20.25 15.8869 20.25 10.5C20.25 5.11315 15.885 0.75 10.5 0.75Z"
                          fill="white"
                          stroke="white"
                          strokeWidth="0.5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_9631">
                          <rect width="21" height="21" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>

                <div className="Admin-modal__body">
                  <div className="adminform__group">
                    {tableApiData?.length > 0 ? (
                      <table className="table">
                        <thead
                          style={{
                            paddingBottom: "50px",
                            textAlign: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <tr>
                            <th style={{ color: "white", width: "10%" }}>
                              <p
                                style={{
                                  display: "flex",
                                  gap: "7px",
                                  alignContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  className="adminform__checkbox"
                                  name="selectAll"
                                  id="selectAll"
                                  checked={selectAll}
                                  onChange={toggleSelectAll}
                                />
                                <label htmlFor="selectAll">Select All</label>
                              </p>
                            </th>
                            <th style={{ color: "white", width: "60%" }}>
                              Payment Description
                            </th>
                            <th style={{ color: "white", width: "30%" }}>
                              Payment Method
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {tableApiData?.map((val, index) => {
                            let transactionType;

                            if (val?.transaction_type == 0) {
                              transactionType = "Credit Card";
                            }
                            if (val?.transaction_type == 1) {
                              transactionType = "Cash";
                            }
                            if (val?.transaction_type == 2) {
                              transactionType = "Cheque";
                            }
                            return (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="adminform__checkbox"
                                    checked={selectedRows.includes(val.id)}
                                    onChange={() => toggleRowSelection(val.id)}
                                  />
                                </td>
                                <td>{val?.description}</td>
                                <td>{transactionType}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <h1
                        className="title--h3"
                        style={{ textAlign: "center", margin: "5% 0" }}
                      >
                        No Data Found
                      </h1>
                    )}
                  </div>
                </div>

                <div className="Admin-modal__footer">
                  <div
                    className="admin-button__group"
                    style={{ display: "flex", gap: "20px" }}
                  >
                    <button
                      className="adminbtn adminbtn-blueOutline"
                      onClick={() => {
                        setOpenVoidOrderModal(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="adminbtn adminbtn-primary"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default VoidPaymenModal;
