import React, { useEffect, useState } from "react";
import logo from "../../parent-assets/images/gymtime_logo_2024.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios"; // Import Axios
import LoaderComponent from "../../../Admin/components/LoaderComponent.jsx";
import successIcon from "../../parent-assets/images/success-icon.gif";
import { toast } from "react-toastify";
function EmailVerification() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [error, setError] = useState(false);
  const [email, setEmail] = useState();
  const [resend, setResend] = useState(false);
  const { token } = useParams();
  const { pathname } = useLocation();

  const resendHandler = async () => {
    if (resend) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/resend-email-verification`,
          {
            email: email,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setLoading(false);
        toast.success(response.data?.data);
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data.error);
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (pathname.startsWith("/email-verification")) {
      setLoading(true);
      const verifyEmail = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/email-verification`,
            { token },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response?.data) {
            setLoading(false);
          }
          setMessage(response?.data?.data);
          if (response?.data?.data === "Account Verified.") {
            setTimeout(() => {
              navigate("/");
            }, 3000);
          }
        } catch (error) {
          setLoading(false);
          setMessage(error?.response?.data?.error);
          if (error?.response?.data?.error == "User is already verified.") {
            setTimeout(() => {
              navigate("/");
            }, 3000);
          }
          setError(true);
          console.error(error);
        }
      };

      verifyEmail();
    }
  }, [token]);

  return (
    <>
      {loading && <LoaderComponent />}
      <div
        className="modal modal--loginModal modal--open"
        id="ForgotPasswordModal"
        role="dialog"
      >
        <div className="modal__dialog modal__dialog--lg">
          <div className="modal__content">
            <div className="loginWrap">
              <div className="loginWrap__item">
                <div className="loginWrap__header">
                  <div className="loginWrap__branding">
                    <div
                      className="logo"
                      style={{ margin: "0 auto" }}
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      <img
                        src={logo}
                        style={{ width: "100%", height: "400" }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="loginWrap__form">
                  <div className="loginWrap__titlebox">
                    <h2 className="title" style={{ textAlign: "center" }}>
                      {message == "Account Verified." && (
                        <div
                          className="orderConfirmed__detail"
                          style={{ cursor: "pointer", alignItems: "center" }}
                        >
                          <div className="pic">
                            <img
                              className="pic__img"
                              src={successIcon}
                              alt="Succes"
                            />
                          </div>
                        </div>
                      )}
                      <br />
                      <p style={{ textAlign: "center" }}> {message}</p>
                    </h2>
                  </div>

                  <form className="form">
                    <div className="form__group">
                      {message !== "User is already verified." &&
                        message !== "Account Verified." && (
                          <div className="form__group">
                            <div className="form__group">
                              <label className="form__label">
                                Email address
                                <span className="form__label__asterisks">
                                  *
                                </span>
                              </label>
                              <input
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                }}
                                value={email}
                                className="form__input"
                                id="email"
                                type="email"
                                placeholder="Enter email address"
                              />
                            </div>

                            {message !== "User is already verified." && (
                              <button
                                className="btn btn--primary btn--full"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setResend(true);
                                  resendHandler();
                                }}
                              >
                                {resend
                                  ? "Sent Mail"
                                  : "Resend to verify account"}
                              </button>
                            )}
                          </div>
                        )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailVerification;
