import React, { useState, useRef, useEffect } from 'react'
import JoditEditor from 'jodit-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCampProgramClassDetails } from '../../adminApi/program';

const GirlGymnastics_schedule = ({ subprogramId, setShowCampModal }) => {
    const token = localStorage.getItem("access_token")
    const editor = useRef(null);
    const [girlGymContent, setGirlGymContent] = useState('')
    const [girlsGymPrefilledData, setGirlsGymPrefilledData] = useState();
    const [campData, setCampData] = useState();
    const campFullHalfContentHandler = async () => {
        try {
            const postData = {
                subprogramId: subprogramId,
                content: [
                    {
                        first_one: girlGymContent
                    },
                ]
            }
            const response = await axios.post(
                process.env.REACT_APP_BASE_URL + `/admin/create_post_program_name`, postData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            }
            );
            if (response?.status === 200 || response?.status === 201) {
                toast.success(`Program Updated Successfully`);
                setShowCampModal(false);
            }
        } catch (error) {
            toast.error("Something went wrong!");
            setShowCampModal(false);
        }
    }

    useEffect(() => {
        getGirlGymPrefilledData();
    }, [])

    const campMetaDataHandler = (e) => {
        const { name, value } = e.target;
        setCampData((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const campMetaDataSubmitHandler = async () => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_BASE_URL + `/admin/edit-sub-program-cms`,
                campData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            if (response?.status === 200 || response?.status === 201){
                toast.success('Camp updated successfully');
                setShowCampModal(false);
            }
        } catch (error) {
            toast.error(`Can't update Camp data`);
            console.error(error);
            setShowCampModal(false);
        }
    }

    const getGirlGymPrefilledData = async () => {
        const response = await getCampProgramClassDetails(subprogramId);

        setCampData(()=>({
            subprogramId:subprogramId,
            camp_heading_first:response?.data?.data?.[0]?.camp_heading_first,
            camp_heading_second : response?.data?.data?.[0]?.camp_heading_second,
            camp_heading_third : response?.data?.data?.[0]?.camp_heading_third
        }))
        
        setGirlsGymPrefilledData(response?.data?.data?.[0]?.content?.[0]?.first_one);
    }
    return (
        <>
            <div className="fluid_container">

                <div className="meta_data_editing_container" style={{ marginBottom: "50px" }}>
                    <div className="call_editable_container" style={{ marginBottom: "10px" }}>
                        <label>Phone:</label>
                        <input type="text"
                            name='camp_heading_first'
                            className='adminform__input'
                            value={campData?.camp_heading_first}
                            onChange={(e) => campMetaDataHandler(e)}
                        />
                    </div>
                    <div className="call_editable_container" style={{ marginBottom: "10px" }}>
                        <label>Email:</label>
                        <input
                            type="email"
                            name='camp_heading_second'
                            className='adminform__input'
                            value={campData?.camp_heading_second}
                            onChange={(e) => campMetaDataHandler(e)}
                        />
                    </div>
                    <div className="call_editable_container" style={{ marginBottom: "10px" }}>
                        <label>Title:</label>
                        <input type="text"
                            className='adminform__input'
                            name='camp_heading_third'
                            value={campData?.camp_heading_third}
                            onChange={(e) => campMetaDataHandler(e)}
                        />
                    </div>
                    <button className='adminbtn adminbtn-primary camp_btm'
                        onClick={() => campMetaDataSubmitHandler()}
                    >Save</button>
                </div>
                <div className="half_day_container">
                    <h1 className='schedule_day_title_camp'>Girls Gymnastics Schedule</h1>

                    <JoditEditor
                        ref={editor}
                        value={girlsGymPrefilledData}
                        onChange={(newContent) => {
                            setGirlGymContent(newContent)
                        }}
                    />

                </div>
                <button className='adminbtn adminbtn-primary camp_btm'
                    onClick={() => campFullHalfContentHandler()}
                >Save</button>
            </div>
        </>
    )
}

export default GirlGymnastics_schedule