const GET_SUB_PROGRAMS=`
query GetProgramsCMS {
    getProgramsCMS {
      id
      name
      updatedAt
      image
      description
      sub_image
      is_active
      image2
    }
  }
`

export {
    GET_SUB_PROGRAMS
}