import React, { useState, useRef, useEffect } from 'react';
import "./colorDropdown.css"
const ColorDropdowns = ({title,setHeaderTitleColor}) => {
    const [optionsVisible, setOptionsVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Select color');
    const dropdownRef = useRef(null);

    const handleSelectedClick = () => {
        setOptionsVisible(!optionsVisible);
    };

    const handleOptionClick = (text) => {
        setHeaderTitleColor(text);
        setSelectedOption(text);
        setOptionsVisible(false);
    };

    const handleDocumentClick = (e) => {
        const clicked = e.target;
        if (dropdownRef.current && !dropdownRef.current.contains(clicked)) {
            setOptionsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
                return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    return (
        <div className="drop-down" ref={dropdownRef}>
            <div className="selected" onClick={handleSelectedClick}>
                    <span>{selectedOption}</span>
            </div>
            {optionsVisible && (
                <div className="color_options_container">
                    <ul className='color-list-items-container'>
                        <li
                            className='color-list-item'
                            onClick={() => handleOptionClick('#c3d940')}>
                            <button style={{color:"#c3d940"}}>
                            {title}
                            </button>
                            <div 
                            className='color-item'
                            style={{backgroundColor:"#c3d940"}}
                            ></div>
                        </li>
                        <li
                            className='color-list-item'
                            onClick={() => handleOptionClick('#00bbe3')}>
                            <button style={{color:"#00bbe3"}}>
                            {title}
                            </button>
                            <div 
                            className='color-item'
                             style={{backgroundColor:"#00bbe3"}}
                            ></div>
                        </li>
                        <li
                            className='color-list-item'
                            onClick={() => handleOptionClick('#f79727')}>
                            <button style={{color:"#f79727"}}>
                            {title}
                            </button>
                            <div className='color-item'                             
                             style={{backgroundColor:"#f79727"}}></div>
                        </li>

                        <li
                            className='color-list-item'
                            onClick={() => handleOptionClick('#ea519c')}>
                           <button style={{color:"#ea519c"}}>
                           {title}
                            </button>
                            <div className='color-item'
                           style={{backgroundColor:"#ea519c"}}
                            ></div>
                        </li>
                        <li
                            className='color-list-item'
                            onClick={() => handleOptionClick('#593f98')}>
                          <button style={{color:"#593f98"}}>
                          {title}
                            </button>
                            <div className='color-item'   
                            style={{backgroundColor:"#593f98"}}></div>
                        </li>
                        <li
                            className='color-list-item'
                            onClick={() => handleOptionClick('#223d98')}>
                          <button style={{color:"#223d98"}}>
                          {title}
                            </button>
                            <div className='color-item'    style={{backgroundColor:"#223d98"}}></div>
                        </li>

                        <li
                            className='color-list-item'
                            onClick={() => handleOptionClick('#5e5f5f')}>
                            <button style={{color:"#5e5f5f"}}>
                            {title}
                            </button>
                            <div className='color-item'
                               style={{backgroundColor:"#5e5f5f"}}
                            ></div>
                        </li>

                    </ul>
                </div>
            )}
        </div>
    );
};

export default ColorDropdowns;
