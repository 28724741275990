import React, { useEffect, useRef, useState } from "react";
import "./birthday-invoice.css";
import { dateFormatter, timeFormatter } from "../../../Admin/adminUtility";
import { ReactComponent as GymtimeLogo } from "../../parent-assets/images/gymtime_logo_2024.svg";
import { Link, useLocation } from "react-router-dom";
import html2pdf from "html2pdf.js";

function BirthdayInvoice() {
  const [data, setData] = useState();
  const { state } = useLocation();
  const contentRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const generatePDF = async () => {
    try {
      if (contentRef.current) {
        const content = contentRef.current;

        // Generate PDF and obtain the ArrayBuffer
        const pdfArrayBuffer = await html2pdf()
          .from(content)
          .outputPdf("arraybuffer");

        // Convert ArrayBuffer to Blob
        const pdfBlob = new Blob([pdfArrayBuffer], { type: "application/pdf" });

        // Create a temporary URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Create a link element
        const downloadLink = document.createElement("a");

        // Set the href attribute to the PDF URL
        downloadLink.href = pdfUrl;

        // Set the download attribute to specify the filename
        downloadLink.download = "Invoice.pdf";

        // Append the link to the document body
        document.body.appendChild(downloadLink);

        // Programmatically trigger the click event to start the download
        downloadLink.click();

        // Clean up: remove the temporary URL and the link element
        URL.revokeObjectURL(pdfUrl);
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      //   toast.error("PDF could not be generated, try again later.");
    }
  };

  useEffect(() => {
    // setData(invoiceApiData?.data?.data);
    setData(state?.data);
    setTimeout(() => {
      setLoading(true);
      generatePDF();
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }, 2000);
  }, [state]);

  if (!data) return null;

  let addOnPackagesTotal = data[0]?.description?.addOnPackage?.reduce(
    (total, val) => total + val.price,
    0
  );

  if (data[0]?.description?.dj?.isDjAdded == 1) {
    addOnPackagesTotal += 100;
  }

  addOnPackagesTotal += data[0]?.description?.package?.price;

  return (
    <div className="main-container" ref={contentRef}>
      <div class="container">
        <div class="header flexbox" style={{ gap: "50px" }}>
          <div className="column">
            <GymtimeLogo />
          </div>

          <div className="column">
            <h2 class="h1 title">INVOICE</h2>
          </div>
        </div>

        <div class="invoice-info">
          <div className="left">
            <p>
              <b>DATE:</b>
            </p>
            <p>
              <b>TIME:</b>
            </p>

            <p>
              <b>Package:</b>
            </p>

            <p>
              <b>Final Charge:</b>
            </p>
          </div>

          <div className="right">
            <p>
              <span>{dateFormatter(data[0]?.date_paid)}</span>
            </p>
            <p>
              <span>{timeFormatter(data[0]?.description?.birthdaytime)}</span>
            </p>

            <p>
              <span>{data[0]?.description?.package?.name}</span>
            </p>

            <p>
              <span>$ {addOnPackagesTotal}</span>
            </p>
          </div>
        </div>

        <div class="customer-info">
          <p>
            <span className="name">{data[0]?.address?.parent_name}</span>
          </p>
          <p>
            <span>{data[0]?.address?.for}</span>
          </p>
        </div>

        <table class=" main-table" style={{ marginTop: "20px" }}>
          <thead>
            <tr>
              <th>DESCRIPTION</th>
              <th>QTY</th>
              <th>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data[0]?.description?.package?.name}</td>
              <td>1</td>
              <td>{data[0]?.description?.package?.price}</td>
            </tr>

            {data[0]?.description?.addOnPackage?.map((val, index) => {
              return (
                <tr>
                  <td>{val?.name}</td>
                  <td>1</td>
                  <td>{val?.price}</td>
                </tr>
              );
            })}

            {data[0]?.description?.dj?.isDjAdded == 1 && (
              <tr>
                {" "}
                <td>Music DJ</td>
                <td>1</td>
                <td> {data[0]?.description?.dj?.price}</td>
              </tr>
            )}
          </tbody>
          <tfoot
            style={{
              border: "1px solid #5497C7",
              background: "rgb(240 249 255)",
            }}
          >
            <tr>
              <td colSpan="2" class="total">
                Total
              </td>
              <td class="total">${addOnPackagesTotal}</td>
            </tr>
          </tfoot>
        </table>

        <div style={{ display: "table", maxWidth: "300px" , border:"1px solid black", marginLeft:"310px", height:"100px"}}>
          <div class="Row">
            <div class="Cell" style={{border:"1px solid", width:"50px"}}><p style={{fontWeight:"600"}}>TOTAL</p></div>
            <div class="Cell" style={{minWidth:"70px", border:"1px solid", }}></div>
            <div class="Cell "style={{border:"1px solid", background:"black", color:"white",minWidth:"123px"}}><p>$ &nbsp; 2,515.00 </p></div>
          </div>

          <div class="Row">
            <div class="Cell"style={{border:"1px solid"}}><p style={{fontWeight:"600", color:"red"}}>DEPOSIT</p></div>
            <div class="Cell"style={{border:"1px solid"}}></div>
            <div class="Cell"style={{border:"1px solid"}}><p style={{color:"red"}} >$ &nbsp; 500.00 </p></div>
          </div>
          <div class="Row">
            <div class="Cell"style={{border:"1px solid"}}><p style={{fontWeight:"600"}}>BALANCE</p></div>
            <div class="Cell"style={{border:"1px solid"}}></div>
            <div class="Cell"style={{border:"1px solid"}}>$ &nbsp;2,015.00</div>
          </div>
         
        
        </div>
        <p style={{marginLeft:"320px", fontSize:"0.8rem" ,width:"100%",  fontWeight:"600"}}>Gratuity is greatly appreciated - cash only</p>

        <div class="total-table">
          <div
            class="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div class="label" style={{ marginTop: "20px" }}>
              TOTAL
            </div>
            <div class="value">$ {addOnPackagesTotal}</div>
          </div>
        </div>

        {/* SIGNATURE  */}
        <div class="sign">
          <div class="text">Sign Here</div>
          <div class="line"></div>
        </div>

        <div className="last-content">
          <p>
            Make all checks payable to <span>Gymtime, Rhythm and Glues </span>{" "}
          </p>
          <p>If you have any questions concerning this invoice, contact:</p>
          <p>GYMTIME </p>
          <p>Phone:113-22-1123</p>
          <p>
            E-Mail:{" "}
            <span>
              <Link
                to="https://info@gymtime.net"
                style={{
                  cursor: "pointer",
                  color: "00bbe3",
                  textDecoration: "underline",
                }}
              ></Link>
              info@gymtime.net
            </span>
          </p>
        </div>

        <h2 className="title" style={{ color: "#5497c7", fontSize: "21px" }}>
          THANK YOU FOR CELEBRATING WITH US!{" "}
        </h2>
      </div>

      <div
        className="right-table-container"
        style={{ borderRadius: "1px solid #eee", marginBottom:"200px" }}
      >
        <div className="Bflexbox">
          <div
            className="BBColumn"
            style={{
              height: "60px",
              background: "green",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <p className="desc">ADMIN USE ONLY</p>
          </div>
          <div className="BColumn">
            <p>{data[0]?.address?.parent_name}</p>
          </div>
          <div className="BColumn">
            <div style={{ textDecoration: "underline" }} className="label">
              Activities
            </div>
            {data[0]?.description?.addOnPackage?.map((val, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <p className="para"> {val?.name}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="BColumn">
            Final Charge : &nbsp; ${addOnPackagesTotal}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BirthdayInvoice;
