import axios from "axios";
import { GET_MANAGE_PROGRAM_DATA, GET_MANAGE_PROGRAM_DATA_NEW } from "../pages/adminQueries/ManageProgram";

export const getManageProgramData = async (token, programId) => {
  try {
    const id = parseInt(programId);
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_MANAGE_PROGRAM_DATA,
        variables: {
          programId: id,
          page: 0,
          limit: 100
        },
      },
      {
        headers: {

          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data?.getClassesCMS;
  } catch (error) {
    console.error(error);
  }
};
export const getManageProgramDataNew = async (token, programId, className) => {
  try {
    const id = parseInt(programId);
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_MANAGE_PROGRAM_DATA_NEW,
        variables: {
          programId: id,
          page: 0,
          limit: 300,
          className: className ? className : ""
        },
      },
      {
        headers: {

          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data?.getClassesCMSAdmin;
  } catch (error) {
    console.error(error);
  }
};

export const getEnrichmentManageProgramData = async (token, programId) => {
  try {
    const postData = {
      programId: programId
    }
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/getCategoryFromProgram`, postData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      }
    )
    return response;
  } catch (err) {
    console.error(err);
  }
}

