import React from 'react';
import "./shimmerUi.css";

const HomeShimmer = () => {
    return (
        <div className="shimmer_UI_container">
            <div className="shimmer_UI_container_fluid">
                <div className="shimmer_header_container">
                    <div className="shimmer_effect"></div>
                </div>
                <div className="image_banner_container">
                    <div className="shimmer_effect"></div>
                </div>
                <div className="browse_by_box_container">
                    <div className="first_browse_by_box">
                        <div className="shimmer_effect"></div>
                    </div>
                    <div className="second_browse_by_box">
                        <div className="shimmer_effect"></div>
                    </div>
                    <div className="third_browse_by_box">
                        <div className="shimmer_effect"></div>
                    </div>
                </div>
                <div className="whats_new_container">
                    <div className="shimmer_effect"></div>
                </div>
            </div>
        </div>
    )
}

export default HomeShimmer;
