import React, { useEffect, useState } from 'react'
import NavigationComponent from '../../../components/NavigationComponent';
import Header from '../../../components/Header';
import "./cmsFooter.css";
import axios from 'axios';
import { toast } from 'react-toastify';
const CmsFooter = () => {
    const token = localStorage.getItem("access_token");
    const [descData, setDescData] = useState();
    const [contactUsData, setContactUsData] = useState();

    const descHandler = (e) => {
        const { name, value } = e.target;
        setDescData((prevState) => ({
            ...prevState, [name]: value, id: 1
        }))
    }
    useEffect(() => {
        getFooterDetails();
        getContactUsDetails();
    }, []);

    const getContactUsDetails = async () => {
        try {
            const data = "";
            const response = await axios.get(
                process.env.REACT_APP_BASE_URL + `/admin/get_contactus_footercms`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            )
         
            setContactUsData(response?.data?.data?.[0]);
        } catch (error) {
            console.error("Error", error);
        }
    }
    const getFooterDetails = async () => {
        try {
            const data = '';
            const response = await axios.get(
                process.env.REACT_APP_BASE_URL + `/admin/getfootercms`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            )
            setDescData(response?.data?.data?.[0]);
        } catch (error) {
            console.error("Error", error)
        }
    }
    const descSubmitHandler = async () => {
        try {
            const response = await axios.put(
                process.env.REACT_APP_BASE_URL + `/admin/footercms`, descData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
            );
            if (response?.status === 200 || response?.status === 201) {
                toast.success(response?.data?.data)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const contactUsHandler = (e) => {
        const { name, value } = e.target;
        setContactUsData(prevState => ({
            ...prevState, [name]: value, id: 1
        }))
    }
    const contactUsSubmitHandler = async () => {
        try {
            const response = await axios.put(
                process.env.REACT_APP_BASE_URL + `/admin/contactus_footercms`, contactUsData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
            )
            if (response?.status === 200 || response?.status === 201) {
                toast.success(response?.data?.data)
            }
        } catch (error) {
            console.error("Error", error);
        }
    }
    return (
        <>

            <div className="container--component">
                <div className="container--grid">
                    <NavigationComponent />
                    <div className="Adminpage">
                        <Header title="Footer" subTitle="(Manage Footer)" />

                        <div class="accordion__card__body" style={{ display: "block" }}>
                            <div className="footer_editable_container">
                                <div className="footer_description_container">
                                    <div>
                                        <textarea className='adminform__textarea'
                                            placeholder='Enter the footer description.'
                                            name='description'
                                            value={descData?.description}
                                            onChange={(e) => descHandler(e)}
                                        />

                                    </div>
                                    <div className='input_box_container'>
                                        <input type="text"
                                            placeholder='Enter copyright'
                                            className='adminform__input'
                                            name='copyright'
                                            value={descData?.copyright}
                                            onChange={(e) => descHandler(e)}
                                        />

                                    </div>
                                    <div>
                                        <button className='admibtn-primary'
                                            onClick={() => descSubmitHandler()}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                                <div className="footer_contact_us_editable_container">
                                    <ul>
                                        <li className='input_box_container'>
                                            <input
                                                type="text"
                                                placeholder='Enter phone number.'
                                                className='adminform__input '
                                                name="phone"
                                                value={contactUsData?.phone}
                                                onChange={(e) => contactUsHandler(e)}
                                            />
                                        </li>
                                        <li className='input_box_container'>
                                            <input
                                                type="text"
                                                placeholder='example@gymtime.com.'
                                                className='adminform__input'
                                                name='email'
                                                value={contactUsData?.email}
                                                onChange={(e) => contactUsHandler(e)}
                                            />
                                        </li>
                                        <li className='input_box_container'>
                                            <input type="text"
                                                placeholder='Enter address'
                                                className='adminform__input'
                                                name='address'
                                                value={contactUsData?.address}
                                                onChange={(e) => contactUsHandler(e)}
                                            />
                                        </li>

                                    </ul>
                                    <div>
                                        <button className='admibtn-primary'
                                            onClick={() => contactUsSubmitHandler()}
                                        >Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div class="accordion__card__body" style={{ display: "block" }}>
                            <div className="activity_editable_container">
                              <div className="add_new_activity_container">
                                <button className='admibtn-primary'>Add New Activity</button>
                              </div>

                              <div className="activity_container_list">
                                <div className="activity_list">
                                    <input type="text" 
                                    className='adminform__input'
                                    placeholder='Edit activity'/>
                                    <button className='admibtn-primary'>Edit</button>
                                </div>
                              </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>


        </>
    )
}

export default CmsFooter