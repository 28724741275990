export const GET_MANAGE_PROGRAM_DATA = `
query GetClassesCMS($programId: Int!, $page: Int!, $limit: Int!) {
  getClassesCMS(programId: $programId, page: $page, limit: $limit) {
    currPage
    data {
      content {
        first_one
        second_one
      }
     umbrella_name
      name
      class_type
      id
      image
      description
      description2
      is_active
      header_image
      image_front_class
      min_age
      max_age
      categoryId
      isLanding
      image_page  
      camp_secondary_name
    }
  }
}
`
export const GET_MANAGE_PROGRAM_DATA_NEW = `
query GetClassesCMSAdmin($programId: Int!, $page: Int!, $limit: Int! , $className: String) {
  getClassesCMSAdmin(programId: $programId, page: $page, limit: $limit, className: $className) {
    currPage
    totalPages
    totalRows
    
    data {

      content {
        first_one
        second_one
      }
      class_type
      image
      is_active
      header_image
      hidden
      image_front_class
      name
      umbrella_name
      description
      description2
      id
      image_front
      image_page  
      camp_secondary_name
    }
  }
}
`