import React from 'react'
import { ReactComponent as CloseIcon } from "../../../assets/images/icons/close-icon.svg";
import { toast } from 'react-toastify';
import axios from 'axios';
const MonthDelModal = ({ setMonthDeleteModal, holiday, month,getCalenderData }) => {
    const token=localStorage.getItem("access_token");
    const deleteMonthHandler = async () => {
        try {
            const postData = {
                monthId: holiday?.[0]?.monthId,
                // sessionId: holiday?.[0]?.session_id
            }
            const response = await axios.post(
                process.env.REACT_APP_BASE_URL + "/admin/deletecalendar",
                postData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
            )
            if(response?.status===200 || response?.status===201){
                setMonthDeleteModal(false);
                toast.success(`${month} deleted successfully.`)
                getCalenderData();
            }
        } catch (error) {
            console.error(error);
            toast.error(error);
            setMonthDeleteModal(false);
        }
    }
    return (
        <>
            <div
                className="Admin-modal Admin-modal--open"
                id="modalDelete"
                role="dialog"
            >
                <div className="Admin-modal__dialog Admin-modal__dialog--md">
                    <div className="Admin-modal__content">
                        <div className="Admin-modal__head">
                            <h2 className="Admin-modal__head--title">Delete Month</h2>
                            <button
                                className="Admin-modal__close"
                                onClick={() => setMonthDeleteModal(false)}
                            >
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="Admin-modal__body">
                            <div className="month_delete_disclaimer">
                                <h1>
                                    Are you sure you want to delete whole &nbsp;
                                    <span style={{ fontWeight: "700" }}>{month} </span>
                                    month?
                                </h1>
                            </div>

                            <div className="btn_conatiner">
                                <button className='btn adminbtn-primary'
                                    onClick={() => deleteMonthHandler()}
                                >Delete</button>
                                <button className='btn adminbtn-primary'
                                    onClick={() => setMonthDeleteModal(false)}
                                >Cancel</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default MonthDelModal