const GET_CATEGORIES = `
query GetCategories($pageInfo: PaginationInfo, $name: String , $schoolName:String) {
    getCategories(pageInfo: $pageInfo, name: $name,  schoolName: $schoolName) {
      data {
        id
        name
        school_name
        createdat
        updatedat
      }
      limit
      totalCount
      totalPages
      currPage
    }
  }

`;

export {
    GET_CATEGORIES
};
