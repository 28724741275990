import React, { useState, useEffect } from "react";

import Header from "../components/Header";
import AddUser from "../components/Users/AddUser";
import NavigationComponent from "../components/NavigationComponent";
import TableNew from "../components/TableComponent/TableNew";
import { getUserDeleteWarning, getUsers } from "../adminApi/users";
import { useSelector } from "react-redux/es/hooks/useSelector";
import DeleteUser from "../components/UserModals/DeleteModal";
import { deleteUser } from "../adminApi/users";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { ReactComponent as SearchIcon } from "../assets/images/icons/search.svg";
import { userTableHeader } from "../tableHeaderComponents";

function User() {
  const [addNewModal, setAddNewModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [editCategory, setEditCategory] = useState(false);
  const [dataEdit, setDataEdit] = useState();
  const [users, setUsers] = useState();
  const [userData, setUserData] = useState();
  const [getAllUser, setGetAllUser] = useState();
  const [temp, setTemp] = useState(1);
  const [additionalFieldsData, setAdditionalFieldsData] = useState([]);
  const [pageLimit, setPageLimit] = useState("10");
  const [currentPage, setCurrentPage] = useState();
  const [totalCount, setTotalCount] = useState();
  const [deleteMessage, setDeleteMessage] = useState();
  const [sortKey, setSortKey] = useState({
    sortId: null,
    isAsc: true,
  });
  const [loadingState, setLoadingState] = useState(false);
  const [formData, setFormData] = useState([]);
  const page = useSelector((state) => state?.adminSlice?.adminModalContent);

  async function fetchData() {
    setLoadingState(true);
    try {
      const token = localStorage.getItem("access_token");
      const response = await getUsers(
        formData,
        token,
        page,
        sortKey,
        pageLimit
      );

      setGetAllUser(response?.data);

      setTotalPages(response.totalPages);
      setCurrentPage(response?.currPage);
      setPageLimit(response?.limit);
      setTotalCount(response?.totalCount);
      const modifiedData = [...response?.data]?.map((user, index) => {
        return {
          data: [
            user?.id ? user?.id : "-",
            user?.first_name ? user?.first_name : "-",
            user?.last_name,
            user?.email,
            user.role_id === 1
              ? "Administrator"
              : user.role_id === 2
              ? "User"
              : user.role_id === 3
              ? "Super Admin"
              : "-",
          ],
          item: user,
          onDeletePress: async () => {
            try {
              const response = await getUserDeleteWarning(token, user.id);
              setDeleteMessage(response?.data);
            } catch (error) {}
            setUserData(parseInt(user.id));
            setDeleteUserModal(true);
          },
          onEditPress: () => {
            setDataEdit(user);
            setEditCategory(true);
          },
        };
      });
      setUsers(modifiedData);
      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
      toast.error(error.response.data.error);
      console.error("Error fetching data:", error);
    }
  }

  const handleSearch = (formData) => {
    fetchData(formData);
  };

  const debouncedFetchData = debounce(fetchData, 2000);

  useEffect(() => {
    const pattern = /[^ ]/;

    if (pattern.test(formData.name) || formData.name?.length === 0) {
      if (formData.name !== undefined) {
        const timeoutId = setTimeout(() => {
          debouncedFetchData(fetchData);
        }, 900);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [formData.name]);

  useEffect(() => {
    fetchData();
  }, [page, sortKey, pageLimit]);

  const handleAddSearch = () => {
    setTemp((prevTemp) => prevTemp + 1);
    setAdditionalFieldsData((prevData) => [
      ...prevData,
      { selectUser: "", name: "" },
    ]);
  };

  const handleAdditionalFieldsChange = (index, key, value) => {
    const updatedData = [...formData];

    while (updatedData.length <= index) {
      updatedData.push({ selectUser: "", name: "" });
    }

    if (value !== updatedData[index][key]) {
      updatedData[index]["name"] = "";
    }
    updatedData[index][key] = value;
    setFormData(updatedData);

    fetchData(formData);
  };

  const renderAdditionalFields = () => {
    const additionalFields = [];
    for (let i = 0; i < temp; i++) {
      additionalFields.push(
        <div key={i} style={{ display: "flex", gap: "10px" }}>
          <div className="adminform__group">
            <select
              name={`selectUser_${i}`}
              id={`selectUser_${i}`}
              className="adminform__select"
              onChange={(e) =>
                handleAdditionalFieldsChange(i, "selectUser", e.target.value)
              }
              value={formData[i]?.selectUser || ""}
            >
              <option value="" disabled>
                Search
              </option>
              <option value="firstName">First Name</option>
              <option value="lastName">Last Name</option>
              <option value="email">Email</option>
              <option value="role">Role</option>
            </select>
          </div>
          <div className="adminform__group">
            <input
              type="text"
              className="adminform__input"
              placeholder="Search here"
              name={`name_${i}`}
              onChange={(e) =>
                handleAdditionalFieldsChange(i, "name", e.target.value)
              }
              value={formData[i]?.name || ""}
              disabled={!formData[i]?.selectUser}
            />
          </div>
        </div>
      );
    }
    return additionalFields;
  };

  const handleCancelAdditionalFields = () => {
    setTemp((prevTemp) => {
      return prevTemp > 1 ? prevTemp - 1 : prevTemp;
    });
  };

  return (
    <>
      <div className="container--component">
        <div className="container--grid">
          <NavigationComponent />

          <div className="Adminpage">
            <Header title="Users" subTitle="(Manage Available Users)" />

            <section className="Adminpage__section">
              <h2 className="page__title">Search by:</h2>

              <div className="Adminpage__head">
                <div className="Adminpage__filter">
                  {renderAdditionalFields()}

                  {temp <= 1 ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className="adminform__cancel-button"
                      onClick={handleCancelAdditionalFields}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}

                  <div className="" style={{ display: "flex", gap: "10px" }}>
                    <button
                      disabled={temp == 2 ? true : false}
                      className="adminbtn adminbtn-blueOutline"
                      onClick={handleAddSearch}
                      style={{ display: temp == 2 ? "none" : "block" }}
                    >
                      and
                    </button>
                    <button
                      className="adminbtn adminbtn-primary"
                      onClick={handleSearch}
                    >
                      <SearchIcon />
                      Search
                    </button>
                  </div>
                </div>

                <button
                  className="adminbtn adminbtn-light"
                  id="btnCategory"
                  onClick={() => {
                    setAddNewModal(!addNewModal);
                  }}
                >
                  + Add New User
                </button>
              </div>

              <TableNew
                loadingState={loadingState}
                headerData={userTableHeader}
                bodyData={users}
                totalPages={totalPages}
                setSortKey={setSortKey}
                setPageLimit={setPageLimit}
                pageLimit={pageLimit}
                currentPage={currentPage}
                totalCount={totalCount}
              />
            </section>
          </div>
        </div>
      </div>

      {addNewModal ? (
        <AddUser
          openAddNewUser={addNewModal}
          setAddNewUser={setAddNewModal}
          data=""
          type="Add"
          fetchData={fetchData}
          setSortKey={setSortKey}
          getAllUser={getAllUser}
        />
      ) : null}

      {editCategory ? (
        <AddUser
          openAddNewUser={editCategory}
          setAddNewUser={setEditCategory}
          data={dataEdit}
          type="Edit"
          fetchData={fetchData}
        />
      ) : null}

      {deleteUserModal ? (
        <DeleteUser
          openDeleteModal={deleteUserModal}
          setDeleteModal={setDeleteUserModal}
          data={userData}
          fetchData={fetchData}
          deleteQuery={deleteUser}
          deleteMessage={deleteMessage}
          type={"Users"}
        />
      ) : null}
    </>
  );
}

export default User;
