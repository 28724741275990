import React from 'react'

const AboutUsShimmer = ({ component }) => {
    return (
        <>
            <div className="shimmer_UI_container">
                <div className="shimmer_UI_container_fluid">
                    <div className="shimmer_header_container">
                        <div className="shimmer_effect"></div>
                    </div>
                    <div className="image_banner_container">
                        <div className="shimmer_effect"></div>
                    </div>

                    {component !== "Birthday" && <div className="About_us_lower_section">
                        <div className="_shimmer_about_us_section">
                            <div className="_shimmer_about_image_section"></div>
                            <div className="_shimmer_aboutus_content">
                                <div className="shimmer_content_line"></div>
                                <div className="shimmer_content_line"></div>
                                <div className="shimmer_content_line"></div>
                                <div className="shimmer_content_line"></div>
                                <div className="shimmer_content_line"></div>
                                <div className="shimmer_content_line"></div>
                                <div className="shimmer_content_line"></div>
                                <div className="shimmer_content_line"></div>
                                <div className="shimmer_content_line"></div>

                            </div>
                        </div>
                    </div>}
                    {
                        component === "Staff" &&
                        <div className="_shimmer_staff_name_list">

                            <div className="_shimmer_staff_name"></div>
                            <div className="_shimmer_staff_name"></div>
                            <div className="_shimmer_staff_name"></div>
                            <div className="_shimmer_staff_name"></div>
                            <div className="_shimmer_staff_name"></div>
                            <div className="_shimmer_staff_name"></div>
                            <div className="_shimmer_staff_name"></div>
                            <div className="_shimmer_staff_name"></div>

                        </div>
                    }

                    {
                        component === "Birthday" &&
                        <div className="birthday_page_shimmer_lower_section">
                            <div className="_shimmer_heading_section">
                                <div className="birthday_header_shimmer"></div>
                                <div className="birthday_header_shimmer-1"></div>
                                <div className="birthday_header_shimmer-1"></div>
                                <div className="birthday_header_shimmer-1"></div>
                                <div className="birthday_header_shimmer-1"></div>
                                <div className="birthday_header_shimmer-1"></div>
                                <div className="birthday_header_shimmer-1"></div>
                            </div>

                            <div className="birthday_big_box_shimmers">
                                <div className="birthday_box_shimmer-1"></div>
                                <div className="birthday_box_shimmer-1"></div>
                                <div className="birthday_box_shimmer-1"></div>

                            </div>
                            <div className="_shimmer_heading_section">
                                <div className="birthday_header_shimmer"></div>
                            </div>
                        </div>
                    }

                    {
                        component === "Contact" &&
                        <div className="_shimmer_staff_name_list">
                            <div className="_shimmer_staff_name"></div>
                            <div className="_shimmer_staff_name"></div>
                            <div className="_shimmer_staff_name"></div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default AboutUsShimmer