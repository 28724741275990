import axios from "axios";
import { GET_SUB_PROGRAMS } from "../pages/adminQueries/SubPrograms";

export async function getSubPrograms(token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_SUB_PROGRAMS,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data?.getProgramsCMS;
  } catch (error) {
    console.error(error);
  }
}

export async function getCampSubProgram(){
  try {
    const data={
      "program_name": "Camp"
    }
    const response=await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/get_sub_prog_one`,data,
      {
        headers:{
          'Content-Type': 'application/json'
        }
      }
    )
    return response;
  } catch (error) {
    
  }
}

export async function getCampSubProgramFrontend(){
  try {
    const data={
      "program_name": "Camp"
    }
    const response=await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/get_sub_prog_one_front`,data,
      {
        headers:{
          'Content-Type': 'application/json'
        }
      }
    )
    return response;
  } catch (error) {
    
  }
}