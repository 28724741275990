import React from "react";
import Header from "../components/Header";
import NavigationComponent from "../components/NavigationComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Table from "../components/TableComponent/Table";

const arrowIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.4615 3.7712C7.72183 3.51085 7.72183 3.08874 7.4615 2.82839L5.57588 0.942771C5.53707 0.903964 5.49647 0.868044 5.45433 0.835024C5.00181 0.480349 4.37288 0.459313 3.89943 0.771917C3.82549 0.820731 3.75535 0.877684 3.69026 0.942771L1.80464 2.82839C1.54429 3.08874 1.54429 3.51085 1.80464 3.7712C2.06499 4.03155 2.4871 4.03155 2.74745 3.7712L3.99992 2.51872V12C3.99992 12.3682 4.2984 12.6666 4.66659 12.6666C5.03478 12.6666 5.33325 12.3682 5.33325 12V2.58576L6.51869 3.7712C6.77903 4.03154 7.20117 4.03154 7.4615 3.7712Z"
      fill="white"
    />
    <path
      d="M8.47064 12.2287C8.21031 12.4891 8.21031 12.9111 8.47064 13.1715L10.3562 15.0571C10.4214 15.1222 10.4915 15.1792 10.5654 15.228C11.083 15.5697 11.7862 15.5127 12.2419 15.0571L14.1275 13.1715C14.3878 12.9111 14.3878 12.4891 14.1275 12.2287C13.8672 11.9683 13.445 11.9683 13.1847 12.2287L11.9992 13.4141V3.99992C11.9992 3.63173 11.7008 3.33325 11.3326 3.33325C10.9644 3.33325 10.6659 3.63173 10.6659 3.99992V13.4811L9.41344 12.2287C9.15311 11.9683 8.73097 11.9683 8.47064 12.2287Z"
      fill="white"
    />
  </svg>
);
function MakeUpClass() {
  // TABLE HEADER DATA
  const _tabelHeadersData = [
    {
      name: "ID",
      icon: arrowIcon,
    },
    {
      name: "Season",
      icon: arrowIcon,
    },
    {
      name: "Class Name",
      icon: arrowIcon,
    },
    {
      name: "Category",
      icon: arrowIcon,
    },
    {
      name: "Start Date",
      icon: arrowIcon,
    },

    {
      name: "Tuition",
      icon: arrowIcon,
    },
    {
      name: "Max Age",
      icon: arrowIcon,
    },
    {
      name: "Min Age",
      icon: arrowIcon,
    },
    {
      name: "Max Strength",
    },
    {
      name: "Website",
      icon: arrowIcon,
    },

    {
      name: "Teacher",
    },
    {
      name: "Actions",
    },
  ];
  const renderAdditionalFields = () => {
    const additionalFields = [];

    return additionalFields;
  };

  return (
    <div className="container--component">
      <div className="container--grid">
        <NavigationComponent />

        <div className="Adminpage">
          <Header title="Classes >> Makeup Trial" />

          <section className="Adminpage__section">
            <h2 className="Adminpage__title">Search by:</h2>

            <div className="Adminpage__head">
              <div className="Adminpage__filter">
                {renderAdditionalFields()}

                <button
                  type="button"
                  className="adminform__cancel-button"
                  // onClick={() => handleCancelAdditionalFields(1)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>

                <div className="" style={{ display: "flex", gap: "10px" }}>
                  <button
                    className="adminbtn adminbtn-light"
                    // onClick={handleAddSearch}
                    // disabled={temp == 2 ? true : false}
                  >
                    and
                  </button>
                  <button
                    className="adminbtn adminbtn-primary"
                    // onClick={handleSearch}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.96326 9.16769L12.3473 11.569M11.2455 6.05994C11.2455 8.49399 9.27231 10.4672 6.83826 10.4672C4.40421 10.4672 2.43103 8.49399 2.43103 6.05994C2.43103 3.62589 4.40421 1.65271 6.83826 1.65271C9.27231 1.65271 11.2455 3.62589 11.2455 6.05994Z"
                        stroke="white"
                        strokeWidth="1.10181"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Search
                  </button>
                </div>
              </div>

              <button
                className="adminbtn adminbtn-light export_btn_attendence"
                id="btnCategory"
                // onClick={async () => {
                //   setAddNewClasses(true);
                // }}
              >
                + Add New Class
              </button>
            </div>

            <div className="Adminpage__body">
              <Table
                headerData={_tabelHeadersData}
                // bodyData={classData}
                // subHeader={subHeader}
                // subTable={getStudentByCLass}
                // setSortKey={setSortKey}
                // tableType="Classes"
                // totalPages={totalPages}
                // setPageLimit={setPageLimit}
                // pageLimit={pageLimit}
                // totalCount={totalCount}
                // currentPage={currentPage}
                // setSelectedClassId={setSelectedClassId}
                // selectedClassId={selectedClassId}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default MakeUpClass;
