import React, { useState } from "react";
import { toast } from "react-toastify";
import { deleteClassAPI } from "../../adminApi/classApi";
import { deleteAdhocDetail, deleteLedger } from "../../adminApi/Ledger";
import LoaderCompnent from "../LoaderComponent";
import { deleteMakeUp } from "../../adminApi/makeUpClassApi";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close-icon.svg";
function DeleteModal({
  openDeleteModal,
  setDeleteModal,
  data,
  fetchData,
  deleteQuery,
  type,
  deleteCardID,
  setToggleState,
  toggleState,
  makeUpId,
  deleteMessage,
  setDeleteCardOnFile,
}) {
  const [loading, setLoading] = useState(false);
  const [reasonInput, setReasonInput] = useState();

  const handleDelete = async (data) => {
    const token = localStorage.getItem("access_token");
    setLoading(true);

    if (type == "ADHOC" && reasonInput == undefined) {
      setLoading(false);
      toast.error("Please enter the Reason to Delete the Adhoc!");
      return;
    }

    try {
      let response;

      switch (type) {
        case "Class":
          response = await deleteClassAPI(token, data?.id, data);
          break;
        case "Ledger":
          response = await deleteLedger(token, data);
          break;
        case "CARD":
          setDeleteCardOnFile((prevState) => ({
            ...prevState,
            deleteArray: [...prevState.deleteArray, deleteCardID],
          }));
          if (setToggleState) {
            setToggleState(!toggleState);
          }
          break;
        case "MAKEUP":
          response = await deleteMakeUp(token, makeUpId);
          break;
        case "ADHOC":
          response = await deleteAdhocDetail(token, deleteCardID, reasonInput);
          break;

        default:
          response = await deleteQuery(data, token);
          break;
      }

      setLoading(false);
      setDeleteModal(false);
      fetchData();

      if (type === "teacher") {
        fetchData();
        toast.success("Teacher Deleted Successfully!");
      } else {
        if (type === "ADHOC") {
          toast.success(response?.data?.data);
          fetchData();
        } else {
          toast.success(response?.data ? response?.data : response);
          fetchData();
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });

      console.error(error);
    }
  };

  return (
    <>
      {loading && <LoaderCompnent />}

      <>
        {openDeleteModal ? (
          <>
            <div
              className="Admin-modal Admin-modal--open"
              id="modalDelete"
              role="dialog"
            >
              <div className="Admin-modal__dialog Admin-modal__dialog--md">
                <div className="Admin-modal__content">
                  <div className="Admin-modal__head">
                    <h2 className="Admin-modal__head--title">Delete {type}</h2>
                    <button
                      className="Admin-modal__close"
                      onClick={() => {
                        setDeleteModal(false);
                      }}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="Admin-modal__body">
                    <div
                      className="Admin-modal__warning"
                      style={{ textAlign: "center" }}
                    >
                      <svg
                        width="46"
                        height="46"
                        viewBox="0 0 46 46"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.166 21.0833V32.5833"
                          stroke="#FF0000"
                          strokeWidth="4.31964"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M26.834 21.0833V32.5833"
                          stroke="#FF0000"
                          strokeWidth="4.31964"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.66602 13.4167H38.3327"
                          stroke="#FF0000"
                          strokeWidth="4.31964"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.5 13.4167H23H34.5V34.5001C34.5 37.6758 31.9257 40.2501 28.75 40.2501H17.25C14.0744 40.2501 11.5 37.6758 11.5 34.5001V13.4167Z"
                          stroke="#FF0000"
                          strokeWidth="4.31964"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M17.25 9.58333C17.25 7.46624 18.9662 5.75 21.0833 5.75H24.9167C27.0338 5.75 28.75 7.46624 28.75 9.58333V13.4167H17.25V9.58333Z"
                          stroke="#FF0000"
                          strokeWidth="4.31964"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>This Record Will Be Deleted. Are You Sure?</span>
                    </div>

                    {type == "Users" && (
                      <div style={{ marginTop: "20px" }}>
                        {deleteMessage?.details?.map((val) => {
                          return (
                            <p style={{ marginTop: "10px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Student Name :
                              </span>{" "}
                              {val?.studentName}
                              <span>
                                {val?.details?.details?.map((val, index) => {
                                  return (
                                    <p>
                                      {index + 1}. &nbsp;{val}
                                    </p>
                                  );
                                })}
                              </span>
                            </p>
                          );
                        })}
                      </div>
                    )}

                    {type == "Class" && (
                      <div style={{ marginTop: "20px" }}>
                        {deleteMessage?.details?.map((val, index) => {
                          return (
                            <p style={{ lineHeight: "2rem" }}>
                              {index + 1}. &nbsp;
                              {val}
                            </p>
                          );
                        })}
                      </div>
                    )}

                    {type == "Student" && (
                      <div style={{ marginTop: "20px" }}>
                        {deleteMessage?.details?.map((val, index) => {
                          return (
                            <p style={{ lineHeight: "2rem" }}>
                              {index + 1}. &nbsp;
                              {val}
                            </p>
                          );
                        })}
                      </div>
                    )}

                    {/* CLIENT  */}
                    {type == "Client" && (
                      <div style={{ marginTop: "20px" }}>
                        {deleteMessage?.details?.map((val) => {
                          return (
                            <p style={{ marginTop: "10px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Student Name :
                              </span>{" "}
                              {val?.studentName}
                              <span>
                                {val?.details?.details?.map((val, index) => {
                                  return (
                                    <p>
                                      {index + 1}. &nbsp;{val}
                                    </p>
                                  );
                                })}
                              </span>
                            </p>
                          );
                        })}
                      </div>
                    )}

                    {/* ADHOC  */}
                    {type == "ADHOC" && (
                      <div className="flexbox" style={{ marginBottom: "30px" }}>
                        <div
                          className="column"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <label
                            htmlFor=""
                            className="label"
                            style={{ textAlign: "left", marginRight: "156px" }}
                          >
                            Reason for Delete
                          </label>
                          <input
                            style={{ minWidth: "170px", maxWidth: "270px" }}
                            className="adminform__input"
                            type="text"
                            placeholder="Reason to Deleting Adhoc"
                            onChange={(e) => {
                              setReasonInput(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="Admin-modal__footer">
                    <div
                      className="admin-button__group"
                      style={{ display: "flex", gap: "20px" }}
                    >
                      <button
                        className="adminbtn adminbtn-blueOutline"
                        onClick={() => {
                          setDeleteModal(false);
                        }}
                      >
                        Cancel
                      </button>

                      {type == "Class" && deleteMessage?.isDeletable ? (
                        <button
                          className="adminbtn adminbtn-danger"
                          onClick={() => {
                            handleDelete(data);
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.66602 7.33325V11.3333"
                              stroke="white"
                              strokeWidth="1.50248"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.33398 7.33325V11.3333"
                              stroke="white"
                              strokeWidth="1.50248"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M2.66602 4.66675H13.3327"
                              stroke="white"
                              strokeWidth="1.50248"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M4 4.66675H8H12V12.0001C12 13.1047 11.1046 14.0001 10 14.0001H6C4.89543 14.0001 4 13.1047 4 12.0001V4.66675Z"
                              stroke="white"
                              strokeWidth="1.50248"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6 3.33333C6 2.59695 6.59695 2 7.33333 2H8.66667C9.40307 2 10 2.59695 10 3.33333V4.66667H6V3.33333Z"
                              stroke="white"
                              strokeWidth="1.50248"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Delete
                        </button>
                      ) : (
                        type !== "Class" && (
                          <button
                            className="adminbtn adminbtn-danger"
                            onClick={() => {
                              handleDelete(data);
                            }}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.66602 7.33325V11.3333"
                                stroke="white"
                                strokeWidth="1.50248"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9.33398 7.33325V11.3333"
                                stroke="white"
                                strokeWidth="1.50248"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M2.66602 4.66675H13.3327"
                                stroke="white"
                                strokeWidth="1.50248"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4 4.66675H8H12V12.0001C12 13.1047 11.1046 14.0001 10 14.0001H6C4.89543 14.0001 4 13.1047 4 12.0001V4.66675Z"
                                stroke="white"
                                strokeWidth="1.50248"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6 3.33333C6 2.59695 6.59695 2 7.33333 2H8.66667C9.40307 2 10 2.59695 10 3.33333V4.66667H6V3.33333Z"
                                stroke="white"
                                strokeWidth="1.50248"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Delete
                          </button>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    </>
  );
}

export default DeleteModal;
