// In your actions file (e.g., cartSlice.js)

import {createSlice} from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        selectedStudents: [],
        selectedClasses: []
    },

    reducers: {
        addStudentToCart: (state, action) => {
            const studentId = action.payload?.id;
            const existingStudent = state.selectedStudents.find((student) => student.id === studentId);

            if (! existingStudent) {
                state.selectedStudents = [action.payload];
            } else {
                toast.error("This student is already in the cart.");
            }
        },

        addClassToCart: (state, action) => {
            state.selectedClasses = [
                ...state.selectedClasses,
                action.payload
            ];
        },

        replaceClassCart: (state, action) => {
            state.selectedClasses = action.payload
        },

        removeAllFromCart: (state) => {
            state.selectedStudents = [];
            state.selectedClasses = [];
        },

        deleteClass: (state, action) => {
            const classId = action.payload;
            state.selectedClasses = state.selectedClasses.filter((classItem) => classItem.id !== classId);
        },

       

    }
});

export const {addStudentToCart, addClassToCart, removeAllFromCart,replaceClassCart, deleteClass} = cartSlice.actions;

export default cartSlice.reducer;
