import React, { useRef, useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getAllCards,
  ledgerPaymentAPI,
  recurringPaymentAPI,
  recurringStatusAPI_MONTHLY,
  recurringStatusAPI_WEEKLY,
  repayPayment,
  weeklyLedgerAPI,
} from "../../adminApi/Ledger";
import { toast } from "react-toastify";
import masterCard from "../../assets/images/icons/mastercard.svg";
import discoverCard from "../../assets/images/icons/discover.svg";
import americanExpress from "../../assets/images/icons/americanExpress.svg";
import jcbCard from "../../assets/images/icons/jcbCard.svg";
import visaCard from "../../assets/images/icons/visa-card.svg";
import {
  isValidEmail,
  isValidExpiryDate,
  isValidName,
  isValidState,
  isValidUSZipCode,
} from "../../../Parent/utility/validation";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/deleteRowIcon.svg";

import { useEffect } from "react";
import { getStates } from "../../adminApi/users";
import creditCardType from "credit-card-type";
import TrashIcon from "../../../Parent/parent-assets/images/trash-icon-red.svg";
import { getCurrentDateYYMMDD, validateScheduleRows } from "../../adminUtility";
import { ReactComponent as AccordionIcon } from "../../../Parent/parent-assets/images/arrow-down-black.svg";

function PaymentModal({
  totalAmount,
  setAddModal,
  selectedLedgerID,
  setShowPaymentModal,
  recurringDuration,
  fetchData,
  ledgerDetails,
  paymentFormData,
  amountTendered,
  LedgerApiData,
  buttonType,
  setLoading,
  PrefilledDetails,
  paymentAmounts,
  paymentMethodStatus,
  setDeleteCardID,
  toggleState,
  selectedLedgerDataFunction,
  setOpenDeleteCardFile,
  deleteCardOnFile,
  STATUS,
  balanceData,
}) {
  const [recurringApiData, setRecurringApiData] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState(null);
  const [recurringDates, setRecurringDates] = useState([]);

  const getCardIcon = () => {
    if (cardType === "american-express") {
      return <img height={"34px"} src={americanExpress} alt="discover" />;
    } else if (cardType === "discover") {
      return <img height={"50px"} src={discoverCard} alt="discover" />;
    } else if (cardType === "mastercard") {
      return <img src={masterCard} alt="masterCard" height={"34px"} />;
    } else if (cardType === "jcb") {
      return <img src={jcbCard} alt="jcb" height={"34px"} />;
    } else if (cardType === "maestro") {
      return <img src={jcbCard} alt="jcb" height={"34px"} />;
    } else if (cardType === "visa") {
      return <img src={visaCard} alt="jcb" height={"34px"} />;
    } else {
      return <></>;
    }
  };
  const currentDate = new Date();
  const [activeAccordion, setActiveAccordion] = useState(null);
  currentDate.setMonth(currentDate.getMonth() + 1);
  const [expiryDate, setExpiryDate] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [getAllStateApi, setGetAllStateApi] = useState();
  const [selectedCardId, setSelectedCardId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);
  const [cardLists, setCardLists] = useState();
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [updatedRecurringDates, setUpdatedRecurringDates] = useState([]);
  const [updatedRecurringDatesWeekly, setUpdatedRecurringDatesWeekly] =
    useState([]);
  const token = localStorage.getItem("access_token");
  const [formErrors, setFormErrors] = useState({
    creditCard: "",
    adhocCharges: "",
    cvv: "",
    expirationDate: "",
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    zip: "",
    country: "",
    // cardType: "",
  });

  const [formData, setFormData] = useState({
    creditCard: "",
    amount: "",
    cvv: "",
    expirationDate: "",
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
    recurringInput: PrefilledDetails?.chargeDay || "",
    isRecurring: buttonType === "Enable" ? true : false,
    recurringAmount: 0,
  });

  const [editedFormData, setEditedFormData] = useState({
    creditCard: "",
    amount: "",
    cvv: "",
    // expirationDate: "",
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    recurringInput: recurringDuration || "",
    cardType: "",
    isRecurring: false,
    updateRecurringDetails: PrefilledDetails?.selectedCheckboxes,
  });
  const [statuses, setStatuses] = useState([]);
  // Add rows
  const [scheduleRows, setScheduleRows] = useState([]);
  const handleAddSchedules = (e, recDate, recIndex) => {
    e.preventDefault();
    const lastObject = recDate?.dateArray[recDate?.dateArray.length - 1];
    const currentRows = scheduleRows[recIndex] || [];
    const updatedRows = [
      ...currentRows,
      {
        memo: lastObject?.memo ? lastObject?.memo : "",
        className: lastObject?.className ? lastObject?.className : "",
        classScheduleId: lastObject?.classScheduleId
          ? lastObject?.classScheduleId
          : recDate?.classScheduleId,
      },
    ];

    setScheduleRows({
      ...scheduleRows,
      [recIndex]: updatedRows,
    });
  };

  const handleDeleteRows = (recIndex, rowIndex) => {
    const updatedRows = scheduleRows[recIndex].filter((_, i) => i !== rowIndex);

    setScheduleRows({
      ...scheduleRows,
      [recIndex]: updatedRows,
    });
  };

  const handleScheduleInputChange = (recIndex, rowIndex, fieldName, value) => {
    const updatedRows = [...scheduleRows[recIndex]];
    updatedRows[rowIndex][fieldName] = value;

    setScheduleRows({
      ...scheduleRows,
      [recIndex]: updatedRows,
    });
  };

  const handleStatusChange = async (
    event,
    index,
    classScheduleId,
    CLASS_TYPE
  ) => {
    const { value } = event.target;
    // Update the status for the specific index
    const updatedStatuses = [...statuses];
    updatedStatuses[index] = { classScheduleId, status: value };
    setStatuses(updatedStatuses);

    try {
      setLoading(true);
      if (CLASS_TYPE == "MONTHLY") {
        const response = await recurringStatusAPI_MONTHLY(
          updatedStatuses[index],
          token,
          selectedLedgerID
        );
        if (response?.status === 200) {
          setLoading(false);
          toast.success(response?.data?.data);
          setAddModal(false);
          fetchData();
        } else {
          setLoading(false);
        }
      } else {
        const response = await recurringStatusAPI_WEEKLY(
          updatedStatuses[index],
          token,
          selectedLedgerID
        );
        if (response?.status === 200) {
          setLoading(false);
          toast.success(response?.data?.data);
          setAddModal(false);
          fetchData();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response.data?.error);
    }
  };

  // Prefilled Details
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      amount: amountTendered
        ? amountTendered
        : PrefilledDetails?.amount_tendered,
      firstName: PrefilledDetails?.first_name,
      lastName: PrefilledDetails?.last_name,
      email: PrefilledDetails?.email,
      address: PrefilledDetails?.address1,
      city: PrefilledDetails?.city,
      state: PrefilledDetails?.state,
      zip: PrefilledDetails?.zip,
      recurringAmount: PrefilledDetails?.recurring_amount,
      updateRecurringDetails: PrefilledDetails?.selectedCheckboxes,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      recurringInput: PrefilledDetails?.chargeDay
        ? PrefilledDetails?.chargeDay
        : 14,
    }));
  }, [PrefilledDetails, amountTendered]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === "checkbox" ? checked : value;

    if (editedFormData.hasOwnProperty(name)) {
      setEditedFormData({
        ...editedFormData,
        [name]: newValue,
      });
    } else {
      if (name === "expirationDate") {
        setFormData({
          ...formData,
          [name]: newValue,
        });
      } else if (name === "amount" || name === "cvv" || name === "zip") {
        newValue = newValue.replace(/\D/g, "");
      }
    }
    if (name === "cvv") {
      const isValidCVV = validateCVV(newValue, cardType);

      if (!isValidCVV) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid CVV for this card type.",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }

    if (name === "expirationDate") {
      setFormData({
        ...formData,
        [name]: newValue,
      });
    } else if (name === "amount" || name === "cvv" || name === "zip") {
      newValue = newValue.replace(/\D/g, "");
    }

    if (name === "creditCard") {
      newValue = newValue.replace(/\D/g, "");
      setCardNumber(newValue);
      const detectedCardType = creditCardType(newValue);
      setCardType(
        detectedCardType?.length > 0 ? detectedCardType[0]?.type : null
      );
      if ((!newValue || newValue?.length < 16) && newValue === "") {
        setCardType(null);
      }
      getCardIcon();
    }

    if (name === "recurringInput") {
      const newValue = type === "checkbox" ? checked : value;
      setFormData((prevData) => ({
        ...prevData,
        recurringInput: newValue,
        isRecurring: newValue !== "" && newValue !== 0,
      }));
    } else {
      setFormData({
        ...formData,
        [name]: newValue,
      });
    }
    if (name === "recurringInput") {
      setFormData((prevData) => ({
        ...prevData,
        recurringInput: newValue,
        isRecurring: isChecked ? newValue : false,
      }));
    } else {
      setFormData({
        ...formData,
        [name]: newValue,
      });
    }

    let errorMessage = "";

    switch (name) {
      case "firstName":
        errorMessage = isValidName(newValue)
          ? ""
          : "First name must not contain numbers.";
        break;
      case "lastName":
        errorMessage = isValidName(newValue)
          ? ""
          : "Last name must not contain numbers.";
        break;
      case "email":
        errorMessage = isValidEmail(newValue) ? "" : "Invalid email address.";
        break;
      case "expirationDate":
        errorMessage = isValidUSZipCode(newValue) ? "" : "Enter Valid Date.";
        break;
      case "zip":
        errorMessage = isValidUSZipCode(newValue)
          ? ""
          : "Zip Code Must be 5-digit .";
        break;

      default:
        errorMessage = "";
        break;
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  const validateForm = () => {
    let isValid = true;

    const errors = {
      creditCard: "",
      cvv: "",
      expirationDate: "",
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      city: "",
      zip: "",
      country: "",
      recurringInput: "",
      // cardType: "",
    };

    if (!isValidName(formData?.firstName)) {
      errors.firstName = "First name must not contain numbers.";
      isValid = false;
    }

    if (!isValidName(formData?.lastName)) {
      errors.lastName = "Last name must not contain numbers.";
      isValid = false;
    }

    if (!isValidEmail(formData?.email)) {
      errors.email = "Invalid email address.";
      isValid = false;
    }

    if (!isValidExpiryDate(formData?.expirationDate)) {
      errors.expirationDate = "Invalid Expiry Date.";
      return false;
    }

    if (!isValidUSZipCode(formData?.zip)) {
      errors.zip = "Zip Code Must be .";
      isValid = true;
    }

    if (formData.state && !isValidState(formData?.state)) {
      errors.email = "Invalid State.";
      return false;
    }

    if (validateCVV(formData?.cvv)) {
      errors.zip = "Zip Code Must be .";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const validateCVV = (cvv, cardType) => {
    let isValid = false;

    // Remove non-digit characters from CVV
    const cleanedCVV = cvv?.replace(/\D/g, "");

    const cvvLengths = {
      amex: 4,
      visa: 3,
      mastercard: 3,
      discover: 3,
    };

    if (cvvLengths.hasOwnProperty(cardType)) {
      const expectedLength = cvvLengths[cardType];
      isValid = cleanedCVV.length === expectedLength;
    }

    return isValid;
  };

  // Submit hanlder
  const handleSubmit = async (e, scheduleID) => {
    e.preventDefault();

    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = String(currentDate.getFullYear()).slice(-2);
    const formattedDate = `${month}/${day}/${year}`;

    // AMOUNT VALIDATION WITH OWED & AMOUNT TO PAY
    let alertNeeded = false;
    let classNames = [];
    balanceData.forEach((classItem) => {
      const amountOwed = classItem?.amountOwed
        ? classItem?.amountOwed
        : classItem?.proRatedFee;
      if (amountOwed < classItem?.amountToPay) {
        alertNeeded = true;
        classNames.push(classItem?.class_name);
      }
    });

    if (alertNeeded) {
      toast.error(
        `Amount to Pay Should be Less than Amount Owed For: ${classNames?.map(
          (Val) => {
            return Val;
          }
        )}.`
      );
      return;
    }

    const classPaymentDetailsArray = balanceData?.map((val, index) => {
      const amountOwed = val?.amountOwed ? val?.amountOwed : val?.proRatedFee;
      // if (amountToPay >= amountOwed) {
      //   toast.error("Amount to pay cannot exceed the amount owed.");
      // }
      return {
        classSchedule: parseInt(val?.classSchedule, 10),
        weeklyCampScheduleId:
          val?.classSchedule == undefined || null || ""
            ? parseInt(val?.weeklyCampScheduleId, 10)
            : "",
        amountOwed: amountOwed,
        ledgerDetailId: val?.ledgerDetailId,
        amountToPay: val?.amountToPay ? val?.amountToPay : 0,
        enrollmentStartDate: val?.enrollmentStartDate,
        enrollmentEndDate: val?.enrollmentEndDate,
      };
    });

    if (buttonType === "PAY") {

      const requiredFields = {
        creditCard: "CCard",
        firstName: "First Name",
        lastName: "Last Name",
        cvv: "CVV",
        expirationDate: "Expiration Date",
        email: "Email",
        address: "Address",
        city: "City",
        state: "State",
        zip: "Zip",
      };

      if ((paymentFormData?.amountTendered == undefined) | "") {
        toast.error("Enter Amount tendered !");
        return;
      }
      if (
        (paymentFormData?.amountTendered === "" &&
          paymentFormData?.memo === "" &&
          amountTendered == "") ||
        undefined
      ) {
        toast.error("Enter memo & amount tendered value!");
        return;
      }

      if (paymentFormData?.memo === "") {
        toast.error("Enter memo!");
        return;
      }
      if (paymentFormData?.amountTendered === "") {
        toast.error("Enter amount tendered!");
        return;
      }
      if (paymentFormData?.memo === "") {
        toast.error("Enter memo!");
        return;
      }
      if (paymentFormData?.paymentProcessor === "") {
        toast.error("Select Payement Processor to Proceed!");
        return;
      }
      let emptyFields = [];

      for (const [fieldName, value] of Object.entries(requiredFields)) {
        if (
          !formData[fieldName] ||
          (typeof formData[fieldName] === "string" &&
            formData[fieldName].trim() === "")
        ) {
          emptyFields.push(value);
        }
      }

      if (emptyFields.length === Object.keys(requiredFields)?.length) {
        toast.error("Please Enter all the fields!", {
          toastId: "error",
        });
        return;
      }

      if (emptyFields.length > 0) {
        const fieldsText = emptyFields?.join(", ");
        toast.error(`Please fill in the following fields: ${fieldsText}`, {
          toastId: "error",
        });
        return;
      }

      if (emptyFields.length > 0) {
        const fieldsText = emptyFields.join(", ");
        toast.error(`Please fill in the following fields: ${fieldsText}`, {
          toastId: "error",
        });
        return;
      }

      if (validateForm()) {
        return;
      }


      const APIDATA = {
        payment_method: paymentFormData?.paymentMethod,
        payment_preprocessor: paymentFormData?.paymentProcessor,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        address1: formData.address,
        address2: formData.address,
        city: formData?.city,
        state: formData?.state,
        zip: formData.zip,
        country: "USA",
        card_number: formData.creditCard,
        expiry: expiryDate,
        cvv: formData.cvv,
        memo: paymentFormData?.memo,
        paymentDate: formattedDate,
        checkNumber: paymentFormData?.checkNumber,
        adhocPaymentDetails: paymentAmounts?.map((val) => {
          return {
            id: val?.id,
            amountOwed: val?.total_amount_to_pay,
            amountToPay: val?.amountToPay ? val?.amountToPay : 0,
          };
        }),
        amountTendered: amountTendered,
        // RECURING DATA
        isRecurringEnabled: formData.isRecurring,
        recurringDuration: formData?.isRecurring
          ? formData?.recurringInput
          : "",
        recurringAmount: parseInt(formData?.recurringAmount),
        classPaymentDetails: classPaymentDetailsArray,
        // classPaymentDetails: balanceData,
        recurringDates: updatedRecurringDates,
      };

      const token = localStorage.getItem("access_token");

      setLoading(true);
      try {
        const response = await ledgerPaymentAPI(
          token,
          selectedLedgerID,
          APIDATA
        );
        setLoading(false);
        
        if (
          response?.data &&
          response?.data?.data &&
          response?.data?.error === null
        ) {
          toast.success(response?.data?.data);
          setAddModal(false);
          setShowPaymentModal(false);
          fetchData();
        }
      } catch (error) {
        return error;
      }
    }

    // RECURRINGG
    if (buttonType === "ENABLE") {
      const emptyFields = Object.keys(formData).filter(
        (key) =>
          !formData[key] &&
          key !== "state" &&
          key !== "isRecurring" &&
          key !== "recurringInput" &&
          key !== "amount" &&
          key !== "recurringAmount" &&
          key !== "updateRecurringDetails"
      );

      if (emptyFields.length > 0) {
        const emptyFieldNames = emptyFields.map((field) => field);
        toast.error(
          `Please fill in the following fields: ${emptyFieldNames.join(", ")}`,
          {
            toastId: "error",
          }
        );
        return;
      }

      if (validateForm()) {
        return;
      }

      const classPaymentDetails = LedgerApiData?.data?.paymentDetails?.map(
        (val, index) => ({
          classSchedule: val?.classSchedule,
          amountOwed: val?.proRatedFee || 0,
          amountToPay: totalAmount[index] || 0,
          ledgerDetailId: val?.ledgerDetailId,
          enrollmentStartDate: val?.enrollmentStartDate,
          enrollmentEndDate: val?.enrollmentEndDate,
        })
      );

      const APIDATA = {
        payment_method: 0,
        payment_preprocessor: paymentFormData?.paymentProcessor,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        address1: formData.address,
        address2: formData.address,
        city: formData?.city,
        state: formData?.state,
        zip: formData.zip,
        country: "USA",
        card_number: formData.creditCard,
        expiry: expiryDate,
        cvv: formData.cvv,
        memo: paymentFormData?.memo,
        paymentDate: formattedDate,
        checkNumber: paymentFormData?.checkNumber,
        amountTendered: amountTendered,
        // RECURING DATA
        isRecurringEnabled: formData.isRecurring,
        recurringDuration: formData?.isRecurring
          ? formData?.recurringInput
          : "",
        recurringAmount: parseInt(formData?.recurringAmount),
        classPaymentDetails: classPaymentDetails,
        recurringDates:
          STATUS == "MONTHLY"
            ? updatedRecurringDates
            : updatedRecurringDatesWeekly,
      };

      const token = localStorage.getItem("access_token");
      setLoading(true);

      try {
        const apiResponse = await recurringPaymentAPI(
          token,
          selectedLedgerID,
          APIDATA
        )
          .then((res) => {
            if (res?.data === null) {
              fetchData();
              setLoading(false);
              toast.error(res.error);
            } else {
              if (res === undefined) {
                fetchData();
                setLoading(false);
              } else {
                setTimeout(() => {
                  setLoading(false);
                }, 1000);
                setShowPaymentModal(false);
                toast.success(res?.data);
                setAddModal(false);
                fetchData();
              }
            }
            fetchData();
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.error, {
              toastId: "error",
            });
          });
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data);
      }
    }
  };

  const options = cardLists?.map((val) => ({
    id: val?.id,
    description: val?.description,
  }));

  // Card on File Prefilled
  useEffect(() => {
    if (PrefilledDetails && PrefilledDetails.cardOnFileId && !selectedCardId) {
      const preselectedOption = options?.find(
        (option) => option?.id === PrefilledDetails?.cardOnFileId
      );
      if (preselectedOption) {
        setSelectedCardId(preselectedOption.id);
        setSelectedOption(preselectedOption.description);
      }
    }
  }, [PrefilledDetails, options, selectedCardId]);

  // STATES API
  useEffect(() => {
    async function getAllStates() {
      const response = await getStates();
      setGetAllStateApi(response?.data?.getStates);
    }
    getAllStates();
  }, []);

  const validateFormData = () => {
    const emptyFields = [];
    for (const field in formData) {
      if (
        field == "firstName" ||
        field == "lastName" ||
        field == "cvv" ||
        field == "expirationDate" ||
        field == "address" ||
        field == "city" ||
        field == "state" ||
        field == "zip" ||
        field == "creditCard"
      ) {
        if (!formData[field]) {
          emptyFields.push(field);
        }
      }
    }

    if (emptyFields.length > 0) {
      let errorMessage;
      if (emptyFields.length === 1) {
        errorMessage = `Please enter the following field: ${emptyFields[0]}`;
      } else {
        errorMessage = `Please enter the following fields: ${emptyFields.join(
          ", "
        )}`;
      }
      toast.error(errorMessage, {
        toastId: "error",
      });
      return true;
    } else {
      return false;
    }
  };
  // Submit USing Card on File
  const handleCardOnChange = async (e, classScheduleId, recIndex, cardType) => {
    e.preventDefault();

    if (cardType === "newCard") {
      const isValid = validateFormData();
      if (isValid) {
        return;
      }
    }

    // Validdation

    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = String(currentDate.getFullYear()).slice(-2);

    const formattedDate = `${month}/${day}/${year}`;
    const classPaymentDetailsArray = ledgerDetails?.map((val, index) => ({
      classSchedule: parseInt(val?.classSchedule, 10),
      amountOwed: parseFloat(val?.proRatedFee),
      ledgerDetailId: val?.ledgerDetailId,
      amountToPay: val?.amountToPay || 0,
      enrollmentStartDate: val?.enrollmentStartDate,
      enrollmentEndDate: val?.enrollmentEndDate,
    }));
    // Schedule Rows Setup
    const APIDATA = {
      payment_method: null,
      payment_preprocessor: paymentFormData?.paymentProcessor,
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      address1: formData.address,
      address2: formData.address,
      city: formData?.city,
      state: formData?.state,
      zip: formData.zip,
      country: "USA",
      card_number: formData.creditCard,
      expiry: expiryDate,
      cvv: formData.cvv,
      memo: paymentFormData?.memo,
      paymentDate: formattedDate,
      checkNumber: paymentFormData?.checkNumber,
      amountTendered: amountTendered,
      isRecurringEnabled: formData.isRecurring,
      recurringDuration: formData?.isRecurring ? formData?.recurringInput : "",
      recurringAmount: parseInt(formData?.recurringAmount),
      classPaymentDetails: classPaymentDetailsArray,
      // recurringDates: selectedCheckboxes,
      recurringDates:
        STATUS == "MONTHLY"
          ? scheduleRows.length == 0
            ? updatedRecurringDates[recIndex]?.dateArray
            : [
              ...scheduleRows[recIndex],
              ...updatedRecurringDates[recIndex]?.dateArray,
            ]
          : updatedRecurringDatesWeekly[recIndex]?.dateArray,
      cardOnFile: paymentMethodStatus === "cardOnFile",
      cardOnFileId: selectedCardId,
      cardDeleted: deleteCardOnFile?.deleteArray,
    };

    const isEmptyAmount = classPaymentDetailsArray.some(
      (detail) =>
        detail.amountToPay === undefined ||
        detail.amountToPay === null ||
        detail.amountToPay === ""
    );

    // Check if card on file is not selected
    const isCardOnFileNotSelected =
      !APIDATA?.cardOnFileId && APIDATA?.cardOnFile;

    if (isEmptyAmount || isCardOnFileNotSelected) {
      if (isEmptyAmount) {
        toast.error("Please enter an amount for all selected classes.");
      }
      if (isCardOnFileNotSelected) {
        toast.error("Please select a card from the dropdown.");
      }
      return;
    }
    const token = localStorage.getItem("access_token");
    if (STATUS === "MONTHLY") {
      setLoading(true);
      if (!validateScheduleRows(scheduleRows)) {
        setLoading(false);
        validateScheduleRows(scheduleRows);
      } else {
        try {
          const apiResponse = await recurringPaymentAPI(
            token,
            selectedLedgerID,
            APIDATA,
            classScheduleId
          )
            .then((res) => {
              if (res?.data == null) {
                setLoading(false);
                toast.error(res?.error);
              } else {
                setLoading(false);
                setShowPaymentModal(false);
                toast.success(res?.data);
                setAddModal(false);

                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 500);
                fetchData();
              }
            })
            .catch((error) => {
              setLoading(false);
              toast.error(error?.response?.data?.error, {
                toastId: "error",
              });
            });
        } catch (error) {
          setLoading(false);
          toast.error(error?.response?.data, {
            toastId: "error",
          });
        }
      }
    } else {
      setLoading(true);
      try {
        const response = await weeklyLedgerAPI(
          selectedLedgerID,
          classScheduleId,
          token,
          APIDATA
        );

        if (response?.data?.data == null) {
          setLoading(false);
          toast.error(response?.response?.data?.error);
          return;
        } else {
          setLoading(false);
          toast.success(response?.data?.data);
          setAddModal(false);
          fetchData();
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.error, {
          toastId: "error",
        });
      }
    }
  };
  // Handle Outside Clicked
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (e, option) => {
    setSelectedCardId(option?.id);
    setSelectedOption(option?.description);
    setIsOpen(false);
  };

  // handle Delete iCOn
  const handleDeleteCard = (selectedId) => {
    // setCofID((prevCofID) => [...prevCofID, selectedId]);

    if (cardLists.length > 1) {
      setOpenDeleteCardFile(true);
      setDeleteCardID(selectedId);
      setSelectedOption(null);
      fetchData();
    } else {
      toast.error("You need to add new Credit Card to Delete!");
    }
  };

  const fetchCards = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await getAllCards(token, selectedLedgerID);

      if (deleteCardOnFile.deleteArray.length > 0) {
        const filteredCards = response.data.filter(
          (card) => !deleteCardOnFile.deleteArray.includes(card.id)
        );
        setCardLists(filteredCards);
      } else {
        setCardLists(response?.data);
      }
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    fetchCards();
  }, [toggleState]);

  // REPAY PAYMENT
  const repayHandler = async (e, val) => {
    e.preventDefault();
    const token = localStorage.getItem("access_token");
    if (cardLists?.length === 0) {
      toast.error("Please Add Card Details!", {
        toastId: "error",
      });
      return;
    }

    if (
      selectedCardId == null ||
      selectedCardId == undefined ||
      !selectedCardId
    ) {
      toast.error("Please Select the Card to proceed!");
      return;
    }

    const payload = {
      date: getCurrentDateYYMMDD(val?.date),
      // id: val?.id,
      id: STATUS == "MONTHLY" ? val?.id : val?.ledgerDetailId,
      payment_method: PrefilledDetails?.payment_method_fk,
      payment_preprocessor: PrefilledDetails?.payment_processor_fk,
      first_name: PrefilledDetails?.first_name,
      last_name: PrefilledDetails?.last_name,
      email: PrefilledDetails?.email,
      address1: PrefilledDetails?.address1,
      address2: PrefilledDetails?.address2,
      city: PrefilledDetails?.city,
      state: PrefilledDetails?.state,
      zip: PrefilledDetails?.zip,
      country: PrefilledDetails?.country,
      card_number: "",
      expiry: PrefilledDetails?.card_expiry_date,
      cvv: PrefilledDetails?.cvv,
      paymentDate: getCurrentDateYYMMDD(PrefilledDetails?.date),
      isRecurringEnabled:
        PrefilledDetails?.recurring_enabled === 1 ? false : true,
      recurringDuration: "",
      recurringAmount: null,
      classPaymentDetails: [],
      cardOnFile: true,
      cardOnFileId: selectedCardId,
      amount: val?.fee,
    };
    setLoading(true);
    try {
      const response = await repayPayment(token, payload);
      if (response.status === 200) {
        setLoading(false);
        selectedLedgerDataFunction();
        toast.success(response?.data?.data);
      } else {
        toast.error(response.response?.data?.error);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    }
    setLoading(false);
  };
  // accordion handler
  const toggleAccordion = (accordionId) => {
    setActiveAccordion((prevActiveAccordion) =>
      prevActiveAccordion === accordionId ? null : accordionId
    );
  };

  useEffect(() => {
    if (
      LedgerApiData &&
      LedgerApiData.data &&
      LedgerApiData.data.recurringDates?.length
    ) {
      setRecurringDates(LedgerApiData?.data?.recurringDates);
      setUpdatedRecurringDates(LedgerApiData?.data?.recurringDates);
    }

    if (
      LedgerApiData &&
      LedgerApiData.data &&
      LedgerApiData.data.recurringDates?.length
    ) {
    }

    // Mapping of Data
    if (LedgerApiData?.data?.recurringDates?.length) {
      setRecurringApiData(LedgerApiData?.data?.recurringDates);
    } else {
      setRecurringApiData(LedgerApiData?.data?.recurringWeeklyDates);
    }
    setUpdatedRecurringDatesWeekly(LedgerApiData?.data?.recurringWeeklyDates);
  }, [LedgerApiData]);

  useEffect(() => {
    if (LedgerApiData?.data?.recurringDates?.length == 0) {
      setUpdatedRecurringDates(LedgerApiData?.data?.recurringDates);
    } else {
      setUpdatedRecurringDatesWeekly(LedgerApiData?.data?.recurringWeeklyDates);
    }

    setUpdatedRecurringDatesWeekly(LedgerApiData?.data?.recurringWeeklyDates);
  }, [LedgerApiData]);

  // CHECKBOX ON CHANGES
  const handleInputChange = (e, recIndex, dateIndex) => {
    if (STATUS === "MONTHLY") {
      const updated = [...updatedRecurringDates];
      updated[recIndex].dateArray[dateIndex].currentlyPaying = e.target.checked;
      setUpdatedRecurringDates(updated);
    } else {
      const updated = [...updatedRecurringDatesWeekly];
      updated[recIndex].dateArray[dateIndex].currentlyPaying = e.target.checked;
      setUpdatedRecurringDatesWeekly(updated);
    }
  };

  // Event handler to update date
  const handleDateChanges = (date, recIndex, dateIndex) => {
    if (STATUS == "MONTHLY") {
      const updated = [...updatedRecurringDates];
      updated[recIndex].dateArray[dateIndex].date = date
        .toISOString()
        .split("T")[0];
      setUpdatedRecurringDates(updated);
    } else {
      const updated = [...updatedRecurringDatesWeekly];
      updated[recIndex].dateArray[dateIndex].date = date
        .toISOString()
        .split("T")[0];
      setUpdatedRecurringDatesWeekly(updated);
    }
  };
  // Event handler to update fee
  const handleAmountChanges = (e, recIndex, dateIndex) => {
    if (STATUS == "MONTHLY") {
      const updated = [...updatedRecurringDates];
      updated[recIndex].dateArray[dateIndex].fee = e.target.value;
      setUpdatedRecurringDates(updated);
    } else {
      const updated = [...updatedRecurringDatesWeekly];
      updated[recIndex].dateArray[dateIndex].fee = e.target.value;
      setUpdatedRecurringDatesWeekly(updated);
    }
  };

  const handleMemoChanges = (e, recIndex, dateIndex) => {
    if (STATUS == "MONTHLY") {
      const updated = [...updatedRecurringDates];
      updated[recIndex].dateArray[dateIndex].memo = e.target.value;
      setUpdatedRecurringDates(updated);
    } else {
      const updated = [...updatedRecurringDatesWeekly];
      updated[recIndex].dateArray[dateIndex].memo = e.target.value;
      setUpdatedRecurringDatesWeekly(updated);
    }
  };

  const handleEnrollmentStartDateChange = (e, recIndex, dateIndex) => {
    if (STATUS == "MONTHLY") {
      const updated = [...updatedRecurringDates];
      updated[recIndex].dateArray[dateIndex].enrollmentStartDate =
        e.target.value;
      setUpdatedRecurringDates(updated);
    } else {
      const updated = [...updatedRecurringDatesWeekly];
      updated[recIndex].dateArray[dateIndex].enrollmentStartDate =
        e.target.value;
      setUpdatedRecurringDatesWeekly(updated);
    }
  };
  const handleEnrollmentEndDateChange = (e, recIndex, dateIndex) => {
    if (STATUS == "MONTHLY") {
      const updated = [...updatedRecurringDates];
      updated[recIndex].dateArray[dateIndex].enrollmentEndDate = e.target.value;
      setUpdatedRecurringDates(updated);
    } else {
      const updated = [...updatedRecurringDatesWeekly];
      updated[recIndex].dateArray[dateIndex].enrollmentEndDate = e.target.value;
      setUpdatedRecurringDatesWeekly(updated);
    }
  };

  return (
    <>
      {/* CARD ON FILE FUNCTIONALITY  */}
      {paymentMethodStatus === "cardOnFile" && buttonType === "ENABLE" ? (
        <div className="payment_container">
          {/* CHOOSE COF  */}
          <div className="flexbox  cof_dropdown">
            <div className="column">
              <label className="label">Card on File:</label>
              <div class="adminform__group">
                <div className="custom-dropdown " ref={dropdownRef}>
                  <div
                    className="selected-option adminform__select"
                    onClick={handleToggleDropdown}
                  >
                    {selectedOption ? (
                      <p className="">
                        <span>{selectedOption}</span>
                        <span
                          className="delete-icon"
                          onClick={() => setSelectedOption(null)}
                        ></span>
                      </p>
                    ) : (
                      <>
                        <span>Select an option</span>
                      </>
                    )}
                  </div>
                  {isOpen && (
                    <div className="options">
                      {options?.map((option) => (
                        <div
                          key={option.id}
                          className="option adminform__group"
                          onClick={(e) => handleOptionClick(e, option)}
                        >
                          <span>{option?.description}</span>
                          <span
                            className="delete-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteCard(option?.id);
                            }}
                          >
                            <img src={TrashIcon} alt="Delete" />
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <form>
            <div className="">
              {/* Day of month to charge  & Payment Options */}
              <div className="cof_container">
                <div className="columm ">
                  <label className="label " htmlFor="flexCheckChecked">
                    Payment
                  </label>
                  <div className="adminform__group__grayBox">
                    <label className="label " htmlFor="flexCheckChecked">
                      Recurring Payment
                    </label>

                    <input
                      className=""
                      type="checkbox"
                      value=""
                      name="isChecked"
                      checked={formData?.isRecurring || true}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          isRecurring: !formData?.isRecurring,
                        });
                      }}
                    />
                  </div>
                </div>

                {STATUS === "MONTHLY" && (
                  <div className="column ">
                    <label htmlFor="" className="label">
                      Day of Month to Charge
                    </label>
                    <div className="adminform__group">
                      <input
                        style={{ width: "150px" }}
                        type="number"
                        className="adminform__input"
                        name="recurringInput"
                        placeholder="Recurring duration in months."
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            recurringInput: e.target.value,
                          });
                        }}
                        value={formData.recurringInput}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="flexbox">
                {formData?.isRecurring || true ? (
                  <div className="class_container">
                    {/* LABELS COF */}
                    <div className="flexbox">
                      <div
                        className="column first_column_label"
                        style={{ minWidth: "450px" }}
                      >
                        <label htmlFor="">Class Name</label>
                      </div>
                      <div className="column" style={{ maxWidth: "200px" }}>
                        <label htmlFor="">Card Used</label>
                      </div>
                      <div
                        className="column"
                        style={{ maxWidth: "100px", minWidth: "200px" }}
                      >
                        <label htmlFor="">Recurring</label>
                      </div>
                      <div
                        className="column"
                        style={{ maxWidth: "100px", minWidth: "250px" }}
                      >
                        <label htmlFor="">Status</label>
                      </div>
                    </div>
                    {/* RECURRING DATAS  */}

                    {STATUS === "MONTHLY" ? (
                      LedgerApiData?.data?.recurringDates?.length > 0 ? (
                        LedgerApiData?.data?.recurringDates?.map(
                          (recDate, recIndex) => {
                            const accordionId = `accordion-${recIndex}`;
                            return (
                              <div className="" key={recIndex}>
                                <div className="flexbox">
                                  <div
                                    className="column first_column"
                                    style={{ minWidth: "200px" }}
                                  >
                                    <div className="adminform__group">
                                      <input
                                        type="text"
                                        className="adminform__input"
                                        value={recDate?.className}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="column  class_name_input"
                                    style={{
                                      cursor: "pointer",
                                      maxWidth: "40px",
                                    }}
                                    onClick={() => toggleAccordion(accordionId)}
                                  >
                                    <AccordionIcon
                                      className={
                                        expandedAccordion === recIndex
                                          ? "rotateIcon"
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div
                                    className="column "
                                    style={{ maxWidth: "140px" }}
                                  >
                                    <button
                                      className="adminbtn adminbtn-blueOutline"
                                      onClick={(e) => {
                                        handleCardOnChange(
                                          e,
                                          recDate.classScheduleId == undefined
                                            ? recDate?.campId
                                            : recDate?.classScheduleId,
                                          recIndex,
                                          "cardOnFile"
                                        );
                                      }}
                                    >
                                      + Save Recurring
                                    </button>
                                  </div>

                                  {recDate?.cardOnFileDescription ? (
                                    <div
                                      className="column "
                                      style={{
                                        minWidth: "100px !important",
                                        maxWidth: "100px",
                                      }}
                                    >
                                      <p className="label">
                                        {
                                          recDate?.cardOnFileDescription?.split(
                                            " "
                                          )[0]
                                        }
                                        <br />
                                        <span>
                                          {
                                            recDate?.cardOnFileDescription?.split(
                                              " "
                                            )[1]
                                          }
                                        </span>
                                        <br />
                                        <span>
                                          {
                                            recDate?.cardOnFileDescription?.split(
                                              " "
                                            )[2]
                                          }
                                        </span>
                                      </p>
                                    </div>
                                  ) : (
                                    <div
                                      className="column"
                                      style={{
                                        minWidth: "250px",
                                        maxWidth: "300px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        className="adminform__group"
                                        style={{ textAlign: "left" }}
                                      >
                                        <p
                                          className="label"
                                          style={{ textAlign: "center" }}
                                        >
                                          N/A
                                        </p>
                                      </div>
                                    </div>
                                  )}

                                  {recDate?.currentRecurring !== undefined &&
                                    recDate?.totalRecurring !== undefined ? (
                                    <div
                                      className="column"
                                      style={{ maxWidth: "230px" }}
                                    >
                                      <p
                                        className="label"
                                        style={{
                                          textAlign: "center",
                                          paddingTop: "15px ",
                                          paddingLeft: "20px",
                                        }}
                                      >
                                        {recDate?.currentRecurring} of{" "}
                                        {recDate?.totalRecurring}
                                      </p>
                                    </div>
                                  ) : (
                                    <div
                                      className="column "
                                      style={{ maxWidth: "230px" }}
                                    >
                                      <p className="label">0 of 0</p>
                                    </div>
                                  )}

                                  {/* STATUS */}
                                  <div className="column first_column">
                                    <select
                                      name="status"
                                      id={`status-${recIndex}`}
                                      style={{ maxWidth: "120px" }}
                                      className="status_input adminform__input"
                                      value={
                                        (statuses[recIndex] &&
                                          statuses[recIndex].status) ||
                                        ""
                                      }
                                      onChange={(e) =>
                                        handleStatusChange(
                                          e,
                                          recIndex,
                                          recDate.classScheduleId,
                                          "MONTHLY"
                                        )
                                      }
                                    >
                                      {recDate?.status ? (
                                        <option
                                          selected
                                          value={recDate?.status}
                                        >
                                          {recDate?.status}
                                        </option>
                                      ) : (
                                        <option value="" selected disabled>
                                          {" "}
                                          Select Status
                                        </option>
                                      )}
                                      {recDate?.status !== "Active" && (
                                        <option value="Active">Active</option>
                                      )}
                                      {recDate?.status !== "Pause" && (
                                        <option value="Pause">Pause</option>
                                      )}
                                      {recDate?.status !== "Complete" && (
                                        <option value="Complete">
                                          Complete
                                        </option>
                                      )}
                                    </select>
                                  </div>
                                </div>

                                {/* Recurring Dates for COF & MONTHLY */}
                                {activeAccordion === accordionId && (
                                  <>
                                    {recDate?.dateArray.map((dates, index) => {
                                      let isDisabled = true;

                                      if (dates?.status && dates?.prefilled) {
                                        isDisabled = true;
                                      } else {
                                        isDisabled = false;
                                      }
                                      return (
                                        <div
                                          className="flexbox"
                                          key={index}
                                          style={{ marginLeft: "2%" }}
                                        >
                                          <div
                                            className="column"
                                            style={{
                                              maxWidth: "40px",
                                              marginTop: "30px",
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              checked={dates?.currentlyPaying}
                                              disabled={
                                                dates?.showRepay
                                                  ? true
                                                  : dates?.prefilled
                                                    ? true
                                                    : false
                                              }
                                              onChange={(e) =>
                                                handleInputChange(
                                                  e,
                                                  recIndex,
                                                  index
                                                )
                                              }
                                            />
                                          </div>
                                          <div
                                            className="column"
                                            style={{
                                              maxWidth: "200x",
                                              minWidth: "200px",
                                            }}
                                          >
                                            <div className="adminform__group fee_input">
                                              <label
                                                htmlFor=""
                                                className="label"
                                              >
                                                Memo
                                              </label>
                                              <br />
                                              <input
                                                style={{ maxWidth: "80px" }}
                                                disabled={isDisabled}
                                                className="adminform__input"
                                                name="memo"
                                                placeholder="Memo"
                                                type="text"
                                                value={dates?.memo}
                                                onChange={(e) =>
                                                  handleMemoChanges(
                                                    e,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>

                                          <div className="column">
                                            <div className="adminform__group">
                                              <label
                                                htmlFor=""
                                                className="label"
                                              >
                                                Enrollment Start Date
                                              </label>
                                              <br />
                                              <input
                                                style={{ maxWidth: "100px" }}
                                                type="date"
                                                disabled={isDisabled}
                                                value={
                                                  dates?.enrollmentStartDate
                                                }
                                                className="adminform__input"
                                                name="enrollmentStartDate"
                                                onChange={(e) =>
                                                  handleEnrollmentStartDateChange(
                                                    e,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="column">
                                            <div className="adminform__group">
                                              <label
                                                htmlFor=""
                                                className="label"
                                              >
                                                Enrollment End Date
                                              </label>
                                              <br />
                                              <input
                                                style={{ maxWidth: "100px" }}
                                                type="date"
                                                disabled={isDisabled}
                                                value={dates?.enrollmentEndDate}
                                                className="adminform__input"
                                                name="enrollmentEndDate"
                                                onChange={(e) =>
                                                  handleEnrollmentEndDateChange(
                                                    e,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="column">
                                            <div className="adminform__group">
                                              <label
                                                htmlFor=""
                                                className="label"
                                              >
                                                Recurring Date
                                              </label>
                                              <input
                                                style={{ maxWidth: "100px" }}
                                                type="date"
                                                disabled={isDisabled}
                                                value={dates?.date}
                                                className="adminform__input"
                                                onChange={(e) =>
                                                  handleDateChanges(
                                                    e.target.valueAsDate,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>

                                          {dates?.campType && (
                                            <div className="column">
                                              <div className="adminform__group">
                                                <input
                                                  type="text"
                                                  value={dates?.campType}
                                                  disabled
                                                  className="adminform__input"
                                                />
                                              </div>
                                            </div>
                                          )}

                                          <div className="column">
                                            <div className="adminform__group fee_input">
                                              <span
                                                className="dollar"
                                                style={{ top: "30px" }}
                                              >
                                                $
                                              </span>
                                              <label
                                                htmlFor=""
                                                className="label"
                                              >
                                                Fee($)
                                              </label>
                                              <br />
                                              <input
                                                style={{ maxWidth: "80px" }}
                                                className="adminform__input "
                                                type="number"
                                                name="fee"
                                                value={dates?.fee}
                                                disabled={isDisabled}
                                                onChange={(e) =>
                                                  handleAmountChanges(
                                                    e,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {/* MONTHlY COF mEMO  */}

                                          <div className="column">
                                            <div className="adminform__group">
                                              <label htmlFor=""></label>

                                              <p className="label">
                                                {dates?.status}
                                              </p>
                                            </div>
                                          </div>

                                          {dates?.showRepay && (
                                            <div className="column">
                                              <button
                                                className="adminbtn adminbtn-blueOutline"
                                                onClick={(e) => {
                                                  repayHandler(e, dates);
                                                }}
                                              >
                                                Repay
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })}
                                    {/* SCHEUDLE ROWS FOR COF - MONTHLY  */}
                                    {scheduleRows?.[recIndex]?.map(
                                      (val, ind) => {
                                        return (
                                          <div
                                            className="flexbox"
                                            key={recIndex}
                                            style={{ marginLeft: "4%" }}
                                          >
                                            <div
                                              className="column"
                                              style={{
                                                height: "23px",
                                                minWidth: "20px",
                                                maxWidth: "50px",
                                                paddingTop: "30px",
                                              }}
                                            >
                                              <input
                                                type="checkbox"
                                                placeholder="Rec"
                                                value={val.currentlyPaying}
                                                name="currentlyPaying"
                                                onChange={(e) =>
                                                  handleScheduleInputChange(
                                                    recIndex,
                                                    ind,
                                                    "currentlyPaying",
                                                    e.target.checked
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="column">
                                              <div className="adminform__group fee_input">
                                                <label
                                                  htmlFor=""
                                                  className="label"
                                                >
                                                  Memo
                                                </label>
                                                <input
                                                  className="adminform__input"
                                                  name="memo"
                                                  placeholder="Memo"
                                                  type="text"
                                                  value={val?.memo}
                                                  // disabled={isDisabled}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "memo",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="column">
                                              <div className="adminform__group fee_input">
                                                <label
                                                  htmlFor=""
                                                  className="label"
                                                >
                                                  Enrollment Start Date
                                                </label>
                                                <br />
                                                <input
                                                  className="adminform__input"
                                                  name="enrollmentStartDate"
                                                  placeholder="Enrollment start Date"
                                                  type="date"
                                                  value={
                                                    val?.enrollmentStartDate
                                                  }
                                                  // disabled={isDisabled}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "enrollmentStartDate",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="column">
                                              <div className="adminform__group fee_input">
                                                <label
                                                  htmlFor=""
                                                  className="label"
                                                >
                                                  Enrollment End Date
                                                </label>
                                                <br />
                                                <input
                                                  className="adminform__input"
                                                  name="enrollmentEndDate"
                                                  placeholder="Enrollment End Date"
                                                  type="date"
                                                  value={val?.enrollmentEndDate}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "enrollmentEndDate",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="adminform__group">
                                              <label
                                                htmlFor=""
                                                className="label"
                                              >
                                                Recurring Date
                                              </label>

                                              <input
                                                type="date"
                                                className="adminform__input"
                                                placeholder="date"
                                                style={{ minWidth: "150px" }}
                                                value={val.date}
                                                onChange={(e) =>
                                                  handleScheduleInputChange(
                                                    recIndex,
                                                    ind,
                                                    "date",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>

                                            <div className="column">
                                              <div className="adminform__group">
                                                <span
                                                  className="dollar"
                                                  style={{
                                                    left: "2px",
                                                    top: "30px",
                                                  }}
                                                >
                                                  $
                                                </span>
                                                <label
                                                  htmlFor=""
                                                  className="label"
                                                >
                                                  Fee($)
                                                </label>

                                                <input
                                                  style={{ minWidth: "150px" }}
                                                  type="number"
                                                  placeholder="Amount"
                                                  className="adminform__input"
                                                  value={val?.fee}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "fee",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>

                                            {/* DELETE ICON FOR ADDING EXTRA SCHEUDLS  */}
                                            <div
                                              className="column"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                handleDeleteRows(recIndex, ind);
                                              }}
                                            >
                                              <DeleteIcon />
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                    <div className="column">
                                      <button
                                        className="adminbtn adminbtn-primary"
                                        style={{ marginLeft: "5%" }}
                                        onClick={(e) => {
                                          handleAddSchedules(
                                            e,
                                            recDate,
                                            recIndex
                                          );
                                        }}
                                      >
                                        + Add recurring
                                      </button>
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          }
                        )
                      ) : (
                        <label className=" important_field">
                          No Monthly Recurrings Found !
                        </label>
                      )
                    ) : LedgerApiData?.data?.recurringWeeklyDates?.length >
                      0 ? (
                      LedgerApiData?.data?.recurringWeeklyDates?.map(
                        (recDate, recIndex) => {
                          const accordionId = `accordion-${recIndex}`;
                          return (
                            <div className="" key={recIndex}>
                              <div className="flexbox">
                                <div
                                  className="column first_column"
                                  style={{ minWidth: "250px" }}
                                >
                                  <div className="adminform__group">
                                    <input
                                      type="text"
                                      className="adminform__input"
                                      value={recDate?.className}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div
                                  className="column  class_name_input"
                                  style={{
                                    cursor: "pointer",
                                    maxWidth: "40px",
                                  }}
                                  onClick={() => toggleAccordion(accordionId)}
                                >
                                  <AccordionIcon
                                    className={
                                      expandedAccordion === recIndex
                                        ? "rotateIcon"
                                        : ""
                                    }
                                  />
                                </div>
                                <div
                                  className="column "
                                  style={{ maxWidth: "140px" }}
                                >
                                  <button
                                    className="adminbtn adminbtn-blueOutline"
                                    onClick={(e) => {
                                      handleCardOnChange(
                                        e,
                                        recDate.classScheduleId == undefined
                                          ? recDate?.campId
                                          : recDate?.classScheduleId,
                                        recIndex,
                                        "cardOnFile"
                                      );
                                    }}
                                  >
                                    + Save Recurring
                                  </button>
                                </div>

                                {recDate?.cardOnFileDescription ? (
                                  <div
                                    className="column "
                                    style={{ minWidth: "100px !important" }}
                                  >
                                    <p className="label">
                                      {
                                        recDate?.cardOnFileDescription?.split(
                                          " "
                                        )[0]
                                      }
                                      <br />
                                      <span>
                                        {
                                          recDate?.cardOnFileDescription?.split(
                                            " "
                                          )[1]
                                        }
                                      </span>
                                      <br />
                                      <span>
                                        {
                                          recDate?.cardOnFileDescription?.split(
                                            " "
                                          )[2]
                                        }
                                      </span>
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    className="column"
                                    style={{ maxWidth: "230px" }}
                                  >
                                    <div className="adminform__group">
                                      <p
                                        className="label"
                                        style={{ textAlign: "center" }}
                                      >
                                        N/A
                                      </p>
                                    </div>
                                  </div>
                                )}

                                {recDate?.currentRecurring !== undefined &&
                                  recDate?.totalRecurring !== undefined ? (
                                  <div
                                    className="column"
                                    style={{ maxWidth: "230px" }}
                                  >
                                    <p
                                      className="label"
                                      style={{
                                        textAlign: "center",
                                        paddingTop: "15px ",
                                        paddingLeft: "20px",
                                      }}
                                    >
                                      {recDate?.currentRecurring} of{" "}
                                      {recDate?.totalRecurring}
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    className="column "
                                    style={{ maxWidth: "230px" }}
                                  >
                                    <p className="label">0 of 0</p>
                                  </div>
                                )}

                                {/* STATUS */}
                                <div className="column first_column">
                                  <select
                                    name="status"
                                    id={`status-${recIndex}`}
                                    style={{
                                      maxWidth: "120px",
                                      minWidth: "50px",
                                      marginLeft: "20px",
                                    }}
                                    className="status_input adminform__input"
                                    value={
                                      (statuses[recIndex] &&
                                        statuses[recIndex].status) ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      handleStatusChange(
                                        e,
                                        recIndex,
                                        recDate.classScheduleId,
                                        "MONTHLY"
                                      )
                                    }
                                  >
                                    {recDate?.status ? (
                                      <option selected value={recDate?.status}>
                                        {recDate?.status}
                                      </option>
                                    ) : (
                                      <option value="" selected disabled>
                                        {" "}
                                        Select Status
                                      </option>
                                    )}
                                    {recDate?.status !== "Active" && (
                                      <option value="Active">Active</option>
                                    )}
                                    {recDate?.status !== "Pause" && (
                                      <option value="Pause">Pause</option>
                                    )}
                                    {recDate?.status !== "Complete" && (
                                      <option value="Complete">Complete</option>
                                    )}
                                  </select>
                                </div>
                              </div>

                              {/* Recurring Dates for COF & MONTHLY */}
                              {activeAccordion === accordionId && (
                                <>
                                  {recDate?.dateArray.map((dates, index) => {
                                    let isDisabled = true;

                                    if (dates?.status && dates?.prefilled) {
                                      isDisabled = true;
                                    } else {
                                      isDisabled = false;
                                    }
                                    return (
                                      <div
                                        className="flexbox"
                                        key={index}
                                        style={{ marginLeft: "8%" }}
                                      >
                                        <div className="column">
                                          <input
                                            type="checkbox"
                                            checked={dates?.currentlyPaying}
                                            disabled={
                                              dates?.showRepay
                                                ? true
                                                : dates?.prefilled
                                                  ? true
                                                  : false
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                recIndex,
                                                index
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="column">
                                          <div className="adminform__group">
                                            <input
                                              type="date"
                                              disabled={isDisabled}
                                              value={dates.date}
                                              className="adminform__input"
                                              onChange={(e) =>
                                                handleDateChanges(
                                                  e.target.valueAsDate,
                                                  recIndex,
                                                  index
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        {dates?.campType && (
                                          <div className="column">
                                            <div className="adminform__group">
                                              <input
                                                type="text"
                                                value={dates?.campType}
                                                disabled
                                                className="adminform__input"
                                              />
                                            </div>
                                          </div>
                                        )}

                                        <div className="column">
                                          <div className="adminform__group fee_input">
                                            <span className="dollar">$</span>
                                            <input
                                              className="adminform__input "
                                              type="number"
                                              name="fee"
                                              value={dates?.fee}
                                              disabled={isDisabled}
                                              onChange={(e) =>
                                                handleAmountChanges(
                                                  e,
                                                  recIndex,
                                                  index
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        {/* MONTHlY COF mEMO  */}
                                        <div className="column">
                                          <div className="adminform__group fee_input">
                                            <input
                                              disabled={isDisabled}
                                              className="adminform__input"
                                              name="memo"
                                              placeholder="Memo"
                                              type="text"
                                              value={dates?.memo}
                                              onChange={(e) =>
                                                handleMemoChanges(
                                                  e,
                                                  recIndex,
                                                  index
                                                )
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="column">
                                          <p className="label">
                                            {dates?.status}
                                          </p>
                                        </div>

                                        {dates?.showRepay && (
                                          <div className="column">
                                            <button
                                              className="adminbtn adminbtn-blueOutline"
                                              onClick={(e) => {
                                                repayHandler(e, dates);
                                              }}
                                            >
                                              Repay
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                                  {/* SCHEDULES FOR COF -  */}
                                  {scheduleRows?.[recIndex]?.map((val, ind) => {
                                    return (
                                      <div
                                        className="flexbox"
                                        key={recIndex}
                                        style={{ marginLeft: "8%" }}
                                      >
                                        <div className="column">
                                          <input
                                            type="checkbox"
                                            placeholder="Rec"
                                            value={val.currentlyPaying}
                                            name="currentlyPaying"
                                            onChange={(e) =>
                                              handleScheduleInputChange(
                                                recIndex,
                                                ind,
                                                "currentlyPaying",
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="adminform__group">
                                          <input
                                            type="date"
                                            className="adminform__input"
                                            placeholder="date"
                                            value={val.date}
                                            onChange={(e) =>
                                              handleScheduleInputChange(
                                                recIndex,
                                                ind,
                                                "date",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="column">
                                          <div className="adminform__group">
                                            <span
                                              className="dollar"
                                              style={{ left: "2px" }}
                                            >
                                              $
                                            </span>
                                            <input
                                              type="number"
                                              placeholder="Amount"
                                              className="adminform__input"
                                              value={val.fee}
                                              onChange={(e) =>
                                                handleScheduleInputChange(
                                                  recIndex,
                                                  ind,
                                                  "fee",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="column">
                                          <div className="adminform__group fee_input">
                                            <input
                                              className="adminform__input"
                                              name="memo"
                                              placeholder="Memo"
                                              type="text"
                                              value={val?.memo}
                                              // disabled={isDisabled}
                                              onChange={(e) =>
                                                handleScheduleInputChange(
                                                  recIndex,
                                                  ind,
                                                  "memo",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        {/* DELETE ICON FOR ADDING EXTRA SCHEUDLS  */}
                                        <div
                                          className="column"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            handleDeleteRows(recIndex, ind);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          );
                        }
                      )
                    ) : (
                      <label className=" important_field">
                        No Weekly Recurrings Found !
                      </label>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* Footer  for Card on File */}
            <div
              className="Admin-modal__footer"
              style={{ display: "flex", justifyContent: "right" }}
            >
              <div
                className="flexbox"
                style={{ display: "flex", alignItems: "center" }}
              >
                <button
                  className="adminbtn adminbtn  adminbtn-blueOutline"
                  onClick={() => setAddModal(false)}
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        // IF NEW CARD & PAYMENT BY C_CARD
        <div className="column" style={{ marginTop: "-10px" }}>
          <form onSubmit={handleSubmit}>
            <div className="recurring" style={{ padding: "0px 10px" }}>
              <div className="flexbox" style={{}}>
                <div className="column">
                  <label className="label">
                    First Name <span className="important_field">*</span>
                  </label>
                  <div className="adminform__group">
                    <input
                      type="text"
                      class="adminform__input"
                      name="firstName"
                      value={formData?.firstName}
                      onChange={handleChange}
                      placeholder="First Name"
                    />
                    {formErrors.firstName && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors.firstName}
                      </div>
                    )}
                  </div>
                </div>

                <div className="column">
                  <div className="adminform__group">
                    <label className="label">
                      Last Name <span className="important_field">*</span>
                    </label>
                    <input
                      type="text"
                      class="adminform__input"
                      id="lastName"
                      name="lastName"
                      value={formData?.lastName}
                      onChange={handleChange}
                      placeholder="Last Name"
                    />
                    {formErrors.lastName && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors.lastName}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flexbox">
                <div className="column">
                  <label className="label">
                    Email <span className="important_field">*</span>{" "}
                  </label>
                  <div className="adminform__group">
                    <input
                      type="text"
                      class="adminform__input"
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={formData?.email}
                      onChange={handleChange}
                    />
                    {formErrors.email && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors.email}
                      </div>
                    )}
                  </div>
                </div>
                <div className="column">
                  <label className="label">
                    Address <span className="important_field">*</span>
                  </label>
                  <div className="adminform__group">
                    <input
                      type="text"
                      className="adminform__input"
                      name="address"
                      value={formData?.address}
                      onChange={handleChange}
                      placeholder="Address"
                    />
                    {formErrors.address && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors.address}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flexbox">
                <div className="column">
                  <label className="label">
                    City<span className="important_field">*</span>{" "}
                  </label>
                  <div className="adminform__group">
                    <input
                      type="text"
                      className="adminform__input"
                      name="city"
                      value={formData?.city}
                      onChange={handleChange}
                      placeholder="City"
                    />
                    {formErrors.city && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors.city}
                      </div>
                    )}
                  </div>
                </div>

                <div className="column">
                  <label className="label">
                    Zip <span className="important_field">*</span>
                  </label>
                  <div className="adminform__group">
                    <input
                      type="text"
                      className="adminform__input"
                      id="zip"
                      name="zip"
                      placeholder="Zip"
                      value={formData?.zip}
                      onChange={handleChange}
                      pattern="[0-9]*"
                    />
                    {formErrors.zip && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors.zip}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flexbox">
                <div className="column">
                  <label className="label">
                    Select States<span className="important_field">*</span>{" "}
                  </label>
                  <div className="adminform__group">
                    <select
                      name="state"
                      className="adminform__select"
                      value={formData?.state}
                      onChange={handleChange}
                    >
                      <option value="">Select State</option>
                      {getAllStateApi &&
                        getAllStateApi.map((state, index) => (
                          <option key={index} value={state.name}>
                            {state.name}
                          </option>
                        ))}
                    </select>

                    {formErrors.state && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors.state}
                      </div>
                    )}
                  </div>
                </div>
                <div className="column">
                  <label className="label">
                    Country <span className="important_field">*</span>
                  </label>
                  <div className="adminform__group">
                    <input
                      type="text"
                      className="adminform__input"
                      id="country"
                      disabled
                      name="country"
                      placeholder="Country"
                      value={"USA"}
                      onChange={handleChange}
                    />
                    {formErrors.country && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors.country}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <h1 className="title--h5" style={{ marginTop: "20px" }}>
                Credit Card & Billing Info :
              </h1>

              <hr />

              <div className="flexbox">
                <div className="column">
                  <label className="label">
                    Card Number <span className="important_field">*</span>{" "}
                  </label>
                  <div
                    className="adminform__group"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <br />
                    <input
                      type="text"
                      name="creditCard"
                      className="adminform__input"
                      placeholder="Enter card number"
                      onChange={handleChange}
                      value={formData.creditCard}
                      maxLength={19}
                    />

                    {getCardIcon()}

                    {formErrors.creditCard && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors?.creditCard}
                      </div>
                    )}
                  </div>
                </div>

                <div className="column">
                  <label className="label">
                    Expiry Date(MM/YYYY)
                    <span className="important_field">*</span>{" "}
                  </label>

                  <DatePicker
                    className="adminform__input"
                    selected={formData?.expirationDate}
                    onChange={(datee) => {
                      if (datee) {
                        const date = new Date(datee);
                        const month = date.getMonth() + 1;
                        const year = date.getFullYear().toString().slice(-2);

                        const mmYyFormat = `${(month < 10 ? "0" : "") + month
                          }/${year}`;
                        setExpiryDate(mmYyFormat);

                        setFormData({ ...formData, expirationDate: datee });
                      }
                    }}
                    placeholderText="MM/YYYY"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    minDate={new Date()}
                    dropdownMode="select"
                  />

                  {showAlert && (
                    <div className="alert alert-danger mt-2">
                      Expiration date should be after September 2023.
                    </div>
                  )}
                </div>
              </div>

              <div className="column">
                <label className="label">
                  CVV <span className="important_field">*</span>
                </label>
                <div className="adminform__group" style={{ maxWidth: "220px" }}>
                  <input
                    type="text"
                    className="adminform__input"
                    maxLength={"4"}
                    id="cvv"
                    name="cvv"
                    placeholder="CVV"
                    value={formData.cvv}
                    onChange={handleChange}
                    pattern="[0-9]*"
                  />
                  {formErrors.cvv && (
                    <div className="error-message" style={{ color: "red" }}>
                      {formErrors?.cvv}
                    </div>
                  )}
                </div>
              </div>

              {buttonType === "ENABLE" ? (
                <div className="flexbox">
                  {formData?.isRecurring || true ? (
                    <div className="" style={{ minWidth: "680px" }}>
                      <div
                        className="flexbox"
                        style={{ maxWidth: "750px !important" }}
                      >
                        <div className="column">
                          <div className="adminform__group">
                            <label
                              htmlFor=""
                              className="label"
                              style={{
                                fontSize: "1rem",
                              }}
                            >
                              {STATUS === "MONTHLY" ? (
                                <p>Class name </p>
                              ) : (
                                <p>Camp Name</p>
                              )}
                            </label>
                          </div>
                        </div>

                        <div className="column">
                          <div className="adminform_group"></div>
                        </div>
                        <div className="column">
                          <div className="adminform_group"></div>
                        </div>
                        <div className="column">
                          <div className="adminform_group"></div>
                        </div>
                        <div className="column">
                          <div className="adminform_group"></div>
                        </div>

                        <div
                          className="column"
                          style={{
                            minWidth: "170px ",
                            position: "absolute",
                            left: "450px",
                          }}
                        >
                          <div className="adminform__group">
                            <label
                              htmlFor=""
                              className="label"
                              style={{ fontSize: "1rem" }}
                            >
                              <p>Card Used</p>
                            </label>
                          </div>
                        </div>

                        <div
                          className="column"
                          style={{ position: "absolute", left: "650px" }}
                        >
                          <div className="adminform__group">
                            <label
                              htmlFor=""
                              className="label"
                              style={{ fontSize: "1rem", padding: "10px" }}
                            >
                              <p>Recurring </p>
                            </label>
                          </div>
                        </div>

                        <div
                          className="column"
                          style={{ position: "absolute", left: "800px" }}
                        >
                          <div className="adminform__group">
                            <label
                              htmlFor=""
                              className="label"
                              style={{
                                fontSize: "1rem",
                                padding: "10px",
                                width: "20% !important",
                              }}
                            >
                              <p>STATUS </p>
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* REURRING DATAS  */}

                      {STATUS === "MONTHLY" ? (
                        LedgerApiData?.data?.recurringDates?.length > 0 ? (
                          LedgerApiData?.data?.recurringDates?.map(
                            (recDate, recIndex) => {
                              const accordionId = `accordion-${recIndex}`;
                              return (
                                <div className="" key={recIndex}>
                                  <div
                                    className="flexbox"
                                    style={{ gap: "26px" }}
                                  >
                                    <div
                                      className="column first_column"
                                      style={{ minWidth: "200px" }}
                                    >
                                      <div className="adminform__group">
                                        <input
                                          type="text"
                                          className="adminform__input"
                                          value={recDate?.className}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="column  class_name_input"
                                      style={{
                                        cursor: "pointer",
                                        maxWidth: "40px",
                                      }}
                                      onClick={() =>
                                        toggleAccordion(accordionId)
                                      }
                                    >
                                      <AccordionIcon
                                        className={
                                          expandedAccordion === recIndex
                                            ? "rotateIcon"
                                            : ""
                                        }
                                      />
                                    </div>
                                    <div
                                      className="column "
                                      style={{ maxWidth: "200px" }}
                                    >
                                      <button
                                        className="adminbtn adminbtn-blueOutline"
                                        onClick={(e) => {
                                          handleCardOnChange(
                                            e,
                                            recDate.classScheduleId == undefined
                                              ? recDate?.campId
                                              : recDate?.classScheduleId,
                                            recIndex,
                                            "cardOnFile"
                                          );
                                        }}
                                      >
                                        + Save Recurring
                                      </button>
                                    </div>

                                    {recDate?.cardOnFileDescription ? (
                                      <div
                                        className="column "
                                        style={{ minWidth: "100px !important" }}
                                      >
                                        <p className="label">
                                          {
                                            recDate?.cardOnFileDescription?.split(
                                              " "
                                            )[0]
                                          }
                                          <br />
                                          <span>
                                            {
                                              recDate?.cardOnFileDescription?.split(
                                                " "
                                              )[1]
                                            }
                                          </span>
                                          <br />
                                          <span>
                                            {
                                              recDate?.cardOnFileDescription?.split(
                                                " "
                                              )[2]
                                            }
                                          </span>
                                        </p>
                                      </div>
                                    ) : (
                                      <div
                                        className="column"
                                        style={{
                                          minWidth: "250px",
                                          maxWidth: "300px",
                                        }}
                                      >
                                        <div
                                          className="adminform__group"
                                          style={{ textAlign: "left" }}
                                        >
                                          <p
                                            className="label"
                                            style={{ textAlign: "center" }}
                                          >
                                            N/A
                                          </p>
                                        </div>
                                      </div>
                                    )}

                                    {recDate?.currentRecurring !== undefined &&
                                      recDate?.totalRecurring !== undefined ? (
                                      <div
                                        className="column"
                                        style={{ maxWidth: "230px" }}
                                      >
                                        <p
                                          className="label"
                                          style={{
                                            textAlign: "center",
                                            paddingTop: "15px ",
                                            paddingLeft: "20px",
                                          }}
                                        >
                                          {recDate?.currentRecurring} of{" "}
                                          {recDate?.totalRecurring}
                                        </p>
                                      </div>
                                    ) : (
                                      <div
                                        className="column "
                                        style={{ maxWidth: "230px" }}
                                      >
                                        <p className="label">0 of 0</p>
                                      </div>
                                    )}

                                    {/* STATUS */}
                                    <div className="column first_column">
                                      <select
                                        name="status"
                                        id={`status-${recIndex}`}
                                        style={{ maxWidth: "120px" }}
                                        className="status_input adminform__input"
                                        value={
                                          (statuses[recIndex] &&
                                            statuses[recIndex].status) ||
                                          ""
                                        }
                                        onChange={(e) =>
                                          handleStatusChange(
                                            e,
                                            recIndex,
                                            recDate.classScheduleId,
                                            "MONTHLY"
                                          )
                                        }
                                      >
                                        {recDate?.status ? (
                                          <option
                                            selected
                                            value={recDate?.status}
                                          >
                                            {recDate?.status}
                                          </option>
                                        ) : (
                                          <option value="" selected disabled>
                                            {" "}
                                            Select Status
                                          </option>
                                        )}
                                        {recDate?.status !== "Active" && (
                                          <option value="Active">Active</option>
                                        )}
                                        {recDate?.status !== "Pause" && (
                                          <option value="Pause">Pause</option>
                                        )}
                                        {recDate?.status !== "Complete" && (
                                          <option value="Complete">
                                            Complete
                                          </option>
                                        )}
                                      </select>
                                    </div>
                                  </div>

                                  {/* Recurring Dates for COF & MONTHLY */}
                                  {activeAccordion === accordionId && (
                                    <>
                                      {recDate?.dateArray.map(
                                        (dates, index) => {
                                          let isDisabled = true;

                                          if (
                                            dates?.status &&
                                            dates?.prefilled
                                          ) {
                                            isDisabled = true;
                                          } else {
                                            isDisabled = false;
                                          }
                                          return (
                                            <div
                                              className="flexbox"
                                              key={index}
                                              style={{ marginLeft: "2%" }}
                                            >
                                              <div
                                                className="column"
                                                style={{
                                                  maxWidth: "40px",
                                                  marginTop: "30px",
                                                }}
                                              >
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    dates?.currentlyPaying
                                                  }
                                                  disabled={
                                                    dates?.showRepay
                                                      ? true
                                                      : dates?.prefilled
                                                        ? true
                                                        : false
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      e,
                                                      recIndex,
                                                      index
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="column">
                                                <div className="adminform__group fee_input">
                                                  <label
                                                    htmlFor=""
                                                    className="label"
                                                  >
                                                    Memo
                                                  </label>
                                                  <br />
                                                  <input
                                                    style={{ maxWidth: "80px" }}
                                                    disabled={isDisabled}
                                                    className="adminform__input"
                                                    name="memo"
                                                    placeholder="Memo"
                                                    type="text"
                                                    value={dates?.memo}
                                                    onChange={(e) =>
                                                      handleMemoChanges(
                                                        e,
                                                        recIndex,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>

                                              <div className="column">
                                                <div className="adminform__group">
                                                  <label
                                                    htmlFor=""
                                                    className="label"
                                                  >
                                                    Enrollment Start Date
                                                  </label>
                                                  <br />

                                                  <input
                                                    style={{
                                                      maxWidth: "100px",
                                                    }}
                                                    type="date"
                                                    disabled={isDisabled}
                                                    value={
                                                      dates?.enrollmentStartDate
                                                    }
                                                    className="adminform__input"
                                                    name="enrollmentStartDate"
                                                    onChange={(e) =>
                                                      handleEnrollmentStartDateChange(
                                                        e,
                                                        recIndex,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="column">
                                                <div className="adminform__group">
                                                  <label
                                                    htmlFor=""
                                                    className="label"
                                                  >
                                                    Enrollment End Date
                                                  </label>
                                                  <br />

                                                  <input
                                                    style={{
                                                      maxWidth: "100px",
                                                    }}
                                                    type="date"
                                                    disabled={isDisabled}
                                                    value={
                                                      dates?.enrollmentEndDate
                                                    }
                                                    className="adminform__input"
                                                    name="enrollmentEndDate"
                                                    onChange={(e) =>
                                                      handleEnrollmentEndDateChange(
                                                        e,
                                                        recIndex,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="column">
                                                <div className="adminform__group">
                                                  <label
                                                    htmlFor=""
                                                    className="label"
                                                  >
                                                    Recurring Date
                                                  </label>
                                                  <input
                                                    style={{
                                                      maxWidth: "100px",
                                                    }}
                                                    type="date"
                                                    disabled={isDisabled}
                                                    value={dates?.date}
                                                    className="adminform__input"
                                                    onChange={(e) =>
                                                      handleDateChanges(
                                                        e.target.valueAsDate,
                                                        recIndex,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>

                                              {dates?.campType && (
                                                <div className="column">
                                                  <div className="adminform__group">
                                                    <input
                                                      type="text"
                                                      value={dates?.campType}
                                                      disabled
                                                      className="adminform__input"
                                                    />
                                                  </div>
                                                </div>
                                              )}

                                              <div className="column">
                                                <div className="adminform__group fee_input">
                                                  <span
                                                    className="dollar"
                                                    style={{ top: "30px" }}
                                                  >
                                                    $
                                                  </span>
                                                  <label
                                                    htmlFor=""
                                                    className="label"
                                                  >
                                                    Fee($)
                                                  </label>
                                                  <br />
                                                  <input
                                                    style={{ maxWidth: "80px" }}
                                                    className="adminform__input "
                                                    type="number"
                                                    name="fee"
                                                    value={dates?.fee}
                                                    disabled={isDisabled}
                                                    onChange={(e) =>
                                                      handleAmountChanges(
                                                        e,
                                                        recIndex,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              {/* MONTHlY COF mEMO  */}

                                              <div className="column">
                                                <p className="label">
                                                  {dates?.status}
                                                </p>
                                              </div>

                                              {dates?.showRepay && (
                                                <div className="column">
                                                  <button
                                                    className="adminbtn adminbtn-blueOutline"
                                                    onClick={(e) => {
                                                      repayHandler(e, dates);
                                                    }}
                                                  >
                                                    Repay
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                      {/* SCHEUDLE ROWS FOR COF - MONTHLY  */}
                                      {scheduleRows?.[recIndex]?.map(
                                        (val, ind) => {
                                          return (
                                            <div
                                              className="flexbox"
                                              key={recIndex}
                                              style={{ marginLeft: "4%" }}
                                            >
                                              <div
                                                className="column"
                                                style={{
                                                  height: "23px",
                                                  minWidth: "20px",
                                                  maxWidth: "50px",
                                                  paddingTop: "30px",
                                                }}
                                              >
                                                <input
                                                  type="checkbox"
                                                  placeholder="Rec"
                                                  value={val.currentlyPaying}
                                                  name="currentlyPaying"
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "currentlyPaying",
                                                      e.target.checked
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="column">
                                                <div className="adminform__group fee_input">
                                                  <label
                                                    htmlFor=""
                                                    className="label"
                                                  >
                                                    Memo
                                                  </label>
                                                  <input
                                                    className="adminform__input"
                                                    name="memo"
                                                    placeholder="Memo"
                                                    type="text"
                                                    value={val?.memo}
                                                    // disabled={isDisabled}
                                                    onChange={(e) =>
                                                      handleScheduleInputChange(
                                                        recIndex,
                                                        ind,
                                                        "memo",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="column">
                                                <div className="adminform__group fee_input">
                                                  <label
                                                    htmlFor=""
                                                    className="label"
                                                  >
                                                    Enrollment Start Date
                                                  </label>
                                                  <br />
                                                  <input
                                                    className="adminform__input"
                                                    name="enrollmentStartDate"
                                                    placeholder="Enrollment start Date"
                                                    type="date"
                                                    value={
                                                      val?.enrollmentStartDate
                                                    }
                                                    // disabled={isDisabled}
                                                    onChange={(e) =>
                                                      handleScheduleInputChange(
                                                        recIndex,
                                                        ind,
                                                        "enrollmentStartDate",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="column">
                                                <div className="adminform__group fee_input">
                                                  <label
                                                    htmlFor=""
                                                    className="label"
                                                  >
                                                    Enrollment End Date
                                                  </label>
                                                  <br />

                                                  <input
                                                    className="adminform__input"
                                                    name="enrollmentEndDate"
                                                    placeholder="Enrollment End Date"
                                                    type="date"
                                                    value={
                                                      val?.enrollmentEndDate
                                                    }
                                                    onChange={(e) =>
                                                      handleScheduleInputChange(
                                                        recIndex,
                                                        ind,
                                                        "enrollmentEndDate",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="adminform__group">
                                                <label
                                                  htmlFor=""
                                                  className="label"
                                                >
                                                  Recurring Date
                                                </label>

                                                <input
                                                  type="date"
                                                  className="adminform__input"
                                                  placeholder="date"
                                                  style={{ minWidth: "150px" }}
                                                  value={val.date}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "date",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>

                                              <div className="column">
                                                <div className="adminform__group">
                                                  <span
                                                    className="dollar"
                                                    style={{
                                                      left: "2px",
                                                      top: "30px",
                                                    }}
                                                  >
                                                    $
                                                  </span>
                                                  <label
                                                    htmlFor=""
                                                    className="label"
                                                  >
                                                    Fee($)
                                                  </label>

                                                  <input
                                                    style={{
                                                      minWidth: "150px",
                                                    }}
                                                    type="number"
                                                    placeholder="Amount"
                                                    className="adminform__input"
                                                    value={val?.fee}
                                                    onChange={(e) =>
                                                      handleScheduleInputChange(
                                                        recIndex,
                                                        ind,
                                                        "fee",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>

                                              {/* DELETE ICON FOR ADDING EXTRA SCHEUDLS  */}
                                              <div
                                                className="column"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  handleDeleteRows(
                                                    recIndex,
                                                    ind
                                                  );
                                                }}
                                              >
                                                <DeleteIcon />
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                      <div className="column">
                                        <button
                                          className="adminbtn adminbtn-primary"
                                          style={{ marginLeft: "5%" }}
                                          onClick={(e) => {
                                            handleAddSchedules(
                                              e,
                                              recDate,
                                              recIndex
                                            );
                                          }}
                                        >
                                          + Add recurring
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </div>
                              );
                            }
                          )
                        ) : (
                          <label className=" important_field">
                            No Monthly Recurrings Found !
                          </label>
                        )
                      ) : LedgerApiData?.data?.recurringWeeklyDates?.length >
                        0 ? (
                        LedgerApiData?.data?.recurringWeeklyDates?.map(
                          (recDate, recIndex) => {
                            const accordionId = `accordion-${recIndex}`;
                            return (
                              <div className="" key={recIndex}>
                                <div
                                  className="flexbox"
                                  style={{ gap: "26px" }}
                                >
                                  <div
                                    className="column first_column"
                                    style={{ minWidth: "200px" }}
                                  >
                                    <div className="adminform__group">
                                      <input
                                        type="text"
                                        className="adminform__input"
                                        value={recDate?.className}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="column  class_name_input"
                                    style={{
                                      cursor: "pointer",
                                      maxWidth: "40px",
                                    }}
                                    onClick={() => toggleAccordion(accordionId)}
                                  >
                                    <AccordionIcon
                                      className={
                                        expandedAccordion === recIndex
                                          ? "rotateIcon"
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div
                                    className="column "
                                    style={{ maxWidth: "140px" }}
                                  >
                                    <button
                                      className="adminbtn adminbtn-blueOutline"
                                      onClick={(e) => {
                                        handleCardOnChange(
                                          e,
                                          recDate.classScheduleId == undefined
                                            ? recDate?.campId
                                            : recDate?.classScheduleId,
                                          recIndex,
                                          "cardOnFile"
                                        );
                                      }}
                                    >
                                      + Save Recurring
                                    </button>
                                  </div>

                                  {recDate?.cardOnFileDescription ? (
                                    <div
                                      className="column "
                                      style={{
                                        minWidth: "215px !important",
                                        maxWidth: "240px",
                                      }}
                                    >
                                      <p className="label">
                                        {
                                          recDate?.cardOnFileDescription?.split(
                                            " "
                                          )[0]
                                        }
                                        <br />
                                        <span>
                                          {
                                            recDate?.cardOnFileDescription?.split(
                                              " "
                                            )[1]
                                          }
                                        </span>
                                        <br />
                                        <span>
                                          {
                                            recDate?.cardOnFileDescription?.split(
                                              " "
                                            )[2]
                                          }
                                        </span>
                                      </p>
                                    </div>
                                  ) : (
                                    <div
                                      className="column"
                                      style={{ maxWidth: "230px" }}
                                    >
                                      <div className="adminform__group">
                                        <p
                                          className="label"
                                          style={{ textAlign: "center" }}
                                        >
                                          N/A
                                        </p>
                                      </div>
                                    </div>
                                  )}

                                  {recDate?.currentRecurring !== undefined &&
                                    recDate?.totalRecurring !== undefined ? (
                                    <div
                                      className="column"
                                      style={{ maxWidth: "230px" }}
                                    >
                                      <p
                                        className="label"
                                        style={{
                                          textAlign: "center",
                                          paddingTop: "15px ",
                                          paddingLeft: "20px",
                                        }}
                                      >
                                        {recDate?.currentRecurring} of{" "}
                                        {recDate?.totalRecurring}
                                      </p>
                                    </div>
                                  ) : (
                                    <div
                                      className="column "
                                      style={{ maxWidth: "230px" }}
                                    >
                                      <p className="label">0 of 0</p>
                                    </div>
                                  )}

                                  {/* STATUS */}
                                  <div className="column first_column">
                                    <select
                                      name="status"
                                      id={`status-${recIndex}`}
                                      style={{
                                        maxWidth: "120px",
                                        minWidth: "50px",
                                        marginLeft: "20px",
                                      }}
                                      className="status_input adminform__input"
                                      value={
                                        (statuses[recIndex] &&
                                          statuses[recIndex].status) ||
                                        ""
                                      }
                                      onChange={(e) =>
                                        handleStatusChange(
                                          e,
                                          recIndex,
                                          recDate.classScheduleId,
                                          "MONTHLY"
                                        )
                                      }
                                    >
                                      {recDate?.status ? (
                                        <option
                                          selected
                                          value={recDate?.status}
                                        >
                                          {recDate?.status}
                                        </option>
                                      ) : (
                                        <option value="" selected disabled>
                                          {" "}
                                          Select Status
                                        </option>
                                      )}
                                      {recDate?.status !== "Active" && (
                                        <option value="Active">Active</option>
                                      )}
                                      {recDate?.status !== "Pause" && (
                                        <option value="Pause">Pause</option>
                                      )}
                                      {recDate?.status !== "Complete" && (
                                        <option value="Complete">
                                          Complete
                                        </option>
                                      )}
                                    </select>
                                  </div>
                                </div>

                                {/* Recurring Dates for COF & MONTHLY */}
                                {activeAccordion === accordionId && (
                                  <>
                                    {recDate?.dateArray.map((dates, index) => {
                                      let isDisabled = true;

                                      if (dates?.status && dates?.prefilled) {
                                        isDisabled = true;
                                      } else {
                                        isDisabled = false;
                                      }
                                      return (
                                        <div
                                          className="flexbox"
                                          key={index}
                                          style={{ marginLeft: "8%" }}
                                        >
                                          <div className="column">
                                            <input
                                              type="checkbox"
                                              checked={dates?.currentlyPaying}
                                              disabled={
                                                dates?.showRepay
                                                  ? true
                                                  : dates?.prefilled
                                                    ? true
                                                    : false
                                              }
                                              onChange={(e) =>
                                                handleInputChange(
                                                  e,
                                                  recIndex,
                                                  index
                                                )
                                              }
                                            />
                                          </div>

                                          <div className="column">
                                            <div className="adminform__group">
                                              <input
                                                type="date"
                                                disabled={isDisabled}
                                                value={dates.date}
                                                className="adminform__input"
                                                onChange={(e) =>
                                                  handleDateChanges(
                                                    e.target.valueAsDate,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {dates?.campType && (
                                            <div className="column">
                                              <div className="adminform__group">
                                                <input
                                                  type="text"
                                                  value={dates?.campType}
                                                  disabled
                                                  className="adminform__input"
                                                />
                                              </div>
                                            </div>
                                          )}

                                          <div className="column">
                                            <div className="adminform__group fee_input">
                                              <span className="dollar">$</span>
                                              <input
                                                className="adminform__input "
                                                type="number"
                                                name="fee"
                                                value={dates?.fee}
                                                disabled={isDisabled}
                                                onChange={(e) =>
                                                  handleAmountChanges(
                                                    e,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {/* MONTHlY COF mEMO  */}
                                          <div className="column">
                                            <div className="adminform__group fee_input">
                                              <input
                                                disabled={isDisabled}
                                                className="adminform__input"
                                                name="memo"
                                                placeholder="Memo"
                                                type="text"
                                                value={dates?.memo}
                                                onChange={(e) =>
                                                  handleMemoChanges(
                                                    e,
                                                    recIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>

                                          <div className="column">
                                            <p className="label">
                                              {dates?.status}
                                            </p>
                                          </div>

                                          {dates?.showRepay && (
                                            <div className="column">
                                              <button
                                                className="adminbtn adminbtn-blueOutline"
                                                onClick={(e) => {
                                                  repayHandler(e, dates);
                                                }}
                                              >
                                                Repay
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })}
                                    {/* SCHEDULES FOR COF -  */}
                                    {scheduleRows?.[recIndex]?.map(
                                      (val, ind) => {
                                        return (
                                          <div
                                            className="flexbox"
                                            key={recIndex}
                                            style={{ marginLeft: "8%" }}
                                          >
                                            <div className="column">
                                              <input
                                                type="checkbox"
                                                placeholder="Rec"
                                                value={val.currentlyPaying}
                                                name="currentlyPaying"
                                                onChange={(e) =>
                                                  handleScheduleInputChange(
                                                    recIndex,
                                                    ind,
                                                    "currentlyPaying",
                                                    e.target.checked
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="adminform__group">
                                              <input
                                                type="date"
                                                className="adminform__input"
                                                placeholder="date"
                                                value={val.date}
                                                onChange={(e) =>
                                                  handleScheduleInputChange(
                                                    recIndex,
                                                    ind,
                                                    "date",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>

                                            <div className="column">
                                              <div className="adminform__group">
                                                <span
                                                  className="dollar"
                                                  style={{ left: "2px" }}
                                                >
                                                  $
                                                </span>
                                                <input
                                                  type="number"
                                                  placeholder="Amount"
                                                  className="adminform__input"
                                                  value={val.fee}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "fee",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="column">
                                              <div className="adminform__group fee_input">
                                                <input
                                                  className="adminform__input"
                                                  name="memo"
                                                  placeholder="Memo"
                                                  type="text"
                                                  value={val?.memo}
                                                  // disabled={isDisabled}
                                                  onChange={(e) =>
                                                    handleScheduleInputChange(
                                                      recIndex,
                                                      ind,
                                                      "memo",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            {/* DELETE ICON FOR ADDING EXTRA SCHEUDLS  */}
                                            <div
                                              className="column"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                handleDeleteRows(recIndex, ind);
                                              }}
                                            >
                                              <DeleteIcon />
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </div>
                            );
                          }
                        )
                      ) : (
                        <label className=" important_field">
                          No Weekly Recurrings Found !
                        </label>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : null}
            </div>
            {/* Footer  */}
            <div
              className="Admin-modal__footer"
              style={{ display: "flex", justifyContent: "right" }}
            >
              <div
                className="flexbox"
                style={{ display: "flex", alignItems: "center" }}
              >
                <button
                  className="adminbtn adminbtn  adminbtn-blueOutline"
                  onClick={() => setAddModal(false)}
                  type="button"
                >
                  Cancel
                </button>
                {buttonType !== "ENABLE" && (
                  <button type="submit" className="adminbtn adminbtn-primary">
                    Add Payment
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default PaymentModal;
