import { createSlice } from "@reduxjs/toolkit";

const studentDataSlice = createSlice({
  name: "studentData",
  initialState: [],
  reducers: {
    addStudent: (state, action) => {
      state.push(action.payload);
    },
    deleteStudentReducer: (state, action) => {
      return state.filter((student) => student.id !== action.payload);
    },
    addBirthdayData: (state, action) => {
      state.push(action.payload);
    },

    clearStudentData: (state) => {
      return [];
    },
    deleteParty: (state, action) => {
      const index = action.payload;
      return state.filter((classItem) => classItem.id !== index);
    },
    removeBirthDayFromCart: (state) => {
      return state = []
  },

  },
});

export const {
  addStudent,
  deleteStudentReducer,
  addBirthdayData,
  clearStudentData,
  deleteParty,
  removeBirthDayFromCart
} = studentDataSlice.actions;
export default studentDataSlice.reducer;
