import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import arrowIcon from "../assets/images/icons/Back To.png";
import { getMasterMenu } from "../adminApi/mastermenu";
import { ReactComponent as GymTimeLogo } from "../../Parent/parent-assets/images/gymtime_logo_2024.svg";

function NavigationComponent() {
  const { navIndex } = useParams();
  const { menuName } = useParams();
  const [setupDropdownState, setSetupDropdownState] = useState(false);
  const [masterMenu, setMasterMenu] = useState([]);
  const [activeState, setActiveState] = useState(true);
  const navigate = useNavigate();
  const [contentDropdownState, setContentDropdownState] = useState(false);
  const [contentManagement, setContentManagement] = useState(true);
  const [cmsDropdown, setCmsDropDown] = useState(false);
  // const role = useSelector((state) => state?.userRole?.RoleId);
  useEffect(() => {
    // Update the cmsDropdown state based on menuName
    if (
      menuName === "Schedule" ||
      menuName === "Home" ||
      menuName === "About" ||
      menuName === "Birthday & Events" ||
      menuName === "Cart" ||
      menuName === "Blog" ||
      menuName === "Programs"
    ) {
      setCmsDropDown(true);
    } else {
      setCmsDropDown(false);
    }
  }, [menuName]);

  const toggleSetupDropdown = () => {
    setSetupDropdownState(!setupDropdownState);
  };

  const toggleContentDropdown = () => {
    setContentDropdownState(!contentDropdownState);
  };

  const token = localStorage.getItem("access_token");
  useEffect(() => {
    getMasterMenuData();
  }, []);

  const getMasterMenuData = async () => {
    const response = await getMasterMenu(token);
    setMasterMenu(response);
  };
  const masterMenuHandler = (menuId) => {};

  return (
    <>
      <div className="navigation">
        <div
          className="navigation__logo"
          onClick={() => {
            navigate("/");
            // localStorage.removeItem("access_token");
          }}
        >
          {/* <img src={logo} alt="GymTime" /> */}
          <GymTimeLogo />
        </div>
        <ul className="navigation__list">
          {[
            "Setup",
            "Clients",
            "Ledger",
            "Attendance",
            "Orders",
            "Notifications",
            "Reports",
            "Content Management",
          ].map((item, index) => (
            <li
              className={
                navIndex == index
                  ? "navigation__dropdown--active-index"
                  : "navigation__list--item navigation__dropdown--active"
              }
              key={index}
            >
              <div
                className={`navigation__link ${
                  (item === "Setup" && setupDropdownState) ||
                  activeState === item
                    ? "navigation__link--active"
                    : ""
                }`}
                onClick={() => {
                  if (item === "Reports") {
                    localStorage.removeItem("Ledger_Client");
                    localStorage.removeItem("Searched_CLIENT_ID");

                    localStorage.removeItem("StudentIdForLedger");

                    setActiveState(true);
                    setContentManagement(false);
                    navigate(`/admin/reports/${index}`);
                  }
                  if (item === "Ledger") {
                    setContentManagement(false);
                    navigate(`/admin/ledger/${index}`);
                  }
                  if (item === "Clients") {
                    localStorage.removeItem("Searched_CLIENT_ID");

                    localStorage.removeItem("StudentIdForLedger");
                    setContentManagement(false);
                    navigate(`/admin/clients/${index}`);
                  }
                  if (item === "Attendance") {
                    localStorage.removeItem("StudentIdForLedger");
                    localStorage.removeItem("Ledger_Client");
                    localStorage.removeItem("Searched_CLIENT_ID");

                    setContentManagement(false);
                    navigate(`/admin/attendence/${index}`);
                  }
                  if (item === "Setup") {
                    localStorage.removeItem("StudentIdForLedger");
                    localStorage.removeItem("Ledger_Client");
                    localStorage.removeItem("Searched_CLIENT_ID");

                    setContentManagement(false);
                    toggleSetupDropdown();
                  } else if (item === "Orders") {
                    localStorage.removeItem("StudentIdForLedger");
                    setContentManagement(false);
                    navigate(`/admin/orders/${index}`);
                  }
                  if (item === "Notifications") {
                    localStorage.removeItem("Searched_CLIENT_ID");
                    localStorage.removeItem("StudentIdForLedger");
                    localStorage.removeItem("Ledger_Client");

                    navigate(`/admin/notification/${index}`);
                  }
                  if (item === "Content Management") {
                    localStorage.removeItem("Searched_CLIENT_ID");
                    localStorage.removeItem("Ledger_Client");
                    localStorage.removeItem("StudentIdForLedger");
                    // getMasterMenuData();
                    setContentManagement(true);
                    setCmsDropDown(!cmsDropdown);
                    toggleContentDropdown();
                  }
                }}
              >
                {item}
                {item === "Setup" && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.70615 8.71067C5.31565 9.10117 5.31565 9.73437 5.70615 10.1249L10.5984 15.0123C11.3796 15.7927 12.6454 15.7924 13.4262 15.0116L18.3165 10.1213C18.707 9.73077 18.707 9.09757 18.3165 8.70707C17.926 8.31647 17.2928 8.31647 16.9023 8.70707L12.7167 12.8927C12.3261 13.2832 11.693 13.2832 11.3025 12.8927L7.12035 8.71067C6.72985 8.32017 6.09665 8.32017 5.70615 8.71067Z"
                      fill="#00ACE5"
                    />
                  </svg>
                )}
                {item === "Content Management" && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.70615 8.71067C5.31565 9.10117 5.31565 9.73437 5.70615 10.1249L10.5984 15.0123C11.3796 15.7927 12.6454 15.7924 13.4262 15.0116L18.3165 10.1213C18.707 9.73077 18.707 9.09757 18.3165 8.70707C17.926 8.31647 17.2928 8.31647 16.9023 8.70707L12.7167 12.8927C12.3261 13.2832 11.693 13.2832 11.3025 12.8927L7.12035 8.71067C6.72985 8.32017 6.09665 8.32017 5.70615 8.71067Z"
                      fill="#00ACE5"
                    />
                  </svg>
                )}
              </div>

              {item === "Setup" && setupDropdownState && (
                <div className="navigation__dropdown navigation__dropdown--active">
                  <ul>
                    <li>
                      <Link to="/admin/category">Categories</Link>
                    </li>
                    <li>
                      <Link to="/admin/teachers">Teachers</Link>
                    </li>
                    <li>
                      <Link to="/admin/users">Users</Link>
                    </li>
                    <li>
                      <Link to="/admin/classes">Classes</Link>
                    </li>
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>

        {contentManagement && cmsDropdown && (
          <div className="navigation__dropdown  navigation__dropdown--active">
            <ul>
              {masterMenu?.map((item) => (
                <li>
                  <Link
                    onClick={() => masterMenuHandler(item.id)}
                    to={`/admin/page/${item.menu_name}/${item?.id}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {item?.menu_name}
                    <span>
                      <img src={arrowIcon} alt="" />
                    </span>{" "}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default NavigationComponent;
