const GET_TEACHERS = `
query GetTeachers($code: String, $name: String, $email: String, $address: String, $city: String, $zipcode: String, $cell: String, $isActive: Boolean, $pageInfo: PaginationInfo) {
  getTeachers(code: $code, name: $name, email: $email, address: $address, city: $city, zipcode: $zipcode, cell: $cell, is_active: $isActive, pageInfo: $pageInfo) {
    data {
      id
      code
      name
      email
      address
      city
      zipcode
      is_active
      home_phone
      altphone
      status
    }
    totalCount
    totalPages
    limit
    currPage
  }
}
`;
export { GET_TEACHERS };
