import React, { useEffect, useState } from "react";
import { createCategory, updateCategory } from "../../adminApi/categories";
import { toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close-icon.svg";
import LoaderComponent from "../LoaderComponent";
import { setAdminModalContentReducer } from "../../../reducers/admin-slice/adminModalsSlice";
import { useDispatch } from "react-redux";
const AddNewUser = ({
  openAddNewUser,
  setAddNewUser,
  type,
  data,
  fetchData,
  setSortKey,
  setIsEdited,
}) => {
  const token = localStorage.getItem("access_token");
  const [id, setId] = useState(data?.id);
  const [editData, setEditData] = useState({
    name: data?.name,
    fk_school_category: data?.school_name,
  });
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    fk_school_category: "",
  });
  const dispatch = useDispatch();

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (type === "Add") {
      setFormData({ ...formData, [name]: value });
    } else {
      setEditData({ ...editData, [name]: value });
    }
  };

  const handleAddCategory = (e) => {
    e.preventDefault();

    const updateResponse = async () => {
      const requiredFields = [formData.name, formData.fk_school_category];
      let allFieldsEmpty = true;
      requiredFields.forEach((field) => {
        if (field && field.trim() !== "") {
          allFieldsEmpty = false;
        }
      });

      if (allFieldsEmpty) {
        toast.error("Please Enter All the Fields!");
        return;
      }

      if (!formData.name) {
        toast.error("Category name is required !");
        return;
      } else if (!/^[a-zA-Z\s]+$/.test(formData.name)) {
      } else if (!/^[a-zA-Z\s]+$/.test(formData.name)) {
        toast.error(
          "Category name should contain only letters and spaces, no numbers or special characters!"
        );
        return;
      }

      if (!formData.fk_school_category) {
        toast.error("Please Select  the Category!");
        return;
      }

      setLoading(true);
      try {
        const updatedData = await createCategory(formData, token);
        setLoading(false);

        if (!updatedData.error) {
          // Handle successful response
          setSortKey({
            sortId: null,
            isAsc: true,
          });
          
          toast.success(updatedData);
          fetchData();
          setAddNewUser(false);
        } else {
          toast.error(updatedData.error);
        }
      } catch (error) {
        setLoading(false);
      }
    };

    const editResponse = async () => {
      const requiredFields = [editData.name, editData.fk_school_category];
      let allFieldsEmpty = true;
      requiredFields.forEach((field) => {
        if (field && field.trim() !== "") {
          allFieldsEmpty = false;
        }
      });

      if (allFieldsEmpty) {
        toast.error("Please Enter All the Fields!");
        return;
      }

      if (allFieldsEmpty) {
        toast.error("Please Enter All the Fields!");
        return;
      }

      if (!editData.name) {
        toast.error("Category name is required !");
        return;
      } else if (!/^[a-zA-Z\s]+$/.test(editData.name)) {
      } else if (!/^[a-zA-Z\s]+$/.test(editData.name)) {
        toast.error(
          "Category name should contain only letters and spaces, no numbers or special characters!"
        );
        return;
      }

      if (!editData.fk_school_category) {
        toast.error("Please Select  the Category!");
        return;
      }

      setLoading(true);
      try {
        const temp = {
          name: editData.name,
          fk_school_category:
            editData?.fk_school_category === "Gymtime" ||
            editData?.fk_school_category === "1"
              ? "1"
              : editData?.fk_school_category === "Eastside Day School" ||
                editData?.fk_school_category === "2"
              ? "2"
              : editData?.fk_school_category === "York Avenue Preschool" ||
                editData?.fk_school_category === "3"
              ? "3"
              : "",
        };
        const updatedData = await updateCategory(temp, token, id);
        if (updatedData) {
          setLoading(true);
          setLoading(false);
          toast.success(updatedData.data);
          setTimeout(() => {
            
            fetchData();
          }, 2000);
          setAddNewUser(false);
        }
        if (updatedData.response.data.error) {
          toast.error(updatedData.response.data.error);
        }
      } catch (error) {
        if (error) {
          setLoading(false);

          toast.error(error);
          return;
        }
      }
    };
    if (type === "Add") {
      updateResponse();
    } else {
      editResponse();
    }
  };

  return (
    <>
      {loading && <LoaderComponent />}
      {openAddNewUser ? (
        <>
          <div
            className="Admin-modal Admin-modal--open"
            id="modalCategory"
            role="dialog"
          >
            <div className="Admin-modal__dialog Admin-modal__dialog--sm">
              <div className="Admin-modal__content">
                <div className="Admin-modal__head">
                  <h2 className="Admin-modal__head--title">
                    {type === "Edit" ? "Edit Category " : "+ Add New Category"}
                  </h2>
                  <button
                    className="Admin-modal__close"
                    onClick={() => {
                      setAddNewUser(false);
                    }}
                  >
                    <CloseIcon />
                  </button>
                </div>

                <div className="Admin-modal__body">
                  <div className="form__group">
                    <label class="label" style={{ color: "#4F4F4F" }}>
                      Category Name <span className="important_field">*</span>
                    </label>

                    <input
                      type="text"
                      className="adminform__input"
                      placeholder="Enter Name"
                      value={data ? editData.name : formData.name}
                      name="name"
                      onChange={handleInput}
                    />
                  </div>
                  <div className="adminform__group">
                    <label class="label" style={{ color: "#4F4F4F" }}>
                      Select School<span className="important_field">*</span>
                    </label>

                    <select
                      name="fk_school_category"
                      id=""
                      className="adminform__select"
                      onChange={handleInput}
                    >
                      <option value="" selected disabled>
                        Select School
                      </option>
                      <option
                        selected={editData.fk_school_category === "Gymtime"}
                        value="1"
                      >
                        Gymtime
                      </option>
                    </select>
                  </div>
                </div>

                <div className="Admin-modal__footer">
                  <div
                    className="admin-button__group"
                    style={{ display: "flex", gap: "20px" }}
                  >
                    <button
                      className="adminbtn adminbtn-blueOutline"
                      onClick={() => {
                        setAddNewUser(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="adminbtn adminbtn-primary"
                      onClick={handleAddCategory}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AddNewUser;
