import React, { useEffect, useState } from 'react'
import NavigationComponent from '../../components/NavigationComponent'
import Header from '../../components/Header'
import "./cmsStaff.css"
import { toast } from 'react-toastify'
import axios from 'axios'
import AddNewTeacherModal from './AddNewTeacherModal'
const Staff = () => {
    const token = localStorage.getItem("access_token");
    const [dummyArr, setDummyArr] = useState();
    const[updatedItems,setUpdatedItems]=useState();
    const [showAddTeacher, setShowAddTeacher] = useState(false);
    useEffect(() => {
        getTeachersData();
    }, []);

    const getTeachersData = async () => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_BASE_URL + `/admin/get_staff_cms`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setDummyArr(response?.data?.data);
        } catch (error) {
            toast.error("Error while getting Teachers data.");
             
        }
    }
    const teacherDeleteHandler = async (id) => {
        try {
            const data = {
                id: parseInt(id)
            }
            const response = await axios.put(
                process.env.REACT_APP_BASE_URL + `/admin/delete_staff_cms`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
            )
            if(response?.status===201 || response?.status===200){
                getTeachersData();
                toast.success("teacher deleted successfully.");

            }
        } catch (error) {
            toast.error("Error while delete teacher");
             
        }
    }

    const handleTeacherInfo=(id,field,value)=>{
        const updatedItem = { ...dummyArr[id], [field]: value };
        const updatedArr=[...dummyArr];
        updatedArr[id]=updatedItem;
        setDummyArr(updatedArr);
        setUpdatedItems(updatedItem);
    }
    const teacherModifyHanlder=async(id)=>{
     try {
        // const data={
        //     updatedItems
        // }
        const response=await axios.put(
            process.env.REACT_APP_BASE_URL +`/admin/update_staff_cms`,updatedItems,{
                headers:{
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${token}`
                }
            }
        )
     } catch (error) {
         
        toast.error("Eroor while updating teacher.");
     }
    }
    return (
        <>
            <div className="container--component">
                <div className="container--grid">
                    <NavigationComponent />
                    <div className="Adminpage">
                        <Header title="Staff" subTitle="(Manage Staff)" />
                        <div className="add_new_teacher_btn">
                            <button
                                className='adminbtn adminbtn-primary'
                                onClick={() => {
                                    if(dummyArr?.length===12){
                                        toast.error("Teachers list cannot be greater than 12");
                                        return;
                                    }
                                    setShowAddTeacher(true)
                                }}
                            >Add Teacher
                            </button>
                        </div>
                        <div className="staff_name_container">
                            {dummyArr?.map((item,index) => (<div 
                            className="staff_info_container_box"
                            key={item?.id}
                            >
                                <input type="text"
                                    placeholder='Enter staff name.'
                                    className='adminform__input'
                                    value={item?.staff_name}
                                    onChange={(e)=>handleTeacherInfo(index,"staff_name",e.target.value)}
                                />
                                <input type="email"
                                    placeholder='Enter staff email.'
                                    className='adminform__input'
                                    value={item?.staff_email}
                                    onChange={(e)=>handleTeacherInfo(index,"staff_email",e.target.value)}
                                />
                                <input type="text"
                                    placeholder='Enter staff designation.'
                                    className='adminform__input'
                                    value={item?.staff_role}
                                    onChange={(e)=>handleTeacherInfo(index,"staff_role",e.target.value)}
                                />
                                <div className="button_container_cms">
                                    <button className='adminbtn adminbtn-primary'
                                    onClick={()=>teacherModifyHanlder(item?.id)}
                                    >Save
                                    </button>
                                    <button className='adminbtn adminbtn-danger'
                                    style={{backgroundColor:"#e98787"}}
                                        onClick={() => teacherDeleteHandler(item?.id)}
                                    >Delete</button>
                                </div>

                            </div>))}
                        </div>
                    </div>
                </div>
            </div>
            {
                showAddTeacher && <AddNewTeacherModal
                    setShowAddTeacher={setShowAddTeacher}
                    getTeachersData={getTeachersData}
                />
            }
        </>
    )
}

export default Staff