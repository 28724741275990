import React, { useEffect, useState } from "react";
import getPrograms from "../../adminApi/categories";
import { getcategory, getclass } from "../../adminApi/clients";
import {
  addCharges,
  deleteAdhocDetail,
  deletePrefilledAdhoc,
  getAdhocDetails,
} from "../../adminApi/Ledger";
import { toast } from "react-toastify";
import LoaderComponent from "../../components/LoaderComponent";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/deleteRowIcon.svg";
import Select from "react-select";
import { customStyles, dateFormatter } from "../../adminUtility";
import { ReactComponent as CakeIcon } from "../../assets/images/icons/cake.svg";
import BirthdayAddOns from "./BirthdayAddOns";
// import CustomDeleteModal from "../CustomDeleteModal";
import DeleteModal from "../UserModals/DeleteModal";
import store from "../../../store/store";
import { openSessionModal } from "../../../reducers/sessionHandler";
// Formatted Date
function getCurrentDate() {
  const currentDate = new Date();

  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const year = currentDate.getFullYear();

  return `${month}/${day}/${year}`;
}

const AddModal = ({ openAddButton, setOpenAddButton, ledgerID, fetchData }) => {
  const [formData, setFormData] = useState({});
  const [isBirthDay, setIsBirthday] = useState(false);
  const [getAllCategoryData, setCategoryData] = useState();
  const [loading, setLoading] = useState(false);
  const [categoryID, setCategoryID] = useState();
  const [allClasses, setAllClasses] = useState([]);
  const [sortKey, setSortKey] = useState({
    sortId: "1",
    isAsc: true,
  });
  const [adhocDetails, setAdhocDetails] = useState();
  const token = localStorage.getItem("access_token");
  const [totolAmount, setTotalAmount] = useState([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  // const [addRows, setAddRows] = useState([0]);
  const [addRows, setAddRows] = useState([
    {
      category: "",
      date: getCurrentDate(),
      memo: "",
      amount: "",
      discount: "",
      totalAmount: "",
    },
  ]);
  // Prefilled ADHOC HANLDLER

  // Handling Add or Delete Rows
  const handleAddRows = (index) => {
    const newRows = {
      category: "",
      memo: "",
      amount: "",
      date: getCurrentDate(),
      discount: 0,
      totalAmount: "",
    };
    let arr = [];
    for (let i = 0; i < addRows.length + 1; i++) {
      arr.push([]);
    }
    setAllClasses(arr);
    setAddRows([...addRows, newRows]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = addRows.filter((_, i) => i !== index);
    setAddRows(updatedRows);
  };

  const getAdhoCDetails = async () => {
    setLoading(true);
    try {
      const response = await getAdhocDetails(token, ledgerID);
      if (response?.data) {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } else {
        setLoading(false);
      }
      setAdhocDetails(response?.data?.data);
    } catch (error) {
      setLoading(false);
    }
  };
  // GET ALL CATEGORIES
  useEffect(() => {
    const getAllCategories = async () => {
      const token = localStorage.getItem("access_token");
      const response = await getPrograms(token, formData, 0, sortKey, "300000");
      if (
        response.data.errors &&
        response.data.errors[0]?.message === "Unauthorised."
      ) {
        setTimeout(() => {
          store?.dispatch(openSessionModal(true));
        }, 1000);
      }
      setCategoryData(response?.data);
    };
    // Addhoc Delete Api When Button Clicked

    getAdhoCDetails();
    getAllCategories();
  }, [ledgerID]);

  const handleSubmit = async () => {
    const emptyFields = addRows.map((row) => {
      const fields = Object.entries(row)
        .filter(([key, value]) => value === "" || value === undefined)
        .map(([key]) => key);

      return fields.filter(
        (field) => field !== "totalAmount" && field !== "discount"
      );
    });

    const flattenedEmptyFields = emptyFields.flat();

    if (flattenedEmptyFields.length > 0) {
      const nonDiscountEmptyFields = flattenedEmptyFields.filter(
        (field) => field !== "discount"
      );
      if (nonDiscountEmptyFields.length > 0) {
        if (nonDiscountEmptyFields.length === 1) {
          toast.error(
            `Enter the following field: ${nonDiscountEmptyFields[0]}`
          );
          return;
        } else {
          toast.error(
            `Enter the following fields: ${nonDiscountEmptyFields.join(", ")}`
          );
          return;
        }
      }
    }
    const rowsWithTotalAmount = addRows.map((row, index) => {
      return {
        ...row,
        totalAmount: totolAmount[index],
      };
    });

    setLoading(true);
    try {
      const token = localStorage.getItem("access_token");
      const response = await addCharges(token, ledgerID, rowsWithTotalAmount);
      if (response?.data) {
        setLoading(false);
        setOpenAddButton(false);
        toast.success("Adhoc Charges Added Successfully.");
        fetchData();
      } else {
        toast.error(response?.error);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    }
  };

  const classDataFromCategory = async (id, index) => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await getclass(id, token);
      const updatedClasses = [...allClasses];
      updatedClasses[index] = response;
      setAllClasses(updatedClasses);
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };

  const handleChargeDetails = (e, index) => {
    const { name, value } = e.target;
    const copydata = [...addRows];
    const requiredFields = ["class", "category"];

    // Check if the value is not empty and trim only if it's a string
    if (
      requiredFields.includes(name) &&
      typeof value === "string" &&
      value.trim() === ""
    ) {
      toast.error(`${name} cannot be empty`);
      return;
    }

    if (name === "category") {
      const selectedCategoryID = parseInt(value);
      copydata[index][name] = selectedCategoryID;
      setCategoryID(selectedCategoryID);
      if (selectedCategoryID !== "") {
        classDataFromCategory(selectedCategoryID, index);
      }
    }

    if (name === "category") {
      const selectedCategoryID = parseInt(value);
      copydata[index][name] = selectedCategoryID;
      setCategoryID(selectedCategoryID);
      if (selectedCategoryID !== "") {
        classDataFromCategory(selectedCategoryID, index);
      }
    } else if (
      name === "amount" ||
      name === "discount" ||
      name === "totalAmount"
    ) {
      copydata[index][name] = value === "" ? "" : parseFloat(value);
    } else {
      copydata[index][name] = value;
    }
    setAddRows(copydata);
  };

  // CHARGET DETAILS
  useEffect(() => {
    const updatedTotalAmounts = addRows.map((row) => {
      const total = (row.amount || 0) - (row.discount || 0);
      return total.toFixed(2);
    });
    setTotalAmount(updatedTotalAmounts);

    // addRows?.map((val, index) => {
    //   if (val?.category) {
    //     classDataFromCategory(val?.category, index);
    //     fetchCategory();
    //   }
    // });
  }, [addRows]);

  const handleSave = async (index) => {
    const row = adhocDetails[index];
    setLoading(true);
    try {
      const response = await deletePrefilledAdhoc(token, row);
      setTimeout(() => {
        setLoading(false);
      }, [1000]);
      toast.success(response?.data);
      getAdhoCDetails();
      setOpenAddButton(false);
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  // Handle input Chnage for Prefilled AdhoC Details.
  const handleInputChange = (index, field, value) => {
    const updatedAdhocDetails = [...adhocDetails];
    updatedAdhocDetails[index] = {
      ...updatedAdhocDetails[index],
      [field]: value,
    };

    if (field === "charge" || field === "discount") {
      const charge = parseFloat(updatedAdhocDetails[index].charge) || 0;
      const discount = parseFloat(updatedAdhocDetails[index].discount) || 0;

      if (
        updatedAdhocDetails[index].charge !== "" &&
        updatedAdhocDetails[index].discount !== ""
      ) {
        updatedAdhocDetails[index].total = (charge - discount).toFixed(2);
      } else {
        updatedAdhocDetails[index].total = "";
      }
    }

    setAdhocDetails(updatedAdhocDetails);
  };

  return (
    <>
      {loading && <LoaderComponent />}

      {openAddButton ? (
        <>
          <div
            className="Admin-modal Admin-modal--open"
            id="modalCategory"
            role="dialog"
          >
            <div className="Admin-modal__dialog Admin-modal__dialog--lg">
              <div
                className="Admin-modal__content"
                style={{ width: "90vw", height: "auto" }}
              >
                <div className="Admin-modal__head">
                  <h2 className="Admin-modal__head--title">+ Add Charges</h2>
                  <button
                    className="Admin-modal__close"
                    onClick={() => {
                      setOpenAddButton(false);
                      fetchData();
                    }}
                  >
                    <CloseIcon />
                  </button>
                </div>

                {/* TAB HANDLER  */}
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  {/* <div className="column">
                    <button
                      class={`adminbtn ${
                        isBirthDay
                          ? "adminbtn-blueOutline "
                          : "adminbtn-primary"
                      }  `}
                      onClick={() => {
                        setIsBirthday(false);
                      }}
                    >
                      +Additional Charges($)
                    </button>
                  </div> */}
                  {/* <div className="column">
                    <button
                      class={`adminbtn ${
                        isBirthDay
                          ? "adminbtn-primary "
                          : "adminbtn-blueOutline"
                      }  `}
                      onClick={() => {
                        setIsBirthday(true);
                      }}
                    >
                      <CakeIcon style={{ width: "20px", height: "20px" }} />+
                      Birthday Add Ons
                    </button>
                  </div> */}
                </div>

                <div class="Admin-modal__body">
                  {!isBirthDay ? (
                    <>
                      <div className="Table">
                        <div className="Heading">
                          <div
                            className="Cell"
                            style={{ color: "white", maxWidth: "50px" }}
                          >
                            <p>Charge Date</p>
                          </div>
                          <div
                            className="Cell"
                            style={{ color: "white", maxWidth: "100px" }}
                          >
                            <p>Category</p>
                          </div>
                          <div
                            className="Cell"
                            style={{ color: "white", maxWidth: "80px" }}
                          >
                            <p>Memo</p>
                          </div>
                          <div
                            className="Cell"
                            style={{ color: "white", maxWidth: "200px" }}
                          >
                            {" "}
                            <p> Charge($)</p>
                          </div>
                          <div className="Cell" style={{ color: "white" }}>
                            {" "}
                            <p>Discount($) </p>
                          </div>
                          <div className="Cell" style={{ color: "white" }}>
                            <p>Total($)</p>
                          </div>
                          <div className="Cell" style={{ width: "40px" }}>
                            <p></p>
                          </div>
                        </div>

                        {addRows.map((row, index) => (
                          <div className="Row" key={index}>
                            <div
                              className="Cell Cell_input"
                              style={{ width: "60px" }}
                            >
                              <input
                                type="text"
                                className="adminform__input"
                                placeholder="Enter Date"
                                value={row.date || ""}
                              />
                            </div>
                            <div
                              className="Cell Cell_input"
                              style={{ width: "200px" }}
                            >
                              <Select
                                name="category"
                                className=""
                                styles={customStyles}
                                onChange={(selectedOption) => {
                                  handleChargeDetails(
                                    {
                                      target: {
                                        name: "category",
                                        value: selectedOption.value,
                                      },
                                    },
                                    index
                                  );
                                }}
                                options={[
                                  { label: "Select Category", value: null },
                                  ...(Array.isArray(getAllCategoryData) &&
                                  getAllCategoryData.length > 0
                                    ? getAllCategoryData
                                        .filter(
                                          (val) => val?.name.trim() !== ""
                                        )
                                        .map((category) => ({
                                          label: category.name,
                                          value: category.id,
                                        }))
                                        .filter((option) => option.value !== "")
                                    : []),
                                ]}
                                // options={getAllCategoryData?.map((val) => ({
                                //   value: val.id,
                                //   label: val.name,
                                // }))}
                              />
                            </div>
                            <div className="Cell " style={{ width: "250px" }}>
                              <input
                                type="text"
                                name="memo"
                                className="adminform__input"
                                placeholder="Memos Goes here"
                                value={row.memo || ""}
                                onChange={(e) => {
                                  handleChargeDetails(e, index);
                                }}
                                style={{ width: "100%", minWidth: "25px" }}
                              />
                            </div>
                            <div className="Cell Cell_input">
                              <input
                                type="number"
                                name="amount"
                                className="adminform__input"
                                placeholder="$ 00.00"
                                value={row?.amount || ""}
                                onChange={(e) => {
                                  handleChargeDetails(e, index);
                                }}
                                style={{ width: "100%", minWidth: "25px" }}
                              />
                            </div>
                            <div className="Cell Cell_input">
                              <input
                                type="number"
                                name="discount"
                                className="adminform__input"
                                placeholder="Discount($)"
                                style={{ width: "100%", minWidth: "25px" }}
                                value={row.discount || ""}
                                onChange={(e) => {
                                  handleChargeDetails(e, index);
                                }}
                              />
                            </div>
                            <div className="Cell Cell_input">
                              <input
                                type="number"
                                disabled
                                value={totolAmount[index]}
                                className="adminform__input"
                                style={{ width: "100%", minWidth: "5px" }}
                              />
                            </div>
                            <div
                              className="Cell "
                              onClick={() => handleDeleteRow(index)}
                              style={{
                                cursor: "pointer",
                                padding: "15px",
                                verticalAlign: "middle",
                                width: "20px",
                              }}
                            >
                              <DeleteIcon />
                            </div>
                          </div>
                        ))}
                      </div>

                      <button
                        className="adminbtn adminbtn-blueOutline"
                        onClick={handleAddRows}
                        style={{ margin: "10px 0" }}
                      >
                        + Add More
                      </button>

                      <div
                        className="flexbox"
                        style={{ justifyContent: "end" }}
                      >
                        <button
                          className="adminbtn adminbtn-blueOutline"
                          onClick={() => {
                            setOpenAddButton(false);
                            fetchData();
                          }}
                          type="button"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="adminbtn adminbtn-primary"
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </>
                  ) : (
                    <BirthdayAddOns
                      openAddButton={openAddButton}
                      setOpenAddButton={setOpenAddButton}
                      ledgerID={ledgerID}
                      fetchData={fetchData}
                    />
                  )}

                  {/* PREFILLED ADHOC DETAILS  */}

                  {adhocDetails?.length > 0 && (
                    <div className="Table" style={{ marginTop: "20px" }}>
                      <label htmlFor="">Added Adhoc's</label>

                      <div className="Heading">
                        <div
                          className="Cell"
                          style={{ color: "white", maxWidth: "50px" }}
                        >
                          <p>Charge Date</p>
                        </div>
                        <div
                          className="Cell"
                          style={{ color: "white", maxWidth: "100px" }}
                        >
                          <p>Category</p>
                        </div>
                        <div
                          className="Cell"
                          style={{ color: "white", maxWidth: "80px" }}
                        >
                          <p>Memo</p>
                        </div>
                        <div
                          className="Cell"
                          style={{ color: "white", maxWidth: "200px" }}
                        >
                          {" "}
                          <p> Charge($)</p>
                        </div>
                        <div className="Cell" style={{ color: "white" }}>
                          {" "}
                          <p>Discount($) </p>
                        </div>
                        <div className="Cell" style={{ color: "white" }}>
                          <p>Total($)</p>
                        </div>
                        <div className="Cell" style={{ width: "40px" }}>
                          <p></p>
                        </div>
                      </div>
                      {adhocDetails?.map((row, index) => {
                        const selectedCategory = getAllCategoryData?.find(
                          (category) => category?.id == row?.categoryId
                        );

                        return (
                          <div className="Row" key={index}>
                            <div
                              className="Cell Cell_input"
                              style={{ width: "60px" }}
                            >
                              <input
                                type="text"
                                className="adminform__input"
                                placeholder="Enter Date"
                                value={dateFormatter(row?.chargeDate) || ""}
                              />
                            </div>
                            <div
                              className="Cell Cell_input"
                              style={{ width: "200px" }}
                            >
                              <Select
                                name="category"
                                className=""
                                styles={customStyles}
                                options={[
                                  { label: "Select Category", value: null },
                                  ...(Array.isArray(getAllCategoryData) &&
                                  getAllCategoryData.length > 0
                                    ? getAllCategoryData
                                        .filter(
                                          (val) => val?.name.trim() !== ""
                                        )
                                        .map((category) => ({
                                          label: category.name,
                                          value: category.id,
                                        }))
                                        .filter((option) => option.value !== "")
                                    : []),
                                ]}
                                value={
                                  selectedCategory
                                    ? {
                                        label: selectedCategory.name,
                                        value: selectedCategory.id,
                                      }
                                    : { label: "Select Category", value: null }
                                }
                                onChange={(option) =>
                                  handleInputChange(
                                    index,
                                    "categoryId",
                                    option.value
                                  )
                                }
                              />
                            </div>
                            <div className="Cell " style={{ width: "250px" }}>
                              <input
                                type="text"
                                name="memo"
                                className="adminform__input"
                                placeholder="Memos Goes here"
                                value={row?.memo || ""}
                                style={{ width: "100%", minWidth: "25px" }}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "memo",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="Cell Cell_input">
                              <input
                                type="number"
                                name="amount"
                                className="adminform__input"
                                placeholder="$ 00.00"
                                value={row?.charge || ""}
                                style={{ width: "100%", minWidth: "25px" }}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "charge",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="Cell Cell_input">
                              <input
                                type="number"
                                name="discount"
                                className="adminform__input"
                                placeholder="Discount($)"
                                style={{ width: "100%", minWidth: "25px" }}
                                value={row.discount || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "discount",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="Cell Cell_input">
                              <input
                                type="number"
                                disabled
                                value={row?.total}
                                className="adminform__input"
                                style={{ width: "100%", minWidth: "5px" }}
                              />
                            </div>
                            <div className="Cell ">
                              <div
                                className="flexbox"
                                style={{
                                  padding: "0px 5px 4px 8px",
                                  alignItems: "center",
                                }}
                              >
                                <div className="column">
                                  <button
                                    className=" adminbtn adminbtn-blueOutline"
                                    onClick={() => handleSave(index)}
                                  >
                                    Save
                                  </button>
                                </div>
                                <div className="column">
                                  <button>
                                    <DeleteIcon
                                      onClick={() => {
                                        setDeleteId(row?.id);
                                        setDeleteModalOpen(true);
                                      }}
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>

                <div
                  className="Admin-modal__footer"
                  style={{ display: "flex", justifyContent: "right" }}
                ></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          openDeleteModal={isDeleteModalOpen}
          setDeleteModal={setDeleteModalOpen}
          type={"ADHOC"}
          deleteCardID={deleteId}
          fetchData={getAdhoCDetails}
        />
      )}
    </>
  );
};

export default AddModal;
