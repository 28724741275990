import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { replaceClassCart } from "../../../reducers/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import aboutUsBanner from "../../parent-assets/images/about-us-banner.jpg";
import trashIconRed from "../../parent-assets/images/trash-icon-red.svg";
import { toast } from "react-toastify";
import CheckoutModal from "../../parent-components/CheckoutModal";
import PaymentInfoModal from "../../parent-components/PaymentInfoModal";
import {
  deleteDataFromTheCart,
  getAllDataForCart,
  getProRatedAmount,
} from "../../api/classApi";
import { getSectionCMS } from "../../../Admin/adminApi/mastermenu";
import programImg1 from "../../parent-assets/images/program-img01.png";
import { AgeCalculationFunction } from "../../utility/postRequests";
import { dateFormatter } from "../../../Admin/adminUtility";


const DobCalculationComponent  = (dob)=>{
  const dateStr =  dob.dateStr;
  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(5, 7);
  const day = dateStr.substring(8, 10);

  // Construct the new date format
  const formattedDate = `${month}/${day}/${year}`;
 const calculatedAge =  AgeCalculationFunction(formattedDate);
  return calculatedAge;
}

function Cart() {
  const token = localStorage.getItem("access_token");
  const [sectionData, setSectionData] = useState([]);
  const [showCartImage, setShowCartImage] = useState(false);
  const data = useSelector((state) => state.studentSlice);
  const dispatch = useDispatch();
  const [totalValue, setTotalValue] = useState(0);
  const [checkoutModal, setCheckoutModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [birthdayTotal, setBirthdayTotal] = useState(null);
  const [studentID, setStudentID] = useState();
  const navigate = useNavigate();
  const [cartListApiData, setCartListApiData] = useState();
  const [quantity, setQuantity] = useState(null);
  const [orderData, setorderData] = useState();
  const cartData = useSelector((state) => state.cart);

  // Parse the stored string int o a JavaScript object
  // Access the first_name property
  const calculateTotalAmount = () => {
    const packageTypeAmount = parseInt(data[0]?.packageType?.amount, 10) || 0;

    const additionChargesAmount =
      data[0]?.additionCharges?.reduce(
        (sum, charge) => sum + parseInt(charge?.amount, 10),
        0
      ) || 0;

    return packageTypeAmount + additionChargesAmount;
  };

  useEffect(() => {
    setStudentID(cartData?.selectedStudents[0]?.id);
  }, [cartData]);

  useEffect(() => {
    getCartImages();
  }, []);

  useEffect(() => {
    if (token) {
      const sessionTimeOut = setTimeout(() => {
        localStorage.removeItem("access_token");
        navigate("/");
      }, 86400000);
      return () => clearTimeout(sessionTimeOut);
    }
  }, [navigate]);

  const getCartImages = async () => {
    setShowCartImage(false);
    const section = await getSectionCMS(token, 175);
    setSectionData(section);
    setShowCartImage(true);
  };

  const getProRatedAmountHandler = async (classSchedules, studentId) => {
    const token = localStorage.getItem("access_token");
    try {
      const data = await getProRatedAmount(classSchedules, studentId, token);
      const updatedSelectedClasses = cartData?.selectedClasses?.map(
        (singleClass) => {
          const matchingData = data.find(
            (item) =>
              item.fk_class_schedule_classes_enrolled ===
              singleClass.class_schedules[0]?.classScheduleId
          );
          if (matchingData) {
            return {
              ...singleClass,
              proRatedAmount: matchingData.proRatedAmount,
            };
          }
        }
      );
      dispatch(replaceClassCart(updatedSelectedClasses));
    } catch (error) {
      toast.error(error.response?.data?.error);
    }
  };

  useEffect(() => {
    const classSchedules = cartData?.selectedClasses?.map(
      (singleClass) => singleClass?.class_schedules[0]?.classScheduleId
    );
    const studentId =
      cartData?.selectedStudents?.length &&
      cartData?.selectedStudents?.map((single) => single?.id)[0];

    getProRatedAmountHandler(classSchedules, studentId);
  }, []);

  const getCartApiDataFunction = async () => {
    const response = await getAllDataForCart(token);
    setCartListApiData(response);
  };

  useEffect(() => {
    getCartApiDataFunction();
  }, []);

  // delete Cart Data Card
  const handleDeleteCart = async (id) => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await deleteDataFromTheCart(token, id);
      toast.success(response?.data);
      getCartApiDataFunction();
    } catch (error) {}
  };

  let subTotalAmount = 0;

  // Total Amount of Birthday

  useEffect(() => {
    if (
      cartListApiData?.classDetails?.length > 0
    ) {
      setQuantity(cartListApiData?.classDetails?.length);
      setStudentID(cartListApiData?.classDetails[0]?.studentId);
      const classTotal = cartListApiData.classDetails.reduce(
        (acc, val) => acc + val?.proRatedAmount,
        0
      );
      setTotalValue((prevTotal) => prevTotal + classTotal);
    }

    return () => {
      setTotalValue(0);
    };
  }, [cartListApiData]);

  return (
    <>
      <div className="contentwraper">
        <section style={{ position: "relative" }}>
          <section className="siteBanner" style={{ background: "#00BBE3" }}>
            <div className="siteBanner__overlay">
              <img
                className="siteBanner__img"
                src={
                  sectionData?.length > 0
                    ? sectionData[0]?.imageSection?.image
                    : aboutUsBanner
                }
                alt="about banner img"
              />
            </div>
          </section>
        </section>
        <section className="breadcrumbSec">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb__item">
                <Link className="breadcrumb__link" onClick={() => navigate(-1)}>
                  Back to results
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="commonSec">
          <div className="container">
            {cartListApiData?.classDetails?.length 
           ? (
              <div className="cartWrap">
                <div className="cartWrap__header">
                  <div className="item">1. My Bag</div>
                  <div className="item item--outline"></div>
                  <div className="item">2. Review & Payment </div>
                </div>

                <div className="cartWrap__body">
                  <div className="cartWrap__body__flex">
                    <div className="col">
                      <div className="orderPrograms">
                        <div className="orderPrograms__list">
                          {/* Class CARDS  */}
                          {cartListApiData?.classDetails?.map((val) => {
                            subTotalAmount =
                              subTotalAmount + val?.proRatedAmount || 0;
                            
                            return (
                              <div className="orderPrograms__item">
                                <div className="orderPrograms__card">
                                  <div className="pic">
                                    <img
                                      src={
                                        val?.classImage
                                          ? val?.classImage
                                          : programImg1
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="detail">
                                    <div className="detail__row">
                                      <div className="detail__row__col">
                                        <p className="para">
                                          {val?.className
                                            ? val?.className
                                            : "Mini Movers"}
                                        </p>
                                      </div>
                                      <div className="detail__row__col">
                                        <button
                                          type="button"
                                          className="btn btn--trashparent"
                                          onClick={() => {
                                            handleDeleteCart(val?.cartId);
                                          }}
                                        >
                                          <img
                                            src={trashIconRed}
                                            alt="Delete"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="detail__row">
                                      <div className="detail__row__col">
                                        <p className="para">
                                          Student Name: &nbsp;
                                          {val?.studentName
                                            ? val?.studentName
                                            : "Invalid Student Name"}
                                        </p>
                                        <p className="para">
                                          Student Age:{" "}
                                          {val?.age ? (AgeCalculationFunction(val?.dob)): null}
                                        </p>
                                      </div>
                                      <div className="detail__row__col">
                                        <p className="para">
                                          $
                                          {val?.proRatedAmount
                                            ? val?.proRatedAmount.toFixed(2)
                                            : null}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          {/* BIRTHDAT DETAILS  */}
                        </div>

                        <div className="orderPrograms__info">
                          <div className="orderPrograms__info__row">
                            <div className="orderPrograms__info__text">
                              Subtotal
                            </div>

                            <div className="orderPrograms__info__value">
                              $ {totalValue}
                            </div>
                          </div>
                          <div className="orderPrograms__info__action">
                            <button
                              className="btn btn--primary btn--primary--outline"
                              onClick={() => navigate(-1)}
                            >
                              Back to programs
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* RIGHT CODE SUMMARY  */}

                    <div className="col">
                      <div className="orderSummry">
                        <h3 className="title title--h3">Order Summary</h3>
                        <div className="orderSummry__detail">
                          <div className="orderSummry__row">
                            <div className="text">Subtotal</div>
                            <div className="value">$ {totalValue}</div>
                          </div>
                        </div>
                        <div className="orderSummry__footer">
                          <div className="orderSummry__row">
                            <div className="text">Total</div>
                            <div className="value">${totalValue}</div>
                          </div>
                          <div className="orderSummry__row">
                            <button
                              className="btn btn--primary"
                              type="button"
                              onClick={() => {
                                setPaymentModal(true);
                              }}
                            >
                              Checkout
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <h3 className="title title--h3" style={{ textAlign: "center" }}>
                You have not added anything !
              </h3>
            )}
          </div>
        </section>
      </div>

      {checkoutModal ? (
        <CheckoutModal
          setCheckoutModal={setCheckoutModal}
          orderData={orderData}
        />
      ) : null}

      {paymentModal ? (
        <PaymentInfoModal
          setPaymentModal={setPaymentModal}
          setCheckoutModal={setCheckoutModal}
          studentID={studentID}
          birthdayDetails={data[0]}
          amount={calculateTotalAmount()}
          cartListData={cartListApiData}
          subTotalAmount={subTotalAmount}
          totalValue={totalValue}
          quantity={quantity}
          birthdayTotal={birthdayTotal}
          cartListApiData={cartListApiData}
          setorderData={setorderData}
        />
      ) : null}
    </>
  );
}

export default Cart;
