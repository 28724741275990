import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { updateTeacher } from "../../adminApi/teacher";
import { getStates } from "../../adminApi/users";
import {
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
  isValidUSZipCode,
} from "../../../Parent/utility/validation";
import LoaderComponent from "../LoaderComponent";
import { setAdminModalContentReducer } from "../../../reducers/admin-slice/adminModalsSlice";
import { useDispatch } from "react-redux";

const AddTeacher = ({
  openAddNewTeacher,
  setAddNewTeacher,
  title,
  data,
  type,
  setSortKey,
  fetchData,
}) => {
  const [teacherData, setTeacherData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [getStAteData, setGetStateData] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    zipcode: "",
    home_phone: "",
    altphone: "",
    state: "",
    is_active: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    zipcode: "",
    home_phone: "",
    altphone: "",
    state: "",
    is_active: "",
  });
  const [id, setId] = useState(data?.id);

  const handleInput = (e) => {
    if (title === "Add") {
      const { name, value } = e.target;

      if (name === "email" && !isValidEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Enter Valid email",
        }));
        setFormData({ ...formData, [name]: value });
        return;
      }

      if (name === "name" && !isValidName(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          name: "Name should only contain letters.",
        }));
        setFormData({ ...formData, [name]: value });
        return;
      }

      if (name === "zipcode" && !isValidUSZipCode(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          zipcode: "Invalid Zip code (should contain only 5 digits).",
        }));
        setFormData({ ...formData, [name]: value });
        return;
      }

      // if (name === "altphone" && !isValidPhoneNumber(value)) {
      //   setErrors((prevErrors) => ({
      //     ...prevErrors,
      //     altphone: "Invalid Alt Phone number (should contain only 10 digits).",
      //   }));
      //   setFormData({ ...formData, [name]: value });
      //   return;
      // }

      // if (name === "home_phone" && !isValidPhoneNumber(value)) {
      //   setErrors((prevErrors) => ({
      //     ...prevErrors,
      //     home_phone: "Invalid  Phone number (should contain only 10 digits).",
      //   }));
      //   setFormData({ ...formData, [name]: value });
      //   return;
      // }

      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      setFormData({ ...formData, [name]: value });
    } else if (title === "Edit") {
      const { name, value } = e.target;
      setTeacherData({ ...teacherData, [name]: value });
    }
  };

  const validateFields = () => {
    const {
      name,
      email,
      address,
      city,
      zipcode,
      home_phone,
      altphone,
      state,
      is_active,
    } = formData;

    if (
      !name &&
      !email &&
      !address &&
      !city &&
      !zipcode &&
      !home_phone &&
      !altphone &&
      !state &&
      !is_active
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required",
        email: "Email is required",
        address: "Address is required",
        city: "City is required",
        zipcode: "Zipcode is required",
        home_phone: "Home phone number is required",
        altphone: "Alt phone number is required",
        state: "State is required",
        is_active: "Status is required",
      }));

      return false;
    }

    if (!name) {
      setErrors((prevErrors) => ({ ...prevErrors, name: "Name is required" }));
    }

    if (!email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
      }));
    }

    if (!address) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: "Address is required",
      }));
    }

    if (!city) {
      setErrors((prevErrors) => ({ ...prevErrors, city: "City is required" }));
    }

    if (!zipcode) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        zipcode: "Zipcode is required",
      }));
    }

    if (!home_phone) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        home_phone: "Home phone number is required",
      }));
    }

    if (!altphone) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        altphone: "Alt phone number is required",
      }));
    }

    if (!state) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        state: "State is required",
      }));
    }

    if (!is_active) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        is_active: "Status is required",
      }));
    }

    return true;
  };

  const handleAddTeacher = async () => {
    const isValid = validateFields();

    if (!isValid) {
      toast.error("Please Enter All Fields!.", {
        toastId: "error",
      });
      return;
    }

    const hasErrors = Object.keys(errors)
      .filter((key) => key !== "name")
      .some((key) => errors[key] !== "");

    if (hasErrors) {
      toast.error("Please Enter all the Fields!.");
      return;
    }

    const token = localStorage.getItem("access_token");
    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/teacher/create",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        setSortKey({
          sortId: null,
          isAsc: true,
        });
        setLoading(false);

        toast.success("Teacher added successfully");
        setAddNewTeacher(false);
        fetchData();
      }
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
      }, 500);

      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    }
  };

  const handleEditTeacher = async () => {
    try {
      if (teacherData?.name?.length < 1) {
        toast.error("Name is a required field");
        return;
      }
      if (teacherData?.email?.length < 1) {
        toast.error("Email is a required field");
        return;
      }
      if (teacherData?.address?.length < 1) {
        toast.error("Address is a required field !");
        return;
      }
      if (teacherData?.city?.length < 1) {
        toast.error("City is a required field !");
        return;
      }
      if (
        teacherData?.zipcode?.length < 1 ||
        teacherData?.zipcode?.length !== 5
      ) {
        toast.error("Zipcode is a required field and must be of 5 digits !");
        return;
      }

      if (teacherData?.altphone < 1) {
        toast.error("Alt Phone is a required field !");
        return;
      }
      if (teacherData?.is_active?.length < 1) {
        toast.error("Status is a required field !");
        return;
      }
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regex.test(teacherData?.email)) {
        toast.error("Invalid email !");
        return;
      }

      if (
        teacherData?.home_phone?.length > 14 &&
        teacherData?.altphone?.length > 14
      ) {
        toast.error("Phone number must contain only 14 digits.");
        return;
      }
      const token = localStorage.getItem("access_token");
      const response = await updateTeacher(teacherData, token, id);
      if (response) {
        toast.success("Teacher Updated Successfully");
        setAddNewTeacher(false);
        fetchData();
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    const getState = async () => {
      const response = await getStates();
      setGetStateData(response?.data?.getStates);
    };
    getState();
  }, []);

  return (
    <>
      {loading && <LoaderComponent />}
      {openAddNewTeacher ? (
        <>
          <div
            class="Admin-modal Admin-modal--open"
            id="modalTeacher"
            role="dialog"
          >
            <div class="Admin-modal__dialog Admin-modal__dialog--md">
              <div class="Admin-modal__content">
                <div class="Admin-modal__head">
                  <h2 class="Admin-modal__head--title">
                    {" "}
                    {type === "Edit" ? "Edit Teacher" : "+ Add New Teacher"}
                  </h2>
                  <button
                    class="Admin-modal__close"
                    onClick={() => {
                      setAddNewTeacher(false);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_1_9631)">
                        <path
                          d="M14.0728 6.92854L14.0728 6.92856C14.4017 7.26332 14.4017 7.79324 14.0728 8.128L14.0713 8.12951L14.0713 8.1295L11.6968 10.5057L14.0354 12.8405C14.3655 13.1697 14.3655 13.7084 14.0354 14.0376C13.706 14.3667 13.1717 14.3663 12.8421 14.0376L12.8418 14.0373L10.5035 11.6969L8.14748 14.0551L8.14649 14.0561C7.81513 14.3841 7.27944 14.3841 6.94808 14.0561L6.94561 14.0536L6.94562 14.0536C6.61805 13.7202 6.61475 13.1828 6.94753 12.8523C6.94764 12.8521 6.94775 12.852 6.94785 12.8519L9.30329 10.4943L6.9652 8.15951C6.63514 7.8303 6.63511 7.2917 6.9651 6.96245C6.96513 6.96242 6.96516 6.96238 6.9652 6.96235L7.14175 7.13935C7.37272 6.90779 7.74915 6.90779 7.98131 7.13935L14.0728 6.92854ZM14.0728 6.92854L14.0703 6.92609C13.739 6.5981 13.2039 6.5981 12.8725 6.92609L12.8716 6.92703L10.4965 9.30317L14.0728 6.92854ZM10.5 18.5625C6.04722 18.5625 2.4375 14.9516 2.4375 10.5C2.4375 6.04836 6.04722 2.4375 10.5 2.4375C14.9528 2.4375 18.5625 6.04836 18.5625 10.5C18.5625 14.9516 14.9528 18.5625 10.5 18.5625ZM10.5 0.75C5.11499 0.75 0.75 5.11315 0.75 10.5C0.75 15.8869 5.11499 20.25 10.5 20.25C15.885 20.25 20.25 15.8869 20.25 10.5C20.25 5.11315 15.885 0.75 10.5 0.75Z"
                          fill="white"
                          stroke="white"
                          strokeWidth="0.5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_9631">
                          <rect width="21" height="21" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>

                <div className="Admin-modal__body">
                  <div className="flexbox" style={{ margin: "0.2rem 0" }}>
                    <div className="column">
                      <div className="adminform__group">
                        <label class="label" style={{ color: "#4F4F4F" }}>
                          Full Name
                        </label>

                        <input
                          type="text"
                          className="adminform__input"
                          placeholder="Full Name"
                          name="name"
                          value={
                            title === "Add" ? formData.name : teacherData.name
                          }
                          onChange={handleInput}
                        />
                        {errors.name && (
                          <p style={{ color: "red", fontSize: "10px" }}>
                            {errors.name}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="column">
                      <div className="adminform__group">
                        <label class="label" style={{ color: "#4F4F4F" }}>
                          Email
                        </label>

                        <input
                          type="email"
                          className="adminform__input"
                          placeholder="Email Address"
                          name="email"
                          value={
                            title === "Add"
                              ? formData.email
                              : teacherData?.email
                          }
                          onChange={handleInput}
                        />

                        {errors.email && (
                          <p style={{ color: "red", fontSize: "10px" }}>
                            {errors.email}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flexbox">
                    <div className="adminform__group" style={{ width: "100%" }}>
                      <label class="label" style={{ color: "#4F4F4F" }}>
                        Address
                      </label>

                      <input
                        type="text"
                        className="adminform__input"
                        placeholder="Address"
                        name="address"
                        value={
                          title === "Add"
                            ? formData.address
                            : teacherData?.address
                        }
                        onChange={handleInput}
                      />
                      {errors.address && (
                        <p style={{ color: "red", fontSize: "10px" }}>
                          {errors.address}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flexbox">
                    <div className="column">
                      <div className="adminform__group">
                        <label class="label" style={{ color: "#4F4F4F" }}>
                          City
                        </label>

                        <input
                          type="text"
                          className="adminform__input"
                          placeholder="City"
                          name="city"
                          value={
                            title === "Add" ? formData.city : teacherData?.city
                          }
                          onChange={handleInput}
                        />
                        {errors.city && (
                          <p style={{ color: "red", fontSize: "10px" }}>
                            {errors.city}
                          </p>
                        )}
                      </div>
                    </div>

                    <div class="column">
                      <div class="adminform__group">
                        <label class="label" style={{ color: "#4F4F4F" }}>
                          Select States
                        </label>

                        <select
                          name="state"
                          id=""
                          class="adminform__select"
                          onChange={handleInput}
                          value={
                            title === "Add"
                              ? formData.state
                              : teacherData?.state
                          }
                        >
                          <option disabled value="">
                            Select State
                          </option>

                          {getStAteData?.map((val, index) => {
                            <option selected disabled>
                              Select State
                            </option>;
                            return (
                              <option key={index} value={val.name}>
                                {val?.name}
                              </option>
                            );
                          })}
                        </select>
                        {errors.state && (
                          <p style={{ color: "red", fontSize: "10px" }}>
                            {errors.state}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class="flexbox">
                    <div class="column">
                      <div class="adminform__group">
                        <label class="label" style={{ color: "#4F4F4F" }}>
                          Select Zip
                        </label>

                        <input
                          type="text"
                          className="adminform__input"
                          placeholder="Zip"
                          name="zipcode"
                          maxLength={5}
                          onChange={handleInput}
                          value={
                            title === "Add"
                              ? formData.zipcode
                              : teacherData?.zipcode
                          }
                          pattern="\d{5}"
                          title="Please enter exactly 5 digits"
                        />

                        {errors.zipcode && (
                          <p style={{ color: "red", fontSize: "10px" }}>
                            {errors.zipcode}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="column">
                      <div className="form__group">
                        <label class="label" style={{ color: "#4F4F4F" }}>
                          Status
                        </label>

                        <select
                          name="is_active"
                          id=""
                          class="adminform__select"
                          onChange={handleInput}
                          value={
                            title === "Add"
                              ? formData.is_active
                              : teacherData?.is_active
                            // === "0"
                            // ? "In-Active"
                            // : "Active"
                          }
                          // onFocus={() => handleFocus("is_active")}
                        >
                          <option value="" selected disabled>
                            Active/Inactive
                          </option>
                          <option value="Active">Active</option>
                          <option value="In-Active">In-Active</option>
                        </select>
                        {errors.is_active && (
                          <p style={{ color: "red", fontSize: "10px" }}>
                            {errors.is_active}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flexbox">
                    <div className="column">
                      <div className="adminform__group">
                        <label class="label" style={{ color: "#4F4F4F" }}>
                          Home Phone
                        </label>

                        <input
                          type="text"
                          className="adminform__input"
                          placeholder="Home Phone"
                          maxLength={14}
                          name="home_phone"
                          value={
                            title === "Add"
                              ? formData?.home_phone
                              : teacherData?.home_phone === null
                              ? "123456789"
                              : teacherData?.home_phone
                          }
                          pattern="\d*"
                          onChange={handleInput}
                        />

                        {/* {errors.home_phone && (
                          <p style={{ color: "red", fontSize: "10px" }}>
                            {errors.home_phone}
                          </p>
                        )} */}
                      </div>
                    </div>

                    <div className="column">
                      <div className="adminform__group">
                        <label class="label" style={{ color: "#4F4F4F" }}>
                          Alternate Phone
                        </label>

                        <input
                          type="number"
                          className="adminform__input"
                          placeholder="Alt Phone"
                          name="altphone"
                          maxLength={10}
                          value={
                            title === "Add"
                              ? formData.altphone
                              : teacherData.altphone === null
                              ? "12345"
                              : teacherData.altphone
                          }
                          onChange={handleInput}
                        />

                        {/* {errors.altphone && (
                          <p style={{ color: "red", fontSize: "10px" }}>
                            {errors.altphone}
                          </p>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Admin-modal__footer">
                  <div
                    className="admin-button__group"
                    style={{ display: "flex", gap: "20px" }}
                  >
                    <button
                      className="adminbtn adminbtn-blueOutline"
                      onClick={() => {
                        setAddNewTeacher(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="adminbtn adminbtn-primary"
                      onClick={
                        title === "Add" ? handleAddTeacher : handleEditTeacher
                      }
                    >
                      {" "}
                      {title === "Add" ? "Save" : "Edit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AddTeacher;
