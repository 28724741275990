import lousengerImg from "../../../parent-assets/images/ana-lousenger-img.png";
import trashIcon from "../../../parent-assets/images/trash-icon.svg";
import aboutUsBanner from "../../../parent-assets/images/about-us-banner.jpg";
import programDetailImg1 from "../../../parent-assets/images/program-detail-img01.png";
// import { addStudent } from "../../../../reducers/studentDataSlice";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useParams } from "react-router-dom";

function PepPlusDetailPage() {
  // CLASS AVAILABILTY
  const { id } = useParams();

  const classAvailabilityList = () => {
    return (
      <div>
        <h3 className="title title--extrabold">Class Availability</h3>
        <div className="classAvailability__list">
          <div className="classAvailability__list__card">
            <div className="item item--flexbox">
              <div className="content">
                <h3 className="title title--extrabold">
                  {id == 45 ? "Recreational Cheer" : "PEP+"}
                </h3>
                <h4 className="title--h5 ages">12-24 Months</h4>

                <h5 className="ages">
                  <span>2 Jan</span> -<span>4 Jan</span>
                </h5>
              </div>
            </div>
            <div className="item item--flexbox item--flexbox--spacebetween">
              <div className="content">
                <div className="weekDays">
                  <div
                    style={{
                      textAlign: "center",
                      marginRight: "10px",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      className="title--h5 weekDays"
                      style={{
                        color: "#5e5f5f",
                        marginBottom: "4px",
                        fontWeight: "600",
                      }}
                    >
                      Monday / Wednesday
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        color: "#5e5f5f",
                      }}
                      className="timing"
                    >
                      <span>12:00 PM</span> - <span>2:00 PM</span>
                    </div>
                  </div>
                </div>
              </div>
              <span className="Class_tuition_fess"> $225</span>
              <div className="buttonsInfo">
                <button className={"btn "}>Add to Cart</button>

                <div style={{ marginTop: "10px" }}>
                  <button type="button" className="btn btn--primary">
                    Book a trial
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const studentList = () => (
    <>
      <div className="addStudentList">
        <div className="addStudentList__item">
          <div className="addStudentList__card">
            <div className="flexbox">
              <input
                name="student-radio"
                style={{
                  height: "25px",
                  width: "25px",
                  accentColor: "#5E5F5F",
                  cursor: "pointer",
                  borderWidth: "10px",
                }}
                type="radio"
              />

              <div className="pic">
                <img
                  className="pic__img"
                  src={lousengerImg}
                  alt="ana-louser-img"
                />
              </div>
              <div className="detail">
                <h3 className="name">Ana Lousenger</h3>
                <p className="para para--lightColor">GYMTIME</p>
              </div>
            </div>
            <div className="btnActions">
              <button className="btnTransparent" type="button">
                <img
                  className="btnTransparent__icon"
                  src={trashIcon}
                  alt="trash-icon"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  // Lower Carousel Componet of the Classes ::

  return (
    <>
      <div className="contentwraper">
        <section
          className="siteBanner"
          style={{ background: "#F79727", cursor: "default" }}
        >
          <div className="siteBanner__overlay">
            <img className="siteBanner__img" src={aboutUsBanner} alt="" />
          </div>
        </section>
        <section className="breadcrumbSec">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">
                  Back to results
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="programDetialSec">
          <div className="container">
            <div className="programCard">
              <h1 className="title title--extrabold">
                {id == 45 ? "Recreational Cheer" : "PEP+"}
                <span className="timeperiod">12-24 Months</span>
              </h1>
              <div className="flexbox">
                <div className="shortText">
                  <p>
                  PEP+ on My Own children not only engage in a multifaceted experience of gym, art, cooking, and music but will also introduce a gradual separation process, fostering independence and preparing them for preschool. During this transitional phase, our experienced instructors will provide gentle guidance all while your child leap in our gym class, paint in the art studio, don aprons in our kitchen and sing in music. This holistic approach nurtures not only their physical and creative abilities but also fosters essential life skills, ensuring a seamless transition to the next stage of their educational journey.
                  </p>
                </div>
                <div class="picture">
                  <img
                    class="picture__img"
                    src="https://cdn.gymtime.net/image1702622378911.jpeg"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="classAvailability">
              <div className="classAvailability__header">
                <h2 className="title title--h4" style={{ background: "none" }}>
                  Add Students for This Program :
                </h2>
                <button className="btn btn--primary">+ Add Student</button>
              </div>

              {studentList()}

              {classAvailabilityList()}
            </div>
          </div>
        </section>

        {/* LOWER FUNCTION  */}
        <section className="ourClassesSec">
          <div className="container">
            <h3 className="title">More Classes Of This Age</h3>
            <div className="classesList">
              <OwlCarousel
                responsiveclassname={true}
                className="galleryGrid owl-carousel"
                autoplay={true}
                arrows={true}
                responsiveRefreshRate={0}
                margin={50}
                dots={true}
                nav={true}
              >
                <div className="classesCard" style={{ cursor: "pointer" }}>
                  <div className="picture">
                    <img
                      style={{ height: "250px" }}
                      className="picture__img"
                      src={programDetailImg1}
                      alt="clas img"
                    />
                    {/* <div className="tagmark">
                          {val?.name?.match(/^(.*?)(?=\d)/)?.[1] || val?.name}
                        </div> */}
                  </div>
                  <h1 className="carasoul_classname_value"> Pep</h1>
                </div>
                <div className="classesCard" style={{ cursor: "pointer" }}>
                  <div className="picture">
                    <img
                      style={{ height: "250px" }}
                      className="picture__img"
                      src={programDetailImg1}
                      alt="clas img"
                    />
                    {/* <div className="tagmark">
                          {val?.name?.match(/^(.*?)(?=\d)/)?.[1] || val?.name}
                        </div> */}
                  </div>
                  <h1 className="carasoul_classname_value"> Pep</h1>
                </div>
                <div className="classesCard" style={{ cursor: "pointer" }}>
                  <div className="picture">
                    <img
                      style={{ height: "250px" }}
                      className="picture__img"
                      src={programDetailImg1}
                      alt="clas img"
                    />
                    {/* <div className="tagmark">
                          {val?.name?.match(/^(.*?)(?=\d)/)?.[1] || val?.name}
                        </div> */}
                  </div>
                  <h1 className="carasoul_classname_value"> Pep</h1>
                </div>
                <div className="classesCard" style={{ cursor: "pointer" }}>
                  <div className="picture">
                    <img
                      style={{ height: "250px" }}
                      className="picture__img"
                      src={programDetailImg1}
                      alt="clas img"
                    />
                    {/* <div className="tagmark">
                          {val?.name?.match(/^(.*?)(?=\d)/)?.[1] || val?.name}
                        </div> */}
                  </div>
                  <h1 className="carasoul_classname_value"> Pep</h1>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default PepPlusDetailPage;
