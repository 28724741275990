import axios from "axios";

export async function getAgeProgramHeaderImage(token){
    try {
        const data="";
        const response=await axios.get(
            process.env.REACT_APP_BASE_URL + "/admin/get_header_program",
            data,{
            headers:{
                'Authorization': `Bearer${token}`
            }
            }
        )
        return response;
    } catch (error) {
    }
}