const GET_STUDENTS = `
query GetStudent { 
    getStudents 
    {
         address 
         last_name 
         first_name 
         school_attending_withs 
         { 
            id 
            name 
            attending_with 
            fk_clients_students 
        } 
        age 
        allergies
         dob 
         doctor_name 
         doctor_phone 
         emergency_name 
         emergency_phone 
         fk_clients_students 
         gender 
         id 
         parent_signature 
         photo_release 
         vaccination 
         image
         state
         zipcode
        } 
    }
`;


// Parent Enorllments..
const GET_ALL_STUDENT_ENROLLMENT = `
query GetAllStudentEnrollments { getAllStudentEnrollments { id first_name last_name age fk_clients_students dob gender allergies createdat updatedat doctor_name doctor_phone emergency_name emergency_phone vaccination photo_release parent_signature address is_active fk_student_classes_enrolled fk_class_schedule_classes_enrolled start_date end_date fk_teacher_class_schedule fk_class_class_schedule start_time end_time weekday fk_season_classes name fk_category_classes tuition max_age min_age website hidden max_strength period } } 
`


export {
    GET_STUDENTS,
    GET_ALL_STUDENT_ENROLLMENT
};
