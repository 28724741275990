export const GET_CLIENTS = `
query GetClientForAdminPanel($category: String, $name: String, $parentEmail: String, $parentName: String, $clientId: String, $pageInfo: PaginationInfo , $studentName:  String , $studentId : String,  $cell : String) {
  getClientForAdminPanel(category: $category, name: $name, parentEmail: $parentEmail, parentName: $parentName, clientId: $clientId, pageInfo: $pageInfo , studentName : $studentName, studentId : $studentId , cell : $cell) {
      data {
        id
        parent1_first_name
        parent1_last_name
        parent1_cell
        parent1_alt_cell
        parent1_email
        parent1_address
        parent1_apartment
        parent1_zip
        parent1_city
        parent1_address2
        parent1_workphone
        parent2_first_name
        parent2_last_name
        parent2_cell
        parent2_alt_cell
        parent2_email
        parent2_address
        parent2_address2
        parent2_workphone
        parent2_apartment
        parent2_zip
        parent2_city
        parent1_state
        parent2_state
        fk_user_clients
        createat
        updatedat
        parent1_image
        parent2_image
        creditCardsList {
          id
          file
        }
      }
      limit
      totalCount
      totalPages
      currPage
    }
  }

`;

export const GET_CATEGORY = `
query GetCategories($pageInfo: PaginationInfo, $schoolName: String, $name: String) {
  getCategories(pageInfo: $pageInfo, schoolName: $schoolName, name: $name) {
      data {
        id
        name
        school_name
      }
      limit
      totalCount
      totalPages
      currPage
    }
  }

`;

export const GET_CLASS = `
query getClassesQuery($searchId: ID!) {
  getClasses(searchId: $searchId) {
    name
    id
    end_date
    start_date
    fk_category_classes
    fk_season_classes
    fk_teachers_classes
    gender
    hidden
    max_age
    min_age
    max_strength
    period
    tuition
    website
    weekday
    season {
      updatedat
      createdat
      id
      name
      start_date
      end_date
    }
    category {
      id
      name
    }
    class_schedules {
      end_time
      start_time
      id
      weekday
     
    }
  }
}
 

`;

export const GET_SCHEDULE_NEW = `
query GetClass($classId: Int) {
  getClass(classId: $classId) {
    id
    end_date
    name
    max_strength
    start_date
    end_date
    category {
      name
    }
    tuition
    class_schedules {
      subject
        id
      createdat
      end_time
      weekday
      start_time
      classScheduleId
    }    
    classes_enrolled {
        id
    }
  }
}
`;
export const CLASS_BY_MONTHLY_SWITCH_QUERY = `
  query getClassesOnly {
    getClassesOnly {
      name
      id
      end_date
      start_date
      fk_category_classes
      fk_season_classes
      fk_teachers_classes
      gender
      hidden
      max_age
      min_age
      max_strength
      period
      tuition
      website
      weekday
      season {
        updatedat
        createdat
        id
        name
        start_date
        end_date
      }
      category {
        id
        name
      }
      class_schedules {
        end_time
        start_time
        id
        weekday
      }
    }
  }
`;
export const CLASS_BY_WEEKLY_SWITCH_QUERY = `

query getCampWeekOnly {
    getCampWeekOnly {
      name
      id
      end_date
      start_date
      fk_category_classes
      fk_season_classes
      fk_teachers_classes
      category_name
      gender
      hidden
      max_age
      min_age
      max_strength
      period
      tuition
      website
      weekday
      season {
        updatedat
        createdat
        id
        name
        start_date
        end_date
      }
      category {
        id
        name
      }
      class_schedules {
        end_time
        start_time
        id
        weekday
      }
    }
  }
`;

export const GET_STUDENT_DETAILS = `
query GetStudentDetailsById($studentId: Int!) {
  getStudentDetailsById(studentId: $studentId) {
      id
      first_name
      last_name
      age
      fk_clients_students
      dob
      gender
      allergies
      doctor_name
      doctor_phone
      emergency_name
      emergency_phone
      vaccination
      photo_release
      parent_signature
      address
      image
      state
      zipcode
      school_attending_withs 
         { 
            id 
            name 
            attending_with 
            fk_clients_students 
        } 
      classDetails {
        classId
        className
        classScheduleId
        categoryId
        categoryName
        period 
        classScheduleDetails {
          id
          start_time
          end_time
          weekday
          name
          max_strength
          subject
        }
      }
    }
 
}
`;
