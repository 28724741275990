import React from "react";
import Header from "../components/Header";

import DeleteUser from "../components/UserModals/DeleteModal";
import { useState } from "react";
import AddNewUser from "../components/UserModals/AddNewUser";
import NavigationComponent from "../components/NavigationComponent";
import TableNew from "../components/TableComponent/TableNew";
import { useEffect } from "react";
import getPrograms from "../adminApi/categories";
import { debounce } from "lodash";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { deleteCategory } from "../adminApi/categories";
import { ReactComponent as SearchIcon } from "../assets/images/icons/search.svg";
import { categoryTable } from "../tableHeaderComponents";

function Categories() {
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLimit, setPageLimit] = useState("10");
  const [editCategory, setEditCategory] = useState(false);
  const [categoryData, setCategoryData] = useState("");
  const [addNewModal, setAddNewModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [dataEdit, setDataEdit] = useState();
  const [totalPages, setTotalPages] = useState();
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [loadingState, setLoadingState] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    categoryName: "",
  });
  const page = useSelector((state) => state?.adminSlice?.adminModalContent);

  const [sortKey, setSortKey] = useState({
    sortId: null,
    isAsc: true,
  });


  async function fetchData() {
    try {
      setLoadingState(true);
      const token = localStorage.getItem("access_token");
      const response = await getPrograms(
        token,
        formData,
        page,
        sortKey,
        pageLimit
      );

      if (response.data.length >= 0) {
        setCurrentPage(response?.currPage);
        setTotalCount(response?.totalCount);
        setTotalPages(response.totalPages);

        const modifiedData = response?.data?.map((category, index) => {
          return {
            data: [
              category?.id ? category?.id : "-",
              category?.name ? category?.name : "-",
              category?.school_name ? category?.school_name : "-",
            ],
            key: category.id,
            item: category,
            onDeletePress: () => {
              setCategoryData(parseInt(category.id));
              setDeleteCategoryModal(true);
            },
            onEditPress: () => {
              setDataEdit(category);
              setEditCategory(true);
            },
          };
        });

        setCategories(modifiedData);
        setLoading(false);
        setLoadingState(false);
      } else {
        setLoadingState(false);
        setLoading(false);
      }
    } catch (error) {
      setLoadingState(false);
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  }

  const handleInput = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
    if (key === "name") {
      if (value.length > 3 && value?.trim() && value !== formData.name) {
        debouncedFetchData(formData);
      }
    }
  };

  const handleSearch = (formData) => {
    fetchData(formData);
  };

  useEffect(() => {
    fetchData();
  }, [page, sortKey, pageLimit]);

  const debouncedFetchData = debounce(fetchData, 2000);

  useEffect(() => {
    const pattern = /[^ ]/;

    if (pattern.test(formData.name) || formData.name?.length === 0) {
      if (formData.name !== undefined) {
        const timeoutId = setTimeout(() => {
          debouncedFetchData(fetchData());
        }, 1000);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [formData.name]);

  return (
    <>
      <div className="container--component">
        <div className="container--grid">
          <NavigationComponent />

          <div className="Adminpage">
            <Header
              title="Categories"
              subTitle="(Manage Available Categories)"
            />
            <section className="Adminpage__section">
              <h2 className="Adminpage__title">Search by:</h2>

              <div className="Adminpage__head">
                <div className="Adminpage__filter">
                  <div className="adminform__group">
                    <select
                      name="categoryName"
                      id=""
                      className="adminform__select"
                      onChange={(e) =>
                        handleInput("categoryName", e.target.value)
                      }
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Search
                      </option>

                      <option value="name">Category Name</option>
                    </select>
                  </div>

                  <div className="adminform__group">
                    <input
                      type="text"
                      className="adminform__input"
                      placeholder="Search Here"
                      name="name"
                      disabled={formData?.categoryName === "" ? true : false}
                      onChange={(e) => handleInput("name", e.target.value)}
                    />
                  </div>

                  <div className="">
                    <button
                      className="adminbtn adminbtn-primary"
                      onClick={handleSearch}
                    >
                      <SearchIcon />
                      Search
                    </button>
                  </div>
                </div>
                <button
                  className="adminbtn adminbtn-light"
                  id="btnCategory"
                  onClick={() => {
                    setAddNewModal(!addNewModal);
                  }}
                  // disabled={role == "4"}
                >
                  + Add New Category
                </button>
              </div>

              {/* {loading && <LoaderComponent />} */}

              <TableNew
                loadingState={loadingState}
                headerData={categoryTable}
                bodyData={categories}
                totalPages={totalPages}
                tableType={"Category"}
                setSortKey={setSortKey}
                setPageLimit={setPageLimit}
                pageLimit={pageLimit}
                totalCount={totalCount}
                currentPage={currentPage}
              />
              {/* </div> */}
            </section>
          </div>
        </div>
      </div>

      {addNewModal ? (
        <AddNewUser
          openAddNewUser={addNewModal}
          setAddNewUser={setAddNewModal}
          data=""
          type="Add"
          fetchData={fetchData}
          setSortKey={setSortKey}
        
        />
      ) : null}

      {editCategory ? (
        <AddNewUser
          openAddNewUser={editCategory}
          setAddNewUser={setEditCategory}
          data={dataEdit}
          type="Edit"
          fetchData={fetchData}
        />
      ) : null}

      {deleteCategoryModal ? (
        <DeleteUser
          openDeleteModal={deleteCategoryModal}
          setDeleteModal={setDeleteCategoryModal}
          data={categoryData}
          fetchData={fetchData}
          deleteQuery={deleteCategory}
          type={"Category"}
        />
      ) : null}
    </>
  );
}

export default Categories;
