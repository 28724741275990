const homePageContent = {
  bannerImage: {
    url: require("../parent-assets/images/home-banner.jpg"),
    alt: "home-banner",
  },

  browseByImage: {
    browseByProgram: {
      url: require("../parent-assets/images/programs-icon.png"),
      alt: "program icon",
    },
    browseByAge: {
      url: require("../parent-assets/images/ages-icon.png"),
      alt: "age icon",
    },
    browseByBirthDay: {
      url: require("../parent-assets/images/cake-icon.png"),
      alt: "cake icon",
    },
  },

  highlights: {
    highlightImage: {
      url: require("../parent-assets/images/about-us-img.png"),
      alt: "highlight img",
    },
    highlightContent: {
      title: "What’s New?",
      content:
        " In publishing and graphic design, Lorem ipsum is aplaceholder  text commonly used to demonstrate the visual form of a    document or a typeface without relying on meaningful content.  Lorem ipsum may be used as a placeholder before final copy is    available",
    },
  },
};

const aboutPageContent = {
  aboutBannerImage: {
    url: require("../parent-assets/images/home-banner.jpg"),
    alt: "about us banner",
  },

  aboutUsTop: {
    topImage: {
      url: require("../parent-assets/images/About.jpg"),
      alt: "about us top banner",
    },

    topContent: {
      title: " About Us",
      content: ` Gymtime is a holistic educational environment for  6-month-olds to 16-year-olds. We are the pioneers and  developers of integrated education, gymnastics, sports,      music, cooking and art programs in the New York City      area. Our comprehensive curriculum includes       “mommy-and-me” and separation classes for toddlers,       plus enrichment and afterschool programs in art, music,      cooking, sports, Tae Kwon Do, recreational and      competitive team gymnastics, and more.`,
    },
  },

  middleContent: {
    middleContentImage: {
      url: require("../parent-assets/images/About.jpg"),
      content: "",
    },
    middleItems: {
      title: "There’s Playtime, and then there’s GYMTIME !",
      content: `We offer integrated education and a wide range of programs, including gymnastics, art, music, cooking, cheer, dance and yoga, "grownup and me" classes and exciting summer camps.
      
      With over 30 years of experience, we've established ourselves as the leader in child development. Our state-of-the-art facility is designed to ignite exploration, engage children's curiosity, and boost self-confidence through play-based learning. At Gymtime, we're dedicated to making it the premier place for children to learn, grow, and play.`,
    },
  },

  bottomContent: {
    bottomImage: {
      url: require("../parent-assets/images/About.jpg"),
      alt: "bottom img",
    },
    bottomText: {
      title: "“Both My Kids Love Gymtime!” -Beth Smith",
      content: `
        With over 30 years of experience, we've established ourselves as the leader in child development. Our state-of-the-art facility is designed to ignite exploration, engage children's curiosity, and boost self-confidence through play-based learning. At Gymtime, we're dedicated to making it the premier place for children to learn, grow, and play.
        `,
    },
  },

  videoContent: {
    videoImg: {
      url: require("../parent-assets/images/video-thumbnail.png"),
      alt: "video img",
    },
    playIcon: {
      url: require("../parent-assets/images/Button-play.svg"),
      alt: "play icon",
    },
    videoData: {
      title: "Check us OUT!",
      content:
        "We offer a wide variety of age-appropriate classes at Gymtime, including gymnastics, art, music, cooking, and more, all designed to nurture growth and development across all age groups. Our programs are carefully crafted to ensure that children of all ages can enjoy and benefit from a diverse range of activities, supporting their physical, creative, and personal growth to help them reach their full potential.",
    },
  },
};

const contactPageContent = {
  bannerImage: {
    url: require("../parent-assets/images/home-banner.jpg"),
    alt: "banner img",
  },

  topContent: {
    title: "Contact Us",
    content:
      "Gymtime is a holistic educational environment for      6-month-olds to 16-year-olds. We are the pioneers and      developers of integrated education, gymnastics,      sports, music, cooking and art programs in the New      York City area. Our comprehensive curriculum includes      “mommy-and-me” and separation classes for      toddlers, plus enrichment and afterschool programs in      art, music, cooking, sports, Tae Kwon Do, recreational      and competitive team gymnastics, and more.",
  },
};

const facilitiesPage = {
  bannerImage: {
    url: require("../parent-assets/images/home-banner.jpg"),
    alt: "facilities banner",
    text1:
      " Gymtime’s 25,000-square-foot facilities were personally designed by our co-founders based on their 30 years of experience   offering integrated education, sports and gymnastics programs.",
    text2:
      " Every room at Gymtime is custom designed with a specific   activity, age group and skill development in mind. Our new   gymnastics center has absolutely no physical limitations and   offers the same top-of-the-line equipment that is used at the   USA Nationals competition. Gymtime’s 25,000-square-foot   facilities were personally designed by our co-founders based on   their 30 years of experience offering integrated education,   sports and gymnastics programs.",
  },

  programImages: {
    prgrm1: {
      // url: require("../parent-assets/images/facilities-img01.png"),
      alt: "gymnastic",
    },
    prgrm2: {
      url: require("../parent-assets/images/facilities-img02.png"),
      alt: "cooking",
    },
    prgrm3: {
      url: require("../parent-assets/images/facilities-img03.png"),
      alt: "music",
    },
    prgrm4: {
      url: require("../parent-assets/images/facilities-img04.png"),
      alt: "art",
    },
    prgrm5: {
      url: require("../parent-assets/images/facilities-img05.png"),
      alt: "tae kwan do",
    },
    prgrm6: {
      url: require("../parent-assets/images/facilities-img06.png"),
      alt: "birthday",
    },
  },
};

const termsAndConditions = {
  termBannerImage: {
    url: require("../parent-assets/images/home-banner.jpg"),
    alt: "term and condition banner image",
  },

  PrivacyList: {
    List1: {
      title: "Privacy",
      content:
        "The information provided on this Site is for educational and entertainment purposes only. Use of this Site is subject to the terms of our Privacy Policy, which is hereby incorporated into and made part of this Agreement. Please carefully review our Privacy Policy. By using this Site, you agree to be bound by the terms of our Privacy Policy. We reserve the right, and you authorize us, to use information regarding your use of this Site and any other personal information provided by you in accordance with our Privacy Policy.",
    },
    List2: {
      title: "Copyright; Trademarks",
      content:
        "You acknowledge that all materials on the Site, including the Site’s design, graphics, text, sounds, pictures, software and other files and the selection and arrangement thereof (collectively, “Materials”), are the property of Company or its licensors, and are subject to and protected by United States and international copyright and other intellectual property laws and rights. All rights to Materials not expressly granted in this Agreement are reserved to their respective copyright owners. Except as expressly authorized by this Agreement or on the Site, you may not copy, reproduce, distribute, republish, download, perform, display, post, transmit, exploit, create derivative works or otherwise use any of the Materials in any form or by any means, without the prior written authorization of Company or the respective copyright owner. Company authorizes you to view and download the Materials only for personal, non-commercial use, provided that you keep intact all copyright and other proprietary notices contained in the original Materials. You may not modify or adapt the Materials in any way or otherwise use them for any public or commercial purposes. The trademarks, service marks, trade names, trade dress and logos (collectively, “Marks”) contained or described on this Site (including, without limitation, GYMTIME) are the sole property of Company and/or its licensors and may not be copied, imitated or otherwise used, in whole or in part, without the prior written authorization of Company and/or its licensors. In addition, all page headers, custom graphics, button icons and scripts are Marks of Company and may not be copied, imitated or otherwise used, in whole or in part, without the prior written authorization of Company. Company will enforce its intellectual property rights to the fullest extent of the law",
    },
    List3: {
      title: "Links; Third Party Websites",
      content:
        "From time to time, Company may collect general, non-personal, statistical information about the use of the Site, such as how many visitors visit a specific page on the Site, how long they stay on that page and which hyperlinks, if any, they “click” on. We collect this information through the use of “cookies” and other tracking technologies, which are discussed in greater detail below. We collect this information in order to determine which areas of the Site are most popular and to enhance the Site for visitors. We may group this information into aggregate visitor data in order to describe the use of the Site to our existing or potential business partners, sponsors, advertisers or other third parties, or in response to a government request. Company may also group demographic and preferences information and responses to surveys that we collect from visitors into aggregate data for the same purposes. However, please be assured that this aggregate data will in no way personally identify you or any other visitors to the Site.",
    },
    List4: {
      title: "Cookies and Other Tracking Technologies",
      content:
        "Some of our web pages utilize “cookies” and other tracking technologies. A cookie is a small text file that a website transfers to an individual’s hard drive for record-keeping purposes. For example, we may use cookies to collect information about website activity. Most browsers allow you to control cookies, including whether or not to accept them and how to remove them. You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser. However, please be aware that some features of the Site may not function properly or may be slower if you refuse cookies. You may occasionally get cookies from our advertisers or other third parties with links on the Site (as in Section X). We do not control these cookies. The use of advertising cookies sent by third party ad servers is standard in the Internet industry. Tracking technologies may record information such as Internet domain and host names; Internet protocol (IP) addresses; browser software and operating system types; clickstream patterns; and dates and times that our Site is accessed. An IP address is a number that is automatically assigned to your computer whenever you are surfing the web. Web servers, the computers that “serve up” webpages, automatically identify your computer by its IP address. We will not link the information we collect through tracking technologies for marketing purposes to the personal information submitted about the students using the Company software. In addition, we reserve the right to use IP addresses and other tracking technologies to identify a visitor only when we feel it is necessary to enforce compliance with the Site’s policies, to protect the Site, our customers or others, or when we believe in good faith that the law requires it.",
    },
  },
};

const privacyPolicy = {
  bannerImage: {
    url: require("../parent-assets/images/home-banner.jpg"),
    alt: "banner img",
  },

  policyList: {
    list1: {
      title: "General Information",
      content:
        "his website, and the various content, features and services offered on this website (collectively, the “Site”), is owned and/or operated by Gymtime (“Company,” “we,” “us,” “our” or similar pronoun). Company is committed to respecting your online privacy and recognizes your need for appropriate protection and management of any personally identifiable information (“Personal Information”) you share with us. Company has established this privacy policy (“Privacy Policy”) to let you know what information we may collect from you on the Site and how we may use and share that information. Please take a moment to review the terms of our Privacy Policy. By using the Site, you agree to accept the terms of our Privacy Policy. If you do not agree to the terms of this Privacy Policy, please do not use the Site. Except as expressly provided herein, this Privacy Policy applies only to information that we collect on the Site and does not apply to information which we may collect by other means.",
    },
    list2: {
      title: "Personal Information; Changes to Personal Information",
      content:
        "Personal Information means any information that may be used to identify an individual, including, but not limited to, first and last name, home or other physical address, telephone number, e-mail address, occupation, job title, or information about your job/occupation. In general, you can visit the Site without telling us who you are or revealing any Personal Information about yourself. Some features or services may require you to provide Personal Information. For example, we may collect certain Personal Information if you choose to subscribe to a mailing list, participate in special offers, sweepstakes or other promotions, or send questions or comments to us via e-mail. We may also collect certain demographic information (such as gender or age) and information about your interests and preferences (“preferences information”). In many cases, such demographic and preferences information is optional. In other cases, such as online surveys, we collect this information on an anonymous basis. However, if we link any demographic or preferences information to any Personal Information, then such demographic and preferences information will be treated as Personal Information under this Privacy Policy.",
    },
    list3: {
      title: "Aggregate Information",
      content:
        "From time to time, Company may collect general, non-personal, statistical information about the use of the Site, such as how many visitors visit a specific page on the Site, how long they stay on that page and which hyperlinks, if any, they “click” on. We collect this information through the use of “cookies” and other tracking technologies, which are discussed in greater detail below. We collect this information in order to determine which areas of the Site are most popular and to enhance the Site for visitors. We may group this information into aggregate visitor data in order to describe the use of the Site to our existing or potential business partners, sponsors, advertisers or other third parties, or in response to a government request. Company may also group demographic and preferences information and responses to surveys that we collect from visitors into aggregate data for the same purposes. However, please be assured that this aggregate data will in no way personally identify you or any other visitors to the Site.",
    },
    list4: {
      title: "Cookies and Other Tracking Technologies",
      content:
        "Some of our web pages utilize “cookies” and other tracking technologies. A cookie is a small text file that a website transfers to an individual’s hard drive for record-keeping purposes. For example, we may use cookies to collect information about website activity. Most browsers allow you to control cookies, including whether or not to accept them and how to remove them. You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser. However, please be aware that some features of the Site may not function properly or may be slower if you refuse cookies. You may occasionally get cookies from our advertisers or other third parties with links on the Site (as in Section X). We do not control these cookies. The use of advertising cookies sent by third party ad servers is standard in the Internet industry. Tracking technologies may record information such as Internet domain and host names; Internet protocol (IP) addresses; browser software and operating system types; clickstream patterns; and dates and times that our Site is accessed. An IP address is a number that is automatically assigned to your computer whenever you are surfing the web. Web servers, the computers that “serve up” webpages, automatically identify your computer by its IP address. We will not link the information we collect through tracking technologies for marketing purposes to the personal information submitted about the students using the Company software. In addition, we reserve the right to use IP addresses and other tracking technologies to identify a visitor only when we feel it is necessary to enforce compliance with the Site’s policies, to protect the Site, our customers or others, or when we believe in good faith that the law requires it.",
    },
  },
};

const staffPageContent = {
  bannerImage: {
    alt: "staff page banner image",
  },

  founder: {
    image: {
      alt: "founder img",
    },

    founderName: "Michael & Bonni Branciforte",
    founderCategory: "Founders",
    title: "The Founders",
    content:
      "Gymtime is a holistic educational environment for 6-month-olds to 16-year-olds. We are the pioneers and developers of integrated education, gymnastics, sports, music, cooking and art programs in the New York City area. Our comprehensive curriculum includes “mommy-and-me” and separation classes for toddlers, plus enrichment and afterschool programs in art, music, cooking, sports, Tae Kwon Do, recreational and competitive team gymnastics, and more.",
  },
};

export {
  homePageContent,
  aboutPageContent,
  contactPageContent,
  facilitiesPage,
  termsAndConditions,
  privacyPolicy,
  staffPageContent,
};
