export const arrowIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.4615 3.7712C7.72183 3.51085 7.72183 3.08874 7.4615 2.82839L5.57588 0.942771C5.53707 0.903964 5.49647 0.868044 5.45433 0.835024C5.00181 0.480349 4.37288 0.459313 3.89943 0.771917C3.82549 0.820731 3.75535 0.877684 3.69026 0.942771L1.80464 2.82839C1.54429 3.08874 1.54429 3.51085 1.80464 3.7712C2.06499 4.03155 2.4871 4.03155 2.74745 3.7712L3.99992 2.51872V12C3.99992 12.3682 4.2984 12.6666 4.66659 12.6666C5.03478 12.6666 5.33325 12.3682 5.33325 12V2.58576L6.51869 3.7712C6.77903 4.03154 7.20117 4.03154 7.4615 3.7712Z"
      fill="white"
    />
    <path
      d="M8.47064 12.2287C8.21031 12.4891 8.21031 12.9111 8.47064 13.1715L10.3562 15.0571C10.4214 15.1222 10.4915 15.1792 10.5654 15.228C11.083 15.5697 11.7862 15.5127 12.2419 15.0571L14.1275 13.1715C14.3878 12.9111 14.3878 12.4891 14.1275 12.2287C13.8672 11.9683 13.445 11.9683 13.1847 12.2287L11.9992 13.4141V3.99992C11.9992 3.63173 11.7008 3.33325 11.3326 3.33325C10.9644 3.33325 10.6659 3.63173 10.6659 3.99992V13.4811L9.41344 12.2287C9.15311 11.9683 8.73097 11.9683 8.47064 12.2287Z"
      fill="white"
    />
  </svg>
);

// CATEGORIES
export const categoryTable = [
  {
    name: "ID No.",
    icon: arrowIcon,
  },
  {
    name: "Category Name",
    icon: arrowIcon,
  },
  {
    name: "School Name",
    icon: arrowIcon,
  },
  {
    name: "Action",
  },
];

// TEACHERS
export const teacherTable = [
  {
    name: "Code",
    icon: arrowIcon,
  },
  {
    name: "Teacher Name",
    icon: arrowIcon,
  },
  {
    name: "Email",
    icon: arrowIcon,
  },
  {
    name: "Address",
    icon: arrowIcon,
  },
  {
    name: "City",
    icon: arrowIcon,
  },
  {
    name: "Zip Code",
  },
  {
    name: "Cell",
  },

  {
    name: "Action",
  },
];

// USERS
export const userTableHeader = [
  {
    name: "ID No.",
    icon: arrowIcon,
  },
  {
    name: "First Name",
    icon: arrowIcon,
  },
  {
    name: "Last Name",
    icon: arrowIcon,
  },
  {
    name: "Email",
    icon: arrowIcon,
  },
  {
    name: "Role",
    icon: arrowIcon,
  },
  {
    name: "Action",
  },
];

// Classes
export const classTableHeader = [
  {
    name: "ID",
    icon: arrowIcon,
  },
  {
    name: "Season",
    icon: arrowIcon,
  },
  {
    name: "Class Name",
    icon: arrowIcon,
  },
  {
    name: "Category",
    icon: arrowIcon,
  },
  {
    name: "Class type",
    icon: arrowIcon,
  },
  {
    name: "Start Date",
    icon: arrowIcon,
  },

  {
    name: "Tuition",
    icon: arrowIcon,
  },
  {
    name: "Min Age",
    icon: arrowIcon,
  },
  {
    name: "Max Age",
    icon: arrowIcon,
  },

  {
    name: "Max Strength",
  },
  {
    name: "Website",
    icon: arrowIcon,
  },

  {
    name: "Teacher",
  },
  {
    name: "Actions",
  },
];

export const subHeaderClassTable = [
  {
    name: "Student ID",
  },
  {
    name: "Student Name",
  },

  {
    name: "Student Age",
  },
  {
    name: "Tuition Fee(Pro-Rated)",
  },
];

// CLients
export const clientTableHeader = [
  {
    name: "Client ID",
    icon: arrowIcon,
  },
  {
    name: "Parent1",
    icon: arrowIcon,
  },
  {
    name: "Parent 1 Email",
    icon: arrowIcon,
  },
  {
    name: "Parent 1 Cell",
  },
  {
    name: "Parent 2",
    icon: arrowIcon,
  },
  {
    name: "Parent 2 Email",
    icon: arrowIcon,
  },
  {
    name: "Parent 2 Cell",
    icon: arrowIcon,
  },
  {
    name: "Actions",
  },
];
export const clientSubHeader = [
  {
    name: "Student ID",
  },
  {
    name: "Student Name",
  },
  {
    name: "DOB",
  },
  {
    name: "Age",
  },
  {
    name: "Gender",
  },
  {
    name: "Allergy",
  },
  {
    name: "Class Enrolled",
  },
  {
    name: "+ MakeUp/Trials",
  },
  {
    name: "Actions",
  },
];
export const clientCommentHeader = [
  {
    name: "Date",
  },
  {
    name: "Subject",
  },
  {
    name: "Comment",
  },
  {
    name: "Actions",
  },
];
export const makeupTableHeader = [
  {
    icon: arrowIcon,
    name: "Class Code",
  },
  {
    icon: arrowIcon,
    name: "Class Name",
  },
  {
    icon: arrowIcon,
    name: "Teacher name",
  },
  {
    icon: arrowIcon,
    name: "Schedule",
  },

  {
    name: "Min Age",
    icon: arrowIcon,
  },
  {
    name: "Max Age",
    icon: arrowIcon,
  },
  {
    name: "Class Type",
  },
  {
    name: "Memo",
  },

  {
    name: "Actions",
  },
];

// Ledger
export const ledgerTable = [
  {
    name: "Client ID",
    icon: arrowIcon,
  },
  {
    name: " Student ID",
    icon: arrowIcon,
  },
  {
    name: "Student Name",
    icon: arrowIcon,
  },
  {
    name: "Class Enrollment",
    icon: arrowIcon,
  },
  {
    name: "Parent 1 Name",
    icon: arrowIcon,
  },
  {
    name: "Parent 2 Name",
    icon: arrowIcon,
  },
  {
    name: "Balance($)",
    icon: arrowIcon,
  },
  {
    name: "Pay",
  },
  {
    name: "Enable Recurring",
  },
  {
    name: "Recurring",
  },

  // {
  //   name: "Paid",
  //   icon: arrowIcon,
  // },
  {
    name: "Void Payments",
  },
  {
    name: "Add Charge",
  },
  {
    name: "Payment Notes",
    icon: arrowIcon,
  },
  {
    name: "Invoice",
  },
  {
    name: "CC Expire Alert",
  },

  // {
  //   name: "Confirmation & Policy Email",
  // },
  {
    name: "Actions",
  },
];

export const balanceHeader = [
  {
    name: "Date",
    // icon: arrowIcon,
  },
  {
    name: "Events",
    // icon: arrowIcon,
  },

  {
    name: "Info",
    // icon: arrowIcon,
  },
];

export const ledgerSubHeader = [
  {
    name: "Class Name",
  },
  {
    name: "Category",
  },
  {
    name: "Class fee/ Pro-rated($)",
  },
  {
    name: "Payment Date",
  },
  {
    name: "Charge Date",
  },
  {
    name: "Amount Paid($)",
  },
  {
    name: "Notes",
  },
];
