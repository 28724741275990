import axios from "axios";
import { GET_NOTIFICATIONS } from "../adminQueries/Notifications";
import { GET_CLASSES } from "../adminQueries/Classes";

export async function getNotifications(
  token,
  formData,
  searchParams,
  page,
  pageLimit,
  sortKey,
  selectedIds
) {
  const categoryArray = selectedIds?.map((item) => parseInt(item.value));
  const variables = {};
  searchParams.forEach((item) => {
    const value = Array.isArray(item.name)
      ? JSON.stringify(item.name)
      : item.name;
    const key = item.selectUser;

    if (key === "categoryIds") {
      variables[key] = categoryArray;
    } else {
      try {
        variables[key] = JSON.parse(value);
      } catch (error) {
        variables[key] = value;
      }
    }
  });

  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_NOTIFICATIONS,
        variables: {
          pageInfo: {
            limit: parseInt(pageLimit),
            page: page,
            sort: sortKey?.sortId === null ? null : sortKey?.sortId?.toString(),
            isAsc: sortKey?.isAsc,
          },
          ...variables,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data?.data?.getDetailsForNotifications;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}

export async function getAllCLass(
  token,
  formData,
  page,
  sortID,
  CategorySortToggle,
  limit
) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_CLASSES,
        variables: {
          pageInfo: {
            // isAsc: sortKey?.isAsc,
            limit: 300000,
            page: page,
            // sort: sortKey?.sortId === null ? null: sortKey?.sortId?.toString() ,
          },
          season: "",
          className: "",
          category: "",
          tuition: null,
          maxAge: null,
          minAge: null,
          website: "",
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data?.data?.getClassesForAdmin;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}

export async function sendNotification(formData, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/admin/sendNotifications",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    return error;
  }
}

// export async function updateCategory(editData, token ,id) {

//     try {
//         const response = await axios.put(process.env.REACT_APP_BASE_URL + `/admin/category/update/${id}`,
//         editData,{
//             headers: {
//                 Authorization: `Bearer ${token}`
//             }
//         });

//         return response?.data;
//     } catch (error) {
//         return error
//         throw new Error(error)
//     }
// }

// export const deleteCategory = async (data, token) => {
//     try {
//         const response = await axios.delete(process.env.REACT_APP_BASE_URL + `/admin/category/delete/${data}`, {
//             headers: {
//                 Authorization: `Bearer ${token}`
//             }
//         });
//         return response.data;
//     } catch (error) {
//         console.error("Error deleting student:", error);
//         throw error;
//     }
// };

export default getNotifications;
