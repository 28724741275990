import React, { useState, useRef, useEffect } from 'react'
import JoditEditor from 'jodit-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCampProgramClassDetails } from '../../adminApi/program';
const TeamGymCheer = ({setShowCampModal,subprogramId}) => {
   
    const token=localStorage.getItem("access_token");
    const editor = useRef(null);
    const [halfDayContent, setHalfDayContent] = useState();
    const [fullDayContent, setFullDayContent] = useState();

    const cheerCampTeamGymHandler=async()=>{
        try {
            const postData = {
                subprogramId: subprogramId,
                content: [
                    {
                        first_one: halfDayContent
                    },
                    {
                        second_one: fullDayContent
                    }
                ]
            }
            const response= await axios.post(
                process.env.REACT_APP_BASE_URL + `/admin/create_post_program_name`,postData,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                      },
                }
            );
            if(response?.status===200 || response?.status===201){
                toast.success(`Program Updated Successfully`);
                setShowCampModal(false);
            }
        } catch (error) {
            toast.error("Something went wrong!");
            setShowCampModal(false);
        }
    }
    useEffect(()=>{
       getTeamCampCheer();
    },[])
    
    const getTeamCampCheer=async()=>{
        const response=await getCampProgramClassDetails(subprogramId);
        setHalfDayContent(response?.data?.data?.[0]?.content?.[0]?.first_one);
        setFullDayContent(response?.data?.data?.[0]?.content?.[1]?.second_one);
    }
    return (
        <>
            <div className="fluid_container">
                <div className="three_four_half_day_container">
                    <div className="half_day_container">
                        <h1 className='schedule_day_title_camp'>Mandatory Practice</h1>
                        <JoditEditor
                            ref={editor}
                            value={halfDayContent}
                            onChange={(newContent) => {
                                setHalfDayContent(newContent)
                            }}
                        />
                    </div>
                    <div className="full_day_container">
                        <h1 className='schedule_day_title_camp'>Try outs</h1>
                        <JoditEditor
                            ref={editor}
                            value={fullDayContent}
                            onChange={(newContent) => {
                                setFullDayContent(newContent)
                            }}
                        />
                    </div>
                </div>
                <button className='adminbtn adminbtn-primary camp_btm'
                    onClick={() => cheerCampTeamGymHandler()}
                >Save</button>
            </div>
        </>
    )
}

export default TeamGymCheer