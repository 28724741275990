import React, { useEffect, useState } from "react";

import { homePageContent } from "../api/contentApi";
import { useNavigate } from "react-router-dom";

import aboutUsImg from "../parent-pages/../parent-assets/images/about-us-img.png";

import { getSectionCMS } from "../../Admin/adminApi/mastermenu";
import Overlay from "../../Admin/components/LoaderComponent";
import HomeShimmer from "../../Admin/components/ShimmerUi/HomeShimmer";

function Home() {
  const [sectionData, setSectionData] = useState([]);
  const [showHomeImage, setShowHomeImage] = useState(false);
  const token = localStorage.getItem("access_token");
  const [showShimmerUI, setShowShimmerUI] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getHomeImages();
  }, []);

  const getHomeImages = async () => {
    setShowShimmerUI(true);
    setShowHomeImage(false);
    const section = await getSectionCMS(token, 172);
    setSectionData(section);
    setShowHomeImage(true);
    setShowShimmerUI(false);
  };

  return (
    <>
      {showShimmerUI ? (
        <HomeShimmer />
      ) : (
        <div
          className="contentwraper"
          data-toggle="modal"
          data-target="#LoginModal"
          style={{ paddingBottom: "0px" }}
        >
          <section style={{ position: "relative" }}>
            <section className="siteBanner">
              <div className="siteBanner__overlay">
                {showHomeImage === true ? (
                  <img
                    alt="banner-img"
                    className="siteBanner__img"
                    src={
                      sectionData?.length > 0 &&
                      sectionData[0]?.imageSection?.image
                    }
                    onError={(e) => {
                      e.target.onerror = null || "";
                      e.target.src = homePageContent.bannerImage.url;
                    }}
                  />
                ) : (
                  <Overlay />
                )}
              </div>
            </section>

            {sectionData?.map(
              (item, index) =>
                item?.section_name === "Header Title" && (
                  <div key={index} className="navbar_heading_siteBanner_img">
                    <h1
                      className="navbar_heading_siteBanner_img_heading"
                      style={{ color: `${item?.header_title[0]}` }}
                    >
                      {" "}
                      {item?.header_title[1]}
                    </h1>
                  </div>
                )
            )}
          </section>
          
          <section className="featureSec home_dummy_text_container">
            <div className="container">
              <div className="featureSec__topblock ">
                {sectionData?.map(
                  (item) =>
                    item?.section_name === "Home Description" && (
                      <p className="shortext_dummy_text_home" key={item?.id}>
                        {item?.descriptionSection?.description}
                      </p>
                    )
                )}
              </div>
            </div>
          </section>
          <section className="featureSec">
            <div className="container">
              <div className="featureSec__topblock">
                <h1 className="title title--h1 title--extrabold">Browse By</h1>
              </div>
              {
                <div className="featureList">
                  <div
                    className="featureList__item"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/browse-by-program-options");
                    }}
                  >
                    <div className="card card--orange">
                      <div className="card__icon">
                        <img
                          src={
                            homePageContent.browseByImage.browseByProgram.url
                          }
                          alt="Programs"
                        />
                      </div>
                      <h3 className="title">Programs</h3>
                    </div>
                  </div>
                  <div
                    className="featureList__item  "
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/browse-by-age-options");
                    }}
                  >
                    <div className="card card--pink">
                      <div className="card__icon">
                        <img
                          src={homePageContent.browseByImage.browseByAge.url}
                          alt="Ages"
                        />
                      </div>
                      <h3 className="title">Ages</h3>
                    </div>
                  </div>

                  <div
                    className="featureList__item"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/party");
                    }}
                  >
                    <div className="card card--blue">
                      <div className="card__icon">
                        <img
                          src={
                            homePageContent.browseByImage.browseByBirthDay.url
                          }
                          alt="Birthdays"
                        />
                      </div>
                      <h3 className="title">Birthdays</h3>
                    </div>
                  </div>
                </div>
              }
            </div>
          </section>

          {/* LOWER SECTION  */}
          <section className="whatsnewSec po">
            <div className="container">
              <div className="flexbox pop_up_sec">
                <div className="content">
                  <h2 className="title">POP UPS!</h2>
                  <p className="para">
                    {sectionData?.[1]?.descriptionSection?.description}
                  </p>
                  <div className="buttons">
                    <button
                      className="pop_up_btn_more_info"
                      onClick={() => {
                        navigate("/pop_up_image");
                      }}
                    >
                      More Info
                    </button>
                  </div>
                </div>

                <div className="picbox">
                  <div className="picbox__layerbox">
                    <img
                      src={
                        sectionData?.length > 0
                          ? sectionData[1]?.imageSection?.image
                          : aboutUsImg
                      }
                      onError={(e) => {
                        e.target.onerror = null || "";
                        e.target.src = aboutUsImg;
                      }}
                      alt="About Us"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default Home;
