import axios from "axios";
import { toast } from "react-toastify";

export const getMakeUpClassTableData = async (
  selectedClassId,
  selectedStudentID,
  sortKey
) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/admin/scheduled-makeups/get",
      {
        studentId: selectedStudentID,
        isAsc: sortKey?.isAsc,
        sort: sortKey?.sortId,
      }
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.error, {
      toastId: "error",
    });
  }
};

export const getMakeUpModalkDetailsAPI = async (data, token) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/admin/makeup-classes/get",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    if (error?.response?.data?.error == "Class is required.") {
      toast.error("Please Select Class.", {
        toastId: "error",
      });
    } else {
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    }
  }
};

//CREATE MAKEUP CLASSES

export const createMakeUpClass = async (makeUpClassIds) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/admin/schedule-makeup/create",
      makeUpClassIds,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    setTimeout(() => {
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    }, 1000);
  }
};

// GET ATTENDENCE IN MAKEUP

export const getMakeUpAttendence = async (studentId, classId) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL +
        `/admin/attendance/get/${studentId}/${classId}`,
      {
        studentId: studentId,
        classId: classId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.error, {
      toastId: "error",
    });
  }
};

// Delete MakeUp Classess

export const deleteMakeUp = async (token, makeUpId) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_BASE_URL +
        `/admin/scheduled-makeup/delete/${makeUpId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.error, {
      toastId: "error",
    });
  }
};
