import React, { useEffect, useState } from "react";
import TableNew from "./TableNew";
import { useSelector } from "react-redux";
import studentIcon from "../../../Parent/parent-assets/images/student-icon.svg"
import enrollmantIcon from "../../../Parent/parent-assets/images/enrollment-icon.svg"
import familyIcon from "../../../Parent/parent-assets/images/family-icon.svg"
import { dateFormatter } from "../../adminUtility";

const ToRender = ({ startDate, 
  endDate, 
  rollSheetCompleteData, 
  copyStartDate, 
  copyEndDate, 
  showNoDataFound,
  setScrollId}) => {
  const _tabelHeadersData = useSelector((state) => state?.torender?.tableheadersData);
  const reportData = useSelector((state) => state?.torender?.reportsData);
  const selectedReport = useSelector((state) => state?.torender?.reportSelected);
  const [activeClassRollSheet, setActiveClassRollSheet] = useState(0);
  const [days, setDays] = useState();
  const classRollSheetHandler = (index) => {
    setActiveClassRollSheet(index)
  }

  useEffect(() => {
    const dateObj1 = new Date(`${copyStartDate}`);
    const dateObj2 = new Date(`${copyEndDate}`);

    const timeDifference = dateObj2 - dateObj1;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    setDays(daysDifference);
  }, [copyStartDate, copyEndDate]);

  const formatTime = (timeString) => {
    const date = new Date(`2000-01-01T${timeString}`);
    return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
  };
  const formatDateYear = (dateString) => {
    const dateArr=dateString?.split("-");
    return `${dateArr?.[1]}/${dateArr?.[2]}`
  };
  const teachIterationHandler = (teachers) => {
    let elements = [];
   
    const teachersData=[];
    teachers?.map((item)=>{
       item?.classScheduleDetails?.map((schedule)=>{
        teachersData?.push(schedule?.teacher?.[0]?.name);
       })

    })
    const filteresTeacherData=teachersData?.filter(item=> item !==null)

    elements.push(
        <div key="teacher_list_container">
        {
          filteresTeacherData?.length >0 ? (
            filteresTeacherData?.map((filterTeacher)=>(
              <span className="rollsheet_metadata_heading">{filterTeacher + ", "}</span>
            ))
          ): <span className="rollsheet_metadata_heading">N/A</span>
        }
        </div>
    );
    return elements;
}
useEffect(()=>{
   const tableId=document.getElementById("temp_table_division_id");
   setScrollId(tableId);
},[])
  return (
    <>
      {
        (selectedReport === "Class Rollsheet" && rollSheetCompleteData?.length > 0) ?
          <div className="radio_class_rollSheet_list">
            {
              rollSheetCompleteData?.map((items, index) => (
                <div
                  className={index == activeClassRollSheet ?
                    "tabs__card_attendance-active" :
                    "tabs__card_attendance"}
                  key={index}
                  onClick={() => classRollSheetHandler(index)}
                >
                  <img className="tabs__card__img" src={(index + 1) % 2 == 0 ? studentIcon : (index + 1) % 3 === 0 ? familyIcon : enrollmantIcon} alt="" />
                  <span className="tabs__rollSheet__text">{items?.className}</span>
                </div>
              ))
            }
          </div>
          : selectedReport === "Class Rollsheet" && rollSheetCompleteData?.length === 0 && showNoDataFound ?
            <div className="No_data_found_container">
              <h1 className="no_data_found_title">
                No Data Found
              </h1>
            </div> :
            " "
      }
       <div className="temp_table_division" id="temp_table_division_id">
       {selectedReport === "Class Rollsheet" ?
        (
          rollSheetCompleteData?.map((items, index) => (
            index == activeClassRollSheet &&
            <div className="rollSheet_details" key={index}>
              <div className="rollsheet_meta_details_row">
                <div className="rollSheet_metaDetail">
                  <div>
                    <label>Class:</label>
                    <span className="rollsheet_metadata_heading">{items?.className}</span>
                  </div>
                  {/* <div> <label>Type:</label><span>Gymtime</span></div> */}
                  <div> <label>Tuition:</label><span className="rollsheet_metadata_heading">${items?.tuition?.toFixed(2)}</span></div>
                </div>
                <div className="rollSheet_metaDetail">
                  {/* <div><label>Level:</label><span>Dummy</span></div> */}
                  <div>
                    <label>Gender: </label>
                    <span className="rollsheet_metadata_heading">{items?.gender}</span></div>

                  <div style={{display:"flex"}}>
                    <label>Teacher:</label>
                    <span className="rollsheet_metadata_heading">
                      {
                        teachIterationHandler(items?.classSchedules)
                      }
                      {/* {items?.classSchedules?.map((classTime) => (
                        classTime?.classScheduleDetails?.map((details) => (
                          <span className="rollsheet_metadata_heading">
                            {`${details?.teacher[0]?.name === null ? "N/A" : details?.teacher[0]?.name + ", "}`}
                          </span>
                        ))
                      ))} */}
                    </span>
                  </div>
                  {/* <div><label>Location:</label> <span>New York</span></div> */}
                </div>
                <div className="rollSheet_metaDetail">
                  <div>
                    <label>Age:</label>
                    <span className="rollsheet_metadata_heading">
                      {items?.min_age} to {items?.max_age}
                    </span>
                  </div>
                  <div>
                    <label>Season</label>
                    <span className="rollsheet_metadata_heading">{items?.season}</span>
                  </div>
                  <div>
                    <label>Max:</label>
                    <span className="rollsheet_metadata_heading">
                      {items?.max_strength}
                    </span>
                  </div>

                  <div>
                    <label>Current: </label>
                    <span className="rollsheet_metadata_heading">{items?.classSchedules?.[0]?.students?.length}</span>
                  </div>
                  <div>
                    <label>Timing:</label><span className="rollsheet_metadata_heading">{items?.classSchedules?.map((classTime) => (
                      classTime?.classScheduleDetails?.map((details) => (
                        <span className="rollsheet_metadata_heading">{`${details?.weekday} ${formatTime(details?.start_time)} to ${formatTime(details?.end_time)} `}</span>
                      ))
                    ))}</span>
                  </div>
                </div>
                <div className="class_rollsheet_timmings">

                </div>
              </div>

              <TableNew
                headerData={_tabelHeadersData}
                bodyData={items?.classSchedules?.[0]?.students?.map((item) => ({
                  data: [
                    item?.first_name ? (
                      <span>
                        {item.first_name} {item.last_name}
                        {item.allergies && (
                          <div className="student_allergies">*{item.allergies}</div>
                        )}
                      </span>
                    ) : "-",
                    item?.dob ? dateFormatter(item?.dob) : "-",
                    item?.age ? item?.age : "-",
                    item?.gender ? item?.gender : "-",
                    item?.school_attending_with?.length > 0 ?
                      item?.school_attending_with?.map((school_attending_with, index) => (
                        <div key={`s${index}`}>
                          <span>Picking up-{school_attending_with?.attending_with}</span> <br />
                          <span> Parent- {school_attending_with?.name}</span>
                        </div>
                      ))
                      : "-",
                    item?.clients[0]?.parent1_email ? item?.clients[0]?.parent1_email : "-",
                    item?.photo_release ? (item?.photo_release === "true" ? "Yes" : "No") : "-",
                    // <h1 >Normal</h1>,
                    // Modify the structure of the JSX element to ensure an array is returned
                    <div className="attendance_box_map" key={item?.id}>
                      {item?.attendance?.map((attendance) => (
                        <div key={attendance?.date}>
                          <span className="rollsheet_date">{formatDateYear(attendance?.date)}</span>
                          <div className="attendance_box">
                            <h2 style={{ fontSize: "20px", fontWeight: "600" }}>
                              {attendance?.is_present == "true" ? "P"
                                : attendance?.is_present == " " ? " "
                                  : "A"}
                            </h2>
                          </div>
                        </div>
                      ))}
                      {/* {
                        showEmptyBox(days , item?.attendance?.length ,item?.attendance?.length>0 ? item?.attendance?.[item?.attendance?.length-1]?.date:copyStartDate)
                      } */}
                    </div>,
                  ],
                }))}
                tableName="Report"
              />
              <div className="make_class_container">
                <h1 className="makeup_trails_heading">Makeups/Dropins/Trial</h1>
                <TableNew
                  headerData={_tabelHeadersData}
                  bodyData={items?.classSchedules?.[0]?.makeupStudents?.map((item) => ({
                    data: [
                      // item?.first_name ? item?.first_name + " " + item?.last_name : "-"
                      item?.first_name ? (
                        <span>
                          {item.first_name} {item.last_name}
                          {item.allergies && (
                            <div className="student_allergies">*{item.allergies}</div>
                          )}
                        </span>
                      ) : "-"
                      ,
                      item?.dob ? item?.dob : "-",
                      item?.age ? item?.age : "-",
                      item?.gender ? item?.gender : "-",
                      item?.school_attending_with?.length > 0 ?
                        item?.school_attending_with?.map((school_attending_with, index) => (
                          <div key={`s${index}`}>
                            <span>Picking up-{school_attending_with?.attending_with}</span> <br />
                            <span> Parent- {school_attending_with?.name}</span>
                          </div>
                        ))
                        : "-",
                      item?.clients[0]?.parent1_email ? item?.clients[0]?.parent1_email : "-",
                      item?.photo_release ? (item?.photo_release === "true" ? "Yes" : "No") : "-",
                      // <h1 >Makeup</h1>,
                      // Modify the structure of the JSX element to ensure an array is returned
                      [
                        <div className="attendance_box_map">
                          {item?.attendance?.map((attendance) => (
                            <div key={attendance?.date}>
                              <span className="rollsheet_date">
                                {dateFormatter(attendance?.date)}
                              </span>
                              <div className="attendance_box">
                                <h2 style={{ fontSize: "20px", fontWeight: "600" }}>
                                  {attendance?.is_present}
                                </h2>
                              </div>
                            </div>
                          ))}
                          {/* {
                            showEmptyBox(days , item?.attendance?.length , item?.attendance?.[item?.attendance?.length-1]?.date)
                          } */}
                        </div>,
                      ],
                    ],
                  }))}
                  tableName="Report"
                />
              </div>
              <hr />
            </div>

          ))
        ) :
        <TableNew
          headerData={_tabelHeadersData}
          bodyData={reportData}
          tableName="Report"
        />
      }
       </div>
      

    </>
  )
}

export default ToRender;
