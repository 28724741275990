const ADD_CLASS_LIST=`
query GetClassesCMSWithNoFkProgram {
    getClassesCMSWithNoFkProgram {
      id
      name
    }
  }  `


  const ADD_CLASS_AGE_LIST=`
  query GetClassesCMSWithNoFkAge {
    getClassesCMSWithNoFkAge {
      id
      name
    }
  }
  `
export {ADD_CLASS_LIST,ADD_CLASS_AGE_LIST};

