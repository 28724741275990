import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { timeFormatter } from "../../adminUtility";
const AttendanceSummary = () => {
  const { attendanceSumaryData, dateCopy } = useSelector(
    (state) => state.attendanceSum
  );

  const dateFormatHandler = (value) => {
    const date = value?.split("-");
    return date ? ` ${date[1]}-${date[2]}-${date[0]}` : "-";
  };

  useEffect(() => {
    const handleBeforePrint = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener("beforeprint", handleBeforePrint);

    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
    };
  }, []);

  return (
    <>
      <div className="page">
        <h1
          style={{
            fontSize: "40px",
            fontWeight: "600",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Attendance Summary
        </h1>
        <h1
          className="rollsheet_date_heading"
          style={{
            fontWeight: "400",
            fontSize: "1.5rem",
            marginTop: "10px",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Summary of all Attendance on
          <span style={{ fontWeight: "600" }}>
            {dateFormatHandler(dateCopy?.copyStartDate)}
          </span>
          to
          <span style={{ fontWeight: "600" }}>
            {dateFormatHandler(dateCopy?.copyEndDate)}
          </span>
        </h1>

        <table className="rollsheet_pdf_container">
          <thead>
            <tr>
              <th>Date</th>
              <th>Class Name</th>
              <th>Time</th>
              <th>Student</th>
              <th>Type</th>
              <th>Memo</th>
            </tr>
          </thead>

          <tbody className="page">
            {attendanceSumaryData?.map((row) => (
              <tr>
                <td style={{ fontSize: "1rem" }}>
                  {row?.class_date ? dateFormatHandler(row?.class_date) : "-"}
                </td>
                <td style={{ fontSize: "1rem" }}>
                  {row?.className ? row?.className : "-"}
                </td>
                <td style={{ fontSize: "1rem" }}>
                  {row?.class_start_time
                    ? timeFormatter(row?.class_start_time)
                    : "-"}{" "}
                  - {row?.class_end_time ? timeFormatter(row?.class_end_time) : "-"}
                </td>
               
                <td style={{ fontSize: "1rem" }}>
                  {row?.studentName ? row?.studentName : "-"}
                </td>
                <td style={{ fontSize: "1rem" }}>
                  {row?.typeStatus ? row?.typeStatus : "-"}
                </td>
                <td style={{ fontSize: "1rem" }}>
                  {row?.memo ? row?.memo : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AttendanceSummary;
