import React, { useState } from 'react'
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close-icon.svg"
import { toast } from 'react-toastify';
import axios from 'axios';
const AddNewTeacherModal = ({ setShowAddTeacher,getTeachersData }) => {
    const token=localStorage.getItem("access_token");
    const [addTeacher, setAddTeacher] = useState({});
    const addTeacherHandler = (e) => {
        const { name, value } = e.target;
        setAddTeacher((pevState) => ({
            ...pevState,
            [name]: value
        }))
    }

    const submitTeacherHandler = async () => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_BASE_URL + `/admin/staff_cms`, addTeacher,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            if(response?.status===200 || response?.status===201){
                getTeachersData();
                toast.success("Teacher added successfully!");
                setShowAddTeacher(false);
            }
        } catch (error) {
            toast.error("Error while creating new teacher.")
             
        }
    }
    return (
        <>
            <div
                className="Admin-modal Admin-modal--open"
                id="modalCategory"
                role="dialog"
            >
                <div
                    className="Admin-modal__dialog Admin-modal__dialog--lg"
                    style={{ maxWidth: "600px" }}
                >
                    <div className="Admin-modal__content">
                        <div className="Admin-modal__head">
                            <h2 className="Admin-modal__head--title">+ Add Teacher</h2>
                            <button
                                className="Admin-modal__close"
                                onClick={() => {
                                    setShowAddTeacher(false);
                                }}
                            >
                                <CloseIcon />
                            </button>
                        </div>

                        <div class="Admin-modal__body">
                            <div className="staff_info_container_box"
                                style={{ width: "100%" }}
                            >
                                <input type="text"
                                    placeholder='Enter staff name.'
                                    className='adminform__input'
                                    name="staff_name"
                                    onChange={(e) => addTeacherHandler(e)}
                                />
                                <input type="email"
                                    placeholder='Enter staff email.'
                                    className='adminform__input'
                                    name='staff_email'
                                    onChange={(e) => addTeacherHandler(e)}
                                />
                                <input type="text"
                                    placeholder='Enter staff designation.'
                                    className='adminform__input'
                                    name='staff_role'
                                    onChange={(e) => addTeacherHandler(e)}
                                />

                            </div>
                        </div>

                        <div
                            className="Admin-modal__footer"
                            style={{ display: "flex", justifyContent: "right" }}
                        >
                            <div
                                className="flexbox"
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <button
                                    className="adminbtn adminbtn-blueOutline"
                                    type="button"
                                    onClick={() => setShowAddTeacher(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="adminbtn adminbtn-primary"
                                    onClick={() => submitTeacherHandler()}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddNewTeacherModal