import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router';
import './rollsheetPdf.css';
import { dateFormatter } from '../../adminUtility';
import "./rollsheetPotrait.css";
import { useSelector } from 'react-redux';
const RollSheetPDF = () => {
  const { rollSheetCompleteData, dateCopy } = useSelector(state => state.rollSheet);
  const { state } = useLocation();
  const contentRef = useRef(null);
  const formatTime = (timeString) => {
    const date = new Date(`2000-01-01T${timeString}`);
    return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
  };

  const formatDateYear = (dateString) => {
    const dateArr = dateString?.split("-");
    return `${dateArr?.[1]}/${dateArr?.[2]}`
  };

  useEffect(() => {
    const handleBeforePrint = () => {
      window.scrollTo(0, 0);
    }

    window.addEventListener('beforeprint', handleBeforePrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
    }
  }, []);
  const teachIterationHandler = (teachers) => {
    let elements = [];
    const teachersData = [];
    teachers?.map((item) => {
      item?.classScheduleDetails?.map((schedule) => {
        teachersData?.push(schedule?.teacher?.[0]?.name);
      })

    })
    const filteresTeacherData = teachersData?.filter(item => item !== null)
    elements.push(
      <div key="teacher_list_container">
        {
          filteresTeacherData?.length > 0 ? (
            filteresTeacherData?.map((filterTeacher) => (
              <span className="rollsheet_metadata_heading">{filterTeacher + ", "}</span>
            ))
          ) : <span className="rollsheet_metadata_heading">N/A</span>
        }
      </div>
    );
    return elements;
  }
  const dateFormatHandler = (value) => {
    const date = value?.split("-");
    return ` ${date[1]}-${date[2]}-${date[0]}`
  }

  const extractName = (value) => {

    const nameSplit = value?.split(" ");
    return `${nameSplit?.[0] !== undefined ? nameSplit?.[0] : ""} ${nameSplit?.[1] !== undefined ? nameSplit?.[1] : ""}`
  }

  function breakLine(email) {
    if (!email) return "";

    let formattedEmail = "";
    for (let i = 0; i < email.length; i += 10) {
      formattedEmail += email.slice(i, i + 10) + "\n";
    }
    return formattedEmail.slice(0, -1); // Remove the last newline character
  }

  const ageHandler = (age) => {
    age = parseInt(age);
    if (age > 23) {
      const years = Math.floor(age / 12);
      const months = age % 12;
      return `${years}.${months}yr`
    }
    return `${age} mn`
  }
  return (
    <>
      <div className="rollSheet_pdf_container" ref={contentRef}>
       
        {rollSheetCompleteData?.map((item, index) => (
          <div className='page' key={index}>
            <div className="class_pdf_container no-page-break">
              <h1 className='rollsheet_pdf_heading'>Class Rollsheet</h1>
              <div>
                <h1 className="rollsheet_date_heading" style={{ fontWeight: "400", fontSize: "1.11rem", marginTop: "10px", marginBottom: "10px", textAlign: "center" }}>
                  Summary of all rollsheet charged on
                  <span style={{ fontWeight: "600" }}>{dateFormatHandler(dateCopy?.copyStartDate)} </span>
                  to
                  <span style={{ fontWeight: "600" }}>{dateFormatHandler(dateCopy?.copyEndDate)}</span>
                </h1>
              </div>
              <h1 className="pdf_table_heading">{item?.className}

             ( <span>  <label style={{ fontWeight: 600 }}>ID:</label>{item?.id}</span>)
              </h1>
              <div className="roll_sheet_meta_data">
                <div className='meta_data_col'>
                  {/* <h1 className="class_name_meta_data">
                    <span className='_rollsheet_title'>Class:</span>
                    <span className="rollsheet_metadata_heading">{item?.className}</span>
                  </h1> */}
                  <div className='meta_data_col'>
                    <h1 className='class_name_meta_data'>
                      <span className='_rollsheet_title'>Age: </span>
                      <span className="rollsheet_metadata_heading">{item?.min_age} to {item?.max_age}</span>
                    </h1>
                    <div>
                      <label>Season</label>
                      <span className="rollsheet_metadata_heading">{item?.season}</span>
                    </div>
                    <h1 className="class_name_meta_data">
                      <span className='_rollsheet_title'>Max Strength:</span>
                      <span className="rollsheet_metadata_heading">{item?.max_strength}</span>
                    </h1>
                    <h1 className="class_name_meta_data">
                      <span className='_rollsheet_title'>Current:</span>
                      <span className="rollsheet_metadata_heading">{item?.classSchedules?.[0]?.students?.length}</span>
                    </h1>
                  </div>
                  <h1 className='class_name_meta_data'>
                    <span className='_rollsheet_title'>Tuition:</span>
                    <span className="rollsheet_metadata_heading">${item?.tuition?.toFixed(2)}</span>
                  </h1>
                  <h1 className='class_name_meta_data'>
                    <span className='_rollsheet_title'>Gender: </span>
                    <span className="rollsheet_metadata_heading">{item?.gender}</span>
                  </h1>

                </div>



                <div className='meta_data_col'>
                  <label style={{ fontWeight: 600 }}>Timing:</label>
                  <span className="rollsheet_metadata_heading">
                    {item?.classSchedules?.map((classTime) => (
                      classTime?.classScheduleDetails?.map((details) => (
                        <span key={details?.weekday} className="rollsheet_metadata_heading">
                          {`${details?.weekday?.slice(0,3)} ${formatTime(details?.start_time)} to ${formatTime(details?.end_time)}`}
                        </span>
                      ))
                    ))}
                  </span>
                 
                </div>
                {/* <div className='teacher_rollsheet_list_container_fluid' style={{ display: "flex" }}>
                  <label style={{ fontWeight: 600 }}>Teacher:</label>
                  {teachIterationHandler(item?.classSchedules)}
                </div> */}
              </div>
              <table className="rollsheet_pdf_container">
                <thead>
                  <tr className="rollsheet_pdf_container_heading_row">
                    <th className="rollsheet_pdf_data">#</th>
                    <th className="rollsheet_pdf_data">Name</th>
                    <th className="rollsheet_pdf_data age_pdf">Age</th>
                    <th className="rollsheet_pdf_data">Gender</th>
                    {/* <th className="rollsheet_pdf_data">Attending/Pick-Up with</th> */}
                    <th className="rollsheet_pdf_data email_pdf"
                      style={{ minWidth: "30px" }}
                    >{breakLine("Email")}</th>
                    <th className="rollsheet_pdf_data"> Photos</th>
                    <th className="rollsheet_pdf_data">
                      Attendance
                      <br />
                      <div className='rollsheet_header_date_caontainer'>
                        {
                          item?.classSchedules?.[0]?.students?.[0]?.attendance?.map((item) => (
                            <span className="rollsheet_date rollsheet_date_pdf" style={{ color: "black" }}>
                              {formatDateYear(item?.date)}
                            </span>
                          ))
                        }
                      </div>


                    </th>
                  </tr>
                </thead>
                <tbody className='rollsheet_tbody_container'>
                  {item?.classSchedules[0]?.students?.length > 0 ? item?.classSchedules[0]?.students?.map((student, index) => (
                    [
                      <tr
                        // className={"rollsheet_pdf_container_heading_row"}
                        key={student.id}

                      >
                        <td style={{textAlign:"center"}}>{index + 1}</td>
                        <td className='rollsheet_pdf_table_row_data student_name_pdf' style={{textAlign:"center"}}>
                          {student?.first_name + " " + student?.last_name}
                          <br />
                          {student?.allergies && <span className="student_allergies">{student?.allergies}</span>}
                        </td>
                        <td className='rollsheet_pdf_table_row_data' style={{textAlign:"center"}}>{ageHandler(student?.age)}</td>
                        <td className='rollsheet_pdf_table_row_data' style={{textAlign:"center"}}>{student?.gender ? (student?.gender?.toLowerCase() === "female" ? "F" : "M") : '-'}</td>

                        <td className='rollsheet_pdf_table_row_data'
                          style={{ minWidth: "30px",textAlign:"center" }}
                        >
                          {breakLine(student?.clients[0]?.parent1_email)}
                        </td>
                        <td className='rollsheet_pdf_table_row_data' style={{textAlign:"center"}}>
                          {student?.photo_release ? (student?.photo_release === 'true' ? 'Yes' : 'No') : '-'}</td>
                        <td className='attendence_box_class_list_container'>
                          <div className="attendance_box_map">
                            {student?.attendance?.map((attendance) => (
                              <div key={attendance?.date}>

                                <div className="attendance_box attendance_box_pdf">
                                  <h2 style={{ fontSize: '25px', fontWeight: '600' }}>
                                    {attendance?.is_present == 'true' ? 'P' : attendance?.is_present == ' ' ? ' ' : 'A'}
                                  </h2>
                                </div>
                              </div>
                            ))}
                          </div>
                        </td>
                      </tr>,
                      <tr
                        style={{ pageBreakAfter: index === 11 ? "always" : "avoid" }}
                      >
                        <td className='rollsheet_pdf_table_row_data'>Atten</td>
                        <td className='rollsheet_pdf_table_row_data' colSpan={7} >
                          {/* <span className='rollsheet_pdf_table_row_data'>Picking up-</span> */}
                          {student?.school_attending_with?.length > 0 ? (
                            student?.school_attending_with?.map((school_attending_with, index) => (
                              <span key={`s${index}`}>
                                <span className='rollsheet_pdf_table_row_data' style={{ fontSize: "1.19rem" }}>
                                  {school_attending_with?.attending_with}
                                </span>

                                <span className='rollsheet_pdf_table_row_data' style={{ fontSize: "1.19rem" }}>
                                  {extractName(school_attending_with?.name)},
                                </span>
                              </span>
                            ))
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                      </tr>
                    ]
                  )) : (
                    <tr className="rollsheet_pdf_container_heading_row">
                      <td className='pdf_no_data_found' colSpan={7}>No data found</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="make_up_class_pdf_content rollsheet_pdf_container"
              // style={{pageBreakBefore:"always"}}
              >
                <h1 className='make_up_class_pdf_content_title'>MakeUps/Dropins/Trial</h1>
                <div className="pdf_makeup_class_table_container">
                  <table className="pdf_make_class_container ">
                    <thead>
                      <tr>
                        <th className="rollsheet_pdf_data">#</th>
                        <th className="rollsheet_pdf_data">Name</th>
                        <th className="rollsheet_pdf_data ">Age</th>
                        <th className="rollsheet_pdf_data">Gender</th>

                        <th className="rollsheet_pdf_data"   style={{ minWidth: "30px" }}>Email</th>
                        <th className="rollsheet_pdf_data">Photos</th>
                        <th className="rollsheet_pdf_data">Attendance</th>
                      </tr>
                    </thead>
                    <tbody className='rollsheet_tbody_container'>
                      {item?.classSchedules[0]?.makeupStudents?.length > 0 ? item?.classSchedules[0]?.makeupStudents?.map((student, index) => (
                        [<tr className="rollsheet_pdf_container_heading_row" key={student.id}>
                          <td style={{textAlign:"center"}}>{index + 1}</td>
                          <td className='rollsheet_pdf_table_row_data pdf_name_student' style={{textAlign:"center"}}>
                            {student?.first_name + " " + student?.last_name}
                            {/* <div className="student_allergies">*{student?.allergies}</div> */}
                          </td>
                          <td className='rollsheet_pdf_table_row_data' style={{textAlign:"center"}}>{ageHandler(student?.age)}</td>
                          <td className='rollsheet_pdf_table_row_data' style={{textAlign:"center"}}>{student?.gender ? (student?.gender?.toLowerCase() === "female" ? "F" : "M") : '-'}</td>

                          <td className='rollsheet_pdf_table_row_data' style={{textAlign:"center"}}>{breakLine(student?.clients[0]?.parent1_email)}</td>
                          <td className='rollsheet_pdf_table_row_data'
                        style={{textAlign:"center"}}
                          >
                            {student?.photo_release
                              ? (student?.photo_release === 'true' ? 'Yes' : 'No') : '-'}
                          </td>
                          <td>
                            <div className="attendance_box_map">
                              {student?.attendance?.map((attendance) => (
                                <div key={attendance?.date}>
                                  {/* <span className="rollsheet_date rollsheet_date_pdf">{formatDateYear(attendance?.date)}</span> */}
                                  <div className="attendance_box attendance_box_pdf">
                                    <h2 style={{ fontSize: '25px', fontWeight: '600' }}>
                                      {attendance?.is_present}
                                    </h2>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </td>
                        </tr>,

                        <tr>
                          <td className='rollsheet_pdf_table_row_data'>Atten</td>
                          <td className='rollsheet_pdf_table_row_data' colSpan={7}>

                            {student?.school_attending_with?.length > 0 ? (
                              student?.school_attending_with?.map((school_attending_with, index) => (
                                <span key={`s${index}`}>
                                  <span className='rollsheet_pdf_table_row_data'>{school_attending_with?.attending_with}</span>
                                 
                                  <span className='rollsheet_pdf_table_row_data'>
                                    {extractName(school_attending_with?.name)},
                                  </span>
                                </span>
                              ))
                            ) : (
                              <span>-</span>
                            )}
                          </td>
                        </tr>]
                      )) : (
                        <tr className="rollsheet_pdf_container_heading_row">
                          <td className='pdf_no_data_found' colSpan={7}>No data found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <hr />
            </div>
          </div>
        ))}
      </div>
    </>

  );
};

export default RollSheetPDF;
