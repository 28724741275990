import React from "react";
import { useEffect } from "react";
import { setLoading } from "../../../../reducers/loadingSlice";
import { toast } from "react-toastify";
import { setSuccessModalContent } from "../../../../reducers/userSlice";
import { getAllStudent } from "../../../api/studentApi";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { isValidName, isValidUSZipCode } from "../../../utility/validation";
import { getStates } from "../../../../Admin/adminApi/users";
import DatePicker from "react-datepicker";
import { AgeCalculationFunction } from "../../../utility/postRequests";
import LoaderComponent from "../../../../Admin/components/LoaderComponent";

function CreateStudent({ setSuccessModal, setTemp, activeTab }) {
  // Parent and Student Account..
  const [students, setStudents] = useState([1]);
  const loader = useSelector((state) => state);

  const dispatch = useDispatch();
  // FORM LOGICS AND API

  // STUDENT PROFILE FORMS -------------------------->
  const [studentFormData, setStudentFormData] = useState([
    {
      firstName: "",
      lastName: "",
      dob: "",
      gender: "Male",
      allergies: "",
      address: "",
      age: "",
      doctor_name: "",
      doctor_phone: "",
      parent_signature: "",
      emergency_phone: "",
      emergency_name: "",
      vaccination: "YES",
      photo_release: "",
      school_attending: [{ name: "", attending_with: "" }],
      image: "",
      zip: "",
      state: "",
    },
  ]);
  const [getAllStateApi, setGetAllStateApi] = useState();

  const resetData = () => {
    setStudentFormData([
      {
        firstName: "",
        lastName: "",
        dob: "",
        gender: "Male",
        allergies: "",
        address: "",
        age: "",
        doctor_name: "",
        doctor_phone: "",
        parent_signature: "",
        emergency_phone: "",
        emergency_name: "",
        vaccination: "",
        photo_release: "",
        school_attending: [{ name: "", attending_with: "" }],
        image: "",
      },
    ]);
  };

  // ERROR HANDLING ..
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    allergies: "",
    address: "",
    age: "",
    doctor_name: "",
    doctor_phone: "",
    parent_signature: "",
    emergency_phone: "",
    emergency_name: "",
    vaccination: "",
    photo_release: "",
    zip: "",
    school_attending: [{ name: "", attending_with: "" }],
  });

  const [totalAge, setTotalAge] = useState();

  function calculateAge(dob) {
    let today = new Date();
    let birthDate = new Date(dob);
    let ageInYears = today.getFullYear() - birthDate.getFullYear();
    let monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      ageInYears--;
      monthDiff += 12;
    }

    if (ageInYears < 2) {
      let totalMonths = ageInYears * 12 + monthDiff;
      // return `${totalMonths} months`;
      if (totalAge === 0) {
        setTotalAge(monthDiff);
      } else {
        setTotalAge(totalMonths);
        return { CalculatedValue: monthDiff, key: "months" };
      }
    } else {
      setTotalAge(ageInYears);
      return { CalculatedValue: ageInYears, key: "years" };

      // return `${ageInYears} years`;
    }
  }

  // Phone number Formatter

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    const resultant = studentFormData.map((x, i) => {
      if (index === i) {
        if (name === "dob") {
          const data = calculateAge(value);
          if (
            (data?.CalculatedValue !== null ||
              data?.CalculatedValue !== undefined) &&
            data?.key
          ) {
            const { CalculatedValue, key } = calculateAge(value);
            return {
              ...x,
              [name]: value,
              ["age"]: `${CalculatedValue} ${key}`,
            };
          }
        }
        return { ...x, [name]: value };
      } else {
        return x;
      }
    });

    setStudentFormData(resultant);

    switch (name) {
      case "doctor_name":
        if (!isValidName(e.target.value)) {
          setErrors({
            ...errors,
            [name]: "Doctor's name doesn't contains number  .",
          });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;

      case "zip":
        if (!isValidUSZipCode(e.target.value)) {
          setErrors({ ...errors, [name]: "Zip code must be 5-digit. " });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;

      case "firstName":
        if (!isValidName(value)) {
          setErrors({
            ...errors,
            [name]: "First name does not contain numbers",
          });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;
      case "emergency_name":
        if (!isValidName(value)) {
          setErrors({
            ...errors,
            [name]: "Emergency name does not contain numbers",
          });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;

      case "lastName":
        if (!isValidName(value)) {
          setErrors({
            ...errors,
            [name]: "Last name does not contain numbers",
          });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;
      case "photo_release":
        const photoReleaseValue = value === "Yes"; // Convert "Yes" to true and "No" to false
        setStudentFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[index] = {
            ...updatedFormData[index],
            [name]: photoReleaseValue,
          };
          return updatedFormData;
        });
        setErrors({ ...errors, [name]: "" });
        break;
      case "parent_signature":
        if (!value) {
          setErrors({
            ...errors,
            [name]: "Please enter the signature.",
          });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;

      default:
        setErrors({ ...errors, [name]: "" });
    }
  };

  const addAttendingWith = (e, index) => {
    const newAttendingWith = { attending_with: "", name: "" };
    const resultant = studentFormData.map((x, i) => {
      if (index === i) {
        x?.school_attending.push(newAttendingWith);
        return x;
      } else {
        return x;
      }
    });
    setStudentFormData(resultant);
  };

  const handleArrayChange = (e, index, i) => {
    e.preventDefault();

    const { name, value } = e?.target;

    const resultant = studentFormData.map((x, ind) => {
      if (index === ind) {
        const updatedSchoolAttending = [...x.school_attending];
        updatedSchoolAttending[i][name] = value;
        x.school_attending = updatedSchoolAttending;
        return x;
      } else {
        return x;
      }
    });
    setStudentFormData(resultant);
  };

  const handleFileChange = (e, index) => {
    e.preventDefault();
    try {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const updated = [...studentFormData];
          updated[index].image = reader.result;
          setStudentFormData(updated);
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      return error;
    }
  };

  // GET STUDENT API

  const getAllStudntData = async () => {
    let token = localStorage.getItem("access_token");
    const allStudentResponse = await getAllStudent(token);
    setStudentFormData(allStudentResponse?.getStudents);
    setTemp((prev) => !prev);
  };
  // CREATE API

  const createStudent = async (e, i) => {
    e.preventDefault();

    const requiredFields = [
      "firstName",
      "lastName",
      "dob",
      "gender",
      "allergies",
      "address",
      "doctor_name",
      "doctor_phone",
      "parent_signature",
      "emergency_phone",
      "emergency_name",
      "zip",
      "state",
    ];

    let hasEmptyField = false;
    const missingFields = [];

    // Reset all errors before starting validation
    const newErrors = {};

    // Check for missing fields and collect error messages
    requiredFields.forEach((field) => {
      if (!studentFormData[i][field]?.trim()) {
        missingFields.push(field);
        newErrors[field] = `Please enter the ${field.replace(
          /_/g,
          " "
        )} field.`;
        hasEmptyField = true;
      }
    });

    // If there are missing fields, show a consolidated error message and set errors
    if (hasEmptyField) {
      const formattedMissingFields = missingFields
        .map((field) => field.replace(/_/g, " "))
        .join(", ");

      toast.error(
        `Please fill out the following fields: ${formattedMissingFields}`
      );

      setErrors({ ...errors, ...newErrors });

      return;
    }

    // Clear common and field-specific errors if validation passes
    setErrors({
      firstName: "",
      lastName: "",
      dob: "",
      gender: "",
      allergies: "",
      address: "",
      doctor_name: "",
      doctor_phone: "",
      parent_signature: "",
      emergency_phone: "",
      emergency_name: "",
      zip: "",
      common: "",
      state: "",
    });

    if (studentFormData[i].school_attending[0].attending_with.trim() === "") {
      studentFormData[i].school_attending[0].attending_with = "CareGiver";
    }

    const studentData = {
      studentDetails: {
        first_name: studentFormData[i].firstName,
        last_name: studentFormData[i].lastName,
        age: studentFormData[i].age,
        dob: studentFormData[i].dob,
        gender: studentFormData[i].gender,
        allergies: studentFormData[i].allergies,
        doctor_name: studentFormData[i].doctor_name,
        doctor_phone: studentFormData[i].doctor_phone,
        emergency_name: studentFormData[i].emergency_name,
        emergency_phone: studentFormData[i].emergency_phone,
        vaccination: true,
        photo_release: true,
        parent_signature: studentFormData[i].parent_signature,
        image: studentFormData[i].image,
        address: studentFormData[i]?.address,
        state: studentFormData[i]?.state,
        zipcode: studentFormData[i].zip,
      },
      schoolAttendingWithDetails: studentFormData[i].school_attending,
    };

    const token = localStorage.getItem("access_token");
    dispatch(setLoading(true));
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/student/create",
        studentData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTimeout(() => {
        dispatch(setLoading(false));
      }, 500);
      dispatch(setSuccessModalContent(response?.data?.data));
      setSuccessModal(true);
      resetData();
      await getAllStudntData();
      setTimeout(() => {
        setSuccessModal(false);
      }, 2000);
      resetData();
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
      console.error(error);
    }
    getAllStudent(token);
  };

  const addAnotherStudent = () => {
    setStudents([...students, {}]);
    setStudentFormData([
      ...studentFormData,
      {
        firstName: "",
        lastName: "",
        dob: "",
        gender: "Male",
        allergies: "",
        address: "",
        age: "",
        doctor_name: "",
        doctor_phone: "",
        parent_signature: "",
        emergency_phone: "",
        emergency_name: "",
        vaccination: "YES",
        photo_release: "",
        school_attending: [{ attending_with: "", name: "" }],
        image: "",
      },
    ]);
  };

  const deleteStudentForm = (index) => {
    setStudents((prevStudents) => {
      const updatedStudents = [...prevStudents];
      updatedStudents.splice(index, 1);
      return updatedStudents;
    });
  };

  // all States
  useEffect(() => {
    async function getAllStates() {
      const response = await getStates();
      setGetAllStateApi(response?.data?.getStates);
    }
    // if (activeTab == "tab2") {
    getAllStates();
    // }
  }, []);

  const CustomDatePickerInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      className="form__input"
      placeholder="Select dob"
      value={value}
      onClick={onClick}
      ref={ref}
      style={{ cursor: "pointer" }}
    />
  ));

  return (
    <>
      {loader?.loading?.loading && <LoaderComponent />}

      {students.map((val, index) => {
        return (
          <form
            className="form parentForm2"
            style={{ marginTop: "5%" }}
            onSubmit={(e) => {
              createStudent(e, index);
            }}
            key={index}
          >
            <div className="form__card">
              <div className="form__header">
                <h4 className="title title--h4">Add Student</h4>

                <div className="btnActions">
                  <button
                    className="btn btn--primary"
                    type="button"
                    onClick={addAnotherStudent}
                  >
                    + Add Another Student
                  </button>
                </div>
              </div>

              <div className="profileBox">
                <div className="profileBox__info">
                  <div className="pic">
                    <img
                      className="pic__img"
                      src={studentFormData[index]?.image}
                      alt=""
                    />
                  </div>
                  <div className="detail">
                    <h3 className="name">
                      {studentFormData[index].firstName} &nbsp;
                      {studentFormData[index].lastName}
                    </h3>
                    <p className="para">{studentFormData[index]?.age}</p>
                  </div>
                </div>
                <div className="profileBox__actions">
                  <div
                    className="profileBox__actions__item"
                    style={{ display: "flex" }}
                  >
                    <input
                      className="form__input form__input--hidden"
                      type="file"
                      id="fileupload-324234234234"
                      onChange={(e) => handleFileChange(e, index)}
                    />
                    <label
                      htmlFor="fileupload-324234234234"
                      className="btn btn--primary"
                    >
                      Upload New Photo
                    </label>
                  </div>
                  {index > 0 && (
                    <div className="btnActions">
                      <button
                        className="btn btn--primary btn--primary--outline"
                        type="button"
                        onClick={() => deleteStudentForm(index)}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {/* EDITING FORM . */}

              <div className="form__group form__group--flex">
                <div className="form__group__col">
                  <label htmlFor="">
                    First Name <span className="important_field">*</span>
                  </label>
                  <input
                    className="form__input"
                    id="Name"
                    type="text"
                    placeholder="First name *"
                    name="firstName"
                    value={studentFormData[index].firstName}
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                  />
                  <div className="error-message" style={{ color: "red" }}>
                    {errors.firstName}
                  </div>
                </div>

                <div className="form__group__col">
                  <label htmlFor="">
                    Last Name <span className="important_field">*</span>
                  </label>
                  <input
                    className="form__input"
                    id="Lastname"
                    type="text"
                    placeholder="Last name"
                    name="lastName"
                    value={studentFormData[index].lastName}
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                  />
                  <style></style>
                  <div className="error-message" style={{ color: "red" }}>
                    {errors.lastName}
                  </div>
                </div>
              </div>
              <div className="form__group form__group--flex">
                {/* DOB  */}
                <div className="form__group__col">
                  <label htmlFor="">
                    DOB <span className="important_field">*</span>
                  </label>
                  <DatePicker
                    className="form__input"
                    id="Date"
                    placeholderText="YY/MM/DD *"
                    maxDate={new Date()}
                    selected={
                      studentFormData[index].dob
                        ? new Date(studentFormData[index].dob)
                        : null
                    }
                    onChange={(date) => {
                      handleChange(
                        {
                          target: {
                            name: "dob",
                            value: date?.toISOString().split("T")[0],
                          },
                        },
                        index
                      );
                    }}
                    customInput={<CustomDatePickerInput />}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  <div className="error-message" style={{ color: "red" }}>
                    {errors.dob}
                  </div>
                </div>
                {/* GENDER  */}
                <div className="form__group__col">
                  <label htmlFor="">
                    Gender <span className="important_field">*</span>
                  </label>
                  <select
                    id="Gender"
                    className="form__select"
                    name="gender"
                    value={studentFormData[index].gender || "Gender"}
                    onChange={(e) => handleChange(e, index)}
                  >
                    <option disabled>Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              {/* AGE  */}
              <div className="form__group">
                <label htmlFor="">
                  Age <span className="important_field">*</span>
                </label>
                <input
                  className="form__input"
                  id="Na"
                  type="text"
                  placeholder="Age *"
                  name="age"
                  // value={studentFormData[index].age}
                  value={AgeCalculationFunction(studentFormData[index].dob)}
                />
                <div className="error-message" style={{ color: "red" }}>
                  {errors.age}
                </div>
              </div>
              {/* ALLERGIES  */}
              <div className="form__group">
                <label htmlFor="">
                  Allergies <span className="important_field">*</span>
                </label>
                <input
                  className="form__input"
                  id="Na"
                  type="text"
                  placeholder="Known allergies/physical limitations/OT/PT (if none, type N/A) *"
                  name="allergies"
                  value={studentFormData[index].allergies}
                  onChange={(e) => {
                    handleChange(e, index);
                  }}
                />
                <div className="error-message" style={{ color: "red" }}>
                  {errors.allergies}
                </div>
              </div>
              {/* ADDRESS  */}
              <div className="form__group">
                <label htmlFor="">
                  Address <span className="important_field">*</span>
                </label>
                <input
                  className="form__input"
                  id="Address"
                  type="text"
                  value={studentFormData[index].address}
                  placeholder="Address"
                  name="address"
                  onChange={(e) => {
                    handleChange(e, index);
                  }}
                />
                <div className="error-message" style={{ color: "red" }}>
                  {errors.address}
                </div>
              </div>
              <div className="form__card">
                {/* SCHOOL ATTENDING  */}

                <div className="form__row">
                  <div className="form__header form__header--justifynone">
                    <h4 className="title title--h4">Picking Up -</h4>

                    <div className="btnActions">
                      <button
                        className="btn btn--primary"
                        type="button"
                        onClick={(e) => {
                          addAttendingWith(e, index);
                        }}
                      >
                        + Add Another Person
                      </button>
                    </div>
                  </div>

                  {/* ATTENDENDING DETAILS  */}
                  {studentFormData[index]?.school_attending?.map(
                    (attending, i) => (
                      <div className="form__group form__group--flex" key={i}>
                        <div className="form__group__col">
                          <label htmlFor="">Select Relation </label>
                          <select
                            className="form__select"
                            name={`attending_with`}
                            value={attending?.attending_with}
                            onChange={(e) => handleArrayChange(e, index, i)}
                          >
                            <option value="" selected disabled>
                              {" "}
                              Select Relationship
                            </option>
                            <option value="CareGiver">Care taker</option>
                            <option value="Parent">Parent</option>
                          </select>
                        </div>

                        <div className="form__group__col">
                          <label htmlFor=""> Name</label>
                          <input
                            className="form__input"
                            type="text"
                            placeholder="Name (must be different than emergency contact) *"
                            name={`name`}
                            value={attending.name}
                            onChange={(e) => handleArrayChange(e, index, i)}
                          />
                        </div>
                      </div>
                    )
                  )}

                  {/* ZIP AND STATE  */}
                  <div className="form__group form__group--flex">
                    <div className="form__group__col">
                      <label htmlFor="">
                        State <span className="important_field">*</span>
                      </label>
                      <select
                        name="state"
                        className="form__select"
                        // value={studentFormData[index].state}
                        onChange={(e) => {
                          handleChange(e, index);
                        }}
                        style={{ width: "100%", height: "50px" }}
                      >
                        <option value="" selected disabled>
                          Select State
                        </option>
                        {getAllStateApi &&
                          getAllStateApi.map((state, index) => (
                            <option key={index} value={state?.name}>
                              {state?.name}
                            </option>
                          ))}
                      </select>
                      <div className="error-message" style={{ color: "red" }}>
                        {errors.state}
                      </div>
                    </div>
                    <div className="form__group__col">
                      <label htmlFor="">
                        Zip <span className="important_field">*</span>
                      </label>
                      <input
                        className="form__input"
                        id="Pzip"
                        type="text"
                        placeholder="Zip *"
                        name="zip"
                        onChange={(e) => {
                          handleChange(e, index);
                        }}
                      />
                      <div className="error-message" style={{ color: "red" }}>
                        {errors.zip}
                      </div>
                    </div>
                  </div>

                  {/* DOCTOR PHONE AND NAME   */}
                  <div className="form__group form__group--flex">
                    <div className="form__group__col">
                      <label htmlFor="">
                        Doctor Name <span className="important_field">*</span>
                      </label>
                      <input
                        className="form__input"
                        id="Pdoctorname"
                        type="text"
                        placeholder="Doctor’s Name *"
                        name="doctor_name"
                        value={studentFormData[index].doctor_name}
                        onChange={(e) => {
                          handleChange(e, index);
                        }}
                      />
                      <div className="error-message" style={{ color: "red" }}>
                        {errors.doctor_name}
                      </div>
                    </div>
                    <div className="form__group__col">
                      <label htmlFor="">
                        Doctor Phone <span className="important_field">*</span>
                      </label>
                      <input
                        className="form__input"
                        id="Pdoctorphone"
                        type="text"
                        placeholder="Doctor’s Phone *"
                        name="doctor_phone"
                        maxlength={14}
                        pattern="[0-15 ()-]*"
                        value={studentFormData[index].doctor_phone}
                        onChange={(e) => {
                          handleChange(e, index);
                        }}
                      />
                      <div className="error-message" style={{ color: "red" }}>
                        {errors.doctor_phone}
                      </div>
                    </div>
                  </div>

                  {/* EMERGENCY NAME AND PHONE  */}
                  <div className="form__group form__group--flex">
                    <div className="form__group__col">
                      <label htmlFor="">
                        Emergency Contact's Name{" "}
                        <span className="important_field">*</span>
                      </label>
                      <input
                        className="form__input"
                        id="Pecontact"
                        type="text"
                        placeholder="Emergency Contact's Name *"
                        name="emergency_name"
                        value={studentFormData[index]?.emergency_name}
                        onChange={(e) => {
                          handleChange(e, index);
                        }}
                      />
                      <div className="error-message" style={{ color: "red" }}>
                        {errors.emergency_name}
                      </div>
                    </div>
                    <div className="form__group__col">
                      <label htmlFor="">
                        Emergency Contact's Phone
                        <span className="important_field">*</span>
                      </label>
                      <input
                        className="form__input"
                        id="Pephone"
                        type="text"
                        placeholder="Emergency Contact's Phone *"
                        name="emergency_phone"
                        maxlength={16}
                        pattern="[0-9 ()-]*"
                        value={studentFormData[index]?.emergency_phone}
                        onChange={(e) => {
                          handleChange(e, index);
                        }}
                      />
                      <div className="error-message" style={{ color: "red" }}>
                        {errors.emergency_phone}
                      </div>
                    </div>
                  </div>

                  {/* PHOTO RELESE  */}

                  <div className="form__group">
                    <h4
                      className="title title--h4"
                      style={{ background: "none" }}
                    >
                      Agree to Photo Release * (View Policy)
                    </h4>
                  </div>

                  <div className="form__radio">
                    <div className="form__radio__option">
                      <input
                        className="form__radio__input"
                        type="radio"
                        id="yes"
                        name="photo_release"
                        value={studentFormData[index].photo_release}
                        onChange={(e) => {
                          handleChange(e, index);
                        }}
                      />
                      <label htmlFor="yes" className="form__radio__label">
                        Yes
                      </label>
                    </div>
                    <div className="form__radio__option">
                      <input
                        className="form__radio__input"
                        type="radio"
                        id="No"
                        name="photo_release"
                        value="No"
                        onChange={(e) => {
                          handleChange(e, index);
                        }}
                      />
                      <label htmlFor="no" className="form__radio__label">
                        No
                      </label>
                    </div>
                  </div>
                  <div className="error-message" style={{ color: "red" }}>
                    {errors.photo_release}
                  </div>
                  {/* SIGNATUYRE  */}
                  <div className="form__group">
                    {" "}
                    <label htmlFor="">
                      Parent Signature{" "}
                      <span className="important_field">*</span>
                    </label>
                    <textarea
                      id="ElectronicSignature"
                      className="form__textarea"
                      placeholder="Electronic Signature *"
                      name="parent_signature"
                      value={studentFormData[index].parent_signature}
                      onChange={(e) => {
                        handleChange(e, index);
                      }}
                    ></textarea>
                  </div>
                  <div className="error-message" style={{ color: "red" }}>
                    {errors.parent_signature}
                  </div>
                </div>
                <div className="form__group form__group--right">
                  <button className="btn btn--primary" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        );
      })}
    </>
  );
}

export default CreateStudent;
