import axios from 'axios'
import React from 'react'
import { toast } from 'react-toastify'

const BlogModalDelete = ({setDeleteModal,deleteBlog,setActiveAccordion}) => {
    const token = localStorage.getItem("access_token");
    const handleDeleteBlog=async()=>{
      const blogData = {
      is_active_blog: 0,
      blogId: deleteBlog?.id
    }
    const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/blog_delete`, blogData, {
           headers:{
            'Authorization': `Bearer ${token}`,
           }
      }
      )
      if(response?.status===200){
        toast.success(` ${deleteBlog?.title} deleted successfully`);
      }
      else{
        toast.error(` ${deleteBlog?.title} can't be deleted now,please try later`);
      }
      setDeleteModal(false);
      setActiveAccordion(null);
    }
  return (
    <>
       <div
              className="Admin-modal Admin-modal--open"
              id="modalDelete"
              role="dialog"
            >
              <div className="Admin-modal__dialog Admin-modal__dialog--sm">
                <div className="Admin-modal__content">
                  <div className="Admin-modal__head">
                    <h2 className="Admin-modal__head--title">{`Delete Blog ${deleteBlog?.title}`}</h2>
                    <button
                      className="Admin-modal__close"
                      onClick={() => {
                        setDeleteModal(false);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_1_9631)">
                          <path
                            d="M14.0728 6.92854L14.0728 6.92856C14.4017 7.26332 14.4017 7.79324 14.0728 8.128L14.0713 8.12951L14.0713 8.1295L11.6968 10.5057L14.0354 12.8405C14.3655 13.1697 14.3655 13.7084 14.0354 14.0376C13.706 14.3667 13.1717 14.3663 12.8421 14.0376L12.8418 14.0373L10.5035 11.6969L8.14748 14.0551L8.14649 14.0561C7.81513 14.3841 7.27944 14.3841 6.94808 14.0561L6.94561 14.0536L6.94562 14.0536C6.61805 13.7202 6.61475 13.1828 6.94753 12.8523C6.94764 12.8521 6.94775 12.852 6.94785 12.8519L9.30329 10.4943L6.9652 8.15951C6.63514 7.8303 6.63511 7.2917 6.9651 6.96245C6.96513 6.96242 6.96516 6.96238 6.9652 6.96235L7.14175 7.13935C7.37272 6.90779 7.74915 6.90779 7.98131 7.13935L14.0728 6.92854ZM14.0728 6.92854L14.0703 6.92609C13.739 6.5981 13.2039 6.5981 12.8725 6.92609L12.8716 6.92703L10.4965 9.30317L14.0728 6.92854ZM10.5 18.5625C6.04722 18.5625 2.4375 14.9516 2.4375 10.5C2.4375 6.04836 6.04722 2.4375 10.5 2.4375C14.9528 2.4375 18.5625 6.04836 18.5625 10.5C18.5625 14.9516 14.9528 18.5625 10.5 18.5625ZM10.5 0.75C5.11499 0.75 0.75 5.11315 0.75 10.5C0.75 15.8869 5.11499 20.25 10.5 20.25C15.885 20.25 20.25 15.8869 20.25 10.5C20.25 5.11315 15.885 0.75 10.5 0.75Z"
                            fill="white"
                            stroke="white"
                            strokeWidth="0.5"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_9631">
                            <rect width="21" height="21" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                  <div className="Admin-modal__body">
                    <div className="Admin-modal__warning">
                      <svg
                        width="46"
                        height="46"
                        viewBox="0 0 46 46"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.166 21.0833V32.5833"
                          stroke="#FF0000"
                          strokeWidth="4.31964"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M26.834 21.0833V32.5833"
                          stroke="#FF0000"
                          strokeWidth="4.31964"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.66602 13.4167H38.3327"
                          stroke="#FF0000"
                          strokeWidth="4.31964"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.5 13.4167H23H34.5V34.5001C34.5 37.6758 31.9257 40.2501 28.75 40.2501H17.25C14.0744 40.2501 11.5 37.6758 11.5 34.5001V13.4167Z"
                          stroke="#FF0000"
                          strokeWidth="4.31964"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M17.25 9.58333C17.25 7.46624 18.9662 5.75 21.0833 5.75H24.9167C27.0338 5.75 28.75 7.46624 28.75 9.58333V13.4167H17.25V9.58333Z"
                          stroke="#FF0000"
                          strokeWidth="4.31964"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>This Blog Will Be Deleted. Are You Sure You Want to Delete This Blog?</span>
                    </div>
                  </div>
                  <div className="Admin-modal__footer">
                    <div
                      className="admin-button__group"
                      style={{ display: "flex" }}
                    >
                      <button
                        className="adminbtn adminbtn-blueOutline"
                        onClick={() => {
                            setDeleteModal(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="adminbtn adminbtn-danger"
                        onClick={() => {
                          handleDeleteBlog();
                        }}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.66602 7.33325V11.3333"
                            stroke="white"
                            strokeWidth="1.50248"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.33398 7.33325V11.3333"
                            stroke="white"
                            strokeWidth="1.50248"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2.66602 4.66675H13.3327"
                            stroke="white"
                            strokeWidth="1.50248"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4 4.66675H8H12V12.0001C12 13.1047 11.1046 14.0001 10 14.0001H6C4.89543 14.0001 4 13.1047 4 12.0001V4.66675Z"
                            stroke="white"
                            strokeWidth="1.50248"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 3.33333C6 2.59695 6.59695 2 7.33333 2H8.66667C9.40307 2 10 2.59695 10 3.33333V4.66667H6V3.33333Z"
                            stroke="white"
                            strokeWidth="1.50248"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
        </div>
    </>
  )
}

export default BlogModalDelete