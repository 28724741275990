import React, { useEffect, useState } from "react";
import footerLogoIcon from "../parent-assets/images/birthday-partybook-img01.png";
// social Icons
import { ReactComponent as GymTimeLogo } from "../parent-assets/images/gymtime_logo_2024.svg";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SvgIcon from "../parent-assets/images";
import { getSubPrograms } from "../../Admin/adminApi/subProgram";
import axios from "axios";

function Footer() {
  const token = localStorage.getItem("access_token");
  const [programClassData, setProgramClassData] = useState([]);
  const [footerDetails, setFooterDetails] = useState();
  const [contactUsDetails, setcontactUsDetails] = useState();
  const navigate = useNavigate();

  // getting current location
  const location = useLocation();
  const params = useParams();

  const getBackgroundColor = () => {
    const path = location.pathname;

    if (location.pathname === "/") {
      return "#593F98";
    }
    if (location.pathname === "/enrichment-combo/pep+/pep-class") {
      return "#00BBE3";
    }

    if (path.startsWith("/browse-by-program/") && params.id && params.classId) {
      if (params.id && params.classId) {
        return "#00BBE3";
      }
    }

    if (location.pathname === "/about" || location.pathname === "/future") {
      return "#C3D940";
    }

    return "#FFFFFF";
  };

  const footerStyle = {
    background: getBackgroundColor(),
  };

  useEffect(() => {
    getProgramClasses();
    getFooterDetails();
    getContactUsDetails();
  }, []);

  const getContactUsDetails = async () => {
    try {
      const data = "";
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + `/admin/get_contactus_footercms`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setcontactUsDetails(response?.data?.data);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const getProgramClasses = async () => {
    const response = await getSubPrograms(token);
    setProgramClassData(response);
  };

  const getFooterDetails = async () => {
    try {
      const data = "";
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + `/admin/getfootercms`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFooterDetails(response?.data?.data);
    } catch (error) {
      console.error("Error", error);
    }
  };
  const d = new Date();

  return (
    <div style={footerStyle} className="footerOuterWrapper">
      <div className="container footer-squiggle"></div>
      <footer className="footer">
        <div className="footerInner">
          <div
            className="footerTextWrap"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="footer__top">
              <div className="container">
                <div className="footer__top__flexbox">
                  <div className="footer__col">
                    <div className="footer__logo">
                      <Link to="/">
                        <GymTimeLogo />
                      </Link>
                    </div>
                    <div className="footer__text">
                      {footerDetails?.map((item) => (
                        <p className="footer__text__para" key={item?.id}>
                          {item?.description}
                        </p>
                      ))}
                    </div>
                    <ul className="footer__socialLink">
                      <li className="footer__socialLink__item">
                        <Link
                          className="footer__socialLink__link"
                          to="https://www.facebook.com/Gymtimekids/"
                          target="blank"
                        >
                          <SvgIcon type={"Facebook"} />
                        </Link>
                      </li>
                      <li className="footer__socialLink__item">
                        <Link
                          className="footer__socialLink__link"
                          to="https://www.google.com/maps?q=Gymtime+Rhythm+&+Glues+Inc"
                          target="blank"
                        >
                          <SvgIcon type={"LocationIcon"} />
                        </Link>
                      </li>
                      <li className="footer__socialLink__item">
                        <Link
                          className="footer__socialLink__link"
                          to="https://www.instagram.com/gymtimekids/"
                          target="blank"
                        >
                          <SvgIcon type={"InstagramIcon"} />
                        </Link>
                      </li>
                      <li className="footer__socialLink__item">
                        <Link
                          className="footer__socialLink__link"
                          to="https://www.youtube.com/user/NYCGymtime"
                          target="blank"
                        >
                          <SvgIcon type={"YoutubeIcon"} />
                        </Link>
                      </li>
                      <li className="footer__socialLink__item">
                        <Link className="footer__socialLink__link" to="/blog">
                          <SvgIcon type={"HomeIcon"} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footer__col">
                    <h4
                      className="footer__title"
                      onClick={() => {
                        navigate("/contact");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Contact Us
                    </h4>
                    {contactUsDetails?.map((item) => (
                      <ul className="footer__contact" key={item?.id}>
                        <li className="footer__contact__item">
                          <Link
                            className="footer__contact__link"
                            to={`tel:${item?.phone}`}
                          >
                            <span className="footer__contact__icon">
                              <SvgIcon type={"PhoneIcon"} />
                            </span>
                            <span className="footer__contact__title">
                              Phone
                            </span>
                            <span className="footer__contact__title">
                              {" "}
                              {item?.phone}
                            </span>
                          </Link>
                        </li>

                        <li className="footer__contact__item">
                          <Link
                            className="footer__contact__link"
                            to={`mailto:${item?.email}`}
                          >
                            <span className="footer__contact__icon">
                              <SvgIcon type={"EnvelopeIcon"} />
                            </span>
                            <span className="footer__contact__title">
                              Email
                            </span>
                            <span className="footer__contact__title">
                              {item?.email}
                            </span>
                          </Link>
                        </li>

                        <li className="footer__contact__item">
                          <Link className="footer__contact__link" to="/contact">
                            <span className="footer__contact__icon">
                              <SvgIcon type={"PinIcon"} />
                            </span>
                            <span className="footer__contact__title">
                              Address
                            </span>
                            <span className="footer__contact__title">
                              {" "}
                              {item?.address}
                            </span>
                          </Link>
                        </li>
                      </ul>
                    ))}
                  </div>
                  <div className="footer__col">
                    <h4
                      className="footer__title"
                      onClick={() => {
                        navigate("/browse-by-program-options");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Activities
                    </h4>
                    <ul className="footer__menu">
                      <li className="footer__menu__item">
                        <Link className="footer__menu__link" to={"/camp/175"}>
                          Summer Camp
                        </Link>
                      </li>
                      <li className="footer__menu__item">
                        <Link
                          className="footer__menu__link"
                          to={"/browse-by-programs/Music/172?browse-by=true"}
                        >
                          Music
                        </Link>
                      </li>
                      <li className="footer__menu__item">
                        <Link
                          className="footer__menu__link"
                          to={"/enrichment/179"}
                        >
                          Creative Combos
                        </Link>
                      </li>
                      <li className="footer__menu__item">
                        <Link
                          className="footer__menu__link"
                          to={"/browse-by-programs/Music/172?browse-by=true"}
                        >
                          Music
                        </Link>
                      </li>
                      <li className="footer__menu__item">
                        <Link
                          className="footer__menu__link"
                          to={"/browse-by-programs/Yoga/178?browse-by=true"}
                        >
                          Yoga
                        </Link>
                      </li>
                      <li className="footer__menu__item">
                        <Link
                          className="footer__menu__link"
                          to={"/browse-by-programs/Warriors/181?browse-by=true"}
                        >
                          Warriors
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer__bottom">
              <div className="container">
                <div className="footer__bottom__flexbox">
                  <ul className="nav">
                    <li className="nav__item">
                      <Link className="nav__item__link" to="/privacy-policy">
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="nav__item">
                      <Link className="nav__item__link" to="/terms">
                        Terms of use
                      </Link>
                    </li>
                    <li className="nav__item">
                      <Link className="nav__item__link" to="/contact">
                        Contact Us
                      </Link>
                    </li>
                    
                  </ul>

                  <div className="copyrighttext">
                    <span>
                      {footerDetails?.map((item) => (
                        <span key={item?.id}>{item?.copyright}</span>
                      ))}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__col_gymtime_guy_icon_container">
            <div className="footer_gymtime_guy_icon">
              <img src={footerLogoIcon} alt="footerLogo" />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
