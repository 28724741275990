import React, { useState, useEffect, useCallback } from "react";
import { getUserDetails } from "../adminApi/profileApi";
import { useDispatch } from "react-redux";
import { setUserRole } from "../../reducers/userSlice";
import LoaderComponent from "../components/LoaderComponent";
import { Link, useNavigate } from "react-router-dom";
import { logoutApi } from "../adminApi/users";
import { toast } from "react-toastify";
import adminLogo from "../assets/images/admin-logo.png";

const Header = React.memo(({ title, subTitle, setMakeUpStatus }) => {
  const [isProfileActive, setIsProfileActive] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getUserProfileDetails = useCallback(async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await getUserDetails(token);
      setUserDetails(response?.data?.getUser);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    getUserProfileDetails();
  }, [getUserProfileDetails]);

  const toggleProfileDropdown = () => {
    setIsProfileActive((prev) => !prev);
  };

  const handleHeaderClick = () => {
    setLoading(true);
    // window.location.reload();
    setMakeUpStatus(false);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const logoutHandler = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("access_token");
      const response = await logoutApi(token);
      if (response?.status === 200) {
        localStorage.removeItem("access_token");
        toast.success(response?.data?.data);
        localStorage.clear();
        navigate("/");
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoaderComponent />}
      <header className="Adminheader">
        <div className="Adminheader__info">
          <h2
            className="Adminheader__title"
            style={{ cursor: "pointer" }}
            onClick={handleHeaderClick}
          >
            {title}
          </h2>
          <p className="Adminheader__subtitle">{subTitle}</p>
        </div>

        <div className="Adminheader__actions">
          <div
            onClick={toggleProfileDropdown}
            className={`Adminheader__profile ${
              isProfileActive ? "Adminprofile--active" : ""
            }`}
          >
            {userDetails && (
              <div className="Adminprofile">
                <div className="Adminprofile__info">
                  <button className="adminbtn-sm adminbtn-rounded adminbtn-secondary">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id="Vector"
                        d="M5.70713 8.71067C5.31663 9.10117 5.31663 9.73437 5.70713 10.1249L10.5993 15.0123C11.3805 15.7927 12.6463 15.7924 13.4271 15.0116L18.3175 10.1213C18.708 9.73077 18.708 9.09757 18.3175 8.70707C17.9269 8.31647 17.2938 8.31647 16.9032 8.70707L12.7176 12.8927C12.327 13.2832 11.6939 13.2832 11.3034 12.8927L7.12133 8.71067C6.73083 8.32017 6.09763 8.32017 5.70713 8.71067Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                  <div className="Adminprofile__info--name">
                    <h4>
                      Hi, {userDetails?.first_name} {userDetails?.last_name}
                    </h4>
                    <p>Administrator</p>
                  </div>
                </div>

                <div className="Adminprofile__image">
                  <img
                    src={userDetails?.image ? userDetails?.image : adminLogo}
                    alt="Admin Profile"
                    width="50"
                    height="50"
                    style={{ borderRadius: "50%" }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = adminLogo;
                    }}
                  />
                </div>
              </div>
            )}

            <div className="Adminprofile__dropdown">
              <ul>
                <li>
                  <Link to="/admin/profile">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.33331 14C3.33331 11.4227 5.42265 9.33333 7.99998 9.33333C10.5773 9.33333 12.6666 11.4227 12.6666 14M10.6666 4.66667C10.6666 6.13943 9.47271 7.33333 7.99998 7.33333C6.52722 7.33333 5.33331 6.13943 5.33331 4.66667C5.33331 3.19391 6.52722 2 7.99998 2C9.47271 2 10.6666 3.19391 10.6666 4.66667Z"
                        stroke="white"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    My profile
                  </Link>
                </li>

                <li
                  onClick={async () => {
                    logoutHandler();
                    dispatch(setUserRole("Parent"));
                  }}
                >
                  <Link to="/">
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.9544 8H9.62103"
                        stroke="white"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.9544 10L14.8964 8.058C14.9284 8.026 14.9284 7.974 14.8964 7.942L12.9544 6"
                        stroke="white"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.6211 3.33333V3C11.6211 2.44771 11.1733 2 10.6211 2H4.28774C3.55136 2 2.95441 2.59695 2.95441 3.33333V12.6667C2.95441 13.4031 3.55136 14 4.28774 14H10.6211C11.1733 14 11.6211 13.5523 11.6211 13V12.6667"
                        stroke="white"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Sign Out
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
});

export default Header;
