import React, { useState } from "react";

// Date Picker;;

import "react-datepicker/dist/react-datepicker.css";
import StudentUpdate from "./StudentForms/StudentUpdate";
import CreateStudent from "./StudentForms/CreateStudent";

function StudentTabContent({ setSuccessModal, activeTab }) {
  const [temp, setTemp] = useState(true);



  return (
    <>
      <h2 className="title title--h2 title--center">
        *Please review your account information below.*
      </h2>

      <StudentUpdate
        setTemp={setTemp}
        temp={temp}
        setSuccessModal={setSuccessModal}
        activeTab={activeTab}
      />
      
      <CreateStudent
        setTemp={setTemp}
        temp={temp}
        setSuccessModal={setSuccessModal}
        activeTab={activeTab}
      />
    </>
  );
}

export default StudentTabContent;
