import React from 'react'
import successIcon from '../../Parent/parent-assets/images/success-icon.gif'
import { useNavigate } from 'react-router-dom';

function CheckoutModal({orderData}) {
  const navigate = useNavigate();
  return (
    <>
   <div className="modal modal--oderconfirmedModal modal--open" id="OrderConfirmedModal" role="dialog">
      <div className="modal__dialog modal__dialog--lg">
        <div className="modal__content" >
          <div className="modal__body">
            <div className="orderConfirmed">
              <h2 className="title">Thank You For Your Purchase</h2>
              <div className="orderConfirmed__detail">
              <div className="pic">
                  <img className="pic__img" src={successIcon} alt="Succes" />
                </div>
                <h3 className="title title--h3">Order #{orderData?.orderId} Confirmed</h3>
                <button className="btn btn--primary btn--full"onClick={()=>{navigate("/parent", { state: "tab3" });}}>Check Enrollment</button>
                <p className="para">Generate Receipt</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default CheckoutModal