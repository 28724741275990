import {
  GET_REPORTS,
  GET_ATTENDENCE,
  GET_PHOTORELEASE,
  GET_MAILING_LIST,
  GET_DROP_OFF_REPORT,
} from "../adminQueries/Reports";
import axios from "axios";

const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export async function getReport(token, selectedCategory, startDate, endDate) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_REPORTS,
        variables: {
          categoryId: selectedCategory?.length > 0 ? selectedCategory : [42345],
          startDate: startDate,
          endDate: endDate,
          // pageInfo: {
          //   limit: 10000,
          //   page: null,
          //   sort: "Category",
          //   isAsc: true,
          // },
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {}
}

export async function getAttendenceReport(
  token,
  typeDetails,
  selectedCategory,
  classEndDate,
  classStartDate,
  typeStatus
) {
  try {
    const currentDate = getCurrentDate();
    const variables = {
      classStartDate: classStartDate ? classStartDate : "1590-10-01",
      classEndDate: classEndDate ? classEndDate : "1590-10-01",
      // className: selectedCategory,
      typeDetails: typeDetails ? typeDetails : null,
    };
    if (typeStatus && typeStatus?.length) {
      variables.typeStatus = typeStatus;
    }
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_ATTENDENCE,
        variables,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {}
}

export async function getphotoRelease(
  token,
  active,
  gender,
  studentEndAge,
  studentStartAge
) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_PHOTORELEASE,
        variables: {
          active: active,
          gender: gender,
          studentEndAge: studentEndAge,
          studentStartAge: studentStartAge,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {}
}

export async function getmailingList(
  token,
  studentStartAge,
  studentEndAge,
  enrollmentStartDate,
  enrollmentEndDate,
  gender,
  selectedCategory,
  selectedClasses
) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_MAILING_LIST,
        variables: {
          studentStartAge: studentStartAge,
          studentEndAge: studentEndAge,
          enrollmentStartDate: enrollmentStartDate,
          enrollmentEndDate: enrollmentEndDate,
          gender: gender,
          categoryId: selectedCategory,
          classes: selectedClasses,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {}
}

export async function getdropoffSummary(token, prevDate, recentDate) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_DROP_OFF_REPORT,
        variables: {
          prevDate: prevDate ? prevDate : "1190-01-01",
          recentDate: recentDate ? recentDate : "1190-01-01",
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {}
}
