import { aboutPageContent } from "../../api/contentApi";
import campLogo from "../../parent-assets/images/enrichment-images/camp-icon-23.png";
import cheerImage from "../../parent-assets/images/about-us-img01.png";
import OwlCarousel from "react-owl-carousel";
import { useEffect, useState } from "react";
import galleryImg2 from "../../parent-assets/images/Facilities-carousel/Art01.jpg";
import galleryImg3 from "../../parent-assets/images/Facilities-carousel/Art02.jpg";
import { Link, useNavigate, useParams } from "react-router-dom";
import galleryImg1 from "../../parent-assets/images/Facilities-carousel/Art03.jpg";
import { Fancybox } from "@fancyapps/ui";
import { getCampProgramClassDetails } from "../../../Admin/adminApi/program";
import AboutUsShimmer from "../../../Admin/components/ShimmerUi/AboutUsShimmer";
import axios from "axios";

function GirlCampLandingPage() {
  const token = localStorage.getItem("access_token");
  const { id } = useParams();
  const [dummyArr, setDummyArr] = useState([1, 2, 3, 4, 5, 6]);
  const [prefilledData, setPrefilledData] = useState();
  const navigate = useNavigate();
  const [showShimmer, setShowShimmer] = useState(false)
  const [galleryImg, setGalleryImg] = useState();
  const [campDetails, setCampDetails] = useState();
  useEffect(() => {
    const fancyboxOptions = {};
    Fancybox.bind("[data-fancybox='gallery']", fancyboxOptions);
    getGirlGymnasticsPrefilledData();
    getGalleryInfoData();
    getWeeks();
  }, []);


  const getWeeks = async () => {
    try {
      const postData = {
        classId: parseInt(id)
      }
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/class/weekly-classes`,
        postData,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
     
      setCampDetails(response?.data?.data);
    } catch (error) {
      console.error("Error while generating Weeks", error);
    }
  }
  const getGalleryInfoData = async () => {
    try {
      const postData = {
        facility_id: parseInt(id)
      }
      const responseImage = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/get_gallery`, postData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }
      )
      setGalleryImg(responseImage?.data?.data)
    } catch (error) {
      console.error("Error while generating gallery info.", error);

    }
  }

  const getGirlGymnasticsPrefilledData = async () => {
    try {
      setShowShimmer(true);
      const response = await getCampProgramClassDetails(parseInt(id));
      setPrefilledData(response?.data?.data);
      setShowShimmer(false);
    } catch (error) {
      console.error(error);
    }
  };


  const responsiveOptions = {
    0: {
      items: 1,
    },
    768: {
      items: 3,
    },
    1024: {
      items: 4,
    },
  };

  const FullDaySignUpHandler = () => {
    navigate("/camp/girl-gymnastic-class-detail");
  };
  const dateformatHandler = (date) => {
    if (date === null || date === undefined || date === "") {
      return ""
    }
    const months = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      "10": "Oct",
      "11": "Nov",
      "12": "Dec"
    }
      ;

    const splitedDate = date?.split("-");
    return ` ${months?.[splitedDate?.[1]]} ${splitedDate?.[2]}`

  }
  return (
    <>
      {
        showShimmer ? <AboutUsShimmer /> :
          <div className="contentwraper contentWrapper_responsive">
            <section style={{ position: "relative" }}>
              <section className="siteBanner" style={{ background: "#ea519c" }}>
                <div className="siteBanner__overlay">
                  <img
                    className="siteBanner__img"
                    src={
                      prefilledData?.[0]?.header_image
                        ? prefilledData?.[0]?.header_image
                        : aboutPageContent.aboutBannerImage.url
                    }
                    alt="banner"
                  />
                </div>
              </section>
            </section>

            {/* BODY  */}
            <section className="breadcrumbSec">
              <div className="container">
                <div className="breadcrumb">
                  <div className="breadcrumb__item">
                    <Link className="breadcrumb__link" onClick={() => navigate(-1)}>
                      Back to results
                    </Link>
                  </div>
                </div>
              </div>
            </section>

            <section className="facilitiesSec facilitySec_responsive">
              <div className="container">
                <div
                  className="picture"
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src={campLogo}
                    alt="gymtimeGuy"
                    className="picture__img"
                    style={{ height: "100px" }}
                  />
                  <h1 className="title title--withicon title--extrabold">
                    Girls Gymnastics Camp
                  </h1>
                </div>

                {/* <section className="aboutusSec" style={{ paddingBottom: "2px" }}>
            <div className="container">
              <div className="aboutData">
                <div className="aboutData__box">
                  <div
                    className="aboutData__row"
                    style={{
                      display: "flex",
                      gap: "50px",
                      height: "min-content",
                    }}
                  >
                    <div className="aboutData__col">
                      <div className="content">
                        <h2 className="girl_gymnastics_content">
                          {prefilledData?.[0]?.description ?
                            prefilledData?.[0]?.description :
                            `Our 60-minute Girls Gymnastics class is the perfect
                          opportunity for young athletes aged 5 and up to
                          explore advanced skills across all aspects of
                          gymnastics. Building upon basic skills in vault, bars,
                          beam, and floor exercises, as well as trampoline,
                          students practice on state-of-the-art equipment,
                          fostering strength, flexibility, coordination , and
                          confidence to perform a wide range of skills. Under
                          the guidance of our dedicated coaches, students will
                          master advanced techniques, including handstands,
                          cartwheels, back walkovers, pull-overs, back hip
                          circles, and much, much more!`}
                        </h2>
                      </div>
                    </div>
                    <div className="aboutData__col class_detail_image_container">
                      <img src={prefilledData?.[0]?.image ? prefilledData?.[0]?.image : cheerImage} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

                <div
                  className="picture class_detail_image_container"
                  style={{ float: "left" }}
                >
                  <img
                    className="picture__img"
                    src={
                      prefilledData?.[0]?.image
                        ? prefilledData?.[0]?.image
                        : cheerImage
                    }
                    alt="CLASS IMAGES"
                  />
                  <p className="class_details_shortText_container">
                    {prefilledData?.[0]?.description
                      ? prefilledData?.[0]?.description
                      : `Our 60-minute Girls Gymnastics class is the perfect
                          opportunity for young athletes aged 5 and up to
                          explore advanced skills across all aspects of
                          gymnastics. Building upon basic skills in vault, bars,
                          beam, and floor exercises, as well as trampoline,
                          students practice on state-of-the-art equipment,
                          fostering strength, flexibility, coordination , and
                          confidence to perform a wide range of skills. Under
                          the guidance of our dedicated coaches, students will
                          master advanced techniques, including handstands,
                          cartwheels, back walkovers, pull-overs, back hip
                          circles, and much, much more!`}
                  </p>
                </div>
                {/* LOWER SECTION */}

                <div className="aboutData">
                  <div className="half_full_container_fluid">
                    <div className="half_full_heading_title_container">
                      <h1 className="register_title_heading">
                        <span> To Register Call : {prefilledData?.[0]?.camp_heading_first}</span> <br />
                        <span>Email : {prefilledData?.[0]?.camp_heading_second}</span>
                      </h1>
                    </div>

                    <div className="girl_gymnastics_full_detail_conatiner">
                      <div>
                        <div className="registration_fees_desc">
                          <h1 className="registration_fees_desc_heading">
                            {prefilledData?.[0]?.camp_heading_third}
                          </h1>
                        </div>

                        <div className="half_full_day_schedule_desc half_full_day_schedule_desc_temp">
                          <div className="half_full_box_container">
                            <div className="girl_gymnastics_day_box">
                              <div className="half_day_box_inner_container">
                                <h1 className="half_day_schedule_title_heading">
                                  Sample Schedules
                                </h1>
                                {prefilledData?.map((item) => (
                                  <p
                                    style={{ color: "white" }}
                                    dangerouslySetInnerHTML={{
                                      __html: item?.content?.[0]?.first_one,
                                    }}
                                  ></p>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="half_full_weekly_desc">
                            <div className="weekly_description">
                              <h1 className="weekly_desc_head_title">
                                Weekly Camp Themes
                              </h1>
                              <span className="weekly_schedule">
                                {dateformatHandler(campDetails?.fullDay?.[0]?.start_date)} -
                                {dateformatHandler(campDetails?.fullDay?.[campDetails?.fullDay?.length - 1]?.end_date)}
                              </span>
                            </div>

                            {campDetails?.fullDay?.map((item, index) => (
                              <div>
                                <div className="weekly_description_info">
                                  <div className="weekday-count-name">
                                    <div className="week_day_count">
                                     <span>Week {index + 1}</span>
                                     <span className="camp_week_days_date">
                                      {dateformatHandler(item?.start_date)}
                                    -
                                    {dateformatHandler(item?.end_date)}</span> 
                                    </div>
                                    <div className="week_day_name">
                                        {item?.tuition && <span>Full day ${item?.tuition} </span>}
                                      </div>
                                    <div className="week_day_name">
                                      {item?.name}
                                    </div>
                                  </div>
                                  
                                </div>
                                <div className="week_end_line"></div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* CAROUSEL  */}
                <section className="facilitiesSec party_carousel">
                  <div className="container">
                    <OwlCarousel
                      margin={10}
                      responsiveclassname="true"
                      responsive={responsiveOptions}
                      className="galleryGrid owl-carousel"
                      autoplay
                      loop={true}
                      arrows="true"
                      dots={false}
                      nav={true}
                      lazyLoad={true}
                      navText={[
                        `<img src=${require("../../parent-assets/images/enrichment-images/arrow-left.png")} />`,
                        `<img src=${require("../../parent-assets/images/enrichment-images/arrow-right.png")} alt='right' />`,
                      ]}
                    >
                      {galleryImg?.map((item) => (
                        <div className="galleryGrid__item">
                          <div className="galleryGrid__card">
                            <a
                              className="galleryGrid__card__link"
                              data-fancybox="gallery"
                              href="../images/gallery-img01.png"
                            >
                              <img
                                className="galleryGrid__card__img"
                                src={item?.image}
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      ))}

                    </OwlCarousel>
                  </div>
                </section>
              </div>
            </section>
          </div>
      }

    </>
  );
}

export default GirlCampLandingPage;
