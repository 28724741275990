import React, { useEffect, useState } from "react";
import { getSubPrograms } from "../../../adminApi/subProgram";
import axios from "axios";
import { toast } from "react-toastify";
const EditProgramImagesModal = ({ setShowEditProgramImagesModal }) => {
  const token = localStorage.getItem("access_token");
  const [programMenuData, setProgramMenuData] = useState([]);
  const [id, setId] = useState();
  const [programImage, setProgramImage] = useState();
  useEffect(() => {
    getSubProgramMenu();
  }, []);
  const getSubProgramMenu = async () => {
    const response = await getSubPrograms(token);
    setProgramMenuData(response);
  };
  const programImageChangeHandler = async (e) => {
    e.preventDefault();
    try {
      const selectedFile = e.target.files;
      if (selectedFile && selectedFile?.length > 0) {
        const file = selectedFile[0];
        setProgramImage(file?.name);
        const reader = new FileReader();

        const loadPromise = new Promise((resolve) => {
          reader.onloadend = () => {
            resolve();
          };
        });

        reader.readAsDataURL(file);
        await loadPromise;

        const img = new Image();
        img.src = reader.result;

        img.onload = async () => {
          const desiredHeight = 645;
          const desireWidth = 690;

          const canvas = document.createElement("canvas");
          canvas.height = desiredHeight;
          canvas.width = desireWidth;

          // Use '2d' with alpha for transparency
          const ctx = canvas.getContext("2d", { alpha: true });

          // Clear the canvas with transparent color
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          // Draw the image with transparency
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          const convertedFile = {
            image: canvas.toDataURL("image/png"), // Use 'png' for transparency
          };

          const convertedFileName = {
            name: file.name,
          };

          const postData = {
            programId: parseInt(id),
            image: convertedFile?.image,
          };

          try {
            const response = await axios.post(
              process.env.REACT_APP_BASE_URL + `/admin/program_edit_cms`,
              postData,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (response?.status === 200 || response?.status === 201) {
              toast.success("Image uploaded successfully");
              setShowEditProgramImagesModal(false);
            }
          } catch (error) {
            toast.error("Image upload failed!");
            setShowEditProgramImagesModal(false);
          }
        };
      }
    } catch (error) {
      toast.error("Image upload failed!");
      setShowEditProgramImagesModal(false);
    }
  };

  return (
    <>
      <div
        className="Admin-modal Admin-modal--open"
        id="modalDelete"
        role="dialog"
      >
        <div className="Admin-modal__dialog Admin-modal__dialog--lg">
          <div className="Admin-modal__content">
            <div className="Admin-modal__head">
              <h2 className="Admin-modal__head--title">Edit Program Images</h2>
              <button
                className="Admin-modal__close"
                onClick={() => setShowEditProgramImagesModal(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1_9631)">
                    <path
                      d="M14.0728 6.92854L14.0728 6.92856C14.4017 7.26332 14.4017 7.79324 14.0728 8.128L14.0713 8.12951L14.0713 8.1295L11.6968 10.5057L14.0354 12.8405C14.3655 13.1697 14.3655 13.7084 14.0354 14.0376C13.706 14.3667 13.1717 14.3663 12.8421 14.0376L12.8418 14.0373L10.5035 11.6969L8.14748 14.0551L8.14649 14.0561C7.81513 14.3841 7.27944 14.3841 6.94808 14.0561L6.94561 14.0536L6.94562 14.0536C6.61805 13.7202 6.61475 13.1828 6.94753 12.8523C6.94764 12.8521 6.94775 12.852 6.94785 12.8519L9.30329 10.4943L6.9652 8.15951C6.63514 7.8303 6.63511 7.2917 6.9651 6.96245C6.96513 6.96242 6.96516 6.96238 6.9652 6.96235L7.14175 7.13935C7.37272 6.90779 7.74915 6.90779 7.98131 7.13935L14.0728 6.92854ZM14.0728 6.92854L14.0703 6.92609C13.739 6.5981 13.2039 6.5981 12.8725 6.92609L12.8716 6.92703L10.4965 9.30317L14.0728 6.92854ZM10.5 18.5625C6.04722 18.5625 2.4375 14.9516 2.4375 10.5C2.4375 6.04836 6.04722 2.4375 10.5 2.4375C14.9528 2.4375 18.5625 6.04836 18.5625 10.5C18.5625 14.9516 14.9528 18.5625 10.5 18.5625ZM10.5 0.75C5.11499 0.75 0.75 5.11315 0.75 10.5C0.75 15.8869 5.11499 20.25 10.5 20.25C15.885 20.25 20.25 15.8869 20.25 10.5C20.25 5.11315 15.885 0.75 10.5 0.75Z"
                      fill="white"
                      stroke="white"
                      strokeWidth="0.5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_9631">
                      <rect width="21" height="21" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            <div className="Admin-modal__body">
              {programMenuData?.map((items) => (
                <div className="edit_image_container" key={items?.id}>
                  <div className="prev_edit_image">
                    <img
                      src={items?.sub_image}
                      className="main_edit_program_image"
                      alt="image loading"
                    />
                  </div>
                  <span className="cms_master_menu_program_title">
                    {items?.name}
                  </span>
                  <div className="edit_delete_image_container">
                    <input
                      class="hiddenInputfor_image_upload"
                      id="BrowseFiles_image_upload"
                      type="file"
                      onChange={(e) => {
                        programImageChangeHandler(e);
                      }}
                    />

                    <label
                      for="BrowseFiles_image_upload"
                      class="browseBtn adminbtn-primary adminbtn image_edit_upload_btn"
                      onClick={() => setId(items?.id)}
                    >
                      Edit
                    </label>
                    <button className="adminbtn-primary adminbtn image_edit_upload_btn">
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProgramImagesModal;
