import React, { useEffect, useState, useRef } from "react";
// import logo from "././Parent/parent-assets/images/gymtime_logo_2024.svg";
import html2pdf from "html2pdf.js";
import { ReactComponent as LOGO } from "../../Parent/parent-assets/images/gymtime_logo_2024.svg";
import { Link } from "react-router-dom";
const DownloadOrderPdf = ({
  categoryForMail,
  sendMailData,
  setShowMailLoader,
  loaderIndex,
  mailOrderId,
  setDownloadPdf,
  setSendMailData
}) => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);
  const token = localStorage.getItem("access_token");
  const contentRef = useRef(null);
  const currentDate = new Date();
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    currentDate
  );
  const changeDateFormat = (value) => {
    const newDateFormat = new Date(`${value}`).toLocaleDateString();
    return newDateFormat;
  };

  useEffect(() => {
    if (sendMailData?.length > 0) {
      let amount = 0;
      let amountPaid = 0;
      for (let i = 0; i < sendMailData.length; i++) {
        amount += sendMailData?.[i]?.rate;
      }
      setTotalAmount(amount);
      for (let i = 0; i < sendMailData.length; i++) {
        amountPaid += sendMailData?.[i]?.amount_paid;
      }
      setTotalAmountPaid(amountPaid);
    }
  }, [sendMailData]);

  useEffect(() => {
    if (sendMailData?.data?.length>0) {
        downloadPdf();
    }
  }, [sendMailData]);

  const downloadPdf = async () => {
    const element = contentRef.current;
    const options = {
      margin: 0.1,
      filename: "Order_invoice.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(element).set(options).save();
    setDownloadPdf(false);
    setSendMailData([])
  };
  const formatTime = (time) => {
    const date = new Date(`1970-01-01T${time}`);
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return d.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  return (
    <>
      <div>
        <div>
          <div ref={contentRef}>
            <div className="Admin-modal__head-send_Email_Modal">
              <div
                className=""
                style={{ maxWidth: "250px", marginBottom: "20px" }}
              >
                <LOGO />
              </div>
              <div className="mailing_data_header">
                <div className="class_name_address">
                  <h2 className="mailing_class">
                    Gymtime Gymnastics Rhythm & Glues
                  </h2>
                  <span className="mailing_address">
                    1520 York Avenue <br /> New York, NY 10028 <br /> Phone
                    212.861.7732 <br />
                    Fax 212.861.8901
                  </span>
                </div>
                <div className="Invoide_mailing_meta_data">
                  <h1 className="mailing_Invoice_heading">INVOICE </h1>
                  <span className="Invoice_date">{formattedDate}</span>
                </div>
              </div>

              <div className="to_for_mailing_section">
                <div className="to_mailing_container">
                  <h2 className="to_mailing_heading">To</h2>
                  <span className="mailing_address">
                    <div>
                      {sendMailData?.data?.[0]?.parent1_first_name +
                        " " +
                        sendMailData?.data?.[0]?.parent1_last_name}
                    </div>
                    <div>
                      {sendMailData?.data?.[0]?.parent1_apartment},
                      {sendMailData?.data?.[0]?.parent1_city}, <br />
                      {sendMailData?.data?.[0]?.parent1_state},
                      {sendMailData?.data?.[0]?.parent1_zip}
                    </div>
                  </span>
                  <div>
                    <span style={{ fontWeight: "600", color: "black" }}>
                      Ph.{" "}
                    </span>
                    <span className="mailing_address">
                      {sendMailData?.data?.[0]?.parent1_cell}
                    </span>
                  </div>
                </div>
                <div className="for_mailing_container">
                  <h2 className="to_mailing_heading">For</h2>
                  <span className="mailing_address">
                    {sendMailData?.data?.[0]?.student_first_name +
                      " " +
                      sendMailData?.data?.[0]?.student_last_name}
                  </span>
                </div>
              </div>
            </div>
            <div className="Admin-modal__body">
              <div className="Admin-modal__warning1">
                <table className="mailing_table_list">
                  <thead>
                    <tr>
                      <th style={{ borderTopLeftRadius: "0px" }}>
                        DESCRIPTION
                      </th>
                      <th>DATES</th>
                      <th>RATE</th>
                      <th>AMOUNT PAID</th>
                      <th style={{ borderTopRightRadius: "0px" }}>DATE PAID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sendMailData?.data?.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: "white",
                          pageBreakInside: "avoid",
                        }}
                      >
                        <td
                          style={{
                            backgroundColor: "white",
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                          }}
                        >
                          <span className="mailing_class_metadata_subtitle">
                            {item?.class_name}
                          </span>

                          {item?.schedules?.map((schedule, idx) => (
                            <span
                              key={idx}
                              className="mailing_class_metadata_subtitle"
                            >
                              {` ${schedule?.weekday}, 
                                                            ${schedule?.start_time}-${schedule?.end_time}`}
                            </span>
                          ))}
                        </td>
                        <td style={{ backgroundColor: "white" }}>{`${
                          item?.class_start_date ? item?.class_start_date : "-"
                        }-${
                          item?.class_end_date ? item?.class_end_date : "-"
                        } `}</td>
                        <td style={{ backgroundColor: "white" }}>
                          $ {item?.class_rate}
                        </td>
                        <td style={{ backgroundColor: "white" }}>
                          $ {item?.amount_paid}
                        </td>
                        <td
                          style={{
                            backgroundColor: "white",
                            borderBottomRightRadius: "0px",
                            borderTopRightRadius: "0px",
                          }}
                        >
                          {changeDateFormat(item?.date_paid)}
                        </td>
                      </tr>
                    ))}
                    <tr className="mailing_total_amount">
                      <td>TOTALS</td>
                      <td></td>
                      <td>${sendMailData?.totalDue}</td>
                      <td>$ {sendMailData?.totalPaid}</td>
                      <td>$ {sendMailData?.totalBalance}</td>
                    </tr>
                    <tr
                      className="due_meta_data_info_mailing"
                      style={{ backgroundColor: "white" }}
                    >
                      <td style={{ backgroundColor: "white" }}></td>
                      <td style={{ backgroundColor: "white" }}></td>
                      <td
                        className="total_rates_mailing_table"
                        style={{ backgroundColor: "white" }}
                      >
                        Total Rate
                      </td>
                      <td
                        style={{ backgroundColor: "white" }}
                        className="total_rates_mailing_table"
                      >
                        Total Amount Paid
                      </td>
                      <td
                        style={{ backgroundColor: "white" }}
                        className="total_rates_mailing_table"
                      >
                        Due Amount
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <div className="greeting_container">
                  <span className="greeting_mailing">
                    THANK YOU FOR YOUR BUSINESS!
                  </span>
                  <span className="gymtime_mailing_info">
                    <Link
                      style={{
                        cursor: "pointer",
                        color: "00bbe3",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        window.open("https://www.gymtime.net", "_blank");
                      }}
                    >
                      info@gymtime.net - www.Gymtime.net - 1520 York Ave - new
                      York, NY 10028
                    </Link>{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="invoice-box">
                <div className="logo">
                    <img src="https://cdn.gymtime.net/image1711229711911.png" alt="Company Logo" />
                </div>
                <div className="flexbox">
                    <div className="col">
                        <div>
                            <p className="title--h6" style={{ fontWeight: 900 }}>Gymtime Gymnastics Rhythm & Glues</p>
                            <p>1520 York Avenue</p>
                            <p>New York, NY 10028</p>
                            <p>Phone: 212.861.7732</p>
                            <p>Fax: 212.861.8901</p>
                        </div>
                    </div>
                    <div>
                        <p className="title--h3" style={{ fontWeight: 900, marginRight: '20px', marginTop: 0, marginBottom: 0 }}>INVOICE</p>
                        <p style={{ marginTop: '4px' }}>{formatDate(new Date())}</p>
                    </div>
                </div>
                <div className="flex">
                    <div className="to">
                        <p style={{ fontWeight: 900, margin: 0 }}>TO</p>
                        <div style={{ lineHeight: '20px' }}>
                            <p style={{ fontSize: '13px', margin: '5px 0px 0px 0px' }}>
                                {sendMailData?.data?.[0]?.parent1_first_name || '-'}
                                {sendMailData?.data?.[0]?.parent1_last_name || '-'}
                            </p>
                            <div style={{ fontSize: '13px', margin: 0 }}>
                                Address: <span>{sendMailData?.data?.[0]?.parent1_address || '-'},
                                    {sendMailData?.data?.[0]?.parent1_city || '-'},
                                    {sendMailData?.data?.[0]?.parent1_state || '-'},
                                    {sendMailData?.data?.[0]?.parent1_zip || '-'}
                                </span>
                            </div>
                            <p style={{ fontSize: '13px', margin: 0 }}>
                                Phone: <span style={{ fontSize: '13px' }}>
                                    {sendMailData?.data?.[0]?.parent1_cell || '-'}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="for">
                        <p style={{ fontWeight: 900, lineHeight: '10px', marginTop: 0, marginBottom: 0 }}>FOR</p>
                        <p style={{ fontSize: '13px', margin: '5px 0px 0px 0px' }}>{sendMailData?.data?.[0]?.student_first_name || '-'} {sendMailData?.data?.[0]?.student_last_name || '-'}</p>
                    </div>
                </div>
                <table className="invoice-table" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr className="table-head">
                            <th className="tableTH" style={{ width: '30%', fontWeight: 900 }}>DESCRIPTION</th>
                            <th className="tableTH" style={{ fontWeight: 900 }}>DATES</th>
                            <th className="tableTH" style={{ fontWeight: 900 }}>RATE</th>
                            <th className="tableTH" style={{ fontWeight: 900 }}>AMOUNT PAID</th>
                            <th className="tableTH" style={{ fontWeight: 900 }}>DATE PAID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sendMailData?.data?.map((val, index) => (
                            <tr className="row-data" key={index}>
                                <td style={{ fontSize: '0.7rem' }}>
                                    <div>{val?.class_name || '-'}</div>
                                    {val?.schedules && val.schedules?.length > 0 && val?.schedules?.map((schedule, i) => (
                                        <div key={i}>
                                            {schedule?.weekday}, {formatTime(schedule?.start_time)} - {formatTime(schedule?.end_time)}
                                        </div>
                                    ))}
                                </td>
                                <td style={{ fontSize: '0.7rem' }}>{val?.class_start_date} - {val?.class_end_date}</td>
                                <td style={{ fontSize: '0.7rem' }}>${val?.class_rate || '-'}</td>
                                <td style={{ fontSize: '0.7rem' }}>${val?.amount_paid || '-'}</td>
                                <td style={{ fontSize: '0.7rem' }}>{val?.date_paid || '-'}</td>
                            </tr>
                        ))}
                        <tr className="total-row" style={{ fontSize: '0.7rem' }}>
                            <td style={{ fontSize: '0.7rem' }}>Total</td>
                            <td></td>
                            <td style={{ fontSize: '0.7rem' }}>${sendMailData?.totalDue || '0'}</td>
                            <td style={{ fontSize: '0.7rem' }}>${sendMailData?.totalPaid || '0'}</td>
                            <td style={{ fontSize: '0.7rem' }}>${sendMailData?.totalBalance || '0'}</td>
                        </tr>
                    </tbody>
                </table>
                {sendMailData?.amountTendered && (
                    <p className="title--h6" style={{ fontWeight: 900 }}>
                        Total Amount Paid for this Transaction: ${sendMailData?.amountTendered || '0'}
                    </p>
                )}
                <div className="message">
                    <h3 className="title--h4" style={{ fontWeight: 900, background: 'none', fontSize: '18px' }}>THANK YOU FOR YOUR BUSINESS!</h3>
                </div>
                <div className="table-footer">
                    <a href="https://gymtime.net/" style={{ cursor: 'pointer', color: '#00bbe3', textDecoration: 'underline' }}>
                        info@gymtime.net - www.Gymtime.net - 1520 York Ave - New York, NY 10028
                    </a>
                </div>
            </div> */}
    </>
  );
};

export default DownloadOrderPdf;
