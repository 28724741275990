import React, { useEffect } from "react";
import alertIcon from "./Admin/assets/images/icons/alert.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openLoginModal, openSessionModal } from "./reducers/sessionHandler";
import { ReactComponent as LOGO } from "../src/Parent/parent-assets/images/gymtime_logo_2024.svg";

function SessionExpired({ openModal, setOpenModal, setLoading }) {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();
    localStorage.removeItem("access_token");

    setTimeout(() => {
      setOpenModal(false);
      dispatch(openSessionModal(false));
      dispatch(openLoginModal(true));
    }, 2000);

    navigate("/");
  }, [openModal, setLoading, navigate, dispatch]);

  return (
    <>
      {openModal ? (
        <>
          <div
            className="Admin-modal Admin-modal--open"
            id="modalDelete"
            role="dialog"
          >
            <div className="Admin-modal__dialog Admin-modal__dialog--sm">
              <div
                className="Admin-modal__content"
                style={{ padding: "45px 50px" }}
              >
                <div className="Admin-modal__body">
                  <div>
                    <div>
                      <LOGO />
                    </div>
                  </div>
                  <hr />
                  <div
                    className="Admin-modal__warning"
                    style={{ maxWidth: "250px" }}
                  >
                    <img
                      height={"30px"}
                      width={"30px"}
                      src={alertIcon}
                      alt="alrt-icon"
                    />
                    <div>
                      <LOGO />
                    </div>
                    <span
                      className="label title title--medium"
                      style={{ fontWeight: "600", fontSize: "1.2rem" }}
                    >
                      Your session has expired.{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default SessionExpired;
