import axios from "axios";
import React from "react";
import { toast } from "react-toastify";

const DeleteClassModal = ({
  categoryItems,
  programs,
  setShowDeleteModal,
  getManageDataFunction,
  deleteType,
  enrichmentClassHandler,
  enrichmentCategoryId,
  programId,
  // setEnrichemntCategoryId
}) => {
  const token = localStorage.getItem("access_token");
  const deleteHandler = async () => {
    try {
      let postData = {
        [`${deleteType}Id`]: parseInt(categoryItems?.data?.[0]),
        programId: programId,
      };

      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/delete_entity_cms`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 201 || response?.status === 200) {
        toast.success("Class deleted successfully!");
        if (enrichmentCategoryId !== undefined) {
          enrichmentClassHandler(enrichmentCategoryId);
        } else {
          getManageDataFunction();
        }
        setShowDeleteModal(false);
      }
    } catch (error) {
      console.error(`Error while deleting ${deleteType}`);
      toast.error(`Error while deleting ${deleteType}`);
      setShowDeleteModal(false);
    }
  };
  return (
    <>
      <div
        className="Admin-modal Admin-modal--open"
        id="modalCategory"
        role="dialog"
      >
        <div className="Admin-modal__dialog Admin-modal__dialog--sm">
          <div className="Admin-modal__content" style={{ width: "200%" }}>
            <div className="Admin-modal__head">
              <h2 className="Admin-modal__head--title">Delete class</h2>
              <h1
                className="closeProgramEditModal"
                onClick={() => setShowDeleteModal(false)}
              >
                X
              </h1>
            </div>
            <div class="accordion__card__body">
              <div className="Admin-modal__body">
                <h1>
                  Are you sure you want to delete
                  <span style={{ fontWeight: 600 }}>
                    {" " + categoryItems?.data?.[1]}
                  </span>
                  .
                </h1>

                <div
                  c
                  lassName="button_container_cms_class_delete"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "20px",
                  }}
                >
                  <button
                    className="adminbtn adminbtn-danger"
                    onClick={() => deleteHandler()}
                  >
                    Delete
                  </button>
                  <button
                    className="adminbtn adminbtn-primary"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteClassModal;
