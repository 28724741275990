import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import NavigationComponent from "../components/NavigationComponent";
import TableNew from "../components/TableComponent/TableNew";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../reducers/loadingSlice";
import { debounce } from "lodash";
import getAttendence from "../adminApi/attendance";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import html2pdf from "html2pdf.js";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { customStyles, dateFormatter } from "../adminUtility";
import Select from "react-select";
import { getClassesByWeekdays, getClassesNew } from "../adminApi/classApi";
const arrowIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.4615 3.7712C7.72183 3.51085 7.72183 3.08874 7.4615 2.82839L5.57588 0.942771C5.53707 0.903964 5.49647 0.868044 5.45433 0.835024C5.00181 0.480349 4.37288 0.459313 3.89943 0.771917C3.82549 0.820731 3.75535 0.877684 3.69026 0.942771L1.80464 2.82839C1.54429 3.08874 1.54429 3.51085 1.80464 3.7712C2.06499 4.03155 2.4871 4.03155 2.74745 3.7712L3.99992 2.51872V12C3.99992 12.3682 4.2984 12.6666 4.66659 12.6666C5.03478 12.6666 5.33325 12.3682 5.33325 12V2.58576L6.51869 3.7712C6.77903 4.03154 7.20117 4.03154 7.4615 3.7712Z"
      fill="white"
    />
    <path
      d="M8.47064 12.2287C8.21031 12.4891 8.21031 12.9111 8.47064 13.1715L10.3562 15.0571C10.4214 15.1222 10.4915 15.1792 10.5654 15.228C11.083 15.5697 11.7862 15.5127 12.2419 15.0571L14.1275 13.1715C14.3878 12.9111 14.3878 12.4891 14.1275 12.2287C13.8672 11.9683 13.445 11.9683 13.1847 12.2287L11.9992 13.4141V3.99992C11.9992 3.63173 11.7008 3.33325 11.3326 3.33325C10.9644 3.33325 10.6659 3.63173 10.6659 3.99992V13.4811L9.41344 12.2287C9.15311 11.9683 8.73097 11.9683 8.47064 12.2287Z"
      fill="white"
    />
  </svg>
);
const Attendence = () => {
  const [exportDrop, setExportDrop] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [attendance, setAttendance] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [formData, setFormData] = useState([]);
  const [temp, setTemp] = useState(1);
  const page = useSelector((state) => state?.adminSlice?.adminModalContent);
  const [pageLimit, setPageLimit] = useState("10");
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [allClassApiData, setAllClassApiData] = useState();
  const [selectedState, setSelectedState] = useState();
  const [pdfStateValue, setPdfStateValue] = useState();
  const [closeCalender, setCloseCalender] = useState(true);
  const [weekday, setWeekday] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const calenderRef = useRef();
  const dispatch = useDispatch();
  const [sortKey, setSortKey] = useState({
    sortId: null,
    isAsc: true,
  });
  const [loadingState, setLoadingState] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const _tabelHeadersData = [
    {
      name: "StudentID",
      icon: arrowIcon,
    },
    {
      name: "Date",
    },
    {
      name: "Student Name",
      icon: arrowIcon,
    },
    {
      name: "Parent 1",
    },
    {
      name: "Parent 2",
    },
    {
      name: "Class Enrolled",
    },
    {
      name: "Time",
    },
    {
      name: "Class Code",
      icon: arrowIcon,
    },
    {
      name: "Type",
    },
    {
      name: "Attendance",
    },
  ];

  const formatTimeTo12Hour = (time24Hour) => {
    const dummyDate = new Date(`2000-01-01T${time24Hour}`);
    const formattedTime = dummyDate.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    return formattedTime;
  };

  async function fetchData() {
    try {
      setLoadingState(true);
      const token = localStorage.getItem("access_token");
      dispatch(setLoading(true));

      const response = await getAttendence(
        token,
        formData,
        page,
        pageLimit,
        sortKey
      );
      setTotalPages(response?.getAttendance?.totalPages);

      setCurrentPage(response?.getAttendance?.currPage);
      setTotalCount(response?.getAttendance?.totalCount);
      const modifiedData = [...response?.getAttendance?.data]?.map(
        (attendance, index) => {
          return {
            data: [
              attendance?.student_id ? attendance?.student_id : "-",
              dateFormatter(attendance?.date)
                ? dateFormatter(attendance?.date)
                : "-",
              attendance?.student_full_name
                ? `${attendance?.student_full_name}`
                : "-",
              attendance?.parent1_first_name && attendance?.parent1_last_name
                ? `${attendance?.parent1_first_name} ${attendance?.parent1_last_name}`
                : "-",
              attendance?.parent2_first_name && attendance?.parent2_last_name
                ? `${attendance?.parent2_first_name} ${attendance?.parent2_last_name}`
                : "-",
              attendance?.class_enrolled ? attendance?.class_enrolled : "-",
              attendance?.time ? formatTimeTo12Hour(attendance?.time) : "-",
              attendance?.class_id ? attendance?.class_id : "-",
              attendance?.enrollment_type ? attendance?.enrollment_type : "-",
            ],
            item: attendance,
          };
        }
      );
      setAttendance(modifiedData);
      dispatch(setLoading(false));
      setLoadingState(false);
    } catch (error) {
      dispatch(setLoading(false));
      setLoadingState(false);
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [page, pageLimit, sortKey]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (calenderRef.current && !calenderRef.current.contains(event.target)) {
        setCloseCalender(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleBtnExport = () => {
    setExportDrop(true);
  };

  const handleExport = (e) => {
    setExportDrop(false);
    if (e.target.value === "pdf") {
      const element = document.getElementById("tablePdfContent");
      if (!element) {
        console.error('Element with id "pdf-content" not found.');
        return;
      }
      const options = {
        margin: 0,
        filename: "myfile.pdf",
        // image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
      };
      html2pdf().from(element).set(options).save();
      toast.success("Report downloaded.");
    }

    if (e.target.value === "csv") {
      const tableContent = document.getElementById("tablePdfContent");
      if (!tableContent) {
        console.error("Table content not found.");
        return;
      }
      const csvData = convertToCSV(tableContent);
      downloadCSV(csvData, "mydata.csv");
    }
  };

  const handleDateChange = (index, key, value) => {
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const utcDate = new Date(
      Date.UTC(value.getFullYear(), value.getMonth(), value.getDate())
    );

    setWeekday(weekdays[utcDate.getUTCDay()]);

    // Format the date to the desired format (YYYY-MM-DD)
    const formattedDate = utcDate.toISOString().split("T")[0];
    setSelectedDate(formattedDate);

    const updatedData = [...formData];

    while (updatedData.length <= index) {
      updatedData.push({ selectUser: "", name: "" });
    }

    updatedData[index][key] = formattedDate;
    setFormData(updatedData);
  };

  function downloadCSV(content, filename) {
    const csvContent =
      "data:text/csv;charset=utf-8," + encodeURIComponent(content);
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function convertToCSV(table) {
    const rows = table.querySelectorAll("tr");
    const csv = [];
    for (const row of rows) {
      const rowData = [];
      const cells = row.querySelectorAll("td, th");
      for (const cell of cells) {
        rowData.push(cell.textContent.trim());
      }
      csv.push(rowData.join(","));
    }
    return csv.join("\n");
  }
  const debouncedFetchData = debounce(fetchData, 2000);

  useEffect(() => {
    const pattern = /[^ ]/;

    if (pattern.test(formData.name) || formData.name?.length == 0) {
      if (formData.name !== undefined) {
        const timeoutId = setTimeout(() => {
          debouncedFetchData(fetchData);
        }, 900);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [formData]);

  const handleSearch = () => {
    setCloseCalender(false);
    fetchData(formData);
  };

  const handleAddSearch = () => {
    setTemp((prevTemp) => prevTemp + 1);
  };

  const handleAdditionalFieldsChange = (index, key, value) => {
    setSelectedState(value);
    if (value == "date") {
      setCloseCalender(true);
    }
    const updatedData = [...formData];
    while (updatedData.length <= index) {
      updatedData.push({ selectUser: "", name: "" });
    }

    if (value !== updatedData[index][key]) {
      updatedData[index]["name"] = "";
    }

    updatedData[index][key] = value;

    setFormData(updatedData);
    fetchData(formData);
  };

  const getAllClassFunction = async () => {
    const token = localStorage.getItem("access_token");
    let getData;
    if (weekday) {
      getData = await getClassesByWeekdays(
        [weekday],
        token,
        isEnded,
        0,
        "100000"
      );
    } else {
      getData = await getClassesNew(token, isEnded, 0, "100000");
    }

    setAllClassApiData(getData?.data);
  };

  useEffect(() => {
    getAllClassFunction();
  }, [isEnded, weekday]);

  const renderAdditionalFields = () => {
    const additionalFields = [];
    const classOptions = [
      { value: "", label: "Select Class" },
      ...(Array.isArray(allClassApiData)
        ? allClassApiData
            .filter((val) => val?.name.trim() !== "")
            .map((val) => ({
              value: [{ id: val?.id, isCamp: val?.isCamp }],
              label: val.name + "(Id " + val?.id + ")",
            }))
        : []),
    ];

    for (let i = 0; i < temp; i++) {
      additionalFields.push(
        <React.Fragment key={i}>
          <div className="adminform__group">
            <select
              name={`selectUser_${i}`}
              id={`selectUser_${i}`}
              className="adminform__select"
              onChange={(e) =>
                handleAdditionalFieldsChange(i, "selectUser", e.target.value)
              }
              onClick={(e) => {
                e.target.value === "date" && setCloseCalender(true);
              }}
              value={formData[i]?.selectUser}
            >
              <option value="" selected disabled>
                Search
              </option>
              <option value="studentName">Student Name</option>
              <option value="inputName">Parent Name</option>
              <option value="classEnrolled">Class Enrolled</option>
              <option value="date">Select Date</option>
            </select>
          </div>
          {formData[i]?.selectUser === "date" ? (
            <div ref={calenderRef}>
              <DatePicker
                className="adminform__input"
                selected={
                  formData[i]?.name
                    ? new Date(`${formData[i]?.name}T00:00:00`)
                    : null
                }
                onChange={(date) => handleDateChange(i, "name", date)}
                dateFormat="MM/dd/yyyy"
                placeholderText="MM/DD/YYYY"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                name={`name_${i}`}
              />
            </div>
          ) : formData[i]?.selectUser === "classEnrolled" ? (
            <Select
              styles={customStyles}
              classNamePrefix="custom-select"
              options={classOptions}
              placeholder={"Select Class"}
              onChange={(option) =>
                handleAdditionalFieldsChange(i, "name", option.value)
              }
            />
          ) : (
            <input
              type="text"
              className="adminform__input"
              placeholder="Enter Name"
              name={`name_${i}`}
              onChange={(e) =>
                handleAdditionalFieldsChange(i, "name", e.target.value)
              }
              value={formData[i]?.name}
              disabled={!formData[i]?.selectUser}
            />
          )}
        </React.Fragment>
      );
    }
    return additionalFields;
  };

  const handleCancelAdditionalFields = (index) => {
    setTemp((prevTemp) => {
      return prevTemp > 1 ? prevTemp - 1 : prevTemp;
    });

    setFormData((prevSearchParams) => {
      const newSearchParams = [...prevSearchParams];
      newSearchParams[index] = { selectUser: "", name: "" };
      const filteredSearchParams = newSearchParams.filter(
        (item) => item.selectUser !== "" || item.name !== ""
      );
      return filteredSearchParams;
    });
  };

  const toglleClassListHandler = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setIsEnded(true);
    } else {
      setIsEnded(false);
    }
  };

  return (
    <>
      <div className="container--component">
        <div className="container--grid">
          <NavigationComponent />

          <div className="Adminpage">
            <Header
              title="Attendance"
              subTitle="(Manage Available Attendance)"
            />

            <section className="Adminpage__section">
              <h2 className="Adminpage__title">Search by:</h2>

              <div style={{ margin: "20px 0px 5px" }}>
                <input
                  type="checkbox"
                  id="classFilterId"
                  onChange={(e) => toglleClassListHandler(e)}
                />
                <label htmlFor="classFilterId">
                  &nbsp; Don't show classes that have ended
                </label>
              </div>

              <div className="Adminpage__head">
                <div className="Adminpage__filter">
                  {renderAdditionalFields()}

                  {temp <= 1 ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className="adminform__cancel-button"
                      onClick={() => handleCancelAdditionalFields(1)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}

                  <div
                    className=""
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className="adminbtn adminbtn-light"
                      onClick={handleAddSearch}
                      disabled={temp == 2 ? true : false}
                      style={{
                        display: temp == 2 ? "none" : "block",
                        marginTop: "8px",
                      }}
                    >
                      and
                    </button>
                    <button
                      className="adminbtn adminbtn-primary"
                      onClick={handleSearch}
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.96326 9.16769L12.3473 11.569M11.2455 6.05994C11.2455 8.49399 9.27231 10.4672 6.83826 10.4672C4.40421 10.4672 2.43103 8.49399 2.43103 6.05994C2.43103 3.62589 4.40421 1.65271 6.83826 1.65271C9.27231 1.65271 11.2455 3.62589 11.2455 6.05994Z"
                          stroke="white"
                          strokeWidth="1.10181"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Search
                    </button>
                  </div>
                </div>
                <div className="attendance_subtitle">
                  <div className="attendance_subtitle_info">
                    <label class="switch">
                      <input type="checkbox" checked={true} />
                      <span
                        class="slider round"
                        style={{ cursor: "auto" }}
                      ></span>
                    </label>
                    <span className="attendance_subtitle_heading">Present</span>
                  </div>

                  <div className="attendance_subtitle_info">
                    <label class="switch">
                      <input type="checkbox" checked={false} />
                      <span
                        class="slider round"
                        style={{ cursor: "auto" }}
                      ></span>
                    </label>
                    <span className="attendance_subtitle_heading">Absent</span>
                  </div>
                </div>
                <button
                  className="adminbtn adminbtn-light export_btn_attendence"
                  id="btnCategory"
                  onClick={handleBtnExport}
                >
                  Export
                </button>
                {exportDrop && (
                  <select
                    className="adminform__select"
                    onChange={(e) => {
                      handleExport(e);
                    }}
                  >
                    <option value="Select format" selected disabled>
                      Select format
                    </option>
                    <option value="csv">Download as csv</option>
                  </select>
                )}
              </div>

              <TableNew
                loadingState={loadingState}
                headerData={_tabelHeadersData}
                bodyData={attendance}
                totalPages={totalPages}
                tableName="Attendance"
                setPageLimit={setPageLimit}
                pageLimit={pageLimit}
                totalCount={totalCount}
                currentPage={currentPage}
                setPdfStateValue={setPdfStateValue}
                setSortKey={setSortKey}
              />
            </section>
          </div>
        </div>
      </div>
      {showDatePicker && <input type="text" />}
    </>
  );
};

export default Attendence;
