import axios from "axios";
import { GET_ATTENDENCE } from "../adminQueries/Attendance";
import { useDispatch } from "react-redux";
import store from "../../store/store";
import { openSessionModal } from "../../reducers/sessionHandler";

export async function getAttendence(token, formData, page, pageLimit, sortKey) {
  const currentDate = new Date().toISOString().split('T')[0];
  const data = formData?.map((item) => {
    return {
      [item.selectUser]: Array?.isArray(item.name)
        ? item?.name?.map((item) => ({ id: item?.id, isCamp: item?.isCamp }))
        : item.name,
    };
  });

  var variables = {};
  data.forEach(function (item) {
    var key = Object.keys(item)[0];
    var value = item[key];
    if (key === "classEnrolled") {
      variables[key] = value;
    } else {
      variables[key] = value.trim();
    }
  });

  if (Object.keys(variables).length === 0 && variables.constructor === Object) {
    variables["date"] = currentDate;
  }

  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_ATTENDENCE,
        variables: {
          pageInfo: {
            limit: parseInt(pageLimit),
            page: page,
            sort: sortKey?.sortId === null ? "0" : sortKey?.sortId?.toString(),
            isAsc: sortKey?.isAsc,
          },
          ...variables,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching Attendence details from GraphQL", error);
  }
}

export const deleteStudent = async (data, token) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_BASE_URL + `/admin/student/delete/${data}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting student:", error);
    throw error;
  }
};

export const updateAttendence = async (
  token,
  classId,
  studentId,
  prs,
  attendanceId
) => {
  const studInt = parseInt(studentId, 10);
  const clasId = parseInt(classId, 10);

  try {
    const response = await axios.put(
      process.env.REACT_APP_BASE_URL + `/admin/attendance/${attendanceId}`,
      prs,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data;
  } catch (error) {
    return error;
    throw new Error(error);
  }
};
export default getAttendence;
