import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { staffPageContent } from "../api/contentApi";
import { getSectionCMS } from "../../Admin/adminApi/mastermenu";
import FounderImage from "../parent-assets/images/Bonni+Michael_Blog1.jpg";
import axios from "axios";
import { toast } from "react-toastify";
import AboutUsShimmer from "../../Admin/components/ShimmerUi/AboutUsShimmer";

function Staff() {
  const token = localStorage.getItem("access_token");
  const [sectionData, setSectionData] = useState([]);
  const [showStaffImage, setShowStaffImage] = useState(false);
  const [showShimmer, setShowShimmer] = useState(false);
  const [staffInfo, setStaffInfo] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      const sessionTimeOut = setTimeout(() => {
        localStorage.removeItem("access_token");
        navigate("/");
      }, 86400000);
      return () => clearTimeout(sessionTimeOut);
    }
  }, [navigate]);

  useEffect(() => {
    getStaffImages();
  }, []);

  const getStaffImages = async () => {
    setShowStaffImage(false);
    setShowShimmer(true);
    const section = await getSectionCMS(token, 169);
    setSectionData(section);
    setShowShimmer(false);
    setShowStaffImage(true);

    getTeachersData();
  };

  const getTeachersData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + `/admin/get_staff_cms`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStaffInfo(response?.data?.data);
    } catch (error) {
      toast.error("Error while getting Teachers data.");
    }
  };

  return (
    <>
      {showShimmer ? (
        <AboutUsShimmer component={"Staff"} />
      ) : (
        <div className="contentwraper staff_contentwraper">
          <section style={{ position: "relative" }}>
            <section className="siteBanner" style={{ background: "#F79727" }}>
              <div className="siteBanner__overlay">
                <img
                  className="siteBanner__img"
                  src={
                    sectionData?.length > 0 &&
                    sectionData[0]?.imageSection?.image
                  }
                  onError={(e) => {
                    e.target.onerror = null || "";
                    e.target.src = staffPageContent.bannerImage.url;
                  }}
                  alt="about-us-banner"
                />
              </div>
            </section>
          </section>
          <section className="breadcrumbSec">
            <div className="container">
              <div className="breadcrumb">
                <div className="breadcrumb__item">
                  <Link
                    className="breadcrumb__link"
                    to="/"
                    onClick={() => navigate(-1)}
                  >
                    Back to results
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="staffSec camp_sec">
            <div className="container">
              <div className="staffData">
                <div className="staffData__row">
                  <div className="staffData__col">
                    <div className="content ">
                      <h1
                        className="title title--h1 title--extrabold"
                        style={{ marginBottom: "1rem" }}
                      >
                        {staffPageContent?.founder?.title}
                      </h1>
                      <div className="shortText">
                        <p>
                          {sectionData?.length > 0
                            ? sectionData[1]?.descriptionSection?.description
                            : staffPageContent?.founder?.content}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="staffData__col">
                    <div className="picture">
                      <div className="picture__box staff_founder_picture_box">
                        {sectionData?.map(
                          (item) =>
                            item?.section_name === "The Founders" && (
                              <img
                                src={
                                  item?.imageSection?.image
                                    ? item?.imageSection?.image
                                    : FounderImage
                                }
                                alt="Founders"
                                onError={(e) => {
                                  e.target.onerror = null || "";
                                  e.target.src = FounderImage;
                                }}
                              />
                            )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ourStaff">
                <div className="ourStaff__topblock">
                  <h2 className="title title--h1 title--extrabold">
                    Our Staff
                  </h2>
                </div>
                <div className="our_staff_list_container_fluid">
                  <div className="ourStaff__list">
                    {staffInfo?.map((val, index) => {
                      return (
                        <div className="ourStaff__list__item" key={val?.id}>
                          <div className="ourStaff__list__card">
                            <div
                              className={`picture  ${val?.className} ourStaff_picture_name`}
                            >
                              <h3
                                className="staff_name"
                                style={{
                                  color:
                                    index == 0
                                      ? "#c3d940"
                                      : index == 1
                                      ? "#00bbe3"
                                      : index == 2
                                      ? "#f79727"
                                      : index == 3
                                      ? "#c3d940"
                                      : index == 4
                                      ? "#00bbe3"
                                      : index == 5
                                      ? "#f79727"
                                      : index == 6
                                      ? "#ea519c"
                                      : index == 7
                                      ? "#00bbe3"
                                      : index == 8
                                      ? "#f79727"
                                      : index == 9
                                      ? "#593f98"
                                      : index == 10
                                      ? "#c3d940"
                                      : "#ea519c",
                                }}
                              >
                                {val?.staff_name}
                              </h3>
                              <h4 className="staff_position">
                                {val?.staff_role}
                              </h4>
                              <h6 className="staff_email">
                                {val?.staff_email}
                              </h6>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default Staff;
