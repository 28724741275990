import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { aboutPageContent } from "../../../api/contentApi";
import cheerImage from "../../../parent-assets/images/enrichment-images/cheerImage.png";
import OwlCarousel from "react-owl-carousel";
import { useEffect, useState } from "react";
import galleryImg1 from "../../../parent-assets/images/Facilities-carousel/Art01.jpg";
import galleryImg2 from "../../../parent-assets/images/Facilities-carousel/Art02.jpg";
import galleryImg3 from "../../../parent-assets/images/Facilities-carousel/Art03.jpg";
import { Fancybox } from "@fancyapps/ui";
import { toast } from "react-toastify";
import { getCampProgramClassDetails } from "../../../../Admin/adminApi/program";
import gymnasticeLogo from "../../../parent-assets/images/gymtimeGuy.png";
import AboutUsShimmer from "../../../../Admin/components/ShimmerUi/AboutUsShimmer";
import axios from "axios";
import CarasouelShimmer from "../../../parent-components/Shimmers/CarasouelShimmer";

const TeamCheerGymnas = () => {
  const { id } = useParams();
  const token = localStorage.getItem("access_token");
  const { state } = useLocation();
  const [showShimmer, setShowShimmer] = useState(false);
  const [prefilledData, setPrefilledData] = useState();
  const [teamsGalleryImg, setTeamsGalleryImg] = useState();
  const navigate = useNavigate();
  const [galleryShimmer, setGalleryShimmer] = useState(false);
  useEffect(() => {
    getGalleryInfoData();
  }, []);
  useEffect(() => {
    const fancyboxOptions = {};
    Fancybox.bind("[data-fancybox='gallery']", fancyboxOptions);
    getTeamGymnasticsData();
  }, [id]);

  const getGalleryInfoData = async () => {
    try {
      setGalleryShimmer(true);
      const postData = {
        facility_id: parseInt(id),
      };
      const responseImage = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/get_gallery`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTeamsGalleryImg(responseImage?.data?.data);
      setGalleryShimmer(false);
    } catch (error) {
      console.error("Error while generating gallery info.", error);
      toast.error(error);
    }
  };

  const getTeamGymnasticsData = async () => {
    try {
      setShowShimmer(true);
      const response = await getCampProgramClassDetails(parseInt(id));
      setPrefilledData(response?.data?.data);
      setShowShimmer(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const responsiveOptions = {
    0: {
      items: 1,
    },
    768: {
      items: 3,
    },
    1024: {
      items: 4,
    },
  };
  return (
    <>
      {showShimmer ? (
        <AboutUsShimmer />
      ) : (
        <div className="contentwraper" style={{ paddingBottom: "0px" }}>
          <section style={{ position: "relative" }}>
            <section className="siteBanner" style={{ background: "#ea519c" }}>
              <div className="siteBanner__overlay">
                <img
                  className="siteBanner__img"
                  src={
                    prefilledData?.[0]?.header_image
                      ? prefilledData?.[0]?.header_image
                      : aboutPageContent.aboutBannerImage.url
                  }
                  alt="banner"
                />
              </div>
            </section>
          </section>

          {/* BODY  */}
          <section className="breadcrumbSec">
            <div className="container">
              <div className="breadcrumb">
                <div className="breadcrumb__item">
                  <Link
                    className="breadcrumb__link"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Back to results
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="facilitiesSec team_cheer_gym_faci">
            <div className="container team_cheer_gym_faci_container">
              <div
                className="picture program_picture program_picture_responsive"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "0px 0px",
                }}
              >
                <img
                  src={gymnasticeLogo}
                  alt="gymtimeGuy"
                  className="picture__img cheer_guy"
                />
                {/* <TeamGymnastic /> */}
                {id == "307" || id == "6179" ? (
                  <h1 className="title title--withicon title--extrabold">
                    {" "}
                    Team Cheer
                  </h1>
                ) : (
                  <h1 className="title title--extrabold">
                    {state?.flag !== undefined
                      ? prefilledData?.[0]?.name
                      : prefilledData?.[0]?.camp_secondary_name
                      ? prefilledData?.[0]?.camp_secondary_name
                      : prefilledData?.[0]?.name}
                  </h1>
                )}
              </div>

              {/* <section className="aboutusSec" style={{ paddingBottom: "2px" }}>
            <div className="container">
              <div className="aboutData">
                <div className="aboutData__box">
                  <div
                    className="aboutData__row"
                    style={{
                      display: "flex",
                      gap: "30px",
                      alignItems: "center",
                      height: "min-content",
                    }}
                  >
                    <div className="aboutData__col">
                      <div className="content">
                        <h2 className="girl_gymnastics_content">
                          {prefilledData?.[0]?.description
                            ? prefilledData?.[0]?.description
                            : `The athletes that are selected to represent Gymtime’s Cheer Team exhibit a high physical skill level in all aspects of the sport as well as excellent teamwork and work ethic. Each year the team puts a lot of time and effort into creating, learning and performing an original 2:30 minute routine. This showcases their most difficult skills, synchronized to fast pace, high energy music, designed to captivate and audience and impress judges!
Whether your child is a novice or experienced, our cheer program will focus on building the fundamentals of a well-rounded cheerleader. The athletes will build strength and flexibility while focusing on stunting, tumbling, jumping and motion technique.`}
                        </h2>
                      </div>
                      <div className="team_gym_cheer_email">
                        <h1 className="cheer_dir_email">
                          Email the Team Director : Info@gymtime.com
                        </h1>
                      </div>
                    </div>
                    <div className="aboutData__col">
                      <img
                        src={
                          prefilledData?.length > 0
                            ? prefilledData?.[0]?.image
                            : cheerImage
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

              <div
                className="picture class_detail_image_container team_cheer_div"
                style={{ float: "left" }}
              >
                <img
                  className="picture__img"
                  src={
                    prefilledData?.length > 0
                      ? prefilledData?.[0]?.image
                      : cheerImage
                  }
                  alt="CLASS IMAGES"
                />
                <p className="class_details_shortText_container">
                  {prefilledData?.[0]?.subprogramdesc2
                    ? prefilledData?.[0]?.subprogramdesc2
                    : `The athletes that are selected to represent Gymtime’s Cheer Team exhibit a high physical skill level in all aspects of the sport as well as excellent teamwork and work ethic. Each year the team puts a lot of time and effort into creating, learning and performing an original 2:30 minute routine. This showcases their most difficult skills, synchronized to fast pace, high energy music, designed to captivate and audience and impress judges!
Whether your child is a novice or experienced, our cheer program will focus on building the fundamentals of a well-rounded cheerleader. The athletes will build strength and flexibility while focusing on stunting, tumbling, jumping and motion technique.`}
                </p>
              </div>

              {/* Body section starts */}

              <div className="team_cheer_gymnastics_container_fluid">
                <div className="team_classes_type_heading_container">
                  <span className="team_classes_type_heading">
                    {state?.campVersionDetails?.map((item) => item?.name)}
                  </span>
                  <span className="team_classes_type_desc">
                    {" "}
                    
                      
                    
                    {prefilledData?.[0]?.camp_heading_first}
                  </span>
                </div>
                <div className="team_gym_cheer_email">
                  <h1 className="cheer_dir_email">
                    Email the Director : {prefilledData?.[0]?.camp_heading_second}
                  </h1>
                </div>

                <div className="cheer_gym_box_container_fluid">
                  <div className="blue_box_container">
                    {prefilledData?.length > 0 ? (
                      prefilledData?.map((item) => (
                        <div
                          className="team_cheer_gymnastics_blue_box_text"
                          key={item?.id}
                        >
                          <p
                            style={{ color: "white" }}
                            className="team_cheer_gymnastics_bule_box_text"
                            dangerouslySetInnerHTML={{
                              __html: item?.content?.[0]?.first_one
                                ? item?.content?.[0]?.first_one
                                : `<p><strong style=\"font-size: 24px;\">Mandatory Practice</strong> </p><p>Thursday 3:30-630</p><p>Additional Pop Up classes close</p><p>to completion.</p>`,
                            }}
                          ></p>
                        </div>
                      ))
                    ) : (
                      <div className="team_cheer_gymnastics_blue_box_text">
                        <p
                          style={{ color: "white" }}
                          className="team_cheer_gymnastics_bule_box_text"
                          dangerouslySetInnerHTML={{
                            __html: `<p><strong style=\"font-size: 24px;\">Mandatory Practice</strong> </p><p>Thursday 3:30-630</p><p>Additional Pop Up classes close</p><p>to completion.</p>`,
                          }}
                        ></p>
                      </div>
                    )}
                  </div>

                  <div className="pink_box_container">
                    {prefilledData?.length > 0 ? (
                      prefilledData?.map((item) => (
                        <div
                          className="team_cheer_gymnastics_pink_box_text"
                          key={item?.id}
                        >
                          <p
                            style={{ color: "white" }}
                            dangerouslySetInnerHTML={{
                              __html: item?.content?.[1]?.second_one
                                ? item?.content?.[1]?.second_one
                                : `<p><strong style=\"font-size: 24px;\">Try Outs!August</strong> </p><p> One on one during the year for new students.</p>`,
                            }}
                          ></p>
                        </div>
                      ))
                    ) : (
                      <div className="team_cheer_gymnastics_pink_box_text">
                        <p
                          style={{ color: "white" }}
                          dangerouslySetInnerHTML={{
                            __html: `<p><strong style=\"font-size: 24px;\">Try Outs!August</strong> </p><p> One on one during the year for new students.</p>`,
                          }}
                        ></p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="container">
                <section className="facilitiesSec">
                  <h1 className="title title--extrabold">Take a Look!</h1>
                  {galleryShimmer ? (
                    <CarasouelShimmer />
                  ) : (
                    <OwlCarousel
                      margin={10}
                      responsiveclassname="true"
                      responsive={responsiveOptions}
                      className="galleryGrid owl-carousel"
                      autoplay
                      loop={true}
                      arrows="true"
                      dots={false}
                      nav={true}
                      lazyLoad={true}
                      navText={[
                        `<img src=${require("../../../parent-assets/images/enrichment-images/arrow-left.png")} />`,
                        `<img src=${require("../../../parent-assets/images/enrichment-images/arrow-right.png")} alt='right' />`,
                      ]}
                    >
                      {teamsGalleryImg?.map((val) => {
                        return (
                          <div className="galleryGrid__item" key={val?.id}>
                            <div className="galleryGrid__card">
                              <img
                                className="galleryGrid__card__img"
                                src={val?.image}
                                alt=""
                              />
                            </div>
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  )}
                </section>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default TeamCheerGymnas;
