const GET_NOTIFICATIONS = `
query GetDetailsForNotifications($pageInfo: PaginationInfo,  $classId: Int,  $categoryIds: [Int],  $parent1Name: String, $parent2Name: String, $studentName: String, $classIds: [String]) {
    getDetailsForNotifications(pageInfo: $pageInfo,categoryIds : $categoryIds , classId : $classId ,  parent1Name:  $parent1Name, parent2Name: $parent2Name, studentName: $studentName, classIds:$classIds) {
      data {
        id
        parent1_name
        parent2_name
        student_name
        parent1_email
        class_name
        dummy_id
      }
      totalCount
      totalPages
      limit
      currPage
    }
  }

`;

export {
    GET_NOTIFICATIONS
};
