export const GET_AGE_CLASSES = `
query GetClassesCMSForBrowseByAge($limit: Int!, $page: Int!, $ageRangeId: Int!,$ageRange: String) {
    getClassesCMSForBrowseByAge(limit: $limit, page: $page, age_range_id: $ageRangeId,age_range: $ageRange) {
      data {
        id
        image
        name
        is_active
        header_image
        description
        description2
        categoryId
        min_age
        max_age
      }
      currPage
      totalPages
      totalRows
    }
  }
`;

export const GET_AGE_CLASSES_NEW = `
query GetClassesCMSAdminBrowseByAge($ageRangeId: Int!, $page: Int!, $limit: Int!, $className: String, $ageRange: String) {
  getClassesCMSAdminBrowseByAge(age_range_id: $ageRangeId, page: $page, limit: $limit, className: $className, age_range: $ageRange) {
    currPage
    data {
      id
        image
        name
        is_active
        description
        description2
        header_image
        categoryId
        hidden
    }
    totalPages
    totalRows
  }
}
`;

export const GET_CLASS_FOR_MAKEUP = `
query getClassesForMakeUp($date: String, $pageInfo: PaginationInfo , $className: String){
  getClassesForMakeUp(date: $date, pageInfo: $pageInfo, className: $className) {
    data {
      id
      fk_season_classes
      name
      fk_category_classes
      categoryId
      tuition
      max_age
      min_age
      website
      hidden
      max_strength
      gender
      period
      fk_teachers_classes
      start_date
      end_date
      weekday
      category_name
      season_name
      is_combo
   
      schedules {
        id
        teacherId
        start_time
        end_time
        weekday
        name  
        max_strength
        subject
      }
      makeUpClasses {
        id
        fk_class_makeup_classes
        fk_teacher_makeup_classes
        date
        start_time
        end_time
        slots
      }
  }
  totalCount
  totalPages
  limit
  currPage
  }
}
`;
