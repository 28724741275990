import React from "react";
import OwlCarousel from "react-owl-carousel";
import { useEffect, useState } from "react";
import galleryImg1 from "../../../parent-assets/images/Facilities-carousel/Art01.jpg";
import galleryImg2 from "../../../parent-assets/images/Facilities-carousel/Art02.jpg";
import galleryImg3 from "../../../parent-assets/images/Facilities-carousel/Art03.jpg";
import cheerlogo from "../../../parent-assets/images/enrichment-images/cheer.png";
import cheerImage from "../../../parent-assets/images/enrichment-images/cheerImage.png";
import { Fancybox } from "@fancyapps/ui";
import { aboutPageContent } from "../../../api/contentApi";
import { Link } from "react-router-dom";
const AdvanceGym = () => {
  const responsiveOptions = {
    0: {
      items: 1,
    },
    768: {
      items: 3,
    },
    1024: {
      items: 4,
    },
  };
  return (
    <>
      <div className="contentwraper">
        <section style={{ position: "relative" }}>
          <section className="siteBanner" style={{ background: "#ea519c" }}>
            <div className="siteBanner__overlay">
              <img
                className="siteBanner__img"
                src={aboutPageContent.aboutBannerImage.url}
                alt="banner"
              />
            </div>
          </section>
        </section>

        {/* BODY  */}
        <section className="breadcrumbSec">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">
                  Back to results
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="facilitiesSec team_cheer_gym_faci">
          <div className="container team_cheer_gym_faci_container">
            <div className="facility_gymTimeGuywithName">
              <img
                src={cheerlogo}
                alt="gymtimeGuy"
                className="gymTimeGuyIcon_fac"
              />

              <h1 className="title title--withicon title--extrabold">
                Advance gymnastics
              </h1>
            </div>

            <section className="aboutusSec" style={{ paddingBottom: "2px" }}>
              <div className="container">
                <div className="aboutData">
                  <div className="aboutData__box">
                    <div
                      className="aboutData__row"
                      style={{
                        display: "flex",
                        gap: "30px",
                        alignItems: "center",
                        height: "min-content",
                      }}
                    >
                      <div className="aboutData__col">
                        <div className="content">
                          <h2 className="girl_gymnastics_content">
                            The athletes that are selected to represent
                            Gymtime’s Cheer Team exhibit a high physical skill
                            level in all aspects of the sport as well as
                            excellent teamwork and work ethic. Each year the
                            team puts a lot of time and effort into creating,
                            learning and performing an original 2:30 minute
                            routine. This showcases their most difficult skills,
                            synchronized to fast pace, high energy music,
                            designed to captivate and audience and impress
                            judges! Whether your child is a novice or
                            experienced, our cheer program will focus on
                            building the fundamentals of a well-rounded
                            cheerleader. The athletes will build strength and
                            flexibility while focusing on stunting, tumbling,
                            jumping and motion technique.
                          </h2>
                        </div>
                        <div className="team_gym_cheer_email">
                          <h1 className="cheer_dir_email">
                            Email the Advance gymnastics Director :
                            Info@gymtime.com
                          </h1>
                        </div>
                      </div>
                      <div className="aboutData__col">
                        <img src={cheerImage} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Body section starts */}

            <div className="team_cheer_gymnastics_container_fluid">
              <div className="team_classes_type_heading_container">
                <span className="team_classes_type_heading">
                  Advance gymnastics
                </span>
                <span className="team_classes_type_desc">
                  Available from July 1st-August 13th, Gymnists will do
                  gymnastics all summer long. Learning new skill and building
                  strength/flexibility.
                </span>
              </div>
              <div className="team_gym_cheer_email">
                <h1 className="cheer_dir_email">
                  Email the Advance gymnastics Director : Info@gymtime.com
                </h1>
              </div>

              <div className="cheer_gym_box_container_fluid">
                <div className="blue_box_container">
                  <h1 className="mandatory_heading">Mandatory Practice</h1>
                  <h1 className="day_time_mandatory_heading">
                    Tuesday 3:30-6:30
                  </h1>
                  <h2 className="mandatory_practice_desc">
                    Additional pop up choose close to competion
                  </h2>
                </div>

                <div className="pink_box_container">
                  <h1 className="mandatory_heading">Try Outs!August</h1>
                  <h2 className="mandatory_practice_desc">
                    One on one during the year for new students.
                  </h2>
                </div>
              </div>
            </div>

            <section className="facilitiesSec">
              <div className="container">
                <h1 className="title title--extrabold">Take a Look!</h1>
                <OwlCarousel
                  margin={10}
                  responsiveclassname="true"
                  responsive={responsiveOptions}
                  className="galleryGrid owl-carousel"
                  autoplay
                  loop={true}
                  arrows="true"
                  dots={false}
                  nav={true}
                  lazyLoad={true}
                  navText={[
                    `<img src=${require("../../../parent-assets/images/enrichment-images/arrow-left.png")} />`,
                    `<img src=${require("../../../parent-assets/images/enrichment-images/arrow-right.png")} alt='right' />`,
                  ]}
                >
                  <div className="galleryGrid__item">
                    <div className="galleryGrid__card">
                      <a
                        className="galleryGrid__card__link"
                        data-fancybox="gallery"
                        href="../images/gallery-img01.png"
                      >
                        <img
                          className="galleryGrid__card__img"
                          src={galleryImg1}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div className="galleryGrid__item">
                    <div className="galleryGrid__card">
                      <a
                        className="galleryGrid__card__link"
                        data-fancybox="gallery"
                        href={galleryImg2}
                      >
                        <img
                          className="galleryGrid__card__img"
                          src={galleryImg2}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div className="galleryGrid__item">
                    <div className="galleryGrid__card">
                      <a
                        className="galleryGrid__card__link"
                        data-fancybox="gallery"
                        href={galleryImg3}
                      >
                        <img
                          className="galleryGrid__card__img"
                          src={galleryImg2}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div className="galleryGrid__item">
                    <div className="galleryGrid__card">
                      <a
                        className="galleryGrid__card__link"
                        data-fancybox="gallery"
                        href={galleryImg2}
                      >
                        <img
                          className="galleryGrid__card__img"
                          src={galleryImg1}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  );
};

export default AdvanceGym;
