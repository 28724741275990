import React from 'react'
import { aboutPageContent } from '../../api/contentApi'
import { Link } from 'react-router-dom'
import campLogo from "../../parent-assets/images/enrichment-images/camp-logo.png";
import UpperImage from "../../parent-assets/images/about-us-img01.png"
import { dummyCampDetailDatas } from './dummyCampDetail';
const GirlDayCampDetailPage = () => {
    return (
        <>
            <div className="contentwraper" data-toggle="modal" data-target="#LoginModal">
                <section style={{ position: "relative" }}>
                    <section className="siteBanner" style={{ background: "#ea519c" }}>
                        <div className="siteBanner__overlay">
                            <img
                                className="siteBanner__img"
                                src={aboutPageContent.aboutBannerImage.url}
                                alt="banner"
                            />
                        </div>
                    </section>
                  
                </section>

                {/* BODY  */}
                <section className="breadcrumbSec">
                    <div className="container">
                        <div className="breadcrumb">
                            <div className="breadcrumb__item">
                                <Link className="breadcrumb__link" to="/">
                                    Back to results
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                {/* CONTENT  */}
                <section class="aboutusSec">
                    <div class="container">
                        <div
                            className=""
                            style={{
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                gap: "20px",
                                marginBottom: "20px",
                            }}
                        >
                            <img
                                style={{ width: "140px" }}
                                src={campLogo}
                                alt="gymtimeGuy"
                                className="gymTimeGuyIcon_fac"
                            />
                           <div className="gir_gymnastics_title_container">
                           <h1 className="title title--withicon title--extrabold">
                               Girls Gymnastic Camp
                            </h1>
                            <h3>5+ Years</h3>
                           </div>
                            
                        </div>

                        <div class="aboutData">
                            <div class="aboutData__box">
                                <div class="aboutData__row camp-row">
                                    <div class="aboutData__col">
                                        <div class="content">
                                            <div class="shortText">
                                                <p className="enrichment_combos_shorttext">
                                                60-minute Developmental Gymnastics class, tailored for ages 
                                                5 and up, where gymnasts master techniques like handstands, 
                                                cartwheels, and more while building strength, flexibility, and confidence.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutData__col ">
                                        <div className="camp-detail-page-picture">
                                            <img className=" dummy-image" src={UpperImage} alt="About Us" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* LOWER SECRTION  */}

                        <div className="classAvailability">
                            <div className="classAvailability__header"
                                style={{ display: "flex", gap: "30px", alignItems: "center" }}
                            >
                                <h2
                                    className="title title--h4"
                                    style={{ background: "none" }}
                                >
                                    Add Students for This Programs :
                                </h2>
                                <button className="btn btn--primary">
                                    + Add Student
                                </button>
                            </div>

                            <div className="class_availaabily-container-fluid">
                                <h3 className="title title--h4">Class Availability</h3>
                                <div className="class_availabilty_container">
                                    {
                                        dummyCampDetailDatas?.map((items) => (
                                            <div>
                                                <div className="class_list_item_container_row">
                                                    <div className="class_list_item_container_col">
                                                        <span className='classDetail_week_number'>{items?.week}</span>
                                                        <span className="camp_class_name">{items?.class_name}</span>
                                                        <div className="_camp_year_subtitle">{items?.subtitle}</div>
                                                        <div className="camp_class_date">
                                                            <h2 className='camp_date_heading_title'>{items?.date}</h2>
                                                        </div>
                                                    </div>
                                                    <div className="class_list_item_container_col">
                                                        <div className="camp_class_days">{items?.weekdays}</div>
                                                        <div className="camp_class_days">{items?.timmming}</div>
                                                        <div className="camp_class_type">{items?.class_type}</div>
                                                    </div>
                                                    <div className="class_list_item_container_col">
                                                        {/* <h2 className='class_camp_slot_left'>{items?.slots_left} slots!</h2> */}
                                                        <button className="_add_to_cart_camp_btn">
                                                            Add to cart
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="camp_horizontal_line"></div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


            </div>
        </>
    )
}

export default GirlDayCampDetailPage;