import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getAllStudentEnrollments, getInvoice } from "../../api/parent-profile";
import { formatDate } from "../../utility/postRequests";
import LoaderComponent from "../../../Admin/components/LoaderComponent.jsx";
import classRoomIcon from "../../parent-assets/images/classroom-icon.svg";
import { openSessionModal } from "../../../reducers/sessionHandler.js";
import store from "../../../store/store.js";
import { useNavigate } from "react-router-dom";

function EnrollmentTabContent({ activeTab }) {
  const [studentEnroll, setStudentEnrollment] = useState();
  const [birthdayDetails, setBirthdayDetails] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");

  const enrollmentHandler = async () => {
    setLoading(true);
    try {
      const enrollmentResponse = await getAllStudentEnrollments(token);

      setLoading(false);
      setStudentEnrollment(
        enrollmentResponse?.getAllStudentEnrollments?.studentEnrolled
      );
      setBirthdayDetails(
        enrollmentResponse?.getAllStudentEnrollments?.birthdayOrders
      );
      if (
        enrollmentResponse.data.errors &&
        enrollmentResponse.data.errors[0]?.message === "Unauthorised."
      ) {
        setTimeout(() => {
          store?.dispatch(openSessionModal(true));
        }, 1000);
      }
      // setShowProgramData(response);
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab == "tab3") {
      enrollmentHandler();
    }
  }, [activeTab]);

  function convertTo12HourFormat(timeStr) {
    const [hours, minutes] = timeStr.split(":").map(Number);
    const period = hours < 12 ? "am" : "pm";
    const hours12 = hours % 12 || 12;
    const formattedTime = `${hours12}:${String(minutes).padStart(
      2,
      "0"
    )} ${period}`;
    return formattedTime;
  }

  const InvoiceHandler = async (e, orderId, isBirthday) => {
    const element = document.createElement("div");
    document.body.appendChild(element);

    setLoading(true);
    try {
      const token = localStorage.getItem("access_token");
      const response = await getInvoice(orderId, token, isBirthday);

      if (response?.status == 200) {
        setTimeout(() => {
          setLoading(false);
        }, 500);

        localStorage.setItem("INVOICE_DATA", JSON.stringify(response?.data));
        window.open("/order-parent-invoice", "_blank");
      } else {
        setLoading(false);
        toast.error(response?.data?.error);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    }
  };

  return (
    <>
      {loading && <LoaderComponent />}
      {studentEnroll?.length ? (
        <>
          <h2 className="title title--h2 title--center">
            *Please review your the students you have enrolled.
          </h2>
          <div className="studentEnrolled parentForm2">
            <h3 className="title title--h1">Student Enrolled</h3>
            <div className="studentEnrolled__list">
              {studentEnroll.length > 0 &&
                studentEnroll?.map((val, index) => {
                  return (
                    <>
                      <div className="studentEnrolled__list__card" key={index}>
                        <div className="profileBox">
                          <div className="profileBox__info">
                            <div className="pic">
                              <img
                                className="pic__img"
                                src={val?.image}
                                alt=""
                              />
                            </div>
                            <div className="detail">
                              <h3 className="name">
                                {val?.first_name} &nbsp;
                                {val?.last_name}
                              </h3>
                              <p className="para">{val?.name}</p>
                              <p className="para para--position">GYMTIME</p>
                            </div>
                          </div>
                        </div>
                        <div className="seInfoCard">
                          <div className="seInfoCard__header">
                            <div className="seInfoCard__header__col">
                              Application
                            </div>
                            <div className="seInfoCard__header__col">
                              Application Status
                            </div>
                            <div className="seInfoCard__header__col">
                              Registration Fee
                            </div>
                          </div>
                          <div className="seInfoCard__body">
                            <div className="flexbox">
                              <div className="flexbox__col">
                                <div className="infobox">
                                  <div className="icon">
                                    <img
                                      className="icon__img"
                                      src={
                                        val?.class_image
                                          ? val?.class_image
                                          : classRoomIcon
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="infobox__content">
                                    <h4 className="title title--h4">
                                      {val?.name}
                                    </h4>
                                    <p className="para">
                                      {val?.classSchedules?.map(
                                        (time, index) => {
                                          return (
                                            <p>
                                              {convertTo12HourFormat(
                                                time?.start_time
                                              )}
                                              -
                                              {convertTo12HourFormat(
                                                time?.end_time
                                              )}
                                            </p>
                                          );
                                        }
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="flexbox__col">
                                <div className="dateInfo">
                                  {formatDate(val?.class_enrollment_start_date)}
                                </div>
                                <p
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  Enrolled
                                </p>
                              </div>
                              <div className="flexbox__col">
                                {val?.invoiceReady ? (
                                  <button
                                    className="btn btn--primary"
                                    type="button"
                                    onClick={(e) => {
                                      InvoiceHandler(e, val?.orderId, false);
                                    }}
                                  >
                                    View Invoice
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn--primary"
                                    type="button"
                                  >
                                    Contact Admin <br /> For Invoice
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        <div
          className="title title--center title--h3"
          style={{
            color: "#EA519C",
          }}
        >
          No Class Enrollments!
        </div>
      )}

      <hr />
    </>
  );
}

export default EnrollmentTabContent;
