import { toast } from "react-toastify";
import { openSessionModal } from "../../reducers/sessionHandler";
import store from "../../store/store";
import { GET_USERS_STUDENTS } from "../adminQueries/Users";
import { GET_USERS } from "../adminQueries/Users";
import { GET_USERS_CLIENTS } from "../adminQueries/Users";
import axios from "axios";

export async function getUsers(formData, token, page, sortKey, pageLimit) {
  const data = formData?.map((item) => {
    return `${item.selectUser}:${item.name.trim()}`;
  });

  var variables = {};

  data.forEach(function (item) {
    var pair = item.split(":");
    variables[pair[0]] = pair[1];
  });
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_USERS,
        variables: {
          pageInfo: {
            limit: parseInt(pageLimit),
            page: page,
            sort: sortKey?.sortId === null ? null : sortKey?.sortId.toString(),
            isAsc: sortKey?.isAsc,
          },
          ...variables,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response?.data?.errors &&
      response?.data?.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data.getUsers;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}

export async function getUsers_Students(id, token, page) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_USERS_STUDENTS,
        variables: {
          pageInfo: {
            isAsc: true,
            limit: 100,
            page: 0,
            sort: null,
          },
          parentId: id,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data?.getStudentByParentId;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}

export async function updateStudent(editData, token, id) {
  try {
    const response = await axios.put(
      process.env.REACT_APP_BASE_URL + `/admin/student/update/${id}`,
      editData,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (
      response?.data?.errors &&
      response?.data?.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.error);
    return;
  }
}

export async function updateComment(editData, token, id) {
  try {
    const response = await axios.put(
      process.env.REACT_APP_BASE_URL + `/admin/comment/update/${id}`,
      editData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data;
  } catch (error) {
    return error;
    throw new Error(error);
  }
}

export async function getUsers_Comments(id, token, page) {
  // const categoryFieldName = formData.categoryName === 'name' ? 'name' : 'schoolName';
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_USERS_CLIENTS,
        variables: {
          pageInfo: {
            isAsc: true,
            limit: 100,
            page: 0,
            sort: null,
          },
          clientId: id,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data.getCommentByClient;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}

export async function createUser(formData, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/admin/user/create",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      return { error: error.response.data.error };
    } else {
      return { error: "An error occurred" };
    }
  }
}

export async function updateUser(editData, token, id) {
  try {
    const response = await axios.put(
      process.env.REACT_APP_BASE_URL + `/admin/user/update/${id}`,
      editData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data;
  } catch (error) {
    return error;
  }
}

export const deleteUser = async (data, token) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_BASE_URL + `/admin/user/delete/${data}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getStates = async () => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: `
          query GetStates {
            getStates {
              id
              name
            }
          }
        `,
        variables: {},
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching states:", error);

    throw error;
  }
};

export const getLogs = async (id, token) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: `
        query GetClientLogs($ledgerId: Int!) {
          getClientLogs(ledgerId: $ledgerId) {
            id
            fk_user_client_logs
            check_number
            memo
            amount_tendered
            payment_date
            message
            fk_ledger_client_logs
            card
          }
        }
        `,
        variables: {
          ledgerId: id,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching states:", error);

    throw error;
  }
};

// Logout Api

export const logoutApi = async (token) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/delete-token",
      {
        token: token,
      }
    );
    return response;
  } catch (error) { }
};

// USER DELETE WARNING..

export async function getUserDeleteWarning(token, userId) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/admin/user/delete-warning/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.error("Error fetching student delete warning:", error);
    throw error;
  }
}
