export const dummyCampDetailDatas=[
    {
        week:"Week1",
        class_name:"Safari",
        subtitle:"4-4.11 Years",
        date:"Jun 8-Jun 12",
        weekdays:"Monday-Friday",
        timmming:"9:15-12:30",
        class_type:"Half Day $240",
        slots_left:"18",
    },
    {
        week:"Week2",
        class_name:"Mad Sciences",
        subtitle:"4-4.11 Years",
        date:"Jun 8-Jun 12",
        weekdays:"Monday-Friday",
        timmming:"9:15-12:30",
        class_type:"Half Day $240",
        slots_left:"18",
    },
    {
        week:"Week3",
        class_name:"Blast of to Space",
        subtitle:"4-4.11 Years",
        date:"Jun 8-Jun 12",
        weekdays:"Monday-Friday",
        timmming:"9:15-12:30",
        class_type:"Half Day $240",
        slots_left:"18",
    }
]