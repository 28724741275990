import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { setRoleID, setSuccessModalContent } from "../../../reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../reducers/loadingSlice";
import { toast } from "react-toastify";
import SuccesModal from "./SuccesModal";
import LoaderComponent from "../../../Admin/components/LoaderComponent";

import { ReactComponent as GymtimeLogo } from "../../parent-assets/images/gymtime_logo_2024.svg";
import { openLoginModal } from "../../../reducers/sessionHandler";
// Overlay fixing::
function useOutsideAlerter(ref, setModal) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        ref.current.className === "modal__content"
      ) {
        setModal(false);
      } else {
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function LoginModal({ modal, setModal, setSuccessModal }) {
  // User Role -->
  const USER_ROLE = useSelector((state) => state.userRole.userRole);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const loader = useSelector((state) => state?.loading?.loading);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const validateInput = (input, type) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

    if (type === "email") {
      return emailPattern.test(input) ? "" : "Invalid email address";
    } else if (type === "password") {
      return passwordPattern.test(input)
        ? ""
        : "Password must contain at least one digit, one lowercase and one uppercase letter, and be at least 6 characters long.";
    }
    return "";
  };

  const closeLoginModal = () => {
    setModal({ key: null, flag: false });
  };

  const validateForm = () => {
    let hasErrors = false;

    let emailError = validateInput(formData.email, "email");
    let passwordError = validateInput(formData.password, "password");

    if (!formData.email) {
      emailError = "Enter the email";
      hasErrors = true;
    }

    if (!formData.password) {
      passwordError = "Enter the password";
      hasErrors = true;
    }

    setErrors({
      email: emailError,
      password: passwordError,
    });

    return !hasErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (errors.email === "" && errors.password === "") {
      dispatch(setLoading(true));
      try {
        const response = await axios.post(
          process.env.REACT_APP_BASE_URL + "/login",
          {
            email: formData.email,
            password: formData.password,
            user: USER_ROLE.toLowerCase(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        localStorage.setItem(
          "access_token",
          response?.data?.data?.access_token
        );
        localStorage.setItem("userId", response?.data?.data?.id);
        if (response?.data?.data) {
          localStorage.setItem(
            "access_token",
            response?.data?.data?.access_token
          );

          // set up the role Id in Redux
          dispatch(setRoleID(response?.data?.data?.role_id));
          setShowSuccessModal(true);
          setSuccessModal(true);
          dispatch(openLoginModal(false));
        }
        dispatch(openLoginModal(false));
        dispatch(setSuccessModalContent("Login Succesfully."));
        setModal(false);

        if (USER_ROLE === "Admin") {
          navigate("/admin/clients/1");
        }
      } catch (error) {
        dispatch(setLoading(false));

        if (error?.response?.status === 401) {
          dispatch(setLoading(false));
          toast.error("Invalid Credentials!");
        } else {
          dispatch(setLoading(false));
          toast.error(error?.response?.data?.error, {
            toastId: "error",
          });
        }
      }
    }
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setModal);

  useEffect(() => {
    if (loader) {
      dispatch(setLoading(false));
    }
  }, []);
  return (
    <>
      {loader && <LoaderComponent />}

      <div
        className="modal modal--loginModal modal--open "
        id="LoginModal"
        role="dialog"
      >
        <div className="modal__dialog modal__dialog--lg">
          <div ref={wrapperRef} className="modal__content login_modal_content">
            <div className="loginWrap__item">
              <div className="loginWrap__header">
                <p
                  className="backbtn login-cross"
                  style={{ marginLeft: "96%" }}
                  onClick={() => {
                    setModal(false);
                    dispatch(openLoginModal(false));
                  }}
                >
                  {" "}
                  X
                </p>

                <div className="loginWrap__branding">
                  {USER_ROLE === "Admin" ? (
                    ""
                  ) : (
                    <button
                      className="backbtn"
                      onClick={() => setModal({ key: "signup", flag: true })}
                    >
                      <svg
                        width="9"
                        height="15"
                        viewBox="0 0 9 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.08357 1.8115L6.85403 0.588867L0.0224609 7.42734L6.86094 14.2658L8.08357 13.0432L2.46773 7.42734L8.08357 1.8115Z"
                          fill="#8692A6"
                        />
                      </svg>
                      Back
                    </button>
                  )}

                  <div className="logo">
                    <Link onClick={closeLoginModal}>
                      <GymtimeLogo />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="loginWrap__form">
                <div className="loginWrap__titlebox">
                  {USER_ROLE === "Admin" ? (
                    <h2 className="title"> Login As Admin </h2>
                  ) : (
                    <h2 className="title"> Login as Individual Account! </h2>
                  )}
                  <p className="para">
                    For the purpose of industry regulation, your details are
                    required.
                  </p>
                </div>
                <form className="form" onSubmit={handleSubmit}>
                  <div className="form__group">
                    <label className="form__label label">
                      Email address
                      <span className="form__label__asterisks important_field">
                        *
                      </span>
                    </label>
                    <input
                      className="form__input"
                      id="email"
                      type="email"
                      placeholder="Enter email address"
                      value={formData.email}
                      onChange={(e) => {
                        const emailError = validateInput(
                          e.target.value,
                          "email"
                        );
                        setErrors({ ...errors, email: emailError });
                        setFormData({ ...formData, email: e.target.value });
                      }}
                    />
                    {errors.email && (
                      <span className="error" style={{ color: "red" }}>
                        {errors.email}
                      </span>
                    )}
                  </div>
                  <div className="form__group">
                    <label className="form__label label">
                      Password
                      <span className="form__label__asterisks important_field ">
                        *
                      </span>
                    </label>
                    <div className="form__paswordfield">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        className="form__input"
                        placeholder="Enter password"
                        value={formData.password}
                        onChange={(e) => {
                          const passwordError = validateInput(
                            e.target.value,
                            "password"
                          );
                          setErrors({ ...errors, password: passwordError });
                          setFormData({
                            ...formData,
                            password: e.target.value,
                          });
                        }}
                      />
                      {errors.password && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.password}
                        </span>
                      )}

                      <button
                        type="button"
                        className="btn"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </button>
                    </div>
                    <div className="forgotText">
                      <Link
                        onClick={() =>
                          setModal({ key: "forgotPassword", flag: true })
                        }
                        className="forgotText__link"
                      >
                        Forgot password ?
                      </Link>
                    </div>
                  </div>

                  <div className="form__group">
                    <button className="btn btn--primary btn--full">
                      Log In
                    </button>
                  </div>
                </form>
                {USER_ROLE === "Admin" ? (
                  ""
                ) : (
                  <div className="loginWrap__footer">
                    <div className="orText">
                      <span className="orText__text">Or</span>
                    </div>
                    <div className="textBox">
                      <p className="para">
                        Didn’t have an account ?
                        <Link
                          className="para__link"
                          onClick={() =>
                            setModal({ key: "signup", flag: true })
                          }
                        >
                          &nbsp; Sign Up here
                        </Link>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showSuccessModal ? <SuccesModal setSuccessModal={setModal} /> : null}
    </>
  );
}

export default LoginModal;
