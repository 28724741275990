import React from "react";
import { dateFormatter, timeFormatter } from "../../adminUtility";

function ScheduleTable({
  scheduleData,
  setClassScheduleData,
  setSelectedSchedules,
  selectedSchedules,
}) {
  const handleCheckboxChange = (event, schedule) => {
    const isChecked = event.target.checked;
    const isAlreadySelected = selectedSchedules.some(
      (selected) => selected.id === schedule.id
    );

    if (isChecked && !isAlreadySelected) {
      setSelectedSchedules((prevSelected) => [...prevSelected, schedule]);
      setClassScheduleData((prevScheduleData) =>
        prevScheduleData.filter((item) => item.id !== schedule.id)
      );
    } else if (!isChecked && isAlreadySelected) {
      setSelectedSchedules((prevSelected) =>
        prevSelected.filter((selected) => selected.id !== schedule.id)
      );
      setClassScheduleData((prevScheduleData) => [
        ...prevScheduleData,
        schedule,
      ]);
    }
  };

  return (
    <div className="">
      <div className="flexbox">
        <table>
          <thead>
            <tr style={{ color: "white" }}>
              <th style={{minWidth:"200px"}}>Category Name</th>
              <th style={{minWidth:"200px"}}>Class Name</th>
              <th style={{minWidth:"200px"}}>Scheduled Week Day</th>
              <th style={{minWidth:"200px"}}>Slots</th>
              <th style={{minWidth:"200px"}}>Class Schedule</th>
            </tr>
          </thead>
          <tbody>
            {scheduleData?.length > 0 ? (
              scheduleData?.map((val, index) => (
                <tr key={index}>
                  <td>{val?.category?.name}</td>
                  <td>{val?.name}</td>
                  <td>
                    {val?.class_schedules?.length > 0 ? (
                      val?.class_schedules?.map((schedule, scheduleIndex) => (
                        <div
                          key={scheduleIndex}
                          className="adminform__group__grayBox"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            maxWidth: "100%",
                          }}
                        >
                          <label
                            style={{
                              marginRight: "10px",
                              flex: 1,
                              fontSize: "0.7rem",
                              color: "#868686",
                            }}
                          >
                            {schedule?.weekday} {/* SUBJETC  */}
                            {schedule?.subject && `: ${schedule.subject}`}
                            {/* STRENGTH  */}
                          </label>

                          {scheduleIndex === 0 && (
                            <input
                              type="checkbox"
                              className="adminform__checkbox"
                              onChange={(event) =>
                                handleCheckboxChange(event, val)
                              }
                              checked={selectedSchedules.some(
                                (selected) => selected.id === val.id
                              )}
                              disabled={
                                val?.max_strength == 0 ||
                                val.max_strength -
                                  val.classes_enrolled.length ==
                                  0
                              }
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <p>No Schedules Found!.</p>
                    )}
                  </td>
                  {val?.class_schedules[0]?.max_strength > 0 ? (
                    <td>
                      {val.class_schedules?.map((slots) => {
                        return (
                          <div className="flexbox">
                            <div className="column">
                              Total Slots: {slots?.max_strength}
                              <br />
                              Available Slots:{" "}
                              {slots?.max_strength -
                                val?.classes_enrolled?.length}
                              {/* {slots?.max_strength -
                              val?.classes_enrolled?.length}{" "}
                            &nbsp; of {slots?.max_strength} &nbsp; */}
                            </div>
                          </div>
                        );
                      })}
                    </td>
                  ) : (
                    <td>
                      {val?.max_strength == 0 ||
                      val.max_strength - val.classes_enrolled.length == 0 ? (
                        <p>No Slots Available!</p>
                      ) : (
                        <div
                          className=""
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "8px",
                          }}
                        >
                          <div>
                            <span> Total Slots: {val.max_strength}</span> <br />
                            <span>
                              {" "}
                              Available Slots:{" "}
                              {val.max_strength - val.classes_enrolled.length}
                            </span>{" "}
                            <br />
                          </div>

                          {/* {val.max_strength - val.classes_enrolled.length} of{" "}
                        {val.max_strength} */}
                          {val?.tuition !== null && (
                            <div style={{ alignItems: "left" }}>
                              <p
                                className=""
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Tuition: $ {val?.tuition}
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </td>
                  )}

                  {/* START TIME ENRD TOME  */}
                  <td>
                    <div className="flexbox">
                      <div className="column">
                        <p style={{ textDecoration: "underline" }}>
                          Start time
                        </p>
                      </div>
                      <div className="column">
                        <p style={{ textDecoration: "underline" }}>End time</p>
                      </div>
                    </div>

                    {/* Time_FORMATTER  */}
                    <div className="flexbox">
                      <div className="column">
                        {val?.class_schedules.length > 0
                          ? val?.class_schedules?.map((time) => {
                              return (
                                <div
                                  className="column"
                                  key={time}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {timeFormatter(time?.start_time)}
                                </div>
                              );
                            })
                          : "-"}
                      </div>

                      <div className="column">
                        {val?.class_schedules.length > 0
                          ? val?.class_schedules?.map((time) => {
                              return (
                                <p key={time}>
                                  {timeFormatter(time?.end_time)}
                                </p>
                              );
                            })
                          : "-"}
                      </div>
                    </div>

                    <tr>
                      <div className="flexbox">
                        <div className="column" style={{ width: "150px" }}>
                          <p
                            style={{
                              fontSize: "14px",
                              textDecoration: "underline",
                            }}
                          >
                            Start Date
                          </p>
                          {val?.start_date
                            ? dateFormatter(val?.start_date)
                            : "-"}
                        </div>
                        <div className="column">
                          <p
                            style={{
                              fontSize: "14px",
                              textDecoration: "underline",
                            }}
                          >
                            End Date
                          </p>
                          {val?.end_date ? dateFormatter(val.end_date) : "-"}
                        </div>
                      </div>
                    </tr>
                  </td>
                </tr>
              ))
            ) : selectedSchedules.length == 0 ? (
              <p>No Schedulesss Found!</p>
            ) : (
              ""
            )}
          </tbody>

          {/* SELECTED TABLE  */}
          <tbody>
            {selectedSchedules.length > 0 &&
              selectedSchedules?.map((val, index) => (
                <tr key={index}>
                  <td>{val?.category?.name}</td>
                  <td>{val?.name}</td>
                  <td>
                    {val?.class_schedules?.map((schedule, scheduleIndex) => (
                      <div
                        key={scheduleIndex}
                        className="adminform__group__grayBox"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          maxWidth: "100%",
                        }}
                      >
                        <label
                          style={{
                            marginRight: "10px",
                            flex: 1,
                            fontSize: "0.7rem",
                            color: "#868686",
                          }}
                        >
                          {schedule?.weekday} {/* SUBJETC  */}
                          {schedule?.subject && `: ${schedule.subject}`}
                          {/* STRENGTH  */}
                        </label>

                        {scheduleIndex === 0 && (
                          <input
                            type="checkbox"
                            className="adminform__checkbox"
                            onChange={(event) =>
                              handleCheckboxChange(event, val)
                            }
                            checked={selectedSchedules.some(
                              (selected) => selected.id === val.id
                            )}
                          />
                        )}
                      </div>
                    ))}
                  </td>
                  {val?.class_schedules[0]?.max_strength > 0 ? (
                    <td>
                      {val.class_schedules?.map((slots) => {
                        return (
                          <div className="flexbox">
                            <div className="column">
                              {slots?.max_strength -
                                val?.classes_enrolled?.length}{" "}
                              &nbsp; of {slots?.max_strength} &nbsp;
                            </div>
                          </div>
                        );
                      })}
                    </td>
                  ) : (
                    <td>
                      <div>
                        <span> Total Slots: {val.max_strength}</span> <br />
                        <span>
                          {" "}
                          Available Slots:{" "}
                          {val.max_strength - val.classes_enrolled.length}
                        </span>{" "}
                        <br />
                      </div>
                      {val?.tuition !== null && (
                        <div style={{ alignItems: "left" }}>
                          <p
                            className=""
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            Tuition: $ {val?.tuition}
                          </p>
                        </div>
                      )}
                    </td>
                  )}

                  {/* START TIME ENRD TOME  */}
                  <td>
                    <div className="flexbox">
                      <div className="column">
                        <p style={{ textDecoration: "underline" }}>
                          Start time
                        </p>
                      </div>
                      <div className="column">
                        <p style={{ textDecoration: "underline" }}>End time</p>
                      </div>
                    </div>

                    {/* Time_FORMATTER  */}
                    <div className="flexbox">
                      <div className="column">
                        {val?.class_schedules?.map((time) => {
                          return (
                            <div
                              className="column"
                              key={time}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {timeFormatter(time?.start_time)}
                            </div>
                          );
                        })}
                      </div>

                      <div className="column">
                        {val?.class_schedules?.map((time) => {
                          return (
                            <p key={time}>{timeFormatter(time?.end_time)}</p>
                          );
                        })}
                      </div>
                    </div>

                    <tr>
                      <div className="flexbox">
                        <div className="column" style={{ width: "150px" }}>
                          <p
                            style={{
                              fontSize: "14px",
                              textDecoration: "underline",
                            }}
                          >
                            Start Date
                          </p>
                          {dateFormatter(val?.start_date)}
                        </div>
                        <div className="column">
                          <p
                            style={{
                              fontSize: "14px",
                              textDecoration: "underline",
                            }}
                          >
                            End Date
                          </p>
                          {dateFormatter(val.end_date)}
                        </div>
                      </div>
                    </tr>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ScheduleTable;
