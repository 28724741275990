import React, { useEffect, useState, useRef } from 'react';
import "./catsum.css"
import { useLocation } from 'react-router-dom'
const CategorySummaryPdf = () => {
    const { state } = useLocation();
    const [totalSumPaid, setTotalSumPaid] = useState(0);
    const [totalDue, setTotalDue] = useState(0);

    const dateFormatHandler = (value) => {
        const date = value?.split("-");
        return ` ${date[1]}-${date[2]}-${date[0]}`
    }

    const convertDateHandler=(value)=>{
   
     const dateArr=value?.split("-");
     const year=dateArr?.[0];
     const month=dateArr?.[1];
     const day=dateArr?.[2];

     return `${month}/${day}/${year}`
     
    }

    useEffect(() => {
        if (state?.selectedReport === "Category Summary") {
            let grandTotal = 0;
            let grandTotalDue = 0;
            for (let i = 0; i < state?.modifiedData?.length; i++) {
                grandTotal += parseInt(state?.modifiedData?.[i]?.data?.[5]);
                grandTotalDue += parseInt(state?.modifiedData?.[i]?.data?.[6])
            }
            setTotalDue(grandTotalDue);
            setTotalSumPaid(grandTotal);
        }
    }, [state]);

    useEffect(() => {
        const handleBeforePrint = () => {
          window.scrollTo(0, 0);
        }
    
        window.addEventListener('beforeprint', handleBeforePrint);
    
        return () => {
          window.removeEventListener('beforeprint', handleBeforePrint);
        }
      }, []);
    return (
        <>
            {state?.selectedReport !== "Category Summary" ?
                <div className="page"
                >
                    <h1
                        style={{
                            fontSize: "40px",
                            fontWeight: "600",
                            marginBottom: "20px",
                            textAlign: "center"
                        }}

                    >{state?.selectedReport}</h1>
                    <h1 className="rollsheet_date_heading"
                        style={{
                            fontWeight: "400",
                            fontSize: "1.5rem",
                            marginTop: "10px",
                            marginBottom: "20px",
                            textAlign: "center"
                        }}
                    >
                        Summary of all categories charged on
                        <span
                            style={{ fontWeight: "600" }}
                        >{dateFormatHandler(state?.date?.copyStartDate)} </span>
                        to
                        <span
                            style={{ fontWeight: "600" }}
                        >{dateFormatHandler(state?.date?.copyEndDate)}</span>
                    </h1>
                    <table className="rollsheet_pdf_container">
                        <thead>
                            <tr >
                                {
                                    state?.tabelHeadersData?.map((item) => (
                                        <th style={{ color: "white" }}>{item?.name}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        {state?.modifiedData?.length > 0 ? <tbody>

                            {
                                state?.modifiedData?.map((item) => (
                                    <tr
                                        key={item?.[0]?.data?.[0]}
                                    >
                                        {
                                            item?.data?.map((data) => (
                                                <td
                                                    style={{ fontSize: "22px" }}
                                                    key={data?.[0]}
                                                >{data}</td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </tbody> : <div className="select_report_title">
                            <h1 className='NonSelectReportHeading'>No data found</h1>
                        </div>}
                    </table>

                </div> :
                <div className="page"
                >
                    <h1
                        style={{
                            fontSize: "40px",
                            fontWeight: "600",
                            marginBottom: "20px",
                            textAlign: "center"
                        }}

                    >{state?.selectedReport}</h1>
                    <h1 className="rollsheet_date_heading"
                        style={{
                            fontWeight: "400",
                            fontSize: "1.5rem",
                            marginTop: "10px",
                            marginBottom: "20px",
                            textAlign: "center"
                        }}
                    >
                        Summary of all categories charged on
                        <span
                            style={{ fontWeight: "600" }}
                        >{dateFormatHandler(state?.date?.copyStartDate)} </span>
                        to
                        <span
                            style={{ fontWeight: "600" }}
                        >{dateFormatHandler(state?.date?.copyEndDate)}</span>
                    </h1>
                    
                    {state?.modifiedData?.length > 0 ? state?.modifiedData?.map((item) => (
                        <table >
                            <thead>
                                <tr className="rollsheet_pdf_container">
                                    {
                                        state?.tabelHeadersData?.map((item) => (
                                         item?.name!=="Memo" &&   <th style={{ color: "white",fontSize:"1.03rem" }}>{item?.name}</th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            
                            <tbody className='page'>
                                {
                                    item?.chunk?.map((item, index) => (
                                        [<tr className='category_summary_row'>
                                            <td style={{fontSize:"1.2rem"}}>{item?.clientId}</td>
                                            <td style={{fontSize:"1.2rem"}}>{item?.client_first_name + " " + item?.client_last_name}</td>
                                            <td style={{fontSize:"1.2rem"}}>{(item?.class_start_date!==undefined ||item?.class_start_date!==null) ? convertDateHandler(item?.class_start_date) :"-"}</td>
                                            <td style={{fontSize:"1.2rem"}}>{item?.payment_date!==null 
                                            ?convertDateHandler(item?.payment_date):"-"}</td>
                                            <td style={{fontSize:"1.2rem"}}>{item?.category_name}</td>
                                            {/* <td style={{fontSize:"20px"}}>{item?.class_name}</td> */}
                                            <td style={{fontSize:"1.2rem"}}>${item?.amount}</td>
                                            <td style={{fontSize:"1.2rem"}}>{item?.paid ? `$${item?.paid}`:"$0"}</td>
                                            <td style={{fontSize:"1.2rem"}}>${Number?.parseFloat(item?.Due)?.toFixed(2)}</td>
                                            
                                        </tr>,
                                        <tr className='category_summary_row'>
                                            <td style={{fontSize:"1.2rem",fontWeight:"700"}}>Memo</td>
                                            <td style={{fontSize:"1.2rem",fontWeight:"700"}} colSpan={7}>{item?.class_name}</td>
                                        </tr>
                                    ]
                                    ))
                                }

                                {state?.selectedReport === "Category Summary" && <tr
                                    style={{ backgroundColor: "gray" }}
                                >
                                    <th colSpan="5"
                                        style={{ color: "white" }}
                                    >
                                        Number of charges per chunk: {item?.chargeForChunk} 
                                        <br />
                                       Total number of Charges:{item?.totalCharges} </th>
                                    <th
                                        style={{ color: "white" }}
                                    >Totals</th>
                                    <th
                                        style={{ color: "white" }}
                                    >${item?.totalAmount?.toFixed(2)}</th>
                                    <th
                                        style={{ color: "white" }}
                                    >${item?.totalPaid?.toFixed(2)}</th>
                                    <th
                                        style={{ color: "white" }}
                                    >
                                    {/* ${item?.totalDue?.toFixed(2)} */}
                                    </th>
                                    {/* <th></th> */}
                                </tr>}

                                {state?.selectedReport === "Category Summary" && <tr
                                    style={{ backgroundColor: "gray" }}
                                >
                                    <th colSpan="5"
                                        style={{ color: "white" }}
                                    ></th>
                                    <th
                                        style={{ color: "white" }}
                                    >Grand total</th>
                                    <th
                                        style={{ color: "white" }}
                                    >${item?.grandTotalAmount?.toFixed(2)}</th>
                                    <th
                                        style={{ color: "white" }}
                                    >${item?.grandTotalPaid?.toFixed(2)}</th>
                                    <th
                                        style={{ color: "white" }}
                                    >
                                    {/* ${item?.grandTotalDue?.toFixed(2)} */}
                                    </th>
                                    {/* <th></th> */}
                                </tr>}
                                <tr></tr>
                                  
                                {/* <tr style={{ backgroundColor: "white" }}>
                                    <td colSpan="8"></td>

                                </tr> */}
                            </tbody>
                        </table>
                    )) : <div className="select_report_title">
                        <h1 className='NonSelectReportHeading'>No data found</h1>
                    </div>
                    }


                </div>}
        </>
    )
}

export default CategorySummaryPdf