// rollSheetSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const attendanceSumaryReport = createSlice({
  name: "rollSheet",
  initialState: {
    attendanceSumaryData: [],
    dateCopy: "",
  },
  reducers: {
    setAttendanceSummaryData: (state, action) => {
      state.attendanceSumaryData = action.payload.attendanceSumaryData;
      state.dateCopy = action.payload.dateCopy;
    },
  },
});

export const { setAttendanceSummaryData } = attendanceSumaryReport.actions;

export default attendanceSumaryReport.reducer;
