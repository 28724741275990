// api/classApi.js
import axios from "axios";
import { GET_CLASS_DETAILS, GET_CLASS } from "../Queries/classQueries";
import { GET_PROGRAMS } from "../Queries/BrowseList";
import { toast } from "react-toastify";
import store from "../../store/store";
import { openSessionModal } from "../../reducers/sessionHandler";

export async function getClassDetails(classId, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_CLASS_DETAILS,
        variables: { classId },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data?.data?.getClass;
  } catch (error) {
    console.error("Error fetching class details from GraphQL", error);
    throw error;
  }
}

export async function getClass(classId, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_CLASS,
        variables: { classId },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data?.data.getClass;
  } catch (error) {
    console.error("Error fetching class details from GraphQL", error);
    throw error;
  }
}

export async function getAllProgram(id, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_PROGRAMS,
        variables: { searchId: id },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data?.data.getClasses;
  } catch (error) {
    console.error("Error fetching class details from GraphQL", error);
    toast.error(error?.response?.data?.error, {
      toastId: "error",
    });
  }
}

export async function joinWatchListAPI(id, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/join-watchlist/" + id,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.error, {
      toastId: "error",
    });
  }
}

export async function getProRatedAmount(classSchedules, studentId, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/getProratedAmount/" + studentId,
      {
        classSchedules,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.error, {
      toastId: "error",
    });
  }
}

//Trial
export async function bookTrialClass(scheduleID, token, studentId) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/trial/create/`,
      {
        classScheduleId: scheduleID,
        studentId: studentId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.error, {
      toastId: "error",
    });
  }
}

// cart api Data

// Get All Data From Cart
export async function getAllDataForCart(token) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/cart/get`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response?.data?.data;
  } catch (error) {}
}

export async function studentAddToCartApiFunction(token, data) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/student/add-to-cart`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    toast.error(error.response.data.error);
  }
}

// Delete From The Cart
export async function deleteDataFromTheCart(token, cartId) {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/cart/clear-cart-item/${cartId}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.error);
  }
}

// CAMP

export async function getCampProgramApi(programName) {
  let payload = {
    program_name: "Camp",
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/admin/get_sub_prog_one`,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function getCampDay() {
  let payload = {
    fk_sub_program_cms: 1,
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/admin/get_sub_prog_two`,
      payload
    );
    return response?.data;
  } catch (error) {
    return error;
  }
}
