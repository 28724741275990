import React, { useState } from "react";
// import { setLoading } from "../../../reducers/loadingSlice";
import { toast } from "react-toastify";
import { sendNotification } from "../../adminApi/notifications";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close-icon.svg";

const AddNewClass = ({
  openAddNewUser,
  setAddNewUser,
  type,
  data,
  fetchData,
  ids,
  email,
  setCheckboxes,
  setSelectedEmails,
  selectedCheckboxesData,
}) => {
 
  const uniqueMails = new Set(email);
  const uniqueMailArr = Array.from(uniqueMails);
  let token = localStorage.getItem("access_token");

  const [formData, setFormData] = useState({
    message: "",
    subject: "",
    ids: selectedCheckboxesData?.map((val) => {
      return val?.clientID;
    }),
    files: "",
  });
  const [images, setImages] = useState([]);
  const handleInput = (e) => {
    const { name, value } = e.target;
    // if (type === "Add") {
    setFormData({ ...formData, [name]: value });
    // } else {
    //   setEditData({ ...editData, [name]: value });
    // }
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    // const updateResponse = async () => {
    if (!formData.message) {
      toast.error("Please write the message");
      return;
    } else {
      try {
        const updatedData = await sendNotification(formData, token);
        if (updatedData) {
          toast.success(updatedData.data);
          setAddNewUser(false);
          fetchData();
          setCheckboxes({});
        }
        if (updatedData.response.data.error) {
          toast.error(updatedData.response.data.error);
        }
      } catch (error) {
         
      }
    }
    setSelectedEmails([]);
    // };
  };

  const handleFileChange = async (e) => {
    e.preventDefault();
    try {
      const selectedFiles = e.target.files;

      if (selectedFiles) {
        const filesArray = await Promise.all(
          Array.from(selectedFiles)?.map(
            (file) =>
              new Promise((resolve, reject) => {
                if (file.size > 10 * 1024 * 1024) {
                  toast.error("File size must be less than 10 MB");
                  reject(new Error("File size must be less than 10 MB"));
                }
                const allowedTypes = [
                  "image/png",
                  "image/jpeg",
                  "image/jpg",
                  "text/csv",
                  "text/plain",
                  "application/pdf",
                ];

                if (!allowedTypes.includes(file.type)) {
                  toast.error(
                    "File type not allowed. Please upload a PNG, JPG, JPEG, CSV, TXT, or PDF file."
                  );
                  reject(
                    new Error(
                      "File type not allowed. Please upload a PNG, JPG, JPEG, CSV, TXT, or PDF file."
                    )
                  );
                }

                const reader = new FileReader();
                reader.onloadend = () => {
                  const convertedFile = {
                    name: file.name,
                    file: reader.result,
                  };
                  resolve(convertedFile);
                };
                reader.readAsDataURL(file);
              })
          )
        );

        setImages(filesArray);
        setFormData({ ...formData, files: filesArray });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const uniqueEmailsSet = new Set(
    selectedCheckboxesData.map((item) => item.email)
  );
  const uniqueEmailsArray = Array.from(uniqueEmailsSet);

  return (
    <>
      {openAddNewUser ? (
        <>
          <div
            className="Admin-modal Admin-modal--open"
            id="modalCategory"
            role="dialog"
          >
            <div className="Admin-modal__dialog Admin-modal__dialog--md">
              <div className="Admin-modal__content">
                <div className="Admin-modal__head">
                  <h2 className="Admin-modal__head--title">
                    Compose Notification
                  </h2>
                  <button
                    className="Admin-modal__close"
                    onClick={() => {
                      setAddNewUser(false);
                    }}
                  >
                    <CloseIcon />
                  </button>
                </div>

                <div className="Admin-modal__body">
                  <div
                    className="adminform__group"
                    style={{ marginBottom: "10px" }}
                  >
                    <label htmlFor="" className="label">
                      Email{" "}
                    </label>
                    <br />
                    <textarea
                      type="text"
                      className="adminform__input"
                      placeholder="To:            |"
                      value={uniqueEmailsArray}
                      name="name"
                      disabled
                      onChange={handleInput}
                    />
                  </div>

                  <div
                    className="adminform__group"
                    style={{ marginBottom: "10px" }}
                  >
                    <label htmlFor="" className="label">
                      Subject{" "}
                    </label>
                    <br />
                    <input
                      type="text"
                      className="adminform__input"
                      placeholder="Subject:  |"
                      value={formData.subject}
                      name="subject"
                      onChange={handleInput}
                    />
                  </div>

                  <div className="adminform__group">
                    <label htmlFor="" className="label">
                      Message{" "}
                    </label>
                    <br />
                    <textarea
                      type="text"
                      className="adminform__textarea"
                      placeholder="Write your Message Here..."
                      value={formData.message}
                      name="message"
                      onChange={handleInput}
                    />
                  </div>
                </div>

                <div className="Admin-modal__footer">
                  <input
                    type="file"
                    multiple
                    id="image"
                    name="image"
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                  />
                  <div
                    className="admin-button__group"
                    style={{ display: "flex", gap: "15px" }}
                  >
                    <button
                      className="adminbtn adminbtn-blueOutline"
                      onClick={() => {
                        setAddNewUser(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="adminbtn adminbtn-primary"
                      onClick={handleAddCategory}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AddNewClass;
