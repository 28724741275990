import React, { useEffect, useRef, useState } from "react";
import html2pdf from "html2pdf.js";
import { dateFormatter, timeFormatter } from "../../adminUtility";
import { ReactComponent as LOGO } from "../../../Parent/parent-assets/images/gymtime_logo_2024.svg";
import { Link } from "react-router-dom";
const InvoiceComp = () => {
  // const { state } = useLocation();
  const [state, setState] = useState();
  const contentRef = useRef(null);

  const data = localStorage.getItem("INVOICE_DATA");

  useEffect(() => {
    if (data) {
      try {
        const parsedData = JSON.parse(data);
        setState(parsedData);
      } catch (error) {
        return error;
      }
    } else {
    }
  }, [data]);

  const generatePDF = async () => {
    try {
      if (contentRef.current) {
        const content = contentRef.current;

        const pdfArrayBuffer = await html2pdf()
          .from(content)
          .outputPdf("arraybuffer");
        const pdfBlob = new Blob([pdfArrayBuffer], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const downloadLink = document.createElement("a");
        downloadLink.href = pdfUrl;
        downloadLink.download = "Invoice.pdf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        URL.revokeObjectURL(pdfUrl);
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <>
      <div
        className="button__group"
        style={{ marginLeft: "83%" }}
        onClick={() => {
          generatePDF();
        }}
      >
        <button className="adminbtn adminbtn-invoice">Download Now</button>
      </div>
      <div className="container">
        <div ref={contentRef}>
          <div className="Admin-modal__head-send_Email_Modal">
            <div
              className=""
              style={{ maxWidth: "250px", marginBottom: "20px" }}
            >
              <LOGO />
            </div>
            <div className="mailing_data_header">
              <div className="class_name_address">
                <h2 className="mailing_class">
                  Gymtime Gymnastics Rhythm & Glues
                </h2>
                <span className="mailing_address">
                  1520 York Avenue <br /> New York, NY 10028 <br /> Phone
                  212.861.7732 <br />
                  Fax 212.861.8901
                </span>
              </div>
              <div
                className="Invoide_mailing_meta_data"
                style={{ marginRight: "15px" }}
              >
                <h1 className="mailing_Invoice_heading">INVOICE </h1>
              </div>
            </div>

            <div className="to_for_mailing_section">
              <div className="to_mailing_container">
                <h2 className="to_mailing_heading">To:</h2>
                <span className="mailing_address">
                  <div>
                    {" "}
                    {state?.data?.data?.[0]?.parent1_first_name} &nbsp;
                    {state?.data?.data?.[0]?.parent1_last_name}
                  </div>
                  <div style={{ fontSize: "13px", display: "flex" }}>
                    <span style={{ fontWeight: "600", color: "black" }}>
                      Address.{" "}
                    </span>
                    &nbsp;{" "}
                    {state?.data?.data?.[0]?.parent1_address && (
                      <p>{state?.data?.data?.[0]?.parent1_address}</p>
                    )}
                    {state?.data?.data?.[0]?.parent1_city && (
                      <p style={{ fontSize: "13px" }}>
                        , {state?.data?.data?.[0]?.parent1_city}{" "}
                        {state?.data?.data?.[0]?.parent1_zip},
                        {state?.data?.data?.[0]?.parent1_state},
                      </p>
                    )}
                  </div>
                </span>
                <div>
                  <span style={{ fontWeight: "600", color: "black", fontSize:"0.9rem" }}>
                    Phone.{" "}
                  </span>
                  <span style={{ fontSize: "13px" }}>
                    {" "}
                    {state?.data?.data?.[0]?.parent1_cell} ,
                    {state?.data?.data?.[0]?.parent1_alt_cell}
                  </span>{" "}
                </div>
              </div>
              <div className="for_mailing_container">
                <h2 className="to_mailing_heading">For</h2>
                <p style={{ fontSize: "13px" }}>
                  {state?.data?.data?.[0]?.student_first_name}{" "}
                  {state?.data?.data?.[0]?.student_last_name}
                </p>
              </div>
            </div>
          </div>
          <div className="Admin-modal__body">
            <div className="Admin-modal__warning1">
              <table className="mailing_table_list">
                <tr>
                  <th style={{ maxWidth: "15%" }}>DESCRIPTION</th>
                  <th style={{ maxWidth: "250px" }}>DATES</th>
                  <th>RATE</th>
                  <th>AMOUNT PAID</th>
                  <th>DATE PAID</th>
                </tr>
                {state?.data?.data?.map((item) => (
                  <tr>
                    <td>
                      <span
                        className="mailing_class_metadata_subtitle"
                        style={{ fontSize: "14px" }}
                      >
                        {item?.class_name}
                      </span>
                      {item?.schedules?.map((schedule) => (
                        <span
                          className="mailing_class_metadata_subtitle"
                          style={{ fontSize: "14px" }}
                        >
                          {` ${schedule?.weekday}, 
                                                                ${timeFormatter(
                                                                  schedule?.start_time
                                                                )}-${timeFormatter(
                            schedule?.end_time
                          )}`}
                        </span>
                      ))}
                    </td>
                    <td style={{ fontSize: "14px" }}>
                      {item.class_start_date
                        ? dateFormatter(item.class_start_date)
                        : "-"}{" "}
                      <br /> to <br />{" "}
                      {item.class_end_date
                        ? dateFormatter(item.class_end_date)
                        : "-"}
                    </td>
                    <td style={{ fontSize: "14px" }}>$ {item?.class_rate}</td>
                    <td style={{ fontSize: "14px" }}>$ {item?.amount_paid}</td>
                    <td style={{ fontSize: "14px" }}>{item?.date_paid}</td>
                  </tr>
                ))}

                <tr className="mailing_total_amount">
                  <td>TOTALS</td>
                  <td></td>
                  <td>$ {state?.data?.totalDue}</td>
                  <td>${state?.data?.totalPaid} </td>
                  <td>$ {state?.data?.totalBalance} </td>
                </tr>
                <tr className="due_meta_data_info_mailing">
                  <td></td>
                  <td></td>
                  <td className="total_rates_mailing_table">Total Rate</td>
                  <td className="total_rates_mailing_table">
                    Total Amount Paid
                  </td>
                  <td className="total_rates_mailing_table">Due Amount</td>
                </tr>
              </table>
            </div>
            <div>
              <div className="greeting_container">
                <span className="greeting_mailing">
                  THANK YOU FOR YOUR BUSINESS!
                </span>
                <span className="gymtime_mailing_info">
                  <Link
                    style={{
                      cursor: "pointer",
                      color: "00bbe3",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      window.open("https://www.gymtime.net", "_blank");
                    }}
                  >
                    info@gymtime.net - www.Gymtime.net - 1520 York Ave - new
                    York, NY 10028
                  </Link>{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceComp;
