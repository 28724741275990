import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setClassData } from "../../../reducers/classDetailsSlice";
// IMAGES
import aboutUsBanner from "../../parent-assets/images/home-banner.jpg";
import programImg1 from "../../parent-assets/images/About.jpg";
import { getSubPrograms } from "../../../Admin/adminApi/subProgram";
import { getManageProgramData } from "../../../Admin/adminApi/manageProgramData";
import { getClassesForAge } from "../../../Admin/adminApi/classes";
import { getCmsAge } from "../../../Admin/adminApi/program";
import recImage from "../../parent-assets/images/static-cheer-image/rec.png";
import gymImage from "../../parent-assets/images/static-cheer-image/gymImage.png";
import teamIMage from "../../parent-assets/images/static-cheer-image/teams.png";
import campLogo from "../../parent-assets/images/enrichment-images/camp-logo.png";
//LOGO
import yogaIcon from "../../parent-assets/images/birthday-icons/yoga-icon.png";
import gymnasticeLogo from "../../parent-assets/images/gymtimeGuy.png";
import cheerlogo from "../../parent-assets/images/enrichment-images/cheer.png";
import musicIcon from "../../parent-assets/images/birthday-icons/music-icon.png";
import danceIcon from "../../parent-assets/images/birthday-icons/dance-icon.png";
import artIcon from "../../parent-assets/images/Program-logo/Art.png";
import cookingIcon from "../../parent-assets/images/cooking-icon.png";
import warrior from "../../parent-assets/images/Program-logo/warrior.png";
import steamIcon from "../../parent-assets/images/Program-logo/steam.png";
import ProgramDetailShimmer from "../../../Admin/components/ShimmerUi/ProgramDetailShimmer";

const DisplayImage = ({ content, titleContent }) => {
  const normalizedContent =
    content !== undefined ? content.trim().toLowerCase() : "";

  const matchedItem = titleContent.find(
    (item) => item?.name?.trim().toLowerCase() === normalizedContent
  );

  if (!matchedItem) {
    return <div></div>;
  }

  return (
    <div className={content == "dance" ? "dance_logo" : ""}>
      <img
        style={{
          width:
            normalizedContent === "warriors"
              ? "60px"
              : normalizedContent === "art"
              ? "80px"
              : normalizedContent === "steam"
              ? "60px"
              : "100px",
          height:
            normalizedContent === "art"
              ? "80px"
              : normalizedContent === "steam"
              ? "60px"
              : "auto",
          transform: normalizedContent === "music" ? "rotate(349deg)" : "none",
        }}
        src={matchedItem?.src}
        alt={matchedItem.name}
      />
    </div>
  );
};

function ProgramDetails() {
  const { id } = useParams();
  const { name } = useParams();
  const temp = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showShimmer, setShowShimmer] = useState(false);
  const [classesProgram, setClassesProgram] = useState([]);
  const [classesAge, setClassesAge] = useState([]);
  const [content, setContent] = useState();
  const [subProgramData, setShowProgramData] = useState([]);
  const [ageRanges, setAgeRanges] = useState([]);
  const [cheertText, setCheerText] = useState();
  const [showMore, setShowMore] = useState([]);

  const titleContent = [
    { name: "Gymnastics", src: gymnasticeLogo },
    { name: "Art", src: artIcon },
    { name: "Cooking", src: cookingIcon },
    { name: "Dance", src: danceIcon },
    { name: "Yoga", src: yogaIcon },
    { name: "Music", src: musicIcon },
    { name: "Warriors", src: warrior },
    { name: "Steam", src: steamIcon },
  ];

  let token = localStorage.getItem("access_token");

  const getContentBasedOnId = (id) => {
    switch (id) {
      case "10":
        return "0-12 Months";
      case "11":
        return "12-24 Months";
      case "12":
        return "2 Years";
      case "13":
        return "3 Years";
      case "14":
        return "4 Years";
      case "15":
        return "5 Years";
      case "16":
        return "6 Years";
      case "17":
        return "7+ Years";
      case "181":
        return "Warriors";
      case "167":
        return "Gymnastics ";
      case "168":
        return "Teams ";
      case "169":
        return "Cheer ";
      case "170":
        return "Art ";
      case "171":
        return "Cooking ";
      case "172":
        return "Music ";
      case "173":
        return "Steam ";
      case "174":
        return "Tai-kwan-do ";
      case "175":
        return "Camp";
      case "177":
        return "Dance";
      case "178":
        return "Yoga";

      default:
        return "Invalid Route";
    }
  };

  const fetchData = async () => {
    setShowShimmer(true);
    try {
      if (temp.search.split("=")?.pop() === "true") {
        setClassesAge([]);
        const getProgramMangeData = await getManageProgramData(token, id);
        setTimeout(() => {
          setShowShimmer(false);
        }, [1000]);
        setClassesProgram(getProgramMangeData?.data);
        dispatch(setClassData(classesProgram));
        setShowMore(new Array(getProgramMangeData?.data?.length).fill(false));
      }
      if (temp.search.split("=")?.pop() === "false") {
        setClassesProgram([]);
        const ageRangeData =
          name === "0-12" ? "0-1" : name === "12-24" ? "1-2" : name;
        const getAgeManageData = await getClassesForAge(
          token,
          id,
          ageRangeData
        );
        setClassesAge(getAgeManageData);
        setShowMore(new Array(getAgeManageData?.length).fill(false));
      }
      setShowShimmer(false);
    } catch (err) {
      setShowShimmer(false);
    }
    setShowShimmer(false);
  };

  const moreInfoHandler = async (programId, value, content) => {
    if (value?.class_type === "weekly") {
      navigate(`/cheer/team-cheer/${value?.id}`);
      return;
    }
    if (value?.isLanding) {
      setShowShimmer(true);
      navigate(`/advance-gymnastics/${value?.id}`);
      setTimeout(() => {
        setShowShimmer(false);
      }, [1000]);
      return;
    }
    if (value?.name === "Team Cheer") {
      setShowShimmer(true);
      navigate(`/cheer/team-cheer/${value?.id}`);
      setTimeout(() => {
        setShowShimmer(false);
      }, [1000]);
      return;
    }
    if (value?.name === "Advance GYM") {
      setShowShimmer(true);
      navigate(`/cheer/team-gymnastic/44`, {
        state: {
          isAdvanceGym: true,
        },
      });
      setTimeout(() => {
        setShowShimmer(false);
      }, [1000]);
      return;
    }
    setShowShimmer(false);

    if (id === 169 && programId === 307) {
      if (programId === 307) {
        navigate(`/cheer/team-cheer/${programId}`);
      } else {
        navigate(`/cheer/team-gymnastic/${programId}`);
      }
    } else if (programId === 308) {
      navigate("/cheer/team-gymnastic/44");
    } else {
      if (content !== undefined) {
        const tempContent = content?.split(" ");
        if (tempContent?.[0] === "12-24") {
          let str = tempContent[0];
          let result = str.substring(0, 3) + str.substring(4, 5);
          navigate(
            `/browse-by-program/${id}/${result}${programId}${temp?.search}`,
            {
              data: programId,
            }
          );
          return;
        }
        navigate(
          `/browse-by-program/${id}/${tempContent?.[0]}${programId}${temp?.search}`,
          {
            data: programId,
          }
        );
      } else {
        navigate(`/browse-by-program/${id}/${programId}${temp?.search}`, {
          data: programId,
        });
      }
    }
  };

  useEffect(() => {
    setContent(getContentBasedOnId(id));
  }, [id]);

  useEffect(() => {
    fetchData();
    getSubProgramFunction();
  }, [content]);

  const getSubProgramFunction = async () => {
    setShowShimmer(true);
    const response = await getSubPrograms(token);
    setTimeout(() => {
      setShowShimmer(false);
    }, [1000]);
    const age_range_response = await getCmsAge(token);
    setAgeRanges(age_range_response);
    setShowProgramData(response);
  };

  const filterData =
    temp.search.split("=")?.pop() === "true"
      ? subProgramData?.filter((item) => item?.id == id)
      : ageRanges?.filter((item) => item?.id == id);

  useEffect(() => {
    if (
      typeof content === "string" &&
      content.trim().toLowerCase() === "cheer"
    ) {
      setCheerText(true);
    }
  }, []);

  useEffect(() => {
    if (token) {
      const sessionTimeOut = setTimeout(() => {
        localStorage.removeItem("access_token");
        navigate("/");
      }, 86400000);
      return () => clearTimeout(sessionTimeOut);
    }
  }, [navigate]);

  const expandSubtitleHandler = (index) => {
    setShowMore((prevState) => {
      const temp = [...prevState];
      temp[index] = !temp[index];
      return temp;
    });
  };

  return (
    <>
      {showShimmer ? (
        <ProgramDetailShimmer />
      ) : (
        <div className="contentwraper">
          <section style={{ position: "relative" }}>
            <section className="siteBanner" style={{ background: "#C3D940" }}>
              <div className="siteBanner__overlay">
                <img
                  className="siteBanner__img"
                  src={
                    filterData?.[0]?.image !== null
                      ? filterData?.[0]?.image
                      : aboutUsBanner
                  }
                  alt="about-us-banner"
                />
              </div>
            </section>
          </section>
          <section className="breadcrumbSec">
            <div className="container">
              <div className="breadcrumb">
                <div className="breadcrumb__item">
                  <Link
                    className="breadcrumb__link"
                    onClick={() => navigate(-1)}
                  >
                    Back to results
                  </Link>
                </div>
              </div>
            </div>
          </section>

          {/* MAIN CONTENT  */}
          <section className="facilitiesSec">
            <div className="container">
              <div
                className="picture program_picture program_picture_responsive"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "0px 0px",
                }}
              >
                {id == 175 && (
                  <img
                    style={{ height: "60px" }}
                    src={campLogo}
                    alt="gymtimeGuy"
                    className="picture__img"
                  />
                )}
                {id == 169 && (
                  <img
                    style={{ height: "70px" }}
                    src={cheerlogo}
                    alt="gymtimeGuy"
                    className="picture__img"
                  />
                )}
                <DisplayImage content={content} titleContent={titleContent} />{" "}
                <h1 className="title title--extrabold">
                  {cheertText ? "Cheerleading" : content}
                </h1>
              </div>

              <div
                className="shortText shortText_prgram_detail"
                style={{ padding: "0px 20px" }}
              >
                {" "}
                <p>{filterData?.[0]?.description}</p>
              </div>

              {classesProgram?.length > 0 ? (
                <div className="programList">
                  {classesProgram?.map((val, index) => {
                    let styleimage;
                    if (index % 3 == 0) {
                      styleimage = "newButton-image1";
                    } else if (index % 3 == 1) {
                      styleimage = "newButton-image2";
                    } else {
                      styleimage = "newButton-image3";
                    }

                    let imagePath;
                    if (index % 3 == 0) {
                      imagePath = recImage;
                    } else if (index % 3 == 1) {
                      imagePath = teamIMage;
                    } else {
                      imagePath = gymImage;
                    }

                    return (
                      <div className="programList__item" key={index}>
                        <div className="programList__card">
                          <div className="picture">
                            {id == 169 || id == 175 ? (
                              content && (
                                <img
                                  src={
                                    val?.class_type === "weekly"
                                      ? val?.image_page
                                      : val?.image_front_class
                                      ? val?.image_front_class
                                      : val?.image
                                      ? val?.image
                                      : imagePath
                                  }
                                  alt="HIT "
                                />
                              )
                            ) : (
                              <img
                                className="picture__img"
                                src={val?.image ? val?.image : programImg1}
                                alt=""
                              />
                            )}

                            {id == 169 && (
                              <div
                                class={`buttons  ${styleimage}`}
                                onClick={() => moreInfoHandler(val?.id, val)}
                              >
                                <button class="btn ">
                                  <span
                                    style={{
                                      fontSize: "1.3rem",
                                      fontWeight: "600",
                                    }}
                                  >
                                    More Info
                                  </span>
                                </button>
                              </div>
                            )}
                          </div>

                          <div className="detail">
                            <div className="content">
                              <h3 className="title title--extrabold">
                                {val?.class_type === "weekly"
                                  ? val?.camp_secondary_name
                                  : val.umbrella_name
                                  ? val.umbrella_name
                                  : val?.name
                                  ? val.umbrella_name
                                    ? val.umbrella_name
                                    : val?.name
                                  : "-"}
                              </h3>
                              <h4 className="timeperiod">
                                {val?.min_age > 23
                                  ? `${Math.floor(val?.min_age / 12)} ${
                                      val?.min_age % 12 ? "." : ""
                                    } ${
                                      val?.min_age % 12 ? val?.min_age % 12 : ""
                                    }Years`
                                  : `${val?.min_age} Months`}
                                {val?.max_age < 2500 ? "-" : " "}
                                {val?.max_age < 2500
                                  ? val?.max_age > 23
                                    ? `${Math.floor(val?.max_age / 12)} ${
                                        val?.max_age % 12 ? "." : ""
                                      } ${
                                        val?.max_age % 12
                                          ? val?.max_age % 12
                                          : ""
                                      }Years`
                                    : `${val?.max_age} Months`
                                  : "and up"}
                              </h4>
                              <p className="para">
                                {val?.description ? (
                                  <>
                                    {showMore?.[index]
                                      ? val?.description
                                      : val?.description?.slice(0, 200)}
                                    <button
                                      style={{ color: "grey", fontWeight: 500 }}
                                      onClick={() =>
                                        expandSubtitleHandler(index)
                                      }
                                    >
                                      {val?.description?.length > 200 &&
                                        (showMore?.[index]
                                          ? "...show less"
                                          : "...show more")}
                                    </button>
                                  </>
                                ) : (
                                  `With over 30 years of experience, we've established ourselves as the leader in child development.`
                                )}
                              </p>
                            </div>
                            {id == 169 || id == 175 ? (
                              ""
                            ) : (
                              <div className="buttons">
                                <button
                                  className="btn btn--primary"
                                  onClick={() => moreInfoHandler(val?.id, val)}
                                >
                                  <span
                                    style={{
                                      fontSize: "1.3rem",
                                      padding: "15px",
                                    }}
                                  >
                                    More Info
                                  </span>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : classesAge?.length > 0 ? (
                <div className="programList">
                  {classesAge.map((val, index) => {
                    return (
                      <div className="programList__item" key={index}>
                        <div className="programList__card">
                          <div className="picture">
                            <img
                              className="picture__img"
                              src={val?.image ? val?.image : programImg1}
                              alt=""
                            />
                          </div>
                          <div className="detail">
                            <div className="content">
                              <h3 className="title title--extrabold">
                                {val?.name}
                              </h3>
                              <h4 className="timeperiod">
                                {val?.min_age > 23
                                  ? `${Math.floor(val?.min_age / 12)} ${
                                      val?.min_age % 12 ? "." : ""
                                    } ${
                                      val?.min_age % 12 ? val?.min_age % 12 : ""
                                    } Years`
                                  : `${val?.min_age} Months`}
                                {val?.max_age < 2500 && "-"}
                                {val?.max_age < 2500
                                  ? val?.max_age > 23
                                    ? `${Math.floor(val?.max_age / 12)} ${
                                        val?.max_age % 12 ? "." : ""
                                      } ${
                                        val?.max_age % 12
                                          ? val?.max_age % 12
                                          : ""
                                      }Years`
                                    : `${val?.max_age} Months`
                                  : " and up"}
                              </h4>
                              <p className="para">
                                {val?.description ? (
                                  <>
                                    {showMore?.[index]
                                      ? val?.description
                                      : val?.description?.slice(0, 200)}
                                    <button
                                      style={{ color: "grey", fontWeight: 500 }}
                                      onClick={() =>
                                        expandSubtitleHandler(index)
                                      }
                                    >
                                      {val?.description?.length > 200 &&
                                        (showMore?.[index]
                                          ? "...show less"
                                          : "...show more")}
                                    </button>
                                  </>
                                ) : (
                                  `With over 30 years of experience, we've established ourselves as the leader in child development.`
                                )}
                              </p>
                            </div>
                            <div className="buttons">
                              <button
                                className="btn btn--primary"
                                onClick={() =>
                                  moreInfoHandler(val.id, val, content)
                                }
                              >
                                More Info
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  className="title title--h1 text-center"
                  style={{ color: "#EA519C", padding: "20px" }}
                >
                  No Program Found!
                </div>
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default ProgramDetails;
