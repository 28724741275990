import React from "react";

function SelectedMakeupComponent({ selectedData, handleDelete }) {
  return (
    <div className="makeup_card">
      <div className="close_div" onClick={handleDelete}>
        &#x2715;
      </div>
      <p>
        <span>
          <b>CLASS NAME</b>
        </span>{" "}
        : {selectedData?.val?.name}
      </p>
      <p className="makeupText">
        <span>
          <b>DATE</b>
        </span>{" "}
        : {selectedData?.date}
      </p>

      <p>
        <span>
          <b>MEMO</b>
        </span>{" "}
        : {selectedData?.memo}
      </p>
      <p>
        <span>
          <b>TYPE</b>
        </span>{" "}
        : {selectedData?.type}
      </p>
    </div>
  );
}

export default SelectedMakeupComponent;
