import { Link } from "react-router-dom";
import { aboutPageContent } from "../../../api/contentApi";
import cheerlogo from "../../../parent-assets/images/enrichment-images/cheer.png";
import cheerImage from "../../../parent-assets/images/enrichment-images/cheerImage.png";
import OwlCarousel from "react-owl-carousel";
import { useEffect } from "react";

import dummyImage from "../../../parent-assets/images/birthday-icons/import-dummy.png";
import { Fancybox } from "@fancyapps/ui";

function RecCheer() {
  useEffect(() => {
    const fancyboxOptions = {};
    Fancybox.bind("[data-fancybox='gallery']", fancyboxOptions);
  }, []);

  const responsiveOptions = {
    0: {
      items: 1,
    },
    768: {
      items: 3,
    },
    1024: {
      items: 4,
    },
  };

  return (
    <div className="contentwraper">
      <section style={{ position: "relative" }}>
        <section className="siteBanner" style={{ background: "#ea519c" }}>
          <div className="siteBanner__overlay">
            <img
              className="siteBanner__img"
              src={aboutPageContent.aboutBannerImage.url}
              alt="banner"
            />
          </div>
        </section>
        <div className="navbar_heading_siteBanner_img">
          <h1 className="navbar_heading_siteBanner_img_heading">
            {" "}
            Cheerleading
          </h1>
        </div>
      </section>

      {/* BODY  */}
      <section className="breadcrumbSec">
        <div className="container">
          <div className="breadcrumb">
            <div className="breadcrumb__item">
              <Link className="breadcrumb__link" to="/">
                Back to results
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="facilitiesSec">
        <div className="container">
          <div className="facility_gymTimeGuywithName">
            <img
              src={cheerlogo}
              alt="gymtimeGuy"
              className="gymTimeGuyIcon_fac"
            />
            <h1 className="title title--withicon title--extrabold">
              Recreational Cheer
            </h1>
          </div>

          <section className="aboutusSec" style={{ paddingBottom: "2px" }}>
            <div className="container">
              <div className="aboutData">
                <div className="aboutData__box">
                  <div
                    className="aboutData__row"
                    style={{
                      display: "flex",
                      gap: "30px",
                      height: "min-content",
                    }}
                  >
                    <div className="aboutData__col">
                      <div className="content">
                        <h2 style={{ fontWeight: "500", lineHeight: "1.3rem" }}>
                          Whether your child is a novice or experienced, our
                          cheer program will focus on building the fundamentals
                          of a well-rounded cheerleader. The athletes will build
                          strength and flexibility while focusing on stunting,
                          tumbling, jumping and motion technique. Our program
                          strives to produce athletes that can successfully
                          transition into the expansive sport of cheer ranging
                          from sideline cheerleaders at school to high level
                          competitive cheerleaders! As cheerleaders, we also
                          learn important life skills such as teamwork,
                          responsibility and taking direction as we are
                          consistently showing up for others to achieve a common
                          goal and improve as a group.
                        </h2>
                      </div>
                    </div>
                    <div className="aboutData__col">
                      <img src={cheerImage} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* pinkImage */}
          <section className="pinkBg-section">
            <h1
              className="title--h5"
              style={{
                fontWeight: "600",
                lineHeight: "31.57px",
                marginRight: "30px",
              }}
            >
              Mandatory Practise Tuesday 3:30 - 6:30
            </h1>
            <p style={{ marginBottom: "5px", lineHeight: "51.57px" }}>
              *Additional pop up classes close to competition
            </p>
            <div className="container">
              <img src={dummyImage} alt="" />
            </div>
          </section>

          {/* CAROUSEL  */}
          <section
            className="facilitiesSec cheer-carousel"
            style={{ padding: "80px 100px" }}
          >
            <div className="container">
              <OwlCarousel
                margin={10}
                responsiveclassname={true}
                responsive={responsiveOptions}
                className="galleryGrid owl-carousel"
                autoplay
                loop={true}
                arrows="true"
                dots={false}
                nav={true}
                lazyLoad={true}
                navText={[
                  `<img src=${require("../../../parent-assets/images/enrichment-images/arrow-left.png")} />`,
                  `<img src=${require("../../../parent-assets/images/enrichment-images/arrow-right.png")} alt='right' />`,
                ]}
              >
                <div className="galleryGrid__item">
                  <div className="galleryGrid__card">
                    <a
                      className="galleryGrid__card__link"
                      data-fancybox="gallery"
                      href="/"
                    >
                      <img
                        className="galleryGrid__card__img"
                        src={dummyImage}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="galleryGrid__item">
                  <div className="galleryGrid__card">
                    <a
                      className="galleryGrid__card__link"
                      data-fancybox="gallery"
                      // href={galleryImg2}
                      href="/"
                    >
                      <img
                        className="galleryGrid__card__img"
                        src={dummyImage}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="galleryGrid__item">
                  <div className="galleryGrid__card">
                    <a
                      className="galleryGrid__card__link"
                      data-fancybox="gallery"
                      href={"/"}
                    >
                      <img
                        className="galleryGrid__card__img"
                        src={dummyImage}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="galleryGrid__item">
                  <div className="galleryGrid__card">
                    <a
                      className="galleryGrid__card__link"
                      data-fancybox="gallery"
                      href={"/"}
                    >
                      <img
                        className="galleryGrid__card__img"
                        src={dummyImage}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}

export default RecCheer;
