import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { aboutPageContent } from "../../api/contentApi";
import campImage from "../../parent-assets/images/About.jpg";
// import camp4 from "../../parent-assets/images/enrichment-images/camp-4.png";
import campLogo from "../../parent-assets/images/enrichment-images/camp-icon-23.png";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../reducers/loadingSlice";
import { useEffect, useState } from "react";
import {
  getCampSubProgramFrontend,
  getSubPrograms,
} from "../../../Admin/adminApi/subProgram";

import { getCampVersionDetails } from "../../api/campClassDetail";
import AboutUsShimmer from "../../../Admin/components/ShimmerUi/AboutUsShimmer";

function CampProgram() {
  const dispatch = useDispatch();
  const campApiData = useLocation();
  const [showShimmer, setShowShimmer] = useState(false);
  const [campSubProgramData, setCampSubProgramData] = useState([]);
  const [campVersionDetails, setCampVersionDetails] = useState([]);
  const campReduxData = campApiData?.state?.data;
  const [programData, setProgramData] = useState([]);

  const token = localStorage.getItem("access_token");
  const { id } = useParams();
  const [getAllProgramList, setGetAllProgramList] = useState();
  useEffect(() => {
    fetchData();
    getCampSubProgramId();
  }, []);

  const getCampSubProgramId = async () => {
    const response = await getCampSubProgramFrontend();
    setCampSubProgramData(response?.data?.data);
  };

  const fetchData = async () => {
    dispatch(setLoading(true));
    try {
      const getProgramMangeData = await getSubPrograms(token, id);
      setTimeout(() => {
        dispatch(setLoading(false));
      }, [1000]);
      setGetAllProgramList(getProgramMangeData);
    } catch (err) {
      dispatch(setLoading(false));
      return err;
    }
  };

  useEffect(() => {
    getCampDetailsData();
  }, []);

  const getCampDetailsData = async () => {
    setShowShimmer(true);
    dispatch(setLoading(true));
    const response = await getSubPrograms(token);
    setTimeout(() => {
      dispatch(setLoading(false));
    }, [1000]);
    setProgramData(response);
    setShowShimmer(false);
  };

  const filterCampData = programData?.filter((item) => item?.id === id);

  const campClassMoreInfoHandler = async (id, item) => {
    dispatch(setLoading(true));
    const response = await getCampVersionDetails(id, token);
    setTimeout(() => {
      dispatch(setLoading(false));
    }, [1000]);
    setCampVersionDetails(response);
    navigate(
      item?.program_sub_name === "4-4.11 Camp" ||
        item?.program_sub_name === "4-4.11 CAMP"
        ? `/camp-version/${id}`
        : item?.program_sub_name === "3-3.11 CAMP" ||
          item?.program_sub_name === "3-3.11 Camp"
        ? `/camp-version/${id}`
        : item?.program_sub_name === "Girl Gymnastics Camp 5+"
        ? `/camp/girl-gymnastics/${id}`
        : item?.program_sub_name === "TEAM GYMNASTICS"
        ? `/cheer/team-gymnastic/${id}`
        : `/cheer/team-gymnastic/${id}`,
      {
        state: {
          campVersionDetails: response,
          item: item,
          flag: "camp",
        },
      }
    );
  };

  const navigate = useNavigate();

  return (
    <>
      {showShimmer ? (
        <AboutUsShimmer />
      ) : (
        <div className="contentwraper">
          <section style={{ position: "relative" }}>
            <section className="siteBanner" style={{ background: "#ea519c" }}>
              <div className="siteBanner__overlay">
                <img
                  className="siteBanner__img"
                  src={
                    filterCampData?.[0]?.image
                      ? filterCampData[0]?.image
                      : aboutPageContent.aboutBannerImage.url
                  }
                  alt="banner"
                />
              </div>
            </section>
          </section>

          {/* BACK TO RESULT SECTION  */}
          <section className="breadcrumbSec">
            <div className="container">
              <div className="breadcrumb">
                <div className="breadcrumb__item">
                  <Link
                    className="breadcrumb__link"
                    onClick={() => navigate(-1)}
                  >
                    Back to results
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="aboutusSec camp_sec">
            <div className="container ">
              <div
                className="picture camp_main_pic_div"
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <img
                  src={campLogo}
                  alt="gymtimeGuy"
                  className="picture__img"
                  style={{ height: "100px" }}
                />
                <h1 className="title title--withicon title--extrabold">
                  Camps
                </h1>
              </div>
              {/* MAin Content  */}
              <div className="aboutData" style={{ marginTop: "20px" }}>
                <div className="aboutData__box">
                  <div className="aboutData__row">
                    <div className="aboutData__col ">
                      <div className="content">
                        <div className="shortText">
                          <p className="enrichment_combos_shorttext enr_text">
                            {filterCampData?.[0]?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="aboutData__col   ">
                      <div className="picture ">
                        <img
                          className="picture__img"
                          src={
                            filterCampData?.[0]?.sub_image
                              ? filterCampData?.[0]?.sub_image
                              : campImage
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* LISTS */}{" "}
              <h1
                className="title title--withicon title--extrabold"
                style={{ textAlign: "center", marginBottom: "40px" }}
              >
                All Camps Runs from July 1st - August 9th{" "}
              </h1>
              <div className="camp_container">
                {/* 3-3.11 DIV  */}
                {campSubProgramData?.map((item) => (
                  <div class="programList__item  camp_item" key={item?.id}>
                    <div class="programList__card  camp_card">
                      <div
                        className="picture_enrichment"
                        style={{ cursor: "pointer" }}
                      >
                        <img src={item?.image_front} alt="" />

                        <div className="detail">
                          <div class="buttons camp-btn ">
                            <button
                              class="btn btn--primary camp-btn"
                              onClick={() => {
                                campClassMoreInfoHandler(item?.id, item);
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "1.3rem",
                                  padding: "15px",
                                }}
                              >
                                More Info
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="detail camp_item ">
                        <div class="content camp_content_mobile">
                          <h3 class="title title--extrabold cheerleading_title">
                            {item?.program_sub_name}
                          </h3>
                          <p
                            class="enrichment_para_content"
                            style={{ fontSize: "1.2rem" }}
                          >
                            {/* {item?.description} */}
                            {item?.description && item?.description?.length > 19
                              ? `${item?.description?.substring(0, 100)}...`
                              : item?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default CampProgram;
