const GET_BLOGS=`
query GetblogsCMS($page: Int!, $limit: Int!) {
  getblogsCMS(page: $page, limit: $limit) {
    currPage
    totalPages
    totalCount
    limit
    data {
      admin_user
      content
      createdAt
      image
      id
      is_active_blog
      likeData {
        blogId
        id
        like
        userId
      }
      like_count
      title
      video
      voice
    }
  }
}
 `
 
 
const GET_LIKES=`query GetTotalLikes {
getTotalLikes {
  like
  id
  blogId
  userId
}
}
 
`
 
const GET_COMMENTS = `query GetCommentsBlog($blogId: Int!) {
  getCommentsBlog(blogId: $blogId) {
    clientName
    commemts
    createdTime
  }
}`
 
 
export {
  GET_BLOGS,
  GET_LIKES,
  GET_COMMENTS
}
