import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSuccessModalContent } from "../../reducers/userSlice";

function EditStudentModal({
  studentData,
  onSave,
  onClose,
  editingStudentId,
  setSuccessModal,
}) {
  const [formData, setFormData] = useState(studentData);
  const [error, setError] = useState(null);

  let accessToken = localStorage.getItem("access_token");
  const dispatch = useDispatch();

  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#fff",
    padding: "20px",
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    width: "300px",
    textAlign: "center",
  };

  const closeBtnStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const editedData = {
      studentDetails: {
        first_name: formData.first_name,
        last_name: formData.last_name,
      },
    };

    axios
      .put(
        process.env.REACT_APP_BASE_URL + `/student/update/${editingStudentId}`,
        editedData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        setSuccessModal(true);
        dispatch(setSuccessModalContent("Student Updated Succesfully."));

        onSave(formData);
        onClose();
      })
      .catch((error) => {
        setError("Error updating student data.");
        console.error("Error updating student data:", error);
      });
  };

  useEffect(() => {}, [studentData]);
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 999,
        }}
      >
        <div style={modalStyle}>
          <span style={closeBtnStyle} onClick={onClose}>
            &times;
          </span>
          <h2>Edit Student Information</h2>
          {error && <div>Error: {error}</div>}
          <form onSubmit={handleSubmit}>
            <div>
              <label>First Name:</label>
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Last Name:</label>
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
              />
            </div>
            <button type="submit">Save</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditStudentModal;
