import React from 'react'
import "./CarasouelShommer.css"
const CarasouelShimmer = () => {
    return (
        <>
            <div className="birthday_carasouel_shimmer_container">
                <div className="birthday_shimmer_row">
                    <div className="birthady_shimmer_col">
                       
                    </div>
                    <div className="birthady_shimmer_col">

                    </div>
                    <div className="birthady_shimmer_col">

                    </div>
                </div>
            </div>
        </>
    )
}

export default CarasouelShimmer