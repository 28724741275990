import axios from "axios";
import {
  GET_MASTERMENU,
  GET_MASTERSUBMENU,
  GET_SECTION,
} from "../pages/adminQueries/MasterMenu";

export const getMasterMenu = async (token) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_MASTERMENU,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data?.getmasterMenuCMS;
  } catch (error) {
    return error;
  }
};

export const getMasterSubMenu = async (token, menuId) => {
  try {
    const id = parseInt(menuId);
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_MASTERSUBMENU,
        variables: {
          masterMenuId: id,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data?.data?.getsubMenuCMS;
  } catch (error) {
  return error;
  }
};

export const getSectionCMS = async (token, id) => {
  const intId = parseInt(id);
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_SECTION,
        variables: {
          subMenuId: intId ? intId : 174,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data?.data?.getsectionCMS;
  } catch (error) {
    return error;
  }
};
