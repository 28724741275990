const GET_ALL_STUDENT_ENROLLMENTS = `

query GetAllStudentEnrollments {
  getAllStudentEnrollments {
    studentEnrolled {
      id
      first_name
      last_name
      age
      fk_clients_students
      dob
      gender
      allergies
      createdat
      updatedat
      doctor_name
      doctor_phone
      emergency_name
      emergency_phone
      vaccination
      photo_release
      parent_signature
      address
      is_active
      fk_student_classes_enrolled
      fk_class_schedule_classes_enrolled
      start_date
      end_date
      fk_teacher_class_schedule
      fk_class_class_schedules
      start_time
      end_time
      weekday
      fk_season_classes
      name
      fk_category_classes
      tuition
      max_age
      min_age
      website
      hidden
      max_strength
      period
      class_enrollment_end_date
      class_enrollment_start_date
      image
      class_image
      orderId
      classEnrolledId
      classId
      classScheduleId
      studentId
      invoiceReady
      classSchedules {
        id
        fk_teacher_class_schedule
        fk_class_class_schedules
        start_time
        end_time
        weekday
        updatedat
        createdat
        name
        tuition
        classScheduleId
      }
    }
    birthdayOrders {
      id
      first_name
      last_name
      age
      fk_clients_students
      dob
      gender
      allergies
      createdat
      updatedat
      doctor_name
      doctor_phone
      emergency_name
      emergency_phone
      vaccination
      photo_release
      parent_signature
      address
      is_active
      image
      date
      time
      orderId
      package {
        id
        name
      }
      addOns {
        id
        name
      }
    }
  }
}
`;

const GET_CLIENT = `
query GetClient { getClient
   { 
    id 
    parent1_first_name 
    parent1_last_name 
    parent1_cell 
    parent1_alt_cell 
    parent1_email 
    parent1_address 
    parent1_apartment 
    parent1_zip parent1_city 
    parent2_first_name 
    parent2_last_name 
    parent2_cell 
    parent2_alt_cell 
    parent2_email 
    parent2_address 
    parent2_address2
    parent1_address2
    parent1_workphone
    parent2_workphone
    parent2_apartment 
    parent2_zip 
    parent2_city 
    parent2_state
    parent1_state 
    fk_user_clients 
    createat updatedat
    parent1_image
    parent2_image
   }
   }
    `;

export { GET_ALL_STUDENT_ENROLLMENTS, GET_CLIENT };
