import React, { useState } from "react";

import BannerImage from "../../parent-assets/images/about-us-banner-1.jpeg";
import familyIcon from "../../parent-assets/images/family-icon.svg";
import studentIcon from "../../parent-assets/images/student-icon.svg";
import enrollMentIcon from "../../parent-assets/images/enrollment-icon.svg";
// FUCNTIONALITY..
import { getClients, getUpdateParent } from "../../api/parent-profile";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../reducers/loadingSlice";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import SuccesModal from "../../parent-components/User/SuccesModal";
import { setSuccessModalContent } from "../../../reducers/userSlice";
import LoaderComponent from "../../../Admin/components/LoaderComponent";
import StudentTabContent from "./StudentTabContent";
import EnrollmentTabContent from "./EnrollmentTabContent";
import { isValidName, isValidUSZipCode } from "../../utility/validation";
import { getStates } from "../../../Admin/adminApi/users";

function ParentProfile() {
  const [activeTab, setActiveTab] = useState("tab1");
  const { state } = useLocation();
  const loader = useSelector((state) => state?.loading?.loading);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const [successModal, setSuccessModal] = useState(false);
  const [getStateData, setGetStateData] = useState();
  // Parent and Student Account..
  const [updatedButton, setUpdatedButton] = useState();
  let token = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [prefilledClientData, setPrefilledClientData] = useState();
  // Parent 1 form
  const [parent1Form, setParent1Form] = useState({
    parent1_first_name: "",
    parent1_last_name: "",
    parent1_address: "",
    parent1_address2: "",
    parent1_cell: "",
    parent1_alt_cell: "",
    parent1_city: "",
    parent1_state: "",
    parent1_zip: "",
    parent1_apartment: "",
    parent1_image: "",
    parent1_image_name: "",
  });
  // Parent 2 Form:
  const [parent2Form, setParent2Form] = useState({
    parent2_first_name: "",
    parent2_last_name: "",
    parent2_cell: "",
    parent2_alt_cell: "",
    parent2_email: "",
    parent2_address: "",
    parent2_address2: "",
    parent2_apartment: "",
    parent2_zip: "",
    parent2_city: "",
    parent2_image: "",
    parent2_image_name: "",

    parent2_state: "",
  });
  const [parent1FormErrors, setParent1FormErrors] = useState({
    parent1_first_name: "",
    parent1_last_name: "",
    parent1_address: "",
    parent1_address2: "",
    parent1_cell: "",
    parent1_alt_cell: "",
    parent1_city: "",
    parent1_state: "",
    parent1_zip: "",
    parent1_image: "",
    parent1_image_name: "",
  });

  const [parent2FormErrors, setParent2FormErrors] = useState({
    parent2_first_name: "",
    parent2_last_name: "",
    parent2_cell: "",
    parent2_alt_cell: "",
    parent2_email: "",
    parent2_address: "",
    parent2_address2: "",
    parent2_zip: "",
    parent2_city: "",
    parent2_image: "",
    parent2_image_name: "",
    parent2_state: "",
  });

  const validateParent1Form = () => {
    if (!parent1Form) {
      return {};
    }

    let errors = {};

    if (!parent1Form.parent1_first_name?.trim()) {
      errors.parent1_first_name = "First Name is required";
    }

    if (!parent1Form.parent1_last_name?.trim()) {
      errors.parent1_last_name = "Last Name is required";
    }

    if (!parent1Form.parent1_address?.trim()) {
      errors.parent1_address = "Address is required";
    }

    if (!parent1Form.parent1_cell?.trim()) {
      errors.parent1_cell = "Cell is required";
    }

    if (!parent1Form.parent1_alt_cell?.trim()) {
      errors.parent1_alt_cell = "Home Phone is required";
    }

    if (!parent1Form.parent1_state?.trim()) {
      errors.parent1_state = "State is required";
    }

    if (!parent1Form.parent1_zip?.trim()) {
      errors.parent1_zip = "ZIP Code is required";
    }

    if (!parent1Form.parent1_city?.trim()) {
      errors.parent1_city = "City is required";
    }

    setParent1FormErrors(errors);
    return errors;
  };
  const validateParent2Form = () => {
    if (!parent2Form) {
      return {};
    }

    let errors = {};

    if (!parent2Form.parent2_first_name?.trim()) {
      errors.parent2_first_name = "First Name is required";
    }

    if (!parent2Form.parent2_last_name?.trim()) {
      errors.parent2_last_name = "Last Name is required";
    }

    if (!parent2Form.parent2_address?.trim()) {
      errors.parent2_address = "Address is required";
    }

    if (!parent2Form.parent2_cell?.trim()) {
      errors.parent2_cell = "Cell is required";
    }

    if (!parent2Form.parent2_alt_cell?.trim()) {
      errors.parent2_alt_cell = "Home Phone is required";
    }

    if (!parent2Form.parent2_state?.trim()) {
      errors.parent2_state = "State is required";
    }

    if (!parent2Form.parent2_zip?.trim()) {
      errors.parent2_zip = "ZIP Code is required";
    }

    if (!parent2Form.parent2_city?.trim()) {
      errors.parent2_city = "City is required";
    }

    setParent2FormErrors(errors);
    return errors;
  };

  const ParentHandler2 = async (e) => {
    e.preventDefault();

    // Validate form fields and update error state
    const errors = validateParent2Form();
    const errorFields = Object.keys(errors).filter(
      (key) => errors[key] !== "" || null
    );

    if (errorFields.length > 0) {
      // Create a list of fields with errors to display
      const fieldNamesWithErrors = errorFields
        .map((field) => {
          switch (field) {
            case "parent2_first_name":
              return "First Name";
            case "parent2_last_name":
              return "Last Name";
            case "parent2_address":
              return "Address";
            case "parent2_cell":
              return "Cell";
            case "parent2_alt_cell":
              return "Home Phone";
            case "parent2_state":
              return "State";
            case "parent2_zip":
              return "ZIP Code";
            case "parent2_city":
              return "City";
            default:
              return field;
          }
        })
        .join(", ");

      // Show toast error message with specific field names
      toast.error(
        `Please Enter the following fields: ${fieldNamesWithErrors}`,
        {
          toastId: "error",
        }
      );
      return;
    }

    setUpdatedButton(false);
    try {
      dispatch(setLoading(true));
      const response = await getUpdateParent(parent2Form, token);
      dispatch(setSuccessModalContent(response?.data));
      setSuccessModal(true);
      dispatch(setLoading(false));
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
      dispatch(setLoading(false));
    }
  };
  const ParentHandler1 = async (e) => {
    e.preventDefault();

    // Validate form fields and update error state
    const errors = validateParent1Form();
    const errorFields = Object.keys(errors).filter(
      (key) => errors[key] !== "" || null
    );

    if (errorFields.length > 0) {
      // Create a list of fields with errors to display
      const fieldNamesWithErrors = errorFields
        .map((field) => {
          switch (field) {
            case "parent1_first_name":
              return "First Name";
            case "parent1_last_name":
              return "Last Name";
            case "parent1_address":
              return "Address";
            case "parent1_cell":
              return "Cell";
            case "parent1_alt_cell":
              return "Home Phone";
            case "parent1_state":
              return "State";
            case "parent1_zip":
              return "ZIP Code";
            case "parent1_city":
              return "City";
            default:
              return field;
          }
        })
        .join(", ");

      // Show toast error message with specific field names
      toast.error(
        `Please Enter the following fields: ${fieldNamesWithErrors}`,
        {
          toastId: "error",
        }
      );
      return;
    }

    // API CALL
    setUpdatedButton(false);
    try {
      dispatch(setLoading(true));
      const response = await getUpdateParent(parent2Form, token);
      dispatch(setSuccessModalContent(response?.data));
      setSuccessModal(true);
      dispatch(setLoading(false));
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
      dispatch(setLoading(false));
    }
  };

  // Parent 1 hanlde Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Apply formatting to the phone number

    switch (name) {
      case "parent1_first_name":
        if (!isValidName(e.target.value)) {
          setParent1FormErrors({
            ...parent1FormErrors,
            [name]: "First Name do not contains number.",
          });
        } else {
          setParent1FormErrors({ ...parent1FormErrors, [name]: "" });
        }
        break;
      case "parent1_last_name":
        if (!isValidName(e.target.value)) {
          setParent1FormErrors({
            ...parent1FormErrors,
            [name]: "Last Name do not contains number.",
          });
        } else {
          setParent1FormErrors({ ...parent1FormErrors, [name]: "" });
        }
        break;

      case "parent1_zip":
        if (!isValidUSZipCode(value)) {
          setParent1FormErrors({
            ...parent1FormErrors,
            [name]: "Zip code Should be 5-digits.",
          });
        } else {
          setParent1FormErrors({ ...parent1FormErrors, [name]: "" });
        }
        break;
      default:
        setParent1FormErrors({ ...parent1FormErrors, [name]: "" });
    }

    setParent2Form({
      ...parent2Form,
      [name]: value || "",
    });

    setParent2FormErrors(parent1FormErrors);
    setParent1Form((prevForm) => ({
      ...prevForm,
      [name]: value || "",
    }));
  };

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    const errors = { ...parent2FormErrors };

    switch (name) {
      case "parent2_first_name":
        if (!isValidName(value)) {
          errors[name] = "First Name should not contain numbers.";
        } else {
          errors[name] = "";
        }
        break;
      case "parent2_last_name":
        if (!isValidName(value)) {
          errors[name] = "Last Name should not contain numbers.";
        } else {
          errors[name] = "";
        }
        break;

      default:
        errors[name] = "";
    }

    setParent2Form({
      ...parent2Form,
      [name]: value,
    });
    setParent2FormErrors(errors);
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    try {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (e.target.name.startsWith("parent1")) {
            setParent1Form({
              ...parent1Form,
              [e.target.name]: reader.result,
              parent1_image_name: file.name,
            });
          } else {
            setParent2Form({
              ...parent2Form,
              [e.target.name]: reader.result,
              parent2_image_name: file.name,
            });
          }
        };
        reader.readAsDataURL(file);
      } else {
      }
    } catch (error) {
      return error;
    }
  };
  // get All States
  const getState = async () => {
    const response = await getStates();
    setGetStateData(response?.data?.getStates);
  };

  useEffect(() => {
    setActiveTab(state);
  }, [state]);

  useEffect(() => {
    getState();
  }, []);

  // Prefilled Data  ::
  const getClientData = async () => {
    try {
      const getClientsData = await getClients(token);
      setPrefilledClientData(getClientsData?.getClient);
    } catch (error) {}
  };
  useEffect(() => {
    if (activeTab === "tab1") {
      getClientData();

      setParent1Form({
        parent1_first_name: prefilledClientData?.parent1_first_name,
        parent1_last_name: prefilledClientData?.parent1_last_name,
        parent1_address: prefilledClientData?.parent1_address,
        parent1_address2: "",
        parent1_cell: prefilledClientData?.parent1_cell,
        parent1_alt_cell: prefilledClientData?.parent1_alt_cell,
        parent1_city: prefilledClientData?.parent1_city,
        parent1_state: prefilledClientData?.parent1_state,
        parent1_zip: prefilledClientData?.parent1_zip,
        parent1_image: prefilledClientData?.parent1_image,
        parent1_image_name: "",
      });
      setParent2Form({
        parent2_first_name: prefilledClientData?.parent2_first_name,
        parent2_last_name: prefilledClientData?.parent2_last_name,
        parent2_address: prefilledClientData?.parent2_address,
        parent2_address2: "",
        parent2_cell: prefilledClientData?.parent2_cell,
        parent2_alt_cell: prefilledClientData?.parent2_alt_cell,
        parent2_city: prefilledClientData?.parent2_city,
        parent2_state: prefilledClientData?.parent2_state,
        parent2_zip: prefilledClientData?.parent2_zip,
        parent2_image: prefilledClientData?.parent2_image,
        parent2_image_name: "",
      });
    }

    if (loader) {
      dispatch(setLoading(false));
    }
  }, [activeTab]);

  return (
    <>
      {loader && <LoaderComponent />}
      <div className="contentwraper">
        <section className="siteBanner" style={{ background: "#EA519C" }}>
          <div className="siteBanner__overlay">
            <img className="siteBanner__img" src={BannerImage} alt="" />
          </div>
        </section>
        <section className="breadcrumbSec">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb__item">
                <Link
                  className="breadcrumb__link"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back to results
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="parentAccountSec">
          <div className="container">
            <div className="parentAccountSec__topblock">
              <h1 className="title title--h1 title--extrabold">User Profile</h1>
            </div>

            <ul className="tabs">
              <li
                className={`tab-link ${activeTab === "tab1" ? "current" : ""}`}
                onClick={() => handleTabClick("tab1")}
                data-tab="tab1"
              >
                <div className="tabs__card">
                  <div className="tabs__card__icon">
                    <img className="tabs__card__img" src={familyIcon} alt="" />
                  </div>
                  <div className="tabs__card__text">Parents</div>
                </div>
              </li>
              <li
                className={`tab-link ${activeTab === "tab2" ? "current" : ""}`}
                onClick={() => handleTabClick("tab2")}
                data-tab="tab2"
              >
                <div className="tabs__card">
                  <div className="tabs__card__icon">
                    <img className="tabs__card__img" src={studentIcon} alt="" />
                  </div>
                  <div className="tabs__card__text">Students</div>
                </div>
              </li>
              <li
                className={`tab-link ${activeTab === "tab3" ? "current" : ""}`}
                onClick={() => handleTabClick("tab3")}
                data-tab="tab3"
              >
                <div className="tabs__card">
                  <div className="tabs__card__icon">
                    <img
                      className="tabs__card__img"
                      src={enrollMentIcon}
                      alt=""
                    />
                  </div>
                  <div className="tabs__card__text">Enrollment</div>
                </div>
              </li>
            </ul>

            <div className="tab-contents">
              <div
                className={`tab-content ${
                  activeTab === "tab1" ? "current" : ""
                }`}
              >
                <h2 className="title title--h2 title--center">
                  *Please review your account information below.*
                </h2>

                <form
                  className="form parentForm2"
                  name="parentForm2"
                  onSubmit={ParentHandler1}
                >
                  {/* pARENT --1  */}
                  <div className="form__card">
                    {/* STUDENT ADDED Button  */}
                    <div className="form__row">
                      <div className="profileBox">
                        <div className="profileBox__info">
                          <div className="pic">
                            <img
                              className="pic__img"
                              src={parent1Form?.parent1_image}
                              alt=""
                            />
                          </div>
                          <div className="detail">
                            <h3 className="name">
                              {parent1Form?.parent1_first_name} &nbsp;
                              {parent1Form?.parent1_last_name}
                            </h3>
                          </div>
                        </div>
                        <div className="profileBox__actions">
                          <div
                            className="profileBox__actions__item"
                            style={{ display: "flex" }}
                          >
                            <input
                              className="form__input form__input--hidden"
                              type="file"
                              id="fileupload2"
                              name="parent1_image"
                              onChange={handleFileChange}
                            />
                            <label
                              htmlFor="fileupload2"
                              className="btn btn--primary"
                            >
                              Upload New Photo
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="form__group form__group--flex">
                        <div className="form__group__col">
                          <label htmlFor="">
                            First Name{" "}
                            <span className="important_field">*</span>
                          </label>
                          <input
                            className="form__input"
                            id="Name"
                            type="text"
                            placeholder="First name *"
                            name="parent1_first_name"
                            value={parent1Form.parent1_first_name || ""}
                            onChange={handleInputChange}
                          />
                          {parent1FormErrors.parent1_first_name && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent1FormErrors.parent1_first_name}
                            </p>
                          )}
                        </div>
                        <div className="form__group__col">
                          <label htmlFor="">
                            Last Name <span className="important_field">*</span>
                          </label>
                          <input
                            className="form__input"
                            id="Lastname"
                            type="text"
                            placeholder="Last name *"
                            name="parent1_last_name"
                            value={parent1Form.parent1_last_name || ""}
                            onChange={handleInputChange}
                          />
                          {parent1FormErrors.parent1_last_name && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent1FormErrors.parent1_last_name}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form__group form__group--flex">
                        <div
                          className="form__group__col"
                          style={{ width: "100%" }}
                        >
                          <label htmlFor="">
                            Address <span className="important_field">*</span>
                          </label>
                          <input
                            className="form__input"
                            id="Address"
                            type="text"
                            placeholder="Address *"
                            name="parent1_address"
                            value={parent1Form.parent1_address || ""}
                            onChange={handleInputChange}
                          />
                          {parent1FormErrors.parent1_address && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent1FormErrors.parent1_address}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form__group form__group--flex">
                        <div className="form__group__col">
                          <label htmlFor="">
                            Home Phone{" "}
                            <span className="important_field">*</span>
                          </label>

                          <input
                            className="form__input"
                            id="HomePhone"
                            type="text"
                            placeholder="Home Phone"
                            maxLength={14}
                            value={parent1Form.parent1_alt_cell || ""}
                            name="parent1_alt_cell"
                            onChange={handleInputChange}
                          />
                          {parent1FormErrors.parent1_alt_cell && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent1FormErrors.parent1_alt_cell}
                            </p>
                          )}
                        </div>
                        <div className="form__group__col">
                          <label htmlFor="">
                            Cell <span className="important_field">*</span>
                          </label>
                          <input
                            className="form__input"
                            id="CellPhone"
                            type="text"
                            maxLength={14}
                            placeholder="Cell phone"
                            value={parent1Form.parent1_cell || ""}
                            onChange={handleInputChange}
                            name="parent1_cell"
                          />

                          {parent1FormErrors.parent1_cell && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent1FormErrors.parent1_cell}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="form__group form__group--flex">
                        <div className="form__group__col">
                          <label htmlFor="">Apartment </label>
                          <input
                            className="form__input"
                            id="Apartment"
                            type="text"
                            placeholder="Apartment"
                            value={parent1Form.parent1_apartment || ""}
                            name="parent1_apartment"
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="form__group__col">
                          <label htmlFor="">
                            City <span className="important_field">*</span>
                          </label>
                          <input
                            className="form__input"
                            id="City"
                            type="text"
                            placeholder="City *"
                            value={parent1Form.parent1_city || ""}
                            onChange={handleInputChange}
                            name="parent1_city"
                          />
                          {parent1FormErrors.parent1_city && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent1FormErrors.parent1_city}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form__group form__group--flex">
                        <div className="form__group__col">
                          <label htmlFor="">
                            State <span className="important_field">*</span>
                          </label>
                          <select
                            className="form__select"
                            name="parent1_state"
                            value={parent1Form.parent1_state || ""}
                            onChange={handleInputChange}
                          >
                            <option value="" disabled>
                              Select States
                            </option>
                            {getStateData?.map((val) => (
                              <option key={val?.name} value={val?.name}>
                                {val?.name}
                              </option>
                            ))}
                          </select>

                          {parent1FormErrors.parent1_state && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent1FormErrors.parent1_state}
                            </p>
                          )}
                        </div>
                        <div className="form__group__col">
                          <label htmlFor="">
                            Zip <span className="important_field">*</span>
                          </label>
                          <input
                            className="form__input"
                            id="Zip"
                            name="parent1_zip"
                            type="number"
                            maxLength={5}
                            placeholder="Zip *"
                            value={parent1Form.parent1_zip || ""}
                            onChange={handleInputChange}
                          />
                          {parent1FormErrors.parent1_zip && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent1FormErrors.parent1_zip}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form__group form__group--right">
                        <button className="btn btn--primary" type="submit">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                {/* PARENT --2  */}
                <form
                  className="form parentForm2"
                  name="parentForm2"
                  onSubmit={ParentHandler2}
                >
                  <div className="form__card">
                    <h2 className="title title--h2">
                      *Please use "N/A" for non applicable fields.*
                    </h2>
                    <div className="form__row">
                      {/* BUTTONS AND FILE UPLOAD  */}
                      <div className="profileBox">
                        <div className="profileBox__info">
                          <div className="pic">
                            <img
                              className="pic__img"
                              src={parent2Form.parent2_image}
                              alt=""
                            />
                          </div>
                          <div className="detail">
                            <h3 className="name">
                              {parent2Form?.parent2_first_name} &nbsp;
                              {parent2Form?.parent2_last_name}
                            </h3>
                          </div>
                        </div>

                        <div className="profileBox__actions">
                          <div
                            className="profileBox__actions__item"
                            style={{ display: "flex" }}
                          >
                            <input
                              className="form__input form__input--hidden"
                              type="file"
                              id="fileupload1"
                              name="parent2_image"
                              onChange={handleFileChange}
                            />
                            <label
                              htmlFor="fileupload1"
                              className="btn btn--primary"
                            >
                              Upload New Photo
                            </label>
                          </div>
                        </div>
                      </div>

                      {/* PARENT -2 , FIRST NAME AND LAST NAME  */}

                      <div className="form__group form__group--flex">
                        {/* Parent1__firstName */}
                        <div className="form__group__col">
                          <label htmlFor="">
                            First Name{" "}
                            <span className="important_field">*</span>
                          </label>
                          <input
                            className="form__input"
                            type="text"
                            name="parent2_first_name"
                            placeholder="First Name"
                            value={parent2Form.parent2_first_name}
                            onChange={handleInputChange2}
                          />
                          {parent2FormErrors.parent2_first_name && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent2FormErrors.parent2_first_name}
                            </p>
                          )}
                        </div>

                        {/* Parent_2__firstName */}

                        <div className="form__group__col">
                          <label htmlFor="">
                            Last Name <span className="important_field">*</span>
                          </label>
                          <input
                            className="form__input"
                            type="text"
                            name="parent2_last_name"
                            placeholder="Last Name *"
                            value={parent2Form.parent2_last_name}
                            onChange={handleInputChange2}
                          />
                          {parent2FormErrors.parent2_last_name && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent2FormErrors.parent2_last_name}
                            </p>
                          )}
                        </div>
                      </div>

                      {/* PARENT -1 , ADDRESS AND CELL PHONE  */}
                      <div className="form__group form__group--flex">
                        <div className="" style={{ width: "100%" }}>
                          <label htmlFor="">
                            Address <span className="important_field">*</span>
                          </label>
                          <input
                            className="form__input"
                            type="text"
                            placeholder="Address *"
                            name="parent2_address"
                            value={parent2Form.parent2_address}
                            onChange={handleInputChange2}
                          />
                          {parent2FormErrors.parent2_address && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent2FormErrors.parent2_address}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="form__group form__group--flex">
                        <div className="form__group__col">
                          <label htmlFor="">
                            Home Phone{" "}
                            <span className="important_field">*</span>
                          </label>
                          <input
                            className="form__input"
                            id="HomePhone"
                            type="text"
                            maxLength={14}
                            name="parent2_alt_cell"
                            placeholder="Home Phone"
                            value={parent2Form.parent2_alt_cell}
                            onChange={handleInputChange2}
                          />
                          {parent2FormErrors.parent2_alt_cell && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent2FormErrors.parent2_alt_cell}
                            </p>
                          )}
                        </div>
                        <div className="form__group__col">
                          <label htmlFor="">
                            Cell <span className="important_field">*</span>
                          </label>
                          <input
                            className="form__input"
                            id="CellPhone"
                            type="text"
                            maxLength={14}
                            placeholder="Cell phone"
                            name="parent2_cell"
                            value={parent2Form.parent2_cell}
                            onChange={handleInputChange2}
                          />
                          {parent2FormErrors.parent2_cell && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent2FormErrors.parent2_cell}
                            </p>
                          )}
                        </div>
                      </div>

                      {/* APPARTMENT */}

                      <div className="form__group form__group--flex">
                        <div className="form__group__col">
                          <label htmlFor="">Appartment </label>
                          <input
                            className="form__input"
                            id="Apartment"
                            type="text"
                            placeholder="Apartment"
                            name="parent2_apartment"
                            value={parent2Form.parent2_apartment}
                            onChange={handleInputChange2}
                          />
                          {parent2FormErrors.parent2_apartment && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent2FormErrors.parent2_apartment}
                            </p>
                          )}
                        </div>

                        <div className="form__group__col">
                          <label htmlFor="">
                            City <span className="important_field">*</span>
                          </label>
                          <input
                            className="form__input"
                            id="City"
                            type="text"
                            placeholder="City *"
                            name="parent2_city"
                            value={parent2Form.parent2_city}
                            onChange={handleInputChange2}
                          />
                          {parent2FormErrors.parent2_city && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent2FormErrors.parent2_city}
                            </p>
                          )}
                        </div>
                      </div>

                      {/* STATE ZIP  */}
                      <div className="form__group form__group--flex">
                        <div className="form__group__col">
                          <label htmlFor="">
                            State <span className="important_field">*</span>
                          </label>
                          <select
                            className="form__select"
                            name="parent2_state"
                            value={parent2Form.parent2_state || ""}
                            onChange={handleInputChange}
                          >
                            <option value={""} disabled>
                              Select States
                            </option>
                            {getStateData?.map((val, index) => {
                              return (
                                <option value={val?.name} key={index}>
                                  {val?.name}
                                </option>
                              );
                            })}
                          </select>
                          {parent2FormErrors.parent2_state && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent2FormErrors.parent2_state}
                            </p>
                          )}
                        </div>

                        <div className="form__group__col">
                          <label htmlFor="">
                            Zip <span className="important_field">*</span>
                          </label>
                          <input
                            className="form__input"
                            type="number"
                            id="Zip"
                            name="parent2_zip"
                            placeholder="zip"
                            value={parent2Form.parent2_zip}
                            onChange={handleInputChange2}
                          />
                          {parent2FormErrors.parent2_zip && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {parent2FormErrors.parent2_zip}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="form__group form__group--right">
                        <button className="btn btn--primary" type="submit">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div
                className={`tab-content ${
                  activeTab === "tab2" ? "current" : ""
                }`}
              >
                <StudentTabContent
                  activeTab={activeTab}
                  setSuccessModal={setSuccessModal}
                />
              </div>

              <div
                className={`tab-content ${
                  activeTab === "tab3" ? "current" : ""
                }`}
              >
                <EnrollmentTabContent activeTab={activeTab} />
              </div>
            </div>
          </div>
        </section>
      </div>
      {successModal ? <SuccesModal setSuccessModal={setSuccessModal} /> : null}
    </>
  );
}

export default ParentProfile;
