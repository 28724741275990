import React, { useEffect, useState } from "react";
import { customStyles } from "../../adminUtility";
import getPrograms from "../../adminApi/categories";
import Select from "react-select";
import { toast } from "react-toastify";
import { getcategory, getclass } from "../../adminApi/clients";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/deleteRowIcon.svg";
import { addCharges } from "../../adminApi/Ledger";
function getCurrentDate() {
  const currentDate = new Date();

  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const year = currentDate.getFullYear();

  return `${month}/${day}/${year}`;
}

function BirthdayAddOns({
  openAddButton,
  setOpenAddButton,
  ledgerID,
  fetchData,
}) {
  const [formData, setFormData] = useState({});
  const [isBirthDay, setIsBirthday] = useState(false);
  const [getAllCategoryData, setCategoryData] = useState();
  const [loading, setLoading] = useState(false);
  const [categoryID, setCategoryID] = useState();
  const [allClasses, setAllClasses] = useState([]);
  const [sortKey, setSortKey] = useState({
    sortId: "1",
    isAsc: true,
  });
  const [totolAmount, setTotalAmount] = useState([]);
  const [addRows, setAddRows] = useState([
    {
      category: "",
      date: getCurrentDate(),
      memo: "",
      amount: "",
      discount: "",
      totalAmount: "",
      quantity: "",
    },
  ]);
  // Handling Add or Delete Rows
  const handleAddRows = (index) => {
    const newRows = {
      category: "",
      memo: "",
      amount: "",
      date: getCurrentDate(),
      discount: 0,
      totalAmount: "",
    };
    let arr = [];
    for (let i = 0; i < addRows.length + 1; i++) {
      arr.push([]);
    }
    setAllClasses(arr);
    setAddRows([...addRows, newRows]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = addRows.filter((_, i) => i !== index);
    setAddRows(updatedRows);
  };

  // GET ALL CATEGORIES
  const getAllCategories = async () => {
    const token = localStorage.getItem("access_token");
    const response = await getPrograms(token, formData, 0, sortKey, "300000");
    setCategoryData(response?.data);
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const handleSubmit = async () => {
    const emptyFields = addRows.map((row) => {
      const fields = Object.entries(row)
        .filter(([key, value]) => value === "" || value === undefined)
        .map(([key]) => key);

      return fields.filter(
        (field) => field !== "totalAmount" && field !== "discount"
      );
    });

    const flattenedEmptyFields = emptyFields.flat();

    if (flattenedEmptyFields.length > 0) {
      const nonDiscountEmptyFields = flattenedEmptyFields.filter(
        (field) => field !== "discount"
      );
      if (nonDiscountEmptyFields.length > 0) {
        if (nonDiscountEmptyFields.length === 1) {
          toast.error(
            `Enter the following field: ${nonDiscountEmptyFields[0]}`
          );
          return;
        } else {
          toast.error(
            `Enter the following fields: ${nonDiscountEmptyFields.join(", ")}`
          );
          return;
        }
      }
    }

    const rowsWithTotalAmount = addRows.map((row, index) => {
      return {
        ...row,
        totalAmount: parseInt(totolAmount[index]),
      };
    });

    setLoading(true);
    try {
      const token = localStorage.getItem("access_token");
      const response = await addCharges(token, ledgerID, rowsWithTotalAmount);
      if (response?.data) {
        setLoading(false);
        setOpenAddButton(false);
        toast.success("Adhoc Charges Added Successfully.");
        fetchData();
      } else {
        toast.error(response?.error);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    }
  };

  async function fetchCategory() {
    try {
      const token = localStorage.getItem("access_token");
      const response = await getcategory(categoryID, token);

      setCategoryData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const classDataFromCategory = async (id, index) => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await getclass(id, token);
      const updatedClasses = [...allClasses];
      updatedClasses[index] = response;
      setAllClasses(updatedClasses);
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };

  const handleChargeDetails = (e, index) => {
    const { name, value } = e.target;
    const copydata = [...addRows];
    const requiredFields = ["class", "category"];

    if (
      requiredFields.includes(name) &&
      typeof value === "string" &&
      value.trim() === ""
    ) {
      toast.error(`${name} cannot be empty`);
      return;
    }

    if (name === "category") {
      const selectedCategoryID = parseInt(value);
      copydata[index][name] = selectedCategoryID;
      setCategoryID(selectedCategoryID);
      if (selectedCategoryID !== "") {
        classDataFromCategory(selectedCategoryID, index);
      }
    }

    if (
      name === "class" ||
      name === "amount" ||
      // name === "discount" ||
      name === "totalAmount" ||
      name === "category"
    ) {
      // Ensure the value is not empty and convert it to a number
      copydata[index][name] = value === "" ? "" : parseInt(value);
    } else {
      copydata[index][name] = value;
    }

    setAddRows(copydata);
  };

  // CHARGET DETAILS
  useEffect(() => {
    const updatedTotalAmounts = addRows.map((row) => {
      const total = (row.amount || 0) - (row.discount || 0);
      return total.toFixed(2);
    });
    setTotalAmount(updatedTotalAmounts);

    addRows?.map((val, index) => {
      if (val?.category) {
        classDataFromCategory(val.category, index);
        fetchCategory();
      }
    });
  }, [addRows, categoryID]);

  // Formatted Date
  function getCurrentDate() {
    const currentDate = new Date();

    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();

    return `${month}/${day}/${year}`;
  }
  const rowsWithTotalAmount = addRows.map((row, index) => {
    return {
      ...row,
      totalAmount: parseFloat(totolAmount[index]),
    };
  });

  return (
    <>
      <div className="Table">
        <div className="Heading">
          <div className="Cell" style={{ color: "white", maxWidth: "50px" }}>
            <p>Charge Date</p>
          </div>
          <div className="Cell" style={{ color: "white", maxWidth: "100px" }}>
            <p>Category</p>
          </div>
          <div className="Cell" style={{ color: "white", maxWidth: "200px" }}>
            <p>Memo</p>
          </div>
          <div className="Cell" style={{ color: "white", maxWidth: "200px" }}>
            {" "}
            <p> Quantity($)</p>
          </div>
          <div className="Cell" style={{ color: "white", maxWidth: "200px" }}>
            {" "}
            <p> Charge($)</p>
          </div>
          <div className="Cell" style={{ color: "white" }}>
            {" "}
            <p>Discount($) </p>
          </div>
          <div className="Cell" style={{ color: "white" }}>
            <p>Total($)</p>
          </div>
          <div className="Cell" style={{ width: "40px" }}>
            <p></p>
          </div>
        </div>

        {addRows.map((row, index) => (
          <div className="Row" key={index}>
          
            <div className="Cell Cell_input" style={{ width: "60px" }}>
              <input
                type="text"
                className="adminform__input"
                placeholder="Enter Date"
                value={row.date || ""}
              />
            </div>
            {/* CATEGORY  */}
            <div className="Cell Cell_input" style={{ width: "200px" }}>
              <Select
                name="category"
                className=""
                styles={customStyles}
                onChange={(selectedOption) => {
                  handleChargeDetails(
                    {
                      target: {
                        name: "category",
                        value: selectedOption.value,
                      },
                    },
                    index
                  );
                }}
                // options={getAllCategoryData?.map((val) => ({
                //   value: val.id,
                //   label: val.name,
                // }))}
                options={[
                  { label: "Select Category", value: null },
                  ...(Array.isArray(getAllCategoryData) &&
                  getAllCategoryData.length > 0
                    ? getAllCategoryData
                        .filter((val) => val?.name.trim() !== "")
                        .map((category) => ({
                          label: category.name,
                          value: category.id,
                        }))
                        .filter((option) => option.value !== "")
                    : []),
                ]}
              />
            </div>
            {/* MEMO  */}
            <div className="Cell " style={{ width: "250px" }}>
              <input
                type="text"
                name="memo"
                className="adminform__input"
                placeholder="Memos Goes here"
                value={row.memo || ""}
                onChange={(e) => {
                  handleChargeDetails(e, index);
                }}
                style={{ width: "100%", minWidth: "155px" }}
              />
            </div>
            {/* QUANTITY  */}
            <div className="Cell" style={{ width: "250px" }}>
              <input
                type="number"
                name="quantity"
                placeholder="Quantity"
                className="adminform__input"
                value={row.quantity || ""}
                onChange={(e) => {
                  handleChargeDetails(e, index);
                }}
                style={{ width: "100%", minWidth: "100px" }}
              />
            </div>
            <div className="Cell Cell_input">
              <input
                type="number"
                name="amount"
                className="adminform__input"
                placeholder="$ 00.00"
                pattern="[0-9]*"
                value={row.amount || ""}
                onChange={(e) => {
                  handleChargeDetails(e, index);
                }}
                style={{ width: "100%", minWidth: "25px" }}
              />
            </div>

            <div className="Cell Cell_input">
              <input
                type="number"
                name="discount"
                className="adminform__input"
                style={{ width: "100%", minWidth: "25px" }}
                placeholder="discount"
                value={row.discount || ""}
                onChange={(e) => {
                  handleChargeDetails(e, index);
                }}
              />
            </div>
            <div className="Cell Cell_input">
              <input
                type="number"
                disabled
                value={totolAmount[index]}
                className="adminform__input"
                style={{ width: "100%", minWidth: "5px" }}
              />
            </div>
            <div
              className="Cell "
              onClick={() => handleDeleteRow(index)}
              style={{
                cursor: "pointer",
                padding: "15px",
                verticalAlign: "middle",
                width: "20px",
              }}
            >
              <DeleteIcon />
            </div>
          </div>
        ))}

        <button
          className="adminbtn adminbtn-blueOutline"
          onClick={handleAddRows}
          style={{ margin: "10px 0" }}
        >
          + Add More
        </button>
      </div>
    </>
  );
}

export default BirthdayAddOns;
