import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as UserIcon } from "../parent-assets/images/parent-icon.svg";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as GymTimeLogo } from "../parent-assets/images/gymtime_logo_2024.svg";
import LoginModal from "./User/LoginModal";
import SignupModal from "./User/SignupModal";
import ForgotPassword from "./User/ForgotPassword";
import dropDownIcon from "../parent-assets/images/drop-down-icon.png";
import SuccesModal from "./User/SuccesModal";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../reducers/loadingSlice";
import { setSuccessModalContent, setUserRole } from "../../reducers/userSlice";
import { toast } from "react-toastify";
import { getSubPrograms } from "../../Admin/adminApi/subProgram";
import { getCmsAge } from "../../Admin/adminApi/program";
import { logoutApi } from "../../Admin/adminApi/users";
import { getCampProgramApi } from "../api/classApi";

function Header() {
  const [subProgramData, setShowProgramData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Parent");
  const [modal, setModal] = useState({ key: "login", flag: false });
  const [activeSidebar, setActiveSidebar] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [programAge, setProgramAge] = useState([]);
  // const student
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [submenuDisplay, setSubmenuDisplay] = useState("none");
  const [submenuDisplay2, setSubmenuDisplay2] = useState("none");
  const [submenuDisplay3, setSubmenuDisplay3] = useState("none");
  const [submenuDisplay4, setSubmenuDisplay4] = useState("none");
  const token = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [programMenuOpen, setProgramMenu] = useState(false);
  const [scheduleMenu, setScheduleMenu] = useState(false);
  const [events, setEvents] = useState(false);
  const [ageRange, setAgeRange] = useState();
  const USER_ROLE = useSelector((state) => state.userRole.userRole);
  const isSessionLogout = useSelector(
    (state) => state?.sessionSlice?.isLoginModalOpen
  );
  const [closeMenu, setCloseMenu] = useState({
    isChange: false,
    status: "",
  });

  const location = useLocation();
  const logoutHandle = async () => {
    dispatch(setSuccessModalContent("Logout Succesfully."));
    localStorage.clear();

    try {
      const response = await logoutApi(token);
      if (response.status == 200) {
        setLoading(false);
        setSuccessModal(true);
        navigate("/");
        setSelectedOption("Parent");
        localStorage.clear();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    }
  };

  useEffect(() => {
    if (activeSidebar) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }

    return () => {
      document.body.classList.remove("menu-open");
    };
  }, [activeSidebar]);

  useEffect(() => {
    getSubProgramFunction();
  }, []);

  const getSubProgramFunction = async () => {
    const response = await getSubPrograms(token);
    setShowProgramData(response);
    const getCmsBrowseAge = await getCmsAge(token);
    setProgramAge(getCmsBrowseAge);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSubMenu = () => {
    if (window.innerWidth <= 1165) {
      setSubMenuOpen(!subMenuOpen);
      setSubmenuDisplay(subMenuOpen ? "none" : "flex");
    }
  };

  const toggleProgram = () => {
    if (window.innerWidth <= 1165) {
      setProgramMenu(!programMenuOpen);

      setSubmenuDisplay2(programMenuOpen ? "none" : "flex");
    }
  };

  const toggleSchedule = () => {
    if (window.innerWidth <= 1165) {
      setScheduleMenu(!scheduleMenu);
      setSubmenuDisplay3(scheduleMenu ? "none" : "flex");
    }
  };

  const toggleEvents = () => {
    if (window.innerWidth <= 1165) {
      setEvents(!events);
      setSubmenuDisplay4(events ? "none" : "flex");
    }
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropDown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // R- Closing the Header when i click on outside.
  useEffect(() => {
    const handleOutsideClick = (e) => {
      const navbar = document.querySelector(".navbar");
      if (!navbar.contains(e.target)) {
        setSubmenuDisplay4("none");
        setSubmenuDisplay3("none");
        setSubmenuDisplay2("none");
        setSubmenuDisplay("none");
        setActiveSidebar(false);
        document.body.classList.remove("menu-open");
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleLinkClick = (age_range) => {
    if (age_range !== null || age_range !== undefined) {
      if (age_range === "0-12") {
        setAgeRange("0-1");
      } else if (age_range === "12-24") {
        setAgeRange("1-2");
      } else {
        setAgeRange(age_range);
      }
    }
    setActiveSidebar(false);
    document.body.classList.remove("menu-open");
    if (!token) {
    } else {
      navigate("/parent", { state: { tab: "tab1" } });
    }
  };

  const campProgramApi = async () => {
    try {
      const response = await getCampProgramApi("Camp");
      if (response && response.data) {
        navigate("/camp/175", {
          state: {
            data: response?.data,
          },
        });
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (selectedOption == "Parent") {
      dispatch(setUserRole("Parent"));
    } else {
      dispatch(setUserRole("Admin"));
    }
  }, [selectedOption]);

  // Closing the All Menu Isocn Whenerver te link is selected or Navigtwd
  useEffect(() => {
    if (location?.pathname !== "/") {
      setCloseMenu({
        isChange: true,
        status: "none",
      });

      setTimeout(() => {
        setCloseMenu({
          isChange: false,
          status: "",
        });
      }, 100);
    } else {
      setCloseMenu({
        isChange: false,
        status: "",
      });
    }
  }, [location]);

  const navbarMenu = () => (
    <>
      <div className="navbar__menu">
        <ul className="navbar__nav nav">
          <li className="nav__item ">
            <Link
              to="/about"
              className="nav__link"
              style={{ background: "none" }}
              onClick={toggleSubMenu}
            >
              About <span className="toggleSubmenu"></span>
            </Link>
            <ul
              className={`submenu ${subMenuOpen ? "show-submenu" : ""}`}
              style={{
                display:
                  window.innerWidth <= 1165
                    ? submenuDisplay
                    : closeMenu?.isChange
                    ? closeMenu?.status
                    : "",
              }}
            >
              <li className="submenu__item">
                <Link
                  to="/about"
                  className="submenu__item__link"
                  onClick={() => {
                    handleLinkClick();
                  }}
                >
                  About Us
                </Link>
              </li>

              <li className="submenu__item">
                <Link
                  to="/staff"
                  className="submenu__item__link"
                  onClick={() => {
                    handleLinkClick();
                  }}
                >
                  The Staff
                </Link>
              </li>
              <li className="submenu__item">
                <Link
                  to="/facilities"
                  className="submenu__item__link"
                  onClick={() => {
                    handleLinkClick();
                  }}
                >
                  Facilities
                </Link>
              </li>
              <li className="submenu__item">
                <Link
                  to="/contact"
                  className="submenu__item__link"
                  onClick={() => {
                    handleLinkClick();
                  }}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav__item">
            <Link
              to="/browse-by-program-options"
              className="nav__link"
              style={{ background: "none" }}
              onClick={toggleProgram}
            >
              Programs <span className="toggleSubmenu"></span>
            </Link>
            <div
              className="submenu submenu--program"
              style={{
                display:
                  window.innerWidth <= 1165
                    ? submenuDisplay2
                    : closeMenu?.isChange
                    ? closeMenu?.status
                    : "",
              }}
            >
              <div
                className="flexbox"
                style={{
                  maxHeight: windowWidth <= 1165 ? "" : "80vh",
                  overflowY: windowWidth <= 1165 ? "hidden" : "auto",
                }}
              >
                <div className="item">
                  <h3 className="title">
                    <Link
                      to="/browse-by-program-options"
                      style={{ textDecoration: "none", color: "#EA519C" }}
                      onClick={() => {
                        handleLinkClick();
                      }}
                    >
                      Browse By Programs
                    </Link>
                  </h3>
                  <ul
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {subProgramData?.map((item, index) => {
                      if (!item?.is_active) {
                        return null;
                      }

                      return (
                        <li className="submenu__item" key={index}>
                          {item?.name === "Cheer" ? (
                            <Link
                              to={`/browse-by-programs/${item?.name}/169?browse-by=true`}
                              className="submenu__item__link"
                              onClick={() => {
                                handleLinkClick();
                              }}
                            >
                              {item?.name}
                            </Link>
                          ) : item?.name === "Enrichment Combos" ? (
                            <Link
                              to={`/enrichment/${item?.id}`}
                              className="submenu__item__link"
                              onClick={() => {
                                handleLinkClick();
                              }}
                            >
                              {item?.name}
                            </Link>
                          ) : item?.name === "Teams" ? (
                            <Link
                              to={`/cheer/team-gymnastic/${item?.id}`}
                              className="submenu__item__link"
                              onClick={() => {
                                handleLinkClick();
                              }}
                            >
                              {item?.name}
                            </Link>
                          ) : item?.name === "Camp" ? (
                            <Link
                              to="/camp/175"
                              className="submenu__item__link"
                              onClick={() => {
                                handleLinkClick();
                                campProgramApi();
                              }}
                            >
                              {item?.name}
                            </Link>
                          ) : (
                            <Link
                              to={`/browse-by-programs/${item?.name}/${item?.id}?browse-by=true`}
                              className="submenu__item__link"
                              onClick={() => {
                                handleLinkClick();
                              }}
                            >
                              {item?.name}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="item">
                  <h3 className="title">
                    <Link
                      to="/browse-by-age-options"
                      style={{ textDecoration: "none", color: "#EA519C" }}
                      onClick={() => {
                        handleLinkClick();
                      }}
                    >
                      Browse By Age
                    </Link>
                  </h3>
                  <ul>
                    {programAge?.map((item, index) => {
                      if (item?.is_active) {
                        return (
                          <li className="submenu__item" key={item?.id}>
                            <Link
                              to={`/browse-by-programs/${item.age_range}/${item?.id}?browse-by=false`}
                              className="submenu__item__link"
                              onClick={() => {
                                handleLinkClick(item.age_range);
                              }}
                            >
                              {item.age_range === "0-12" ||
                              item.age_range === "12-24"
                                ? `${item?.age_range} Months`
                                : `${item?.age_range} Year Olds`}
                            </Link>
                          </li>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </li>

          <li className="nav__item">
            <Link
              to="/schedule/calender"
              className="nav__link"
              style={{ background: "none" }}
              onClick={toggleSchedule}
            >
              Schedule <span className="toggleSubmenu"></span>
            </Link>
            <ul
              className="submenu"
              style={{
                display:
                  window.innerWidth <= 1165
                    ? submenuDisplay3
                    : closeMenu?.isChange
                    ? closeMenu?.status
                    : "",
              }}
            >
              <li className="submenu__item">
                <Link
                  to="/schedule/calender"
                  className="submenu__item__link"
                  onClick={() => {
                    handleLinkClick();
                  }}
                >
                  Calendar
                </Link>
              </li>
              <li className="submenu__item">
                <Link
                  to="/schedule/policies"
                  className="submenu__item__link"
                  onClick={() => {
                    handleLinkClick();
                  }}
                >
                  Policies
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav__item">
            <Link
              to="/party"
              className="nav__link"
              style={{ background: "none" }}
              onClick={toggleEvents}
            >
              Birthdays & Events <span className="toggleSubmenu"></span>
            </Link>
            <ul
              className="submenu"
              style={{
                display:
                  window.innerWidth <= 1165
                    ? submenuDisplay4
                    : closeMenu?.isChange
                    ? closeMenu?.status
                    : "",
              }}
            >
              <li className="submenu__item">
                <Link
                  to="/party"
                  className="submenu__item__link"
                  onClick={() => {
                    handleLinkClick();
                  }}
                >
                  Party Options
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav__item">
            <Link
              target="blank"
              to="https://www.yorkavenuedayschool.org/"
              className="nav__link"
              style={{ background: "none" }}
              onClick={() => {
                handleLinkClick();
              }}
            >
              Preschool
            </Link>
          </li>
          <li className="nav__item">
            <Link
              to={token ? "/cart" : "/"}
              className="nav__link"
              style={{ background: "none" }}
              onClick={(e) => {
                if (!token) {
                  e.preventDefault();
                  toast.error("Please Login to Proceed!", {
                    toastId: "error",
                  });
                  setActiveSidebar(false);
                  document.body.classList.remove("menu-open");
                }
                setActiveSidebar(false);
                document.body.classList.remove("menu-open");
              }}
            >
              Cart
            </Link>
          </li>
          <li className="nav__item" style={{ backgroundColor: "transparent" }}>
            <Link
              to="/blog"
              className="nav__link"
              style={{ backgroundColor: "inherit" }}
              onClick={() => {
                handleLinkClick();
              }}
            >
              Blog
            </Link>
          </li>

          {/* PARENT BUTTON  */}

          {windowWidth >= 639 ? null : (
            <li
              className={`nav__item nav__item--language ${
                windowWidth >= 639 ? "" : "nav__item--language--hideDeskTopView"
              } ${openDropDown ? "open" : ""}`}
            >
              <Link
                className="languagetoggle"
                to={token ? "/parent" : null}
                onClick={() => {
                  handleLinkClick();
                }}
              >
                <span className="iconbox">
                  <UserIcon />
                </span>
                Parent
              </Link>
            </li>
          )}
        </ul>
      </div>

      <div className="navbar__menu">
        <ul className="navbar__nav navLogin">
          <li
            className={`nav__item nav__item--language ${
              openDropDown ? "open" : ""
            } `}
            onClick={() => {
              if (token && token !== "undefined") {
              } else {
                setOpenDropDown(!openDropDown);
              }
            }}
            ref={dropdownRef}
          >
            <Link
              className="languagetoggle"
              to={token ? (token !== "undefined" ? "/parent" : "/") : "/"}
            >
              <span className="iconbox">
                <UserIcon />
              </span>
              {selectedOption}

              {token ? (
                token !== "undefined" ? null : (
                  <span className="iconbox iconbox--arrow">
                    <img src={dropDownIcon} alt="" />
                  </span>
                )
              ) : (
                <span className="iconbox iconbox--arrow">
                  <img src={dropDownIcon} alt="" />
                </span>
              )}
            </Link>

            <ul className="languagedropdown">
              <li className="languagedropdown__item">
                <Link
                  to={token ? (token !== "undefined" ? "/parent" : "/") : "/"}
                  className="submenu__item__link"
                  onClick={() => {
                    setSelectedOption("Parent");
                    dispatch(setUserRole("Parent"));
                  }}
                >
                  Parent
                </Link>
              </li>
              <li className="languagedropdown__item">
                <Link
                  to="/"
                  className="submenu__item__link"
                  onClick={() => {
                    setSelectedOption("Admin");
                    dispatch(setUserRole("Admin"));
                    setModal({ key: "login", flag: true });
                  }}
                >
                  Admin
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav__item">
            {token && token !== "undefined" ? (
              <>
                <Link
                  className="nav__link nav__link--login"
                  onClick={logoutHandle}
                >
                  Logout
                </Link>
              </>
            ) : (
              <>
                <Link
                  onClick={() => {
                    setModal({ key: "login", flag: true });
                  }}
                  className="nav__link nav__link--login"
                >
                  Login/Register
                </Link>
              </>
            )}
          </li>
          <li className="nav__item nav__item--sideBarMenu" id="sideBarMenu">
            <button
              className="menubtn"
              onClick={() => {
                setActiveSidebar(!activeSidebar);
              }}
            >
              <span className="menubtn__line"></span>
            </button>
          </li>
        </ul>
      </div>
    </>
  );

  return (
    <>
      <header className="siteheader">
        <div className="navbar">
          <div className="navbar__branding">
            <Link to="/">
              <GymTimeLogo />
            </Link>
          </div>

          {/* NAVBAR MENU  */}
          {navbarMenu()}
        </div>
      </header>

      {modal.flag && modal.key === "login" && (
        <div id="modal-container">
          <LoginModal
            setSuccessModal={setSuccessModal}
            modal={modal}
            setModal={setModal}
          />
        </div>
      )}

      {isSessionLogout && (
        <div id="modal-container">
          <LoginModal
            setSuccessModal={setSuccessModal}
            modal={modal}
            setModal={setModal}
          />
        </div>
      )}

      {modal.flag && modal.key === "signup" && (
        <div id="modal-container">
          <SignupModal
            modal={modal}
            setModal={setModal}
            setSuccessModal={setSuccessModal}
          />
        </div>
      )}

      {modal.flag && modal.key === "forgotPassword" && (
        <div id="modal-container">
          <ForgotPassword
            modal={modal}
            setModal={setModal}
            setSuccessModal={setSuccessModal}
          />
        </div>
      )}

      {successModal ? <SuccesModal setSuccessModal={setSuccessModal} /> : null}
    </>
  );
}

export default Header;
