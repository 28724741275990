import React from "react";
import Header from "../components/Header";
import NavigationComponent from "../components/NavigationComponent";
import TableNew from "../components/TableComponent/TableNew";
import { useState } from "react";
import { getOrdersAPI, markAsReadAPI } from "../adminApi/OrdersAPI";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import EnableRecurring from "../components/Orders/EnableRecurring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../components/UserModals/DeleteModal";
import axios from "axios";
import SendMailModal from "../components/SendMailModal";
import ClockLoader from "react-spinners/ClockLoader";
import { toast } from "react-toastify";
import {
  customStyles,
  dateFormatterWithMonthName,
  timeFormatter,
} from "../adminUtility";
import OrderLogs from "../components/OrderLogs";
import { getOrderLogs } from "../adminApi/Ledger";
import VoidOrderPayment from "../components/Orders/VoidOrderPayment";
import warning from "../assets/images/icons/warning.png";
import { getInvoice } from "../../Parent/api/parent-profile";
import LoaderComponent from "../components/LoaderComponent";
import downloadIcon from "../assets/images/icons/donwload-icon.svg";
import alertIcon from "../assets/images/icons/alert.svg";
import { getClassForDropdown } from "../adminApi/addClassList.js";
import { ReactComponent as SearchIcon } from "../assets/images/icons/search.svg";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import DownloadOrderPdf from "../components/DownloadOrderPdf.jsx";

// Arrow Icon;
const arrowIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.4615 3.7712C7.72183 3.51085 7.72183 3.08874 7.4615 2.82839L5.57588 0.942771C5.53707 0.903964 5.49647 0.868044 5.45433 0.835024C5.00181 0.480349 4.37288 0.459313 3.89943 0.771917C3.82549 0.820731 3.75535 0.877684 3.69026 0.942771L1.80464 2.82839C1.54429 3.08874 1.54429 3.51085 1.80464 3.7712C2.06499 4.03155 2.4871 4.03155 2.74745 3.7712L3.99992 2.51872V12C3.99992 12.3682 4.2984 12.6666 4.66659 12.6666C5.03478 12.6666 5.33325 12.3682 5.33325 12V2.58576L6.51869 3.7712C6.77903 4.03154 7.20117 4.03154 7.4615 3.7712Z"
      fill="white"
    />
    <path
      d="M8.47064 12.2287C8.21031 12.4891 8.21031 12.9111 8.47064 13.1715L10.3562 15.0571C10.4214 15.1222 10.4915 15.1792 10.5654 15.228C11.083 15.5697 11.7862 15.5127 12.2419 15.0571L14.1275 13.1715C14.3878 12.9111 14.3878 12.4891 14.1275 12.2287C13.8672 11.9683 13.445 11.9683 13.1847 12.2287L11.9992 13.4141V3.99992C11.9992 3.63173 11.7008 3.33325 11.3326 3.33325C10.9644 3.33325 10.6659 3.63173 10.6659 3.99992V13.4811L9.41344 12.2287C9.15311 11.9683 8.73097 11.9683 8.47064 12.2287Z"
      fill="white"
    />
  </svg>
);

const Orders = () => {
  const [orders, setOrders] = useState();
  const [showMailLoader, setShowMailLoader] = useState(
    Array.from({ length: 5 }, () => false)
  );
  const [downloadPdf, setDownloadPdf] = useState(false);
  const [openVoidOrderModal, setOpenVoidOrderModal] = useState(false);
  const [toggleState, setToggleState] = useState(true);
  const [openDeleteCardFile, setOpenDeleteCardFile] = useState(false);
  const [deleteCardID, setDeleteCardID] = useState();
  const [selectedState, setSelectedState] = useState();
  const [orderID, setOrderID] = useState();
  const [orderState, setOrderState] = useState(true);
  const [recurringDuration, setRecurrinDuration] = useState();
  const [categoryForMail, setCategoryForMail] = useState();
  const [formData, setFormData] = useState([]);
  const [orderType, setOrderType] = useState("");
  const [temp, setTemp] = useState(1);
  const [EnablePaymentModal, setEnablePaymentModal] = useState(false);
  const [currentPage, setCurrentPage] = useState();
  const [totalCount, setTotalCount] = useState();
  const [totalPages, setTotalPages] = useState();
  const [allClassApiData, setAllClassApiData] = useState();
  const [sendMailData, setSendMailData] = useState([]);
  const [showMailModal, setShowMailModal] = useState(false);
  const [loaderIndex, setLoaderIndex] = useState();
  const [openLogs, setOpenLogs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderLogData, setOrderLogData] = useState();
  const [mailOrderId, setMailOrderId] = useState();
  const [deleteCardOnFile, setDeleteCardOnFile] = useState({
    isDeleted: false,
    deleteArray: [],
  });
  // TABLE HEADER DATA
  const tableHeader1 = [
    {
      name: "Date",
      icon: arrowIcon,
    },
    {
      name: "Billing Info",
      icon: arrowIcon,
    },
    {
      name: "Parent Details",
      icon: arrowIcon,
    },
    {
      name: "Student Name",
      icon: arrowIcon,
    },
    {
      name: "Classes",
    },
    {
      name: " Recurring",
    },
    { name: "Recurring Payments" },
    {
      name: " Void Payments ",
    },

    {
      name: "Logs",
    },
    {
      name: "CC Expire Alert",
    },
    {
      name: "Invoice",
    },
  ];

  const _tabelHeadersData = [
    {
      name: "Date",
      icon: arrowIcon,
    },
    {
      name: "Billing Info",
      icon: arrowIcon,
    },
    {
      name: "Parent Details",
      icon: arrowIcon,
    },
    {
      name: "Student Name",
      icon: arrowIcon,
    },
    {
      name: "Classes",
    },

    {
      name:
        orderState == "3"
          ? "Export"
          : orderState == "2"
          ? "Action"
          : "Recurring Payments",
    },
  ];

  const [sortKey, setSortKey] = useState({
    // sortId: "2",
    sortId: null,
    isAsc: false,
  });

  const [readStatus, setReadStatus] = useState({});
  const [isMarked, setIsMarked] = useState();
  const navigate = useNavigate();
  // Mark as read api :
  const page = useSelector((state) => state?.adminSlice?.adminModalContent);

  const [pageLimit, setPageLimit] = useState("5");

  const markAsRead = async (orderId) => {
    setIsMarked(!markAsRead);
    setReadStatus((prevStatus) => ({
      ...prevStatus,
      [`disable_${orderId}`]: true,
    }));

    const token = localStorage.getItem("access_token");

    try {
      const response = await markAsReadAPI(token, orderId);
      if (response.status === 200) {
        toast.success(response?.data?.data);

        getApiData();
      }
      setReadStatus((prevStatus) => ({
        ...prevStatus,
        [orderId]: true,
      }));
    } catch (error) {
      setReadStatus((prevStatus) => ({
        ...prevStatus,
        [`disable_${orderId}`]: false,
      }));
    }
  };

  const token = localStorage.getItem("access_token");

  const notificationHandler = async (category, index) => {
    setMailOrderId(category?.id);
    setLoaderIndex(index);
    setCategoryForMail(category);
    setShowMailModal(true);
    const orderid = {
      orderId: parseInt(category?.id),
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/invoiceSendMail`,
        orderid,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201)
        toast.success("Email sent successfully");
      // setSendMailData(response?.data?.data?.data1);
      // setTimeout(() => {
      //   setShowMailModal(false);
      // }, 1500);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const orderLogsHandler = async (category) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("access_token");
      const response = await getOrderLogs(token, category);
      setLoading(false);

      setOrderLogData(response?.data);
    } catch (error) {
      setLoading(false);
      return error;
    }

    setOpenLogs(true);
  };

  const voidOrderHandler = async (category) => {
    setOrderID(category?.id);
    setOpenVoidOrderModal(true);
  };

  const InvoiceHandler = async (e, orderID, isBirthday) => {
    const element = document.createElement("div");
    document.body.appendChild(element);
    setLoading(true);

    try {
      const token = localStorage.getItem("access_token");
      const response = await getInvoice(orderID, token, isBirthday);

      if (response?.status == 200) {
        setTimeout(() => {
          setLoading(false);
        }, 2000);

        if (isBirthday) {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          toast.success("Invoice Downloaded Successfully!");
          navigate("/birthday-invoice", {
            state: {
              data: response?.data?.data,
            },
          });
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          toast.success("Invoice Downloaded Successfully!");
          localStorage.setItem("INVOICE_DATA", JSON.stringify(response?.data));
          window.open("/order-parent-invoice", "_blank");
          // navigate("/order-parent-invoice", {
          //   state: {
          //     data: response?.data?.data,
          //   },
          // });
        }
      } else {
        setLoading(false);
        toast.error(response?.data?.error);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    }
  };

  const downloadOrderPdfHandler = async (category, index) => {
    setMailOrderId(category?.id);
    setLoaderIndex(index);
    setCategoryForMail(category);
    setDownloadPdf(true);
    const orderid = {
      orderId: parseInt(category?.id),
    };

    try {
      const response = await axios.post(
        // process.env.REACT_APP_BASE_URL + `/admin/invoiceSendMail`,
        process.env.REACT_APP_BASE_URL + `/admin/orders/invoice`,
        orderid,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSendMailData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error?.response?.data?.error);
      toast.error(error?.response?.data?.error);
      setDownloadPdf(false);
    }
  };
  // GET TABLE DATA
  const getApiData = async () => {
    setLoading(true);
    try {
      const response = await getOrdersAPI(
        token,
        page,
        sortKey,
        formData,
        orderState,
        pageLimit
      );

      if (response?.data) {
        setLoading(false);
      } else {
        setLoading(false);
      }
      setOrders(response?.data);
      setTotalPages(response?.totalPages);
      setCurrentPage(response?.currPage);
      setTotalCount(response?.totalCount);

      const modifiedData = [...response?.data]?.map((category, index) => {
        const data = [];
        // Date and Mark as Read
        data.push(
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "flex-start",
              gap: "130px",
            }}
          >
            {category?.date && (
              <p> {dateFormatterWithMonthName(category?.date)} </p>
            )}
            <p className="btnWithCheckBox">
              <button
                className="btnMarkAsRead"
                style={{
                  marginTop: "45%",
                  border: "0.5px solid #eee",
                  padding: "5%",
                }}
                onClick={() => markAsRead(category?.id)}
                disabled={
                  readStatus[`disable_${category?.id}`] || category?.is_viewed
                }
              >
                <input
                  id={`check_${category.id}`}
                  type="checkbox"
                  checked={category.isRead || category.is_viewed}
                  onChange={() => {}}
                  disabled={
                    readStatus[`disable_${category.id}`] || category.is_viewed
                  }
                />
                <label
                  htmlFor={`check_${category.id}`}
                  style={{ fontWeight: "500" }}
                >
                  Mark as Read
                </label>
              </button>
            </p>
          </div>
        );

        // Billing Info
        data.push(
          <div style={{ marginBottom: "70%", textAlign: "left" }}>
            {category?.first_name && category?.last_name && (
              <>
                {category.first_name} {category.last_name} <br />
              </>
            )}
            {category?.email && (
              <>
                {category.email} <br />
              </>
            )}
            {category?.address1 && (
              <>
                {category.address1}
                <br />
              </>
            )}
            {category?.city && category?.state && category?.zip && (
              <>
                {category.city}, {category.state} , {category.zip}
              </>
            )}
          </div>
        );

        // Parent Details
        data.push(
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
              fontSize: "13px",
            }}
          >
            <div>
              {category?.client?.parent1_first_name &&
                category?.client?.parent1_last_name && (
                  <p>
                    {category.client.parent1_first_name} &nbsp;
                    {category.client.parent1_last_name}
                  </p>
                )}
              {category?.client?.parent1_email && (
                <p> {category.client.parent1_email} </p>
              )}
              {category?.client?.parent1_cell && (
                <p>Cell : {category.client.parent1_cell} </p>
              )}
              {category?.client?.parent1_address && (
                <p> {category.client.parent1_address} </p>
              )}
              {category?.client?.parent1_apartment &&
                category?.client?.parent1_city &&
                category?.client?.parent1_state &&
                category?.client?.parent1_zip && (
                  <p>
                    Apt: {category.client.parent1_apartment},{" "}
                    {category.client.parent1_city} ,{" "}
                    {category.client.parent1_state} ,{" "}
                    {category.client.parent1_zip}
                  </p>
                )}
            </div>
            <div>
              {category?.client?.parent2_first_name &&
                category?.client?.parent2_last_name && (
                  <p>
                    {category.client.parent2_first_name} &nbsp;
                    {category.client.parent2_last_name}
                  </p>
                )}
              {category?.client?.parent2_email && (
                <p> {category.client.parent2_email} </p>
              )}
              {category?.client?.parent2_cell && (
                <p>Cell : {category.client.parent2_cell} </p>
              )}
              {category?.client?.parent2_address && (
                <p> {category.client.parent2_address} </p>
              )}
              {category?.client?.parent2_apartment &&
                category?.client?.parent2_city &&
                category?.client?.parent2_state &&
                category?.client?.parent2_zip && (
                  <p>
                    Apt: {category.client.parent2_apartment},{" "}
                    {category.client.parent2_city} ,{" "}
                    {category.client.parent2_state} ,{" "}
                    {category.client.parent2_zip}
                  </p>
                )}
            </div>
          </div>
        );

        // Student Details
        data.push(
          <div style={{ marginBottom: "135%" }}>
            {category?.student?.first_name && category?.student?.last_name && (
              <p>
                {category.student.first_name} &nbsp;
                {category.student.last_name}
              </p>
            )}
          </div>
        );

        // Classes
        data.push(
          <div style={{ marginBottom: "128%" }}>
            {category?.class.length > 0
              ? category?.class?.map((val, key) => (
                  <p key={key}>{val?.name ? val?.name : "-"}, </p>
                ))
              : null}
          </div>
        );

        // Order State
        orderState == "1" &&
          data.push(
            <div>
              {category?.currentInterval === null &&
              category?.totalInterval === null ? (
                <span>0 of 0</span>
              ) : (
                `${category?.current_recurring || 0} of ${
                  category?.total_recurring || 0
                }`
              )}
            </div>
          );

        // Button Handlers (Send Email / Enable Recurring)
        data.push(
          <div style={{ display: "flex", gap: "20px" }}>
            <button
              style={{ padding: orderState === "2" && "0px" }}
              className={`adminbtn  ${
                orderState === "3" ? "" : "adminbtn-primary"
              }`}
              onClick={(e) => {
                if (orderState == "1") {
                  // if (role !== "4") {
                  setEnablePaymentModal(true);
                  // }
                }
                setOrderID(category?.id);
                orderState === "2" && notificationHandler(category, index);
                orderState === "3" && InvoiceHandler(e, category?.id, true);
              }}
            >
              {orderState === "3" ? (
                <div>
                  <img
                    src={downloadIcon}
                    alt="download"
                    style={{
                      margin: "0px",
                      height: "100px",
                      width: "63px",
                    }}
                  />
                  <br />
                  Download
                </div>
              ) : orderState === "2" ? (
                ""
              ) : (
                // showMailLoader[index] ? (
                //   <ClockLoader color="#ffffff" size={30} />
                // ) : (
                //   "Send Mail"
                // )
                "Enable"
              )}
            </button>
            {orderState === "2" && (
              <button
                className="adminbtn adminbtn-primary"
                onClick={() => downloadOrderPdfHandler(category, index)}
              >
                Download Invoice
              </button>
            )}
          </div>
        );

        // Void Payments
        orderState == "1" &&
          data.push(
            <button
              className={`adminbtn ${
                category?.isVoidAllowed
                  ? "adminbtn-primary"
                  : "adminbtn-transparent"
              } `}
              style={{
                cursor: "pointer",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                voidOrderHandler(category);
              }}
              disabled={!category?.isVoidAllowed}
            >
              Void Transactions
            </button>
          );

        // View Logs
        orderState == "1" &&
          data.push(
            <button
              className="adminbtn adminbtn-primary"
              onClick={() => {
                orderLogsHandler(category?.id);
              }}
            >
              View Logs
            </button>
          );

        // CC Alert
        orderState == "1" &&
          data.push(
            <p>
              {category?.ccExpiryAlert ? (
                <button
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "15px",
                  }}
                >
                  <img
                    src={warning}
                    alt="warning"
                    style={{
                      height: "50px",
                      width: "50px",
                      padding: "0px",
                    }}
                  />
                  {category?.message}
                </button>
              ) : (
                <button
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    height={"40px"}
                    width={"50px"}
                    src={alertIcon}
                    alt="alrt-icon"
                  />
                  CC Expiry Alert
                  {category?.message}
                </button>
              )}
            </p>
          );

        // Download Button
        orderState == "1" &&
          data.push(
            <button
              onClick={(e) => {
                InvoiceHandler(e, category?.id, false);
              }}
              className=""
            >
              <img
                src={downloadIcon}
                alt="download"
                style={{
                  height: "40px",
                  width: "30px",
                  padding: "0px",
                }}
              />
              <br />
              Download
            </button>
          );

        return {
          data,
          item: category,
        };
      });

      setOrders(modifiedData);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getApiData();
  }, [isMarked]);

  const handleSearch = () => {
    getApiData(formData);
  };

  const handleAddSearch = () => {
    setTemp((prevTemp) => prevTemp + 1);
  };

  useEffect(() => {
    getApiData();
  }, [
    sortKey,
    page,
    pageLimit,
    orderType,
    pageLimit,
    orderState,
    showMailLoader,
  ]);

  const handleAdditionalFieldsChange = (index, key, value) => {
    setSelectedState(value === "className");

    const updatedData = [...formData];

    while (updatedData.length <= index) {
      updatedData.push({ selectUser: "", name: "" });
    }
    if (value !== updatedData[index][key]) {
      updatedData[index]["name"] = "";
    }
    updatedData[index][key] = value;
    setFormData(updatedData);
    getApiData(formData);
  };

  const getAllClassFunction = async () => {
    const token = localStorage.getItem("access_token");
    const response = await getClassForDropdown(token, page, "2");
    setAllClassApiData(response?.data);
  };

  useEffect(() => {
    getAllClassFunction();
  }, []);

  const renderAdditionalFields = () => {
    const additionalFields = [];
    const classOptions = [
      { value: "", label: "Select Class" },
      ...(Array.isArray(allClassApiData)
        ? allClassApiData
            .filter((val) => val?.name.trim() !== "")
            .map((val) => ({
              show: val?.name,
              value: val.name,
              label: `${val?.name} ${
                val?.schedules?.length > 0 ? ", (" : ""
              }${val?.schedules
                ?.map((el) => {
                  if (el?.weekday && el?.start_time && el?.end_time) {
                    return `${el.weekday.substring(0, 3)} ${timeFormatter(
                      el.start_time
                    )} - ${timeFormatter(el.end_time)}`;
                  }
                  return null;
                })
                .filter(Boolean)
                .join(", ")} ${val?.schedules?.length > 0 ? ")" : ""}`,
            }))
        : []),
    ];

    for (let i = 0; i < temp; i++) {
      additionalFields.push(
        <>
          <div
            key={i}
            className="adminform__group"
            style={{ minWidth: "160px" }}
          >
            <select
              style={{ minWidth: "115px" }}
              name={`selectUser_${i}`}
              id={`selectUser_${i}`}
              className="adminform__select"
              onChange={(e) =>
                handleAdditionalFieldsChange(i, "selectUser", e.target.value)
              }
              value={formData[i]?.selectUser}
            >
              <option value="" selected disabled>
                Search
              </option>
              <option value="billingInfo">Billing Info</option>
              <option value="parentDetails">Parent Name</option>
              <option value="studentName">Student Name</option>
              {orderState === "3" ? (
                ""
              ) : (
                <option value="className">Class Name</option>
              )}
            </select>
          </div>
          {formData[i]?.selectUser !== "className" ? (
            <input
              type="text"
              className="adminform__input"
              placeholder="Search here"
              name={`name_${i}`}
              onChange={(e) =>
                handleAdditionalFieldsChange(i, "name", e.target.value)
              }
              value={formData[i]?.name}
            />
          ) : (
            <Select
              styles={customStyles}
              options={classOptions}
              onChange={(selectedOption) => {
                handleAdditionalFieldsChange(i, "name", selectedOption.value);
              }}
              value={
                formData[i]?.name
                  ? { value: formData[i]?.name, label: formData[i]?.name }
                  : null
              }
              placeholder={"Search here"}
            />
            // <select
            //   name={`className_${i}`}
            //   className="adminform__select"
            //   onChange={(e) =>
            //     handleAdditionalFieldsChange(i, "name", e.target.value)
            //   }
            //   value={formData[i]?.name}
            //   style={{ maxWidth: "250px" }}
            // >
            //   <option value="" selected>
            //     Select Class
            //   </option>
            //   options=
            //   {allClassApiData
            //     ?.filter((val) => val?.name.trim() !== "")
            //     .map((val, index) => (
            //       <option
            //         key={index}
            //         value={val?.name}
            //         style={{ maxWidth: "250px" }}
            //       >
            //         {val?.name}
            //       </option>
            //     ))}
            // </select>
          )}
        </>
      );
    }
    return additionalFields;
  };

  const handleCancelAdditionalFields = (index) => {
    setTemp((prevTemp) => {
      return prevTemp > 1 ? prevTemp - 1 : prevTemp;
    });

    setFormData((prevSearchParams) => {
      const newSearchParams = [...prevSearchParams];
      newSearchParams[index] = { selectUser: "", name: "" };
      const filteredSearchParams = newSearchParams.filter(
        (item) => item.selectUser !== "" || item.name !== ""
      );
      return filteredSearchParams;
    });
  };

  return (
    <>
      {loading && <LoaderComponent />}
      <div className="container--component">
        <div className="container--grid">
          <NavigationComponent />

          <div className="Adminpage">
            <Header title="Orders" subTitle="(View Website Orders)" />

            <section className="Adminpage__section">
              <div
                style={{
                  marginBottom: "30px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <div style={{ paddingTop: "25px" }}>
                  <h2 className="Adminpage__title">Select Order type:</h2>
                  <select
                    name="selectedOrder"
                    id=""
                    className="adminform__select_Order_Type"
                    value={orderType}
                    onChange={(e) => {
                      setOrderType(e.target.value);
                      setOrderState(e.target.value);
                    }}
                    style={{ width: "175px" }}
                  >
                    <option value="1">Parent Panel Orders</option>
                    <option value="2">Admin Panel Orders</option>
                    <option value="3">Birthday Party Orders</option>
                  </select>
                </div>

                <div className="" style={{ height: "80px", padding: "10px" }}>
                  <h2 className="Adminpage__title">Search by:</h2>

                  <div className="Adminpage__head">
                    <div className="Adminpage__filter">
                      {renderAdditionalFields()}

                      {temp <= 1 ? (
                        ""
                      ) : (
                        <button
                          type="button"
                          className="adminform__cancel-button"
                          onClick={() => handleCancelAdditionalFields(1)}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      )}

                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <button
                          className="adminbtn adminbtn-light"
                          onClick={handleAddSearch}
                          disabled={temp == 2 ? true : false}
                          style={{
                            display: temp == 2 ? "none" : "block",
                            alignItems: "center",
                            marginTop: "8px",
                          }}
                        >
                          and
                        </button>
                        <button
                          className="adminbtn adminbtn-primary"
                          onClick={handleSearch}
                        >
                          <SearchIcon />
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* </div> */}
              </div>
              {/* </div> */}

              <TableNew
                headerData={
                  orderState == "1" ? tableHeader1 : _tabelHeadersData
                }
                bodyData={orders}
                totalPages={totalPages}
                currentPage={currentPage}
                tableName="Orders"
                setSortKey={setSortKey}
                setPageLimit={setPageLimit}
                pageLimit={pageLimit}
                totalCount={totalCount}
              />
            </section>
          </div>
        </div>
      </div>

      {EnablePaymentModal && (
        <EnableRecurring
          setEnablePaymentModal={setEnablePaymentModal}
          buttonType={"Orders"}
          orderID={orderID}
          setRecurrinDuration={setRecurrinDuration}
          recurringDuration={recurringDuration}
          setToggleState={setToggleState}
          toggleState={toggleState}
          setOpenDeleteCardFile={setOpenDeleteCardFile}
          setDeleteCardID={setDeleteCardID}
          fetchData={getApiData}
          deleteCardOnFile={deleteCardOnFile}
        />
      )}

      {openDeleteCardFile && (
        <DeleteModal
          toggleState={toggleState}
          setToggleState={setToggleState}
          openDeleteModal={openDeleteCardFile}
          setDeleteModal={setOpenDeleteCardFile}
          type={"CARD"}
          deleteCardID={deleteCardID}
          setDeleteCardOnFile={setDeleteCardOnFile}
          // fetchData={getAllLedgers()}
        />
      )}

      {/* {
      showMailModal &&
       (
        <SendMailModal
          sendMailData={sendMailData}
          categoryForMail={categoryForMail}
          setShowMailLoader={setShowMailLoader}
          loaderIndex={loaderIndex}
          mailOrderId={mailOrderId}
        />
      )} */}
      {downloadPdf && (
        <DownloadOrderPdf
          sendMailData={sendMailData}
          categoryForMail={categoryForMail}
          setShowMailLoader={setShowMailLoader}
          loaderIndex={loaderIndex}
          mailOrderId={mailOrderId}
          setDownloadPdf={setDownloadPdf}
          setSendMailData={setSendMailData}
        />
      )}
      {openLogs && (
        <OrderLogs
          openVoidModalState={openLogs}
          setOpenVoidModalState={setOpenLogs}
          logsData={orderLogData}
        />
      )}

      {/* VOID ORDERS  */}

      {openVoidOrderModal && (
        <VoidOrderPayment
          openVoidOrderModal={openVoidOrderModal}
          setOpenVoidOrderModal={setOpenVoidOrderModal}
          orderID={orderID}
          fetchData={getApiData}
        />
      )}
    </>
  );
};

export default Orders;
