import React from 'react'

const ProgramDetailShimmer = () => {
    return (
        <>
            <div className="shimmer_UI_container">
                <div className="shimmer_UI_container_fluid">
                    <div className="shimmer_header_container">
                        <div className="shimmer_effect"></div>
                    </div>
                    <div className="image_banner_container">
                        <div className="shimmer_effect"></div>
                    </div>

                    <div className="_shimmer_program_heading_container">
                        <div className="shimmer_content_heading_container">
                            <div className="_shimmer_heading_section">
                                <div className="birthday_header_shimmer"></div>
                                <div className="birthday_header_shimmer-1"></div>
                                <div className="birthday_header_shimmer-1"></div>
                                <div className="birthday_header_shimmer-1"></div>
                                <div className="birthday_header_shimmer-1"></div>
                                <div className="birthday_header_shimmer-1"></div>
                                <div className="birthday_header_shimmer-1"></div>
                            </div>
                        </div>
                    </div>

                    <div className="program_class_shimmer_container">
                        <div className="program_list_shimmer-1"></div>
                        <div className="program_list_shimmer-1"></div>
                        <div className="program_list_shimmer-1"></div>
                        <div className="program_list_shimmer-1"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProgramDetailShimmer