import {createSlice} from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const toRenderSlice = createSlice({
    name: "torender",
    initialState: {
        tableheadersData: [],
        reportsData: [],
        reportSelected:""
    },

    reducers: {
        generatetableheadersData: (state, action) => {
           
            state.tableheadersData = action.payload;

        },
        generateReportData:(state, action)=>{
            state.reportsData = action.payload;

        },
        getReportSelected:(state, action)=>{
            state.reportSelected = action.payload;
        }

    }})

    export const {generatetableheadersData,generateReportData,getReportSelected} = toRenderSlice.actions;

export default toRenderSlice.reducer;