import React from "react";
import { ReactComponent as Logo } from "../parent-assets/images/gymtime_logo_2024.svg";
import { useNavigate } from "react-router-dom";
import borderImage from "../parent-assets/images/birthday-icons/bday-pdf-bottom.png";
function BirthdayTerms() {
  const navigate = useNavigate();
  return (
    <>
      <div
        className=""
        style={{ width: "450px", margin: "40px 30%", cursor: "pointer" }}
        onClick={() => {
          navigate("/");
        }}
      >
        <Logo />
      </div>
      <div className="contentwraper" style={{ marginTop: "-35px" }}>
        <section className="commonSec">
          <div className="container">
            <div className="shortText">
              <p className="bday_content">
                Thank you for choosing Gymtime Rhythm and Glues to host your
                child’s birthday party!
              </p>
              <p className="bday_content">
                Please take a moment to review our Terms & Conditions
              </p>
              <p className="bday_content">
                If you are planning on having any outside
                entertainment/characters, photographers, videographers, they
                must provide Gymtime with an insurance certificate in the amount
                of $1,000,000, naming Gymtime Rhythm and Glues as the
                additionally insured party.
              </p>
              <p className="bday_content">
                PAYMENTS ARE NON-TRANSFERABLE. PAYMENT IN FULL IS EXPECTED UPON
                COMPLETION OF THE PARTY. THERE ARE NO REFUNDS OR CREDITS FOR
                CANCELED PARTIES.
              </p>

              <p className="bday_content">
                We will contact you 10 days prior to your party date to confirm
                the exact number of children.{" "}
                <span style={{ fontWeight: "600" }}>
                  {" "}
                  If you do not confirm the number of children attending the
                  party on or before this date we will prep your party based on
                  the estimated number you provided upon booking the party.{" "}
                </span>
                We cannot accept any changes after this date, due to staff
                scheduling and ordering of supplies. This will be the minimum
                number that you will be charged. If you go over your confirmed
                number, you will still be obligated to pay any additional
                charges per child. If fewer children show, adjustments can be
                made, but you will still be responsible for the cost of
                additional items that were purchased for the final number given.
                All children including siblings who participate are counted in
                the final total. Instructor/child ratio is 1 instructor per 8
                children. Instructors are assigned based upon your confirmed
                number of children.
              </p>
              <p className="bday_content">A few points to remember:</p>
              <ul>
                <li className="bdaylist  bday_content">
                  The standard 90-minute party consists of 60 minutes of gym and
                  30 minutes in our party room. This is subject to change if the
                  party is extended or another activity is added.
                </li>
                <li className="bdaylist bday_content">
                  <span style={{ fontWeight: "600" }}>
                    Time restraints do not permit the opening of gifts during
                    the party.
                  </span>
                </li>
                <li className="bdaylist bday_content">
                  We are responsible for the setup, clean up of your party.
                </li>
                <li className="bdaylist bday_content">
                  <span className="bdayfont">
                    {" "}
                    There are{" "}
                    <span style={{ textDecoration: "underline" }}>
                      no shoes
                    </span>{" "}
                    allowed in the gym (children and adults). Please inform your
                    guest of this rule prior to your party. For their comfort,
                    they may wear socks.{" "}
                  </span>
                </li>
                <li className="bdaylist bday_content">
                  Please do not arrive more than 15 minutes prior to the time of
                  your party. Be courteous of the classes and parties happening
                  before your event.
                </li>
              </ul>
            </div>

            <div className="shortText" style={{ marginTop: "30px" }}>
              <p className="bday_content">
                Please do not hesitate to call 212 861-7732 if you have any
                questions.{" "}
              </p>
              <p style={{ fontStyle: "italic" }} className="bday_content">
                In agreeing to the Terms & Conditions, I hereby give permission
                to Gymtime Rhythm and Glues to collect use or disclose for
                advertising, promotion or marketing purposes the name, portrait
                or picture of student or undersigned in all media, now or
                hereafter developed, including without limitation, on bulletin
                boards located in schools or in websites or print media. On
                behalf of myself and my student hereby agrees not to seek and
                waive any and all rights to receive any compensation or seek
                renumeration of any kind whatsoever in connection with the
                foregoing. On behalf of myself and my student I hereby release,
                indemnify and agree to hold harmless Gymtime Rhythm and Glues,
                its affiliates, trustees, officers, directors, employees,
                website designers, attorneys, agents, representatives and other
                similar persons from and against all claims, costs, damages,
                liabilities and expenses of any kind whatsoever which may arise
                at any time, now or hereafter, or indirectly, as a result of the
                collection, use or disclosure by Gymtime Rhythm and Glues of the
                name, portrait or picture of student or myself in all media, now
                or hereafter developed!.
              </p>
            </div>
          <div style={{marginTop:"5%",marginLeft:"8%"}}>
            <img src={borderImage} alt="" />
          </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default BirthdayTerms;
