import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

const ChangeCell = ({ openAddNewUser, setAddNewUser, setCellState }) => {
  const [formData, setFormData] = useState({
    cell:""
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddCell = async(e) => {
    e.preventDefault();
    // const updateResponse = async () => {
      if (
        formData.cell < 1
      ) {
        toast.error("Cell Number is required");
        return;
      }
      const token = localStorage.getItem("access_token");
      try {
        const response = await axios.put(
          process.env.REACT_APP_BASE_URL + `/admin/user/update`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response) {
          toast.success("Cell updated successfully");
          setAddNewUser(false);
          setCellState(formData?.cell)
          // setAddNewClient(false);
        }
        // fetchData();
      } catch (error) {
        console.error("Error:", error);
      }
    // };
  }

  return (
    <>
      {openAddNewUser ? (
        <div
          className="Admin-modal Admin-modal--open"
          id="modalCategory"
          role="dialog"
        >
          <div className="Admin-modal__dialog Admin-modal__dialog--sm">
            <div className="Admin-modal__content">
              <div className="Admin-modal__head">
                <h2 className="Admin-modal__head--title">
                  {/* {type === "Edit" ? "Edit Category " : "+ Add New Category"} */}
                  Change Cell
                </h2>
                <button
                  className="Admin-modal__close"
                  onClick={() => {
                    setAddNewUser(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_1_9631)">
                      <path
                        d="M14.0728 6.92854L14.0728 6.92856C14.4017 7.26332 14.4017 7.79324 14.0728 8.128L14.0713 8.12951L14.0713 8.1295L11.6968 10.5057L14.0354 12.8405C14.3655 13.1697 14.3655 13.7084 14.0354 14.0376C13.706 14.3667 13.1717 14.3663 12.8421 14.0376L12.8418 14.0373L10.5035 11.6969L8.14748 14.0551L8.14649 14.0561C7.81513 14.3841 7.27944 14.3841 6.94808 14.0561L6.94561 14.0536L6.94562 14.0536C6.61805 13.7202 6.61475 13.1828 6.94753 12.8523C6.94764 12.8521 6.94775 12.852 6.94785 12.8519L9.30329 10.4943L6.9652 8.15951C6.63514 7.8303 6.63511 7.2917 6.9651 6.96245C6.96513 6.96242 6.96516 6.96238 6.9652 6.96235L7.14175 7.13935C7.37272 6.90779 7.74915 6.90779 7.98131 7.13935L14.0728 6.92854ZM14.0728 6.92854L14.0703 6.92609C13.739 6.5981 13.2039 6.5981 12.8725 6.92609L12.8716 6.92703L10.4965 9.30317L14.0728 6.92854ZM10.5 18.5625C6.04722 18.5625 2.4375 14.9516 2.4375 10.5C2.4375 6.04836 6.04722 2.4375 10.5 2.4375C14.9528 2.4375 18.5625 6.04836 18.5625 10.5C18.5625 14.9516 14.9528 18.5625 10.5 18.5625ZM10.5 0.75C5.11499 0.75 0.75 5.11315 0.75 10.5C0.75 15.8869 5.11499 20.25 10.5 20.25C15.885 20.25 20.25 15.8869 20.25 10.5C20.25 5.11315 15.885 0.75 10.5 0.75Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.5"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_9631">
                        <rect width="21" height="21" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>

              <div className="Admin-modal__body">
                <div className="form__group">
                  <label className="adminform-label">
                   Enter new Cell
                  </label>
                  <input
                    type="text"
                    className="adminform__input"
                    placeholder=" Enter new Cell"
                    //   value={data ? editData.name : formData.name}
                    name="cell"
                    onChange={handleInput}
                  />
                </div>
                {/* <div className="adminform__group">
                <label className="adminform-label">
                   Re-Enter new Cell
                  </label>
                  <input
                    type="text"
                    className="adminform__input"
                    placeholder="Re-Enter new Cell"
                    //   value={data ? editData.name : formData.name}
                    name="cell"
                    onChange={handleInput}
                  />
                </div> */}
              </div>

              <div className="Admin-modal__footer">
                <div
                  className="admin-button__group"
                  style={{ display: "flex" }}
                >
                  <button
                    className="adminbtn adminbtn-blueOutline"
                    onClick={() => {
                      setAddNewUser(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="adminbtn adminbtn-primary"
                      onClick={handleAddCell}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ChangeCell;
