import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { setClassData } from "../../../../reducers/classDetailsSlice";
import { GET_CLASS_DETAILS } from "../../../Queries/classQueries";
import EditStudentModal from "../../../parent-components/editStudentModal";
import SuccesModal from "../../../parent-components/User/SuccesModal";
// API
import {
  getAllDataForCart,
  getClassDetails,
  joinWatchListAPI,
  studentAddToCartApiFunction,
} from "../../../api/classApi";
import { getStudentsByClass } from "../../../api/studentApi";
// Images
import lousengerImg from "../../../parent-assets/images/ana-lousenger-img.png";
import aboutUsBanner from "../../../parent-assets/images/home-banner.jpg";
import programDetailImg1 from "../../../parent-assets/images/About.jpg";
import { toast } from "react-toastify";
import { getManageProgramData } from "../../../../Admin/adminApi/manageProgramData";
import { getClassesForAge } from "../../../../Admin/adminApi/classes";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { empty } from "@apollo/client";
import {
  convertDateForClassDetails,
  convertTo12HourFormat,
} from "../../../../Admin/adminUtility";
import AboutUsShimmer from "../../../../Admin/components/ShimmerUi/AboutUsShimmer";
import { OptionsClassDetailsPage } from "../../../utility/validation";

function ClassDetails() {
  const token = localStorage.getItem("access_token");
  const [ageRangeIdRange, setAgeRangeIdRange] = useState();
  const [showShimmer, setShowShimmer] = useState(false);
  const temp = useLocation();
  const [tempState, setTempState] = useState(
    temp?.pathname
      ?.split("/")
      ?.pop()
      ?.toString()
      ?.split("")
      ?.slice(0, 4)
      .join("")
  );
  const dispatch = useDispatch();
  const location = useLocation();
  let { classId } = useParams();
  let { id } = useParams();
  const { state } = useLocation();
  let ageRangeTempId;

  if (temp.search.split("=")?.pop() === "false") {
    if (
      temp?.pathname?.split("/")?.pop()?.toString()?.split("")?.includes("-")
    ) {
      classId = classId.toString().slice(4);
    } else {
      classId = classId.toString().slice(1);
    }
  }

  useEffect(() => {
    let classpramId = parseInt(temp?.pathname?.split("/")?.pop());

    let tempVar;
    if (
      temp?.pathname?.split("/")?.pop()?.toString()?.split("")?.includes("-")
    ) {
      tempVar = temp?.pathname
        ?.split("/")
        ?.pop()
        ?.toString()
        ?.split("")
        ?.slice(0, 4)
        .join("");
      setTempState(tempVar);
      if (tempVar === "0-12") {
        ageRangeTempId = "0-1";
        setAgeRangeIdRange("0-1");
      } else {
        ageRangeTempId = "1-2";
        setAgeRangeIdRange("1-2");
      }
    } else if (
      temp?.pathname?.split("/")?.pop()?.toString()?.split("")?.includes("+")
    ) {
      ageRangeTempId = "7";
      setTempState("7+");
      setAgeRangeIdRange("7+");
    } else {
      ageRangeTempId = classpramId?.toString().charAt(0);
      setTempState(ageRangeTempId);
      setAgeRangeIdRange(ageRangeTempId);
    }
  }, []);

  const categoryId =
    window.location.href.split("/")[window.location.href.split("/").length - 1];
  const isAge = window.location.href
    .split("/")
    [window.location.href.split("/").length - 1].split("=")[1];

  const navigate = useNavigate();
  let accessToken = localStorage.getItem("access_token");
  const [successModal, setSuccessModal] = useState(false);
  const owlRef = useRef(null);
  const [editingStudentId, setEditingStudentId] = useState(null);
  const [classDetailsData, setClassDetailsData] = useState([]);
  const [classDetails, setClassDetails] = useState();
  const [getStudentData, setGetStudentData] = useState("");
  const [classAvail, setClassAvail] = useState();
  const [selectedStudent, setSelectedStudent] = useState(null);
  const selectedStudents = useSelector((state) => state.cart.selectedStudents);
  const [getCartApiData, setGetCartApiData] = useState();
  const [selectedClassProgram, setSelectedClassProgram] = useState();
  const [isSelectedState, setIsSelectedState] = useState(false);
  const params = useParams();
  // UpdatedStates
  const [isStudentSelectState, setIsStudentSelectState] = useState(false);
  const [studentState, setStudentState] = useState({
    isStudentSelected:
      getCartApiData?.classDetails?.length > 0 ||
      getCartApiData?.birthday?.studentDetails?.id
        ? true
        : false,

    studentID:
      getCartApiData?.classDetails?.length > 0
        ? getCartApiData?.classDetails[0]?.studentId
        : getCartApiData?.birthday?.studentDetails?.id,
  });

  const [activeButtons, setActiveButtons] = useState(
    Array(classAvail).fill(false)
  );

  useEffect(() => {
    setShowShimmer(true);
    const storedData = localStorage.getItem("classData");
    if (storedData) {
      dispatch(setClassData(JSON.parse(storedData)));
    }
    localStorage.setItem("class-location", location.pathname);
    const token = localStorage.getItem("access_token");
    // CLASS DETAILS
    localStorage.setItem("classID", classId);
    getClassDetails(parseInt(classId), token)
      .then((classDetailsResponse) => {
        setShowShimmer(false);
        if (state !== null) {
          setClassDetails([state?.comboData]);
        } else {
          setClassDetails(classDetailsResponse);
        }
        dispatch(setClassData(classDetailsResponse));
      })
      .catch((error) => {
        setShowShimmer(false);
        toast.error(error?.response?.data?.error, {
          toastId: "error",
        });
        console.error("Error fetching class details from API", error);
      })
      .finally(() => {
        setShowShimmer(false);
      });
    fetchData();
  }, [isSelectedState, state]);

  // CMS DATA
  const fetchData = async () => {
    if (temp.search.split("=")?.pop() === "true") {
      const getProgramMangeData = await getManageProgramData(token, id);
      setClassDetailsData(getProgramMangeData?.data);
    } else {
      const getAgeManageData = await getClassesForAge(
        token,
        id,
        ageRangeIdRange ? ageRangeIdRange : ageRangeTempId
      );
      setClassDetailsData(getAgeManageData);
    }
  };

  useEffect(() => {
    if (token) {
      const sessionTimeOut = setTimeout(() => {
        localStorage.removeItem("access_token");
        navigate("/");
      }, 86400000);
      return () => clearTimeout(sessionTimeOut);
    }
  }, [navigate]);

  useEffect(() => {
    const classVariables = {
      name: classDetails?.[0]?.name,
      categoryId:
        filterData?.[0]?.categoryId !== undefined
          ? filterData[0].categoryId
          : classDetails?.[0]?.fk_category_classes,
      isCombo: false,
      minAge: mappedData?.[0]?.min_age,
      maxAge: mappedData?.[0]?.max_age,
      programId:
        temp?.search?.split("=")?.pop() === "true" ? parseInt(id) : null,
    };

    const comboVariable = {
      name: state?.comboData?.name,
      categoryId: `${state?.comboData?.fk_category_classes}`,
      minAge: state?.comboData?.min_age,
      maxAge: state?.comboData?.max_age,
      isCombo: true,
      programId: parseInt(id),
      fk_category_class_cms: parseInt(params?.classId),
    };

    const token = localStorage.getItem("access_token");
    setShowShimmer(true);

    const fetchClassDetailsData = async () => {
      axios
        .post(
          process.env.REACT_APP_BASE_URL + "/graphql",
          {
            query: GET_CLASS_DETAILS,
            variables: state !== null ? comboVariable : classVariables,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setTimeout(() => {
            setShowShimmer(false);
          }, [2000]);
          setClassAvail(response?.data?.data?.getClass);
          localStorage.setItem("CART_DATA", response?.data?.data?.getClass);
        })
        .catch((error) => {
          setShowShimmer(false);
          toast.error(error?.response?.data?.error, {
            toastId: "error",
          });
        })
        .finally(() => {
          setShowShimmer(false);
        });
    };

    if (mappedData?.length) {
      fetchClassDetailsData();
    }
  }, [classDetails]);

  useEffect(() => {
    const classVariables = {
      name: classId,
      categoryId: categoryId,
      programId: id,
      minAge: mappedData?.[0]?.min_age,
      maxAge: mappedData?.[0]?.max_age,
    };

    setShowShimmer(true);
    if (accessToken !== null || undefined) {
      getStudentsByClass(classVariables, accessToken)
        .then((studentDataResponse) => {
          setShowShimmer(false);
          setGetStudentData(studentDataResponse);
        })
        .catch((error) => {
          setShowShimmer(false);
          toast.error(error?.response?.data?.error, {
            toastId: "error",
          });
        })
        .finally(() => {
          setShowShimmer(false);
        });
    }
  }, []);

  // Add student Handler
  const clickHandler = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      if (selectedStudents.length === 0) {
        navigate("/parent", { state: "tab2" });
      } else {
        navigate("/parent", { state: "tab2" });
      }
    } else {
      toast.error("Please log in to proceed.", {
        toastId: "error",
      });
    }
  };

  const closeEditModal = () => {
    setEditingStudentId(null);
  };

  useEffect(() => {
    closeEditModal();
  }, [getStudentData, selectedStudent]);

  const filterData = classDetailsData?.filter(
    (classDetails) => classDetails?.id === classId
  );

  const saveStudentChanges = (updatedStudent) => {
    closeEditModal();

    setGetStudentData((prevStudentData) =>
      prevStudentData.map((student) =>
        student.id === updatedStudent.id ? updatedStudent : student
      )
    );
  };

  const joinWatchListAPIHandler = async (id) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      try {
        await joinWatchListAPI(id, token);
        toast.success("Thank you, our team will connect with you soon.");
      } catch (error) {
        toast.error(error?.message);
      }
    } else {
      toast.error("Please log in to proceed.", {
        toastId: "error",
      });
    }
  };

  const classAvailabilityList = () => {
    if (classAvail?.length) {
      return (
        <>
          <h3 className="title title--extrabold">Class Availability</h3>
          <div className="classAvailability__list">
            {classAvail?.map((classItem, index) => {
              const { name, max_strength, min_age, max_age, tuition } =
                classItem;
              const slots =
                max_strength === 0
                  ? 0
                  : max_strength - classItem?.classes_enrolled?.length < 0
                  ? 0
                  : max_strength - classItem?.classes_enrolled?.length;
              return (
                <div key={index} className="classAvailability__list__card">
                  <div className="item item--flexbox">
                    <div className="content">
                      <h3 className="title title--extrabold">{name}</h3>
                      <h4 className="title--h5 ages">
                        {min_age > 24
                          ? `${Math.floor(min_age / 12)} ${
                              min_age % 12 ? "." : ""
                            } ${min_age % 12 ? min_age % 12 : ""}Years`
                          : `${min_age} Months`}
                        {max_age < 2500 ? "-" : " "}
                        {max_age < 2500
                          ? max_age > 24
                            ? `${Math.floor(max_age / 12)} ${
                                max_age % 12 ? "." : ""
                              } ${max_age % 12 ? max_age % 12 : ""}Years`
                            : `${max_age} Months`
                          : "and up"}
                      </h4>

                      <h5 className="ages">
                        <span>
                          {convertDateForClassDetails(classItem?.start_date)}
                        </span>
                        -
                        <span>
                          {convertDateForClassDetails(classItem?.end_date)}
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div className="item item--flexbox item--flexbox--spacebetween">
                    <div className="content">
                      {classItem?.class_schedules?.map(
                        (classSchedule, scheduleIndex) => (
                          <div
                            key={scheduleIndex}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="weekDays"
                          >
                            <div className="class_wk">
                              <p
                                className="title--h5 weekDays weekdays_width_container"
                                style={{
                                  color: "#5e5f5f",
                                  marginBottom: "4px",
                                  fontWeight: "600",
                                }}
                              >
                                {classItem?.is_combo
                                  ? classSchedule?.subject
                                  : classSchedule?.weekday}
                              </p>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  color: "#5e5f5f",
                                  fontSize: " 0.961rem",
                                }}
                                className="timing"
                              >
                                <span>
                                  {convertTo12HourFormat(
                                    classSchedule?.start_time
                                  )}
                                </span>{" "}
                                -{" "}
                                <span>
                                  {convertTo12HourFormat(
                                    classSchedule?.end_time
                                  )}
                                </span>
                                {classItem?.is_combo == true &&
                                  classSchedule?.subject !== null && (
                                    <span>({classSchedule?.weekday})</span>
                                  )}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    <span className="Class_tuition_fess">${tuition}/month</span>
                    <div className="buttonsInfo">
                      <button
                        className={`btn ${
                          activeButtons[index] ? "btn--primary" : ""
                        }`}
                        onClick={async () => {
                          if (
                            isStudentSelectState ||
                            getCartApiData?.classDetails?.length > 0 ||
                            Object.keys(getCartApiData?.birthday || {}).length >
                              0 ||
                            getCartApiData?.birthday?.studentDetails?.id ==
                              undefined
                          ) {
                            if (slots === 0) {
                              const classItemId =
                                classItem?.class_schedules[0]?.classScheduleId;
                              await joinWatchListAPIHandler(classItemId);
                            } else {
                              if (!activeButtons[index]) {
                                if (token) {
                                  const response =
                                    await studentAddToCartApiFunction(token, {
                                      studentId: studentState?.studentID,
                                      classScheduleId:
                                        classItem?.class_schedules[0]
                                          ?.classScheduleId,
                                      adhocIds: [],
                                      packageId: "",
                                    });
                                  if (response?.status === 200) {
                                    toast.success(response?.data?.data);
                                    const updatedButtons = [...activeButtons];
                                    updatedButtons[index] = true;
                                    setActiveButtons(updatedButtons);
                                  } else if (
                                    response?.response?.status === 400
                                  ) {
                                    toast.error(
                                      response?.response?.data?.error,
                                      {
                                        toastId: "error",
                                      }
                                    );
                                  }
                                } else {
                                  toast.error("Please log in to proceed.", {
                                    toastId: "error",
                                  });
                                }
                              } else {
                                navigate("/cart");
                              }
                            }
                          } else {
                            toast.error("Select student to proceed!", {
                              toastId: "error",
                            });
                          }
                        }}
                      >
                        {activeButtons[index]
                          ? "Go to Cart"
                          : slots === 0
                          ? "Join Watchlist"
                          : "+ Add to Cart"}
                      </button>

                      <div style={{ marginTop: "10px" }}>
                        {/* <button
                          type="button"
                          className="btn btn--primary"
                          onClick={(e) => {
                            setClassScheduleID(
                              classItem?.class_schedules[0]?.classScheduleId
                            );
                            if (studentState?.studentID == undefined || null) {
                              toast.error("Select Student to book a trial.", {
                                toastId: "error",
                              });
                              return;
                            } else {
                              bookTrialClassHandler(e, classItem);
                            }
                          }}
                        >
                          Book a trial
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      );
    } else {
      return (
        <div style={{ padding: "10px ", width: "100%" }}>
          <div
            className="title title--h1 text-center "
            style={{ color: "#EA519C", paddingTop: "30px" }}
          >
            No Class Schedules available.
          </div>
        </div>
      );
    }
  };

  // Hanlde Selected Student
  const handleSelectStudent = async (event, student) => {
    if (getCartApiData?.classDetails?.length) {
      toast.error(" Student is Already Added in the Cart!", {
        toastId: "error",
      });
      return;
    } else {
      setIsStudentSelectState(true);
      setStudentState({
        isStudentSelected: true,
        studentID: student?.id,
      });
    }
  };

  const studentList = () => (
    <>
      <div className="student_container">
        {getStudentData &&
          getStudentData?.map((val, index) => {
            let isSelected = false;

            if (getCartApiData?.classDetails?.length > 0) {
              isSelected = getCartApiData?.classDetails?.some(
                (cartStudent) => cartStudent?.studentId == val?.id
              );
            } else if (getCartApiData?.classDetails?.length === 0) {
              isSelected =
                getCartApiData?.birthday?.studentDetails?.id == val?.id;
            } else {
              isSelected = undefined;
            }

            return (
              <div className="student__card" key={index}>
                <div className="student_flexbox">
                  <div className="student_flx">
                    <div>
                      <input
                        id={`student-${index}`}
                        name="student-radio"
                        style={{
                          height: "18px",
                          width: "17px",
                          accentColor: "#5E5F5F",
                          cursor: "pointer",
                          borderWidth: "10px",
                        }}
                        type="radio"
                        checked={isSelected}
                        onChange={(e) => {
                          handleSelectStudent(e, val);
                        }}
                      />
                    </div>
                    <div>
                      <div className="student_pic">
                        <img
                          className="student_image"
                          src={val?.image ? val.image : lousengerImg}
                          alt="ana-louser-img"
                        />
                      </div>
                      <div className="detail">
                        <h3 className="name" style={{ fontSize: "1rem" }}>
                          {val?.first_name} {val?.last_name}
                        </h3>
                        <p className="para para--lightColor">GYMTIME</p>
                      </div>
                    </div>
                  </div>
                  <div className="btnActions">
                    {/* <button
                      className=" cross_btn"
                      type="button"
                      onClick={() => {
                        deleteStudentHandle(val?.id);
                      }}
                    >
                      <img
                        className="btnTransparent__icon"
                        src={trashIcon}
                        alt="trash-icon"
                        width={20}
                        height={20}
                      />
                    </button> */}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );

  // Lower Carousel Componet of the Classes ::
  const getClassByProgramID = async () => {
    const token = localStorage.getItem("access_token");

    if (state !== null) {
      const postData = {
        categoryId: `${state?.comboData?.fk_category_class_cms}`,
      };

      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/getClassesCMSAdminForCombo`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSelectedClassProgram(response?.data?.data);
    } else {
      if (isAge == "false") {
        const getAgeManageData = await getClassesForAge(
          token,
          id,
          ageRangeIdRange ? ageRangeIdRange : ageRangeTempId
        );
        setSelectedClassProgram(getAgeManageData);
      } else {
        const response = await getManageProgramData(token, params?.id);
        setSelectedClassProgram(response?.data);
      }
    }
  };

  useEffect(() => {
    getClassByProgramID();
  }, []);

  const moreInfoHandler = async (programId, value) => {
    if (value?.is_combo) {
      navigate(
        `/browse-by-program/${id}/${value?.fk_category_class_cms}?browse-by=true`,
        {
          state: {
            comboData: value,
            mainData: state?.mainData,
          },
        }
      );
      return;
    }
    if (temp?.search?.split("=")?.pop() === "true") {
      navigate(`/browse-by-program/${id}/${programId}${temp?.search}`);
    } else {
      navigate(
        `/browse-by-program/${id}/${tempState}${programId}${temp?.search}`
      );
    }
    setIsSelectedState(!isSelectedState);
  };

  const cartData = async () => {
    try {
      const response = await getAllDataForCart(token);
      setGetCartApiData(response);
      return response;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("access_token");

    const fetchData = async () => {
      const cartResponse = await cartData();

      if (
        cartResponse?.classDetails?.length == 0 &&
        getCartApiData?.birthday !== empty
      ) {
        setStudentState({
          isStudentSelected: true,
          studentID: cartResponse?.birthday?.studentDetails?.id,
        });
      }

      if (
        cartResponse &&
        cartResponse.classDetails &&
        cartResponse.classDetails.length > 0
      ) {
        setStudentState({
          isStudentSelected: true,
          studentID: cartResponse.classDetails[0].studentId,
        });
      }
    };

    if (token !== null) {
      fetchData();
    }
  }, [token]);

  const mappedData = classDetails?.length
    ? classDetails
    : filterData?.length
    ? filterData
    : classDetails;

  return (
    <>
      {showShimmer ? (
        <AboutUsShimmer />
      ) : (
        <div>
          <div className="contentwraper" style={{ paddingBottom: "0px" }}>
            <section
              className="siteBanner"
              style={{ background: "#F79727", cursor: "default" }}
            >
              <div className="siteBanner__overlay">
                <img
                  className="siteBanner__img"
                  src={
                    state?.comboData?.header_image
                      ? state?.comboData?.header_image
                      : filterData?.length && filterData[0]?.header_image
                      ? filterData[0].header_image
                      : aboutUsBanner
                  }
                  alt=""
                />
              </div>
            </section>

            <section className="breadcrumbSec">
              <div className="container">
                <div className="breadcrumb">
                  <div className="breadcrumb__item">
                    <Link
                      className="breadcrumb__link"
                      onClick={() => navigate(-1)}
                    >
                      Back to result
                    </Link>
                  </div>
                </div>
              </div>
            </section>

            <section className="programDetialSec">
              <div className="container">
                {mappedData?.map((val) => {
                  const getAgeLabel = (age) => {
                    if (age > 2500) {
                      return "and up";
                    }
                    if (age > 23) {
                      const years = Math.floor(age / 12);
                      const months = age % 12;
                      return `${years} ${months ? "." : ""} ${
                        months ? months : ""
                      } Years`;
                    } else {
                      return `${age} Months`;
                    }
                  };

                  const getImageSrc = () => {
                    if (filterData?.length && filterData[0]?.image) {
                      return filterData[0].image;
                    } else if (state?.comboData?.image) {
                      return state.comboData.image;
                    } else {
                      return programDetailImg1;
                    }
                  };

                  const getDescription = () => {
                    if (filterData?.[0]?.description2) {
                      return filterData[0].description2;
                    } else if (state?.comboData?.description2) {
                      return state.comboData.description2;
                    } else {
                      return `It’s the best of both worlds: paint, draw and color in our art classroom, then run, 
            jump and climb through the Tiny Tot mini gym. PEP is a preschool-like experience that 
            combines learning, fun and physical development, giving your toddler 35 minutes in a play-based
            classroom environment, followed by 35 minutes in our state-of-the-art gym space.
            The program is designed to be your child’s first classroom experience, introducing them to the structure 
            of school while cultivating their independent spirit and sparking their creative curiosity to learn and
            explore. Classes are theme-based and integrate literacy, sensory exploration, musical theatre, dramatic 
            play and art, always with an emphasis on imagination and experimentation.`;
                    }
                  };

                  return (
                    <div className="programCard" key={val?.id}>
                      <h1 className="title title--extrabold class_det_head">
                        {val?.umbrella_name
                          ? val?.umbrella_name
                          : val?.name || "-"}
                        <span className="timeperiod">
                          {getAgeLabel(val?.min_age)}{" "}
                          {val?.max_age < 2500 && "-"}{" "}
                          {getAgeLabel(val?.max_age)}
                        </span>
                      </h1>
                      <div
                        className="picture class_detail_image_container"
                        style={{ float: "left" }}
                      >
                        <img
                          className="picture__img"
                          src={getImageSrc()}
                          alt="CLASS IMAGES"
                        />
                        <p className="class_details_shortText_container">
                          {getDescription()}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* STUDENT AND CLASS LIST SCHEUDLES  */}
              <div className="classAvailability_container_fluid">
                <div className="classAvailability">
                  <div className="classAvailability__header">
                    <h2
                      className="title title--h4"
                      style={{ background: "none", fontSize: "2.5rem" }}
                    >
                      Add Student:
                    </h2>
                    <button className="btn btn--primary" onClick={clickHandler}>
                      + New Student
                    </button>
                  </div>

                  {studentList()}

                  {classAvailabilityList()}
                </div>
              </div>
            </section>

            {/* LOWR SECTION CAROUSRL   */}

            <section className="ourClassesSec">
              <div className="container">
                {selectedClassProgram?.length > 0 ? (
                  <>
                    <h3 className="title">
                      More Classes Of This{" "}
                      {temp?.search?.split("=")?.pop() === "true"
                        ? "Program"
                        : "Age"}
                    </h3>
                    <div className="classesList">
                      <OwlCarousel
                        ref={owlRef}
                        responsive={OptionsClassDetailsPage}
                        className="galleryGrid owl-carousel"
                        responsiveclassname="true"
                        arrows="true"
                        responsiveRefreshRate={0}
                        margin={50}
                        dots={true}
                        nav={true}
                        autoplay={500}
                        smartSpeed={500}
                      >
                        {selectedClassProgram?.map((val, index) => {
                          return (
                            <div
                              className="classesCard"
                              onClick={() => moreInfoHandler(val.id, val)}
                              style={{ cursor: "pointer" }}
                              key={index}
                            >
                              <div className="picture">
                                <img
                                  className="galleryGrid__card__img"
                                  src={
                                    val?.image ? val?.image : programDetailImg1
                                  }
                                  alt="clas img"
                                />
                              </div>
                              <h1 className="carasoul_classname_value">
                                {val?.name}
                              </h1>
                            </div>
                          );
                        })}
                      </OwlCarousel>
                    </div>
                  </>
                ) : (
                  <h3 className="title" style={{ textAlign: "center" }}>
                    No Class Found
                  </h3>
                )}
              </div>
            </section>
          </div>

          {editingStudentId && (
            <EditStudentModal
              studentData={getStudentData.find(
                (student) => student.id === editingStudentId
              )}
              onSave={saveStudentChanges}
              onClose={closeEditModal}
              editingStudentId={editingStudentId}
              setSuccessModal={setSuccessModal}
            />
          )}

          {successModal ? (
            <SuccesModal setSuccessModal={setSuccessModal} />
          ) : null}
        </div>
      )}
    </>
  );
}

export default ClassDetails;
