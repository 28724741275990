import React from "react";
import { useEffect } from "react";
import { ColorRing } from "react-loader-spinner";

function Overlay() {
  useEffect(() => {
    document.getElementById("root").style.overflowY = "hidden";
    document.getElementById("root").style.height = "100vh";

    return () => {
      document.getElementById("root").style.overflow = "auto";
      document.getElementById("root").style.height = "100%";
    };
  }, []);

  return (
    <>
      <div>
        <div
          style={{
            position: "absolute",
            top: "0%",
            left: "50%",
            transform: "translate(-50%, 0%)",
            background: "#eee",
            height: "100vh",
            width: "100vw",
            zIndex: 99999,
            opacity: 0.3,
            overflowY: "none",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 99999,
            opacity: 0.7,
          }}
        >
          <ColorRing
            visible={true}
            height="120"
            width="120"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperclassName="blocks-wrapper"
            colors={["#EA519C", "#EA519C", "#EA519C", "#EA519C", "#EA519C"]}
          />
        </div>
      </div>
    </>
  );
}

export default Overlay;
