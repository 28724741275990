import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./parent-invoice.css";
// import logo from "../../assets/images/logo.png";
import { ReactComponent as GymtimeLogo } from "../../../parent-assets/images/gymtime_logo_2024.svg";
import {
  dateFormatter,
  getCurrentDate,
  timeFormatter,
} from "../../../../Admin/adminUtility";
import html2pdf from "html2pdf.js";

function ParentInvoiceComponent() {
  const [apiData, setApiData] = useState([]);
  const [totalClassAmount, setTotalClassAmount] = useState(0);
  const [state, setState] = useState(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const data = localStorage.getItem("INVOICE_DATA");
    if (data) {
      const parsedData = JSON.parse(data);
      setState(parsedData);
      setApiData(parsedData.data);
    } else {
    }
  }, []);

  useEffect(() => {
    const calculateTotalDue = () => {
      let totalDue = 0;
      apiData?.forEach((val) => {
        totalDue += val?.rate || 0;
      });
      setTotalClassAmount(totalDue);
    };

    if (apiData.length > 0) {
      calculateTotalDue();
    }
  }, [apiData]);

  const generatePDF = async () => {
    try {
      if (contentRef.current) {
        const content = contentRef.current;

        // Generate PDF and obtain the ArrayBuffer
        const pdfArrayBuffer = await html2pdf()
          .from(content)
          .outputPdf("arraybuffer");

        // Convert ArrayBuffer to Blob
        const pdfBlob = new Blob([pdfArrayBuffer], { type: "application/pdf" });

        // Create a temporary URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Create a link element
        const downloadLink = document.createElement("a");

        // Set the href attribute to the PDF URL
        downloadLink.href = pdfUrl;

        // Set the download attribute to specify the filename
        downloadLink.download = "Invoice.pdf";

        // Append the link to the document body
        document.body.appendChild(downloadLink);

        // Programmatically trigger the click event to start the download
        downloadLink.click();

        // Clean up: remove the temporary URL and the link element
        URL.revokeObjectURL(pdfUrl);
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      //   toast.error("PDF could not be generated, try again later.");
    }
  };

  return (
    <>
      <div
        className="button__group"
        style={{ marginLeft: "63%" }}
        onClick={() => {
          generatePDF();
        }}
      >
        <button className="adminbtn adminbtn-invoice">Download Now</button>
      </div>
      <div class="invoice-box" ref={contentRef}>
        <div className="logo" style={{ width: "210px" }}>
          {/* <img src={logo} alt="" /> */}
          <GymtimeLogo />
        </div>

        <div className="invoice-flexBox ">
          <div className="col">
            <div>
              <p className="title--h6" style={{ fontWeight: "bold" }}>
                Gymtime Gymnastics Rhythm & Glues
              </p>
              <p>1520 York Avenue</p>
              <p>New York, NY 10028</p>
              <p>Phone: 212.861.7732</p>
              <p>Fax: 212.861.8901</p>
            </div>
          </div>

          <div className="">
            <p
              className="title--h3"
              style={{ fontWeight: "bold", marginRight: "20px" }}
            >
              INVOICE
            </p>

            <p>{new Date().toLocaleDateString()}</p>
          </div>
        </div>

        <div className="flex">
          <div className="to">
            <p style={{ fontWeight: "bold" }}>TO</p>

            <p style={{ fontSize: "13px" }}>
              {apiData?.[0]?.address?.parent_name}
            </p>

            <div style={{ fontSize: "13px" }}>
              <span style={{fontWeight:"600"}}> Address:</span>{" "}
              {apiData?.[0]?.address && (
                <span>
                  {apiData?.[0]?.address?.zip}&nbsp;,{" "}
                  {apiData?.[0]?.address?.to},{apiData?.[0]?.address?.city},{" "}
                  {apiData?.[0]?.address?.state}
                </span>
              )}
            </div>

            {apiData?.[0]?.address?.phone_number && (
              <p style={{ fontSize: "13px" }}>
                Phone:{" "}
                <span style={{ fontSize: "13px" }}>
                  {apiData?.[0]?.address?.phone_number}
                </span>{" "}
              </p>
            )}
          </div>
          <div className="For">
            <p style={{ fontWeight: "bold" }}>FOR</p>
            <p style={{ fontSize: "13px" }}>{apiData?.[0]?.address?.for}</p>
          </div>
        </div>

        <table cellpadding="0" cellspacing="0" className="invoice-table">
          <tr className="table-head" style={{ border: "1px solid white" }}>
            <th
              className="tableTH"
              style={{ width: "30%", border: "1px solid white" }}
            >
              DESCRIPTION
            </th>
            <th style={{ border: "1px solid white" }} className="tableTH">
              DATES
            </th>
            <th style={{ border: "1px solid white" }} className="tableTH">
              RATE
            </th>
            <th style={{ border: "1px solid white" }} className="tableTH">
              AMOUNT PAID
            </th>
            <th style={{ border: "1px solid white" }} className="tableTH">
              DATE PAID
            </th>
          </tr>

          {apiData?.map((val, index) => {
            return (
              <tr key={index} className="row-data">
                <td style={{ fontSize: "13px" }}>
                  {val?.flag ? (
                    <div>
                      {val?.description?.class_name} &nbsp;
                      {val?.description?.schedules?.map((data) => {
                        return (
                          <p style={{ fontSize: "13px" }}>
                            {data?.weekday} : {timeFormatter(data?.start_time)}-
                            {timeFormatter(data?.end_time)}
                          </p>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      <p
                        style={{
                          display: "flex",
                          gap: "3px",
                        }}
                      >
                        Package Name:{" "}
                        <span>{val?.description?.packageName}</span>
                      </p>
                      <p
                        style={{
                          display: "flex",
                          gap: "3px",
                        }}
                      >
                        Birthday Date:
                        <span>{dateFormatter(val?.dates?.birthdate)}</span>
                      </p>
                      <p>
                        Package Details:
                        {val?.description?.addOnPackage?.map((pack) => (
                          <span style={{ fontSize: "11px" }} key={pack}>
                            {pack}
                          </span>
                        ))}
                      </p>
                    </div>
                  )}
                </td>
                <td style={{ fontSize: "12px" }}>
                  {val?.flag ? (
                    <p>
                      {getCurrentDate(val?.dates?.class_start_date)} :
                      {getCurrentDate(val?.dates?.class_end_date)}
                    </p>
                  ) : (
                    <p> {getCurrentDate(val?.dates?.birthdate)}</p>
                  )}
                </td>
                <td style={{ fontSize: "13px" }}>${val?.rate}</td>
                <td style={{ fontSize: "13px" }}>${val?.rate}</td>
                <td style={{ fontSize: "13px" }}>
                  {dateFormatter(val?.date_paid)}
                </td>
              </tr>
            );
          })}
          <tr className="total-row">
            <td>Total</td>
            <td></td>
            <td>$ {totalClassAmount}</td>
            <td>$ {totalClassAmount}</td>
          </tr>
        </table>

        <div className="message">
          <h3
            className="title--h4"
            style={{ fontWeight: "bold", background: "none" }}
          >
            THANK YOU FOR YOUR BUSINESS!
          </h3>
        </div>
        <div className="table-footer">
          {" "}
          <Link
            style={{
              cursor: "pointer",
              color: "00bbe3",
              textDecoration: "underline",
            }}
            onClick={() => {
              window.open("https://www.gymtime.net", "_blank");
            }}
          >
            info@gymtime.net - www.Gymtime.net - 1520 York Ave - new York, NY
            10028
          </Link>{" "}
        </div>
      </div>
    </>
  );
}

export default ParentInvoiceComponent;
