import axios from "axios";
import { GET_ROLLSHEET } from "../pages/adminQueries/RollSheet";

export async function getRollSheetReport(token,selectedClass,startDate,endDate) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_ROLLSHEET,
        variables: {
          attendanceStartDate: startDate ? startDate :"",
          attendanceEndDate: endDate?endDate :"",
          classId: selectedClass
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
