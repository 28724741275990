import React from "react";
import { ReactComponent as DeleteCardIcon } from "../../assets/images/icons/deleteRowIcon.svg";

function ComboClass({
  rows,
  handleAddRow,
  handleDeleteRow,
  handleChange,
  getTeacherData,
  days,
}) {
  return (
    <div className="flexbox" style={{ width: "1100px" }}>
      <table className="campTable">
        <thead>
          <tr>
            <th style={{ minWidth: "120px" }}>Subject</th>
            <th style={{ minWidth: "120px" }}>Select teacher</th>
            <th style={{ minWidth: "120px" }}>Weekday</th>
            <th style={{ minWidth: "120px" }}>Start time</th>
            <th style={{ minWidth: "120px" }}>End time</th>
            <th style={{ minWidth: "60px" }}>Max Students</th>
            <th style={{ minWidth: "20px" }}></th>
          </tr>
        </thead>

        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              {/* SUBJECT  */}
              <td>
                <input
                  style={{ minWidth: "120px" }}
                  className="adminform__input"
                  type="text"
                  placeholder="Subject name"
                  value={row?.subject}
                  onChange={(e) =>
                    handleChange(index, e.target.name, e.target.value)
                  }
                  name="subject"
                />
              </td>
              {/* TEACHER */}
              <td style={{ minWidth: "120px" }}>
                <select
                  style={{ minWidth: "150px" }}
                  name="teacher"
                  id="teacher"
                  className="adminform__select"
                  onChange={(event) =>
                    handleChange(index, "teacher", event.target.value)
                  }
                  value={row.teacher}
                >
                  <option value="" selected disabled>
                    Select teacher
                  </option>
                  {getTeacherData?.map((item, index) => (
                    <option value={item?.id} key={index}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </td>
              {/* WEEKDAY  */}
              <td>
                <select
                  style={{ minWidth: "120px" }}
                  name="weekday"
                  className="adminform__select"
                  onChange={(e) =>
                    handleChange(index, e.target.name, e.target.value)
                  }
                  value={row.weekday}
                >
                  <option value="" selected disabled>
                    Select Weekday
                  </option>
                  {days?.map((item, index) => (
                    <option value={item?.label} key={index}>
                      {item?.value}
                    </option>
                  ))}
                </select>
              </td>
              {/* START TIME  */}
              <td style={{ minWidth: "100px" }}>
                <input
                  style={{ minWidth: "120px" }}
                  className="adminform__input"
                  type="time"
                  placeholder="Week name"
                  value={row?.startTime}
                  onChange={(e) =>
                    handleChange(index, e.target.name, e.target.value)
                  }
                  name="startTime"
                />
              </td>
              {/* END TIME  */}
              <td style={{ minWidth: "100px" }}>
                <input
                  style={{ minWidth: "120px" }}
                  className="adminform__input"
                  type="time"
                  placeholder="Week name"
                  value={row?.endTime}
                  onChange={(e) =>
                    handleChange(index, e.target.name, e.target.value)
                  }
                  name="endTime"
                />
              </td>

              {/* Max Strnght  */}
              <td style={{ minWidth: "100px" }}>
                <input
                  style={{ minWidth: "100px" }}
                  className="adminform__input"
                  type="text"
                  name="maxStudents"
                  value={row.maxStudents}
                  onChange={(e) =>
                    handleChange(index, e.target.name, e.target.value)
                  }
                  placeholder="Max Students"
                />
              </td>
              <td style={{ minWidth: "20px" }}>
                <button onClick={() => handleDeleteRow(index)}>
                  <DeleteCardIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ComboClass;
