import axios from "axios";
import {
  GET_CAMP_CLASS_DETAIL_QUERY,
  GET_CLASS_CAMP_QUERY,
} from "../Queries/CampClassDetailsQuery";

export async function getCampDetailClassDetails(token, programId, isHalf) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_CAMP_CLASS_DETAIL_QUERY,
        variables: {
          programId: 175,
          isHalf: isHalf,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {}
}

export async function getCampVersionDetails(classId, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_CLASS_CAMP_QUERY,
        variables: {
          classId: classId,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data?.getWeeklyClass;
  } catch (error) {
    return error;
  }
}
