import axios from "axios";
import {
  GET_STUDENTS,
  GET_ALL_STUDENT_ENROLLMENT,
} from "../Queries/studenQueries";
import { toast } from "react-toastify";
import store from "../../store/store";
import { openSessionModal } from "../../reducers/sessionHandler";

export async function getStudentsByClass(classId, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_STUDENTS,
        variables: {
          classId,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data?.data?.getStudents;
  } catch (error) {
    console.error("Error fetching student data from GraphQL", error);
    throw error;
  }
}

export const deleteStudent = async (deleteId, accessToken) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_BASE_URL + `/student/delete/${deleteId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response.data;
  } catch (error) {
    console.error("Error deleting student:", error);
    throw error;
  }
};

export async function getAllStudentByEnrollment(token) {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_ALL_STUDENT_ENROLLMENT,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching student data from GraphQL", error);
    throw error;
  }
}

export async function getAllStudent(token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_STUDENTS,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data;
  } catch (error) {
     
  }
}

export async function createStudent(studentData, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/student/create",
      studentData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data;
  } catch (error) {
     
  }
}

export async function updateStudentAPI(studentForm, studentID, token) {
  try {
    const response = await axios.put(
      process.env.REACT_APP_BASE_URL + `/student/update/${studentID}`,
      studentForm,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.error, {
                                    toastId: "error",
                                  });
    return error;
  }
}
