import React from "react";
import successImg from "../../parent-assets/images/DONE.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openLoginModal } from "../../../reducers/sessionHandler";

function SuccesModal({ setSuccessModal }) {
  const content = useSelector((state) => state?.successModalContent);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(openLoginModal(false));
      setSuccessModal(false);
    }, 1500);
  }, []);

  return (
    <>
      <div
        className="modal modal--successModal modal--open"
        id="SuccessMessageModal"
        role="dialog"
      >
        <div className="modal__dialog modal__dialog--lg">
          <div className="modal__content">
            <div className="modal__body">
              <div className="pic">
                <img className="pic__img" src={successImg} alt="Success" />
              </div>
              <div className="" style={{ marginTop: "-35px" }}>
                <h2
                  className="title succes-modal-title"
                  style={{ color: "#c3d940" }}
                >
                  {content.successModalContent}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SuccesModal;
