// sessionHandler.js
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  sessionExpired: false,
  isModalOpen: false,
  isLoginModalOpen: false,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    openSessionModal: (state, action) => {
      state.isModalOpen = action.payload;
    },

    openLoginModal: (state, action) => {
      state.isLoginModalOpen = action.payload;
    },
  },
});

export const { openSessionModal, openLoginModal } = sessionSlice?.actions;
export default sessionSlice.reducer;
