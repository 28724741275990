import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { termsAndConditions } from "../api/contentApi";

function Terms() {
  const navigate  = useNavigate()
  return (
    <>
      <div className="contentwraper">
        <section className="siteBanner" style={{ background: "#C3D940" }}>
          <div className="siteBanner__overlay">
            <img
              className="siteBanner__img"
              src={termsAndConditions.termBannerImage.url}
              alt="term-banner-img"
            />
          </div>
        </section>
        <section className="breadcrumbSec">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb__item">
                <Link className="breadcrumb__link" onClick={() => navigate(-1)}>
                  Back to results
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="commonSec">
          <div className="container">
            <div className="shortText">
              <h4 style={{ color: "#5E5F5F", marginBottom: "20px" }}>
                I.{termsAndConditions.PrivacyList.List1.title}
              </h4>
              <p>
                The information provided on this Site is for educational and
                entertainment purposes only. Use of this Site is subject to the
                terms of our Privacy Policy, which is hereby incorporated into
                and made part of this Agreement. Please carefully review our
                Privacy Policy. By using this Site, you agree to be bound by the
                terms of our Privacy Policy. We reserve the right, and you
                authorize us, to use information regarding your use of this Site
                and any other personal information provided by you in accordance
                with our Privacy Policy.
              </p>

              <h4 style={{ color: "#5E5F5F", marginBottom: "20px" }}>
                II. Copyright; Trademarks
              </h4>
              <p>
                You acknowledge that all materials on the Site, including the
                Site’s design, graphics, text, sounds, pictures, software and
                other files and the selection and arrangement thereof
                (collectively, “Materials”), are the property of Company or its
                licensors, and are subject to and protected by United States and
                international copyright and other intellectual property laws and
                rights. All rights to Materials not expressly granted in this
                Agreement are reserved to their respective copyright owners.
                Except as expressly authorized by this Agreement or on the Site,
                you may not copy, reproduce, distribute, republish, download,
                perform, display, post, transmit, exploit, create derivative
                works or otherwise use any of the Materials in any form or by
                any means, without the prior written authorization of Company or
                the respective copyright owner. Company authorizes you to view
                and download the Materials only for personal, non-commercial
                use, provided that you keep intact all copyright and other
                proprietary notices contained in the original Materials. You may
                not modify or adapt the Materials in any way or otherwise use
                them for any public or commercial purposes. The trademarks,
                service marks, trade names, trade dress and logos (collectively,
                “Marks”) contained or described on this Site (including, without
                limitation, GYMTIME) are the sole property of Company and/or its
                licensors and may not be copied, imitated or otherwise used, in
                whole or in part, without the prior written authorization of
                Company and/or its licensors. In addition, all page headers,
                custom graphics, button icons and scripts are Marks of Company
                and may not be copied, imitated or otherwise used, in whole or
                in part, without the prior written authorization of Company.
                Company will enforce its intellectual property rights to the
                fullest extent of the law.
              </p>

              <h4 style={{ color: "#5E5F5F", marginBottom: "20px" }}>
                III. Links; Third Party Websites{" "}
              </h4>
              <p>
                Links on the Site to third party websites are provided only as a
                convenience to you. If you use these links, you will leave the
                Site. Company does not control or endorse any such third party
                websites. You agree that Company and its Affiliated Parties will
                not be responsible or liable for any content, goods or services
                provided on or through these outside websites or for your use or
                inability to use such websites. You will use these links at your
                own risk. You are advised that other websites on the Internet,
                including third party websites linked from this Site, might
                contain material or information that some people may find
                offensive or inappropriate; or that is inaccurate, untrue,
                misleading or deceptive; or that is defamatory, libelous,
                infringing of others’ rights or otherwise unlawful. Company
                expressly disclaims any responsibility for the content,
                legality, decency or accuracy of any information, and for any
                products and services, that appear on any third party website.
                Without limiting the foregoing, your correspondence or business
                dealings with, or participation in promotions of, advertisers
                found on or through the use of the Site, including payment for
                and delivery of related goods or services, and any other terms,
                conditions, warranties or representations associated with such
                dealings, are solely between you and such advertiser. You agree
                that Company and its Affiliated Parties shall not be responsible
                or liable for any loss or damage of any sort incurred as the
                result of any such dealings or as the result of the presence of
                such advertisers on the Site.
              </p>

              <h4 style={{ color: "#5E5F5F", marginBottom: "20px" }}>
                IV.Disclaimer; Limitation of Liability
              </h4>
              <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT:</p>
              <p>
                YOUR USE OF THE SITE IS AT YOUR SOLE RISK. THE SITE AND THE
                MATERIALS CONTAINED HEREIN ARE PROVIDED ON AN “AS IS” AND “AS
                AVAILABLE” BASIS. EXCEPT AS OTHERWISE EXPRESSLY PROVIDED IN THIS
                AGREEMENT, COMPANY AND ITS AFFILIATED COMPANIES AFFILIATED
                COMPANIES AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES
                AND OTHER REPRESENTATIVES (COLLECTIVELY, “COMPANY AND ITS
                AFFILIATED PARTIES”) EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY
                KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO
                THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE AND NON-INFRINGEMENT.
              </p>
              <p>
                EXCEPT AS OTHERWISE EXPRESSLY PROVIDED IN THIS AGREEMENT,
                COMPANY AND ITS AFFILIATED PARTIES MAKE NO WARRANTY THAT (I) THE
                SITE WILL MEET YOUR REQUIREMENTS, (II) THE SITE WILL BE
                UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS
                THAT MAY BE OBTAINED FROM THE USE OF THE SITE WILL BE ACCURATE
                OR RELIABLE, (IV) THE QUALITY OF ANY SERVICES, INFORMATION, OR
                OTHER MATERIAL OBTAINED BY YOU THROUGH THE SITE WILL MEET YOUR
                EXPECTATIONS, AND (V) ANY ERRORS IN THE SITE WILL BE CORRECTED.
                ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF
                THE SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL
                BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
                LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH
                MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
                OBTAINED BY YOU FROM COMPANY AND ITS AFFILIATED PARTIES OR ON OR
                THROUGH THE SITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED
                IN THIS AGREEMENT.
              </p>
              <p>
                COMPANY AND ITS AFFILIATED PARTIES SHALL NOT UNDER ANY
                CIRCUMSTANCES BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING OUT
                OF, IN CONNECTION WITH OR RELATING TO THE USE OF OR INABILITY TO
                USE THE SITE, INCLUDING ANY LIABILITY (I) AS A PUBLISHER OF
                INFORMATION, (II) FOR ANY INCORRECT OR INACCURATE INFORMATION,
                (III) FOR ANY UNAUTHORIZED ACCESS TO OR DISCLOSURE OF YOUR
                TRANSMISSIONS OR DATA, (IV) FOR STATEMENTS OR CONDUCT OF ANY
                THIRD PARTY ON THE SITE, OR (VII) FOR ANY OTHER MATTER RELATING
                TO THIS SITE OR ANY THIRD PARTY WEBSITE. THIS IS A COMPREHENSIVE
                LIMITATION OF LIABILITY THAT APPLIES TO ALL DAMAGES OF ANY KIND,
                INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL OR
                CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS,
                LOSS OF PROFITS, LOSS OF GOOD WILL, LOSS OF USE, LOSS OF DATA,
                COST OF PROCURING SUBSTITUTE GOODS, SERVICES OR INFORMATION,
                LITIGATION OR THE LIKE), WHETHER BASED ON BREACH OF CONTRACT,
                BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE,
                EVEN IF AN INDIVIDUAL ADVISES OF THE POSSIBILITY OF SUCH
                DAMAGES. THE LIMITATIONS OF LIABILITY SET FORTH HEREIN ARE
                FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN COMPANY
                AND YOU. THE INFORMATION AND SERVICES OFFERED ON AND THROUGH THE
                SITE WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS.
                NOTWITHSTANDING THE FOREGOING, THE SOLE AND ENTIRE MAXIMUM
                LIABILITY OF THE COMPANY AND ITS AFFILIATED PARTIES FOR ANY
                REASON, AND YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY CAUSE OR
                CLAIM WHATSOEVER, SHALL BE LIMITED TO THE AMOUNT PAID BY YOU TO
                COMPANY ON THIS SITE.
              </p>
              <p>
                SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF CERTAIN
                WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
                CERTAIN TYPES OF DAMAGES. ACCORDINGLY, SOME OF THE ABOVE
                DISCLAIMERS AND LIMITATIONS MAY NOT APPLY TO YOU. IN SUCH
                JURISDICTIONS, THE EXCLUSIONS AND LIABILITY ARE LIMITED TO THE
                FULLEST EXTENT PERMITTED BY LAW.
              </p>

              <h4 style={{ color: "#5E5F5F", marginBottom: "20px" }}>
                V. Indemnification
              </h4>
              <p>
                You agree to indemnify, defend and hold harmless Company and its
                Affiliated Parties against all claims, demands, causes of
                action, losses, expenses, damages and costs, including any
                reasonable attorneys’ fees, resulting or arising from or
                relating to your use of or conduct on the Site, any activity
                related to your use of the Site, any material that you submit
                to, post on or transmit through the Site, your breach of this
                Agreement, your infringement or violation of any rights of
                another, or termination of your access to the Site.
              </p>

              <h4 style={{ color: "#5E5F5F", marginBottom: "20px" }}>
                VI. Site Usage; Termination of Usage
              </h4>
              <p>
                If you provide information on this Site, you agree to (a)
                provide true, accurate, current and complete information about
                yourself as prompted by the Site, and (b) as permitted, maintain
                and promptly update such information to keep it true, accurate,
                current and complete. If you provide any information that is
                false, inaccurate, outdated or incomplete, or Company has
                reasonable grounds to suspect that such information is false,
                inaccurate, outdated or incomplete, Company has the right to
                suspend or terminate your access to the Site and prohibit any
                and all current or future use of the Site (or any portion
                thereof) by you.
              </p>
              <p>
                You agree to use this Site only for lawful purposes, and that
                you are responsible for your use of and communications on the
                Site. You agree not to post on or transmit through this Site any
                unlawful, infringing, threatening, harassing, defamatory,
                vulgar, obscene, profane, indecent, offensive, hateful or
                otherwise objectionable material of any kind, including any
                material that encourages criminal conduct or conduct that would
                give rise to civil liability, infringes others’ intellectual
                property rights or otherwise violates any applicable local,
                state, national or international law. You agree not to use this
                Site in any manner that interferes with its normal operation or
                with any other user’s use and enjoyment of the Site.
              </p>

              <p>
                Company makes no representation that Materials contained on the
                Site are appropriate or available for use in jurisdictions
                outside the United States, or that this Agreement complies with
                the laws of any other country. Visitors who use the Site and
                reside outside the United States do so on their own initiative
                and are responsible for compliance with all laws, if and to the
                extent local laws are applicable. You agree that you will not
                access this Site from any territory where its contents are
                illegal, and that you, and not Company and its Affiliated
                Parties, are responsible for compliance with applicable local
                laws.
              </p>
              <p>
                This Agreement is effective unless and until terminated by
                either you or Company. You may terminate this Agreement at any
                time, provided that you discontinue any further use of this
                Site. Company also may terminate or suspend this Agreement, at
                any time, without notice, and accordingly deny you access to the
                Site, if in Company’s sole discretion you fail to comply with
                any term or provision of this Agreement or your use is harmful
                to the interests of another user or Company and its Affiliated
                Parties. Upon any termination of the Agreement by either you or
                us, you must promptly destroy all materials downloaded or
                otherwise obtained from this Site, as well as all copies of such
                materials, whether made under the terms of use or otherwise.
              </p>
              <p>
                Company reserves the right at any time and from time to time to
                modify or discontinue, temporarily or permanently, the Site (or
                any part thereof) with or without notice. Except as otherwise
                expressly stated in this Agreement, you agree that Company and
                its Affiliated Parties shall not be liable to you or to any
                third party for any modification, suspension or discontinuation
                of the Site.
              </p>

              <p>
                You agree that Company may terminate or suspend your access to
                all or part of this Site, without notice, for any conduct that
                Company, in its sole discretion, believes is in violation of
                this Agreement or any applicable law or is harmful to the
                interests of another user or Company and its Affiliated Parties.
              </p>

              <p>
                Sections II, V through VIII, XI through XIV, and XVI shall
                survive any termination of this Agreement.
              </p>

              <h4 style={{ color: "#5E5F5F", marginBottom: "20px" }}>
                VII. Rules for Sweepstakes, Contests, Raffles, Surveys and
                Similar Promotions
              </h4>
              <p>
                Any sweepstakes, contests, raffles, surveys, or similar
                promotions made available through the Site will be governed by
                specific rules that are separate from and in addition to this
                Agreement. By participating in any such sweepstakes, contest,
                raffle, survey, or similar promotion, you will become subject to
                those rules, which may vary from this Agreement set forth
                herein. Company urges you to read the applicable rules, which
                are linked from the particular activity, and to review our
                Privacy Policy, which in addition to this Agreement, governs any
                information you submit in connection with such activities.
              </p>

              <p>
                By participating in any such sweepstakes, contest, raffle,
                survey, or similar promotion, you will become subject to those
                rules, which may vary from this Agreement set forth herein.
                Company urges you to read the applicable rules, which are linked
                from the particular activity, and to review our Privacy Policy,
                which in addition to this Agreement, governs any information you
                submit in connection with such activities.
              </p>

              <h4 style={{ color: "#5E5F5F", marginBottom: "20px" }}>
                VIII. Filtering
              </h4>
              <p>
                Pursuant to 47 U.S.C. Section 230 (d) as amended, we hereby
                notify you that parental control protections (such as computer
                hardware, software or filtering services) are commercially
                available that may assist you in limiting access to material
                that is harmful to minors. Information identifying current
                providers of such protections is available at
                http://www.staysafe.org.
              </p>

              <h4 style={{ color: "#5E5F5F", marginBottom: "20px" }}>
                IX. Applicable Law; Jurisdiction; Dispute Resolution
              </h4>
              <p>
                This Agreement shall be governed by and construed in accordance
                with the laws of the State of New York in the United States,
                without regard to its choice of law provisions. You agree to
                submit to personal jurisdiction in the State of New York and
                further agree that any cause of action arising from or relating
                to the use of this Site or this Agreement shall be brought
                exclusively in the Federal or State Courts residing in New York.
              </p>
              <p>
                TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOU AND COMPANY
                HEREBY WAIVE THE RIGHT TO A TRIAL BY JURY IN ANY PROCEEDING OR
                LITIGATION BROUGHT AGAINST THE OTHER WITH RESPECT TO THIS
                AGREEMENT OR THE SITE. IN ADDITION, NEITHER YOU NOR COMPANY
                SHALL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST
                OTHER SITE USERS, OR LITIGATE ANY CLAIM AS A REPRESENTATIVE OR
                CLASS ACTION OR IN A PRIVATE ATTORNEY GENERAL CAPACITY.
              </p>

              <h4 style={{ color: "#5E5F5F", marginBottom: "20px" }}>
                X. Severability; Interpretation
              </h4>
              <p>
                If any provision of this Agreement shall be deemed unlawful,
                void, or for any reason unenforceable by a court of competent
                jurisdiction, the validity and enforceability of any remaining
                provisions shall not be affected. When used in this Agreement,
                the term “including” shall be deemed to be followed by the words
                “without limitation.”
              </p>
              <p>
                TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOU AND COMPANY
                HEREBY WAIVE THE RIGHT TO A TRIAL BY JURY IN ANY PROCEEDING OR
                LITIGATION BROUGHT AGAINST THE OTHER WITH RESPECT TO THIS
                AGREEMENT OR THE SITE. IN ADDITION, NEITHER YOU NOR COMPANY
                SHALL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST
                OTHER SITE USERS, OR LITIGATE ANY CLAIM AS A REPRESENTATIVE OR
                CLASS ACTION OR IN A PRIVATE ATTORNEY GENERAL CAPACITY.
              </p>

              <h4 style={{ color: "#5E5F5F", marginBottom: "20px" }}>
                XI. Entire Agreement
              </h4>
              <p>
                This Agreement constitutes the entire and only Agreement between
                Company and each user of this Site with respect to the subject
                matter of this Agreement and supersedes any and all prior or
                contemporaneous Agreements, representations, warranties and
                understandings, written or oral, with respect to the subject
                matter of this Agreement.
              </p>

              <h4 style={{ color: "#5E5F5F", marginBottom: "20px" }}>
                XII. Miscellaneous
              </h4>
              <p>
                The failure of Company and its Affiliated Parties to insist upon
                strict adherence to any term of this Agreement shall not
                constitute a waiver of such term and shall not be considered a
                waiver or limit that party’s right thereafter to insist upon
                strict adherence to that term or any other term of this
                Agreement.
              </p>
              <p>
                You agree that regardless of any statute or law to the contrary,
                any claim of cause of action arising from or relating to use of
                this Site or this Agreement must be filed within one (1) year
                after such claim or cause of action arose, or will be forever
                barred. The “Disclaimer; Limitation of Liability” provisions of
                this Agreement are for the benefit of Company and its Affiliated
                Parties as defined herein, and each of these individuals or
                entities shall have the right to assert and enforce these
                provisions directly against you on its own behalf.
              </p>

              <h4 style={{ color: "#5E5F5F", marginBottom: "20px" }}>
                XIII. Contact Information
              </h4>
              <p>
                If you have any comments, questions, or complaints regarding
                this Agreement, or wish to report any violation of this
                Agreement, please contact us.
              </p>

              <h4
                style={{
                  fontWeight: "600",
                  color: "#5E5F5F",
                  marginBottom: "20px",
                }}
              >
                Gymtime <br /> 1520 York Avenue <br /> New York, NY 10028
              </h4>
              <p>
                We will address any issue to the best of our abilities as soon
                as possible.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Terms;
