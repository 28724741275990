import React from "react";
import Header from "../components/Header";
import DeleteUser from "../components/UserModals/DeleteModal";
import { useState } from "react";
import Table from "../components/TableComponent/Table";
import { useDispatch } from "react-redux";
import NavigationComponent from "../components/NavigationComponent";
import { useEffect } from "react";
import { debounce } from "lodash";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { setLoading } from "../../reducers/loadingSlice";
import AddClient from "../components/ClientsModal/AddClient";
import {
  getClients,
  deleteClient,
  getWarningForClients,
} from "../adminApi/clients";

import { getUsers_Comments, getUsers_Students } from "../adminApi/users";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { dateFormatter, timeFormatter } from "../adminUtility";
import { getMakeUpClassTableData } from "../adminApi/makeUpClassApi";
import AddNewMakeUpClass from "../components/Makeup/AddNewMakeUpClass";
import DeleteModal from "../components/UserModals/DeleteModal";
import { ReactComponent as SearchIcon } from "../assets/images/icons/search.svg";
import EditMakeupClass from "../components/Makeup/EditMakeupClass";
import {
  clientCommentHeader,
  clientSubHeader,
  clientTableHeader,
  makeupTableHeader,
} from "../tableHeaderComponents";
function Categories() {
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
  const [editClient, setEditClient] = useState(false);
  const [categoryData, setCategoryData] = useState("");
  const [addNewModal, setAddNewModal] = useState(false);
  const [clients, setClients] = useState();
  const [dataEdit, setDataEdit] = useState();
  const [totalPages, setTotalPages] = useState();
  const [temp, setTemp] = useState(1);
  const [formData, setFormData] = useState([]);
  const [currentPage, setCurrentPage] = useState();
  const [totalCount, setTotalCount] = useState();
  const [openDeleteMakeUp, setOpenDeleteMakeup] = useState();
  const [makeUpClassStatus, setMakeUpStatus] = useState();
  const [selectedStudentID, setSelectedStudentID] = useState();
  const [classMakeUpData, setClassMakeUpData] = useState();
  const [openMakeUpClass, setOpenMakeUpClass] = useState(false);
  const [deleteMakeUpID, setDeleteMakeupID] = useState();
  const [deleteMessage, setDeleteMessage] = useState();
  const [sortKey, setSortKey] = useState({
    sortId: null,
    isAsc: false,
  });
  const [selectedClassId, setSelectedClassId] = useState();
  const page = useSelector((state) => state?.adminSlice?.adminModalContent);
  const [pageLimit, setPageLimit] = useState("10");
  const dispatch = useDispatch();
  const [loadingState, setLoadingState] = useState(false);
  const role = useSelector((state) => state?.userRole?.RoleId);
  const [isClassEdited, setIsClassEdited] = useState(false);
  const [editMakeupClass, setEditMakeupClass] = useState({
    isOpenMakeup: false,
    value: "",
  });

  // All Client Table Data
  async function fetchData() {
    try {
      setLoadingState(true);
      const token = localStorage.getItem("access_token");
      dispatch(setLoading(true));
      const response = await getClients(
        token,
        formData,
        isClassEdited ? (page == 0 ? 0 : page) : page,
        sortKey,
        pageLimit
      );
      dispatch(setLoading(false));
      setTotalPages(response.totalPages);
      setTotalCount(response?.totalCount);
      setCurrentPage(response?.currPage);
      const modifiedData = [...response?.data]?.map((client, index) => {
        return {
          data: [
            client?.id ? client?.id : "-",
            client?.parent1_first_name + " " + client?.parent1_last_name,
            client?.parent1_email ? client?.parent1_email : "-",
            client?.parent1_cell ? client?.parent1_cell : "-",
            client?.parent2_first_name ? client?.parent2_first_name : "-",
            client?.parent2_email ? client?.parent2_email : "-",
            client?.parent2_cell ? client?.parent2_cell : "-",
          ],
          item: client,

          onDeletePress: async () => {
            try {
              const response = await getWarningForClients(token, client.id);
              setDeleteMessage(response?.data);
            } catch (error) {}
            setCategoryData(parseInt(client.id));
            setDeleteCategoryModal(true);
          },
          onEditPress: () => {
            setDataEdit(client);
            setEditClient(true);
          },
        };
      });
      setClients(modifiedData);
      setLoadingState(false);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error fetching data:", error);
    }
  }

  const handleSearch = (formData) => {
    fetchData(formData);
  };

  useEffect(() => {
    fetchData();
  }, [page, sortKey, pageLimit]);

  const debouncedFetchData = debounce(fetchData, 2000);

  useEffect(() => {
    const pattern = /[^ ]/;

    if (pattern.test(formData.name) || formData.name?.length === 0) {
      if (formData.name !== undefined) {
        const timeoutId = setTimeout(() => {
          debouncedFetchData(fetchData);
        }, 900);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [formData.name]);

  const handleAddSearch = () => {
    setTemp((prevTemp) => prevTemp + 1);
  };

  const handleAdditionalFieldsChange = (index, key, value) => {
    const updatedData = [...formData];

    while (updatedData.length <= index) {
      updatedData.push({ selectUser: "", name: "" });
    }

    if (value !== updatedData[index][key]) {
      updatedData[index]["name"] = "";
    }

    updatedData[index][key] = value;
    setFormData(updatedData);
    fetchData(formData);
  };

  const renderAdditionalFields = () => {
    const additionalFields = [];
    for (let i = 0; i < temp; i++) {
      additionalFields.push(
        <div
          key={i}
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            width: "auto",
            flexWrap: "wrap",
          }}
        >
          <div key={i} className="adminform__group">
            <select
              name={`selectUser_${i}`}
              id={`selectUser_${i}`}
              className="adminform__select"
              onChange={(e) =>
                handleAdditionalFieldsChange(i, "selectUser", e.target.value)
              }
              value={formData[i]?.selectUser}
            >
              <option value="" selected disabled>
                Search
              </option>
              <option value="clientId">ClientId</option>
              <option value="parentName">Parent Name</option>
              <option value="studentName">Student Name</option>
              <option value="studentId">Student Id </option>
              <option value="cell">Phone Number </option>
              <option value="parentEmail">Parent Email</option>
            </select>
          </div>
          <div key={i} className="adminform__group">
            <input
              type="text"
              disabled={
                temp == 1
                  ? formData?.length === 0
                    ? true
                    : false
                  : temp == 2
                  ? formData?.length < 2
                    ? true
                    : false
                  : true
              }
              className="adminform__input"
              placeholder="Search here"
              name={`name_${i}`}
              onChange={(e) =>
                handleAdditionalFieldsChange(i, "name", e.target.value)
              }
              value={formData[i]?.name}
            />
          </div>
        </div>
      );
    }
    return additionalFields;
  };

  const handleCancelAdditionalFields = (index) => {
    setTemp((prevTemp) => {
      return prevTemp > 1 ? prevTemp - 1 : prevTemp;
    });

    setFormData((prevSearchParams) => {
      const newSearchParams = [...prevSearchParams];
      newSearchParams[index] = { selectUser: "", name: "" };
      const filteredSearchParams = newSearchParams.filter(
        (item) => item.selectUser !== "" || item.name !== ""
      );
      return filteredSearchParams;
    });
  };

  // Makeup Table Data
  const getMakeupClassData = async () => {
    setLoadingState(true);
    try {
      const getData = await getMakeUpClassTableData(
        selectedClassId,
        selectedStudentID?.id,
        sortKey
      );

      setTimeout(() => {
        setLoadingState(false);
      }, 1000);

      if (getData) {
        const modifiedData = getData?.data?.map((classItem) => {
          return {
            data: [
              classItem?.classId,
              `${classItem?.name}-${
                classItem?.schedules?.length
                  ? classItem.schedules
                      .map((val) => {
                        return `(${val?.weekday || "-"}, ${
                          val?.start_time ? timeFormatter(val.start_time) : "-"
                        }:${
                          val?.end_time ? timeFormatter(val.end_time) : "-"
                        })`;
                      })
                      .join(", ")
                  : "-"
              }`,
              classItem?.teacher_name || "-",
              classItem?.date ? dateFormatter(classItem.date) : "-",
              classItem?.min_age,
              classItem?.max_age,
              classItem?.type,
              classItem?.memo,
            ],
            item: classItem,
            tableType: "MAKEUP",
            onEditPress: async () => {
              setEditMakeupClass({
                isOpenMakeup: true,
                value: classItem,
              });
            },
            onDeletePress: async () => {
              const token = localStorage.getItem("access_token");
              try {
                const response = await getWarningForClients(
                  token,
                  classItem?.id
                );
                setDeleteMessage(response?.data);
              } catch (error) {
                console.error(error);
              }
              setDeleteMakeupID(classItem?.id);
              setOpenDeleteMakeup(true);
            },
          };
        });

        setClassMakeUpData(modifiedData);
      }
    } catch (error) {
      console.error(error); // Handle the error appropriately
    } finally {
      setLoadingState(false); // Ensure loading state is reset in case of error
    }
  };

  useEffect(() => {
    if (makeUpClassStatus) {
      getMakeupClassData();
    }
  }, [selectedClassId, selectedStudentID, openMakeUpClass, sortKey]);

  return (
    <>
      <div className="container--component">
        <div className="container--grid">
          <NavigationComponent />

          <div className="Adminpage">
            <Header
              title={makeUpClassStatus ? "Clients>>Makeup" : "Clients"}
              subTitle={
                makeUpClassStatus
                  ? "(Manage Available Makeup's)"
                  : "(Manage Available Clients)"
              }
              setMakeUpStatus={setMakeUpStatus}
            />

            <section className="Adminpage__section">
              {makeUpClassStatus ? (
                ""
              ) : (
                <h2 className="Adminpage__title">Search by:</h2>
              )}
              <div className="Adminpage__head">
                <div className="Adminpage__filter">
                  {makeUpClassStatus ? "" : renderAdditionalFields()}
                  {temp <= 1 ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className="adminform__cancel-button"
                      onClick={() => handleCancelAdditionalFields(1)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}

                  {makeUpClassStatus && (
                    <div>
                      <p className="title" style={{ fontSize: "1.3rem" }}>
                        Makeups/Trials for{" "}
                        {`${selectedStudentID?.first_name} ${selectedStudentID?.last_name}`}
                      </p>
                    </div>
                  )}

                  {makeUpClassStatus ? (
                    ""
                  ) : (
                    <div
                      className=""
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <button
                        className="adminbtn adminbtn-light"
                        onClick={handleAddSearch}
                        disabled={temp === 2 ? true : false}
                        style={{
                          display: temp == 2 ? "none" : "block",
                          marginTop: "10px",
                        }}
                      >
                        and
                      </button>
                      <button
                        className="adminbtn adminbtn-primary"
                        onClick={handleSearch}
                      >
                        <SearchIcon />
                        Search
                      </button>
                    </div>
                  )}
                </div>
                {makeUpClassStatus ? (
                  <button
                    className="adminbtn adminbtn-light export_btn_attendence"
                    id="btnCategory"
                    onClick={async () => {
                      setOpenMakeUpClass(true);
                    }}
                  >
                    + Schedule Makeup Class
                  </button>
                ) : (
                  <button
                    className="adminbtn adminbtn-light"
                    id="btnCategory"
                    onClick={() => {
                      setAddNewModal(!addNewModal);
                    }}
                  >
                    + Add New Client
                  </button>
                )}
              </div>

              <Table
                loadingState={loadingState}
                bodyData={makeUpClassStatus ? classMakeUpData : clients}
                headerData={
                  makeUpClassStatus ? makeupTableHeader : clientTableHeader
                }
                totalPages={totalPages}
                subHeader={clientSubHeader}
                subTable={!makeUpClassStatus && getUsers_Students}
                SubComment={getUsers_Comments}
                commentHeader={clientCommentHeader}
                setSortKey={setSortKey}
                setPageLimit={setPageLimit}
                currentPage={currentPage}
                pageLimit={pageLimit}
                totalCount={totalCount}
                setSelectedClassId={setSelectedClassId}
                tableType={makeUpClassStatus ? "MAKEUP" : "Clients"}
                setSelectedStudentID={setSelectedStudentID}
                setMakeUpStatus={setMakeUpStatus}
                makeUpClassStatus={makeUpClassStatus}
                formData={formData}
              />
            </section>
          </div>
        </div>
      </div>

      {addNewModal ? (
        <AddClient
          setIsClassEdited={setIsClassEdited}
          openAddNewClient={addNewModal}
          setAddNewClient={setAddNewModal}
          data=""
          type="Add"
          setSortKey={setSortKey}
        />
      ) : null}

      {editClient ? (
        <AddClient
          setIsClassEdited={setIsClassEdited}
          openAddNewClient={editClient}
          setAddNewClient={setEditClient}
          data={dataEdit}
          type="Edit"
          fetchData={fetchData}
        />
      ) : null}

      {deleteCategoryModal ? (
        <DeleteUser
          openDeleteModal={deleteCategoryModal}
          setDeleteModal={setDeleteCategoryModal}
          data={categoryData}
          deleteQuery={deleteClient}
          fetchData={fetchData}
          type={"Client"}
          deleteMessage={deleteMessage}
        />
      ) : null}

      {/* CLASS  */}
      {openMakeUpClass ? (
        <AddNewMakeUpClass
          openMakeUpClass={openMakeUpClass}
          setOpenMakeUpClass={setOpenMakeUpClass}
          selectedClassId={selectedClassId}
          selectedStudentID={selectedStudentID}
          getMakeupClassData={getMakeupClassData}
        />
      ) : null}

      {/* Edit Makeup Class  */}
      {editMakeupClass?.isOpenMakeup && (
        <EditMakeupClass
          editMakeupClass={editMakeupClass}
          openEditMakeupClass={editMakeupClass}
          setEditMakeupClass={setEditMakeupClass}
          getMakeupClassData={getMakeupClassData}
        />
      )}

      {openDeleteMakeUp && (
        <DeleteModal
          openDeleteModal={openDeleteMakeUp}
          setDeleteModal={setOpenDeleteMakeup}
          data={deleteMakeUpID}
          makeUpId={deleteMakeUpID}
          fetchData={getMakeupClassData}
          type="MAKEUP"
        />
      )}
    </>
  );
}

export default Categories;
