import React, { useEffect, useState } from "react";
import aboutUsBanner from "../../parent-assets/images/home-banner.jpg";
import djIcon from "../../parent-assets/images/birthday-icons/dj-icon.png";
import { Link, useNavigate } from "react-router-dom";
import PackageComponent from "./PackageComponent";
import ActivityComponent from "./ActivityComponent";
import { getSectionCMS } from "../../../Admin/adminApi/mastermenu";
import AboutUsShimmer from "../../../Admin/components/ShimmerUi/AboutUsShimmer";

function BirthdayMain() {
  // Amount Calucaltion

  const [TotalAmount, setTotalAmount] = useState(0);

  const [sectionData, setSectionData] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showShimmer, setShowShimmer] = useState(false);
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();

  useEffect(() => {
    getPartyImages();
  }, []);

  const getPartyImages = async () => {
    setShowShimmer(true);
    const section = await getSectionCMS(token, 174);
    setSectionData(section);
    setShowShimmer(false);
  };

  useEffect(() => {
    if (token) {
      const sessionTimeOut = setTimeout(() => {
        localStorage.removeItem("access_token");
        navigate("/");
      }, 86400000);
      return () => clearTimeout(sessionTimeOut);
    }
  }, [navigate]);

  // Cart Api Data

  function emailRemovalHandler(description) {
    return description?.replace(/\s*at\s+\S+@\S+/, " at ");
  }
  function extractEmail(description) {
    const emailMatch = description?.match(
      /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
    );
    return emailMatch ? emailMatch[0] : "";
  }
  return (
    <>
      {showShimmer ? (
        <AboutUsShimmer component={"Birthday"} />
      ) : (
        <div className="contentwraper">
          <section className="siteBanner" style={{ background: "#F79727" }}>
            <div className="siteBanner__overlay">
              {sectionData?.map(
                (item) =>
                  item?.section_name === "Party Page Header" && (
                    <img
                      className="siteBanner__img"
                      src={
                        item?.imageSection?.image
                          ? item?.imageSection?.image
                          : aboutUsBanner
                      }
                      onError={(e) => {
                        e.target.onerror = null || "";
                        e.target.src = aboutUsBanner;
                      }}
                      alt=""
                    />
                  )
              )}
            </div>
          </section>
          <section className="breadcrumbSec">
            <div className="container">
              <div className="breadcrumb">
                <div className="breadcrumb__item">
                  <Link
                    className="breadcrumb__link"
                    onClick={() => navigate(-1)}
                  >
                    Back to results
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="commonSec">
            <div className="container">
              <div className="contentWithImg bday_Div">
                <h3 className="title title--h1 title--extrabold ">
                  Book Your Gymnastics Party Here!
                </h3>

                <div className="contentWithImg__row">
                  <div className="contentWithImg">
                    <div className="content">
                      <div className="shortText">
                        {sectionData?.map(
                          (item) =>
                            item?.section_name === "Party Page Description" && (
                              <p>{item?.descriptionSection?.description}</p>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*  BIRTHDAT SECTIONS  */}
          <section className="birthdaySec">
            <div className="container">
              {/* PACKAGES */}

              <PackageComponent
                setTotalAmount={setTotalAmount}
                TotalAmount={TotalAmount}
                sectionData={sectionData}
                setSelectedPackage={setSelectedPackage}
                selectedPackage={selectedPackage}
              />

              {/* DJ_SECTION  */}

              <div className="dj-container">
                <div className="djSection">
                  <div className="bday_flex" style={{ minWidth: "160px" }}>
                    <div className="bday_flex__col">
                      <img src={djIcon} alt="" className="dj_img" />
                    </div>
                    <div className="bday_flex__col">
                      <h2 className="title dj-title" style={{ color: "white" }}>
                        {" "}
                        Music DJ
                      </h2>
                    </div>
                  </div>

                  <div className="dj_mid_content" style={{ width: "38%" }}>
                    <h2
                      className="dj_content"
                      style={{
                        color: "white",
                        width: "400px",
                        fontSize: "1.2rem",
                        textAlign: "left",
                      }}
                    >
                      Take your part to the next level and Add a DJ to get your
                      pumpin’
                    </h2>
                  </div>
                  {/* DJ DUTRATION  */}
                  <div className="dj_div">
                    <h2
                      className="dj_content"
                      style={{
                        color: "white",
                        width: "100%",
                        fontSize: "1.2rem",
                        lineHeight: "0px",
                      }}
                    >
                      Dj Duration
                    </h2>
                    <p
                      style={{
                        color: "white",
                        width: "100%",
                        fontSize: "1.2rem",
                        lineHeight: "normal",
                      }}
                    >
                      1 Hour: $ 250 / 1.5 Hours: $300
                    </p>
                  </div>
                </div>
              </div>

              <div className="birthdayActivity">
                <h3 className="title title--h1 title--extrabold  extra_act_resp">
                  Added Extra Activities
                </h3>
                <ActivityComponent
                  setTotalAmount={setTotalAmount}
                  TotalAmount={TotalAmount}
                />
              </div>

              <div className="birthdaypartyDetail party_det">
                {sectionData?.map((item) => {
                  let FirstContent = "";
                  let SecondContent = "";

                  if (item?.section_name === "Party Page Footer 1") {
                    FirstContent = item?.descriptionSection?.description;
                  }

                  if (item?.section_name === "Party Page Footer 2") {
                    SecondContent = item?.descriptionSection?.description;
                  }

                  if (
                    item?.section_name === "Party Page Footer 1" ||
                    item?.section_name === "Party Page Footer 2"
                  ) {
                    return (
                      <h3 className="title title--extrabold" key={item?.id}>
                        {FirstContent} {emailRemovalHandler(SecondContent)}{" "}
                        <Link
                          className="title title--extrabold"
                          style={{
                            cursor: "pointer",
                            padding: "0px",
                            textDecoration: "none",
                          }}
                        >
                          {extractEmail(item?.descriptionSection?.description)}
                        </Link>
                      </h3>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default BirthdayMain;
