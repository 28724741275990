const GET_REPORTS = `
query GetcategorySummary($startDate: String, $endDate: String, $categoryId: [Int]) {
  getcategorySummary(startDate: $startDate, endDate: $endDate, categoryId: $categoryId) {
    totalPaid
    chargeForChunk
    totalDue
    totalAmount
    chunk {
      Due
      amount
      category_name
      
      class_name
      class_start_date
      clientId
      client_first_name
      client_last_name
      paid
      start
      end
      payment_date
    }
    grandTotalAmount
    grandTotalDue
    grandTotalPaid
    totalCharges
  }
}
`;
export const GET_ATTENDENCE = `
query GetAttendanceSummary($classStartDate: String, $classEndDate: String, $typeStatus: [String], $className: [String], $typeDetails: String) {
  getAttendanceSummary(class_start_date: $classStartDate, class_end_date: $classEndDate, typeStatus: $typeStatus, className: $className, typeDetails: $typeDetails) {
    className
    studentName
    time
    type
    class_date
    typeStatus
    memo
    class_start_time
    class_end_time
  }
}
`;

export const GET_PHOTORELEASE = `
query GetphotoRelease($active: Boolean, $gender: String, $studentEndAge: Int, $studentStartAge: Int) {
  getphotoRelease(active: $active, gender: $gender, student_end_age: $studentEndAge, student_start_age: $studentStartAge) {
    parent1_email
    parent2_email
    parent1_last_name
    parent1_first_name
    parent1_city
    parent1_address
    parent1_zip
    parent1_state
    parent1_cell
    age 
    gender
    active 
  }
}
`;

export const GET_MAILING_LIST = `
query GetmailingList($studentStartAge: Int, 
  $studentEndAge: Int, 
  $enrollmentStartDate: String, 
  $enrollmentEndDate: String, 
  $gender: String, 
  $classes: [Int],
  $categoryId: [Int]) {
  getmailingList(student_start_age: $studentStartAge, student_end_age: $studentEndAge, enrollment_start_date: $enrollmentStartDate, enrollment_end_date: $enrollmentEndDate, gender: $gender, categoryId: $categoryId,classes: $classes) {
    parent1_email
    parent2_email
    enrollment_status
    parent1_first_name
    parent1_last_name
    parent1_city
    parent1_address
    parent1_zip
    parent1_state
    parent1_cell
    age
   classes_name 
   category_name
   gender
  }
}
`;

export const GET_DROP_OFF_REPORT = `
query GetdropoffSummary($prevDate: String, $recentDate: String) {
  getdropoffSummary(prevDate: $prevDate, recentDate: $recentDate) {
    class_name
    parent1_address
    parent1_city
    parent1_email
    parent1_state
    parent1_zip
    parent_name
    start_month
    student_name
  }
}
`;

export { GET_REPORTS };
