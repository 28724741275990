import React from "react";
import { contactPageContent } from "../api/contentApi";
import { useState } from "react";
import { useEffect } from "react";
import { getSectionCMS } from "../../Admin/adminApi/mastermenu";
import { Link, useNavigate } from "react-router-dom";
import AboutUsShimmer from "../../Admin/components/ShimmerUi/AboutUsShimmer";

function Contact() {
  const token = localStorage.getItem("access_token");
  const [sectionData, setSectionData] = useState([]);
  const [showShimmer, setShowShimmer] = useState(false);
  const [showHomeImage, setShowHomeImage] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getContactUsImages();
  }, []);

  useEffect(() => {
    if (token) {
      const sessionTimeOut = setTimeout(() => {
        localStorage.removeItem("access_token");
        navigate("/");
      }, 86400000);
      return () => clearTimeout(sessionTimeOut);
    }
  }, [navigate]);

  const getContactUsImages = async () => {
    setShowHomeImage(false);
    setShowShimmer(true);
    const section = await getSectionCMS(token, 171);
    setSectionData(section);
    setShowShimmer(false);
    setShowHomeImage(true);
  };
  return (
    <>
      {showShimmer ? (
        <AboutUsShimmer component={"Contact"} />
      ) : (
        <div className="contentwraper" style={{ paddingBottom: "80px" }}>
          <section style={{ position: "relative" }}>
            <section className="siteBanner" style={{ background: "#F79727" }}>
              <div className="siteBanner__overlay">
                <img
                  className="siteBanner__img"
                  alt=""
                  src={
                    sectionData?.length > 0 &&
                    sectionData[0]?.imageSection?.image
                  }
                  onError={(e) => {
                    e.target.onerror = null || "";
                    e.target.src = contactPageContent.bannerImage.url;
                  }}
                />
              </div>
            </section>
          </section>
          <section className="breadcrumbSec">
            <div className="container">
              <div className="breadcrumb">
                <div className="breadcrumb__item">
                  <Link
                    className="breadcrumb__link"
                    onClick={() => navigate(-1)}
                  >
                    Back to results
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className="aboutusSec camp_sec">
            <div className="container">
              <div className="aboutData">
                <div className="aboutData__box">
                  <div className="aboutData__row">
                    <div className="aboutData__col">
                      <div className="content">
                        <h1
                          className="title title--h1 title--extrabold"
                          style={{ marginBottom: "1rem" }}
                        >
                          {contactPageContent.topContent.title}{" "}
                        </h1>
                        <div className="shortText">
                          <p>
                            {sectionData?.[1]?.descriptionSection?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="aboutData__col">
                      <div className="picture">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12578.922705933614!2d-73.95833108417031!3d40.77435850646472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258b84978ccb3%3A0x72c6759d8575a76b!2sGymtime%20Rhythm%20%26%20Glues%20Inc!5e0!3m2!1sen!2sin!4v1701536715479!5m2!1sen!2sin"
                          width="600"
                          height="450"
                          style={{ border: "0" }}
                          allowFullScreen={true}
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                          title="xfdg"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="contactInfoSec">
            <div className="container">
              <div className="contactInfo">
                <div className="contactInfo__item">
                  <div className="contactInfo__icon">
                    <svg
                      width="45"
                      height="71"
                      viewBox="0 0 45 71"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M35.9848 5.29058H9.72568C8.56496 5.29058 7.45178 5.75167 6.63102 6.57243C5.81027 7.39318 5.34917 8.50637 5.34917 9.66709V62.1853C5.34917 63.346 5.81027 64.4592 6.63102 65.2799C7.45178 66.1007 8.56496 66.5618 9.72568 66.5618H35.9848C37.1455 66.5618 38.2587 66.1007 39.0794 65.2799C39.9002 64.4592 40.3613 63.346 40.3613 62.1853V9.66709C40.3613 8.50637 39.9002 7.39318 39.0794 6.57243C38.2587 5.75167 37.1455 5.29058 35.9848 5.29058ZM9.72568 0.914063C7.40424 0.914063 5.17787 1.83625 3.53636 3.47777C1.89485 5.11928 0.972656 7.34564 0.972656 9.66709V62.1853C0.972656 64.5067 1.89485 66.7331 3.53636 68.3746C5.17787 70.0161 7.40424 70.9383 9.72568 70.9383H35.9848C38.3062 70.9383 40.5326 70.0161 42.1741 68.3746C43.8156 66.7331 44.7378 64.5067 44.7378 62.1853V9.66709C44.7378 7.34564 43.8156 5.11928 42.1741 3.47777C40.5326 1.83625 38.3062 0.914063 35.9848 0.914062L9.72568 0.914063Z"
                        fill="#EA519C"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22.8531 62.1849C24.0138 62.1849 25.127 61.7238 25.9477 60.9031C26.7685 60.0823 27.2296 58.9691 27.2296 57.8084C27.2296 56.6477 26.7685 55.5345 25.9477 54.7137C25.127 53.893 24.0138 53.4319 22.8531 53.4319C21.6924 53.4319 20.5792 53.893 19.7584 54.7137C18.9377 55.5345 18.4766 56.6477 18.4766 57.8084C18.4766 58.9691 18.9377 60.0823 19.7584 60.9031C20.5792 61.7238 21.6924 62.1849 22.8531 62.1849Z"
                        fill="#EA519C"
                      />
                    </svg>
                  </div>
                  <div className="contactInfo__text">
                    <h3 className="title" style={{ color: "#5E5F5F" }}>
                      Phone
                    </h3>
                    <p className="para">Call customer services</p>
                    <p className="para">
                      <a className="para__link" href="tel:212-861-7732">
                        212-861-7732
                      </a>
                    </p>
                  </div>
                </div>
                <div className="contactInfo__item">
                  <div className="contactInfo__icon">
                    <svg
                      width="71"
                      height="71"
                      viewBox="0 0 71 71"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_641_6539)">
                        <path
                          d="M67.8641 64.2927L63.0779 49.9308H57.8261L60.7426 63.9356H9.39385L12.3104 49.9308H7.05854L2.26888 64.2927C1.05396 67.948 3.2072 70.938 7.05854 70.938H63.0779C66.9293 70.938 69.0825 67.948 67.8641 64.2927ZM52.5743 18.4199C52.5743 13.777 50.7299 9.32425 47.4469 6.04122C44.1639 2.7582 39.7111 0.913818 35.0682 0.913818C30.4253 0.913818 25.9726 2.7582 22.6896 6.04122C19.4066 9.32425 17.5622 13.777 17.5622 18.4199C17.5622 35.1382 35.0682 53.432 35.0682 53.432C35.0682 53.432 52.5743 35.1382 52.5743 18.4199ZM25.615 18.6299C25.615 13.4096 29.8444 9.18018 35.0682 9.18018C37.5749 9.18018 39.979 10.176 41.7515 11.9485C43.524 13.721 44.5197 16.125 44.5197 18.6317C44.5197 21.1384 43.524 23.5424 41.7515 25.3149C39.979 27.0874 37.5749 28.0832 35.0682 28.0832C32.5611 28.0832 30.1566 27.0873 28.3838 25.3144C26.6109 23.5416 25.615 21.1371 25.615 18.6299Z"
                          fill="#EA519C"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_641_6539">
                          <rect
                            width="70.0242"
                            height="70.0242"
                            fill="white"
                            transform="translate(0.0546875 0.913818)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="contactInfo__text">
                    <h3 className="title" style={{ color: "#5E5F5F" }}>
                      Address
                    </h3>
                    <p className="para">
                      <Link className="para__link" to="/contact">
                        1520 York AvenueNew York NY, 10028
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="contactInfo__item">
                  <div className="contactInfo__icon">
                    <svg
                      width="63"
                      height="48"
                      viewBox="0 0 63 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M58.7715 0.584717H4.3082C3.27645 0.584717 2.28695 0.994579 1.55739 1.72414C0.827832 2.4537 0.417969 3.4432 0.417969 4.47495V43.3773C0.417969 44.409 0.827832 45.3985 1.55739 46.1281C2.28695 46.8577 3.27645 47.2675 4.3082 47.2675H58.7715C59.8033 47.2675 60.7928 46.8577 61.5223 46.1281C62.2519 45.3985 62.6617 44.409 62.6617 43.3773V4.47495C62.6617 3.4432 62.2519 2.4537 61.5223 1.72414C60.7928 0.994579 59.8033 0.584717 58.7715 0.584717ZM55.776 43.3773H7.5371L21.1529 29.2946L18.352 26.5909L4.3082 41.121V7.43153L28.486 31.4926C29.2149 32.2172 30.2009 32.6239 31.2286 32.6239C32.2564 32.6239 33.2424 32.2172 33.9713 31.4926L58.7715 6.82854V40.8681L44.4554 26.552L41.7128 29.2946L55.776 43.3773ZM6.85631 4.47495H55.6204L31.2286 28.7306L6.85631 4.47495Z"
                        fill="#EA519C"
                      />
                    </svg>
                  </div>
                  <div className="contactInfo__text">
                    <h3 className="title" style={{ color: "#5E5F5F" }}>
                      Email Address
                    </h3>
                    <p className="para">
                      <a className="para__link" href="mailto:info@gymtime.net">
                        info@gymtime.net
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default Contact;
