import React from "react";

import { privacyPolicy } from "../api/contentApi";
import { Link, useNavigate } from "react-router-dom";

function PrivacyPolicy() {
  const navigate = useNavigate();
  return (
    <>
      <div className="contentwraper">
        <section className="siteBanner" style={{ background: "#00BBE3;" }}>
          <div className="siteBanner__overlay">
            <img
              className="siteBanner__img"
              src={privacyPolicy.bannerImage.url}
              alt="about-banner"
            />
          </div>
        </section>
        <section className="breadcrumbSec">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb__item">
                <Link className="breadcrumb__link" onClick={() => navigate(-1)}>
                  Back to results
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="commonSec">
          <div className="container">
            <div className="shortText">
              <h3 style={{ color: "#EA519C", marginBottom: "20px" }}>
                I. General Information
              </h3>
              <p>
                This website, and the various content, features and services
                offered on this website (collectively, the “Site”), is owned
                and/or operated by Gymtime (“Company,” “we,” “us,” “our” or
                similar pronoun). Company is committed to respecting your online
                privacy and recognizes your need for appropriate protection and
                management of any personally identifiable information (“Personal
                Information”) you share with us. Company has established this
                privacy policy (“Privacy Policy”) to let you know what
                information we may collect from you on the Site and how we may
                use and share that information. Please take a moment to review
                the terms of our Privacy Policy. By using the Site, you agree to
                accept the terms of our Privacy Policy. If you do not agree to
                the terms of this Privacy Policy, please do not use the Site.
                Except as expressly provided herein, this Privacy Policy applies
                only to information that we collect on the Site and does not
                apply to information which we may collect by other means.
              </p>
              <h3 style={{ color: "#EA519C", marginBottom: "20px" }}>
                II. Personal Information; Changes to Personal Information
              </h3>
              <p>
                Personal Information means any information that may be used to
                identify an individual, including, but not limited to, first and
                last name, home or other physical address, telephone number,
                e-mail address, occupation, job title, or information about your
                job/occupation.
              </p>
              <p>
                In general, you can visit the Site without telling us who you
                are or revealing any Personal Information about yourself. Some
                features or services may require you to provide Personal
                Information. For example, we may collect certain Personal
                Information if you choose to subscribe to a mailing list,
                participate in special offers, sweepstakes or other promotions,
                or send questions or comments to us via e-mail.
              </p>

              <p>
                We may also collect certain demographic information (such as
                gender or age) and information about your interests and
                preferences (“preferences information”). In many cases, such
                demographic and preferences information is optional. In other
                cases, such as online surveys, we collect this information on an
                anonymous basis. However, if we link any demographic or
                preferences information to any Personal Information, then such
                demographic and preferences information will be treated as
                Personal Information under this Privacy Policy.
              </p>

              <h3 style={{ color: "#EA519C", marginBottom: "20px" }}>
                III. Aggregate Information
              </h3>
              <p>
                From time to time, Company may collect general, non-personal,
                statistical information about the use of the Site, such as how
                many visitors visit a specific page on the Site, how long they
                stay on that page and which hyperlinks, if any, they “click” on.
                We collect this information through the use of “cookies” and
                other tracking technologies, which are discussed in greater
                detail below. We collect this information in order to determine
                which areas of the Site are most popular and to enhance the Site
                for visitors. We may group this information into aggregate
                visitor data in order to describe the use of the Site to our
                existing or potential business partners, sponsors, advertisers
                or other third parties, or in response to a government request.
                Company may also group demographic and preferences information
                and responses to surveys that we collect from visitors into
                aggregate data for the same purposes. However, please be assured
                that this aggregate data will in no way personally identify you
                or any other visitors to the Site.
              </p>
              <h3 style={{ color: "#EA519C", marginBottom: "20px" }}>
                IV. Cookies and Other Tracking Technologies
              </h3>
              <p>
                Some of our web pages utilize “cookies” and other tracking
                technologies. A cookie is a small text file that a website
                transfers to an individual’s hard drive for record-keeping
                purposes. For example, we may use cookies to collect information
                about website activity. Most browsers allow you to control
                cookies, including whether or not to accept them and how to
                remove them. You may set most browsers to notify you if you
                receive a cookie, or you may choose to block cookies with your
                browser. However, please be aware that some features of the Site
                may not function properly or may be slower if you refuse
                cookies.
              </p>
              <p>
                You may occasionally get cookies from our advertisers or other
                third parties with links on the Site (as in Section X). We do
                not control these cookies. The use of advertising cookies sent
                by third party ad servers is standard in the Internet industry.
              </p>
              <p>
                Tracking technologies may record information such as Internet
                domain and host names; Internet protocol (IP) addresses; browser
                software and operating system types; clickstream patterns; and
                dates and times that our Site is accessed. An IP address is a
                number that is automatically assigned to your computer whenever
                you are surfing the web. Web servers, the computers that “serve
                up” webpages, automatically identify your computer by its IP
                address. We will not link the information we collect through
                tracking technologies for marketing purposes to the personal
                information submitted about the students using the Company
                software. In addition, we reserve the right to use IP addresses
                and other tracking technologies to identify a visitor only when
                we feel it is necessary to enforce compliance with the Site’s
                policies, to protect the Site, our customers or others, or when
                we believe in good faith that the law requires it.
              </p>

              <h3 style={{ color: "#EA519C", marginBottom: "20px" }}>
                V. How We Use and Share Personal Information
              </h3>
              <p>How We May Use Your Information</p>
              <p>
                With respect to the Personal Information we collect, we may
                retain and use that information for several purposes. For
                example, we may use your Personal Information to send e-mails to
                our mailing list subscribers or respond to your questions. We
                may also use Personal Information to ensure compliance with our
                policies and applicable law. We use your e-mail address as a
                customer identifier on the Site. In addition, we may send you
                periodic communications by e-mail or regular mail, such as news
                updates, special promotional offers or information on our
                services which may be of interest to you. If you later wish to
                opt-out of receiving such promotional communications from us at
                any time, simply follow the instructions in Section VI.
                Notwithstanding the foregoing, we reserve the right to send you
                certain non-promotional communications relating to the Site,
                such as service announcements or similar administrative or
                transactional messages, without offering you the opportunity to
                opt-out of receiving them.
              </p>
              <p>With Whom We May Share Your Information</p>
              <p>
                We may sometimes use other businesses to perform certain
                services for us, such as maintaining the Site and our mailing
                lists, sending postal mail and providing marketing assistance
                and data analysis. We may provide Personal Information to those
                businesses when that information is necessary for them to
                complete a requested transaction or otherwise perform their
                duties. Company will take reasonable steps to ensure that these
                third party service providers are obligated to protect Personal
                Information on Company’s behalf.
              </p>
              <p>
                As Company develops, we may buy other businesses or their assets
                or sell our business assets. Customer information is generally
                one of the business assets involved in such transactions. Thus,
                in the event that Company or all of its assets are acquired,
                Personal Information would be one of the transferred assets.
              </p>
              <p>
                Company fully cooperates with law enforcement agencies in
                identifying those who use our services for illegal activities,
                and may in its sole discretion disclose Personal Information or
                other information to satisfy any law, regulation, subpoena, or
                government request, or in connection with litigation. Company
                reserves the right to release Personal Information or other
                information about users who we believe are engaged in illegal
                activities or are otherwise in violation of our Terms of Use
                Agreement, even without a subpoena, warrant or court order, if
                we believe in our sole discretion that such disclosure is
                necessary or appropriate to operate the Site, or to protect the
                rights or property of Company, its affiliates, or any of their
                officers, directors, or employees, agents, third party content
                providers, suppliers, sponsors, or licensors. Company also
                reserves the right to report to law enforcement agencies any
                activities we reasonably believe in our sole discretion to be
                unlawful.
              </p>

              <h3 style={{ color: "#EA519C", marginBottom: "20px" }}>
                VI. Privacy Preferences/Opt-Out
              </h3>
              <p>
                If you do not want to receive promotional e-mails from Company,
                please follow one of the two options below:
              </p>
              <p>
                If you do not want to receive promotional e-mails from Company,
                please follow one of the two options below:
                <ul>
                  <li>
                    <p>
                      1. You must opt-in to receive promotional e-mails. When
                      providing your Personal Information, simply do not check
                      the box that asks whether you would like to receive the
                      e-newsletter, and we will not send you any promotional
                      information via e-mail.
                    </p>
                  </li>
                  <li>
                    <p>
                      2. When you receive an e-mail from Company, it will
                      contain a link that allows you to stop the delivery of
                      e-mail messages from us. If you decide to use this opt-out
                      link, you will no longer receive special offers or
                      e-newsletters from Company; however, you will continue to
                      receive non-promotional e-mails from us, such as
                      informational announcements.
                    </p>
                  </li>
                </ul>
              </p>

              <h3 style={{ color: "#EA519C", marginBottom: "20px" }}>
                VII. Security
              </h3>
              <p>
                Company intends to take reasonable and appropriate steps to
                protect the Personal Information that you share with us from
                unauthorized access or disclosure. When you transmit Personal
                Information from your PC to our servers, your information is
                protected by both a “firewall” (a combination of computer
                hardware and software that helps keep unauthorized visitors from
                accessing information within our computer network) and industry
                standard SSL (secure socket layer) encryption. Unfortunately, no
                data transmission over the Internet can be guaranteed to be 100%
                secure. As a result, while we strive to protect your Personal
                Information and privacy, we cannot guarantee or warrant the
                security of any information you disclose or transmit to us
                online and cannot be responsible for the theft, destruction, or
                inadvertent disclosure of your Personal Information.
              </p>

              <h3 style={{ color: "#EA519C", marginBottom: "20px" }}>
                VIII. Children’s Privacy
              </h3>
              <p>
                This Site is not intended for use by children under the age of
                13, and Company does not knowingly collect any Personal
                Information from such children. If we become aware that we have
                unknowingly collected Personal Information from a child under
                the age of 13, we will make commercially reasonable efforts to
                delete such information from our database.
              </p>

              <h3 style={{ color: "#EA519C", marginBottom: "20px" }}>
                IX. Changes to Privacy Policy
              </h3>
              <p>
                The terms of this Privacy Policy may change from time to time.
                We will notify you of any material changes to this Privacy
                Policy by posting a notice on the homepage of the Site for a
                reasonable period of time after such changes are made that this
                Privacy Policy has been updated, and by changing the “Last
                Updated” date at the top of this Privacy Policy. We encourage
                you to check this page periodically for any changes. Your
                continued use of the Site following the posting of changes to
                these terms will mean you accept those changes. If you have
                questions about this Privacy Policy, or believe that Company has
                acted inconsistently with this Privacy Policy, please Contact Us
                at info@gymtime.net.
              </p>

              <h3 style={{ color: "#EA519C", marginBottom: "20px" }}>
                X. Third Party Websites
              </h3>
              <p>
                Please note that you may have cookies placed on your computer by
                third party websites that refer you to the Site. Although we do
                not share your Personal Information with these third party
                websites, they may be able to link certain non-personally
                identifiable information we transfer to them with Personal
                Information they previously collected from you. Please review
                the privacy policies of each website you visit to better
                understand their privacy practices. In addition, Company would
                like to inform you that anytime you click on links (including
                advertising banners), which take you to third party websites,
                you will be subject to the third parties’ privacy policies. G
              </p>
              <p>
                While we support the protection of our customer’s privacy on the
                Internet, Company expressly disclaims any and all liability for
                the actions of third parties, including but without limitation
                to actions relating to the use and/or disclosure of Personal
                Information by third parties.
              </p>

              <h3 style={{ color: "#EA519C", marginBottom: "20px" }}>
                XI. Third Party Ad Servers
              </h3>
              <p>
                As of the effective date of this Privacy Policy, we do not have
                relationships at the Site with third party ad server networks.
                However, we may at some point engage third party ad server
                networks to serve advertisements within or in connection with
                the Site. Because your web browser must request these
                advertising banners from the ad networks’ servers, these
                companies can send their own cookies to your cookie file, just
                as if you had requested a web page from the Site. Please note
                that if an advertiser on a third party ad server network asks us
                to show an advertisement to a certain audience (for example,
                women ages 18-34) or audience segment (for example, women ages
                18-34 who have viewed educational items) and you respond to that
                ad, the advertiser or ad-server may conclude that you fit the
                description of the audience they are trying to reach. However,
                we would only authorize third party ad servers to employ
                anonymous cookies for ad delivery and anonymous targeting. We
                would not knowingly permit a third party ad server to collect
                Personal Information about you. If you want to prevent a third
                party ad server and/or third party service provider from sending
                and reading cookies on your computer, you must visit each third
                party ad server network’s website individually and opt out (if
                they offer this capability).
              </p>

              <h3 style={{ color: "#EA519C", marginBottom: "20px" }}>
                XII. Miscellaneous
              </h3>
              <p>
                Company makes no representation that this Privacy Policy and our
                practices comply with the laws of any other country or
                jurisdiction. Visitors who use the Site and reside outside the
                United States do so on their own initiative and are responsible
                for compliance with local laws, if and to the extent local laws
                are applicable. If you reside outside of the United States, by
                using our Site, you consent to the transfer, processing and use
                of your information outside your country.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default PrivacyPolicy;
