import { createSlice } from "@reduxjs/toolkit";




const classDataSlice = createSlice({
  name: "classData",
  initialState: [],
  reducers: {
    setClassData: (state, action) => {
      return action.payload;
    },
  },
});

export const { setClassData } = classDataSlice.actions;
export default classDataSlice.reducer;
