import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminModalContent:"",
};

const adminModalSlice = createSlice({
  name: "adminModal",
  initialState ,
  reducers: {
    setAdminModalContentReducer: (state, action) => {
      state.adminModalContent = action.payload;
    },
  },
});

export const { setAdminModalContentReducer } =
adminModalSlice.actions;
export default adminModalSlice.reducer;
