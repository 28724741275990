import React from "react";
import Header from "../components/Header";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import NavigationComponent from "../components/NavigationComponent";
import { useEffect } from "react";
import DeleteUser from "../components/UserModals/DeleteModal";
import downloadIcon from "../assets/images/icons/donwload-icon.svg";
import warning from "../assets/images/icons/warning.png";
import {
  enableButtonAPI,
  getLedgers,
  ledgerInvoiceDownload,
} from "../adminApi/Ledger";
import AddPaymentModals from "../components/PaymentModals/AddPaymentModal";
import { deleteLedger } from "../adminApi/Ledger";
import { useSelector } from "react-redux";
import EditLedgerModal from "../components/EditLedgerModal";
import Table from "../components/TableComponent/Table";
import AddModal from "../components/PaymentModals/AddModal";
import alertIcon from "../../Admin/assets/images/icons/alert.svg";
import VoidPaymentModal from "../components/VoidPaymentModal";
import { toast } from "react-toastify";
import {
  balancePrecission,
  customStyles,
  timeFormatter,
} from "../adminUtility";
import { getClassForDropdown } from "../adminApi/addClassList";
import Select from "react-select";
import { ReactComponent as SearchIcon } from "../assets/images/icons/search.svg";
import LoaderComponent from "../components/LoaderComponent.jsx";
import {
  balanceHeader,
  ledgerSubHeader,
  ledgerTable,
} from "../tableHeaderComponents.js";

function Ledgers() {
  const [recurringButtonText, setRecurringButtonText] = useState();
  const [buttonType, setButtonType] = useState("PAY");
  const [openAddButton, setOpenAddButton] = useState(false);
  const [ledgerData, setLeadgerData] = useState();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showLedgerModal, setShowLedgerModal] = useState(false);
  const [recurringDuration, setRecurrinDuration] = useState();
  const [totalPages, setTotalPages] = useState();
  const [ledgerDetails, setLedgerDetails] = useState();
  const [ledgerId, setLedgerId] = useState();
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [selectedLedgerID, setSelectedLedgerID] = useState();
  const [selectedLedgerDetails, setSelectedLedgerDetails] = useState();
  const [temp, setTemp] = useState(1);
  const [sortKey, setSortKey] = useState({
    sortID: null,
    isAsc: true,
  });
  const [formData, setFormData] = useState([]);
  const [enableResponse, setEnableResponse] = useState();
  const [pageLimit, setPageLimit] = useState("5");
  const [selectedClassId, setSelectedClassId] = useState();
  const page = useSelector((state) => state?.adminSlice?.adminModalContent);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [allClassApiData, setAllClassApiData] = useState();
  const [loadingState, setLoadingState] = useState(false);
  const [openVoidModalState, setOpenVoidModalState] = useState();
  const [toggleState, setToggleState] = useState(true);
  const [isDownload, setIsDownload] = useState(false);
  const token = localStorage.getItem("access_token");
  const role = useSelector((state) => state?.userRole?.RoleId);
  const [filterData, setFilterData] = useState();
  const SearchedCLientId = localStorage.getItem("Searched_CLIENT_ID");

  const tempLedgerID = localStorage.getItem("Ledger_Client");
  let ledgerClientObject = null;
  if (tempLedgerID !== "undefined") {
    ledgerClientObject = JSON.parse(tempLedgerID);
  }

  // DOwnload ledger Invoice
  const handleDownload = async (e, ledgerID) => {
    const element = document.createElement("div");
    document.body.appendChild(element);
    setIsDownload(true);
    try {
      const token = localStorage.getItem("access_token");
      const response = await ledgerInvoiceDownload(token, ledgerID);
      if (response?.status === 200) {
        setTimeout(() => {
          setIsDownload(false);
        }, 1000);
        localStorage.setItem("INVOICE_DATA", JSON.stringify(response?.data));
        window.open("/admin/invoice-pdf", "_blank");
      } else {
        setIsDownload(false);
      }
    } catch (error) {
      setIsDownload(false);
      toast.error(error?.response?.data.error);
    }
  };

  const enableApiFunction = async (id) => {
    const token = localStorage.getItem("access_token");
    const enableButtonResponse = await enableButtonAPI(token, id);
    setEnableResponse(enableButtonResponse?.data?.paymentDetails);
  };

  const getAllLedgers = async () => {
    setLoadingState(true);
    try {
      const response = await getLedgers(
        token,
        sortKey,
        page,

        SearchedCLientId !== null && formData?.length == 0
          ? [
              {
                name: SearchedCLientId,
                selectUser: "clientId",
              },
            ]
          : formData,
        pageLimit
      );
      console.log("RESPONSE", response);
      if (response?.data?.length > 0) {
        setLoadingState(false);
      }

      setRecurringButtonText(response?.data);
      setCurrentPage(response?.currPage);
      setTotalCount(response?.totalCount);
      setTotalPages(response?.totalPages);
      const modifiedData = response?.data.map((classItem, index) => {
        return {
          data: [
            classItem?.clientId,
            classItem?.studentId,
            <p>
              {classItem?.student_first_name} {classItem?.student_last_name}
            </p>,
            // ENROLL
            <p>
              {classItem?.classes?.map((val, index) => (
                <React.Fragment key={index}>
                  <span>{val?.name}</span>
                  {index !== classItem.classes.length - 1 && <span>, </span>}
                </React.Fragment>
              ))}
            </p>,
            <p>
              {classItem?.parent1_first_name || classItem?.parent1_last_name
                ? `${classItem?.parent1_first_name || "-"} ${
                    classItem?.parent1_last_name || "-"
                  }`
                : "-"}
            </p>,
            // Parent 2 name
            <p>
              {classItem?.parent2_first_name || classItem?.parent2_last_name
                ? `${classItem?.parent2_first_name || "-"} ${
                    classItem?.parent2_last_name || "-"
                  }`
                : "-"}
            </p>,
            <p className="title title--extrabold" style={{ fontSize: "1rem" }}>
              ${balancePrecission(classItem?.amount_to_pay)}
            </p>,

            // PAY
            <button
              // disabled={role == "4"}
              className="adminbtn adminbtn-primary"
              style={{ width: "70%" }}
              onClick={() => {
                setAddModal(true);
                setSelectedLedgerID(classItem?.id);
                setButtonType("PAY");
              }}
            >
              PAY NOW
            </button>,
            // ENABLE
            <button
              className="adminbtn adminbtn-primary"
              style={{ width: "70%" }}
              onClick={() => {
                setAddModal(true);
                setSelectedLedgerID(classItem?.id);
                enableApiFunction(classItem?.id);
                setButtonType("ENABLE");
              }}
            >
              Enable Recurring
            </button>,

            <div>
              {classItem?.currentInterval === null &&
              classItem?.totalInterval === null ? (
                <span>0 of 0</span>
              ) : (
                `${classItem?.currentInterval} of ${classItem?.totalInterval}`
              )}
            </div>,

            // <p>{classItem?.paid ? "YES" : "No"}</p>,
            <button
              className={`adminbtn ${
                classItem?.isVoidAllowed
                  ? "adminbtn-primary"
                  : "adminbtn-transparent"
              } `}
              style={{
                cursor: "pointer",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
              // disabled={classItem?.isVoidAllowed}
              onClick={() => {
                setOpenVoidModalState(true);
                setSelectedLedgerID(classItem?.id);
              }}
              disabled={!classItem?.isVoidAllowed}
            >
              Void Transactions
            </button>,
            // ADHOC
            <buton
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenAddButton(true);
                setLedgerId(classItem?.id);
              }}
              className="adminbtn adminbtn-primary"
              // disabled={role == "4"}
            >
              + Add
            </buton>,

            <p
              className={
                classItem?.paymentnote !== null
                  ? "leder_payment_notes_temp"
                  : ""
              }
            >
              {classItem?.paymentnote === null ? "-" : classItem?.paymentnote}
            </p>,
            // INVOIC DOWNLOAD

            <button
              onClick={(e) => {
                setSelectedLedgerID(classItem?.id);
                handleDownload(e, classItem?.id, classItem?.paid);
              }}
            >
              <img
                src={downloadIcon}
                alt="download"
                style={{
                  height: "40px",
                  width: "30px",
                  padding: "0px",
                }}
              />
              <br />
              Download
            </button>,

            //  EXPIRY ALERT
            <>
              {classItem?.ccExpiryAlert ? (
                <button
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "15px",
                  }}
                >
                  <img
                    src={warning}
                    alt="warning"
                    style={{
                      height: "50px",
                      width: "50px",
                      padding: "0px",
                    }}
                  />

                  <p>{classItem?.message}</p>
                </button>
              ) : (
                <button
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    height={"40px"}
                    width={"50px"}
                    src={alertIcon}
                    alt="alrt-icon"
                  />
                  CC Expiry Alert
                  <p>{classItem?.message}</p>
                </button>
              )}
            </>,
          ],
          item: classItem,
          onDeletePress: () => {
            setLedgerId(classItem?.id);

            setDeleteCategoryModal(true);
          },
          onEditPress: () => {
            setShowLedgerModal(true);
            setLedgerId(classItem?.id);

            setSelectedLedgerDetails(classItem);
          },
        };
      });
      setLeadgerData(modifiedData);
      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    }
  };

  const getSearchedData = async () => {
    setLoadingState(true);

    try {
      const response = await getLedgers(
        token,
        sortKey,
        page,
        [
          {
            name: searchStudentID
              ? searchStudentID
              : ledgerClientObject
              ? ledgerClientObject?.studentId.toString()
              : searchStudentID,
            selectUser: "studentId",
          },
        ],
        pageLimit
      );
      if (response?.data?.length > 0) {
        setLoadingState(false);
      }

      setRecurringButtonText(response?.data);
      setCurrentPage(response?.currPage);
      setTotalCount(response?.totalCount);
      setTotalPages(response?.totalPages);
      const modifiedData = response?.data.map((classItem, index) => {
        return {
          data: [
            classItem?.clientId,
            classItem?.studentId,
            <p>
              {classItem?.student_first_name} {classItem?.student_last_name}
            </p>,
            // ENROLL
            <p>
              {classItem?.classes?.map((val, index) => (
                <React.Fragment key={index}>
                  <span>{val?.name}</span>
                  {index !== classItem.classes.length - 1 && <span>, </span>}
                </React.Fragment>
              ))}
            </p>,
            <p>
              {classItem?.parent1_first_name || classItem?.parent1_last_name
                ? `${classItem?.parent1_first_name || "-"} ${
                    classItem?.parent1_last_name || "-"
                  }`
                : "-"}
            </p>,
            // Parent 2 name
            <p>
              {classItem?.parent2_first_name || classItem?.parent2_last_name
                ? `${classItem?.parent2_first_name || "-"} ${
                    classItem?.parent2_last_name || "-"
                  }`
                : "-"}
            </p>,
            <p className="title title--extrabold" style={{ fontSize: "1rem" }}>
              ${balancePrecission(classItem?.amount_to_pay)}
            </p>,

            // PAY
            <button
              // disabled={role == "4"}
              className="adminbtn adminbtn-primary"
              style={{ width: "70%" }}
              onClick={() => {
                setAddModal(true);
                setSelectedLedgerID(classItem?.id);
                setButtonType("PAY");
              }}
            >
              PAY NOW
            </button>,
            // ENABLE
            <button
              // disabled={role == "4"}
              className="adminbtn adminbtn-primary"
              style={{ width: "70%" }}
              onClick={() => {
                setAddModal(true);
                setSelectedLedgerID(classItem?.id);
                enableApiFunction(classItem?.id);
                setButtonType("ENABLE");
              }}
            >
              Enable Recurring
            </button>,

            <div>
              {classItem?.currentInterval === null &&
              classItem?.totalInterval === null ? (
                <span>0 of 0</span>
              ) : (
                `${classItem?.currentInterval} of ${classItem?.totalInterval}`
              )}
            </div>,

            // <p>{classItem?.paid ? "YES" : "No"}</p>,
            <button
              className={`adminbtn ${
                classItem?.isVoidAllowed
                  ? "adminbtn-primary"
                  : "adminbtn-transparent"
              } `}
              style={{
                cursor: "pointer",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
              // disabled={classItem?.isVoidAllowed}
              onClick={() => {
                setOpenVoidModalState(true);
                setSelectedLedgerID(classItem?.id);
              }}
              disabled={!classItem?.isVoidAllowed}
            >
              Void Transactions
            </button>,
            // ADHOC
            <buton
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenAddButton(true);
                setLedgerId(classItem?.id);
              }}
              className="adminbtn adminbtn-primary"
              // disabled={role == "4"}
            >
              + Add
            </buton>,

            <p
              className={
                classItem?.paymentnote !== null
                  ? "leder_payment_notes_temp"
                  : ""
              }
            >
              {classItem?.paymentnote === null ? "-" : classItem?.paymentnote}
            </p>,
            // INVOIC DOWNLOAD

            <button
              onClick={(e) => {
                setSelectedLedgerID(classItem?.id);
                handleDownload(e, classItem?.id, classItem?.paid);
              }}
            >
              <img
                src={downloadIcon}
                alt="download"
                style={{
                  height: "40px",
                  width: "30px",
                  padding: "0px",
                }}
              />
              <br />
              Download
            </button>,

            //  EXPIRY ALERT
            <>
              {classItem?.ccExpiryAlert ? (
                <button
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "15px",
                  }}
                >
                  <img
                    src={warning}
                    alt="warning"
                    style={{
                      height: "50px",
                      width: "50px",
                      padding: "0px",
                    }}
                  />

                  <p>{classItem?.message}</p>
                </button>
              ) : (
                <button
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    height={"40px"}
                    width={"50px"}
                    src={alertIcon}
                    alt="alrt-icon"
                  />
                  CC Expiry Alert
                  <p>{classItem?.message}</p>
                </button>
              )}
            </>,
          ],
          item: classItem,
          onDeletePress: () => {
            setLedgerId(classItem?.id);

            setDeleteCategoryModal(true);
          },
          onEditPress: () => {
            setShowLedgerModal(true);
            setLedgerId(classItem?.id);

            setSelectedLedgerDetails(classItem);
          },
        };
      });
      setFilterData(modifiedData);
      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    }

    // }
  };

  const handleSearch = () => {
    getAllLedgers(formData);
  };

  const handleAddSearch = () => {
    setTemp((prevTemp) => prevTemp + 1);
  };

  const handleAdditionalFieldsChange = (index, key, value) => {
    const updatedData = [...formData];

    while (updatedData.length <= index) {
      updatedData.push({ selectUser: "", name: "" });
    }

    if (value !== updatedData[index][key]) {
      updatedData[index]["name"] = "";
    }

    updatedData[index][key] = value;
    setFormData(updatedData);
  };

  const renderAdditionalFields = () => {
    const handleSelectChange = (index, value) => {
      handleAdditionalFieldsChange(index, "selectUser", value);
    };

    const classOptions = [
      { value: "", label: "Select Class" },
      ...(Array.isArray(allClassApiData)
        ? allClassApiData
            .filter((val) => val?.name.trim() !== "")
            .map((val) => ({
              show: val?.name,
              value: val.name,
              label: `${val?.name} ${
                val?.schedules?.length > 0 ? ", (" : ""
              }${val?.schedules
                ?.map((el) => {
                  if (el?.weekday && el?.start_time && el?.end_time) {
                    return `${el.weekday.substring(0, 3)} ${timeFormatter(
                      el.start_time
                    )} - ${timeFormatter(el.end_time)}`;
                  }
                  return null;
                })
                .filter(Boolean)
                .join(", ")} ${val?.schedules?.length > 0 ? ")" : ""}`,
            }))
        : []),
    ];

    const additionalFields = [];
    for (let i = 0; i < temp; i++) {
      additionalFields.push(
        <div key={i} style={{ display: "flex", gap: "4px" }}>
          <div className="adminform__group">
            <select
              style={{ minWidth: "150px" }}
              name={`selectUser_${i}`}
              id={`selectUser_${i}`}
              className="adminform__select"
              onChange={(e) => handleSelectChange(i, e.target.value)}
              value={formData[i]?.selectUser}
            >
              <option value="" selected disabled>
                Search
              </option>
              <option value="parentName">Parent Name</option>
              <option value="studentName">Student Name</option>
              <option value="className">Class Name</option>
              <option value="classId">Class ID</option>
              <option value="clientId">Client ID</option>
              <option value="studentId">Student ID</option>
            </select>
          </div>
          {formData[i]?.selectUser === "className" && (
            <Select
              styles={customStyles}
              classNamePrefix="custom-select"
              options={classOptions}
              placeholder={"Select Class"}
              onChange={(option) =>
                handleAdditionalFieldsChange(i, "name", option.value)
              }
            />
          )}
          {formData[i]?.selectUser !== "className" && (
            <input
              type="text"
              className="adminform__input"
              placeholder="Search here"
              name={`name_${i}`}
              onChange={(e) =>
                handleAdditionalFieldsChange(i, "name", e.target.value)
              }
              value={formData[i]?.name}
              disabled={!formData[i]?.selectUser}
            />
          )}
        </div>
      );
    }
    return additionalFields;
  };

  const handleCancelAdditionalFields = (index) => {
    setTemp((prevTemp) => {
      return prevTemp > 1 ? prevTemp - 1 : prevTemp;
    });

    setFormData((prevSearchParams) => {
      const newSearchParams = [...prevSearchParams];
      newSearchParams[index] = { selectUser: "", name: "" };
      const filteredSearchParams = newSearchParams.filter(
        (item) => item.selectUser !== "" || item.name !== ""
      );
      return filteredSearchParams;
    });
  };

  const getAllClassFunction = async () => {
    const token = localStorage.getItem("access_token");
    const response = await getClassForDropdown(token, page, "2");
    setAllClassApiData(response?.data);
  };

  useEffect(() => {
    const condition2 = formData.length > 0 && formData[0].name !== "";
    const condition3 =
      formData.length > 0 && formData[0].selectUser === "className";
    const condition4 = openVoidModalState || page;

    getAllLedgers();
    if (condition2) {
      getAllLedgers(formData);
    }
    if (condition3) {
      getAllClassFunction();
    }
    if (condition4) {
      localStorage.removeItem("Ledger_Client");
      getAllLedgers();
    }
  }, [buttonType, sortKey, page, pageLimit, formData, openVoidModalState]);

  useEffect(() => {
    if (formData?.length) {
      localStorage.removeItem("Searched_CLIENT_ID");
      getAllLedgers();
    }
    getAllLedgers();
    if (formData?.length && formData[0]?.selectUser !== "studentId") {
      localStorage.removeItem("Ledger_Client");
      localStorage.removeItem("StudentIdForLedger");
    }
  }, [page, formData]);

  const searchStudentID = localStorage.getItem("StudentIdForLedger");

  useEffect(() => {
    if (
      ledgerClientObject !== undefined &&
      (searchStudentID || ledgerClientObject)
    ) {
      getSearchedData();
    }

    if (
      (searchStudentID === "undefined" && ledgerClientObject == null) ||
      "undefined"
    ) {
      getAllLedgers();
    }
  }, [searchStudentID]);

  return (
    <>
      {isDownload && <LoaderComponent />}
      <div className="container--component">
        <div className="container--grid">
          <NavigationComponent />

          <div className="Adminpage">
            <Header title="Ledger" subTitle="(Manage Available Ledger)" />
            <section className="Adminpage__section">
              <h2 className="Adminpage__title">Search by:</h2>

              <div className="Adminpage__head">
                <div className="Adminpage__filter">
                  {renderAdditionalFields()}

                  {temp <= 1 ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className="adminform__cancel-button"
                      onClick={() => handleCancelAdditionalFields(1)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}

                  <div
                    className=""
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className="adminbtn adminbtn-light"
                      onClick={handleAddSearch}
                      style={{ marginTop: "10px" }}
                      disabled={temp === 2 ? true : false}
                    >
                      and
                    </button>
                    <button
                      className="adminbtn adminbtn-primary"
                      onClick={handleSearch}
                    >
                      <SearchIcon />
                      Search
                    </button>
                  </div>
                </div>
              </div>

              <Table
                loadingState={loadingState}
                headerData={ledgerTable}
                bodyData={
                  ledgerClientObject
                    ? filterData
                    : searchStudentID
                    ? filterData
                    : ledgerData
                }
                totalPages={totalPages}
                setSortKey={setSortKey}
                fetchData={ledgerDetails}
                subHeader={ledgerSubHeader}
                tableType="Ledger"
                setPageLimit={setPageLimit}
                commentHeader={balanceHeader}
                pageLimit={pageLimit}
                totalCount={totalCount}
                currentPage={currentPage}
                setSelectedClassId={setSelectedClassId}
                selectedClassId={selectedClassId}
                formData={formData}
              />
            </section>
          </div>
        </div>
      </div>

      {/* ADD PAYMENT MODAL  */}

      {addModal && (
        <AddPaymentModals
          setToggleState={setToggleState}
          toggleState={toggleState}
          setAddModal={setAddModal}
          addModal={addModal}
          showPaymentModal={showPaymentModal}
          selectedLedgerID={selectedLedgerID}
          setShowPaymentModal={setShowPaymentModal}
          setRecurrinDuration={setRecurrinDuration}
          recurringDuration={recurringDuration}
          setLedgerDetails={setLedgerDetails}
          fetchData={
            ledgerClientObject
              ? getSearchedData
              : searchStudentID
              ? getSearchedData
              : getAllLedgers
          }
          ledgerData={ledgerDetails}
          ledgerId={ledgerId}
          recurringButtonText={recurringButtonText}
          selectedLedgerDetails={(t) => {
            selectedLedgerDetails(t);
          }}
          buttonType={buttonType}
          enableResponse={enableResponse}
          enableApiFunction={enableApiFunction}
        />
      )}

      {/* EDIT LEDGER  */}
      <EditLedgerModal
        setShowLedgerModal={setShowLedgerModal}
        showLedgerModal={showLedgerModal}
        ledgerId={ledgerId}
        selectedLedgerDetails={selectedLedgerDetails}
        fetchData={getAllLedgers}
      />
      {/* DELETE LEDGER  */}

      {deleteCategoryModal ? (
        <DeleteUser
          openDeleteModal={deleteCategoryModal}
          setDeleteModal={setDeleteCategoryModal}
          data={ledgerId}
          deleteQuery={deleteLedger}
          fetchData={getAllLedgers}
          type="Ledger"
        />
      ) : null}

      {/* ADDHOC - CHARGES  */}

      {openAddButton ? (
        <AddModal
          setOpenAddButton={setOpenAddButton}
          openAddButton={openAddButton}
          ledgerID={ledgerId}
          fetchData={
            ledgerClientObject
              ? getSearchedData
              : searchStudentID
              ? getSearchedData
              : getAllLedgers
          }
        />
      ) : null}

      {/* DELETE CARD ON FILE  */}

      {/* Void Transactions  */}
      {openVoidModalState && (
        <VoidPaymentModal
          openVoidModalState={openVoidModalState}
          setOpenVoidModalState={setOpenVoidModalState}
          selectedLedgerID={selectedLedgerID}
        />
      )}
    </>
  );
}

export default Ledgers;
