import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import NavigationComponent from "../../../components/NavigationComponent";
import { useParams } from "react-router-dom";
import { getMasterSubMenu } from "../../../adminApi/mastermenu";
import TableNew from "../../../components/TableComponent/TableNew";
import { getCmsAge, getCmsPrograms } from "../../../adminApi/program";
import {
  getEnrichmentManageProgramData,
  getManageProgramDataNew,
} from "../../../adminApi/manageProgramData";
import { getClassesForAgeMew } from "../../../adminApi/classes";
import AddClassModal from "../AddClassModal/AddClassModal";
import EditProgramImagesModal from "./EditProgramImagesModal";
import { getCampProgramApi } from "../../../../Parent/api/classApi";

import AddHeaderImage from "./AddHeaderImage";
function CmsProgram() {
  const { menuId } = useParams();
  const token = localStorage.getItem("access_token");
  const [programData, setProgramData] = useState([]);
  const [ageData, setAgeData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [subtleProgramValue, setSubtleProgramValue] = useState();
  //it is used to set the value of Browse by program=0 and age=1
  const [searchInput, setSearchInput] = useState();
  const [isSearchDropdownActive, setIsSearchDropdownActive] = useState(false);
  const [dummayManageProgramData, setDummyManageProgramData] = useState([]);
  const [dummyManageAgeData, setDummyManageAgeData] = useState([]);
  const [addClassId, setAddClassId] = useState();
  const [classProgramId, setClassProgramId] = useState();
  const [classProgramName, setClassProgramName] = useState();
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [subMenu, setSubMenu] = useState([]);
  const [browseByProgramData, setBrowseByProgramData] = useState();
  const [browseByAge, setBrowseByAge] = useState();
  const [manageData, setManageData] = useState(false);
  const [programManageData, setProgramManageData] = useState([]);
  const [ageManageData, setAgeManageData] = useState([]);
  const [tableName, setTableName] = useState("Programs");
  const [manageDataId, setManageDataId] = useState();
  const [temp, setTemp] = useState(1);
  const [formData, setFormData] = useState([]);
  const [isEnrichmentCombo, setIsEnrichmentCombos] = useState();
  const [showAddClassModal, setShowAddClassModal] = useState(false);
  const [enrichmentManageClass, setEnrichmentManageClass] = useState(false);
  const [manageEnrichmentData, setManageEnrichmentData] = useState([]);
  const [allClassData, setAllClassData] = useState([]);
  const [enrichmentData, setEnrichmentData] = useState();
  const [indexNumber, setIndexNumber] = useState();
  const [ageRangedRange, setAgeRangedRange] = useState();
  const [enrichmentCategoryId, setEnrichemntCategoryId] = useState();
  const [showHeaderImageModal, setShowHeaderImageModal] = useState(false);
  const [showEditProgramImagesModal, setShowEditProgramImagesModal] =
    useState(false);
  const [headerName, setHeaderName] = useState();
  const _tabelHeadersBrowseByProgramData = [
    {
      name: "ID No.",
      // icon: arrowIcon,
    },
    {
      name: "Program Name",
      // icon: arrowIcon,
    },
    {
      name: "Updated On",
      // icon: arrowIcon,
    },
    {
      name: "Action",
      // icon: arrowIcon,
    },
  ];
  const _tabelHeadersBrowseByAgeData = [
    {
      name: "ID No.",
      // icon: arrowIcon,
    },
    {
      name: "Age Range",
      // icon: arrowIcon,
    },
    {
      name: "Updated On",
      // icon: arrowIcon,
    },
    {
      name: "Action",
      // icon: arrowIcon,
    },
  ];
  const _tabelHeaderManageClass = [
    {
      name: "ID No",
    },
    {
      name:
        manageData && activeAccordion === 0 && enrichmentManageClass
          ? "Class Name"
          : isEnrichmentCombo !== "Enrichment Combos"
          ? "Class Name"
          : "Category Name",
    },
    {
      name: "School Name",
    },
    {
      name: "Actions",
    },
  ];

  const accordionHandler = (index) => {
    setEnrichemntCategoryId(undefined);
    setIndexNumber(index);
    setEnrichmentManageClass(false);
    index = parseInt(index);
    setActiveAccordion(index === activeAccordion ? null : index);
    setManageData(false);
    setTableName("Programs");
  };

  useEffect(() => {
    getMasterProgramMenuData();
  }, []);

  useEffect(() => {
    getManageDataFunction();
  }, [manageDataId, searchInput]);

  const getMasterProgramMenuData = async () => {
    const response = await getMasterSubMenu(token, menuId);
    setSubMenu(response);

    const getCmsProgram = await getCmsPrograms(token);
    setProgramData(getCmsProgram);
    const modifiedData = getCmsProgram?.map((programs, index) => {
      return {
        data: [
          programs?.id ? programs?.id : "-",
          programs?.name ? programs?.name : "-",
          programs?.updatedAt
            ? new Date(Number(programs.updatedAt)).toISOString().split("T")[0]
            : "-",
        ],
        item: programs,
      };
    });
    setBrowseByProgramData(modifiedData);

    const getCmsBrowseAge = await getCmsAge(token);
    setAgeData(getCmsBrowseAge);
    const modifiedAgeData = getCmsBrowseAge?.map((age, index) => {
      return {
        data: [
          age?.id ? age?.id : "-",
          age?.age_range ? age?.age_range : "-",
          age?.updatedAt
            ? new Date(Number(age.updatedAt)).toISOString().split("T")[0]
            : "-",
        ],
        item: age,
      };
    });
    setBrowseByAge(modifiedAgeData);
  };

  const getManageDataFunction = async () => {
    if (manageDataId == 175) {
      const getProgramMangeData = await getCampProgramApi();
      setAllClassData(getProgramMangeData?.data);
      setDummyManageProgramData(getProgramMangeData?.data);
      setTotalPages(getProgramMangeData?.totalPages);
      const modifiedProgramManageData = getProgramMangeData?.data?.map(
        (programData, index) => {
          return {
            data: [
              programData?.id ? programData?.id : "-",
              programData?.program_sub_name
                ? programData?.program_sub_name
                : "-",
              "Gymtime",
            ],
          };
        }
      );
      setProgramManageData(modifiedProgramManageData);
    } else {
      if (isEnrichmentCombo === "Enrichment Combos") {
        const getEnrichmentManageData = await getEnrichmentManageProgramData(
          token,
          manageDataId
        );
        setAllClassData(getEnrichmentManageData?.data?.data);
        setDummyManageProgramData(getEnrichmentManageData?.data?.data);
        const modifiedProgramManageData =
          getEnrichmentManageData?.data?.data?.map((programData, index) => {
            return {
              data: [
                programData?.id ? programData?.id : "-",
                programData?.name ? programData?.name : "-",
                "Gymtime",
              ],
            };
          });
        setProgramManageData(modifiedProgramManageData);
      } else {
        const getProgramMangeData = await getManageProgramDataNew(
          token,
          manageDataId,
          searchInput
        );

        setAllClassData(getProgramMangeData?.data);
        setDummyManageProgramData(getProgramMangeData?.data);
        setTotalPages(getProgramMangeData?.totalPages);
        const modifiedProgramManageData = getProgramMangeData?.data?.map(
          (programData, index) => {
            return {
              data: [
                programData?.id ? programData?.id : "-",
                programData?.class_type === "weekly"
                  ? programData?.camp_secondary_name
                  : programData?.name
                  ? programData?.name
                  : "-",
                "Gymtime",
              ],
            };
          }
        );
        setProgramManageData(modifiedProgramManageData);
      }
    }

    const getAgeManageData = await getClassesForAgeMew(
      token,
      manageDataId ? manageDataId : "10",
      searchInput,
      ageRangedRange
    );
    setDummyManageAgeData(getAgeManageData);
    const modifiedAgeManageData = getAgeManageData?.map((ageData, index) => {
      return {
        data: [
          ageData?.id ? ageData?.id : "-",
          ageData?.name ? ageData?.name : "-",
          "Gymtime",
        ],
      };
    });
    setAgeManageData(modifiedAgeManageData);
  };

  const handleAdditionalFieldsChange = (index, key, value) => {
    setIsSearchDropdownActive(true);
  };

  const addNewClassHandler = () => {
    setShowAddClassModal(true);
  };
  const selectAgeHandler = async () => {
    setTableName("Class's Program");
    setManageData(true);
    setActiveAccordion(1);
  };
  const selectProgramHandler = async () => {
    setTableName("Class's Program");
    setManageData(true);
    setActiveAccordion(0);
  };

  const cmsBackBtnHandler = () => {
    setEnrichemntCategoryId(undefined);
    setManageData(false);
    setTableName("Programs");
    setEnrichmentManageClass(false);
  };
  const renderAdditionalFields = () => {
    const additionalFields = [];
    for (let i = 0; i < temp; i++) {
      additionalFields.push(
        <>
          <div key={i} className="adminform__group">
            <select
              name={`selectUser_${i}`}
              id={`selectUser_${i}`}
              className="adminform__select"
              onChange={(e) => {
                handleAdditionalFieldsChange(i, "selectUser", e.target.value);
                accordionHandler(e.target.value);
                setSubtleProgramValue(e.target.value);
              }}
              // value={formData[i]?.selectUser}
            >
              <option value="" selected disabled>
                Search
              </option>
              <option value="0">Browse by Program</option>
              <option value="1">Browse by Age</option>
            </select>
          </div>

          {isSearchDropdownActive && subtleProgramValue == "0" && (
            <div className="adminform__group">
              <select
                className="adminform__select"
                onChange={(e) => {
                  selectProgramHandler();
                  setManageDataId(e.target.value);
                }}
              >
                <option value="" selected disabled>
                  Search browse by Program
                </option>
                {programData?.map((item) => (
                  <option value={item?.id}>{item?.name}</option>
                ))}
              </select>
            </div>
          )}
          {isSearchDropdownActive && subtleProgramValue == "1" && (
            <div className="adminform__group">
              <select
                className="adminform__select"
                onChange={(e) => {
                  selectAgeHandler();
                  // setManageDataId(e.target.value);
                }}
              >
                <option value="" selected disabled>
                  Search browse by Age
                </option>
                {ageData?.map((item) => (
                  <option value={item?.id}>{item?.age_range}</option>
                ))}
              </select>
            </div>
          )}
          <div key={i + `a`} className="adminform__group">
            <input
              type="text"
              className="adminform__input"
              placeholder="Enter Class Name"
              disabled={manageDataId === undefined ? true : false}
              name={`name_${i}`}
              onChange={(e) => {
                setSearchInput(e.target.value);
                // searchClassNameHandler(e.target.value)
              }}
              value={formData[i]?.name}
            />
          </div>
        </>
      );
    }
    return additionalFields;
  };

  const handleAddSearch = () => {
    setTemp((prevTemp) => prevTemp + 1);
  };

  const scrollToTopHandler = () => {
    const scrollContainer = document.getElementById("admin_page_body_id");
    if (scrollContainer) {
      scrollContainer.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const browseByHeaderImageHandler = (name) => {
    setShowHeaderImageModal(true);
    setHeaderName(name);
  };

  return (
    <>
      {
        //adding the Class Modal to add the Classes
        showAddClassModal && (
          <AddClassModal
            setShowAddClassModal={setShowAddClassModal}
            classProgramId={classProgramId}
            classProgramName={classProgramName}
            addClassId={addClassId}
            activeAccordion={activeAccordion}
            isEnrichmentCombo={isEnrichmentCombo}
            getManageDataFunction={getManageDataFunction}
            manageData={manageData}
            enrichmentManageClass={enrichmentManageClass}
            enrichmentCategoryId={enrichmentCategoryId}
            setManageEnrichmentData={setManageEnrichmentData}
          />
        )
      }

      {showHeaderImageModal && (
        <AddHeaderImage
          setShowHeaderImageModal={setShowHeaderImageModal}
          headerName={headerName}
        />
      )}
      <div className="container--component">
        <div className="container--grid">
          <NavigationComponent />

          <div className="Adminpage">
            <Header title={tableName} subTitle="(Manage Available Program)" />
            <section class="Adminpage__section">
              <h2 className="Adminpage__title">Search by:</h2>
              <div className="Adminpage__head">
                <div className="Adminpage__filter">
                  {renderAdditionalFields()}
                  {temp <= 1 ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className="adminform__cancel-button"
                    ></button>
                  )}
                  <div className="adminbutton__group  cms_program_search_btn">
                    <button className="adminbtn adminbtn-primary">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.96326 9.16769L12.3473 11.569M11.2455 6.05994C11.2455 8.49399 9.27231 10.4672 6.83826 10.4672C4.40421 10.4672 2.43103 8.49399 2.43103 6.05994C2.43103 3.62589 4.40421 1.65271 6.83826 1.65271C9.27231 1.65271 11.2455 3.62589 11.2455 6.05994Z"
                          stroke="white"
                          strokeWidth="1.10181"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Search
                    </button>
                  </div>
                </div>

                {manageData &&
                  !enrichmentManageClass &&
                  activeAccordion === 0 && (
                    <button
                      className="adminbtn adminbtn-light export_btn_attendence"
                      id="btnCategory"
                      onClick={() => addNewClassHandler()}
                    >
                      {isEnrichmentCombo !== "Enrichment Combos"
                        ? `+ Add New Class`
                        : `+ Add Category`}
                    </button>
                  )}
                {manageData &&
                  activeAccordion === 0 &&
                  enrichmentManageClass && (
                    <button
                      className="adminbtn adminbtn-light export_btn_attendence"
                      onClick={() => addNewClassHandler()}
                    >
                      + Add Class
                    </button>
                  )}
              </div>

              <div className="back_btn_cms" onClick={() => cmsBackBtnHandler()}>
                <section className="breadcrumbSec breadcrumbSec_cms">
                  <div className="container">
                    <div className="breadcrumb">
                      <div
                        className="breadcrumb__item"
                        style={{ width: "24px", height: "auto" }}
                      >
                        <h2
                          className="breadcrumb__link breadcrumb__link_cms"
                          style={{ fontSize: "1rem" }}
                        >
                          Back to Program
                        </h2>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div class="Adminpage__body" id="admin_page_body_id">
                <div class="accordion">
                  {subMenu?.map((val, index) => {
                    return (
                      <div
                        className={`accordion__card ${
                          activeAccordion === index ? "active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          class="accordion__card__header"
                          key={index}
                          onClick={() => accordionHandler(index)}
                        >
                          <h2 class="title">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.4615 3.7712C7.72183 3.51085 7.72183 3.08874 7.4615 2.82839L5.57588 0.942771C5.53707 0.903964 5.49647 0.868044 5.45433 0.835024C5.00181 0.480349 4.37288 0.459313 3.89943 0.771917C3.82549 0.820731 3.75535 0.877684 3.69026 0.942771L1.80464 2.82839C1.54429 3.08874 1.54429 3.51085 1.80464 3.7712C2.06499 4.03155 2.4871 4.03155 2.74745 3.7712L3.99992 2.51872V12C3.99992 12.3682 4.2984 12.6666 4.66659 12.6666C5.03478 12.6666 5.33325 12.3682 5.33325 12V2.58576L6.51869 3.7712C6.77903 4.03154 7.20117 4.03154 7.4615 3.7712Z"
                                fill="white"
                              />
                              <path
                                d="M8.47162 12.2288C8.21128 12.4892 8.21128 12.9112 8.47162 13.1716L10.3572 15.0572C10.4224 15.1223 10.4925 15.1793 10.5664 15.2281C11.084 15.5698 11.7872 15.5128 12.2429 15.0572L14.1285 13.1716C14.3888 12.9112 14.3888 12.4892 14.1285 12.2288C13.8682 11.9684 13.446 11.9684 13.1857 12.2288L12.0002 13.4142V4.00004C12.0002 3.63185 11.7018 3.33337 11.3336 3.33337C10.9654 3.33337 10.6669 3.63185 10.6669 4.00004V13.4812L9.41442 12.2288C9.15408 11.9684 8.73195 11.9684 8.47162 12.2288Z"
                                fill="white"
                              />
                            </svg>
                            {val?.sub_menu_name}
                          </h2>
                          <div class="actions">
                            <button class="actions__btn accordionToggleBtn">
                              <svg
                                width="14"
                                height="8"
                                viewBox="0 0 14 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.707131 7.28933C0.316631 6.89883 0.316631 6.26563 0.707131 5.87513L5.59933 0.987686C6.38053 0.207286 7.64633 0.207595 8.42713 0.988375L13.3175 5.87873C13.708 6.26923 13.708 6.90243 13.3175 7.29293C12.9269 7.68353 12.2938 7.68353 11.9032 7.29293L7.71763 3.10733C7.32703 2.71683 6.69393 2.71683 6.30343 3.10733L2.12133 7.28933C1.73083 7.67983 1.09763 7.67983 0.707131 7.28933Z"
                                  fill="white"
                                />
                              </svg>
                            </button>
                            <button
                              class="actions__btn"
                              onClick={() =>
                                browseByHeaderImageHandler(val?.sub_menu_name)
                              }
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 5H7C5.89543 5 5 5.89543 5 7V19C5 20.1046 5.89543 21 7 21H9M15 5H17C18.1046 5 19 5.89543 19 7V9"
                                  stroke="white"
                                  strokeWidth="2.33333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14.9015 20.3343L12.7148 20.7716L13.1521 18.585C13.1909 18.3914 13.286 18.2136 13.4256 18.074L17.4995 14L19.4995 12L21.4864 13.9869L19.4864 15.9869L15.4125 20.0608C15.2729 20.2004 15.0951 20.2956 14.9015 20.3343Z"
                                  stroke="white"
                                  strokeWidth="2.33333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                  stroke="white"
                                  strokeWidth="2.33333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div
                          class="accordion__card__body"
                          id="accordion__card__body_id"
                        >
                          <TableNew
                            headerData={
                              manageData
                                ? _tabelHeaderManageClass
                                : activeAccordion === 0
                                ? _tabelHeadersBrowseByProgramData
                                : _tabelHeadersBrowseByAgeData
                            }
                            bodyData={
                              manageData &&
                              activeAccordion === 0 &&
                              enrichmentManageClass
                                ? manageEnrichmentData
                                : manageData && activeAccordion === 0
                                ? programManageData
                                : manageData && activeAccordion === 1
                                ? ageManageData
                                : activeAccordion === 0
                                ? browseByProgramData
                                : browseByAge
                            }
                            totalPages={totalPages}
                            tableName={tableName}
                            setPageLimit="29"
                            setManageData={setManageData}
                            setTableName={setTableName}
                            setManageDataId={setManageDataId}
                            setClassProgramId={setClassProgramId}
                            setClassProgramName={setClassProgramName}
                            dummayManageProgramData={
                              activeAccordion === 0
                                ? dummayManageProgramData
                                : dummyManageAgeData
                            }
                            allClassData={allClassData}
                            activeAccordion={activeAccordion}
                            setIsEnrichmentCombos={setIsEnrichmentCombos}
                            isEnrichmentCombo={isEnrichmentCombo}
                            setEnrichmentManageClass={setEnrichmentManageClass}
                            setManageEnrichmentData={setManageEnrichmentData}
                            enrichmentManageClass={enrichmentManageClass}
                            setEnrichmentData={setEnrichmentData}
                            indexNumber={indexNumber}
                            setIndexNumber={setIndexNumber}
                            setDummyManageProgramData={
                              setDummyManageProgramData
                            }
                            scrollToTopHandler={scrollToTopHandler}
                            setAllClassData={setAllClassData}
                            setAgeRangedRange={setAgeRangedRange}
                            getMasterProgramMenuData={getMasterProgramMenuData}
                            getManageDataFunction={getManageDataFunction}
                            enrichmentCategoryId={enrichmentCategoryId}
                            setEnrichemntCategoryId={setEnrichemntCategoryId}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      {showEditProgramImagesModal && (
        <EditProgramImagesModal
          setShowEditProgramImagesModal={setShowEditProgramImagesModal}
        />
      )}
    </>
  );
}

export default CmsProgram;
