import { contentTypes } from "@fancyapps/ui";
import axios from "axios"

export const popUpsGetAll=async()=>{
   try {
    const data={
        data:""
    }
    const response=await axios.get(
        process.env.REACT_APP_BASE_URL + `/admin/pop_ups`,data,
        {
            headers:{

            }
        }
    ) 
    return response;
   } catch (error) {
    console.error(error);
   }
}