const GET_ALL_ORDERS = `
query GetOrders($orderType: Int, $pageInfo: PaginationInfo, $date: String, $billingInfo: String, $parentOrder: Boolean, $parentDetails: String, $studentName: String, $className: String) {
    getOrders(orderType: $orderType, pageInfo: $pageInfo, date: $date, billingInfo: $billingInfo, parentOrder: $parentOrder, parentDetails: $parentDetails, studentName: $studentName, className: $className) { 
            data 
            { 

              ccExpiryAlert
              message
              isVoidAllowed
      current_recurring
      total_recurring
              is_viewed
                id 
                date 
                school_policy_sign 
                first_name 
                last_name 
                email 
                address1 
                address2 
                city 
                class {
                    name
                  }
                
                state 
                zip 
                country
                 card_expiry_date 
                payment_method_fk 
                payment_processor_fk 
                fk_students_payments 
                fk_clients_orders orderDetails 
                
                { id 
                    fk_class_schedule_order_details
                     classSchedule
                      { 
                        id 
                        class 
                        { 
                            id 
                            name 
                        } }
                     } client
                      { 
                        id 
                        parent1_first_name 
                        parent1_last_name 
                        parent1_cell 
                        parent1_alt_cell 
                        parent1_email 
                        parent1_address 
                        parent1_apartment 
                        parent1_zip 
                        parent1_city parent1_address2 parent1_workphone parent2_first_name parent2_last_name parent2_cell
                         parent2_alt_cell 
                         parent2_email 
                         parent2_address 
                         parent2_address2 
                         parent2_workphone 
                         parent2_apartment 
                         parent2_zip parent2_city 
                         parent1_state 
                         parent2_state 
                         fk_user_clients 
                         createat 
                         updatedat 
                         parent1_image 
                         parent2_image } 
                         student 
                         { id 
                            first_name 
                            last_name 
                            age 
                            fk_clients_students 
                            dob gender allergies doctor_name doctor_phone emergency_name emergency_phone vaccination photo_release parent_signature address image state zipcode } } totalCount totalPages limit currPage } }
                        `;

export { GET_ALL_ORDERS };
