import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { orderModalApi } from "../../adminApi/OrdersAPI";
import { balancePrecission, dateFormatter } from "../../adminUtility";
import CreditCardPayment from "./CreditCardPayment";
import LoaderComponent from "../LoaderComponent";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close-icon.svg";
import { toast } from "react-toastify";

function EnableRecurring({
  setEnablePaymentModal,
  orderID,
  setRecurrinDuration,
  recurringDuration,
  orderData,
  toggleState,
  setToggleState,
  setDeleteCardID,
  setOpenDeleteCardFile,
  fetchData,
  deleteCardOnFile,
}) {
  const [orderApiData, setOrderApiData] = useState();
  const [loading, setLoading] = useState(false);
  const [PrefilledDetails, setPrefilledDetails] = useState();
  const [selectedStateValue, setSelectedStateValue] = useState();
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const year = String(currentDate.getFullYear()).slice(-2);
  const formattedDate = `${month}/${day}/${year}`;

  // ADJUST STATUS
  const [paymentFormData, setPaymentFormData] = useState({
    date: formattedDate,
    memo: "",
    checkNumber: "",
    amountTendered: "",
    paymentMethod: "",
    paymentProcessor: "",
  });
  /// FOR ADHOC
  const [paymentMethodStatus, setPaymentMethodStatus] = useState("cardOnFile");
  const [orderDetailsState, setOrderDetailsState] = useState();
  // PREFILLED DETAILS
  const orderDetails = async () => {
    setLoading(true);
    try {
      const response = await orderModalApi(orderID);
      if (response?.data !== null) {
        setLoading(false);
        setPrefilledDetails(response?.data?.prefilledDetails);
        setRecurrinDuration(response?.data?.recurringDetails?.duration);
        setOrderApiData(response);
        setOrderDetailsState(response?.data?.paymentDetails);
      } else {
        setLoading(false);
        toast.error(response?.error);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    orderDetails();
  }, []);

  const handleRadioChange = (event) => {
    const selectRadio = event.target.value;
    setPaymentMethodStatus(selectRadio);
  };

  useEffect(() => {
    if (PrefilledDetails?.id) {
      let a = {};
      a = {
        date: formattedDate,
        memo: PrefilledDetails?.memo,
        checkNumber: PrefilledDetails?.check_number,
        amountTendered: PrefilledDetails?.amount_tendered,
        paymentMethod: PrefilledDetails?.payment_method_fk,
        paymentProcessor:
          PrefilledDetails?.payment_processor_fk === 1
            ? "Gymtime Merchant Account"
            : "YAP Merchant Account",
      };

      setPaymentFormData(a);
    }
    // setOpenPaymentForm(true);
  }, [PrefilledDetails]);

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, "0");
    const day = `${now.getDate()}`.padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      {loading && <LoaderComponent />}

      <div className="Admin-modal Admin-modal--open">
        <div
          className="Admin-modal__dialog Admin-modal__dialog--lg"
          style={{  minWidth: "96vw", height: "auto" }}
        >
          <div className="Admin-modal__content">
            <div className="Admin-modal__head">
              <h2 className="Admin-modal__head--title">+ Recurring Payment</h2>
              <button
                type="button"
                className="Admin-modal__close"
                onClick={() => {
                  setEnablePaymentModal(false);
                }}
              >
                <CloseIcon />
              </button>
            </div>

            <div className=" adminform__group " style={{ margin: "2%" }}>
              {/* FIRST TABLE  */}
              <table className="table" style={{ marginLeft: "-14px" }}>
                <thead>
                  <tr>
                    <th style={{ color: "white" }}>Date</th>
                    <th style={{ color: "white" }}>Memo</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <input
                        type="date"
                        className="adminform__input"
                        placeholder="Enter Date"
                        style={{
                          width: "120px",
                          height: "40px",
                          background: "#f1f1ff",
                        }}
                        // value={paymentFormData.date}
                        value={getCurrentDate()}
                        onChange={(e) =>
                          setPaymentFormData({
                            ...paymentFormData,
                            date: e.target.value,
                          })
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="adminform__input"
                        placeholder="Enter Memo"
                        style={{
                          width: "120px",
                          height: "40px",
                          background: "#f1f1ff",
                        }}
                        value={paymentFormData.memo}
                        onChange={(e) => {
                          setPaymentFormData({
                            ...paymentFormData,
                            memo: e.target.value,
                          });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <hr />

              {orderApiData?.data?.paymentDetails?.length > 0 ? (
                <>
                  <h2 className="title--h5">Select Which Charges to Pay :</h2>
                  <table className="table" style={{ marginRight: "80px" }}>
                    <thead>
                      <tr>
                        <th style={{ color: "white" }}>Category</th>
                        <th style={{ color: "white" }}>Class Name</th>
                        <th style={{ color: "white" }}>Amount Owed</th>
                        <th style={{ color: "white" }}>Start Date</th>
                        <th style={{ color: "white" }}>End Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderApiData?.data?.paymentDetails?.length > 0 &&
                        orderApiData?.data?.paymentDetails?.map(
                          (val, index) => {
                            return (
                              <tr>
                                <td>{val?.category_name}</td>
                                <td>{val?.class_name}</td>
                                {/* <td>{balancePrecission(val?.proRatedFee)}</td> */}
                                <td>${balancePrecission(val?.tuition)}</td>
                                <td>{dateFormatter(val?.start_date)}</td>
                                <td>{dateFormatter(val?.end_date)}</td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </>
              ) : (
                <p style={{ fontWeight: "600", color: "red" }}>
                  No Enrollments Found
                </p>
              )}

              <hr />
              {/* CARD ON FILE  */}

              <div style={{ display: "flex", gap: "20px" }}>
                <div className="adminform__group">
                  <input
                    type="radio"
                    id="cardOnFile"
                    name="paymentMethod"
                    className="adminform__radio"
                    value="cardOnFile"
                    checked={paymentMethodStatus === "cardOnFile"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="cardOnFile">&nbsp; Card on File</label>
                </div>
                <div className="adminform__group">
                  <input
                    type="radio"
                    id="newCard"
                    name="paymentMethod"
                    className="adminform__radio"
                    value="newCard"
                    checked={paymentMethodStatus === "newCard"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="newCard">&nbsp; New Card</label>
                </div>
              </div>

              {paymentMethodStatus === "newCard" ? (
                <div
                  class="flexbox"
                  style={{
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  <div className="flexbox" style={{ marginLeft: "20px" }}>
                    <div className="column">
                      <label className="title--regular label">
                        Payment Method{" "}
                      </label>
                      <div class="adminform__group">
                        <select
                          name="cardType"
                          id=""
                          className="adminform__select"
                          style={{ width: "250px" }}
                          // value={paymentFormData?.paymentMethod}
                          value={paymentFormData?.paymentMethod}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setSelectedStateValue(selectedValue);

                            setPaymentFormData({
                              paymentMethod: selectedValue,
                            });
                            // }
                          }}
                        >
                          <option value="0">Credit Card</option>
                        </select>
                      </div>
                    </div>
                    <div className="column">
                      <label className="title--regular label">
                        Payment Preprocessor{" "}
                      </label>
                      <div
                        class="adminform__group"
                        style={{ minWidth: "450px" }}
                      >
                        <select
                          name="paymentProcessor"
                          id=""
                          class="adminform__select"
                          value={paymentFormData.paymentProcessor}
                          onChange={(e) => {
                            setPaymentFormData({
                              paymentProcessor: e.target.value,
                            });
                          }}
                        >
                          <option value="" selected disabled>
                            Payment Processor
                          </option>
                          <option value="Gymtime Merchant Account">
                            GymTime Merchant Account
                          </option>
                          <option value="YAP Merchant Account">
                            YAP Merchant Account
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <CreditCardPayment
                paymentMethodStatus={paymentMethodStatus}
                orderDetails={orderDetailsState}
                orderApiData={orderApiData}
                setEnablePaymentModal={setEnablePaymentModal}
                orderID={orderID}
                toggleState={toggleState}
                setOpenDeleteCardFile={setOpenDeleteCardFile}
                PrefilledDetails={PrefilledDetails}
                setLoading={setLoading}
                setDeleteCardID={setDeleteCardID}
                fetchData={fetchData}
                getModalDatafunction={orderDetails}
                paymentFormData={paymentFormData}
                deleteCardOnFile={deleteCardOnFile}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EnableRecurring;
