import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  successModalContent:"",
  userRole: "parent",
  RoleId : ""
};

const successModalSlice = createSlice({
  name: "successModal",
  initialState ,
  reducers: {
    setSuccessModalContent: (state, action) => {
      state.successModalContent = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setRoleID: (state, action) => {
      state.RoleId = action.payload;
    },
  },
});

export const { setSuccessModalContent, setUserRole, setRoleID } =
  successModalSlice.actions;
export default successModalSlice.reducer;
