import * as React from "react";
import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { setAdminModalContentReducer } from "../../../reducers/admin-slice/adminModalsSlice";
import DeleteUser from "../UserModals/DeleteModal";
import { deleteStudent, deleteComment } from "../../adminApi/clients";
import { ReactComponent as DownArrowIcon } from "../../../Parent/parent-assets/images/down-arrow.svg";
import AddComments from "../ClientsModal/AddComment";
import AddNewClass from "../../components/Classess/AddNewClass";

import { getLogs } from "../../adminApi/users";
import { ledgerBalanceHistory } from "../../adminApi/Ledger";
import {
  AgeCalculationFunction,
  FormatDateGMT,
} from "../../../Parent/utility/postRequests";
import { balancePrecission, dateFormatter } from "../../adminUtility";
import { getStudentByClassID } from "../../adminApi/classApi";
import calenderIcon from "../../assets/images/icons/addTrialIcon.png";
import { getWarningForStudent } from "../../adminApi/addClassList";
import { ReactComponent as DeleteRowIcon } from "../../assets/images/icons/deleteRowIcon.svg";
import { ReactComponent as EditRowIcon } from "../../assets/images/icons/editRowIcon.svg";
import { ReactComponent as UpArrowIcon } from "../../assets/images/icons/upArrowIcon.svg";
import AddStudentModal from "../ClientsModal/AddStudentModal";

export default function CollapsibleTable({
  handleCheckboxChange,
  headerData = [],
  bodyData = [],
  subHeader,
  subTable,
  tableType,
  setSortKey,
  SubComment,
  totalPages,
  commentHeader,
  setPageLimit,
  pageCount,
  pageLimit,
  totalCount,
  currentPage,
  setSelectedClassId,
  selectedClassId,
  setMakeUpStatus,
  setSelectedStudentID,
  selectedStudentID,
  makeUpClassStatus,
  loadingState,
  formData,
  setMultiDeleteClass,
  setIsClassEdited,
  isClassEdited,
}) {
  const [activePage, setActivePage] = useState(0);
  const [colSpans, setcolSpans] = useState(
    Array(bodyData?.length).fill(false)
  );
  const [id, setId] = useState();
  const [editStudent, setEditStudent] = useState(false);
  const [addStudent, setAddStudent] = useState(false);
  const [addComment, setAddComment] = useState(false);
  const [subdata, setSubData] = useState();
  const [comment, setComment] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteComments, setDeletesComment] = useState(false);
  const [studentData, setStudentData] = useState(false);
  const [editstudentData, setEditStudentData] = useState();
  const [editComment, setEditComment] = useState(false);
  const [editCommentData, setEditCommentData] = useState();
  const [isAsc, setIsAsc] = useState(true);
  const [addNewClass, setAddNewClasses] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState();
  const [isCamp, setIsCamp] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  // SORTING
  const [expandedItems, setExpandedItems] = useState([]);
  const dispatch = useDispatch();
  const role = useSelector((state) => state?.userRole?.RoleId);

  const handlePageChange = (selectedPage) => {
    dispatch(setAdminModalContentReducer(selectedPage.selected));
    setcolSpans(new Array(colSpans.length).fill(false));
  };

  const handleExpand = (index, item) => {
    const newExpandedItems = [...expandedItems];
    setSelectedItem(item);
    setId(parseInt(item?.id));
    setIsCamp(item?.period);
    localStorage.removeItem("Searched_CLIENT_ID");
    localStorage.removeItem("Ledger_Client");
    localStorage.removeItem("StudentIdForLedger");

    if (tableType == "Clients") {
      localStorage.setItem("Searched_CLIENT_ID", item?.id);
    }
    const itemId = parseInt(item?.id);
    const indexOfItem = newExpandedItems.indexOf(itemId);

    if (indexOfItem !== -1) {
      newExpandedItems.splice(indexOfItem, 1);
    } else {
      newExpandedItems.push(itemId);
    }
    setExpandedItems(newExpandedItems);
    const newcolSpans = [...colSpans];
    newcolSpans[index] = !newcolSpans[index];
    if (newcolSpans[index]) {
      newcolSpans.forEach((colSpan, i) => {
        if (i !== index) {
          newcolSpans[i] = false;
        }
      });
    }
    setcolSpans(newcolSpans);
  };

  useEffect(() => {
    if (selectedItem) {
      setId(parseInt(selectedItem?.id));
    }
  }, [selectedItem]);

  useEffect(() => {
    if (formData) {
      setcolSpans([]);
    }
  }, [formData]);

  async function fetchData() {
    try {
      const token = localStorage.getItem("access_token");
      const response = await subTable(id, token);

      setSubData(response);

      const modifiedData = [...response?.data]?.map((student, index) => {
        return {
          data: [
            student?.id,
            student?.first_name + " " + student?.last_name,
            dateFormatter(student?.dob),
            student?.age,
            student?.gender,
            student?.allergies,
            student?.classDetails?.map((val) => val.className + ", "),
          ],
          item: student,
          onDeletePress: () => {
            setStudentData(parseInt(student?.id));
            setDeleteModal(true);
          },
          onEditPress: (index) => {
            setEditStudentData(student);
            setAddNewClasses(true);
            setEditStudent(true);
          },
        };
      });

      setSubData(modifiedData?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // FETCH STUDENT BY PARNET
  async function fetchStudentFromParent() {
    try {
      const token = localStorage.getItem("access_token");

      const response = await subTable(id, token, 0);

      setSubData(response);
      // setTotalPages(response.totalPages)

      const modifiedData = [...response?.data]?.map((student, index) => {
        return {
          data: [
            student?.id,
            student?.first_name + " " + student?.last_name,
            dateFormatter(student?.dob),
            AgeCalculationFunction(student?.dob),
            student?.gender,
            student?.allergies,
            student?.classDetails?.map((val) => val?.className + ", "),
            <p
              onClick={() => {
                setMakeUpStatus(true);
                setSelectedStudentID(student);
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={calenderIcon} alt="" height="20px" width="20px" />
            </p>,
          ],
          item: student,
          onDeletePress: async () => {
            try {
              const response = await getWarningForStudent(token, student?.id);
              setDeleteMessage(response?.data);
            } catch (error) {}
            setStudentData(parseInt(student?.id));
            setDeleteModal(true);
          },
          onEditPress: (index) => {
            setEditStudentData(student);
            setAddNewClasses(true);
            setEditStudent(true);
          },
        };
      });

      setSubData(modifiedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // GET ALL LEDGERS
  async function fetchClientsLog() {
    try {
      const token = localStorage.getItem("access_token");
      const response = await getLogs(id, token);

      setComment(response);
      const modifiedData = [...response?.data?.getClientLogs]?.map(
        (student, index) => {
          const extraInfo = [];

          if (
            student?.amount_tendered !== null &&
            student?.amount_tendered !== undefined &&
            student?.amount_tendered !== 0
          ) {
            extraInfo.push(`Amount Tendered: $${student?.amount_tendered}`);
          }
          if (
            student?.check_number !== null &&
            student?.check_number !== undefined &&
            student?.check_number !== ""
          ) {
            extraInfo.push(`Check Number: ${student?.check_number}`);
          }
          if (
            student?.card !== null &&
            student?.card !== undefined &&
            student?.card !== ""
          ) {
            extraInfo.push(`Card: ${student?.card}`);
          }
          return {
            data: [
              dateFormatter(student?.payment_date),
              <p>
                {student?.message} <br /> <br />{" "}
                {student?.memo !== "" && <span>MEMO: {student?.memo}</span>}{" "}
              </p>,
              <p>
                ID : {student?.fk_ledger_client_logs} <br />
                {extraInfo.join(", ")}
                <br />
              </p>,
            ],
          };
        }
      );

      setComment(modifiedData || []);
      // setSubData(modifiedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  // LEDGER BALANCE
  async function fetchLedgerBalance() {
    try {
      const token = localStorage.getItem("access_token");
      const response = await ledgerBalanceHistory(token, id);

      const modifiedData = await Promise.all(
        response?.data?.map(async (comment, index) => {
          const formattedPaymentDate = await FormatDateGMT(
            comment?.payment_date
          );
          const chargeDate = await FormatDateGMT(comment?.charge_date);
          return {
            data: [
              comment?.name,
              comment?.category_name,
              `$ ${balancePrecission(comment?.class_fee)}`,
              formattedPaymentDate,
              chargeDate,
              `$  ${balancePrecission(comment?.total_amount_paid)}`,
              comment?.notes ? comment?.notes : "-",
            ],
            item: comment,
          };
        })
      );
      setSubData(modifiedData);
      // setComment(modifiedData || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // COMMENTS
  async function fetchComment() {
    try {
      const token = localStorage.getItem("access_token");
      if (id !== undefined) {
        const response = await SubComment(id, token);
        const modifiedData = [...response?.data]?.map((comment, index) => {
          return {
            data: [
              dateFormatter(comment?.date),
              comment?.subject,
              comment?.comment,
            ],
            item: comment,
            onDeletePress: () => {
              setStudentData(parseInt(comment.id));
              setDeletesComment(true);
            },
            onEditPress: () => {
              setEditCommentData(comment);
              setEditComment(true);
            },
          };
        });
        setComment(modifiedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  //GET Student Details oF CLASSES, Get Student By Class ID
  async function fetchStudentDetails() {
    try {
      const token = localStorage.getItem("access_token");
      const response = await getStudentByClassID(token, parseInt(id), isCamp);

      const modifiedData = [...response?.data]?.map((student, index) => {
        return {
          data: [
            student?.id,
            <p>
              {student?.first_name} {student?.last_name}
            </p>,
            <p> {AgeCalculationFunction(student?.dob)} </p>,
            <p> {student?.proRatedFee}</p>,
          ],
          item: student,
        };
      });
      setSubData(modifiedData);
    } catch (error) {
      console.error("Error fetching student details:", error);
    }
  }

  // Classes Sort ::
  const handleHeaderClick = async (index) => {
    const clickedItem = headerData[index];

    if (clickedItem && clickedItem?.icon) {
      setIsAsc(!isAsc);
      setSortKey({
        sortId: index,
        isAsc: !isAsc,
      });
    }
  };

  useEffect(() => {
    fetchComment();

    if (tableType === "Classes") {
      fetchStudentDetails();
    }

    if (tableType === "Clients") {
      fetchStudentFromParent();
      fetchComment();
    }
  }, [id, editStudent]);

  useEffect(() => {
    if (tableType === "Classes") {
      fetchStudentDetails();
      fetchData();
    }

    if (tableType === "Clients" && selectedClassId) {
      fetchStudentFromParent();
    }
  }, []);

  //  LEDGR BALANCE AND LOGS
  useEffect(() => {
    if (tableType === "Ledger" && id !== undefined) {
      fetchClientsLog();
      fetchLedgerBalance();
    }
  }, [id, tableType]);

  // Balannce table
  return (
    <>
      <div className="Adminpage__body" style={{ minHeight: "auto" }}>
        <table>
          <thead>
            <tr>
              {makeUpClassStatus ? "" : <th style={{ minWidth: "1px" }}></th>}
              {headerData?.map((i, index) => {
                return (
                  <th
                    style={{ minWidth: "200px" }}
                    key={index}
                    onClick={() => handleHeaderClick(index)}
                  >
                    <span style={{ cursor: "pointer" }}>
                      {i.icon}
                      {i.name}
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>

          {loadingState ? (
            <tbody>
              <tr
                style={{
                  width: "100%",
                  textAlign: "left",
                  paddingLeft: "5%",
                  height: "100%",
                }}
              >
                <td colSpan={headerData?.length}>
                  <p>Loading...</p>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {bodyData?.length ? (
                bodyData?.map((categoryItem, ind) => {
                  const category = categoryItem?.item;
                  const isExpanded = expandedItems.includes(ind);
                  return (
                    <React.Fragment key={ind}>
                      <tr key={ind}>
                        {tableType === "MAKEUP" ? null : (
                          <td style={{ minWidth: "1px" }}>
                            <div className="flexbox">
                              <button
                                className="accordion-button"
                                style={{ width: "auto" }}
                                onClick={() => handleExpand(ind, category)}
                              >
                                {isExpanded ? (
                                  <DownArrowIcon />
                                ) : (
                                  <UpArrowIcon />
                                )}
                              </button>
                            </div>
                          </td>
                        )}

                        {categoryItem?.data?.map((text, index) => {
                          return (
                            <td key={index}>
                              <span>{text}</span>
                            </td>
                          );
                        })}

                        <td>
                          <div className="adminbutton__group">
                            <button
                              className=""
                              onClick={() => {
                                categoryItem?.onEditPress(category?.id);
                              }}
                            >
                              <EditRowIcon />
                            </button>

                            <button
                              className=""
                              id="btnDelete"
                              onClick={() => {
                                categoryItem.onDeletePress();
                              }}
                            >
                              <DeleteRowIcon />
                            </button>
                          </div>
                        </td>
                      </tr>

                      {/* ACCORDION  */}
                      {tableType !== "MAKEUP" && (
                        <tr>
                          {colSpans[ind] && id ? (
                            <td colSpan="8">
                              <div className="accordion">
                                <div className="accordion-item active">
                                  <div
                                    className="accordion-content"
                                    style={{
                                      minHeight: "300px",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    <div className="accordion__tableCard">
                                      <div className="accordion__header">
                                        <div className="item">
                                          {tableType === "Ledger" ? (
                                            <h2
                                              className="title--h2 label"
                                              style={{
                                                fontSize: "25px",
                                                fontWeight: "normal",
                                              }}
                                            >
                                              Balance
                                            </h2>
                                          ) : (
                                            <h2
                                              className="title"
                                              style={{
                                                fontSize: "25px",
                                                fontWeight: "normal",
                                              }}
                                            >
                                              Students Details
                                            </h2>
                                          )}
                                        </div>
                                        {tableType === "Classes" ||
                                        tableType === "Ledger" ? (
                                          ""
                                        ) : (
                                          <div className="item">
                                            <button
                                              id="adminbtnAddNewStudent"
                                              className="adminbtn adminbtn-primary"
                                              style={{ height: "35px" }}
                                              onClick={() => {
                                                setAddStudent(true);
                                              }}
                                            >
                                              + Add Student
                                            </button>
                                          </div>
                                        )}
                                      </div>

                                      {/* SUB TABLE For Every Class , Client and Ledger */}
                                      <table
                                        style={{
                                          width:
                                            tableType === "Ledger"
                                              ? "50vw"
                                              : "auto",
                                        }}
                                      >
                                        <thead>
                                          <tr>
                                            {subHeader?.map((i) => (
                                              <th>
                                                <span>
                                                  {i.icon}
                                                  {i.name}
                                                </span>
                                              </th>
                                            ))}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {subdata?.length ? (
                                            subdata?.map((item, ind) => {
                                              return (
                                                <>
                                                  <tr
                                                    style={{
                                                      cursor:
                                                        tableType === "Clients"
                                                          ? "pointer"
                                                          : "default",
                                                    }}
                                                    key={ind}
                                                    onClick={() => {
                                                      if (
                                                        tableType === "Clients"
                                                      ) {
                                                        const studentId =
                                                          localStorage.getItem(
                                                            "StudentIdForLedger"
                                                          );
                                                        if (studentId) {
                                                          localStorage.removeItem(
                                                            "StudentIdForLedger"
                                                          );
                                                        } else {
                                                          localStorage.setItem(
                                                            "StudentIdForLedger",
                                                            item?.item?.id
                                                          );
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    {item?.data?.map(
                                                      (text, index) => {
                                                        return (
                                                          <td
                                                            key={index}
                                                            style={{
                                                              minWidth: "200px",
                                                            }}
                                                          >
                                                            <span>{text}</span>
                                                          </td>
                                                        );
                                                      }
                                                    )}
                                                    {![
                                                      "Classes",
                                                      "Ledger",
                                                      "MAKEUP",
                                                    ].includes(tableType) && (
                                                      <td>
                                                        <div className="adminbutton__group">
                                                          <button
                                                            className=""
                                                            onClick={() => {
                                                              item.onEditPress(
                                                                ind
                                                              );
                                                            }}
                                                          >
                                                            <EditRowIcon />
                                                          </button>
                                                          <button
                                                            className=""
                                                            id="btnDelete"
                                                            onClick={() => {
                                                              item.onDeletePress();
                                                            }}
                                                          >
                                                            <DeleteRowIcon />
                                                          </button>
                                                        </div>
                                                      </td>
                                                    )}
                                                  </tr>
                                                </>
                                              );
                                            })
                                          ) : (
                                            <tr>
                                              <td
                                                colSpan={headerData.length}
                                                className="no-data"
                                              >
                                                No Data Found
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>

                                    {tableType === "Ledger" ? (
                                      <div
                                        className="accordion__tableCard scrollable-container"
                                        style={{
                                          height: "40vh",
                                          overflowY: "scroll",
                                        }}
                                      >
                                        <div className="accordion__header">
                                          <div className="item">
                                            <h2
                                              className="title--h2 label"
                                              style={{
                                                fontSize: "25px",
                                                fontWeight: "normal",
                                              }}
                                            >
                                              Client logs
                                            </h2>
                                          </div>
                                        </div>
                                        <table
                                          style={{
                                            width: "50%",
                                            height: "20%",
                                            overflowY: "scroll",
                                          }}
                                        >
                                          <thead>
                                            <tr>
                                              {commentHeader?.map((i) => (
                                                <th style={{ width: "200px" }}>
                                                  <span>
                                                    {i.icon}
                                                    {i.name}
                                                  </span>
                                                </th>
                                              ))}
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {comment?.length ? (
                                              comment?.map((item, ind) => {
                                                return (
                                                  <>
                                                    <tr
                                                      key={ind}
                                                      style={{
                                                        border:
                                                          "1px solid #eee",
                                                      }}
                                                    >
                                                      {item?.data?.map(
                                                        (text, index) => {
                                                          return (
                                                            <td>
                                                              <span>
                                                                {text}
                                                              </span>
                                                            </td>
                                                          );
                                                        }
                                                      )}
                                                      {tableType ===
                                                      "Ledger" ? (
                                                        ""
                                                      ) : (
                                                        <td>
                                                          <div className="adminbutton__group">
                                                            <button
                                                              // disabled={role == "4"}
                                                              className="adminbtn"
                                                              onClick={() => {
                                                                item.onEditPress();
                                                              }}
                                                            >
                                                              <EditRowIcon />
                                                            </button>
                                                            <button
                                                              // disabled={role == "4"}
                                                              className="adminbtn"
                                                              id="btnDelete"
                                                              onClick={() => {
                                                                item.onDeletePress();
                                                              }}
                                                            >
                                                              <DeleteRowIcon />
                                                            </button>
                                                          </div>
                                                        </td>
                                                      )}
                                                    </tr>
                                                    <tr></tr>
                                                  </>
                                                );
                                              })
                                            ) : (
                                              <tr>
                                                <td
                                                  colSpan={headerData.length}
                                                  className="no-data"
                                                >
                                                  No Data Found
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    ) : (
                                      <>
                                        {tableType === "Classes" ? (
                                          ""
                                        ) : (
                                          <div className="accordion__tableCard">
                                            <div className="accordion__header">
                                              <div className="item">
                                                <h2
                                                  className="title"
                                                  style={{
                                                    fontSize: "25px",
                                                    fontWeight: "normal",
                                                  }}
                                                >
                                                  Comments
                                                </h2>
                                              </div>
                                              <div className="item">
                                                <button
                                                  className="adminbtn adminbtn-primary"
                                                  style={{ height: "35px" }}
                                                  onClick={() => {
                                                    setAddComment(true);
                                                  }}
                                                >
                                                  + Add Comments
                                                </button>
                                              </div>
                                            </div>
                                            <table>
                                              <thead>
                                                <tr>
                                                  {commentHeader?.map((i) => (
                                                    <th>
                                                      <span>
                                                        {i.icon}
                                                        {i.name}
                                                      </span>
                                                    </th>
                                                  ))}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {comment?.length ? (
                                                  comment?.map((item, ind) => {
                                                    return (
                                                      <>
                                                        <tr key={ind}>
                                                          {item?.data?.map(
                                                            (text, index) => {
                                                              return (
                                                                <td>
                                                                  <span>
                                                                    {text}
                                                                  </span>
                                                                </td>
                                                              );
                                                            }
                                                          )}

                                                          <td>
                                                            <div className="adminbutton__group">
                                                              <button
                                                                className="adminbtn"
                                                                onClick={() => {
                                                                  item.onEditPress();
                                                                }}
                                                              >
                                                                <EditRowIcon />
                                                              </button>
                                                              <button
                                                                className="adminbtn"
                                                                id="btnDelete"
                                                                onClick={() => {
                                                                  item.onDeletePress();
                                                                }}
                                                              >
                                                                <DeleteRowIcon />
                                                              </button>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        <tr></tr>
                                                      </>
                                                    );
                                                  })
                                                ) : (
                                                  <tr>
                                                    <td
                                                      colSpan={
                                                        headerData.length
                                                      }
                                                      className="no-data"
                                                    >
                                                      No Data Found
                                                    </td>
                                                  </tr>
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerData.length} className="no-data">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>

      {/* PAGINATION AND SHOW MORE  */}
      {makeUpClassStatus ? (
        ""
      ) : (
        <div className="pagination-container">
          <ReactPaginate
            pageCount={Math.ceil(
              bodyData?.totalPages ? bodyData?.totalPages : totalPages
            )}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageChange}
            initialPage={activePage}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
          <div className="sub-pagination-container">
            <p style={{ fontSize: "16px", flex: "none" }}>
              Showing Data [{pageLimit * (currentPage + 1) + 1 - pageLimit}-{" "}
              {totalCount < pageLimit * (currentPage + 1)
                ? totalCount
                : pageLimit * (currentPage + 1)}{" "}
              of {totalCount}]
            </p>
            <select
              onChange={(e) => {
                setPageLimit(e.target.value);
              }}
              className="adminform__select"
              style={{ minWidth: "50px" }}
            >
              <option disabled>Select Rows</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
          </div>
        </div>
      )}

      {addStudent && (
        <AddStudentModal
          openAddNewUser={addStudent}
          data={id}
          setAddNewUser={setAddStudent}
          type="Add"
          // fetchData={fetchData}
          fetchData={fetchStudentFromParent}
        />
      )}

      {/* PREVIOSU  */}

      {editStudent && (
        <AddStudentModal
          openAddNewUser={editStudent}
          data={editstudentData}
          setAddNewUser={setEditStudent}
          type="Edit"
          // fetchData={fetchData}
          fetchData={fetchStudentFromParent}
        />
      )}

      {editComment ? (
        <AddComments
          openAddNewUser={editComment}
          data={editCommentData}
          setAddNewUser={setEditComment}
          type="Edit"
          fetchData={fetchComment}
        />
      ) : null}

      {addComment ? (
        <AddComments
          openAddNewUser={addComment}
          data={id}
          setAddNewUser={setAddComment}
          type="Add"
          fetchData={fetchComment}
        />
      ) : null}

      {deleteModal ? (
        <DeleteUser
          openDeleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          data={studentData}
          // fetchData={fetchData}
          fetchData={fetchStudentFromParent}
          deleteQuery={deleteStudent}
          type={"Student"}
          deleteMessage={deleteMessage}
        />
      ) : null}

      {deleteComments ? (
        <DeleteUser
          openDeleteModal={deleteComments}
          setDeleteModal={setDeletesComment}
          data={studentData}
          fetchData={fetchComment}
          deleteQuery={deleteComment}
        />
      ) : null}

      {deleteComments ? (
        <DeleteUser
          openDeleteModal={deleteComments}
          setDeleteModal={setDeletesComment}
          data={studentData}
          fetchData={fetchComment}
          deleteQuery={deleteComment}
        />
      ) : null}

      {/* EDIT CLASS  */}

      {addNewClass ? <AddNewClass setAddNewClasses={setAddNewClasses} /> : null}
    </>
  );
}
