import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Editor from 'react-simple-wysiwyg';
import { toast } from 'react-toastify';
import "./editClassModal.css"
const GymCheerEditor = ({ classData,setShowCMSEditModal }) => {
    const [editorOneData, setEditorOneData] = useState(classData?.[0]?.content?.[0]?.first_one);
    const [editorTwoData, setEditorTwoData] = useState(classData?.[0]?.content?.[1]?.second_one);
    useEffect(()=>{
        setEditorOneData(classData?.[0]?.content?.[0]?.first_one);
        setEditorTwoData(classData?.[0]?.content?.[1]?.second_one);
    },[classData])
    const saveTextHandler = async () => {
        const token = localStorage.getItem("access_token")
        try {
            const postData = {
                subprogramId: parseInt(classData?.[0]?.id),
                content: [
                    {
                        first_one: editorOneData
                    },
                    {
                        second_one: editorTwoData
                    }
                ]
            }
            const response = await axios.post(
                process.env.REACT_APP_BASE_URL + `/admin/create_post_program_name`, postData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            }
            );
            if (response?.status === 200 || response?.status === 201) {
                toast.success(`Program Updated Successfully`);
                setShowCMSEditModal(false);
            }
        } catch (error) {
            toast.error("Something went wrong!");
            setShowCMSEditModal(false);
        }
    }
    return (
        <>
            <div className="fluid_container">
                <div className="three_four_half_day_container"
                style={{display:"flex",justifyContent:"space-evenly"}}
                >
                    <div className="half_day_container">
                        <h1 className='schedule_day_title_camp'>Mandatory Practice</h1>
                         <Editor
                            value={editorOneData}
                            onChange={(e) => setEditorOneData(e.target.value)}
                        />
                    </div>
                    <div className="full_day_container">
                        <h1 className='schedule_day_title_camp'>Try outs</h1>
                        <Editor
                            value={editorTwoData}
                            onChange={(e) => setEditorTwoData(e.target.value)}
                        />
                    </div>
                </div>

            </div>
            <button className='adminbtn adminbtn-primary camp_btm'
                onClick={() => saveTextHandler()}
                style={{ marginTop: "60px",marginLeft:"25px",float:"left"}}
            >Save</button>
        </>
    )
}

export default GymCheerEditor