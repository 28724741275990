import React, { useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close-icon.svg";
import { useState } from "react";
import Select from "react-select";
import { customStyles, timeFormatter } from "../../adminUtility";
import LoaderComponent from "../LoaderComponent.jsx";

import {
  getClassScheduleDetails,
  getWeeklyClassByID,
  saveSwitchingClass,
  saveWeeklySwitchingClasss,
} from "../../adminApi/clients";
import { toast } from "react-toastify";

function SwtichClassModal({
  setOpenSwitchModal,
  OpenSwitchModal,
  fetchStudentDetails,
}) {
  const [selectedClass, setSelectedClass] = useState({ id: "", name: "" });
  const token = localStorage.getItem("access_token");
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState();

  const classOptions = [
    { value: "", label: "Select Class" },
    ...(Array.isArray(OpenSwitchModal?.data)
      ? OpenSwitchModal?.data
          .filter((val) => val?.name.trim() !== "")
          .map((val) => {
            return {
              value: val?.id,
              label: `${val?.name} ${
                val?.class_schedules?.length > 0 ? ", (" : ""
              }${val?.class_schedules
                ?.map((el) => {
                  if (el?.weekday && el?.start_time && el?.end_time) {
                    return `${el.weekday.substring(0, 3)} ${timeFormatter(
                      el.start_time
                    )} - ${timeFormatter(el.end_time)}`;
                  }
                  return null;
                })
                .filter(Boolean)
                .join(", ")} ${val?.class_schedules?.length > 0 ? ")" : ""}`,
              classData: val,
            };
          })
      : []),
  ];

  const handleClassChange = (selectedOption) => {
    if (OpenSwitchModal?.val?.period == "Monthly") {
      setSelectedClass({
        id: selectedOption.value,
        name: selectedOption.label,
      });
    } else {
      setTableData(selectedOption?.classData);
      setSelectedClass({
        id: selectedOption.value,
        name: selectedOption.label,
      });
    }
  };

  useEffect(() => {
    if (selectedClass?.id !== "" && OpenSwitchModal?.val?.period !== "Weekly") {
      const getClassScheduled = async () => {
        try {
          let response = "";
          if (OpenSwitchModal?.val?.period === "Monthly") {
            response = await getClassScheduleDetails(
              parseInt(selectedClass?.id),
              token
            );
            setTableData(response);
          } else {
            response = await getWeeklyClassByID(
              token,
              parseInt(selectedClass?.id)
            );
            setTableData(response);
          }
        } catch (error) {
          return error;
        }
      };
      getClassScheduled();
    }
  }, [selectedClass]);

  const handleSubmit = async () => {
    const payload = {
      studentId: OpenSwitchModal?.studentId,
      currentClassId:
        OpenSwitchModal?.val?.period == "Monthly"
          ? OpenSwitchModal?.val?.classId
          : OpenSwitchModal?.val?.classScheduleId,
      newClassId: selectedClass?.id,
    };

    try {
      setLoading(true);
      let response = "";

      if (OpenSwitchModal?.val?.period == "Monthly") {
        response = await saveSwitchingClass(payload, token);
      } else {
        response = await saveWeeklySwitchingClasss(payload, token);
      }

      if (
        response?.data?.data !== null &&
        response?.data?.data === "Class switched successfully."
      ) {
        setLoading(false);
        toast.success(response?.data?.data);
        fetchStudentDetails();
        setOpenSwitchModal({
          isOpen: false,
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error);
    }
  };

  

  return (
    <div className="Admin-modal Admin-modal--open">
      {loading && <LoaderComponent />}
      <div className="Admin-modal__dialog Admin-modal__dialog--lg">
        <div className="Admin-modal__content">
          <div className="Admin-modal__head">
            <h2 className="Admin-modal__head--title"> Switch Class</h2>
            <button
              type="button"
              className="Admin-modal__close"
              onClick={() => {
                setOpenSwitchModal({
                  isOpen: false,
                });
              }}
            >
              <CloseIcon />
            </button>
          </div>

          <div
            className="flexbox"
            style={{ padding: "30px", marginBottom: "80px" }}
          >
            <div className="column">
              <div className="adminform__group">
                <label htmlFor="" className="label">
                  Class Name
                </label>
                <input
                  type="text"
                  className="adminform__input"
                  value={OpenSwitchModal?.val?.className}
                  disabled
                />
              </div>
            </div>
            <div className="column">
              <div className="adminform__group">
                <label htmlFor="" className="label">
                  Choose Class To Switch
                </label>
                <Select
                  styles={customStyles}
                  options={classOptions}
                  placeholder={"Search here"}
                  onChange={handleClassChange}
                />
              </div>
            </div>
          </div>
          <div className="flexbox">
            {tableData?.length !== undefined && (
              <div className="Table">
                {tableData?.length ? (
                  <div className="Heading">
                    <div className="Cell" style={{ width: "5%" }}>
                      <p>Category</p>
                    </div>
                    <div
                      className="Cell"
                      style={{ width: "35%", maxWidth: "25%" }}
                    >
                      <p>Class Name</p>
                    </div>
                    <div
                      className="Cell"
                      style={{ width: "35%", maxWidth: "35%" }}
                    >
                      <p>Schedules</p>
                    </div>
                    <div className="Cell" style={{ width: "20%" }}>
                      <p>Slots</p>
                    </div>
                  </div>
                ) : null}

                {tableData?.length ? (
                  tableData?.map((val) => (
                    <div className="Row" key={val?.id}>
                      <div className="Cell">
                        <p>{val?.category?.name ? val?.category?.name : "-"}</p>
                      </div>
                      <div className="Cell" style={{ maxWidth: "120px" }}>
                        <p>{val?.name ? val?.name : "-"}</p>
                      </div>

                      <div className="Cell">
                        {val?.class_schedules?.length ? (
                          <div className="flexbox">
                            <div
                              className="column"
                              style={{ maxWidth: "80px" }}
                            >
                              <p style={{ textDecoration: "underline" }}>
                                WeekDay
                              </p>
                            </div>
                            <div
                              className="column"
                              style={{ maxWidth: "100px" }}
                            >
                              <p style={{ textDecoration: "underline" }}>
                                Start Time
                              </p>
                            </div>
                            <div
                              className="column"
                              style={{ maxWidth: "100px" }}
                            >
                              <p style={{ textDecoration: "underline" }}>
                                End Time
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p>No Schedules Found!</p>
                        )}
                        {val?.class_schedules ? (
                          val?.class_schedules.map((sched, index) => (
                            <div className="flexbox" key={index}>
                              <div
                                className="column"
                                style={{ maxWidth: "100px" }}
                              >
                                <p style={{ padding: "0px" }}>
                                  {sched?.weekday}
                                </p>
                              </div>
                              <div
                                className="column"
                                style={{ maxWidth: "80px" }}
                              >
                                <p style={{ padding: "0px" }}>
                                  {timeFormatter(sched?.start_time)}
                                </p>
                              </div>
                              <div
                                className="column"
                                style={{ maxWidth: "100px" }}
                              >
                                <p style={{ padding: "0px" }}>
                                  {timeFormatter(sched?.end_time)}
                                </p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>No Schedules</p>
                        )}
                      </div>

                      <div className="Cell">
                        Total Slots: {val?.max_strength}
                        <br />
                        Available Slots:{" "}
                        {val?.max_strength - val?.classes_enrolled?.length !==
                        undefined
                          ? val?.max_strength - val?.classes_enrolled?.length
                          : "N/A"}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="shortText">
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: "1.5rem",
                        alignItems: "center",
                        textAlign: "center",
                        color: "red",
                      }}
                    >
                      No Schedules found!
                    </p>
                  </div>
                )}
              </div>
            )}

            {/* TABLE FOR CAMP  */}

            {OpenSwitchModal?.val?.period === "Weekly" &&
              tableData !== undefined && (
                <div className="Table">
                  <div className="Heading">
                    <div className="Cell" style={{ width: "5%" }}>
                      <p>Category</p>
                    </div>
                    <div
                      className="Cell"
                      style={{ width: "35%", maxWidth: "25%" }}
                    >
                      <p>Camp Name</p>
                    </div>
                    <div
                      className="Cell"
                      style={{ width: "35%", maxWidth: "35%" }}
                    >
                      <p>Schedules</p>
                    </div>
                    <div className="Cell" style={{ width: "20%" }}>
                      <p>Slots</p>
                    </div>
                  </div>
                  <div className="Row">
                    <div className="Cell">
                      {tableData?.category_name
                        ? tableData?.category_name
                        : "-"}
                    </div>
                    <div className="Cell">
                      {tableData?.name ? tableData?.name : "-"}
                    </div>
                    <div className="Cell">
                      {tableData?.class_schedules?.length > 0 && (
                        <div className="flexbox">
                          <div className="column">
                            <label
                              className="label"
                              htmlFor=""
                              style={{ textDecoration: "underline" }}
                            >
                              Weekday
                            </label>
                          </div>
                          <div className="column">
                            <label
                              className="label"
                              htmlFor=""
                              style={{ textDecoration: "underline" }}
                            >
                              Start Time
                            </label>
                          </div>
                          <div className="column">
                            <label
                              className="label"
                              htmlFor=""
                              style={{ textDecoration: "underline" }}
                            >
                              End Time
                            </label>
                          </div>
                        </div>
                      )}

                      {tableData?.class_schedules
                        ? tableData?.class_schedules?.map((val) => {
                            return (
                              <div className="flexbox">
                                <div
                                  className="column"
                                  style={{ margin: "0px", padding: "0px" }}
                                >
                                  <span>
                                    {" "}
                                    {val?.weekday ? val?.weekday : "-"}
                                  </span>
                                </div>
                                <div className="column">
                                  <span>
                                    {" "}
                                    {val?.start_time
                                      ? timeFormatter(val?.start_time)
                                      : "-"}
                                  </span>
                                </div>
                                <div className="column">
                                  <span>
                                    {val?.end_time
                                      ? timeFormatter(val?.end_time)
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        : "-"}
                    </div>
                    <div className="Cell">
                      <p> ${tableData?.tuition}</p>
                    </div>
                  </div>
                </div>
              )}
          </div>
          {/* MODAL FOOTER  */}
          <div
            className="Admin-modal__footer"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div
              className="admin-button__group"
              style={{ display: "flex", gap: "20px" }}
            >
              <button
                className="adminbtn adminbtn-blueOutline"
                onClick={() => {
                  setOpenSwitchModal({
                    isOpen: false,
                  });
                }}
              >
                Cancel
              </button>

              <button
                className="adminbtn adminbtn-blueOutline"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SwtichClassModal;
