import { useEffect, useRef, useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import {
  adminLedgerDetails,
  enableButtonAPI,
  getAllCards,
  ledgerPaymentAPI,
} from "../../adminApi/Ledger";
import PaymentModal from "./PaymentModal";
import LoaderComponent from "../LoaderComponent";
import { toast } from "react-toastify";
import {
  balancePrecission,
  dateFormatter,
  getCurrentDateLedger,
} from "../../adminUtility";
import DeleteModal from "../UserModals/DeleteModal";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close-icon.svg";
import TrashIcon from "../../../Parent/parent-assets/images/trash-icon-red.svg";

function AddPaymentModals({
  enableResponse,
  addModal,
  setAddModal,
  showPaymentModal,
  selectedLedgerDetails,
  selectedLedgerID,
  setShowPaymentModal,
  setRecurrinDuration,
  setLedgerDetails,
  recurringDuration,
  ledgerData,
  fetchData,
  buttonType,
  setCardStatus,
  enableApiFunction,
  // cardLists,
}) {
  const [cardLists, setCardLists] = useState();
  const [monthlyStatus, setMonthlyStatus] = useState("MONTHLY");
  const [ledgerApiData, setLedgerApiData] = useState();
  const [loading, setLoading] = useState(false);
  const [openPaymentForm, setOpenPaymentForm] = useState(false);
  const [amountCalculated, setAmountCalculated] = useState();
  const [PrefilledDetails, setPrefilledDetails] = useState();
  const [totalAmount, setTotalAmount] = useState([]);
  const [selectedStateValue, setSelectedStateValue] = useState();
  const [paymentError, setPaymentError] = useState("");
  const [amountTendered, setAmountTendered] = useState();
  const [totalSumValue, setTotalSumValue] = useState();
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const year = String(currentDate.getFullYear()).slice(-2);
  const formattedDate = `${month}/${day}/${year}`;
  const [totalCalcAmount, setTotalCalcAmount] = useState(0);
  const [balanceData, setBalanceData] = useState([]);
  const [accountCreditBalance, setAccountCreditBalance] = useState(0);
  // ADJUST STATUS
  const dropdownRef = useRef(null);
  const [adjustAmount, setAdjustAmount] = useState(false);
  const [toggleState, setToggleState] = useState(true);
  const [deleteCardID, setDeleteCardID] = useState();
  const [openDeleteCardFile, setOpenDeleteCardFile] = useState(false);
  const [paymentMethodStatus, setPaymentMethodStatus] = useState("cardOnFile");
  const [adhocTotal, setAdhocTotal] = useState();
  const [isCardOnFile, setIsCardOnFile] = useState(false);
  const [paymentFormData, setPaymentFormData] = useState({
    date: formattedDate,
    memo: "",
    checkNumber: "",
    amountTendered: "",
    paymentMethod: "",
    paymentProcessor: "",
  });
  const [adhocBalanceData, setAdhocBalanceData] = useState([]);
  /// FOR ADHOC
  const [amountToPayTotalNumber, setAmountToPayTotalNumber] = useState();
  const [deleteCardOnFile, setDeleteCardOnFile] = useState({
    isDeleted: false,
    deleteArray: [],
  });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [totalAmountOwed, setTotalAmountOwed] = useState(0);
  const [dynamicAT, setDynamicAT] = useState(0.0);

  // PREFILLED DATA
  useEffect(() => {
    if (PrefilledDetails?.id) {
      let a = {};
      a = {
        date: formattedDate,
        paymentMethod: PrefilledDetails?.payment_method_fk,
        paymentProcessor: PrefilledDetails?.payment_processor_fk,
      };
      if (PrefilledDetails?.payment_method_fk === "0") {
        setSelectedStateValue("0");
        a = {
          ...a,
          paymentMethod: PrefilledDetails?.payment_method_fk,
        };
      }

      if (PrefilledDetails?.payment_method_fk === "1") {
        setSelectedStateValue("1");
        a = {
          ...a,
          paymentMethod: PrefilledDetails?.payment_method_fk,
        };
      }

      if (PrefilledDetails?.payment_method_fk === "2") {
        setSelectedStateValue("2");

        a = {
          ...a,
          paymentMethod: PrefilledDetails?.payment_method_fk,
        };
      }
      if (PrefilledDetails?.payment_method_fk) {
        setIsCardOnFile(true);
      }
      setPaymentFormData(a);
    }
    if (buttonType === "ENABLE") {
      setOpenPaymentForm(true);
    }
  }, [PrefilledDetails]);

  // SELECTED LEDGER DATA CAlled in USEFFECT
  const ledgerDetails = async () => {
    if (buttonType === "PAY") {
      setLoading(true);
      const token = localStorage.getItem("access_token");
      const response = await adminLedgerDetails(token, selectedLedgerID);
      setPrefilledDetails(response?.data?.prefilledDetails);
      setLoading(false);
      setRecurrinDuration(response?.data?.recurringDetails?.duration);
      setBalanceData(
        response?.data?.paymentDetails?.map((item) => {
          return {
            ...item,
            classSchedule: item?.classSchedule,
            amountToPay: "",
          };
        })
      );
      let sum = 0,
        sum1 = 0;
      response?.data?.paymentDetails?.forEach((item) => {
        sum = sum + item?.proRatedFee;
      });
      setTotalCalcAmount(sum);
      setAdhocBalanceData(
        response?.data?.adhocCharges?.map((item) => {
          return {
            ...item,
            amountToPay: "",
          };
        })
      );
      response?.data?.adhocCharges?.forEach((item) => {
        sum1 = sum1 + item?.total_amount_to_pay;
      });
      setLedgerApiData(response);
      setLedgerDetails(response?.data?.paymentDetails);
    } else {
      setLoading(true);
      const token = localStorage.getItem("access_token");
      const response = await enableButtonAPI(token, selectedLedgerID);
      setPrefilledDetails(response?.data?.prefilledDetails);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setRecurrinDuration(response?.data?.recurringDetails?.duration);
      setLedgerApiData(response);
      setLedgerDetails(response?.data?.paymentDetails);
    }
  };

  useEffect(() => {
    ledgerDetails();
    setPaymentFormData({
      ...paymentFormData,
      date: getCurrentDateLedger(),
    });
  }, []);

  useEffect(() => {
    if (
      ledgerApiData &&
      ledgerApiData.data &&
      ledgerApiData.data.paymentDetails
    ) {
      const totalTuition = ledgerApiData.data.paymentDetails.reduce(
        (total, val) => total + parseFloat(val.proRatedFee || 0),
        0
      );

      if (ledgerApiData?.data?.adhocCharges?.length > 0) {
        const adhocTotal = ledgerApiData?.data?.adhocCharges.reduce(
          (total, val) => total + parseFloat(val?.total_amount_to_pay || 0),
          0
        );
        const finalAmount = totalTuition + adhocTotal;
        setAmountCalculated(finalAmount);
      } else {
        setAmountCalculated(totalTuition);
      }
    }

    setAdhocTotal(adhocTotal);

    let sum = 0;
    for (let i = 0; i < totalAmount.length; i++) {
      sum = sum + totalAmount[i];
    }
  }, [ledgerApiData, balanceData]);

  // ADD PAYMENT BUTTON
  const handleAddPayment = async () => {
    if (
      !paymentFormData.memo ||
      !paymentFormData.amountTendered ||
      (isCardOnFile == false && !paymentFormData.paymentMethod) ||
      (isCardOnFile == false && !paymentFormData.paymentProcessor)
    ) {
      const emptyFields = [];
      if (!paymentFormData.memo) emptyFields.push("Memo");
      if (!paymentFormData.amountTendered) emptyFields.push("Amount Tendered");
      if (!paymentFormData.paymentMethod) emptyFields.push("Payment Method");
      if (!paymentFormData.paymentProcessor && isCardOnFile == false)
        emptyFields.push("Payment Processor");
      if (emptyFields.length === 1) {
        toast.error(`Please fill in the ${emptyFields[0]} field.`);
      } else {
        toast.error(
          `Please fill in the following fields: ${emptyFields.join(", ")}.`
        );
      }
      return;
    }

    if (paymentFormData?.paymentProcessor === "" && isCardOnFile == false) {
      toast.error("Select the Payment Preprocessor!");
      return;
    }
    let alertNeeded = false;
    let classNames = [];

    // AMOUNT VALIDATION FOR CLASSE TABLES DATA
    balanceData?.length &&
      balanceData.forEach((classItem) => {
        const amountOwed = classItem?.amountOwed
          ? classItem?.amountOwed
          : classItem?.proRatedFee;
        if (amountOwed < classItem?.amountToPay) {
          alertNeeded = true;
          classNames.push(classItem?.class_name);
        }
      });
    // adhoc Balance Data
    adhocBalanceData?.length &&
      adhocBalanceData.forEach((classItem) => {
        if (classItem?.amount < classItem?.amountToPay) {
          alertNeeded = true;
          classNames.push(classItem?.class_name);
        }
      });

    // Show alert with class names (CLASS TABLE)
    if (alertNeeded) {
      toast.error(
        `Amount to Pay Should be Less than or Equal to  Amount Owed For: ${classNames?.map(
          (Val) => {
            return Val;
          }
        )}.`
      );
      return;
    }
    const classPaymentDetails = balanceData?.map((val, index) => {
      return {
        weeklyCampScheduleId:
          val?.period !== "Monthly" ? val?.weeklyCampScheduleId : "",
        amountOwed: val?.amountOwed
          ? parseFloat(val?.amountOwed)
          : val?.proRatedFee,
        amountToPay: val?.amountToPay ? val?.amountToPay : 0,
        ledgerDetailId: val?.ledgerDetailId,
        enrollmentStartDate: val?.enrollmentStartDate,
        enrollmentEndDate: val?.enrollmentEndDate,
        classSchedule: val?.classSchedule,
        notes: val?.notes || "",
      };
    });

    const recurringDates = PrefilledDetails?.recurringDates || [];

    //reccurring Dates
    const dynamicRecurringDates = recurringDates.map((dateObj) => ({
      currentlyPaying: dateObj.currentlyPaying || false,
      date: dateObj.date || "",
      prefilled: dateObj.prefilled || false,
    }));

    const adhocDetails = adhocBalanceData?.map((val, index) => {
      return {
        id: val?.id,
        amountOwed: val?.total_amount_to_pay,
        amountToPay: val?.amountToPay ? val?.amountToPay : 0,
      };
    });

    if (isCardOnFile) {
      if (selectedCardId == "") {
        toast.error("Select card to proceed!");
        return;
      }
    }
    // PAYLOAD
    const apiData = {
      payment_method: paymentFormData?.paymentMethod,
      payment_preprocessor: paymentFormData?.paymentProcessor,
      first_name: "",
      last_name: "",
      email: "",
      address1: "address1",
      address2: "address2",
      city: "",
      state: "",
      zip: "",
      country: "",
      card_number: "",
      expiry: "",
      cvv: "",
      isRecurringEnabled: false,
      recurringDuration: recurringDuration,
      memo: paymentFormData?.memo,
      checkNumber: paymentFormData?.checkNumber,
      paymentDate: formattedDate,
      amountTendered: paymentFormData?.amountTendered,
      classPaymentDetails: classPaymentDetails || [],
      adjustBalance: false,
      adhocPaymentDetails: adhocDetails,
      recurringDates: dynamicRecurringDates,
      cardOnFile: isCardOnFile ? true : false,
      cardOnFileId: selectedCardId,
      cardDeleted: deleteCardOnFile?.deleteArray,
    };

    const token = localStorage.getItem("access_token");

    setLoading(true);
    try {
      const anotherPaymentApiResponse = await ledgerPaymentAPI(
        token,
        selectedLedgerID,
        apiData
      );
      setLoading(false);
      if (anotherPaymentApiResponse?.response?.status === 400) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        toast.error(anotherPaymentApiResponse?.response?.data?.error);
      }
      if (anotherPaymentApiResponse?.response?.data?.error) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        toast.error(anotherPaymentApiResponse?.response?.data?.error);
      }
      if (anotherPaymentApiResponse?.status == 200) {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        toast.success(anotherPaymentApiResponse?.data?.data);
        setAddModal(false);
        setShowPaymentModal(false);
        fetchData();
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data);
    }
  };

  useEffect(() => {
    let sum = 0;
    const filteredTotalAmount = totalAmount.filter((value) => !isNaN(value));

    for (let i = 0; i < filteredTotalAmount.length; i++) {
      sum += filteredTotalAmount[i];
    }

    const diff = totalSumValue - amountTendered;

    if (amountTendered < totalSumValue) {
      setPaymentError(`Due  by $${diff}.00. Please Adjust amount to continue.`);
    } else {
      setPaymentError("");
    }

    setTotalSumValue(sum);
  }, [totalSumValue, totalAmount, amountTendered]);

  /// SUM OF ENTERED AMOUNT
  let sum = 0;
  for (let i = 0; i < totalAmount.length; i++) {
    sum = sum + totalAmount[i];
  }

  // HANLDE AMOUNT CHANGES
  const handleAmountChange = (index, amount, data) => {
    const updatedAdhocBalanceData = [...adhocBalanceData];
    const parsedAmount = parseFloat(amount);
    const newAmountToPay = isNaN(parsedAmount) ? undefined : parsedAmount;

    updatedAdhocBalanceData[index] = {
      ...data,
      amountToPay: newAmountToPay,
    };

    setAdhocBalanceData(updatedAdhocBalanceData);
  };

  const handleRadioChange = (event) => {
    const selectRadio = event.target.value;
    setPaymentMethodStatus(selectRadio);
  };

  const handleAmountUpdate = (data, enteredAmount, isAdhoc) => {
    let remainingAmount = enteredAmount;

    return data?.map((item) => {
      let newAmountToPay = 0;

      if (enteredAmount > 0) {
        newAmountToPay = isAdhoc
          ? Math.min(item.total_amount_to_pay, remainingAmount)
          : Math.min(item.proRatedFee, remainingAmount);

        remainingAmount -= newAmountToPay;
      }

      return {
        ...item,
        amountToPay: newAmountToPay,
      };
    });
  };

  const setAmountHandler = (e) => {
    const enteredAmount = parseFloat(e.target.value) || "";
    setAmountTendered(e.target.value);
    setPaymentFormData({
      ...paymentFormData,
      amountTendered: e.target.value,
    });

    const updatedPaymentDetails = handleAmountUpdate(
      balanceData,
      enteredAmount,
      false
    );
    setBalanceData(updatedPaymentDetails);

    const accountCreditBalance = enteredAmount - totalCalcAmount;
    setAccountCreditBalance(accountCreditBalance);
  };

  const formattedPrice = (price) => {
    let formatPrice = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    }).format(Math.abs(price));
    return price >= 0 ? formatPrice : `${formatPrice}`;
  };

  const calucatedTotalAmountPay = () => {
    let sum = 0,
      sum1 = 0;
    let hasValidValue = false;

    balanceData?.forEach((item) => {
      if (item?.amountToPay !== undefined && item?.amountToPay !== null) {
        sum += parseFloat(item?.amountToPay);
        hasValidValue = true;
      }
    });

    adhocBalanceData?.forEach((item) => {
      if (item?.amountToPay !== undefined && item?.amountToPay !== null) {
        // sum1 += parseFloat(item?.amount);
        sum1 += parseFloat(item?.total_amount_to_pay);
        hasValidValue = true;
      }
    });

    if (hasValidValue) {
      setAmountToPayTotalNumber(sum + sum1);
    } else {
      setAmountToPayTotalNumber(0);
    }
  };

  useEffect(() => {
    calucatedTotalAmountPay();
  }, [balanceData, adhocBalanceData]);

  const setAdhocAmountHandler = () => {
    const enteredAmount = parseFloat(accountCreditBalance) || 0;

    if (enteredAmount && ledgerApiData?.data?.paymentDetails.length !== 0) {
      const updatedPaymentDetails = handleAmountUpdate(
        adhocBalanceData,
        enteredAmount,
        true
      );
      setAdhocBalanceData(updatedPaymentDetails);
    } else if (
      enteredAmount &&
      ledgerApiData?.data?.paymentDetails.length === 0
    ) {
      const updatedPaymentDetails = handleAmountUpdate(
        adhocBalanceData,
        enteredAmount,
        true
      );
      setAdhocBalanceData(updatedPaymentDetails);
    }
  };

  useEffect(() => {
    setAdhocAmountHandler();
  }, [accountCreditBalance]);
  // ADJUST Amount Credit  BUTTON handler....
  const AdjustAmountHandler = async () => {
    setAdjustAmount(!adjustAmount);
    let alertNeeded = false;
    let classNames = [];

    // AMOUNT VALIDATION FOR CLASSE TABLES DATA
    balanceData?.length &&
      balanceData.forEach((classItem) => {
        const amountOwed = classItem?.amountOwed
          ? classItem?.amountOwed
          : classItem?.proRatedFee;
        if (amountOwed < classItem?.amountToPay) {
          alertNeeded = true;
          classNames.push(classItem?.class_name);
        }
      });
    // adhoc Balance Data
    adhocBalanceData?.length &&
      adhocBalanceData.forEach((classItem) => {
        if (classItem?.amount < classItem?.amountToPay) {
          alertNeeded = true;
          classNames.push(classItem?.class_name);
        }
      });

    // Show alert with class names (CLASS TABLE)
    if (alertNeeded) {
      toast.error(
        `Amount to Pay Should be Less than or Equal to  Amount Owed For: ${classNames?.map(
          (Val) => {
            return Val;
          }
        )}.`,
        {
          toastId: "error",
        }
      );
      return;
    }

    const classPaymentDetails = balanceData?.map((val) => {
      return {
        classSchedule: val?.classSchedule,
        amountOwed: val?.amountOwed ? val?.amountOwed : val?.proRatedFee,
        amountToPay: val?.amountToPay,
        ledgerDetailId: val?.ledgerDetailId,
        enrollmentStartDate: val?.enrollmentStartDate,
        enrollmentEndDate: val?.enrollmentEndDate,
        weeklyCampScheduleId: val?.weeklyCampScheduleId,
        notes: val?.notes,
      };
    });
    const recurringDates = PrefilledDetails?.recurringDates || [];

    //curring Dates
    const dynamicRecurringDates = recurringDates.map((dateObj) => ({
      currentlyPaying: dateObj.currentlyPaying || false,
      date: dateObj.date || "",
      prefilled: dateObj.prefilled || false,
    }));

    const adhocDetails = adhocBalanceData?.map((val, index) => {
      return {
        id: val?.id,
        amountOwed: val?.total_amount_to_pay,
        amountToPay: val?.amountToPay,
      };
    });

    const apiData = {
      payment_method: paymentFormData?.paymentMethod,
      payment_preprocessor: paymentFormData?.paymentProcessor,
      first_name: "",
      last_name: "",
      email: "",
      address1: "address1",
      address2: "address2",
      city: "",
      state: "",
      zip: "",
      country: "",
      card_number: "",
      expiry: "",
      cvv: "",
      isRecurringEnabled: false,
      recurringDuration: recurringDuration,
      memo: paymentFormData?.memo,
      checkNumber: paymentFormData?.checkNumber,
      paymentDate: formattedDate,
      amountTendered: paymentFormData?.amountTendered,
      classPaymentDetails: classPaymentDetails || [],
      // adjustBalance: tempAdjustAmount,
      adjustBalance: true,
      adhocPaymentDetails: adhocDetails,
      recurringDates: dynamicRecurringDates,
    };

    const token = localStorage.getItem("access_token");

    setLoading(true);
    if (
      ledgerApiData?.data?.paymentDetails?.length > 0 ||
      ledgerApiData?.data?.adhocCharges?.length > 0
    ) {
      try {
        const anotherPaymentApiResponse = await ledgerPaymentAPI(
          token,
          selectedLedgerID,
          apiData
        );
        if (anotherPaymentApiResponse?.response?.data?.error) {
          setLoading(false);
          toast.error(anotherPaymentApiResponse?.response?.data?.error, {
            toastId: "error",
          });
          return;
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 500);

          if (anotherPaymentApiResponse !== undefined) {
            toast.success("Credited Amount Being adjusted with Amounts.");
            ledgerDetails();
            setPaymentFormData({
              date: formattedDate,
              memo: "",
              checkNumber: "",
              amountTendered: "",
            });
            setAdjustAmount(false);
          }
        }
      } catch (error) {
        toast.error(error?.response?.data);
        setLoading(false);
      }
    } else {
      setAdjustAmount(false);
      setLoading(false);
      toast.error("No Class & Adhoc Detail found to Adjust the Amounts!", {
        toastId: "error",
      });
    }
  };

  const handleAmountOwedChange = (e, index) => {
    const { value } = e.target;
    const updatedBalanceData = [...balanceData];
    updatedBalanceData[index].amountOwed = value;
    setBalanceData(updatedBalanceData);
  };

  const handleLedgerNotes = (e, index) => {
    const { value } = e.target;
    const updatedBalanceData = [...balanceData];
    updatedBalanceData[index].notes = value;
    setBalanceData(updatedBalanceData);
  };

  useEffect(() => {
    let totalOwed = 0;

    balanceData?.forEach((val) => {
      const amountOwed = parseFloat(val?.amountOwed);
      const proRatedFee = parseFloat(val?.proRatedFee);
      totalOwed += isNaN(amountOwed) ? proRatedFee : amountOwed;
    });

    if (adhocBalanceData?.length > 0) {
      adhocBalanceData.forEach((val) => {
        const proRatedFee = parseFloat(val?.total_amount_to_pay);
        totalOwed += proRatedFee;
      });
    }

    setTotalAmountOwed(totalOwed);
  }, [balanceData, adhocBalanceData]);

  const handleOptionClick = (e, option) => {
    setSelectedCardId(option?.id);
    setSelectedOption(option?.description);
    setIsOpen(false);
  };

  const handleDeleteCard = (selectedId) => {
    if (cardLists.length > 1) {
      setOpenDeleteCardFile(true);
      setDeleteCardID(selectedId);
      setSelectedOption(null);
      fetchData();
    } else {
      toast.error("You need to add new Credit Card to Delete!");
    }
  };

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const fetchCards = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await getAllCards(token, selectedLedgerID);

      if (deleteCardOnFile.deleteArray.length > 0) {
        const filteredCards = response.data.filter(
          (card) => !deleteCardOnFile.deleteArray.includes(card.id)
        );
        setCardLists(filteredCards);
      } else {
        setCardLists(response?.data);
      }
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    fetchCards();
  }, [toggleState]);

  const options = cardLists?.map((val) => ({
    id: val?.id,
    description: val?.description,
  }));

  const handleAmountToPayChange = (e, index) => {
    const amountPaid =
      e.target.value.trim() === "" ? undefined : parseFloat(e.target.value);

    const updatedBalanceData = [...balanceData];
    updatedBalanceData[index].amountToPay = amountPaid;

    // Set the updated balanceData
    setBalanceData(updatedBalanceData);

    setTotalAmount((prevTotalAmount) => {
      const updatedTotal = [...prevTotalAmount];
      updatedTotal[index] = amountPaid;
      return updatedTotal;
    });
  };

  useEffect(() => {
    if (PrefilledDetails && PrefilledDetails.cardOnFileId && !selectedCardId) {
      const preselectedOption = options?.find(
        (option) => option?.id === PrefilledDetails?.cardOnFileId
      );
      if (preselectedOption) {
        setSelectedCardId(preselectedOption.id);
        setSelectedOption(preselectedOption.description);
      }
    }
  }, [PrefilledDetails, options, selectedCardId]);

  useEffect(() => {
    if (PrefilledDetails?.id) {
      if (
        PrefilledDetails?.payment_method_fk == "1" ||
        PrefilledDetails?.payment_method_fk == "0" ||
        PrefilledDetails?.payment_method_fk == "2"
      ) {
        setIsCardOnFile(false);
      }
    }
  }, [PrefilledDetails]);

  // Calculating Total Amount Without Entering AT:
  useEffect(() => {
    if (
      amountTendered === undefined ||
      amountTendered === null ||
      amountTendered === 0 ||
      amountTendered === ""
    ) {
      let total = 0;
      if (balanceData?.length > 0) {
        total = balanceData.reduce(
          (sum, item) => sum + parseFloat(item?.amountToPay || 0),
          0
        );
      }

      if (adhocBalanceData.length > 0) {
        const adhocTotal = adhocBalanceData.reduce(
          (sum, item) => sum + parseFloat(item?.amountToPay || 0),
          0
        );
        total += adhocTotal;
      }
      setDynamicAT(isNaN(total) ? "" : total);
    }
  }, [balanceData, amountTendered, adhocBalanceData]);

  useEffect(() => {
    if (dynamicAT == 0) {
      setPaymentFormData((prevState) => ({
        ...prevState,
        amountTendered: "",
      }));
    } else {
      setPaymentFormData((prevState) => ({
        ...prevState,
        amountTendered: isNaN(dynamicAT) ? "" : dynamicAT.toFixed(2),
      }));
    }
  }, [dynamicAT]);

  useEffect(() => {
    let input = document.getElementById("amountToPayValue");
    if (input) {
      const disableScroll = (event) => {
        event.preventDefault();
      };

      input.addEventListener("wheel", disableScroll);

      return () => {
        input.removeEventListener("wheel", disableScroll);
      };
    }
  }, [balanceData]);

  return (
    <>
      {loading && <LoaderComponent />}

      <div className="Admin-modal Admin-modal--open">
        <div
          className="Admin-modal__dialog Admin-modal__dialog--lg"
          style={{ width: "96vw", height: "auto" }}
        >
          <div className="Admin-modal__content" style={{ width: "90vw" }}>
            <div className="Admin-modal__head">
              <h2 className="Admin-modal__head--title">
                {buttonType === "ENABLE" ? "+ Add Recurring" : "+ Add Payment "}
              </h2>
              <button
                type="button"
                className="Admin-modal__close"
                onClick={() => {
                  setAddModal(false);
                }}
              >
                <CloseIcon />
              </button>
            </div>

            <div className="Admin-modal__body">
              {/* FIRST TABLE -- TENDERED  */}
              {buttonType == "PAY" && (
                <div className="column">
                  <table className="table" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{ color: "white" }}>Date</th>
                        <th style={{ color: "white" }}>Memo</th>
                        <th style={{ color: "white" }}>Check Number</th>
                        <th style={{ color: "white" }}>Amount Tendered($)</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>
                          <input
                            type="date"
                            className="adminform__input"
                            placeholder="Enter Date"
                            style={{
                              width: "120px",
                              height: "40px",
                              background: "#f1f1ff",
                            }}
                            value={getCurrentDateLedger()}
                            onChange={(e) =>
                              setPaymentFormData({
                                ...paymentFormData,
                                date: e.target.value,
                              })
                            }
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="adminform__input"
                            placeholder="Enter Memo"
                            style={{
                              width: "120px",
                              height: "40px",
                              background: "#f1f1ff",
                            }}
                            value={paymentFormData?.memo}
                            onChange={(e) =>
                              setPaymentFormData({
                                ...paymentFormData,
                                memo: e.target.value,
                              })
                            }
                          />
                        </td>

                        <td>
                          <input
                            type="number"
                            className="adminform__input"
                            placeholder="Check Number"
                            style={{
                              width: "120px",
                              height: "40px",
                              background: "#f1f1ff",
                            }}
                            value={paymentFormData?.checkNumber}
                            onChange={(e) =>
                              setPaymentFormData({
                                ...paymentFormData,
                                checkNumber: e.target.value,
                              })
                            }
                          />
                        </td>

                        <td>
                          <input
                            type="text"
                            className="adminform__input"
                            placeholder="Amount Tendered"
                            pattern="[0-9]*"
                            style={{
                              width: "120px",
                              height: "40px",
                              background: "#f1f1ff",
                            }}
                            value={paymentFormData?.amountTendered}
                            onChange={setAmountHandler}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {/* ADJUST AND PAY BUTTONS  */}
              {buttonType == "PAY" && ledgerApiData?.data?.balance < 0 ? (
                <div
                  className="flexbox"
                  style={{ display: "flex", justifyContent: "right" }}
                >
                  <button
                    className={`
                      adminbtn adminbtn-blueOutline
                    }`}
                    onClick={() => {
                      AdjustAmountHandler();
                    }}
                  >
                    Adjust with Credited Amount
                  </button>
                </div>
              ) : (
                ""
              )}

              {/* BALANCE STATUS  */}

              {buttonType === "PAY" && ledgerApiData?.data?.balance !== 0 ? (
                <p
                  style={{
                    color: "lightgreen",
                    marginBottom: "20px",
                    fontWeight: "600",
                    fontSize: "1.2rem",
                  }}
                >
                  Balance is Overpaid by: &nbsp;
                  {Math.abs(ledgerApiData?.data?.balance).toFixed(2)}$
                </p>
              ) : null}

              {/* CLASS TABLE  */}
              {balanceData?.length > 0 ? (
                <div>
                  <h2 className="title--h5 ">Select Which Charges to Pay :</h2>
                  <div
                    className="Table"
                    style={{ marginRight: "80px", width: "100%" }}
                  >
                    {buttonType === "ENABLE" ? (
                      <thead>
                        <tr>
                          <th style={{ color: "white" }}>Category</th>
                          <th style={{ color: "white" }}>Class Name</th>
                          <th style={{ color: "white" }}>
                            Tuition Fee / Month
                          </th>
                          <th style={{ color: "white" }}>Class Start Date</th>
                          <th style={{ color: "white", maxWidth: "100%" }}>
                            Class End Date
                          </th>
                        </tr>
                      </thead>
                    ) : (
                      <div className="Heading">
                        <div class="Cell">
                          <p>Charge Date</p>
                        </div>
                        <div class="Cell">
                          <p> Category</p>
                        </div>
                        <div class="Cell">
                          <p> Class Name</p>
                        </div>
                        <div class="Cell">
                          <p>Amount Owed($) </p>
                        </div>
                        <div class="Cell">
                          <p> Amount To Pay($)</p>
                        </div>
                        <div class="Cell">
                          <p>Ledger Note</p>
                        </div>
                      </div>
                    )}

                    {balanceData?.map((val, index) => {
                      const defaultProRatedFee =
                        val?.proRatedFee === null ||
                        val?.proRatedFee === undefined
                          ? 0
                          : val.proRatedFee.toFixed(2);
                      const formattedDate = val.chargeDate
                        ? new Date(val.chargeDate).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                        : new Date().toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          });

                      return (
                        <>
                          {buttonType === "ENABLE" ? (
                            <tr key={index}>
                              <td>{val?.category_name}</td>
                              <td>{val?.class_name}</td>
                              <td>$ {balancePrecission(defaultProRatedFee)}</td>
                              <td>{dateFormatter(val?.start_date)}</td>
                              <td style={{ maxWidth: "120px" }}>
                                {dateFormatter(val?.end_date)}
                              </td>
                            </tr>
                          ) : (
                            <div className="Row" key={index}>
                              <div className="Cell">
                                {val.chargeDate
                                  ? formattedDate
                                  : new Date().toLocaleDateString()}
                              </div>
                              <div className="Cell">{val?.category_name}</div>
                              <div className="Cell">{val?.class_name}</div>

                              <div className="Cell" style={{ padding: "5px" }}>
                                <input
                                  style={{
                                    background: "#f1f1ff",
                                    height: "40px",
                                    padding: "10px",
                                    maxWidth: "140px",
                                  }}
                                  type="text"
                                  pattern="[0-9]*"
                                  className="adminform__input"
                                  placeholder="Amount owed"
                                  value={
                                    val?.amountOwed !== undefined
                                      ? val?.amountOwed
                                      : val?.proRatedFee
                                  }
                                  onChange={(e) =>
                                    handleAmountOwedChange(e, index)
                                  }
                                />
                              </div>

                              <div className="Cell">
                                <input
                                  value={
                                    val?.amountToPay !== undefined
                                      ? val?.amountToPay
                                      : ""
                                  }
                                  id="amountToPayValue"
                                  type="number"
                                  pattern="[0-9]*"
                                  className="adminform__input"
                                  style={{
                                    background: "#f1f1ff",
                                    height: "40px",
                                    padding: "10px",
                                    maxWidth: "140px",
                                  }}
                                  onChange={(e) => {
                                    handleAmountToPayChange(e, index);
                                  }}
                                />
                              </div>

                              <div className="Cell">
                                <input
                                  style={{
                                    background: "#f1f1ff",
                                    height: "40px",
                                    padding: "10px",
                                    maxWidth: "140px",
                                  }}
                                  type="text"
                                  className="adminform__input"
                                  placeholder="Ledger Notes"
                                  value={val?.notes}
                                  onChange={(e) => handleLedgerNotes(e, index)}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {buttonType === "ENABLE" &&
              ledgerApiData?.data?.paymentDetails.length > 0 ? (
                <div style={{ width: "100%" }}>
                  <h2 className="title--h5">Select Which Charges to Pay :</h2>
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ color: "white" }}>Category</th>
                        <th style={{ color: "white" }}>Class Name</th>
                        <th style={{ color: "white" }}>Tuition Fee / Month </th>
                        <th style={{ color: "white" }}>Class Start Date</th>
                        <th style={{ color: "white" }}>Class End Date</th>
                      </tr>
                    </thead>

                    <tbody>
                      {ledgerApiData?.data?.paymentDetails?.map(
                        (val, index) => {
                          const defaultProRatedFee =
                            val?.tuition === null || val?.tuition === undefined
                              ? 0
                              : val?.tuition;

                          return (
                            <>
                              <tr key={index}>
                                <td>{val?.category_name}</td>
                                <td>{val?.class_name}</td>
                                <td>$ {defaultProRatedFee.toFixed(2)}</td>

                                <td>{dateFormatter(val?.start_date)}</td>
                                <td>{dateFormatter(val?.end_date)}</td>
                              </tr>
                            </>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              ) : null}

              {/* ADDHOC CHARGE TABLE  */}

              {buttonType === "PAY" && adhocBalanceData?.length > 0 && (
                <div style={{ marginTop: "20px" }}>
                  <h2 className="title--h5">Adhoc Charges :</h2>
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ color: "white" }}>Charge Date</th>
                        <th style={{ color: "white" }}>Category</th>
                        <th style={{ color: "white" }}>Memo</th>

                        <th style={{ color: "white" }}>Amount Owed ($)</th>
                        <th style={{ color: "white" }}>Amount To Pay ($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {adhocBalanceData?.map((val, index) => (
                        <tr key={index}>
                          <td>{dateFormatter(val?.charge_date)}</td>
                          <td>{val?.category_name}</td>
                          <td>{val?.memo}</td>
                          <td>
                            $ {balancePrecission(val?.total_amount_to_pay)}
                          </td>

                          <td>
                            <input
                              type="number"
                              id="amountToPayValue"
                              className="adminform__input"
                              placeholder="Amount to pay"
                              style={{ width: "50px" }}
                              value={
                                val?.amountToPay !== undefined
                                  ? val.amountToPay
                                  : ""
                              }
                              onChange={(e) => {
                                handleAmountChange(index, e.target.value, val);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {/* TOTAL TABLE  */}
              {buttonType === "ENABLE" ? (
                " "
              ) : (
                <table>
                  <tbody>
                    <tr style={{ height: "40px", width: "100%" }}>
                      <td style={{ background: "#eee" }}>Total</td>
                      <td style={{ minWidth: "100px" }}></td>
                      <td style={{ minWidth: "100px" }}></td>

                      <td
                        style={{
                          minWidth: "220px",
                          background: "#eee",
                          textAlign: "left",
                        }}
                      >
                        $ {balancePrecission(totalAmountOwed)}
                      </td>

                      <td style={{ background: "#eee", textAlign: "left" }}>
                        <span>
                          ${" "}
                          {paymentFormData?.amountTendered
                            ? paymentFormData.amountTendered
                            : "0.00"}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              {/* BALANCE STATUS  PAY ALL FEATURE */}
              {buttonType === "PAY" &&
              accountCreditBalance > 0 &&
              amountTendered - amountCalculated > 0 ? (
                <>
                  {accountCreditBalance > 0 ? (
                    <p style={{ color: "green", margin: "10px" }}>
                      Account credit of &nbsp; ${" "}
                      {balancePrecission(
                        amountTendered - amountToPayTotalNumber
                      )}
                      &nbsp; being applied.
                    </p>
                  ) : (
                    <p style={{ color: "red", margin: "10px" }}>
                      Overpaid by ${formattedPrice(accountCreditBalance)} .
                      Please adjust the amounts to continue.
                    </p>
                  )}
                </>
              ) : (
                ""
              )}

              {buttonType !== "PAY" && (
                <div className="adminbutton__group">
                  <button
                    className={`adminbtn ${
                      monthlyStatus == "MONTHLY"
                        ? "adminbtn-primary"
                        : "adminbtn-blueOutline"
                    }`}
                    onClick={() => {
                      setMonthlyStatus("MONTHLY");
                    }}
                  >
                    Monthly
                  </button>
                  <button
                    className={`adminbtn ${
                      monthlyStatus == "WEEKLY"
                        ? "adminbtn-primary"
                        : "adminbtn-blueOutline"
                    }`}
                    onClick={() => {
                      setMonthlyStatus("WEEKLY");
                    }}
                  >
                    Weekly
                  </button>
                </div>
              )}
              <hr />

              {/* CARD ON FILE  */}
              {buttonType === "ENABLE" ? (
                <div
                  className="flexbox"
                  style={{ display: "flex", justifyContent: "left" }}
                >
                  <div className="adminform__group ">
                    <input
                      type="radio"
                      id="cardOnFile"
                      name="paymentMethod"
                      className="adminform__radio "
                      value="cardOnFile"
                      checked={paymentMethodStatus === "cardOnFile"}
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="cardOnFile">&nbsp; Card on File</label>
                  </div>

                  <div className="adminform__group">
                    <input
                      type="radio"
                      id="newCard"
                      name="paymentMethod"
                      className="adminform__radio__input"
                      value="newCard"
                      checked={paymentMethodStatus === "newCard"}
                      onChange={handleRadioChange}
                    />
                    <label
                      className="adminform__radio__label"
                      htmlFor="newCard"
                    >
                      &nbsp; New Card
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}

              {!(
                buttonType === "ENABLE" && paymentMethodStatus === "cardOnFile"
              ) ? (
                <>
                  <div
                    class="flexbox"
                    style={{ display: "flex", marginTop: "20px" }}
                  >
                    <div className="column">
                      <label className="label">
                        Payment Method<span className="important_field">*</span>{" "}
                      </label>
                      <div class="adminform__group">
                        <select
                          name="cardType"
                          id=""
                          className="adminform__select"
                          // value={paymentFormData?.paymentMethod}
                          value={paymentFormData?.paymentMethod}
                          onChange={(e) => {
                            const selectedValue = e.target.value;

                            if (selectedValue === "3") {
                              setIsCardOnFile(true);
                            } else {
                              setIsCardOnFile(false);
                            }

                            setSelectedStateValue(selectedValue);

                            if (
                              selectedValue === "0" ||
                              PrefilledDetails?.prefilledDetails
                                ?.payment_method_fk === "0"
                            ) {
                              setOpenPaymentForm(true);
                              setPaymentFormData({
                                ...paymentFormData,
                                paymentMethod: selectedValue,
                              });
                            } else {
                              setOpenPaymentForm(false);
                              setPaymentFormData({
                                ...paymentFormData,
                                paymentMethod: selectedValue,
                              });
                            }
                          }}
                        >
                          {buttonType === "ENABLE" ? (
                            <option value="0">Credit Card</option>
                          ) : (
                            <>
                              <option value={""} selected disabled>
                                Payment Method
                              </option>
                              {buttonType !== "ENABLE" && (
                                <option value="3">Card on file</option>
                              )}
                              <option value="1">Cash</option>
                              <option value="2">Cheque</option>
                              <option value="0">Credit Card</option>
                            </>
                          )}
                        </select>
                      </div>
                    </div>

                    <div className="column">
                      <label className="label">
                        Payment Preprocessor
                        <span className="important_field">*</span>
                      </label>
                      <div class="adminform__group">
                        <select
                          name="paymentProcessor"
                          id=""
                          class="adminform__select"
                          value={paymentFormData.paymentProcessor}
                          onChange={(e) => {
                            setPaymentFormData({
                              ...paymentFormData,
                              paymentProcessor: e.target.value,
                            });
                          }}
                        >
                          <option value="" selected disabled>
                            Payment Processor
                          </option>
                          <option value="Gymtime Merchant Account">
                            GymTime Merchant Account
                          </option>
                          <option value="YAP Merchant Account">
                            YAP Merchant Account
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {isCardOnFile && (
                    <div className="flexbox">
                      <div className="column">
                        <label className="label">Card on File:</label>
                        <div class="adminform__group">
                          <div className="custom-dropdown " ref={dropdownRef}>
                            <div
                              className="selected-option adminform__select"
                              onClick={handleToggleDropdown}
                            >
                              {selectedOption ? (
                                <p className="">
                                  <span>{selectedOption}</span>
                                  <span
                                    className="delete-icon"
                                    onClick={() => setSelectedOption(null)}
                                  ></span>
                                </p>
                              ) : (
                                <>
                                  {options?.length == 0 ? (
                                    <span>No Cards Found(Add new Card)</span>
                                  ) : (
                                    <span>Select an option</span>
                                  )}
                                </>
                              )}
                            </div>
                            {isOpen && (
                              <div className="options">
                                {options?.map((option) => (
                                  <div
                                    key={option.id}
                                    className="option adminform__group"
                                    onClick={(e) =>
                                      handleOptionClick(e, option)
                                    }
                                  >
                                    <span>{option?.description}</span>

                                    <span
                                      className="delete-icon"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteCard(option?.id);
                                      }}
                                    >
                                      <img src={TrashIcon} alt="Delete" />
                                    </span>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </div>

            {/* CREDIT CARD COMPEONT CALL  */}
            {selectedStateValue === "0" || buttonType === "ENABLE" ? (
              <PaymentModal
                toggleState={toggleState}
                setAddModal={setAddModal}
                selectedLedgerID={selectedLedgerID}
                setShowPaymentModal={setShowPaymentModal}
                recurringDuration={recurringDuration}
                ledgerDetails={balanceData}
                fetchData={fetchData}
                paymentFormData={paymentFormData}
                amountTendered={paymentFormData?.amountTendered}
                setLoading={setLoading}
                LedgerApiData={ledgerApiData}
                PrefilledDetails={PrefilledDetails}
                buttonType={buttonType}
                totalAmount={totalAmount}
                paymentAmounts={adhocBalanceData}
                paymentMethodStatus={paymentMethodStatus}
                setDeleteCardID={setDeleteCardID}
                setOpenDeleteCardFile={setOpenDeleteCardFile}
                openDeleteCardFile={openDeleteCardFile}
                cardLists={cardLists}
                selectedLedgerDataFunction={ledgerDetails}
                deleteCardOnFile={deleteCardOnFile}
                STATUS={monthlyStatus}
                balanceData={balanceData}
              />
            ) : (
              ""
            )}

            {/* BUTTONS HANDLER  */}
            {!(selectedStateValue === "0" || buttonType === "ENABLE") ? (
              <div
                className="Admin-modal__footer"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <div
                  className="flexbox"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <button
                    className="adminbtn adminbtn  adminbtn-blueOutline"
                    onClick={() => setAddModal(false)}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className="adminbtn adminbtn-primary"
                    style={{ width: "224%" }}
                    onClick={(e) => {
                      handleAddPayment(e);
                    }}
                  >
                    Add Payment
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {openDeleteCardFile && (
        <DeleteModal
          toggleState={toggleState}
          setToggleState={setToggleState}
          openDeleteModal={openDeleteCardFile}
          setDeleteModal={setOpenDeleteCardFile}
          type={"CARD"}
          deleteCardID={deleteCardID}
          setDeleteCardOnFile={setDeleteCardOnFile}
          // fetchData={getAllLedgers()}
        />
      )}
    </>
  );
}

export default AddPaymentModals;
