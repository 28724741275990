// import { ReactComponent as AgeIcon } from "./age-icon.svg";
import { ReactComponent as ArrowIcon } from "./arrow-icon.svg";
import { ReactComponent as ArrowIconBlue } from "./arrow-icon-blue.svg";
// import { ReactComponent as BirthDayIcon } from "./birthday-icon.svg";
import { ReactComponent as BreadcrumSpacer } from "./breadcrumb-spacer.svg";
import { ReactComponent as CalenderIcon } from "./calender.svg";
// import { ReactComponent as ChildrenImage1 } from "./children-img01.svg";
import { ReactComponent as DownArrow } from "./down-arrow.svg";
import EnvelopeIcon  from "./envelope-icon.svg";
import { ReactComponent as FacebookIcon } from "./facebook-icon.svg";
import  HomeIcon  from "./home-icon.svg";
// import { ReactComponent as LocationIcon } from "./location-icon.svg";
import  PhoneIcon from "./phone-icon.svg";
import  PinIcon from "./pin-icon.svg";
import Facebook from "./facebook-icon.svg";
import LocationIcon from "./location-icon.svg";
import InstagramIcon from './instagram-icon.svg';
// import {ReactComponent as ProgramIcon} from "./program-icon.svg";
import YoutubeIcon  from "./youtube-icon.svg";

import { ReactComponent as playIcon } from "../images/Button-play.svg";

const SvgIcon = (props) => {
  const EnumIcons = {
    Facebook: Facebook,
    PinIcon: PinIcon,
    LocationIcon: LocationIcon,
    YoutubeIcon: YoutubeIcon,
    InstagramIcon: InstagramIcon,
    HomeIcon : HomeIcon,
    PhoneIcon : PhoneIcon,
    EnvelopeIcon:EnvelopeIcon,
  };

  return <img src={EnumIcons[props.type]} alt={props.type} />;
};

export default SvgIcon;

export {
  // AgeIcon,
  //   BirthDayIcon,
  BreadcrumSpacer,
  ArrowIcon,
  ArrowIconBlue,
  //   ChildrenImage1,
  CalenderIcon,
  DownArrow,
  EnvelopeIcon,
  FacebookIcon,
  HomeIcon,
  InstagramIcon,
  LocationIcon,
  PhoneIcon,
  PinIcon,
  //   ProgramIcon,
  YoutubeIcon,
  playIcon,
};
