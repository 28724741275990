import React from "react";
import "./invalid-routes.css";
import {  useNavigate } from "react-router-dom";
import logo from "../../parent-assets/images/gymtime_logo_2024.svg";
function InvalidRoutes() {
  const navigate = useNavigate();
  return (
    <div className="sectionInvalid">
      <img src={logo} alt="" height={"100px"} width={"250px"} />
      <h1 className="error">404</h1>
      <div className="page">
        Oops!!! The page you are looking for is not found
      </div>
      <button
        className="back-home"
        onClick={() => {
          navigate("/");
        }}
      >
        Back to home
      </button>
    </div>
  );
}

export default InvalidRoutes;
