import React from "react";
import aboutUsBanner from "../../parent-assets/images/about-us-banner.jpg";
import { getBlogs, getComments, getLikes } from "../../../Admin/adminApi/blog";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./blogPage.css";
import { Helmet } from "react-helmet";
import ReactAudioPlayer from "react-audio-player";
import _ from "lodash";

function BlogPage() {
  const navigate = useNavigate();
  const { title } = useParams();
  const token = localStorage.getItem("access_token");
  const [blogDatas, setBlogData] = useState([]);
  const [expandedBlogs, setExpandedBlogs] = useState(
    blogDatas?.map(() => false)
  );
  const [like, setLike] = useState(true);
  const [likesData, setLikesData] = useState([]);
  const [commentsData, setCommentsData] = useState([]);
  const [commentBool, setCommentBool] = useState(false);
  const [expandedBlogs2, setExpandedBlogs2] = useState(
    blogDatas?.map(() => false)
  );
  const [commentText, setCommentText] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const temp = async () => {
    await fetchLikesData();
    await fetchBlogData();
  };
  useEffect(() => {
    temp();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currPage]);

  const fetchLikesData = async () => {
    const response = await getLikes(token);
    setLikesData(response);
  };

  const fetchBlogData = async () => {
    const response = await getBlogs(token, currPage, 10);
    setBlogData(response?.data);
    setTotalPage(response?.totalPages);
    // setCurrPage(response?.currPage);
  };

  const blogReadHandler = (index, blogTitle, blogId) => {
    const cleaned = blogTitle.replace(/\s/g, "");
    navigate(`/blog/${cleaned}`);
    const updatedExpandedBlogs = [...expandedBlogs];
    updatedExpandedBlogs[index] = !updatedExpandedBlogs[index];
    setExpandedBlogs(updatedExpandedBlogs);
  };
  const blogLikeHandler = async (blogId) => {
    try {
      setLike(!like);
      const token = localStorage.getItem("access_token");
      const userId = localStorage.getItem("userId");

      const likeData = {
        like: isUserLikedMap[blogId] ? false : true,
        fk_blogs: blogId,
        fk_users: userId,
      };
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/like_blog`,
        likeData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await fetchLikesData();

      await fetchBlogData();
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    }
  };

  const [isUserLikedMap, setIsUserLikedMap] = useState({});
  useEffect(() => {
    const userId = localStorage.getItem("userId");

    const updatedIsUserLikedMap = {};
    blogDatas?.forEach((blog) => {
      const isUserLiked = blog.likeData.some(
        (like) => like.userId === userId && like.like
      );
      updatedIsUserLikedMap[blog.id] = isUserLiked;
    });

    setIsUserLikedMap(updatedIsUserLikedMap);
  }, [likesData, blogDatas]); //

  const handleLikeCLick = () => {};

  const handleCommentHandler = async (index, blogId, flag) => {
    setCommentsData([]);
    const updatedExpandedBlogs = Array(blogDatas.length).fill(false);
    updatedExpandedBlogs[index] = !expandedBlogs2[index];
    if (!flag) {
      setExpandedBlogs2(updatedExpandedBlogs);
      setCommentBool(!commentBool);
    }

    const response = await getComments(token, blogId);
    setCommentsData(response);
  };

  const writeCommentChange = (e) => {
    setCommentText(e.target.value);
  };

  const createcommentHandler = async (index, blogId) => {
    try {
      if (!commentText) {
        throw new Error("Please add comment");
      }
      const userId = localStorage.getItem("userId");
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/comment_blog`,
        { comments: commentText, fk_users: userId, fk_blogs: blogId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCommentText("");
      handleCommentHandler(index, blogId, true);
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    }
  };

  const handleShareFacebook = (titleBlog, id) => {
    const cleanTitle = titleBlog.replace(/\s/g, "");

    const baseUrl = `https://gymtime.qsstechnosoft.com/blog/${cleanTitle}`;
    const shareUrl = encodeURIComponent(baseUrl);
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
      "_blank"
    );
  };

  const handleShare = (titleBlog, id) => {
    const cleanTitle = titleBlog.replace(/\s/g, "");

    const baseUrl = `https://gymtime.qsstechnosoft.com/blog/${cleanTitle}`;

    const tweetText = `${titleBlog} | Gymtime Blog ${baseUrl}`;
    const encodedTweetText = encodeURIComponent(tweetText);

    const twitterIntentUrl = `https://twitter.com/intent/tweet?text=${encodedTweetText}`;

    window.open(twitterIntentUrl, "_blank");
    // toast.warning("Will be released in later version")
  };

  const utilDate = (date) => {
    const getHTML = (message) => _.unescape(message).replace("\\", "");
    const inputDate = Number(date);
    const dateObject = new Date(inputDate);
    const formattedDate = dateObject
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
      .replace(/,/g, "");
    const [month, day, year] = formattedDate.split(" ");
    const html = _.escape(`<div className="_blog_creation_date">
    <div className="_blog_creation_month">${month}</div>
    <div className="_blog_creation_day">${day}</div>
    <div className="_blog_creation_year">${year}</div>
  </div>`);

    const template = (
      <div>
        <div dangerouslySetInnerHTML={{ __html: getHTML(html) }} />
      </div>
    );
    return template;
  };

  const dateFormat = (date) => {
    const inputDate = Number(date);
    const dateObject = new Date(inputDate);
    const formattedDate = dateObject.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return formattedDate;
  };

  const handlePageChange = (selectedPage) => {
    setCurrPage(selectedPage?.selected);
  };

  const titleNavigateHandler = (blogTitle) => {
    const cleaned = blogTitle.replace(/\s/g, "");
    navigate(`/blog/${cleaned}`);
  };
  return (
    <>
      <Helmet>
        <title>Gymtime Blog</title>
        <meta
          name="description"
          content="
          Explore the world of holistic education and child development with Gymtime's blog. From insights into our innovative 'mommy-and-me' classes to expert tips on nurturing your child's spirit of exploration, discover a wealth of knowledge about integrated education, gymnastics, sports, music, cooking, and art programs for children aged 6 months to 16 years. Join us in creating a vibrant learning environment where play is a powerful tool for social, cognitive, and physical development. Gymtime: Where children learn, grow, and play with passion and purpose in the heart of New York City.
          
          Gymtime is a holistic educational environment for 6-month-olds to 16-year-olds. We are the pioneers and developers of integrated education, gymnastics, sports, music, cooking and art programs in the New York City area. Our comprehensive curriculum includes “mommy-and-me” and separation classes for toddlers, plus enrichment and afterschool programs in art, music, cooking, sports, Tae Kwon Do, recreational and competitive team gymnastics, and more. During the summer months, we also offer Summer Breeze Day Camp, Gymnastics and Sports Camp.

          The two branches of our community—Gymtime and York Avenue Preschool—share a mission and philosophy focused on stimulating each child’s spirit of exploration and engaging parents as partners in learning. This collaborative approach is evident throughout the Gymtime community, in each and every one of our enrichment programs. Through innovative, play-based environments, our professional staff nurtures each child’s individual skills and unique needs, giving them the foundation to develop self-confidence and a love of learning.
          
          At Gymtime we know that playtime for children is not merely a leisurely activity, but an opportunity to develop social skills and cognitive skills, build language, engage in movement and more. Our classes give children the chance to practice all of these skills in a fun, vibrant learning environment. Our more than 30 years of experience offering integrated education, sports and enrichment programs is reflected in our 25,000-square-foot facility, where we have custom designed every room to suit a specific developmental skill, activity and age group, including a brand new gymnastics center stocked with state-of-the-art equipment and staffed by our USA Gymnastics certified coaches.
          
          We are constantly evolving to add new activities and ensure Gymtime remains New York’s premier place to learn, grow and play. Join Gymtime today and see for yourself."
        />
      </Helmet>
      <div className="contentwraper">
        <section style={{ position: "relative" }}>
          <section className="siteBanner" style={{ background: "#F79727" }}>
            <div className="siteBanner__overlay">
              <img
                className="siteBanner__img"
                src={aboutUsBanner}
                alt="bannerImg"
              />
            </div>
          </section>
          {/* <div className="navbar_heading_siteBanner_img">
            <h1 className="navbar_heading_siteBanner_img_heading"> Blogs</h1>
          </div> */}
        </section>
        <section className="breadcrumbSec">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb__item">
                <Link className="breadcrumb__link" onClick={() => navigate(-1)}>
                  Back to results
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="commonSec">
          <div className="container">
            <div className="contentWithSidebar">
              <div className="content">
                <div className="blogList">
                  {blogDatas?.map((blogdata, index) => {
                    if (title) {
                      if (
                        title &&
                        title === (blogdata?.title).replace(/\s/g, "")
                      ) {
                        return (
                          <div className="blogList__card" key={blogdata.id}>
                            <div className="picture">
                              <a className="picture__link" href="/">
                                <img
                                  className="picture__img"
                                  src={blogdata?.image}
                                  alt=""
                                />
                                <span className="tagmark">
                                  {utilDate(blogdata?.createdAt)}
                                </span>
                              </a>
                            </div>
                            {/* <div className="blog_video_container blog_newPage_container" >
                              {blogdata?.video !== "undefined" && (
                                <ReactPlayer
                                  url={blogdata?.video}
                                  controls={true}
                                  height="250px"
                                  width="450px"
                                />
                              )}
                            </div> */}
                            <div className="contentBox">
                              <h1 className="title">
                                <p style={{ textDecoration: "underline" }}>
                                  {" "}
                                  {blogdata?.title}{" "}
                                </p>
                              </h1>
                              <div className="infoBox">
                                <div className="infoBox__item">
                                  <div className="flexbox">
                                    <p>{blogdata?.admin_user}</p>
                                    <p>
                                      {dateFormat(blogdata?.createdAt)}
                                      <span style={{ marginLeft: "3rem" }}>
                                        {blogdata?.voice && (
                                          <ReactAudioPlayer
                                            src={blogdata?.voice}
                                            autoPlay={false}
                                            controls
                                            controlsList="nodownload"
                                          />
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="infoBox__item"></div>
                              </div>
                              <div className="shortText">
                                <div>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: blogdata.content,
                                    }}
                                  ></p>
                                </div>
                              </div>
                              <div className="bottomInfo">
                                <ul className="bottomInfo__list">
                                  <li
                                    className="bottomInfo__list__item"
                                    onClick={() => {
                                      blogLikeHandler(blogdata?.id);
                                    }}
                                  >
                                    {isUserLikedMap[blogdata.id] ? (
                                      <div
                                        style={{
                                          fontSize: "14px",
                                          color: "grey",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        {" "}
                                        {blogdata?.like_count > 1
                                          ? `${
                                              blogdata?.like_count === 2
                                                ? "You and 1 other"
                                                : `You and ${
                                                    blogdata?.like_count - 1
                                                  } others`
                                            }`
                                          : "First one to like"}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <a className="bottomInfo__list__link">
                                      <svg
                                        onClick={handleLikeCLick}
                                        className="svg"
                                        width="26"
                                        height="26"
                                        viewBox="0 0 26 26"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          style={
                                            isUserLikedMap[blogdata.id]
                                              ? { opacity: "1" }
                                              : { opacity: "0.5" }
                                          }
                                          d="M24.9969 12.4822C25.4623 12.8255 25.8906 13.166 25.8906 14.3634C25.8906 15.5637 25.2851 16.0494 24.6372 16.3871C24.9021 16.8428 24.9866 17.3902 24.8726 17.9111C24.669 18.8713 23.836 19.6165 23.0956 19.8371C23.4155 20.3786 23.516 20.9117 23.1352 21.5676C22.646 22.391 22.2203 22.7037 20.3375 22.7037H12.669C10.0564 22.7037 8.70251 21.1796 8.70251 19.9124V13.3949C8.70251 9.96167 12.5817 7.04483 12.5817 4.65832L12.3014 1.68566C12.2882 1.50423 12.3226 1.06042 12.4548 0.932026C12.6664 0.711519 13.2508 0.373779 14.134 0.373779C14.7104 0.373779 15.0939 0.48822 15.546 0.717101C17.0824 1.49027 17.4817 3.44693 17.4817 5.02118C17.4817 5.77761 16.3869 8.04409 16.2388 8.82843C16.2388 8.82843 18.5315 8.29251 21.2075 8.27297C24.0132 8.25622 25.8325 8.80331 25.8325 10.6232C25.8325 11.3517 25.2534 12.083 24.9969 12.4822ZM2.35613 11.5387H4.47159C4.89238 11.5387 5.29594 11.7152 5.59348 12.0292C5.89103 12.3433 6.05819 12.7693 6.05819 13.2135V23.8201C6.05819 24.2643 5.89103 24.6903 5.59348 25.0044C5.29594 25.3184 4.89238 25.4949 4.47159 25.4949H2.35613C1.93534 25.4949 1.53178 25.3184 1.23423 25.0044C0.93669 24.6903 0.769531 24.2643 0.769531 23.8201V13.2135C0.769531 12.7693 0.93669 12.3433 1.23423 12.0292C1.53178 11.7152 1.93534 11.5387 2.35613 11.5387Z"
                                          fill={
                                            isUserLikedMap[blogdata.id]
                                              ? "#129BF7"
                                              : "gray"
                                          }
                                        />
                                      </svg>
                                      Like
                                      {!isUserLikedMap[blogdata.id]
                                        ? blogdata?.like_count
                                        : ""}
                                    </a>
                                  </li>

                                  <li
                                    className="bottomInfo__list__item"
                                    onClick={() =>
                                      handleShare(blogdata?.title, blogdata?.id)
                                    }
                                  >
                                    {isUserLikedMap[blogdata.id] ? (
                                      <div style={{ visibility: "hidden" }}>
                                        Post
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <a className="bottomInfo__list__link">
                                      <img
                                        width="30"
                                        height="30"
                                        src="https://img.icons8.com/ios-filled/50/twitterx--v2.png"
                                        alt="twitterx--v2"
                                      />
                                      Post
                                    </a>
                                  </li>
                                  <li
                                    className="bottomInfo__list__item"
                                    onClick={() =>
                                      handleShareFacebook(
                                        blogdata?.title,
                                        blogdata?.id
                                      )
                                    }
                                  >
                                    {isUserLikedMap[blogdata.id] ? (
                                      <div style={{ visibility: "hidden" }}>
                                        Share
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <a className="bottomInfo__list__link">
                                      <img
                                        width="36"
                                        height="36"
                                        src="https://img.icons8.com/color/48/facebook-new.png"
                                        alt="facebook-new"
                                      />
                                      Share
                                    </a>
                                  </li>
                                </ul>
                                {expandedBlogs2[index] && (
                                  <>
                                    <div>
                                      <div className="card">
                                        <div className="row">
                                          <div className="col-2"></div>

                                          <div className="col-10">
                                            <div className="comment-box ml-2">
                                              <div
                                                className="comment-area"
                                                style={{ position: "relative" }}
                                              >
                                                <textarea
                                                  onChange={(e) =>
                                                    writeCommentChange(e)
                                                  }
                                                  className="form-control"
                                                  placeholder="drop a comment!"
                                                  rows="4"
                                                ></textarea>
                                                <img
                                                  onClick={() =>
                                                    createcommentHandler(
                                                      index,
                                                      blogdata?.id
                                                    )
                                                  }
                                                  style={{
                                                    position: "absolute",
                                                    bottom: "0",
                                                    right: "10px",
                                                    cursor: "pointer",
                                                  }}
                                                  width="40"
                                                  height="40"
                                                  src="https://img.icons8.com/office/40/sent.png"
                                                  alt="sent"
                                                />
                                              </div>

                                              <div className="comment-btns mt-2">
                                                <div className="row">
                                                  <div className="col-6">
                                                    <div className="pull-left"></div>
                                                  </div>

                                                  <div className="col-6">
                                                    <div className="pull-right">
                                                      <br />

                                                      <br />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        {commentsData?.map((data) => {
                                          return (
                                            <>
                                              {" "}
                                              <div
                                                style={{
                                                  border: "1px solid grey",
                                                  borderRadius: "5px",
                                                  margin: "0.5rem",
                                                  padding: "8px",
                                                }}
                                              >
                                                <div className="commenntsBox">
                                                  <span
                                                    style={{
                                                      fontSize: "14px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {data && data?.clientName}
                                                  </span>

                                                  <span
                                                    style={{
                                                      marginLeft: "20px",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    {data?.createdTime}
                                                  </span>
                                                </div>
                                                <div
                                                  className="commentText"
                                                  style={{
                                                    fontSize: "15px",
                                                    color: "grey",
                                                  }}
                                                >
                                                  {data.commemts}
                                                </div>{" "}
                                                <br></br>{" "}
                                              </div>
                                            </>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    } else {
                      return (
                        <div className="blogList__card" key={blogdata.id}>
                          <div className="picture">
                            <img
                              className="picture__img"
                              src={blogdata?.image}
                              alt=""
                            />
                            <span className="tagmark">
                              {utilDate(blogdata?.createdAt)}
                            </span>
                            {/* 
                            <div className="blog_video_container">
                              <ReactPlayer
                                url={blogdata?.video}
                                controls={true}
                                height="250px"
                                width="450px"
                              />
                            
                            </div> */}
                          </div>
                          <div className="contentBox">
                            <h1
                              className="title"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                titleNavigateHandler(blogdata?.title)
                              }
                            >
                              <p style={{ textDecoration: "underline" }}>
                                {blogdata?.title}{" "}
                              </p>
                            </h1>
                            <div className="infoBox">
                              <div className="infoBox__item">
                                <div className="flexbox">
                                  <p>{blogdata?.admin_user}</p>
                                  <p>
                                    {dateFormat(blogdata?.createdAt)}

                                    <div style={{ marginLeft: "3rem" }}>
                                      {blogdata?.voice && (
                                        <ReactAudioPlayer
                                          src={blogdata?.voice}
                                          autoPlay={false}
                                          controls
                                          controlsList="nodownload"
                                        />
                                      )}
                                    </div>
                                  </p>
                                </div>
                              </div>
                              <div className="infoBox__item"></div>
                            </div>
                            <div className="shortText">
                              <div>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: blogdata.content.substring(0, 350),
                                  }}
                                ></p>
                                <span>
                                  ...{" "}
                                  <button
                                    style={{ color: "red" }}
                                    onClick={() =>
                                      blogReadHandler(
                                        index,
                                        blogdata?.title,
                                        blogdata?.id
                                      )
                                    }
                                  >
                                    Continue Reading
                                  </button>
                                </span>
                              </div>
                            </div>
                            <div className="bottomInfo">
                              <ul className="bottomInfo__list">
                                <li
                                  className="bottomInfo__list__item"
                                  onClick={() => {
                                    blogLikeHandler(blogdata?.id);
                                  }}
                                >
                                  {isUserLikedMap[blogdata.id] ? (
                                    <div
                                      style={{
                                        fontSize: "13px",
                                        color: "grey",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      {" "}
                                      {blogdata?.like_count > 1
                                        ? `${
                                            blogdata?.like_count === 2
                                              ? "You and 1 other"
                                              : `You and ${
                                                  blogdata?.like_count - 1
                                                } others`
                                          }`
                                        : "First one to like"}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <a className="bottomInfo__list__link">
                                    <svg
                                      onClick={handleLikeCLick}
                                      className="svg"
                                      width="26"
                                      height="26"
                                      viewBox="0 0 26 26"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        style={
                                          isUserLikedMap[blogdata.id]
                                            ? { opacity: "1" }
                                            : { opacity: "0.5" }
                                        }
                                        d="M24.9969 12.4822C25.4623 12.8255 25.8906 13.166 25.8906 14.3634C25.8906 15.5637 25.2851 16.0494 24.6372 16.3871C24.9021 16.8428 24.9866 17.3902 24.8726 17.9111C24.669 18.8713 23.836 19.6165 23.0956 19.8371C23.4155 20.3786 23.516 20.9117 23.1352 21.5676C22.646 22.391 22.2203 22.7037 20.3375 22.7037H12.669C10.0564 22.7037 8.70251 21.1796 8.70251 19.9124V13.3949C8.70251 9.96167 12.5817 7.04483 12.5817 4.65832L12.3014 1.68566C12.2882 1.50423 12.3226 1.06042 12.4548 0.932026C12.6664 0.711519 13.2508 0.373779 14.134 0.373779C14.7104 0.373779 15.0939 0.48822 15.546 0.717101C17.0824 1.49027 17.4817 3.44693 17.4817 5.02118C17.4817 5.77761 16.3869 8.04409 16.2388 8.82843C16.2388 8.82843 18.5315 8.29251 21.2075 8.27297C24.0132 8.25622 25.8325 8.80331 25.8325 10.6232C25.8325 11.3517 25.2534 12.083 24.9969 12.4822ZM2.35613 11.5387H4.47159C4.89238 11.5387 5.29594 11.7152 5.59348 12.0292C5.89103 12.3433 6.05819 12.7693 6.05819 13.2135V23.8201C6.05819 24.2643 5.89103 24.6903 5.59348 25.0044C5.29594 25.3184 4.89238 25.4949 4.47159 25.4949H2.35613C1.93534 25.4949 1.53178 25.3184 1.23423 25.0044C0.93669 24.6903 0.769531 24.2643 0.769531 23.8201V13.2135C0.769531 12.7693 0.93669 12.3433 1.23423 12.0292C1.53178 11.7152 1.93534 11.5387 2.35613 11.5387Z"
                                        fill={
                                          isUserLikedMap[blogdata.id]
                                            ? "#129BF7"
                                            : "gray"
                                        }
                                      />
                                    </svg>
                                    Like
                                    {!isUserLikedMap[blogdata.id]
                                      ? blogdata?.like_count
                                      : ""}
                                  </a>
                                </li>

                                <li
                                  className="bottomInfo__list__item"
                                  onClick={() =>
                                    handleShare(blogdata?.title, blogdata?.id)
                                  }
                                >
                                  {isUserLikedMap[blogdata.id] ? (
                                    <div style={{ visibility: "hidden" }}>
                                      Post
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <a className="bottomInfo__list__link">
                                    <img
                                      width="30"
                                      height="30"
                                      src="https://img.icons8.com/ios-filled/50/twitterx--v2.png"
                                      alt="twitterx--v2"
                                    />
                                    Post
                                  </a>
                                </li>
                                <li
                                  className="bottomInfo__list__item"
                                  onClick={() =>
                                    handleShareFacebook(
                                      blogdata?.title,
                                      blogdata?.id
                                    )
                                  }
                                >
                                  {isUserLikedMap[blogdata.id] ? (
                                    <div style={{ visibility: "hidden" }}>
                                      Share
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <a className="bottomInfo__list__link">
                                    <img
                                      width="36"
                                      height="36"
                                      src="https://img.icons8.com/color/48/facebook-new.png"
                                      alt="facebook-new"
                                    />
                                    Share
                                  </a>
                                </li>
                              </ul>
                              {expandedBlogs2[index] && (
                                <>
                                  <div>
                                    <div className="card">
                                      <div className="row">
                                        <div className="col-2"></div>

                                        <div className="col-10">
                                          <div className="comment-box ml-2">
                                            <div
                                              className="comment-area"
                                              style={{ position: "relative" }}
                                            >
                                              <textarea
                                                onChange={(e) =>
                                                  writeCommentChange(e)
                                                }
                                                className="form-control"
                                                placeholder="drop a comment!"
                                                rows="4"
                                              ></textarea>
                                              <img
                                                onClick={() =>
                                                  createcommentHandler(
                                                    index,
                                                    blogdata?.id
                                                  )
                                                }
                                                style={{
                                                  position: "absolute",
                                                  bottom: "0",
                                                  right: "10px",
                                                  cursor: "pointer",
                                                }}
                                                width="40"
                                                height="40"
                                                src="https://img.icons8.com/office/40/sent.png"
                                                alt="sent"
                                              />
                                            </div>

                                            <div className="comment-btns mt-2">
                                              <div className="row">
                                                <div className="col-6">
                                                  <div className="pull-left"></div>
                                                </div>

                                                <div className="col-6">
                                                  <div className="pull-right">
                                                    <br />
                                                    {/* <button onClick={()=>createcommentHandler(index,blogdata?.id)} className="btn btn-success send btn-sm" style={{background:"#EA519C"}}>Post <i className="fa fa-long-arrow-right ml-1"></i></button>       */}

                                                    <br />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {commentsData?.map((data) => {
                                        return (
                                          <>
                                            {" "}
                                            <div
                                              style={{
                                                border: "1px solid grey",
                                                borderRadius: "5px",
                                                margin: "0.5rem",
                                                padding: "8px",
                                              }}
                                            >
                                              <div className="commenntsBox">
                                                <span
                                                  style={{
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {data && data?.clientName}
                                                </span>

                                                <span
                                                  style={{
                                                    marginLeft: "20px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {data?.createdTime}
                                                </span>
                                              </div>
                                              <div
                                                className="commentText"
                                                style={{
                                                  fontSize: "15px",
                                                  color: "grey",
                                                }}
                                              >
                                                {data.commemts}
                                              </div>{" "}
                                              <br></br>{" "}
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>

        {!title && (
          <ReactPaginate
            pageCount={totalPage}
            // pageRangeDisplayed={7}
            marginPagesDisplayed={1}
            onPageChange={handlePageChange}
            initialPage={currPage}
            containerClassName={"pagination-1"}
            activeClassName={"active-1"}
            breakLabel="..."
            nextLabel=">"
            previousLabel="<"
          />
        )}
      </div>
    </>
  );
}

export default BlogPage;
