import LoaderComponent from "../LoaderComponent";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getStates } from "../../adminApi/users.js";
import { ReactComponent as ParentIcon } from "../../assets/images/icons/clientIcon.svg";
import { ReactComponent as ParentIcon2 } from "../../assets/images/icons/client-icon2.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close-icon.svg";

const AddClient = ({
  openAddNewClient,
  setAddNewClient,
  type,
  data,
  fetchData,
  setSortKey,
  setIsClassEdited,
}) => {
  
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    parent1_first_name: "",
    parent1_last_name: "",
    parent1_email: "",
    parent1_cell: "",
    parent1_alt_cell: "",
    parent1_address: " ",
    parent1_apartment: "",
    parent1_zip: "",
    parent1_city: "",
    parent2_first_name: "",
    parent2_last_name: "",
    parent2_cell: "",
    parent2_alt_cell: "",
    parent2_email: "",
    parent2_address: "",
    parent2_apartment: "",
    parent2_zip: "",
    parent2_city: "",
    parent1_state: "",
    parent2_state: "",
  });
  const [editData, setEditData] = useState({
    parent1_first_name: data.parent1_first_name,
    parent1_last_name: data.parent1_last_name,
    parent1_email: data.parent1_email,
    parent1_cell: data.parent1_cell,
    parent1_alt_cell: data.parent1_alt_cell,
    parent1_address: data.parent1_address,
    parent1_apartment: data.parent1_apartment,
    parent1_zip: data.parent1_zip,
    parent1_city: data.parent1_city,
    parent2_first_name: data.parent2_first_name,
    parent2_last_name: data.parent2_last_name,
    parent2_cell: data.parent2_cell,
    parent2_alt_cell: data.parent2_alt_cell,
    parent2_email: data.parent2_email,
    parent2_address: data.parent2_address,
    parent2_apartment: data.parent2_apartment,
    parent2_zip: data.parent2_zip,
    parent2_city: data.parent2_city,
    parent1_state: data.parent1_state,
    parent2_state: data.parent2_state,
  });

  // Prefilled Details
  const [getStAteData, setGetStateData] = useState();
  const [guardianDetail, setGuardianDetail] = useState(false);

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (type === "Add") {
      setFormData({ ...formData, [name]: value });
    } else {
      setEditData({ ...editData, [name]: value });
    }
  };

  const handleClient = async () => {
    // If Parent 2 is Selected then these  Validations Are Done.
    if (guardianDetail) {
      const isValidPhoneNumber = (phoneNumber) => {
        return /^\d{15}$/.test(phoneNumber);
      };
      const requiredFields = [
        formData.parent1_first_name,
        formData.parent1_last_name,
        formData.parent1_cell,
        // formData.parent1_alt_cell,
        formData.parent1_address,
        // formData.parent1_apartment,
        formData.parent1_zip,
        formData.parent1_city,
        formData.parent1_state,
      ];
      let allFieldsEmpty = true;

      requiredFields.forEach((field) => {
        if (field && field.trim() !== "") {
          allFieldsEmpty = false;
        }
      });

      if (allFieldsEmpty) {
        toast.error("All Fields are required!", {
          toastId: "error",
        });
        return;
      }

      // VALIDATION FOR EACH FIELDS NOW
      if (!formData.parent1_first_name) {
        toast.error("First name is required", {
          toastId: "error",
        });
        return;
      } else if (!/^[a-zA-Z]+$/.test(formData.parent1_first_name)) {
        toast.error(
          "First name should contain only letters and no spaces, numbers, or special characters",
          {
            toastId: "error",
          }
        );
        return;
      }

      if (!formData.parent1_last_name) {
        toast.error("Last name is required!");
        return;
      } else if (!/^[a-zA-Z]+$/.test(formData.parent1_last_name)) {
        toast.error(
          "Last name should not contain numbers,space and special character!",
          {
            toastId: "error",
          }
        );
        return;
      }

      // CELL PHONE

      if (!formData.parent1_cell) {
        toast.error(" Cell number is required!", {
          toastId: "error",
        });
        return;
      }

      if (!formData.parent1_alt_cell) {
        toast.error(" Alternate Cell number is required!", {
          toastId: "error",
        });
        return;
      }

      // EMAIL
      if (!formData.parent1_email) {
        toast.error("Email address is required!", {
          toastId: "error",
        });
        return;
      } else if (
        !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
          formData.parent1_email
        )
      ) {
        toast.error("Please enter a valid email address", {
          toastId: "error",
        });
        return;
      }

      // ADDRESS
      if (!formData.parent1_address) {
        toast.error("Address is required!");
        return;
      }

      // Appartment
      if (!formData.parent1_apartment) {
        toast.error("Apartment is required!", {
          toastId: "error",
        });
        return;
      } else if (
        !/^[a-zA-Z0-9\s,'.-]+$/.test(formData.parent1_apartment.trim())
      ) {
        toast.error("Apartment is invalid", {
          toastId: "error",
        });
        return;
      }
      // States
      if (!formData.parent1_state || formData.parent1_state === "") {
        toast.error("Please select a state", {
          toastId: "error",
        });
        return;
      }
      // Zip Code
      if (!formData.parent1_zip) {
        toast.error("ZIP code is required!");
        return;
      } else if (!/^\d{5}$/.test(formData.parent1_zip.trim())) {
        toast.error("ZIP code should be a 5-digit number");
        return;
      }

      // CITY
      if (!formData.parent1_city) {
        toast.error("City is required", {
          toastId: "error",
        });
        return;
      } else if (!/^[a-zA-Z\s,'.-]+$/.test(formData.parent1_city.trim())) {
        toast.error("City name is invalid");
        return;
      }

      //------------------------- Parent 2
      if (!formData.parent2_first_name) {
        toast.error("First name of Parent 2 is required", {
          toastId: "error",
        });
        return;
      } else if (!/^[a-zA-Z]+$/.test(formData.parent2_first_name)) {
        toast.error(
          "First name  of Parent 2 should contain only letters and no spaces, numbers, or special characters",
          {
            toastId: "error",
          }
        );
        return;
      }

      if (!formData.parent2_last_name) {
        toast.error("Last name  of Parent 2 is required!", {
          toastId: "error",
        });
        return;
      } else if (!/^[a-zA-Z]+$/.test(formData.parent2_last_name)) {
        toast.error(
          "Last name  of Parent 2 should not contain numbers,space and special character!",
          {
            toastId: "error",
          }
        );
        return;
      }

      // CELL PHONE

      if (!formData.parent2_cell) {
        toast.error(" Cell number  of Parent 2 is required!", {
          toastId: "error",
        });
        return;
      }
      if (!formData.parent2_alt_cell) {
        toast.error(" Alternate Cell  of Parent 2 number is required!", {
          toastId: "error",
        });
        return;
      } else if (!isValidPhoneNumber(formData.parent2_alt_cell.trim())) {
        toast.error(
          "Alternate Cell number  of Parent 2 should contain only numbers &  exactly 10 digits!",
          {
            toastId: "error",
          }
        );
        return;
      }

      // EMAIL
      if (!formData.parent2_email) {
        toast.error("Email address  of Parent 2 is required!", {
          toastId: "error",
        });
        return;
      } else if (
        !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
          formData.parent2_email
        )
      ) {
        toast.error("Please enter a valid email address  of Parent 2 !", {
          toastId: "error",
        });
        return;
      }

      // ADDRESS

      if (!formData.parent2_address) {
        toast.error("Address is required  of Parent 2!", {
          toastId: "error",
        });
        return;
      } else if (
        !/^[a-zA-Z0-9\s,'.-]+$/.test(formData.parent2_address.trim())
      ) {
        toast.error(" Address of Parent 2 is invalid", {
          toastId: "error",
        });
        return;
      }
      // Appartment
      if (!formData.parent2_apartment) {
        toast.error("Apartment  of Parent 2 is required!");
        return;
      } else if (
        !/^[a-zA-Z0-9\s,'.-]+$/.test(formData.parent2_apartment.trim())
      ) {
        toast.error("Apartment  of Parent 2 is invalid");
        return;
      }
      // States
      if (!formData.parent2_state || formData.parent2_state === "") {
        toast.error("Please select a state  of Parent 2");
        return;
      }
      // Zip Code
      if (!formData.parent2_zip) {
        toast.error("ZIP code  of Parent 2 is required!", {
          toastId: "error",
        });
        return;
      } else if (!/^\d{5}$/.test(formData.parent2_zip.trim())) {
        toast.error("ZIP code  of Parent 2 should be a 5-digit number");
        return;
      }

      // CITY
      if (!formData.parent2_city) {
        toast.error("City  of Parent 2 is required", {
          toastId: "error",
        });
        return;
      } else if (!/^[a-zA-Z\s,'.-]+$/.test(formData.parent2_city.trim())) {
        toast.error("City  of Parent 2 name is invalid", {
          toastId: "error",
        });
        return;
      }
    }
    const requiredFields = [
      formData.parent1_first_name,
      formData.parent1_last_name,
      formData.parent1_cell,
      // formData.parent1_alt_cell,
      formData.parent1_address,
      // formData.parent1_apartment,
      formData.parent1_zip,
      formData.parent1_city,
      formData.parent1_state,
    ];
    let allFieldsEmpty = true;

    requiredFields.forEach((field) => {
      if (field && field.trim() !== "") {
        allFieldsEmpty = false;
      }
    });

    if (allFieldsEmpty) {
      toast.error("All Fields are required!");
      return;
    }

    // VALIDATION FOR EACH FIELDS NOW
    if (!formData.parent1_first_name) {
      toast.error("First name is required", {
        toastId: "error",
      });
      return;
    }

    if (!formData.parent1_last_name) {
      toast.error("Last name is required!", {
        toastId: "error",
      });
      return;
    }
    // CELL PHONE

    if (!formData.parent1_cell) {
      toast.error(" Cell number is required!", {
        toastId: "error",
      });
      return;
    }

    // EMAIL
    if (!formData.parent1_email) {
      toast.error("Email address is required!", {
        toastId: "error",
      });
      return;
    } else if (
      !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
        formData.parent1_email
      )
    ) {
      toast.error("Please Enter a valid email address", {
        toastId: "error",
      });
      return;
    }

    // ADDRESS

    if (!formData.parent1_address) {
      toast.error("Address is required!", {
        toastId: "error",
      });
      return;
    }
    // States
    if (!formData.parent1_state || formData.parent1_state === "") {
      toast.error("Please select a state", {
        toastId: "error",
      });
      return;
    }
    // Zip Code
    if (!formData.parent1_zip) {
      toast.error("ZIP code is required!", {
        toastId: "error",
      });
      return;
    } else if (!/^\d{5}$/.test(formData.parent1_zip.trim())) {
      toast.error("ZIP code should be a 5-digit number");
      return;
    }

    // CITY
    if (!formData.parent1_city) {
      toast.error("City is required", {
        toastId: "error",
      });
      return;
    } else if (!/^[a-zA-Z\s,'.-]+$/.test(formData.parent1_city.trim())) {
      toast.error("City name is invalid", {
        toastId: "error",
      });
      return;
    }

    const token = localStorage.getItem("access_token");
    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/admin/client/create",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.status === 200) {
        setTimeout(() => {
          setLoading(false);
        }, 2000);

        setSortKey({
          sortId: null,
          isAsc: false,
        });
        setIsClassEdited(true);

        toast.success(response?.data?.data);
        setAddNewClient(false);
        fetchData();
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
      console.error("Error:", error);
    }
  };

  const editClient = async () => {
    const requiredFields = [
      editData.parent1_first_name,
      editData.parent1_last_name,
      editData.parent1_cell,
      // editData.parent1_alt_cell,
      editData.parent1_address,
      // editData.parent1_apartment,
      editData.parent1_zip,
      editData.parent1_city,
      editData.parent1_state,
    ];

    let allFieldsEmpty = true;

    requiredFields.forEach((field) => {
      if (field && field.trim() !== "") {
        allFieldsEmpty = false;
      }
    });

    if (allFieldsEmpty) {
      toast.error("All Fields are required!", {
        toastId: "error",
      });
      return;
    }

    // VALIDATION FOR EACH FIELDS NOW
    if (!editData.parent1_first_name) {
      toast.error("First name is required", {
        toastId: "error",
      });
      return;
    }

    if (!editData.parent1_last_name) {
      toast.error("Last name is required!", {
        toastId: "error",
      });
      return;
    } else if (!/^[a-zA-Z ]+$/.test(editData.parent1_last_name)) {
      toast.error(
        "Last name should contain only letters and spaces, no numbers or special characters",
        {
          toastId: "error",
        }
      );
      return;
    }

    // CELL PHONE

    if (!editData.parent1_cell) {
      toast.error(" Cell number is required!", {
        toastId: "error",
      });
      return;
    }
    // EMAIL
    if (!editData.parent1_email) {
      toast.error("Email address is required!", {
        toastId: "error",
      });
      return;
    } else if (
      !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
        editData.parent1_email
      )
    ) {
      toast.error("Please enter a valid email address", {
        toastId: "error",
      });
      return;
    }

    // ADDRESS

    if (!editData.parent1_address) {
      toast.error("Address is required!", {
        toastId: "error",
      });
      return;
    }
    // Appartment

    if (!editData.parent1_state || editData.parent1_state === "") {
      toast.error("Please select a state", {
        toastId: "error",
      });
      return;
    }
    // Zip Code
    if (!editData.parent1_zip) {
      toast.error("ZIP code is required!", {
        toastId: "error",
      });
      return;
    } else if (!/^\d{5}$/.test(editData.parent1_zip.trim())) {
      toast.error("ZIP code should be a 5-digit number", {
        toastId: "error",
      });
      return;
    }

    // CITY
    if (!editData.parent1_city) {
      toast.error("City is required");
      return;
    } else if (!/^[a-zA-Z\s,'.-]+$/.test(editData.parent1_city.trim())) {
      toast.error("City name is invalid", {
        toastId: "error",
      });
      return;
    }
    const token = localStorage.getItem("access_token");

    try {
      setLoading(true);
      const response = await axios.put(
        process.env.REACT_APP_BASE_URL + `/admin/client/update/${data.id}`,
        editData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response) {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        setIsClassEdited(true);
        toast.success("Client edited successfully");
        setAddNewClient(false);
        fetchData();
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error)
      console.error("Error:", error?.response?.data?.error);
    }
  };

  const getState = async () => {
    const response = await getStates();
    setGetStateData(response?.data?.getStates);
  };

  useEffect(() => {
    getState();
  }, []);

  return (
    <>
      {loading && <LoaderComponent />}

      <>
        {openAddNewClient ? (
          <>
            <div
              class="Admin-modal Admin-modal--open"
              id="modalAddNewClient"
              role="dialog"
            >
              <div class="Admin-modal__dialog Admin-modal__dialog--md">
                <div class="Admin-modal__content">
                  <div class="Admin-modal__head">
                    <h2 class="Admin-modal__head--title">
                      + {type === "Edit" ? "Edit Client" : "Add New Client"}
                    </h2>
                    <button
                      class="Admin-modal__close"
                      onClick={() => {
                        setAddNewClient(false);
                      }}
                    >
                      <CloseIcon />
                    </button>
                  </div>

                  <div class="Admin-modal__body">
                    <div
                      class="adminbutton__group"
                      style={{ marginBottom: "20px" }}
                    >
                      <button
                        class="adminbtn adminbtn-primary"
                        onClick={() => {
                          setGuardianDetail(false);
                        }}
                      >
                        <ParentIcon />
                        Parent 1
                      </button>
                      <button
                        class="adminbtn adminbtn-blueOutline"
                        onClick={() => {
                          setGuardianDetail(true);
                        }}
                      >
                        <ParentIcon2 />
                        Parent 2 / Guardian
                      </button>
                    </div>
                    {guardianDetail ? (
                      <>
                        <div class="Admin-modal__bodytab">
                          <div
                            class="adminform__group"
                            style={{ marginBottom: "10px" }}
                          >
                            <select class="adminform__select">
                              <option value="1" selected>
                                Parent 2
                              </option>
                            </select>
                          </div>
                          <div class="flexbox">
                            <div class="column">
                              <label htmlFor="" className="label">
                                First Name{" "}
                              </label>
                              <div class="adminform__group">
                                <input
                                  type="text"
                                  class="adminform__input"
                                  placeholder="First Name"
                                  name="parent2_first_name"
                                  value={
                                    data
                                      ? editData.parent2_first_name
                                      : formData.parent2_first_name
                                  }
                                  onChange={handleInput}
                                />
                              </div>
                            </div>

                            <div class="column">
                              <label htmlFor="" className="label">
                                Last Name{" "}
                              </label>
                              <div class="adminform__group">
                                <input
                                  type="text"
                                  class="adminform__input"
                                  placeholder="Last Name"
                                  name="parent2_last_name"
                                  value={
                                    data
                                      ? editData.parent2_last_name
                                      : formData.parent2_last_name
                                  }
                                  onChange={handleInput}
                                />
                              </div>
                            </div>
                          </div>

                          <div class="flexbox">
                            <div class="column">
                              <label htmlFor="" className="label">
                                Cell{" "}
                              </label>
                              <div class="adminform__group">
                                <input
                                  type="text"
                                  class="adminform__input"
                                  placeholder="Cell"
                                  name="parent2_cell"
                                  maxLength={15}
                                  value={
                                    data
                                      ? editData.parent2_cell
                                      : formData.parent2_cell
                                  }
                                  onChange={handleInput}
                                />
                              </div>
                            </div>

                            <div class="column">
                              <label htmlFor="" className="label">
                                Home{" "}
                              </label>
                              <div class="adminform__group">
                                <input
                                  type="text"
                                  class="adminform__input"
                                  placeholder="Home "
                                  name="parent2_alt_cell"
                                  maxLength={14}
                                  value={
                                    data
                                      ? editData.parent2_alt_cell
                                      : formData.parent2_alt_cell
                                  }
                                  onChange={handleInput}
                                />
                              </div>
                            </div>
                          </div>

                          <div class="flexbox">
                            <div class="column">
                              <label htmlFor="" className="label">
                                Email{" "}
                              </label>
                              <div class="adminform__group">
                                <input
                                  type="text"
                                  class="adminform__input"
                                  placeholder="Email"
                                  name="parent2_email"
                                  value={
                                    data
                                      ? editData.parent2_email
                                      : formData.parent2_email
                                  }
                                  onChange={handleInput}
                                />
                              </div>
                            </div>

                            <div class="column">
                              <label htmlFor="" className="label">
                                Address{" "}
                              </label>
                              <div class="adminform__group">
                                <input
                                  type="text"
                                  class="adminform__input"
                                  placeholder="Address"
                                  name="parent2_address"
                                  value={
                                    data
                                      ? editData.parent2_address
                                      : formData.parent2_address
                                  }
                                  onChange={handleInput}
                                />
                              </div>
                            </div>
                          </div>

                          <div class="flexbox">
                            <div class="column">
                              <label htmlFor="" className="label">
                                Appartment{" "}
                              </label>
                              <div class="adminform__group">
                                <input
                                  type="text"
                                  class="adminform__input"
                                  placeholder="Appartment"
                                  name="parent2_apartment"
                                  value={
                                    data
                                      ? editData.parent2_apartment
                                      : formData.parent2_apartment
                                  }
                                  onChange={handleInput}
                                />
                              </div>
                            </div>

                            <div class="column">
                              <label htmlFor="" className="label">
                                State{" "}
                              </label>
                              <div class="adminform__group">
                                <select
                                  class="adminform__input"
                                  name="parent2_state"
                                  placeholder="State"
                                  id=""
                                  className="adminform__select"
                                  onChange={handleInput}
                                  value={
                                    data && type === "Edit"
                                      ? editData?.parent2_state
                                      : formData?.parent2_state
                                  }
                                >
                                  <option value="" selected disabled>
                                    Select State
                                  </option>
                                  {getStAteData?.map((val, index) => {
                                    return (
                                      <option key={index} value={val.name}>
                                        {val?.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="flexbox">
                            <div class="column">
                              <label htmlFor="" className="label">
                                Zip{" "}
                              </label>
                              <div class="adminform__group">
                                <input
                                  type="text"
                                  class="adminform__input"
                                  placeholder="Zip"
                                  name="parent2_zip"
                                  maxLength={5}
                                  value={
                                    data
                                      ? editData.parent2_zip
                                      : formData.parent2_zip
                                  }
                                  onChange={handleInput}
                                />
                              </div>
                            </div>

                            <div class="column">
                              <label htmlFor="" className="label">
                                City{" "}
                              </label>
                              <div class="adminform__group">
                                <input
                                  type="text"
                                  class="adminform__input"
                                  placeholder="City"
                                  name="parent2_city"
                                  value={
                                    data
                                      ? editData.parent2_city
                                      : formData.parent2_city
                                  }
                                  onChange={handleInput}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div class="Admin-modal__bodytab ">
                        <div class="flexbox">
                          <div class="column">
                            <label htmlFor="" className="label">
                              First Name{" "}
                              <span className="important_field">*</span>
                            </label>
                            <div class="adminform__group">
                              <input
                                type="text"
                                class="adminform__input"
                                placeholder="First Name"
                                name="parent1_first_name"
                                value={
                                  data
                                    ? editData.parent1_first_name
                                    : formData.parent1_first_name
                                }
                                onChange={handleInput}
                              />
                            </div>
                          </div>

                          <div class="column">
                            <label htmlFor="" className="label">
                              Last Name{" "}
                              <span className="important_field">*</span>
                            </label>

                            <div class="adminform__group">
                              <input
                                type="text"
                                class="adminform__input"
                                placeholder="Last Name"
                                name="parent1_last_name"
                                value={
                                  data
                                    ? editData.parent1_last_name
                                    : formData.parent1_last_name
                                }
                                onChange={handleInput}
                              />
                            </div>
                          </div>
                        </div>

                        <div class="flexbox">
                          <div class="column">
                            <label htmlFor="" className="label">
                              Cell <span className="important_field">*</span>
                            </label>
                            <div class="adminform__group">
                              <input
                                type="text"
                                class="adminform__input"
                                placeholder="Cell"
                                name="parent1_cell"
                                maxLength={15}
                                value={
                                  data
                                    ? editData.parent1_cell
                                    : formData.parent1_cell
                                }
                                onChange={handleInput}
                              />
                            </div>
                          </div>

                          <div class="column">
                            <label htmlFor="" className="label">
                              Home{" "}
                            </label>
                            <div class="adminform__group">
                              <input
                                type="text"
                                class="adminform__input"
                                placeholder="Home"
                                name="parent1_alt_cell"
                                maxLength={15}
                                value={
                                  data
                                    ? editData.parent1_alt_cell
                                    : formData.parent1_alt_cell
                                }
                                onChange={handleInput}
                              />
                            </div>
                          </div>
                        </div>

                        <div class="flexbox">
                          <div class="column">
                            <label htmlFor="" className="label">
                              Email <span className="important_field">*</span>
                            </label>

                            <div class="adminform__group">
                              <input
                                // disabled={type == "Edit" ? true : false}
                                type="text"
                                class="adminform__input"
                                placeholder="Email"
                                name="parent1_email"
                                value={
                                  data
                                    ? editData.parent1_email
                                    : formData.parent1_email
                                }
                                onChange={handleInput}
                              />
                            </div>
                          </div>

                          <div class="column">
                            <label htmlFor="" className="label">
                              Address
                              <span className="important_field">*</span>
                            </label>

                            <div class="adminform__group">
                              <input
                                type="text"
                                class="adminform__input"
                                placeholder="Address"
                                name="parent1_address"
                                value={
                                  editData?.parent1_address !== null
                                    ? editData?.parent1_address
                                    : formData?.parent1_address
                                }
                                onChange={handleInput}
                              />
                            </div>
                          </div>
                        </div>

                        <div class="flexbox">
                          <div class="column">
                            <label htmlFor="" className="label">
                              Appartment
                            </label>
                            <div class="adminform__group">
                              <input
                                type="text"
                                class="adminform__input"
                                placeholder="Apt"
                                name="parent1_apartment"
                                value={
                                  data
                                    ? editData.parent1_apartment
                                    : formData.parent1_apartment
                                }
                                onChange={handleInput}
                              />
                            </div>
                          </div>

                          <div class="column">
                            <label htmlFor="" className="label">
                              State <span className="important_field">*</span>
                            </label>
                            <div class="adminform__group">
                              <select
                                className="adminform__input adminform__select"
                                name="parent1_state"
                                onChange={handleInput}
                                value={
                                  data && type === "Edit"
                                    ? editData?.parent1_state
                                    : formData?.parent1_state
                                }
                              >
                                <option value="" selected disabled>
                                  Select State
                                </option>
                                {getStAteData?.map((val, index) => (
                                  <option key={index} value={val?.name}>
                                    {val?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="flexbox">
                          <div class="column">
                            <label htmlFor="" className="label">
                              Zip <span className="important_field">*</span>
                            </label>

                            <div class="adminform__group">
                              <input
                                type="text"
                                class="adminform__input"
                                placeholder="Zip"
                                name="parent1_zip"
                                maxLength={5}
                                value={
                                  data
                                    ? editData.parent1_zip
                                    : formData.parent1_zip
                                }
                                onChange={handleInput}
                                //   onFocus={() => handleFocus("name")}
                              />
                            </div>
                          </div>

                          <div class="column">
                            <label htmlFor="" className="label">
                              City <span className="important_field">*</span>
                            </label>

                            <div class="adminform__group">
                              <input
                                type="text"
                                class="adminform__input"
                                placeholder="City"
                                name="parent1_city"
                                value={
                                  data
                                    ? editData.parent1_city
                                    : formData.parent1_city
                                }
                                onChange={handleInput}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {data?.creditCardsList?.length > 0 ? (
                      <h2 style={{ fontWeight: "600", marginTop: "20px" }}>
                        Credit Card Authorzation Form{" "}
                      </h2>
                    ) : null}
                    {data?.creditCardsList?.length > 0
                      ? data?.creditCardsList?.map((val) => {
                          return (
                            <div className="preview">
                              <a
                                href={val?.file}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ cursor: "pointer" }}
                              >
                                Click to View the Pdf
                              </a>
                            </div>
                          );
                        })
                      : null}
                  </div>

                  {/* BUTTON GROUP   */}
                  <div
                    className="Admin-modal__footer"
                    style={{ margin: "10px 0px" }}
                  >
                    <div
                      className="admin-button__group"
                      style={{ display: "flex", gap: "30px" }}
                    >
                      <button
                        className="adminbtn adminbtn-blueOutline"
                        onClick={() => {
                          setAddNewClient(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="adminbtn adminbtn-primary"
                        onClick={type === "Add" ? handleClient : editClient}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </>
    </>
  );
};

export default AddClient;
