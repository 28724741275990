import React from "react";
import { ReactComponent as DeleteCardIcon } from "../../assets/images/icons/deleteRowIcon.svg";
import ReactDatePicker from "react-datepicker";

function CampClassTable({
  rows,
  handleAddRow,
  handleDeleteRow,
  handleChange,
  getTeacherData,
  campDay,
  setCampRows,
  setCampTime,
  campTime,
  classType,
  setCampType,
  fullDayCampRow,
  setFullDayCampRow,
  setFullDayCampTime,
  fullDayCampTime,
  handleChangeCampFullDay,
}) {
  const handleAddCampRow = (campType) => {
    if (campType === "half_day") {
      setCampType("half_day");
      setCampRows([
        ...rows,
        {
          fk_teacher_id: "",
          start_date: "",
          end_date: "",
          week: "",
          tuition: "",
          max_strength: "",
        },
      ]);
    }
    if (campType === "full_day") {
      setCampType("full_day");
      setFullDayCampRow([
        ...fullDayCampRow,
        {
          fk_teacher_id: "",
          start_date: "",
          end_date: "",
          week: "",
          tuition: "",
          max_strength: "",
        },
      ]);
    }
  };

  const handleTimeChange = (event, timeType) => {
    const updatedTime = {
      ...campTime,
      [timeType]: event.target.value,
    };
    setCampTime((prevState) => ({
      ...prevState,
      half_day_start_time: updatedTime?.half_day_start_time,
      half_day_end_time: updatedTime?.half_day_end_time,
    }));
  };

  const handleTimeChangeFullDay = (event, timeType) => {
    const updatedTime = {
      ...fullDayCampTime,
      [timeType]: event.target.value,
    };
    setFullDayCampTime((prevState) => ({
      ...prevState,
      full_day_start_time: updatedTime?.full_day_start_time,
      full_day_end_time: updatedTime?.full_day_end_time,
    }));
  };

  return (
    <div className="flexbox" style={{ width: "1100px" }}>
      <div className="full_day_half_day_row_container">
        {campDay?.half_day && (
          <div
            className="half_day_camp_container_fluid"
            style={{ maxWidth: "700px" }}
          >
            <h3>Half Day camp</h3>
            {classType == "WEEKLY" && (
              <div className="flexbox">
                <div className="column">
                  <div className="adminform_group">
                    <label htmlFor="" className="label">
                      Start time
                    </label>
                    <input
                      type="time"
                      className="adminform__input"
                      placeholder="Camp Start time"
                      name="half_day_start_time"
                      value={campTime?.half_day_start_time}
                      onChange={(event) =>
                        handleTimeChange(
                          event,
                          "half_day_start_time",
                          "half_day"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="adminform_group">
                    <label htmlFor="" className="label">
                      End time
                    </label>
                    <input
                      type="time"
                      className="adminform__input"
                      placeholder="Camp End time"
                      value={campTime?.half_day_end_time}
                      name="half_day_end_time"
                      onChange={(event) =>
                        handleTimeChange(event, "half_day_end_time", "half_day")
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            {/* FULL DAY CAMP   */}
            <button
              className="adminbtn adminbtn-light"
              onClick={() => {
                handleAddCampRow("half_day");
              }}
            >
              + Add Half Camps
            </button>
            {rows.length > 0 && (
              <table className="campTable">
                <thead>
                  <tr>
                    <th>Select teacher</th>
                    <th>Week Start Date</th>
                    <th>Week End Date</th>
                    <th>Week Name</th>
                    <th>Tuition ($)</th>
                    <th>Max Students</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {rows?.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <select
                          style={{ minWidth: "200px" }}
                          name="half_day_fk_teacher_id"
                          id="half_day_fk_teacher_id"
                          className="adminform__select"
                          onChange={(event) =>
                            handleChange(
                              index,
                              "half_day_fk_teacher_id",
                              event.target.value
                            )
                          }
                          value={row.half_day_fk_teacher_id}
                        >
                          <option value="" selected disabled>
                            Select teacher
                          </option>
                          {getTeacherData?.map((item, index) => (
                            <option value={item?.id} key={index}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <ReactDatePicker
                          value={row?.half_day_start_date}
                          onChange={(date) =>
                            handleChange(index, "half_day_start_date", date)
                          }
                          className="adminform__input"
                          placeholderText="Week Start Date"
                          style={{
                            cursor: " pointer",
                            minWidth: "120px !important",
                          }}
                        />
                      </td>
                      {/* WeekStart End Date  */}
                      <td style={{ minWidth: "60px" }}>
                        <ReactDatePicker
                          value={row?.half_day_end_date}
                          onChange={(date) =>
                            handleChange(index, "half_day_end_date", date)
                          }
                          className="adminform__input"
                          placeholderText="Week End Date"
                          style={{
                            cursor: " pointer",
                            minWidth: "120px !important",
                          }}
                        />
                      </td>

                      {/* Week Name  */}
                      <td style={{ minWidth: "60px" }}>
                        <input
                          style={{ minWidth: "120px" }}
                          className="adminform__input"
                          type="text"
                          name="half_day_name"
                          placeholder="Week name"
                          value={row?.half_day_name}
                          onChange={(e) =>
                            handleChange(index, e.target.name, e.target.value)
                          }
                        />
                      </td>

                      <td>
                        <input
                          style={{ minWidth: "100px" }}
                          className="adminform__input"
                          type="text"
                          name="half_day_tuition"
                          value={row?.half_day_tuition}
                          onChange={(e) =>
                            handleChange(index, e.target.name, e.target.value)
                          }
                          placeholder="Tuition($)"
                        />
                      </td>
                      {/* Max Students  */}
                      <td>
                        <input
                          style={{ minWidth: "60px" }}
                          className="adminform__input"
                          type="text"
                          name="half_day_max_strength"
                          value={row.half_day_max_strength}
                          onChange={(e) =>
                            handleChange(index, e.target.name, e.target.value)
                          }
                          placeholder="Max Students"
                        />
                      </td>
                      <td>
                        <button
                          onClick={() => handleDeleteRow(index, "halfDay")}
                        >
                          <DeleteCardIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}

        {campDay?.full_day && (
          <div className="full_day_camp_container_fluid">
            <h3>Full day Camp</h3>

            {classType == "WEEKLY" && (
              <div className="flexbox">
                <div className="column">
                  <div className="adminform_group">
                    <label htmlFor="" className="label">
                      Start time
                    </label>
                    <input
                      type="time"
                      className="adminform__input"
                      placeholder="Camp Start time"
                      name="full_day_start_time"
                      value={fullDayCampTime?.full_day_start_time}
                      onChange={(event) =>
                        handleTimeChangeFullDay(
                          event,
                          "full_day_start_time",
                          "full_day"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="adminform_group">
                    <label htmlFor="" className="label">
                      End time
                    </label>
                    <input
                      type="time"
                      className="adminform__input"
                      placeholder="Camp End time"
                      name="full_day_end_time"
                      value={fullDayCampTime?.full_day_end_time}
                      onChange={(event) =>
                        handleTimeChangeFullDay(
                          event,
                          "full_day_end_time",
                          "full_day"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            <button
              className="adminbtn adminbtn-light"
              onClick={() => {
                handleAddCampRow("full_day");
              }}
            >
              {" "}
              + Add Full Day Camps
            </button>

            {fullDayCampRow?.length > 0 && (
              <table className="campTable">
                <thead>
                  <tr>
                    <th>Select teacher</th>
                    <th>Week Start Date</th>
                    <th>Week End Date</th>
                    <th>Week Name</th>
                    <th>Tuition ($)</th>
                    <th>Max Students</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {fullDayCampRow?.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <select
                          style={{ minWidth: "200px" }}
                          name="full_day_fk_teacher_id"
                          id="teacher"
                          className="adminform__select"
                          onChange={(event) =>
                            handleChangeCampFullDay(
                              index,
                              "full_day_fk_teacher_id",
                              event.target.value
                            )
                          }
                          value={row.full_day_fk_teacher_id}
                        >
                          <option value="" selected disabled>
                            Select teacher
                          </option>
                          {getTeacherData?.map((item, index) => (
                            <option value={item?.id} key={index}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <ReactDatePicker
                          // selected={row.weekStartDate}
                          value={row?.full_day_start_date}
                          onChange={(date) =>
                            handleChangeCampFullDay(
                              index,
                              "full_day_start_date",
                              date
                            )
                          }
                          placeholderText="MM/DD/YYYY"
                          className="adminform__input"
                          style={{
                            cursor: " pointer",
                            minWidth: "100px !important",
                          }}
                        />
                      </td>
                      {/* WeekStart End Date  */}
                      <td style={{ minWidth: "60px" }}>
                        <ReactDatePicker
                          value={row?.full_day_end_date}
                          onChange={(date) =>
                            handleChangeCampFullDay(
                              index,
                              "full_day_end_date",
                              date
                            )
                          }
                          className="adminform__input"
                          placeholderText="Week End Date"
                          style={{
                            cursor: " pointer",
                            minWidth: "120px !important",
                          }}
                        />
                      </td>

                      {/* Week Name  */}
                      <td style={{ minWidth: "120px" }}>
                        <input
                          style={{ minWidth: "60px" }}
                          className="adminform__input"
                          type="text"
                          placeholder="Week name"
                          value={row?.full_day_name}
                          onChange={(e) =>
                            handleChangeCampFullDay(
                              index,
                              e.target.name,
                              e.target.value
                            )
                          }
                          name="full_day_name"
                        />
                      </td>

                      <td>
                        <input
                          style={{ minWidth: "100px" }}
                          className="adminform__input"
                          type="text"
                          name="full_day_tuition"
                          value={row?.full_day_tuition}
                          onChange={(e) =>
                            handleChangeCampFullDay(
                              index,
                              e.target.name,
                              e.target.value
                            )
                          }
                          placeholder="Tuition($)"
                        />
                      </td>
                      {/* Max Students  */}
                      <td>
                        <input
                          style={{ minWidth: "60px" }}
                          className="adminform__input"
                          type="text"
                          name="full_day_max_strength"
                          value={row?.full_day_max_strength}
                          onChange={(e) =>
                            handleChangeCampFullDay(
                              index,
                              e.target.name,
                              e.target.value
                            )
                          }
                          placeholder="Max Students"
                        />
                      </td>
                      <td>
                        <button
                          onClick={() => handleDeleteRow(index, "fullDay")}
                        >
                          <DeleteCardIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default CampClassTable;
