import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import NavigationComponent from "../components/NavigationComponent";
import { useSelector } from "react-redux";
import DeleteModal from "../components/UserModals/DeleteModal";
import AddTeacher from "../components/UserModals/AddTeacher";
import { deleteTeacher, getTeachers } from "../adminApi/teacher";
import TableNew from "../components/TableComponent/TableNew";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { customStyles } from "../adminUtility";
import { teacherTable } from "../tableHeaderComponents";

function Teachers() {
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [openAddNewTeacher, setAddNewTeacher] = useState(false);
  const [categoryData, setCategoryData] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [teacherNames, setTeachersName] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [dataEdit, setDataEdit] = useState();
  const [editCategory, setEditCategory] = useState(false);
  const [temp, setTemp] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [loadingState, setLoadingState] = useState(false);
  const [sortKey, setSortKey] = useState({
    sortId: null,
    isAsc: true,
    page: "",
  });
  const [pageLimit, setPageLimit] = useState("10");
  const [formData, setFormData] = useState([]);
  const page = useSelector((state) => state?.adminSlice?.adminModalContent);
  const role = useSelector((state) => state?.userRole?.RoleId);

  // TABLE DATA
  async function fetchData() {
    setLoadingState(true);
    try {
      const token = localStorage.getItem("access_token");
      const response = await getTeachers(
        token,
        formData,
        page,
        sortKey,
        pageLimit
      );

      setTotalPages(response?.getTeachers?.totalPages);
      setCurrentPage(response?.getTeachers?.currPage);
      setTotalCount(response?.getTeachers?.totalCount);
      const modifiedData = [...response?.getTeachers?.data]?.map(
        (teacher, index) => {
          return {
            data: [
              teacher?.code ? teacher.code : "-",
              teacher?.name ? teacher.name : "-",
              teacher?.email ? teacher.email : "-",
              teacher?.address ? teacher.address : "-",
              teacher?.city ? teacher.city : "-",
              teacher?.zipcode ? teacher.zipcode : "-",
              teacher?.home_phone ? teacher.home_phone : "-",
              // teacher?.status ? teacher.status : "-",
            ],
            item: teacher,
            onDeletePress: () => {
              setCategoryData(parseInt(teacher?.id));
              setDeleteModal(true);
            },
            onEditPress: () => {
              setDataEdit(teacher);
              setEditCategory(true);
            },
          };
        }
      );

      setTeachers(modifiedData);
      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
      console.error("Error fetching data:", error);
    }
  }

  const handleSearch = () => {
    fetchData(formData);
  };

  useEffect(() => {
    fetchData();
  }, [page, editCategory, openAddNewTeacher, sortKey, pageLimit]);

  const debouncedFetchData = debounce(fetchData, 2000);

  useEffect(() => {
    const pattern = /[^ ]/;

    if (pattern.test(formData.name) || formData.name?.length == 0) {
      if (formData.name !== undefined) {
        const timeoutId = setTimeout(() => {
          debouncedFetchData(fetchData);
        }, 900);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [formData.name]);

  const handleAddSearch = () => {
    setTemp((prevTemp) => prevTemp + 1);
  };

  const [selectedOption, setSelectedOption] = useState("");

  const renderAdditionalFields = () => {
    const handleSelectChange = (index, value) => {
      setSelectedOption(value);
      handleAdditionalFieldsChange(index, "selectUser", value);
    };

    const renderInputField = (index) => {
      const classOptions = [
        { value: "", label: "Select teacher" },
        ...(Array.isArray(teacherNames)
          ? teacherNames
              .filter((val) => val?.name.trim() !== "")
              .map((val) => ({
                value: val.name,
                label: val.name,
              }))
          : []),
      ];

      if (selectedOption === "name") {
        return (
          <Select
            styles={customStyles}
            classNamePrefix="custom-select"
            // options={teacherNames.map((item) => ({
            //   value: item[selectedOption],
            //   label: item[selectedOption],
            // }))}
            options={classOptions}
            placeholder={"Teacher name"}
            onChange={(option) =>
              handleAdditionalFieldsChange(index, "name", option.value)
            }
          />
        );
      } else if (selectedOption === "city") {
        return (
          <select
            className="adminform__select"
            onChange={(e) =>
              handleAdditionalFieldsChange(index, "name", e.target.value)
            }
          >
            <option value="" disabled selected>
              Select City name
            </option>
            {teacherNames.map((item, index) => (
              <option key={index} value={item[selectedOption]}>
                {item[selectedOption]}
              </option>
            ))}
          </select>
        );
      } else {
        return (
          <input
            type="text"
            className="adminform__input"
            placeholder="Search here"
            name={`name_${index}`}
            onChange={(e) =>
              handleAdditionalFieldsChange(index, "name", e.target.value)
            }
            value={formData[index]?.name || ""}
            disabled={!selectedOption}
          />
        );
      }
    };

    const additionalFields = [];
    for (let i = 0; i < temp; i++) {
      additionalFields.push(
        <React.Fragment key={i}>
          <div className="adminform__group">
            <select
              className="adminform__select"
              onChange={(e) => handleSelectChange(i, e.target.value)}
              value={selectedOption}
            >
              <option value="" disabled>
                Search
              </option>
              <option value="code">Code</option>
              <option value="name">Teacher Name</option>
              <option value="email">Email</option>
              <option value="address">Address</option>
            </select>
          </div>
          <div className="adminform__group">{renderInputField(i)}</div>
        </React.Fragment>
      );
    }
    return additionalFields;
  };

  const handleCancelAdditionalFields = (index) => {
    setTemp((prevTemp) => {
      return prevTemp > 1 ? prevTemp - 1 : prevTemp;
    });

    setFormData((prevSearchParams) => {
      const newSearchParams = [...prevSearchParams];
      newSearchParams[index] = { selectUser: "", name: "" };
      const filteredSearchParams = newSearchParams.filter(
        (item) => item.selectUser !== "" || item.name !== ""
      );
      return filteredSearchParams;
    });
  };

  const handleAdditionalFieldsChange = async (index, key, value) => {
    const token = localStorage.getItem("access_token");
    if (value == "name" || value == "city") {
      const response = await getTeachers(token, formData, 0, sortKey, 300000);
      setTeachersName(response?.getTeachers?.data);
    }
    const updatedData = [...formData];

    while (updatedData.length <= index) {
      updatedData.push({ selectUser: "", name: "" });
    }

    while (updatedData.length <= index) {
      updatedData.push({ selectUser: "", name: "" });
    }

    if (value !== updatedData[index][key]) {
      updatedData[index]["name"] = "";
    }

    updatedData[index][key] = value;
    setFormData(updatedData);
    if (formData?.length !== 0 || formData[0]?.selectUser !== "") {
      fetchData();
    }
  };
  return (
    <>
      <div className="container--component">
        <div className="container--grid">
          <NavigationComponent />

          <div className="Adminpage">
            <Header title="Teacher" subTitle="(Manage Available Teachers)" />

            <section className="Adminpage__section">
              <h2 className="Adminpage__title">Search by:</h2>

              <div className="Adminpage__head">
                <div className="Adminpage__filter">
                  {renderAdditionalFields()}

                  {temp <= 1 ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className="adminform__cancel-button"
                      onClick={() => handleCancelAdditionalFields(1)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}

                  <div className="" style={{ display: "flex", gap: "10px" }}>
                    <button
                      className="adminbtn adminbtn-blueOutline"
                      onClick={handleAddSearch}
                      disabled={temp == 2 ? true : false}
                      style={{ display: temp == 2 ? "none" : "block" }}
                    >
                      and
                    </button>
                    <button
                      className="adminbtn adminbtn-primary"
                      onClick={handleSearch}
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.96326 9.16769L12.3473 11.569M11.2455 6.05994C11.2455 8.49399 9.27231 10.4672 6.83826 10.4672C4.40421 10.4672 2.43103 8.49399 2.43103 6.05994C2.43103 3.62589 4.40421 1.65271 6.83826 1.65271C9.27231 1.65271 11.2455 3.62589 11.2455 6.05994Z"
                          stroke="white"
                          strokeWidth="1.10181"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Search
                    </button>
                  </div>
                </div>

                <button
                  className="adminbtn adminbtn-light"
                  id="btnCategory"
                  // disabled={(role == "4")}
                  onClick={() => {
                    setAddNewTeacher(true);
                  }}
                >
                  + Add New Teacher
                </button>
              </div>

              {/* <div className="Adminpage__body"> */}
              <TableNew
                loadingState={loadingState}
                headerData={teacherTable}
                bodyData={teachers}
                totalPages={totalPages}
                tableType={"Teachers"}
                setSortKey={setSortKey}
                setPageLimit={setPageLimit}
                pageLimit={pageLimit}
                totalCount={totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
              {/* </div> */}
            </section>
          </div>
        </div>
      </div>

      {editCategory && (
        <AddTeacher
          openAddNewTeacher={editCategory}
          setAddNewTeacher={setEditCategory}
          title="Edit"
          type={"Edit"}
          data={dataEdit}
          setSortKey={setSortKey}
          fetchData={fetchData}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          openDeleteModal={showDeleteModal}
          setDeleteModal={setDeleteModal}
        />
      )}

      {openAddNewTeacher && (
        <AddTeacher
          openAddNewTeacher={openAddNewTeacher}
          setAddNewTeacher={setAddNewTeacher}
          title="Add"
          setSortKey={setSortKey}
          fetchData={fetchData}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          openDeleteModal={showDeleteModal}
          setDeleteModal={setDeleteModal}
          data={categoryData}
          fetchData={fetchData}
          deleteQuery={deleteTeacher}
          type={"teacher"}
        />
      )}
    </>
  );
}

export default Teachers;
