import React, { useEffect, useRef } from "react";
import { useState } from "react";
// import DotLoader from "react-spinners/DotLoader";
// import ResetPassword from "./ResetPassword";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setSuccessModalContent } from "../../../reducers/userSlice";
import { setLoading } from "../../../reducers/loadingSlice";
import { toast } from "react-toastify";

import { ReactComponent as GymtimeLogo } from "../../parent-assets/images/gymtime_logo_2024.svg";

// Overlay fixing::
function useOutsideAlerter(ref, setModal) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        ref.current.className === "modal__content"
      ) {
        setModal(false);
      } else {
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function ForgotPassword({ setModal, setSuccessModal }) {
  const [email, setEmail] = useState("");
  const [resetPasswordModalVisible, setResetPasswordModalVisible] =
    useState(false);

  const dispatch = useDispatch();

  const handleBackToLogin = () => {
    setModal({ key: "login", flag: true });
  };

  const openResetPasswordModal = (e) => {
    e.preventDefault();
    setResetPasswordModalVisible(true);

    localStorage.setItem("forgot-email", email);

    axios
      .get(process.env.REACT_APP_BASE_URL + `/forgot-password/${email}`)
      .then((res) => {
        dispatch(setLoading(true));
        dispatch(setSuccessModalContent("Email Sent Succesfully."));
        setTimeout(() => {
          dispatch(setLoading(false));
        }, [500]);
        setSuccessModal(true);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        toast.error(error?.response?.data?.error, {
                                    toastId: "error",
                                  });
        console.error("API Request Error", error);
      });
  };

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, setModal);

  return (
    <>
      <div
        className="modal modal--loginModal modal--open"
        id="ForgotPasswordModal"
        role="dialog"
      >
        <div className="modal__dialog modal__dialog--lg">
          <div ref={wrapperRef} className="modal__content">
            <div className="loginWrap">
              {/* <div className="loginWrap__item loginWrap__item--bg">
                <div className="loginWrap__testimonial">
                  <div className="loginWrap__testimonial__bio">
                    <div className="loginWrap__testimonial__shortText">
                      <p className="para">
                        The passage experienced a surge in popularity during the
                        1960s when Letraset used it on their dry-transfer
                        sheets, and again during the 90s as desktop publishers
                        bundled the text with their software.
                      </p>
                    </div>

                    <div className="loginWrap__testimonial__bioName">
                      Vincent Obi
                      <svg
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_182_2875)">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.45801 5.9257C1.45801 2.94157 3.87712 0.522461 6.86125 0.522461C9.84538 0.522461 12.2645 2.94157 12.2645 5.9257C12.2645 8.90983 9.84538 11.3289 6.86125 11.3289C3.87712 11.3289 1.45801 8.90983 1.45801 5.9257Z"
                            fill="#08AD36"
                          />

                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.46999 3.9818C9.66182 4.17362 9.66182 4.48464 9.46999 4.67647L6.42104 7.86929C6.22921 8.06112 5.9182 8.06112 5.72637 7.86929L4.25276 6.39568C4.06093 6.20385 4.06093 5.89284 4.25276 5.70101C4.44458 5.50918 4.7556 5.50918 4.94742 5.70101L6.0737 6.82729L8.77532 3.9818C8.96715 3.78997 9.27816 3.78997 9.46999 3.9818Z"
                            fill="white"
                          />
                        </g>

                        <defs>
                          <clipPath id="clip0_182_2875">
                            <rect
                              width="11.7889"
                              height="11.7889"
                              fill="white"
                              transform="translate(0.966797 0.03125)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="loginWrap__item">
                <div className="loginWrap__header">
                  <div className="loginWrap__branding">
                    <button className="backbtn" onClick={handleBackToLogin}>
                      <svg
                        width="9"
                        height="15"
                        viewBox="0 0 9 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.08357 1.8115L6.85403 0.588867L0.0224609 7.42734L6.86094 14.2658L8.08357 13.0432L2.46773 7.42734L8.08357 1.8115Z"
                          fill="#8692A6"
                        />
                      </svg>
                      Back
                    </button>

                    <div className="logo">
                      <Link to="/">
                        <GymtimeLogo />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="loginWrap__form">
                  <div className="loginWrap__titlebox">
                    <h2 className="title">Forgot Password</h2>
                    <p className="para">
                      To recover your password, enter your email address below.
                    </p>
                  </div>

                  <form className="form" onSubmit={openResetPasswordModal}>
                    <div className="form__group">
                      <label className="form__label">
                        Email address
                        <span className="form__label__asterisks">*</span>
                      </label>
                      <input
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                        className="form__input"
                        id="email"
                        type="email"
                        placeholder="Enter email address"
                      />
                    </div>

                    <div className="form__group">
                      <button
                        type="submit"
                        className="btn btn--primary btn--full"
                      >
                        Send Recovery Mail
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
