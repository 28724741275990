const GET_ROLLSHEET = `
query ClassRollSheet($attendanceStartDate: String, $attendanceEndDate: String, $classId: [ClassTypeInput!],){
  classRollSheet(attendance_start_date: $attendanceStartDate, attendance_end_date: $attendanceEndDate, classId: $classId,) {
    className
    id
    classSchedules {
      classScheduleDetails {
        end_time
        weekday
        start_time
        teacher {
          name
        }
      }

      students {
        allergies
        last_name
        gender
        first_name
        clients {
          parent1_email
        }
        photo_release
        school_attending_with {
          name
          attending_with
        }
        dob
        age
        attendance {
          date
          is_present
        }
      }
      makeupStudents {
        first_name
        last_name
        dob
        age
        gender
        photo_release
        allergies
        school_attending_with {
          name
          attending_with
        }
        attendance {
          date
          is_present
        }
        clients {
          parent1_email
        }
      }
    }
    max_age
    gender
    max_strength
    tuition
    min_age
    season
  }
}
`;
export { GET_ROLLSHEET };
