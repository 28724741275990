// PageLoader.js
import React from "react";
import { ReactComponent as Logo } from "../parent-assets/images/gymtime_logo_2024.svg";

const PageLoader = () => {
  return (
    <div className="loader-container">
      <Logo className="loader-logo" />
    </div>
  );
};

export default PageLoader;
