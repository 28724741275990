import React from "react";
import NavigationComponent from "../components/NavigationComponent";
import Header from "../components/Header";
import { useEffect } from "react";
import { getUserDetails } from "../adminApi/profileApi";
import { useState } from "react";
import ChangePassword from "../components/ProfileModal/ChangePassword";
import ChangeCell from "../components/ProfileModal/ChangeCell";
import { toast } from "react-toastify";
import axios from "axios";

const MyProfile = () => {
  const [userData, setUserData] = useState();
  const [openPasswordModal, setOpenPAsswordModal] = useState(false);
  const [openCellModal, setOpenCellModal] = useState(false);
  const [cellState, setCellState] = useState();
  const [formData, setFormData] = useState({});
  const [images, setImages] = useState([]);

  const getUsers = async () => {
    const token = localStorage.getItem("access_token");
    const getUser = await getUserDetails(token);
    setUserData(getUser?.data?.getUser);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleChangePassword = () => {
    setOpenPAsswordModal(!openPasswordModal);
  };

  const handleChangeCell = () => {
    setOpenCellModal(!openCellModal);
  };

  const handleFileChange = async (e) => {
    e.preventDefault();
    try {
      const selectedFiles = e.target.files;

      if (selectedFiles && selectedFiles.length > 0) {
        const file = selectedFiles[0];

        if (file.size > 10 * 1024 * 1024) {
          toast.error("File size must be less than 10 MB");
          return;
        }

        const allowedTypes = ["image/png", "image/jpeg"];

        if (!allowedTypes.includes(file.type)) {
          toast.error(
            "File type not allowed. Please upload a PNG, JPG, JPEG file."
          );
          return;
        }

        const reader = new FileReader();
        reader.onloadend = async () => {
          const convertedFile = {
            // file,
            image: reader.result,
          };
          const convertedFileName = {
            // file,
            name: file.name,
          };

          setImages([convertedFile]);
          setFormData({ ...formData, file: convertedFileName });

          // Perform the API call here
          const token = localStorage.getItem("access_token");
          try {
            const response = await axios.put(
              process.env.REACT_APP_BASE_URL + `/admin/user/update`,
              convertedFile,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (response) {
              toast.success("Profile updated successfully!");
            }
          } catch (error) {
            console.error("Error:", error);
          }
        };

        reader.readAsDataURL(file);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="container--component">
        <div className="container--grid">
          <NavigationComponent />
          <div className="Adminpage">
            <Header title="My Profile" subTitle="(Manage your Credentials)" />

            <div className="Adminpage__sections">
              <div class="myProfileWrap">
                <div class="pic">
                  <img
                    class="pic__img"
                    src={images.length > 0 ? images[0]?.image : userData?.image}
                    alt=""
                  />
                  <input
                    class="uploadInput"
                    type="file"
                    id="uploadprofile"
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                  />
                  <label for="uploadprofile" class="btnUpload">
                    Change Profile
                  </label>
                </div>
                <div class="detailsInfo">
                  <h1 class="page__title">
                    {userData?.first_name} {userData?.last_name}
                  </h1>
                  <div class="flexbox">
                    <div class="column">
                      <div class="adminform__group">
                        <input
                          class="adminform__input"
                          type="email"
                          placeholder="jacob@gymtime.com"
                          value={userData?.email}
                        />
                      </div>
                    </div>
                    <div class="column">
                      <div class="adminform__group">
                        
                        <button
                          type="button"
                          id="btnEditUserProfile"
                          class="adminbtn adminbtn-blueOutline"
                          onClick={handleChangePassword}
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="flexbox">
                    <div class="column">
                      <div class="adminform__group">
                        <input
                          class="adminform__input"
                          type="text"
                          placeholder="+1 908-9870-098"
                          value={cellState ? cellState : userData?.cell}
                        />
                        <button
                          type="button"
                          class="adminbtn adminbtn-blueOutline"
                          onClick={handleChangeCell}
                        >
                          Change Cell
                        </button>
                      </div>
                    </div>
                    <div class="column"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openPasswordModal ? (
        <ChangePassword
          openAddNewUser={openPasswordModal}
          setAddNewUser={setOpenPAsswordModal}
        />
      ) : null}

      {openCellModal ? (
        <ChangeCell
          setCellState={setCellState}
          openAddNewUser={openCellModal}
          setAddNewUser={setOpenCellModal}
        />
      ) : null}
    </>
  );
};

export default MyProfile;
