import React, { useEffect, useRef, useState } from "react";
import programImg from "../parent-assets/images/program-img01.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  dateFormatter,
  isValidEmail,
  isValidExpiryDate,
  isValidName,
  isValidUSZipCode,
} from "../utility/validation";

import creditCardType from "credit-card-type";
import { removeAllFromCart } from "../../reducers/cartSlice";
import { removeBirthDayFromCart } from "../../reducers/studentDataSlice";

import programImg1 from "../parent-assets/images/about-us-img01.png";
import LoaderComponent from "../../Admin/components/LoaderComponent.jsx";
import masterCard from "../../Admin/assets/images/icons/mastercard.svg";
import discoverCard from "../../Admin/assets/images/icons/discover.svg";
import americanExpress from "../../Admin/assets/images/icons/americanExpress.svg";
import jcbCard from "../../Admin/assets/images/icons/jcbCard.svg";
import visaCard from "../../Admin/assets/images/icons/visa-card.svg";
import { getStates } from "../../Admin/adminApi/users";
import { formatDateNewMMDDYYY, timeFormatter } from "../../Admin/adminUtility";
import store from "../../store/store.js";
import { openSessionModal } from "../../reducers/sessionHandler.js";

// Overlay fixing::
function useOutsideAlerter(ref, setPaymentModal) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        ref.current.className === "modal__content"
      ) {
        setPaymentModal(false);
      } else {
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function PaymentInfoModal({
  setPaymentModal,
  paymentModal,
  setCheckoutModal,
  birthdayDetails,
  amount,
  cartListData,
  totalValue,
  quantity,
  birthdayTotal,
  cartListApiData,
  setorderData,
}) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setPaymentModal);
  const [cardType, setCardType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getAllStateApi, setGetAllStateApi] = useState();
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + 1);
  const [expiryDate, setExpiryDate] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [studentID, setStudentID] = useState();
  const [signUpformData, setSignupFormData] = useState({
    authForm: false,
  });
  const validateField = (fieldName, value) => {
    let errorMessage = "";
    switch (fieldName) {
      case "firstName":
        if (!isValidName(value)) {
          errorMessage = "First Name should only contain letters";
        }
        break;
      case "lastName":
        if (!isValidName(value)) {
          errorMessage = "Last Name should only contain letters";
        }
        break;
      case "email":
        if (!isValidEmail(value)) {
          errorMessage = "Enter a valid email address";
        }
        break;
      case "zip":
        if (!isValidUSZipCode(value)) {
          errorMessage = "Enter a valid US Zip Code";
        }
        break;
      case "expirationDate":
        if (!isValidExpiryDate(expiryDate)) {
          errorMessage = "Enter a valid expiration date";
        }
        break;
      default:
    }
    return errorMessage;
  };

  const getCardIcon = () => {
    if (cardType === "american-express") {
      return <img height={"34px"} src={americanExpress} alt="discover" />;
    } else if (cardType === "discover") {
      return <img height={"50px"} src={discoverCard} alt="discover" />;
    } else if (cardType === "mastercard") {
      return <img src={masterCard} alt="masterCard" height={"34px"} />;
    } else if (cardType === "jcb") {
      return <img src={jcbCard} alt="jcb" height={"34px"} />;
    } else if (cardType === "maestro") {
      return <img src={jcbCard} alt="jcb" height={"34px"} />;
    } else if (cardType === "visa") {
      return <img src={visaCard} alt="jcb" height={"34px"} />;
    } else {
      return <></>;
    }
    return <span></span>;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === "checkbox" ? checked : value;

    if (name == "cvv") {
      if (value?.length > 5) {
        toast.error("CVV lenght should not be greater than 5-digit");
        return;
      }
    }

    if (name === "expirationDate") {
      const formattedDate = value.replace(/\s/g, "");
      if (/^\d{2}\/\d{2}$/.test(formattedDate)) {
        newValue = formattedDate;
      }
    }

    if (name === "amount" || name === "cvv" || name === "zip") {
      newValue = newValue.replace(/\D/g, "");
    }

    if (name === "creditCard") {
      newValue = newValue.replace(/\D/g, "");

      const detectedCardType = creditCardType(newValue);
      setCardType(detectedCardType.length > 0 ? detectedCardType[0].type : "");
      if ((!newValue || newValue.length < 16) && newValue === "") {
        setCardType(null);
      }
      getCardIcon();
      if (newValue.length > 16) {
        return;
      }
    }

    // Update the form data
    setFormData({
      ...formData,
      [name]: newValue,
    });

    // Validate the input and update the formErrors state
    const validationErrors = validateField(name, newValue);
    setFormErrors({
      ...formErrors,
      [name]: validationErrors,
    });

    // Clear the error message when the field is no longer invalid
    if (formErrors[name] && !validationErrors) {
      setFormErrors({
        ...formErrors,
        [name]: "",
      });
    }
  };

  const handleExpiryDateChange = (date) => {
    const formattedDate = moment(date).format("MM/YY");

    if (moment(date).isValid()) {
      const currentDate = new Date();
      const september2023 = new Date(2023, 8, 1);

      if (
        moment(date) < september2023 ||
        (moment(date) >= september2023 && moment(date) <= currentDate)
      ) {
        setShowAlert(true);
      } else {
        setShowAlert(false);
      }

      setExpiryDate(date);

      setFormData({
        ...formData,
        expirationDate: formattedDate,
      });
    } else {
      setShowAlert(true);
    }
  };

  const [formData, setFormData] = useState({
    creditCard: "",
    amount: "",
    adhocCharges: "",
    cvv: "",
    expirationDate: "",
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    zip: "",
    country: "USA",
    state: "New York",
  });

  const [formErrors, setFormErrors] = useState({
    creditCard: "",
    amount: "",
    adhocCharges: "",
    cvv: "",
    expirationDate: "",
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    zip: "",
    country: "",
    recurringInput: "2",
  });

  useEffect(() => {
    // Case1: Only Class Added
    if (
      cartListApiData?.classDetails?.length > 0 &&
      Object.keys(cartListApiData?.birthday || {}).length === 0
    ) {
      setStudentID(cartListApiData?.classDetails[0]?.studentId);
    }
  }, [cartListApiData]);

  const getFieldLabel = (index) => {
    const fieldLabels = [
      "First Name",
      "Last Name",
      "Address",
      "ZIP",
      "City",
      "State",
      "Credit Card",
      "Expiration Date",
      "Email",
      "CVV",
      "AuthForm"
    ];
    return fieldLabels[index];
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // VALIDATION

    const requiredFields = [
      formData.firstName,
      formData.lastName,
      formData.address,
      formData.zip,
      formData.city,
      formData.state,
      formData.creditCard,
      formData.expirationDate,
      formData.email,
      formData.cvv,
      signUpformData?.authForm
    ];

    let emptyFieldNames = [];
 
    requiredFields.forEach((field, index) => {
      if (!field) {
        emptyFieldNames.push(getFieldLabel(index));
      }
    });

    if (emptyFieldNames.length > 0) {
      const emptyFieldNamesString = emptyFieldNames.join(", ");
      toast.error(
        `Please fill in all required fields. Empty fields: ${emptyFieldNamesString}`
      );
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("access_token");

    let requestPromise;

    requestPromise = axios.post(
      process.env.REACT_APP_BASE_URL + `/enrollStudent/${studentID}`,
      {
        classSchedules: cartListData?.classDetails?.map(
          (val, index) => val?.fk_class_schedule_classes_enrolled
        ),
        paymentDetails: {
          first_name: formData.firstName,
          last_name: formData.lastName,
          school_policy_sign: "",
          payment_method_fk: "1",
          payment_processor_fk: "GymTime Merchant Account",
          email: formData.email,
          address1: formData?.address,
          address2: "",
          city: formData.city,
          state: formData.state,
          zip: formData.zip,
          country: formData.country,
          card_expiry_date: moment(expiryDate).format("MM/YYYY"),
          fk_clients_orders: "1",
          fk_class_orders: "1",
          card_number: formData.creditCard,
          cvv: formData.cvv,
          amount: totalValue,
          authForm:signUpformData?.authForm
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    requestPromise
      .then((res) => {
        setorderData(res.data);
        setPaymentModal(false);
        dispatch(removeAllFromCart());
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        setCheckoutModal(true);

        setTimeout(() => {
          setCheckoutModal(false);
          navigate("/parent", { state: "tab3" });
        }, 2000);
      })
      .catch((error) => {
        if (error.response.status == "401") {
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
        setIsLoading(false);
        toast.error(error?.response?.data?.error, {
          toastId: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // all States
  useEffect(() => {
    async function getAllStates() {
      const response = await getStates();
      setGetAllStateApi(response?.data?.getStates);
    }
    getAllStates();
  }, []);

  let subTotalAmount = 0;
  const CustomDatePickerInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      className="form__input"
      placeholder="Select Date"
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
    />
  ));
  const checkboxStyles = {
    padding: " 33px",
    width: "15px",
    height: "61px",
    fontWeight: "500",
  };
  return (
    <>
      {isLoading && <LoaderComponent />}
      <div
        className="modal modal--paymentModal modal--open"
        id="PaymentModal"
        role="dialog"
        ref={wrapperRef}
      >
        <div className="modal__dialog modal__dialog--lg">
          <div className="modal__content" style={{ width: "100%" }}>
            <div className="cartWrap">
              <div className="cartWrap__body">
                <div className="checkoutWrap">
                  <div className="col">
                    <div className="orderSummry">
                      <button
                        className="btnClose"
                        onClick={() => {
                          setPaymentModal(false);
                        }}
                      >
                        &times;
                      </button>
                      <div className="orderSummry__header">
                        <h3 className="title title--h3">Order summary</h3>
                      </div>
                      <div className="orderSummry__detail">
                        <div className="orderPrograms">
                          <div className="orderPrograms__list">
                            {cartListApiData?.classDetails?.map(
                              (val, index) => {
                                subTotalAmount =
                                  subTotalAmount + val.proRatedAmount || 0;
                                return (
                                  <>
                                    <div className="orderPrograms__item">
                                      <div className="orderPrograms__card">
                                        <div className="pic">
                                          <img
                                            src={
                                              val?.classImage
                                                ? val?.classImage
                                                : val?.studentImage
                                                  ? val?.studentImage
                                                  : programImg1
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div className="detail">
                                          <div className="detail__row">
                                            <div className="detail__row__col">
                                              <p className="para">
                                                {val?.className}
                                              </p>
                                              {val.start_date
                                                ? formatDateNewMMDDYYY(
                                                  val.start_date
                                                )
                                                : ""}{" "}
                                              -{" "}
                                              {val?.end_date
                                                ? formatDateNewMMDDYYY(
                                                  val.end_date
                                                )
                                                : ""}
                                              <p className="para">
                                                {val?.studentName}
                                              </p>
                                            </div>
                                            <div className="detail__row__col">
                                              <p className="totalValue">
                                                ${val?.proRatedAmount}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                        <div className="orderSummry__row">
                          <div className="text">Subtotal</div>
                          <div className="value">${totalValue}</div>
                        </div>
                      </div>
                      <div className="orderSummry__footer">
                        <div className="orderSummry__row">
                          <div className="text" style={{ color: " #5E5F5F" }}>
                            Total: ${totalValue}
                          </div>
                          <div className="value">Qty: {quantity}</div>
                        </div>
                        <div className="orderSummry__row">
                          <div className="text"></div>
                        </div>
                        <div className="orderSummry__actions">
                          <p className="para">
                            Your personal data will be used to process your
                            order, support your experience throughout this
                            website, and for other purposes described in our
                            privacy policy.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <form className="form" onSubmit={handleSubmit}>
                      <div className="form__row">
                        <h4
                          className="title title--h4"
                          style={{ cursor: "pointer", background: "none" }}
                        >
                          Personal Details
                        </h4>
                        <div className="form__group form__group--flex">
                          <div className="form__group__col">
                            <label className="form__label">
                              First Name <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form__input"
                              type="text"
                              name="firstName"
                              value={formData?.firstName}
                              onChange={handleChange}
                              placeholder="Enter First Name"
                            />
                            {formErrors.firstName && (
                              <p
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {formErrors.firstName}
                              </p>
                            )}
                          </div>
                          <div className="form__group__col">
                            <label className="form__label">
                              Last Name <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form__input"
                              type="text"
                              name="lastName"
                              value={formData.lastName}
                              onChange={handleChange}
                              placeholder="Enter Last Name"
                            />
                            {formErrors.lastName && (
                              <p
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {formErrors.lastName}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="form__group form__group--flex">
                          <div className="form__group__col">
                            <label className="form__label">
                              Email <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form__input"
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              placeholder="Enter Email ID"
                            />
                            {formErrors.email && (
                              <p
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {formErrors.email}
                              </p>
                            )}
                          </div>
                          <div className="form__group__col">
                            <label className="form__label">
                              Address <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form__input"
                              type="text"
                              name="address"
                              value={formData.address}
                              onChange={handleChange}
                              placeholder="Enter Address"
                            />
                          </div>
                        </div>
                        <div className="form__group form__group--flex">
                          <div className="form__group__col">
                            <label className="form__label">State</label>

                            <select
                              name="state"
                              className="form__select"
                              value={formData.state}
                              onChange={handleChange}
                              style={{ width: "100%", height: "44px" }}
                            >
                              <option value="" selected disabled>
                                Select State
                              </option>
                              {getAllStateApi &&
                                getAllStateApi.map((state, index) => (
                                  <option key={index} value={state?.name}>
                                    {state?.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="form__group__col">
                            <label className="form__label">City</label>
                            <input
                              className="form__input"
                              type="text"
                              placeholder="Enter City"
                              name="city"
                              value={formData.city}
                              onChange={handleChange}
                            />
                            {formErrors.city && (
                              <p
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {formErrors.city}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form__group form__group--flex">
                        <div
                          className="form__group__col"
                          style={{ width: "60%" }}
                        >
                          <label className="form__label">
                            Zip Code <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="form__input"
                            type="text"
                            placeholder="Enter Zip Code"
                            name="zip"
                            value={formData.zip}
                            onChange={handleChange}
                          />
                          {formErrors.zip && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {formErrors.zip}
                            </p>
                          )}
                        </div>
                        <div className="form__group">
                          <label className="form__label">Country</label>
                          <input
                            className="form__input"
                            type="text"
                            placeholder="Enter Country"
                            value="USA"
                            name="country"
                            onChange={handleChange}
                            style={{ userSelect: "none" }}
                            disabled={"true"}
                          />
                          {formErrors.country && (
                            <p className="error-message">
                              {formErrors.country}
                            </p>
                          )}
                        </div>
                      </div>

                      {/* CARD DETAILS  */}
                      <div className="form__row">
                        <h4
                          className="title title--h4"
                          style={{ background: "none" }}
                        >
                          Enter Your Card Details
                        </h4>
                        <div className="form__group">
                          <label className="form__label">
                            Credit Card <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="form__input"
                            type="number"
                            placeholder="Enter Card Number"
                            onChange={handleChange}
                            name="creditCard"
                            value={formData.creditCard}
                          />
                          {getCardIcon(cardType)}
                          {formErrors.creditCard && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {formErrors.creditCard}
                            </p>
                          )}
                        </div>
                        <div className="form__group form__group--flex">
                          <div className="form__group__col">
                            <label className="form__label">
                              Expiration Date (MM/YY){" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <DatePicker
                              selected={expiryDate}
                              onChange={(date) => handleExpiryDateChange(date)}
                              dateFormat="MM/yy"
                              showMonthYearPicker
                              placeholderText="MM/YY"
                              className="form-control"
                              minDate={new Date()}
                              customInput={<CustomDatePickerInput />}
                            />

                            {showAlert && (
                              <div
                                className="alert alert-danger mt-2"
                                style={{ color: "red" }}
                              >
                                Expiration date should be after September 2023.
                              </div>
                            )}
                          </div>

                          <div className="form__group__col">
                            <label className="form__label">Amount</label>
                            <input
                              className="form__input"
                              type="number"
                              placeholder="Enter Amount"
                              name="amount"
                              value={totalValue}
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="form__group form__group--flex">
                          <div className="form__group__col">
                            <label className="form__label">
                              CVV <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form__input"
                              type="text"
                              name="cvv"
                              placeholder="Enter CVV"
                              onChange={handleChange}
                              pattern="\d*"
                              maxLength={4}
                            />

                            {formErrors.cvv && (
                              <p
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {formErrors.cvv}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* AUTHOIRZATION FORM  */}
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignContent: "center",
                            marginTop: "-20px",
                            marginBottom: "1.5rem",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="authForm"
                            name="authFrorm"
                            onChange={(e) =>
                              setSignupFormData({
                                ...signUpformData,
                                authForm: e.target.checked,
                              })
                            }
                            className="authForm_checkbox"
                            style={{
                              checkboxStyles,
                            }}
                          />
                          <label
                            htmlFor="authForm"
                            style={{
                              fontSize: "0.8rem",
                              marginTop: "15px",
                              color: "#337ab7",
                              fontWeight: "500",
                            }}
                          >
                            <span className="important_field">*</span> I accept
                            that gymtime can access my Credit Card for payment
                            purpose.
                          </label>
                        </div>
                        <div className="form__group">
                          <button
                            className="btn btn--primary btn--full"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentInfoModal;
