import React from 'react'

const PolicyContent = ({sectionData}) => {
  return (
    <>
     
     {
              sectionData?.map((item)=>(
                item?.section_name==="Policies Description" && 
                <p
                dangerouslySetInnerHTML={{
                  __html:item?.descriptionSection?.description,
                }}
                ></p>
              ))
            }
          

    </>
  )
}

export default PolicyContent