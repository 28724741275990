export const GET_USERS = `
query GetUsers($pageInfo: PaginationInfo, $firstName: String, $lastName: String, $email: String, $role: String) {
    getUsers(pageInfo: $pageInfo, firstName: $firstName, lastName: $lastName, email: $email, role: $role) {
      data {
        id
        first_name
        last_name
        is_active
        email
        role_id
      }
      totalCount
      totalPages
      limit
      currPage
    }
  }
 
`;

export const GET_USERS_CLIENTS = `
query GetCommentByClient($clientId: Int, $pageInfo: PaginationInfo) {
  getCommentByClient(clientId: $clientId, pageInfo: $pageInfo) {
    currPage
    data {
      id
      date
      subject
      comment
      fk_client_comments
      createdat
      updatedat
    }
    limit
    totalCount
    totalPages
  }
}
`;

export const GET_USERS_STUDENTS = `query GetStudentByParentId($parentId: Int, $pageInfo: PaginationInfo) {
  getStudentByParentId(parentId: $parentId, pageInfo: $pageInfo) {
    data {
      id
      first_name
      last_name
      age
      fk_clients_students
      dob
      gender
      allergies
      doctor_name
      doctor_phone
      emergency_name
      emergency_phone
      vaccination
      photo_release
      parent_signature
      address
      image
      state
      zipcode
     
      classDetails {
        classId
        className
        classScheduleId
        categoryId
        categoryName
         period
         classScheduleDetails {
          id
        start_time
        end_time
        weekday
        name
        max_strength
        subject
     }
      }
    }
    totalCount
    totalPages
    limit
    currPage
  }
}
`;

// export {
//     GET_USERS
// };
