import { toast } from "react-toastify";
import { format } from "date-fns";
import { getClassForDropdown } from "./adminApi/addClassList";
import { getClassesForMakeUp } from "./adminApi/classes";

function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
  }
}
 
export function formatDateRangeCalender(dateString) {
  const dates = dateString?.split('|');
   
  const startDate = new Date(dates[0] + 'T00:00:00Z');
  const endDate = new Date(dates[1] + 'T00:00:00Z');
 
  const startDay = startDate.getUTCDate();
  const endDay = endDate.getUTCDate();
  const startMonth = startDate.toLocaleString('default', { month: 'long' });
  const endMonth = endDate.toLocaleString('default', { month: 'long' });
 
  const formattedStartDate = `${startDay}${getOrdinalSuffix(startDay)} ${startMonth}`;
  const formattedEndDate = endDay ? `${endDay}${getOrdinalSuffix(endDay)} ${endMonth}` : '';
 
  return `${formattedStartDate}${formattedEndDate ? ` - ${formattedEndDate}` : ''}`;
}
 


export function dateFormatter(dateString) {
  const dateParts = dateString?.split("-");
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];
  return `${month}-${day}-${year}`;
}

export const getCurrentDateLedger = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = `${now.getMonth() + 1}`.padStart(2, "0");
  const day = `${now.getDate()}`.padStart(2, "0");
  return `${year}-${month}-${day}`;
};
// time Formatter
export function timeFormatter(timeStr) {
  const [hours, minutes] = timeStr?.split(":").map(Number);
  const period = hours < 12 ? "am" : "pm";
  const hours12 = hours % 12 || 12;
  const formattedTime = `${hours12}:${String(minutes).padStart(
    2,
    "0"
  )} ${period}`;
  return formattedTime;
}

// convert mm/dd/yyy to yyyy/mm/dd
export function convertDateFormat(dateString) {
  const parts = dateString.split("/");

  const newDateString = `${parts[2]}/${parts[0]}/${parts[1]}`;

  return newDateString;
}

// NEW DATe FORMATTER
export function getCurrentDate(currentData) {
  let currentDate = new Date(currentData);

  const currentYear = currentDate.getFullYear();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const currentDay = currentDate.getDate().toString().padStart(2, "0");

  return `${currentMonth}-${currentDay}-${currentYear}`;
}

// Conversion of YY/mm/DD
export function getCurrentDateYYMMDD(currentData) {
  let currentDate = new Date(currentData);

  const currentYear = currentDate.getFullYear();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const currentDay = currentDate.getDate().toString().padStart(2, "0");

  return `${currentYear}-${currentMonth}-${currentDay}`;
}

// Amount Tendered Validationexp
export function isValidNumberInput(value) {
  const numericRegex = /^[0-9]+$/;
  return typeof value === "string" && numericRegex.test(value);
}

// Date Formmaterr for 02/Aug/2023 format
export const dateFormatterWithMonthName = (inputDate) => {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const formattedDate = new Date(inputDate)
    .toLocaleDateString("en-US", options)
    .replace(/ /g, "/")
    .replace(",", "");
  return formattedDate;
};

// Date format of 5 july 2023
export function convertDateFormatInMonths(dateString) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (!dateString) {
    return null; // Return null if input string is null or empty
  }

  // Split the input date string based on the delimiter
  const dateParts = dateString.split(/[-/]/);

  // Extract day, month, and year components from the split date
  const day = parseInt(dateParts[1]);
  const monthIndex = parseInt(dateParts[0]) - 1; // Subtract 1 to convert to 0-based index
  const year = parseInt(dateParts[2]);

  // Format the date in the desired format
  const formattedDate = `${day} ${months[monthIndex]} ${year}`;

  return formattedDate;
}

export function convertDateFormatInMonths2(dateString) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (!dateString) {
    return null; // Return null if input string is null or empty
  }

  // Split the input date string based on the delimiter
  const dateParts = dateString.split(/[-/]/);

  // Extract day, month, and year components from the split date
  const day = parseInt(dateParts[2]);
  const monthIndex = parseInt(dateParts[1]) - 1; // Subtract 1 to convert to 0-based index
  const year = parseInt(dateParts[0]);

  // Format the date in the desired format
  const formattedDate = `${day} ${months[monthIndex]} ${year}`;

  return formattedDate;
}

// balance Precission Calculation

// DATE fORMATER IN april  1
export function convertDateForClassDetails(dateStr) {
  const parts = dateStr.split("-");
  const month = new Date(dateStr).toLocaleString("default", { month: "long" });
  const day = parseInt(parts[2]);

  const result = month + " " + day;

  return result;
}

export function balancePrecission(value) {
  let roundedValue = Math.round(value * 100) / 100;
  let result = roundedValue.toFixed(2);
  if (result === "-0.00") {
    result = "0.00";
  }
  return result;
}

// Date Format Conversion of ---> GMT Mon Mar 18 2024 00:00:00 GMT+0530 (India Standard Time)
export function dateFormatterGMT(dateString) {
  const date = new Date(dateString);

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  // Ensure leading zeros if necessary
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${formattedMonth}/${formattedDay}/${year}`;
}

// React Select Compeont Styling
export const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    height: "44px",
    padding: "0.25rem 0.75rem",
    border: "1px solid #dadafd",
    borderRadius: "0.5rem",
    cursor: "pointer",
    appearance: "none",
    background: "#f1f1ff",
    fontSize: "0.75rem",
    fontWeight: "400",
    lineHeight: "normal",
    minWidth: "230px",
  }),
};

// CONVERT BASe 64
export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });
};

export function convertTo12HourFormat(timeStr) {
  const [hours, minutes] = timeStr.split(":").map(Number);
  const period = hours < 12 ? "am" : "pm";
  const hours12 = hours % 12 || 12;
  const formattedTime = `${hours12}:${String(minutes).padStart(
    2,
    "0"
  )} ${period}`;
  return formattedTime;
}

export const customStyles2 = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    height: "44px",
    padding: "0.25rem 0.75rem",
    border: "1px solid #dadafd",
    borderRadius: "0.5rem",
    cursor: "pointer",
    appearance: "none",
    background: "#f1f1ff",
    fontSize: "0.75rem",
    fontWeight: "400",
    lineHeight: "normal",
    minWidth: "200px",
  }),
};

export const customStylesCLASS = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    height: "44px",
    padding: "0.25rem 0.75rem",
    border: "1px solid #dadafd",
    borderRadius: "0.5rem",
    cursor: "pointer",
    appearance: "none",
    background: "#f1f1ff",
    fontSize: "0.75rem",
    fontWeight: "400",
    lineHeight: "normal",
    minWidth: "200px",
  }),
};

export function validateScheduleRows(rows) {
  let allValid = true;
  for (let key in rows) {
    if (rows.hasOwnProperty(key)) {
      for (let i = 0; i < rows[key].length; i++) {
        const schedule = rows[key][i];
        if (
          !schedule.hasOwnProperty("fee") ||
          !schedule.hasOwnProperty("date")
        ) {
          toast.error(
            `Please Enter date & amount Field in added recurrings to proceed!.`,
            {
              toastId: "error",
            }
          );
          allValid = false;
        }
      }
    }
  }
  return allValid;
}

// Formatting of Dates Without New
export const formatDateWithoutNew = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}/${month}/${day}`;
};

export const formatDateWithoutNewPrefilled = (dateString) => {
  if (!dateString) return null;

  const [year, month, day] = dateString.split("-");

  return new Date(year, month - 1, day);
};

// Browse By Program Option Boxes

// DAYS
export const days = [
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
  { label: "Sunday", value: "Sunday" },
];

//nction to convert the date Tue Jun 11 2024 00:00:00 GMT-0400 (Eastern Daylight Time) tpo MM/DD/YY without New

export const dateFormatterWithoutNew = (date) => {
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error("Invalid date format. Expected a valid Date object.");
  }

  return format(date, "MM/dd/yyyy");
};

export const formatDateNewMMDDYYY = (inputDate) => {
  // Split the input date string by '-'
  const parts = inputDate.split("-");

  // Extract year, month, and day from parts array
  let year = parts[0];
  let month = parts[1];
  let day = parts[2];

  // Check if any part is undefined or empty
  if (year === undefined || month === undefined || day === undefined) {
    // Find the last valid date part
    for (let i = parts.length - 1; i >= 0; i--) {
      if (parts[i] !== undefined) {
        day = parts[i]; // Assign the last valid part to day
        parts.splice(i, 1); // Remove the assigned part from the array
        month = parts.pop(); // Get and assign the month from the last index
        year = parts.pop(); // Get and assign the formatted date string
      }
    }
  }
  return `${month}/${day}/${year}`;
};


export const makeupClassOption = (classListData) => {

  const classOptions = [
    { value: "", label: "Select Class" },
    ...(Array.isArray(classListData)
      ? classListData
        .filter((val) => val?.name.trim() !== "")
        .map((val) => ({
          show: val?.name,
          value: val?.id,
          label: `${val?.name} ${val?.schedules?.length > 0 ? ", (" : ""
            }${val?.schedules
              ?.map((el) => {
                if (el?.weekday && el?.start_time && el?.end_time) {
                  return `${el.weekday.substring(0, 3)} ${timeFormatter(
                    el.start_time
                  )} - ${timeFormatter(el.end_time)}`;
                }
                return null;
              })
              .filter(Boolean)
              .join(", ")} ${val?.schedules?.length > 0 ? ")" : ""}`,
        }))
      : []),
  ];

  return classOptions;
}

export const fetchSortedClass = async (setLoading, setClassList) => {
  const token = localStorage.getItem("access_token");
  try {
    setLoading(true);
    const response = await getClassForDropdown(token, 0, "2");

    setClassList(response?.data);
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};


export const getClassesForMakeUpAPI = async (setLoading, makeupForm, sortKey, token, currentPage, selectedRow, selectedClass, setMakeupApiData) => {

  try {
    // setLoading(true);
    const response = await getClassesForMakeUp(
      makeupForm?.selectedDate,
      sortKey,
      token,
      currentPage,
      selectedRow,
      selectedClass
    );
    setTimeout(() => {
      // setLoading(false);
    }, 1000);
    setMakeupApiData(response?.data?.getClassesForMakeUp);
  } catch (error) {
    // setLoading(false);
  }
};



export const classOptions = (classListData) => {
  return [
    { value: "", label: "Select Class" },
    ...(Array.isArray(classListData)
      ? classListData
        .filter((val) => val?.name.trim() !== "")
        .map((val) => ({
          show: val?.name,
          value: val?.name,
          label: `${val?.name} ${val?.schedules?.length > 0 ? ", (" : ""
            }${val?.schedules
              ?.map((el) => {
                if (el?.weekday && el?.start_time && el?.end_time) {
                  return `${el.weekday.substring(0, 3)} ${timeFormatter(
                    el.start_time
                  )} - ${timeFormatter(el.end_time)}`;
                }
                return null;
              })
              .filter(Boolean)
              .join(", ")} ${val?.schedules?.length > 0 ? ")" : ""}`,
        }))
      : []),
  ]
}

