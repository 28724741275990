const GET_PROGRAMS=`
query GetProgramsCMS {
  getProgramsCMS {
    description
    id
    image
    name
    updatedAt
    alias_id
    is_active
    sub_image
  }
}
`


const GET_AGE=`
query AgeRangeCMS {
  ageRangeCMS {
    age_range
    description
    id
    image
    updatedAt
    is_active
  }
}
`


   

export {GET_AGE};
export {GET_PROGRAMS};