// Correct import statement
import axios from "axios";
import { GET_ALL_ORDERS } from "../adminQueries/Orders";
import { toast } from "react-toastify";
import store from "../../store/store";
import { openSessionModal } from "../../reducers/sessionHandler";

export async function getOrdersAPI(
  token,
  page,
  sortKey,
  formData,
  parentOrderVal,
  pageLimit
) {
  const data = formData?.map((item) => {
    return `${item.selectUser}:${item.name}`;
  });

  var variables = {};
  data.forEach(function (item) {
    var pair = item.split(":");

    variables[pair[0]] = pair[1];
  });

  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_ALL_ORDERS,
        variables: {
          pageInfo: {
            limit: parseInt(pageLimit),
            page: page,
            sort: sortKey?.sortId === null ? null : sortKey?.sortId?.toString(),
            isAsc: sortKey?.isAsc,
          },
          orderType: parseInt(parentOrderVal) ? parseInt(parentOrderVal) : 1,
          ...variables,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data.getOrders;
  } catch (error) {
    console.error("Error fetching orders from GraphQL", error);
    throw error;
  }
}

export async function markAsReadAPI(token, orderID) {
  try {
    const response = await axios.put(
      process.env.REACT_APP_BASE_URL + `/admin/order/markRead/${orderID}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const orderModalApi = async (orderId) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/order/getModalInfo/${orderId}`
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

// Void Payment::

export async function orderVoidPayment(token, orderID) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/admin/order-transactions/${orderID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {
     
  }
}

// Order REPAY

export async function orderRepay(token, data) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/admin/order/repay-void`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {
    toast.error(error.response.data.error);
     
  }
}

// Void the the Transactions

export async function voidOrderTransaction(token, selectedCheckbox) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/admin/ledger/void-transaction/`,
      {
        ids: selectedCheckbox,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {
    toast.error(error.response.data.error);
     
  }
}

export const orderRecurringAPI = async (
  token,
  orderID,
  classScheduleID,
  payload
) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL +
        `/admin/enable-recurring/${orderID}/${classScheduleID}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {
     
    return error;
  }
};
