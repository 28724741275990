import React, { useCallback, useRef } from "react";
import Header from "../components/Header";

import DeleteUser from "../components/UserModals/DeleteModal";
import { useState } from "react";
import NavigationComponent from "../components/NavigationComponent";
import TableNew from "../components/TableComponent/TableNew";
import { useEffect } from "react";
import { debounce } from "lodash";
import { useSelector } from "react-redux/es/hooks/useSelector";
import getPrograms, { deleteCategory } from "../adminApi/categories";
import getNotifications from "../adminApi/notifications";
import AddNewClass from "../components/NotificationModal/AddNewClass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { getClassForDropdown } from "../adminApi/addClassList";
import { ReactComponent as SearchIcon } from "../assets/images/icons/search.svg";
import store from "../../store/store";
import { openSessionModal } from "../../reducers/sessionHandler";
import Multiselect from "multiselect-react-dropdown";
// CoreUI PRO version
const arrowIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.4615 3.7712C7.72183 3.51085 7.72183 3.08874 7.4615 2.82839L5.57588 0.942771C5.53707 0.903964 5.49647 0.868044 5.45433 0.835024C5.00181 0.480349 4.37288 0.459313 3.89943 0.771917C3.82549 0.820731 3.75535 0.877684 3.69026 0.942771L1.80464 2.82839C1.54429 3.08874 1.54429 3.51085 1.80464 3.7712C2.06499 4.03155 2.4871 4.03155 2.74745 3.7712L3.99992 2.51872V12C3.99992 12.3682 4.2984 12.6666 4.66659 12.6666C5.03478 12.6666 5.33325 12.3682 5.33325 12V2.58576L6.51869 3.7712C6.77903 4.03154 7.20117 4.03154 7.4615 3.7712Z"
      fill="white"
    />
    <path
      d="M8.47064 12.2287C8.21031 12.4891 8.21031 12.9111 8.47064 13.1715L10.3562 15.0571C10.4214 15.1222 10.4915 15.1792 10.5654 15.228C11.083 15.5697 11.7862 15.5127 12.2419 15.0571L14.1275 13.1715C14.3878 12.9111 14.3878 12.4891 14.1275 12.2287C13.8672 11.9683 13.445 11.9683 13.1847 12.2287L11.9992 13.4141V3.99992C11.9992 3.63173 11.7008 3.33325 11.3326 3.33325C10.9644 3.33325 10.6659 3.63173 10.6659 3.99992V13.4811L9.41344 12.2287C9.15311 11.9683 8.73097 11.9683 8.47064 12.2287Z"
      fill="white"
    />
  </svg>
);

function Notifications() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [editCategory, setEditCategory] = useState(false);
  const [categoryData, setCategoryData] = useState("");
  const [addNewModal, setAddNewModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [dataEdit, setDataEdit] = useState();
  const [totalPages, setTotalPages] = useState();
  const [notification, setNotification] = useState();
  // const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [checkboxes, setCheckboxes] = useState({});
  const [emails, setEmails] = useState({});
  const [className, setClassName] = useState();
  const [formData, setFormData] = useState([]);
  const [searchParams, setSearchParams] = useState([]);
  const [temp, setTemp] = useState(1);
  const [selectedFieldType, setSelectedFieldType] = useState("");
  const [sortKey, setSortKey] = useState({
    sortId: null,
    isAsc: true,
  });
  const [loadingState, setLoadingState] = useState(false);
  const [pageLimit, setPageLimit] = useState("10");
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [selectedClassNames, setSelectedClassNames] = useState([]);
  const page = useSelector((state) => state?.adminSlice?.adminModalContent);
  const dropdownRef = useRef();
  const role = useSelector((state) => state?.userRole?.RoleId);
  const [selectedCheckboxesData, setSelectedCheckboxesData] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState();
  const token = localStorage.getItem("access_token");
  const [categoryListData, setCategoryListData] = useState();
  const [selectedIds, setSelectedIds] = useState();

  const areAllCheckboxesSelected = () => {
    if (checkboxes && Object.keys(checkboxes).length > 0) {
      const checkboxStates = Object.values(checkboxes);
      return checkboxStates.length === parseInt(pageLimit)
        ? checkboxStates.every((state) => state)
        : false;
    } else {
      return false;
    }
  };

  const handleSelectAll = (e) => {
    setIsSelectAll(e.target.checked);
    const newCheckboxesData = [];
    const allSelected = categories?.length === selectedCheckboxesData?.length;
    if (allSelected) {
      setSelectedCheckboxesData([]);
    } else {
      categories.forEach((item) => {
        newCheckboxesData.push({
          dummyId: item.item.dummy_id,
          email: item.item.parent1_email,
          clientID: item?.item?.id,
        });
      });
      setSelectedCheckboxesData(newCheckboxesData);
    }
  };

  const _tabelHeadersData = [
    {
      name: "Select All",
      icon: (
        <input
          type="checkbox"
          onChange={(e) => {
            handleSelectAll(e);
          }}
          // checked={selectedCheckboxesData?.length === categories?.length}
          checked={isSelectAll}
        />
      ),
    },
    {
      name: "Client ID",
    },
    {
      name: "Parent 1 Name",
      icon: arrowIcon,
    },
    {
      name: "Parent 1 Email",
      icon: arrowIcon,
    },
    {
      name: "Child's Name",
      icon: arrowIcon,
    },
    {
      name: "Class",
      icon: arrowIcon,
    },
  ];

  const classFilterHandler = (e) => {
    setFilterText(e.target.value);
  };

  const filterClass = className?.filter((item) =>
    item?.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleCheckboxClassNameChange = (
    index,
    checkboxIndex,
    checkboxId,
    categoryItem
  ) => {
    setSearchParams((prevFormData) => {
      const updatedFormData = [...prevFormData];
      const selectedCheckboxes =
        updatedFormData[index]?.selectedCheckboxes || [];
      if (selectedCheckboxes.includes(checkboxId)) {
        // Remove the checkbox ID if already present
        updatedFormData[index].selectedCheckboxes = selectedCheckboxes.filter(
          (id) => id !== checkboxId
        );

        // Uncheck the checkbox
        setCheckboxes((prevCheckboxes) => ({
          ...prevCheckboxes,
          [index]: false,
        }));

        // Remove categoryItem from setSelectedClassNames
        setSelectedClassNames((prevClassNames) =>
          prevClassNames.filter((className) => className.id !== categoryItem.id)
        );
      } else {
        // Add the checkbox ID if not present
        updatedFormData[index].selectedCheckboxes = [
          ...selectedCheckboxes,
          checkboxId,
        ];

        // Check the checkbox
        setCheckboxes((prevCheckboxes) => ({
          ...prevCheckboxes,
          [index]: true,
        }));

        // Add categoryItem to setSelectedClassNames
        setSelectedClassNames((prevClassNames) => [
          ...prevClassNames,
          categoryItem,
        ]);
      }
      updatedFormData.forEach((data, i) => {
        if (i === index) {
          data.name = [...data.selectedCheckboxes];
        } else {
          data.name = data?.name || [];
        }
      });

      return updatedFormData;
    });
  };

  const handleSelectCheckbox = (
    isChecked,
    dummyId,
    parentEmail,
    notification
  ) => {
    if (isChecked) {
      if (!selectedCheckboxesData.some((item) => item.dummyId === dummyId)) {
        setSelectedCheckboxesData((prevData) => [
          ...prevData,
          {
            dummyId: dummyId,
            email: parentEmail,
            clientID: notification?.id,
          },
        ]);
      }
    } else {
      setSelectedCheckboxesData((prevData) =>
        prevData.filter((item) => item.dummyId !== dummyId)
      );
    }
  };

  async function fetchData() {
    try {
      setLoadingState(true);
      const token = localStorage.getItem("access_token");
      const response = await getNotifications(
        token,
        formData,
        searchParams,
        page,
        pageLimit,
        sortKey,
        selectedIds
      );
      setNotification(response?.data);
      setCurrentPage(response?.currPage);
      setTotalCount(response?.totalCount);
      setTotalPages(response.totalPages);
      const modifiedData = [...response?.data]?.map((notification, index) => {
        return {
          data: [
            <input
              type="checkbox"
              checked={
                selectedCheckboxesData?.length > 0 &&
                selectedCheckboxesData?.some(
                  (data) => data?.dummyId === notification?.dummy_id
                )
              }
              onChange={(e) =>
                handleSelectCheckbox(
                  e.target.checked,
                  notification?.dummy_id,
                  notification?.parent1_email,
                  notification
                )
              }
            />,
            notification?.id ? notification?.id : "-",
            notification?.parent1_name ? notification?.parent1_name : "-",
            notification?.parent1_email ? notification?.parent1_email : "-",
            notification?.student_name ? notification?.student_name : "-",
            notification?.class_name ? notification?.class_name : "-",
          ],
          item: notification,
          onDeletePress: () => {
            setCategoryData(parseInt(notification.id));
            setDeleteCategoryModal(true);
          },
          onEditPress: () => {
            setDataEdit(notification);

            setEditCategory(true);
          },
        };
      });
      setCategories(modifiedData);
      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
      console.error("Error fetching data:", error);
    }
  }

  // const handleCheckboxChange = (index, id, email, dummyId) => {
  //   setCheckboxes((prevCheckboxes) => {
  //     const updatedCheckboxes = { ...prevCheckboxes };
  //     if (dummyId !== undefined && dummyId !== null) {
  //       updatedCheckboxes[dummyId] = !updatedCheckboxes[dummyId];
  //     }
  //     return updatedCheckboxes;
  //   });

  //   setEmails((prevEmails) => ({
  //     ...prevEmails,
  //     [email]: !prevEmails[email],
  //   }));

  //   setSelectedCheckboxes((prevSelected) => {
  //     if (prevSelected.includes(id)) {
  //       const updatedSelected = prevSelected.filter(
  //         (selectedId) => selectedId !== id
  //       );
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         name: updatedSelected,
  //       }));
  //       return updatedSelected;
  //     } else {
  //       const uniqueIds = Array.from(new Set([...prevSelected, id]));
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         name: uniqueIds,
  //       }));
  //       return uniqueIds;
  //     }
  //   });

  //   setSelectedEmails((prevSelectedEmails) => {
  //     if (!checkboxes[index]) {
  //       return [...prevSelectedEmails, email];
  //     } else {
  //       const updatedEmails = [...prevSelectedEmails];
  //       const emailIndex = updatedEmails.indexOf(email);
  //       if (emailIndex !== -1) {
  //         updatedEmails.splice(emailIndex, 1);
  //       }
  //       return updatedEmails;
  //     }
  //   });
  // };

  const handleSearch = (formData) => {
    fetchData(formData);
  };

  // Get all Categories  Sorted
  useEffect(() => {
    const sortData = {
      sortId: "1",
      isAsc: true,
    };
    const getAllSortedCategory = async () => {
      try {
        const response = await getPrograms(
          token,
          formData,
          0,
          sortData,
          1000000
        );
        setCategoryListData(response);
        if (
          response.data.errors &&
          response.data.errors[0]?.message === "Unauthorised."
        ) {
          setTimeout(() => {
            store?.dispatch(openSessionModal(true));
          }, 1000);
        }
      } catch (error) {
         
      }
    };

    getAllSortedCategory();

    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page, sortKey, pageLimit, selectedCheckboxesData]);

  useEffect(() => {
    getCLassData();
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const debouncedFetchData = debounce(fetchData, 2000);

  useEffect(() => {
    const pattern = /[^ ]/;

    if (pattern.test(formData.name) || formData.name?.length === 0) {
      if (formData.name !== undefined) {
        const timeoutId = setTimeout(() => {
          debouncedFetchData(fetchData);
        }, 900);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [formData.name]);

  const getCLassData = async () => {
    try {
      const token = localStorage.getItem("access_token");

      const getData = await getClassForDropdown(token, 0, "2");

      const modifiedData = getData?.data.map((classItem, index) => {
        return classItem;

        // };
      });
      setClassName(modifiedData);
    } catch (error) {
       
    }
  };

  const handleAddSearch = () => {
    setTemp((prevTemp) => prevTemp + 1);
    setSelectedFieldType("");
  };

  const handleAdditionalFieldsChange = async (index, key, value) => {
    const updatedData = [...searchParams];

    while (updatedData.length <= index) {
      updatedData.push({ selectUser: "", name: "", selectedCheckboxes: [] });
    }

    updatedData[index].selectedCheckboxes = [];

    if (key === "selectUser") {
      if (value === "studentName") {
        updatedData[index].name = "";
      }
      updatedData[index][key] = value;
    } else if (Array.isArray(value)) {
      updatedData[index].name = value;
    } else {
      updatedData[index][key] = value;
    }

    setSearchParams(updatedData);
    setSelectedFieldType(value);
    setIsDropdownOpen(value === "classIds");
    setSelectedIds([]);
    await fetchData();
  };

  const renderAdditionalFields = () => {
    const additionalFields = [];

    const handleSelect = (selectedList, selectedItem) => {
      if (selectedItem.value === "") {
        setSelectedIds([
          { value: "", key: "select_category", name: "Select Category" },
        ]);
      } else {
        setSelectedIds(selectedList);
      }
    };

    const options = [
      {
        value: "",
        key: "select_category",
        name: "Select Category",
        disableCheckbox: true,
      },
      ...(categoryListData?.data || [])
        .filter((categoryItem) => categoryItem?.name)
        .map((categoryItem) => ({
          value: categoryItem?.id,
          key: categoryItem?.id.toString(),
          name: categoryItem?.name,
        })),
    ];

    for (let i = 0; i < temp; i++) {
      const isClassField = searchParams[i]?.selectUser === "classIds";
      const isCategory = searchParams[i]?.selectUser === "categoryIds";

      additionalFields.push(
        <div
          key={i}
          className="adminform__group"
          style={{ display: "flex", alignSelf: "center" }}
        >
          <select
            name={`selectUser_${i}`}
            id={`selectUser_${i}`}
            className="adminform__select"
            onChange={(e) =>
              handleAdditionalFieldsChange(i, "selectUser", e.target.value)
            }
            value={searchParams[i]?.selectUser || ""}
          >
            <option value="" disabled>
              Search
            </option>
            <option value="parent1Name">Parent name</option>
            <option value="minAge">Min Age</option>
            <option value="maxAge">Max Age</option>
            <option value="categoryIds">Category Name</option>
            <option value="studentName">Student Name</option>
            <option value="classIds">Class Name</option>
            <option value="classId">Class ID</option>
          </select>
        </div>
      );

      additionalFields.push(
        <div key={i} className="adminform__group">
          {isClassField && isDropdownOpen ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="text"
                className="adminform__input"
                placeholder="Search Here"
                name={`text_${i}`}
                onChange={(e) =>
                  handleAdditionalFieldsChange(i, "name", e.target.value)
                }
                value={
                  searchParams[i]?.selectUser === "classIds"
                    ? selectedClassNames?.map((item) => item?.name).join(", ")
                    : searchParams[i]?.name
                }
              />
              <ul className="nestedSelectBox-notification" ref={dropdownRef}>
                <input
                  type="text"
                  className="classNameSearchBox"
                  placeholder="Search class..."
                  onChange={classFilterHandler}
                />
                {filterClass?.map((categoryItem, index) => (
                  <li key={index} className="childCheckBoxContainer">
                    <input
                      type="checkbox"
                      className="childCheckbox"
                      name={`checkbox_${i}_${index}`}
                      checked={searchParams[i]?.selectedCheckboxes?.includes(
                        categoryItem.id
                      )}
                      // data-value={categoryItem?.name}
                      onChange={(e) =>
                        handleCheckboxClassNameChange(
                          i,
                          index,
                          categoryItem?.id,
                          categoryItem
                        )
                      }
                    />
                    {categoryItem.name}
                  </li>
                ))}
              </ul>
            </div>
          ) : isCategory ? (
            <Multiselect
              className="react_custom_menu"
              options={options}
              placeholder={"Select Category"}
              selectedValues={selectedIds}
              onSelect={handleSelect}
              onRemove={handleSelect}
              displayValue="name"
              // hideSelectedList
              showCheckbox
            />
          ) : (
            <input
              type="text"
              className="adminform__input"
              placeholder="Enter Name"
              name={`text_${i}`}
              onChange={(e) =>
                handleAdditionalFieldsChange(i, "name", e.target.value)
              }
              value={searchParams[i]?.name || ""}
            />
          )}
        </div>
      );
    }

    return additionalFields;
  };

  const handleCancelAdditionalFields = (index) => {
    setTemp((prevTemp) => {
      return prevTemp > 1 ? prevTemp - 1 : prevTemp;
    });
    setSearchParams((prevSearchParams) => {
      const newSearchParams = [...prevSearchParams];
      newSearchParams[index] = { selectUser: "", name: "" };

      // Filter out elements with cleared states
      const filteredSearchParams = newSearchParams.filter(
        (item) => item.selectUser !== "" || item.name !== ""
      );
      return filteredSearchParams;
    });
  };

  useEffect(() => {
    const isReset =
      selectedIds?.length > 0 && selectedIds.some((val) => val?.value === "");

    if (isReset) {
      setSelectedIds([]);

      fetchData();
    }
  }, [selectedIds, setSearchParams]);

  return (
    <>
      <div className="container--component">
        <div className="container--grid">
          <NavigationComponent />

          <div className="Adminpage">
            <Header
              title="Notification"
              subTitle="(Manage Available Notification)"
            />
            <section className="Adminpage__section">
              <h2 className="Adminpage__title">Search by:</h2>
              <div className="Adminpage__head">
                <div className="Adminpage__filter">
                  {renderAdditionalFields()}
                  {temp <= 1 ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className="adminform__cancel-button"
                      onClick={() => handleCancelAdditionalFields(1)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}
                  <div
                    className="adminbutton__group"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <button
                      className="adminbtn adminbtn-light"
                      onClick={handleAddSearch}
                      disabled={temp == 2 ? true : false}
                      style={{ marginTop: "-8px" }}
                    >
                      and
                    </button>
                    <button
                      className="adminbtn adminbtn-primary"
                      onClick={handleSearch}
                      style={{ marginTop: "-17px" }}
                    >
                      <SearchIcon />
                      Search
                    </button>
                  </div>
                </div>
                <button
                  className="adminbtn adminbtn-light"
                  id="btnCategory"
                  // disabled={selectedCheckboxes.length ? false : true}
                  disabled={selectedCheckboxesData?.length == 0}
                  onClick={() => {
                    setAddNewModal(!addNewModal);
                  }}
                >
                  + Compose
                </button>
              </div>

              {/* <div className="Adminpage__body"> */}
              <TableNew
                loadingState={loadingState}
                headerData={_tabelHeadersData}
                bodyData={categories}
                totalPages={totalPages}
                tableName="Notification"
                setSortKey={setSortKey}
                pageLimit={pageLimit}
                setPageLimit={setPageLimit}
                totalCount={totalCount}
                currentPage={currentPage}
                setCheckboxes={setCheckboxes}
                notification="notification"
              />
              {/* </div> */}
            </section>
          </div>
        </div>
      </div>

      {addNewModal ? (
        <AddNewClass
          openAddNewUser={addNewModal}
          setAddNewUser={setAddNewModal}
          data=""
          type="Add"
          fetchData={fetchData}
          // ids={selectedCheckboxes}
          email={selectedEmails}
          setCheckboxes={setCheckboxes}
          setSelectedEmails={setSelectedEmails}
          selectedCheckboxesData={selectedCheckboxesData}
        />
      ) : null}

      {deleteCategoryModal ? (
        <DeleteUser
          openDeleteModal={deleteCategoryModal}
          setDeleteModal={setDeleteCategoryModal}
          data={categoryData}
          fetchData={fetchData}
          deleteQuery={deleteCategory}
        />
      ) : null}
    </>
  );
}

export default Notifications;
