import axios from "axios";
import store from "../../store/store";
import { openSessionModal } from "../../reducers/sessionHandler";

export async function getUserDetails(token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: `query GetUser {
            getUser {
              id
              first_name
              last_name
              is_active
              email
              role_id
              password
              image
              cell
            }
          }
        `,
        variables: {},
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
