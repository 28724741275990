import { useEffect, useState } from "react";
import { dateFormatter, timeFormatter } from "../../adminUtility";

function CampTable({
  selectedCampSchedules,
  campScheduleData,
  setCampScheduleData,
  setSelectedCampSchedules,
  setSelectedCampId,
  selectedCampId,
}) {
  const [selectedIds, setSelectedIds] = useState([]);

  const [selectedCampRows, setSelectedCampRows] = useState([]);

  const handleCheckboxChange = (campId, val) => {
    const updatedSelectedIds = selectedIds.includes(campId)
      ? selectedIds.filter((id) => id !== campId)
      : [...selectedIds, campId];
    setSelectedIds(updatedSelectedIds);

    const updatedIds = [...selectedCampId];
    const index = updatedIds.indexOf(campId);
    if (index !== -1) {
      updatedIds.splice(index, 1);
      setSelectedCampRows((prevRows) =>
        prevRows.filter((row) => row.id !== val.id)
      );
    } else {
      updatedIds.push(campId);
      setSelectedCampRows((prevRows) => {
        const newRowSet = new Set(prevRows);
        newRowSet.add(val);
        return Array.from(newRowSet);
      });
    }

    setSelectedCampId(updatedIds);
  };


  return (
    <div className="">
      <div className="flexbox">
        <table>
          <thead>
            <tr style={{ color: "white" }}>
              <th
                style={{ minWidth: "100px", maxWidth: "100px", width: "100px" }}
              >
                Category Name
              </th>
              <th>Camp Name</th>
              <th>Scheduled Camp Day</th>
              <th
                style={{ minWidth: "150px", maxWidth: "120px", width: "120px" }}
              >
                Slots
              </th>
              <th style={{ minWidth: "300px", maxWidth: "150px", width: "180px",  }}>Camp Schedule</th>
            </tr>
          </thead>

          <tbody>
            {campScheduleData?.map((val) => (
              <>
                {/* Render row for halfDay */}
                {val.halfDay.length > 0 && (
                  <tr key={`${val.id}-halfday`}>
                    <td>{val.category_name}</td>
                    <td>{val.name}</td>
                    <td>
                      {val.halfDay.map((camp) => (
                        <div className="flexbox" key={camp.id}>
                          <div className="adminform__group__grayBox">
                            <label htmlFor="week" className="label">
                              <span>
                                {camp.week}: {camp.name} (half day camp)
                              </span>
                            </label>{" "}
                            <input
                              type="checkbox"
                              className="adminform__checkbox"
                              checked={selectedCampId.includes(camp.id)}
                              onChange={() =>
                                handleCheckboxChange(camp.id, val)
                              }
                              disabled={camp.max_strength === 0}
                            />
                          </div>
                        </div>
                      ))}
                    </td>
                    <td>
                      {val.halfDay.map((camp) => (
                        <div className="flexbox" key={camp.id}>
                          {camp.max_strength === 0 ? (
                            <p>No Slots Available!</p>
                          ) : (
                            <div className="column">
                              <p>
                                {camp.max_strength - camp.enrolled} of{" "}
                                {camp.max_strength} <br />
                                <span
                                  className=""
                                  style={{
                                    fontSize: "14px",
                                    marginRight: "20px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Tuition : ${camp.tuition}
                                </span>
                              </p>
                            </div>
                          )}
                        </div>
                      ))}
                    </td>
                    <td>
                      <div className="flexbox">
                        <div
                          className="column"
                          style={{
                            textDecoration: "underline",
                            minWidth: "80px",
                          }}
                        >
                          Start time
                        </div>
                        <div
                          className="column"
                          style={{ textDecoration: "underline" }}
                        >
                          End time
                        </div>
                      </div>
                      <div className="flexbox">
                        <div className="column">
                          {val.halfDay.length > 0 &&
                            val.halfDay[0].start_time !== null &&
                            timeFormatter(val.halfDay[0].start_time)}
                        </div>
                        <div className="column">
                          {val.halfDay.length > 0 &&
                            val.halfDay[0].end_time !== null &&
                            val.halfDay[0].end_time}
                        </div>
                      </div>
                      <div className="flexbox">
                        <div
                          className="column"
                          style={{
                            textDecoration: "underline",
                            minWidth: "80px",
                          }}
                        >
                          Start Date
                        </div>
                        <div
                          className="column"
                          style={{
                            textDecoration: "underline",
                            minWidth: "80px",
                          }}
                        >
                          End Date
                        </div>
                      </div>
                      <div
                        className="flexbox"
                        style={{
                          gap: "50px",
                          alignItems: "flex-start",
                          textAlign: "left",
                        }}
                      >
                        <div className="">
                          {val.halfDay.map((camp) => (
                            <p style={{ textAlign: "left" }}>
                              {dateFormatter(camp.start_date)}
                            </p>
                          ))}
                        </div>
                        <div className="">
                          {val.halfDay.map((camp) => (
                            <p>{dateFormatter(camp.end_date)}</p>
                          ))}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}

                {/* Render row for fullDay */}
                {val.fullDay.length > 0 && (
                  <tr key={`${val.id}-fullday`}>
                    <td>{val.category_name}</td>
                    <td>{val.name}</td>
                    <td>
                      {val.fullDay.map((camp) => (
                        <div className="flexbox" key={camp.id}>
                          <div className="adminform__group__grayBox">
                            <label htmlFor="" className="label">
                              <span>
                                {camp.week}: {camp.name} (fullday camp)
                              </span>
                            </label>
                            <input
                              type="checkbox"
                              className="adminform__checkbox"
                              checked={selectedIds.includes(camp.id)}
                              onChange={() =>
                                handleCheckboxChange(camp.id, val)
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </td>
                    <td>
                      {val.fullDay.map((camp) => (
                        <div className="flexbox" key={camp.id}>
                          <div className="column">
                            <p>
                              {camp.max_strength - camp.enrolled} of{" "}
                              {camp.max_strength} <br />
                              <span
                                className=""
                                style={{
                                  fontSize: "14px",
                                  marginRight: "20px",
                                  fontWeight: "600",
                                }}
                              >
                                Tuition : ${camp.tuition}
                              </span>
                            </p>
                          </div>
                        </div>
                      ))}
                    </td>
                    <td>
                      {val.fullDay.map((camp) => (
                        <div className="flexbox" key={camp.id}>
                          <div className="column">
                            <p
                              style={{
                                textDecoration: "underline",
                                minWidth: "100px",
                                marginBottom: "10px",
                              }}
                            >
                              Start time:{" "}
                            </p>
                            <p style={{ marginBottom: "20px" }}>
                              {camp.start_time !== null &&
                                timeFormatter(camp.start_time)}
                            </p>
                            <p
                              style={{
                                textDecoration: "underline",
                                minWidth: "100px",
                                marginBottom: "10px",
                              }}
                            >
                              Start Date:{" "}
                            </p>
                            <div className="column">
                              <p
                                style={{
                                  textDecoration: "underline",
                                  minWidth: "100px",
                                }}
                              >
                                {dateFormatter(camp.start_date)}
                              </p>
                            </div>
                          </div>
                          <div className="column">
                            <p
                              style={{
                                textDecoration: "underline",
                                minWidth: "100px",
                                marginBottom: "10px",
                              }}
                            >
                              End time:{" "}
                            </p>
                            <p style={{ marginBottom: "20px" }}>
                              {camp.end_time !== null &&
                                timeFormatter(camp.end_time)}
                            </p>
                            <p
                              style={{
                                textDecoration: "underline",
                                minWidth: "100px",
                                marginBottom: "10px",
                              }}
                            >
                              End Date:{" "}
                            </p>
                            <p>{dateFormatter(camp.end_date)}</p>
                          </div>
                        </div>
                      ))}
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>

          <tbody>
            {selectedCampSchedules?.map((val) => (
              <>
                <tr key={val?.id}>
                  <td>{val?.halfDay?.length > 0 ? val?.category_name : ""}</td>
                  <td>{val?.halfDay?.length > 0 ? val?.name : ""}</td>
                  <td>
                    {val?.halfDay?.map((camp) => (
                      <div className="flexbox" key={camp?.id}>
                        <div className="adminform__group__grayBox">
                          <label htmlFor="week" className="label">
                            <span>
                              {camp?.week} : {camp?.name + " (half day camp)"}
                            </span>
                          </label>{" "}
                          <input
                            type="checkbox"
                            className="adminform__checkbox"
                            checked={selectedCampId.includes(camp?.id)}
                            onChange={() => handleCheckboxChange(camp.id)}
                          />
                        </div>
                      </div>
                    ))}
                  </td>
                  <td>
                    {val?.halfDay?.map((camp) => (
                      <div className="flexbox" key={camp?.id}>
                        <div className="column">
                          {camp?.max_strength - camp?.enrolled} of{" "}
                          {camp?.max_strength}
                        </div>
                      </div>
                    ))}
                  </td>
                  <td>
                    <div className="flexbox">
                      <div
                        className="column"
                        style={{
                          textDecoration: "underline",
                          minWidth: "80px",
                        }}
                      >
                        Start time
                      </div>
                      <div
                        className="column"
                        style={{ textDecoration: "underline" }}
                      >
                        End time
                      </div>
                    </div>

                    <div className="flexbox">
                      <div className="column">
                        {val?.halfDay[0]?.start_time}
                      </div>
                      <div className="column">{val?.halfDay[0]?.end_time}</div>
                    </div>

                    <div className="flexbox">
                      <div
                        className="column"
                        style={{
                          textDecoration: "underline",
                          minWidth: "80px",
                        }}
                      >
                        Start Date
                      </div>
                      <div
                        className="column"
                        style={{
                          textDecoration: "underline",
                          minWidth: "80px",
                        }}
                      >
                        End Date
                      </div>
                    </div>
                    <div
                      className="flexbox"
                      style={{
                        gap: "20px",
                        alignItems: "flex-start",
                        textAlign: "left",
                      }}
                    >
                      <div className="">
                        {val?.halfDay?.map((camp) => (
                          <p style={{ textAlign: "left" }}>
                            {dateFormatter(camp.start_date)}
                          </p>
                        ))}
                      </div>
                      <div className="">
                        {val?.halfDay?.map((camp) => (
                          <p>{dateFormatter(camp.end_date)}</p>
                        ))}
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "%" }}>
                    {val?.fullDay?.length > 0
                      ? val?.category_name
                        ? val?.category_name
                        : "-"
                      : ""}
                  </td>
                  <td style={{ width: "5%" }}>
                    {val?.fullDay?.length > 0
                      ? val?.name
                        ? val?.name
                        : "-"
                      : ""}
                  </td>
                  <td style={{ width: "5%" }}>
                    {val?.fullDay?.map((camp) => {
                      return (
                        <div className="flexbox" key={camp?.id}>
                          <div className="adminform__group__grayBox ">
                            <label htmlFor="" className="label">
                              <span>
                                {camp?.week} : {camp?.name + " (fullday camp)"}
                              </span>
                            </label>

                            <input
                              type="checkbox"
                              className="adminform__checkbox"
                              checked={selectedIds.includes(camp?.id)}
                              onChange={() =>
                                handleCheckboxChange(camp?.id, val)
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </td>

                  {/* MAX STRENGTH  */}
                  <td style={{ width: "5%" }}>
                    {val?.fullDay?.map((camp) => {
                      return (
                        <div className="flexbox" key={camp?.id}>
                          <div className="column">
                            {camp?.max_strength} of{" "}
                            {camp?.max_strength - camp?.enrolled}
                          </div>
                        </div>
                      );
                    })}
                  </td>
                  <td style={{ width: "10%" }}>
                    {val?.fullDay?.map((camp) => {
                      return (
                        <div className="flexbox" key={camp?.id}>
                          <div className="column">
                            <p
                              style={{
                                textDecoration: "underline",
                                minWidth: "100px",
                                marginBottom: "10px",
                              }}
                            >
                              Start time:{" "}
                            </p>
                            <p style={{ marginBottom: "20px" }}>
                              {camp?.start_time}
                            </p>
                            <p
                              style={{
                                textDecoration: "underline",
                                minWidth: "100px",
                                marginBottom: "10px",
                              }}
                            >
                              Start Date:{" "}
                            </p>
                            <div className="column">
                              <p
                                style={{
                                  textDecoration: "underline",
                                  minWidth: "100px",
                                }}
                              >
                                {dateFormatter(camp?.start_date)}
                              </p>
                            </div>
                          </div>

                          <div className="column">
                            <p
                              style={{
                                textDecoration: "underline",
                                minWidth: "100px",
                                marginBottom: "10px",
                              }}
                            >
                              End time:{" "}
                            </p>
                            <p style={{ marginBottom: "20px" }}>
                              {camp?.end_time}
                            </p>

                            <p
                              style={{
                                textDecoration: "underline",
                                minWidth: "100px",
                                marginBottom: "10px",
                              }}
                            >
                              End Date:{" "}
                            </p>
                            <p>{dateFormatter(camp?.end_date)}</p>
                          </div>
                        </div>
                      );
                    })}
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CampTable;
