import axios from 'axios';
import {GET_AGE, GET_PROGRAMS} from "../pages/adminQueries/Programs"

export async function getCmsPrograms(token){
    try {
        const response=await axios.post(
            process.env.REACT_APP_BASE_URL + '/graphql',
            {
                query:GET_PROGRAMS,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response?.data?.data?.getProgramsCMS;
    } catch (error) {
       console.error(error); 
    }
}

export async function getCmsAge(token){
    try {
        const response=await axios.post(
            process.env.REACT_APP_BASE_URL + '/graphql',
            {
                query:GET_AGE,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response?.data?.data?.ageRangeCMS;
    } catch (error) {
        console.error(error);
    }
}
export async function getCampProgramClassDetails(id){
    try {
        const data={
            subprogramId:id
        }
        const response= await axios.post(
            process.env.REACT_APP_BASE_URL +`/admin/get_sub_program`
            , data,{
                headers:{
                    'Content-Type': 'application/json'
                }
            }
        )
        return response;
    } catch (error) {
         
    }
}
// export async function getManageData(token)