import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Contact from "./Parent/parent-pages/Contact";
import Terms from "./Parent/parent-pages/Terms";
import Facilities from "./Parent/parent-pages/FaciltiesPage/Facilities";
import PrivacyPolicy from "./Parent/parent-pages/PrivacyPolicy";
import { Home, About } from "../src/Parent/parent-pages";
import { Users } from "./Admin";
import Categories from "./Admin/pages/Categories";
import Teachers from "./Admin/pages/Teachers";
import Ledger from "./Admin/pages/Ledger";
import Orders from "./Admin/pages/Orders.jsx";
import Staff from "./Parent/parent-pages/Staff";
import Calender from "./Parent/parent-pages/Schedule/Calender";
import Policies from "./Parent/parent-pages/Schedule/Policies";
import PartyPage from "./Parent/parent-pages/BirthdayAndEvents/PartyPage";
import Cart from "./Parent/parent-pages/Cart/Cart";
// PROGRAMS
import ClassDetails from "./Parent/parent-pages/BrowseByCategory/ClassDetail/ClassDetails";
import BrowseByProgramOptions from "./Parent/parent-pages/BrowseByCategory/BrowseByProgram/BrowseByProgramOptions";
import BrowseByAgeOptions from "./Parent/parent-pages/BrowseByCategory/BrowseByAge/BrowseByAgeOptions";
import ProgramDetails from "./Parent/parent-pages/BrowseByCategory/ProgramDetails.jsx";
import Layout from "./Parent/parent-components/Layout";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { setLoading } from "./reducers/loadingSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ParentProfile from "./Parent/parent-pages/ParentAndEnrollments/ParentProfile.jsx";
// import Reports from "./Admin/pages/Reports.jsx";
import Clients from "./Admin/pages/Clients.jsx";
import Attendence from "./Admin/pages/Attendence.jsx";
import Classes from "./Admin/pages/Classes.jsx";
import BirthdayMain from "./Parent/parent-pages/BirthdayAndEvents/BirthdayMain.jsx";
import BlogPage from "./Parent/parent-pages/Blog/BlogPage.jsx";
import Reports from "./Admin/pages/Reports.jsx";
import Notifications from "./Admin/pages/Notifications.jsx";
import CmsSchedule from "./Admin/pages/CMS/Schedule/CmsSchedule.jsx";
import MyProfile from "./Admin/pages/MyProfile.jsx";
import ChangeRoute from "./Admin/components/ChangeRoute/ChangeRoute.jsx";
import CmsProgram from "./Admin/pages/CMS/Programs/CmsProgram.jsx";
import SessionExpired from "./SessionExpired.jsx";
import MakeUpClass from "./Admin/pages/MakeUpClass.jsx";
import InvalidRoutes from "./Parent/parent-pages/Invalid-routes/InvalidRoutes.jsx";
// NEW ADD CLASS PAGES
import EnrichmentComboMain from "./Parent/parent-pages/AddedClass/EnrichmentCombo/EnrichmentComboMain.jsx";
import CampProgram from "./Parent/parent-pages/AddedClass/CampProgram.jsx";
import CampVersion from "./Parent/parent-pages/AddedClass/CampVersion.jsx";
import RecCheer from "./Parent/parent-pages/AddedClass/Cheer/RecCheer.jsx";
import HalfDayCamp from "./Parent/parent-pages/AddedClass/HalfDayCamp.jsx";
import PepPlus from "./Parent/parent-pages/AddedClass/EnrichmentCombo/PepPlus.jsx";
import CampDetailPage from "./Parent/parent-pages/AddedClass/CampDetailPage.jsx";
import FulDayCampDetailPage from "./Parent/parent-pages/AddedClass/FulDayCampDetailPage.jsx";
import GirlCampLandingPage from "./Parent/parent-pages/AddedClass/GirlCampLandingPage.jsx";
import GirlDayCampDetailPage from "./Parent/parent-pages/AddedClass/GirlDayCampDetailPage.jsx";
import PepPlusDetailPage from "./Parent/parent-pages/AddedClass/EnrichmentCombo/PepPlusDetailPags.jsx";
import BirthdayInvoice from "./Parent/parent-pages/Birthday-Invoice/BirthdayInvoice.jsx";
import AdminShimmer from "./Admin/components/ShimmerUi/AdminShimmer.jsx";
import PopUp from "./Parent/parent-pages/AddedClass/PopUp.jsx";
import TeamCheerGymnas from "./Parent/parent-pages/AddedClass/Cheer/TeamCheerGymnas.jsx";
import CampCheerModal from "./Admin/components/CampCmsModal/CampCheerModal.jsx";
import RollSheetPDF from "./Admin/components/TableComponent/RollSheetPDF.jsx";
import EmailVerification from "./Parent/parent-components/User/EmailVerification.jsx";
import AdvanceGym from "./Parent/parent-pages/AddedClass/Cheer/AdvanceGym.jsx";
import ResetPassword from "./Parent/parent-components/User/ResetPassword.jsx";
import InvoiceComp from "./Admin/components/TableComponent/InvoiceComp.jsx";
import ParentInvoiceComponent from "./Parent/parent-pages/ParentAndEnrollments/parent-invoice/ParentInvoice.jsx";
import ParentInvoice from "./Admin/components/TableComponent/ParentInvoice.jsx";
import CategorySummaryPdf from "./Admin/components/TableComponent/CategorySummaryPdf.jsx";
import BirthdayTerms from "./Parent/parent-components/BirthdayTerms.jsx";
import MailingListPdf from "./Admin/components/TableComponent/MailingListPdf.jsx";
import PageLoader from "./Parent/parent-components/PageLoader.jsx";
import MakeUpPdf from "./Admin/components/ClientsModal/MakeUpPdf.jsx";
import AttendanceSummary from "./Admin/components/TableComponent/AttendanceSummary.jsx";
import CategorySummaryPDFPrint from "./Admin/components/TableComponent/CategorySummaryPDFPrint.jsx";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/users" element={<Users />} />
      <Route path="/category" element={<Categories />} />
      <Route path="/classes/make-up" element={<MakeUpClass />} />
      <Route path="/ledger/:navIndex" element={<Ledger />} />
      <Route path="/teachers" element={<Teachers />} />
      <Route path="/clients/:navIndex" element={<Clients />} />
      <Route path="/orders/:navIndex" element={<Orders />} />
      <Route path="/attendence/:navIndex" element={<Attendence />} />
      <Route path="/classes/" element={<Classes />} />
      <Route path="/reports/:navIndex" element={<Reports />} />
      <Route path="/notification/:navIndex" element={<Notifications />} />
      <Route path="/classes/make-up" element={<MakeUpClass />} />
      {/* CMS ROUTES  */}
      <Route path="/page/:menuName/:menuId" element={<ChangeRoute />} />
      <Route path="/schedule" element={<CmsSchedule />} />
      <Route path="/shimmerUI" element={<AdminShimmer />} />
      <Route path="/program/browse-by-program" element={<CmsProgram />} />
      <Route path="/program/browse-by-age" element={<CmsSchedule />} />
      <Route path="/profile" element={<MyProfile />} />
      <Route path="/rollSheetPDF" element={<RollSheetPDF />} />
      <Route path="/mailingListPdf" element={<MailingListPdf />} />
      <Route path="/PDF" element={<CategorySummaryPdf />} />
      <Route path="/categorySumPDF" element={<CategorySummaryPDFPrint />} />
      <Route path="/invoice-pdf" element={<InvoiceComp />} />
      <Route path="/makeup-pdf" element={<MakeUpPdf />} />
      <Route path="*" element={<InvalidRoutes />} />
      <Route path="/attendance-summary" element={<AttendanceSummary />} />
      {/* CAMP-COMPONENT  */}

      <Route path="/cheer-camp-modal" element={<CampCheerModal />} />
    </Routes>
  );
};

function App() {
  const USER = useSelector((state) => state.userRole.userRole);
  const [openModal, setOpenModal] = useState(false);
  const session = useSelector((state) => state?.sessionSlice);

  // CSS FIXER
  useEffect(() => {
    const loadStyles = async () => {
      if (USER === "admin") {
        import("../src/Admin/assets/scss/main.scss");
      } else {
        import("../src/Parent/parent-assets/scss/pages/main.scss");
      }
    };

    loadStyles();
  }, [USER]);

  useEffect(() => {
    if (session?.isModalOpen) {
      setOpenModal(true);
    }
  }, [session?.isModalOpen]);

  const tokenExpiredFunction = () => {
    if (session.isModalOpen) {
      setOpenModal(true);
    }
  };

  useEffect(() => {
    tokenExpiredFunction();
  }, [session]);

  // Invalid Routes
  const isTokenPresent = () => {
    const token = localStorage.getItem("access_token");
    return !!token;
  };

  // Protected routes
  const ProtectedRoute = ({ element: Component }) => {
    const isToken = isTokenPresent();
    return isToken ? <Component /> : <InvalidRoutes to="/" />;
  };

  const [isPageLoads, setIsPageLoads] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPageLoads(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div>
        {isPageLoads ? (
          <PageLoader />
        ) : (
          <>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/facilities" element={<Facilities />} />
                <Route path="/staff" element={<Staff />} />
                <Route path="/cart" element={<Cart />} />

                <Route
                  path="/parent"
                  element={<ProtectedRoute element={ParentProfile} />}
                />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/blog/:title" element={<BlogPage />} />
                <Route path="/party" element={<PartyPage />} />
                <Route path="/birthday" element={<BirthdayMain />} />
                <Route path="/terms" element={<Terms />} />

                {/* NEW REQUIREMENT PAGES---------------------------------->  */}
                <Route
                  path="/enrichment/:id"
                  element={<EnrichmentComboMain />}
                />

                <Route
                  path="/cheer/team-cheer/:id"
                  element={<TeamCheerGymnas />}
                />
                <Route path="/cheer/rec-cheer" element={<RecCheer />} />

                <Route
                  path="/cheer/team-gymnastic/:id"
                  // element={<TeamGymnastics />}
                  element={<TeamCheerGymnas />}
                />

                <Route path="/camp/:id" element={<CampProgram />} />
                <Route path="/camp-version/:id" element={<CampVersion />} />
                <Route
                  path="/camp/team_gym_cheer"
                  element={<TeamCheerGymnas />}
                />
                <Route
                  path="/camp/halfday_camp-detail-class"
                  element={<CampDetailPage />}
                />
                <Route
                  path="/camp/fullday_camp-detail-class"
                  element={<FulDayCampDetailPage />}
                />
                <Route
                  path="/camp/girl-gymnastics/:id"
                  element={<GirlCampLandingPage />}
                />
                <Route path="/pop_up_image" element={<PopUp />} />
                <Route path="/camp_excep" element={<PopUp />} />
                <Route
                  path="/camp/girl-gymnastic-class-detail"
                  element={<GirlDayCampDetailPage />}
                />
                <Route path="/camp/half-day-camp" element={<HalfDayCamp />} />
                <Route path="/enrichment-combo/:id" element={<PepPlus />} />

                <Route
                  path="/enrichment-combo/pep+/:id"
                  element={<PepPlusDetailPage />}
                />

                <Route
                  path="/add-student"
                  element={<ProtectedRoute ParentProfile />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/reset-password/:token"
                  element={<ResetPassword />}
                />
                {/* Email Verificastion  */}
                <Route
                  path="/email-verification/:token"
                  element={<EmailVerification />}
                />
                <Route path="/schedule/calender" element={<Calender />} />
                <Route path="/schedule/policies" element={<Policies />} />
                <Route
                  path="/browse-by-program-options"
                  element={<BrowseByProgramOptions />}
                />
                <Route
                  path="/advance-gymnastics/:id"
                  element={<AdvanceGym />}
                />
                <Route
                  path="/browse-by-age-options/"
                  element={<BrowseByAgeOptions />}
                />
                <Route
                  path="/browse-by-programs/:name/:id"
                  element={<ProgramDetails />}
                />
                <Route
                  path="/browse-by-program/:id/:classId"
                  element={<ClassDetails />}
                />
              </Route>
              <Route
                path="/admin/*"
                element={<ProtectedRoute element={AdminRoutes} />}
              />
              <Route path="*" element={<InvalidRoutes />} />
              <Route path="/birthday-invoice" element={<BirthdayInvoice />} />
              <Route
                path="/order-parent-invoice"
                element={<ParentInvoiceComponent />}
              />
              <Route path="/parent-terms-pdf" element={<BirthdayTerms />} />
              <Route path="/parent-invoice" element={<ParentInvoice />} />
              <Route path="/birthday-invoice" element={<BirthdayInvoice />} />
            </Routes>

            <ToastContainer />

            {openModal ? (
              <SessionExpired
                openModal={openModal}
                setOpenModal={setOpenModal}
                setLoading={setLoading}
              />
            ) : null}
          </>
        )}
      </div>
    </>
  );
}

export default App;
