import React, { useEffect, useState, useRef } from "react";
import logo from "../../Parent/parent-assets/images/gymtime_logo_2024.svg";
import "./sendMailModal.css";

import html2pdf from "html2pdf.js";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const SendMailModal = ({
  categoryForMail,
  sendMailData,
  setShowMailLoader,
  loaderIndex,
  mailOrderId,
}) => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);
  const token = localStorage.getItem("access_token");
  const contentRef = useRef(null);

  useEffect(() => {
    let amount = 0;
    let amountPaid = 0;
    for (let i = 0; i < sendMailData.length; i++) {
      amount += sendMailData?.[i]?.rate;
    }
    setTotalAmount(amount);
    for (let i = 0; i < sendMailData.length; i++) {
      amountPaid += sendMailData?.[i]?.amount_paid;
    }
    setTotalAmountPaid(amountPaid);
  }, [sendMailData]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      generatePDF();
    }, 300);

    return () => clearTimeout(timeoutId);
  }, []);

  const currentDate = new Date();
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    currentDate
  );

  const changeDateFormat = (value) => {
    const newDateFormat = new Date(`${value}`).toLocaleDateString();
    return newDateFormat;
  };

  const generatePDF = async () => {
    try {
      setShowMailLoader((prev) => {
        const newLoaderState = [...prev];
        newLoaderState[loaderIndex] = true;
        return newLoaderState;
      });
      if (contentRef.current) {
        const content = contentRef.current;

        // Generate PDF and obtain the ArrayBuffer
        const pdfArrayBuffer = await html2pdf()
          .from(content)
          .outputPdf("arraybuffer");

        // Convert ArrayBuffer to base64
        const base64String = arrayBufferToBase64(pdfArrayBuffer);
        const body = new FormData();
        body.append("pdf", base64String);
        body.append("email", categoryForMail?.email);
        body.append("orderId", mailOrderId);
        body.append(
          "clientName",
          categoryForMail?.client?.parent1_first_name +
            " " +
            categoryForMail?.client?.parent1_last_name
        );
        const formDataObject = {};
        body.forEach((value, key) => {
          formDataObject[key] = value;
        });

        const response = await axios.post(
          process.env.REACT_APP_BASE_URL + `/admin/pdf_upload`,
          formDataObject,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setShowMailLoader((prev) => {
          const newLoaderState = [...prev];
          newLoaderState[loaderIndex] = false;
          return newLoaderState;
        });
        if (response?.status == "200" || response?.status == "201") {
          toast.success("Mail send successfully");
        } else {
          // toast.error("Mail can't be send, try after some time.");
          setShowMailLoader((prev) => {
            const newLoaderState = [...prev];
            newLoaderState[loaderIndex] = false;
            return newLoaderState;
          });
        }
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Mail can't be send, try after some time.");
      setShowMailLoader((prev) => {
        const newLoaderState = [...prev];
        newLoaderState[loaderIndex] = false;
        return newLoaderState;
      });
    }
  };

  function arrayBufferToBase64(arrayBuffer) {
    const bytes = new Uint8Array(arrayBuffer);
    let binary = "";
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  return (
    <>
      <div>
        <div>
          <div ref={contentRef}>
            <div className="Admin-modal__head-send_Email_Modal">
              <div className="mailing_logo_container">
                {/* <GymtimeLogo /> */}
                <img src={logo} alt="Gymtime logo" />
              </div>
              <div className="mailing_data_header">
                <div className="class_name_address">
                  <h2 className="mailing_class">
                    Gymtime Gymnastics Rhythm & Glues
                  </h2>
                  <span className="mailing_address">
                    1520 York Avenue <br /> New York, NY 10028 <br /> Phone
                    212.861.7732 <br />
                    Fax 212.861.8901
                  </span>
                </div>
                <div className="Invoide_mailing_meta_data">
                  <h1 className="mailing_Invoice_heading">INVOICE </h1>
                  <span className="Invoice_date">{formattedDate}</span>
                </div>
              </div>

              <div className="to_for_mailing_section">
                <div className="to_mailing_container">
                  <h2 className="to_mailing_heading">To</h2>
                  <span className="mailing_address">
                    <div>{sendMailData?.[0]?.address?.parent_name}</div>
                    <div>
                      {sendMailData?.[0]?.address?.to},
                      {sendMailData?.[0]?.address?.state},{" "}
                      {sendMailData?.[0]?.address?.zip}
                    </div>
                  </span>
                  <div>
                    <span style={{ fontWeight: "600", color: "black" }}>
                      Ph.{" "}
                    </span>
                    <span className="mailing_address">
                      {sendMailData?.[0]?.address?.phone_number}
                    </span>
                  </div>
                </div>
                <div className="for_mailing_container">
                  <h2 className="to_mailing_heading">For</h2>
                  <span className="mailing_address">
                    {sendMailData?.[0]?.address?.for == "undefined undefined"
                      ? "-"
                      : sendMailData?.[0]?.address?.for}
                  </span>
                </div>
              </div>
            </div>
            <div className="Admin-modal__body">
              <div className="Admin-modal__warning1">
                <table className="mailing_table_list">
                  <tr>
                    <th>DESCRIPTION</th>
                    <th>DATES</th>
                    <th>RATE</th>
                    <th>AMOUNT PAID</th>
                    <th>DATE PAID</th>
                  </tr>
                  {sendMailData?.map((item) => (
                    <tr>
                      <td>
                        <span className="mailing_class_metadata_subtitle">
                          {item?.description?.class_name}
                        </span>
                        {item?.description?.schedules?.map((schedule) => (
                          <span className="mailing_class_metadata_subtitle">
                            {` ${schedule?.weekday}, 
                                                                ${schedule?.start_time}-${schedule?.end_time}`}
                          </span>
                        ))}
                      </td>
                      <td>{`${
                        item?.dates?.class_start_date
                          ? item?.dates?.class_start_date
                          : "-"
                      }-${
                        item?.dates?.class_end_date
                          ? item?.dates?.class_end_date
                          : "-"
                      } `}</td>
                      <td>$ {item?.rate}</td>
                      <td>$ {item?.amount_paid}</td>
                      <td>{changeDateFormat(item?.date_paid)}</td>
                    </tr>
                  ))}

                  <tr className="mailing_total_amount">
                    <td>TOTALS</td>
                    <td></td>
                    <td>$ {totalAmount}</td>
                    <td>$ {totalAmountPaid}</td>
                    <td>$ {totalAmount - totalAmountPaid}</td>
                  </tr>
                  <tr className="due_meta_data_info_mailing">
                    <td></td>
                    <td></td>
                    <td className="total_rates_mailing_table">Total Rate</td>
                    <td className="total_rates_mailing_table">
                      Total Amount Paid
                    </td>
                    <td className="total_rates_mailing_table">Due Amount</td>
                  </tr>
                </table>
              </div>
              <div>
                <div className="greeting_container">
                  <span className="greeting_mailing">
                    THANK YOU FOR YOUR BUSINESS!
                  </span>
                  <span className="gymtime_mailing_info">
                    <Link
                      style={{
                        cursor: "pointer",
                        color: "00bbe3",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        window.open("https://www.gymtime.net", "_blank");
                      }}
                    >
                      info@gymtime.net - www.Gymtime.net - 1520 York Ave - new
                      York, NY 10028
                    </Link>{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendMailModal;
