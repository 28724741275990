import axios from "axios";

export const updateHeaderTitleFunc = async (
  sectionid,
  header_title_color,
  header_title,
  token
) => {
  let payload = {
    sectionId: sectionid,
    header_title: [header_title_color, header_title],
  };
  const response = await axios.put(
    process.env.REACT_APP_BASE_URL + `/admin/editTitleAndColor`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};
export const updateProgramHeaderTitleFunc = async (
  programId,
  color,
  name,
  token
) => {
    let id=parseInt(programId);
  let payload = {
    programId: id,
    color: color,
    name: name,
  };
  const response = await axios.put(
    process.env.REACT_APP_BASE_URL + `/admin/editProgramNameAndColorCMS`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};
