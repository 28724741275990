import React, { useState } from 'react'

const AdminShimmer = () => {
    const [numOfRows,setNumOfRows]=useState(6);
    const numberOfRowsHandler=()=>{
        let element
    }
  return (
    <>
      <div className="shimmer_admin_ui_container">
        <div className="shimmer_grid_container">
            <div className="navigation_shimmer">

            </div>

            <div className="admin_shimmer">
                <div className="admin_header_shimmer"></div>
                <div className="admin_page_shimmer_ui">
                    <div className="table_heading_shimmer"></div>
                    {
                        numberOfRowsHandler()
                    }
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default AdminShimmer