const GET_MASTERMENU = `query GetmasterMenuCMS {
    getmasterMenuCMS {
      menu_name
      id
    }
  }`;

export const GET_MASTERSUBMENU = `
  query GetsubMenuCMS($masterMenuId: Int!) {
    getsubMenuCMS(masterMenuId: $masterMenuId) {
      sub_menu_name
      id
    }
  }`;
  export const GET_SECTION=`
  query GetsectionCMS($subMenuId: Int!) {
    getsectionCMS(subMenuId: $subMenuId) {
      descriptionSection {
        description
        id
      }
      id
      imageSection {
        id
        image
      }
      video
      price_package
      section_name
      header_title
    }
  }
  `
export { GET_MASTERMENU };
